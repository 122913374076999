(function() {
    'use strict';

    angular.module('adminApp.saas')
        .service('accountService', accountService);

    function accountService() {
        var ids = {
            cloud: null,
            quwurfl: null,
            wit: null,
            wjs: null
        };
        var values = {
            cloud: {},
            quwurfl: {},
            wit: {},
            wjs: {}
        };
        var service = {
            clear: clear,
            getAll: getAll,
            getId: getId,
            getValues: getValues,
            setId: setId,
            setValues: setValues
        };

        return service;

        function clear() {
            values = {
                cloud: {},
                quwurfl: {},
                wit: {},
                wjs: {}
            };
        }

        function getAll(type) {
            return values[type];
        }

        function getId(type) {
            return ids[type];
        }

        function getValues(type, key) {
            return values[type][key];
        }

        function setId(type, val) {
            ids[type] = val;
        }

        function setValues(type, key, val) {
            values[type][key] = val;
        }
    }
})();
