(function() {
    'use strict';

    angular.module('adminApp.cut')
        .factory('centraltestTestsService', centraltestTestsService);

    centraltestTestsService.$inject = ['$resource', '$window', 'Notification','CUT_URL'];

    function centraltestTestsService($resource, $window, Notification, CUT_URL) {
        var resource = new $resource(CUT_URL + '/api/admin/centraltests/test/:id/:action', {id: '@id'}, {
            all: {
                method: 'GET',
                params: {
                    action: 'all',
                    params: {
                        test_id: '@test_id',
                        name: '@name',
                        description: '@description',
                        mode: '@mode',
                        type_unit: '@type_unit',
                        type_regression: '@type_regression',
                        enabled: '@enabled'
                    }
                }
            },
            create: {
                method: 'POST',
                params: {action: 'create'}
            },
            gatherIds: {
                method: 'GET',
                params: {action: 'ids'}
            },
            get: {
                method: 'GET',
                params: {action: null}
            },
            getRequestId: {
                method: 'GET',
                params: {action: 'request'}
            },
            quickadmin: {
                method: 'GET',
                params: {action: 'quickadmin'}
            },
            remove: {
                method: 'DELETE',
                params: {action: null}
            },
            save: {
                method: 'PATCH',
                params: {action: null}
            }
        });

        var gather = new $resource(CUT_URL + '/api/admin/centraltests/gather/:id/:action', {id: '@id'}, {
            assertions: {
                method: 'GET',
                params: {action: 'assertions'}
            },
            headers: {
                method: 'GET',
                params: {action: 'headers'}
            }
        });

        var service = {
            all: all,
            create: create,
            gatherAssertions: gatherAssertions,
            gatherHeaders: gatherHeaders,
            gatherIds: gatherIds,
            get: get,
            getRequestId: getRequestId,
            getQuickAdmin: getQuickAdmin,
            remove: remove,
            save: save,
            validate: validate,
            validateLength: validateLength
        };

        return service;

        function all(params) {
            var obj = {
                id: null
            };
            angular.forEach(params, function(val, key) {
                key = key === 'id' ? 'test_id' : key;
                obj[key] = val;
            });
            return resource.all(obj).$promise;
        }

        function create(form) {
            var errors = validate(form);
            if (errors) {
                return Promise.reject({status: false, errors: errors});
            }
            return resource.create({id: null}, form).$promise;
        }

        function getQuickAdmin() {
            return resource.quickadmin({id: null}).$promise;
        }

        function gatherAssertions(id) {
            return gather.assertions({id: id}).$promise;
        }

        function gatherHeaders(id) {
            return gather.headers({id: id}).$promise;
        }

        function gatherIds() {
            return resource.gatherIds({id: null}).$promise;
        }

        function get(id) {
            return resource.get({id: id}).$promise;
        }

        function getRequestId(id) {
            return resource.getRequestId({id: id}).$promise;
        }

        function remove(id) {
            return resource.remove({id: id}).$promise;
        }

        function save(form) {
            var errors = validate(form);
            if (errors) {
                return Promise.reject({status: false, errors: errors});
            }
            return resource.save({id: form.id}, form).$promise;
        }

        function validate(form) {
            var errors = {
                name: {
                    show: false,
                    message: null
                },
                mode: {
                    show: false,
                    message: null
                }
            };

            if (
                form.name === null ||
                form.name === '' ||
                form.name === undefined
            ) {
                 errors.name.show = true;
                 errors.name.message = "Name is required";
                return errors;
            }
            var length = false;
            angular.forEach(['name'], function(val, key) {
                var response = validateLength(form, val);
                if (response) {
                    length = true;
                    errors[val] = response;
                }
            });
            if (length) {
                return errors;
            }
            return false;
        }

        function validateLength(form, field) {
            if (form[field] !== undefined && form[field].length > 255) {
                return {
                    show: true,
                    message: field + " must be no more than 255 characters"
                };
            }
            return false;
        }
    }
})();
