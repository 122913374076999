(function() {
    'use strict';

    angular.module('adminApp.coreapi')
        .controller('CoreApiEditModalController', CoreApiEditModalController);

    CoreApiEditModalController.$inject = ['$rootScope', '$state', '$stateParams', '$uibModalInstance', 'client', 'coreApiClientService', 'Notification'];

    function CoreApiEditModalController($rootScope, $state, $stateParams, $uibModalInstance, client, coreApiClientService, Notification) {
        var vm = this;

        vm.client = client;
        vm.close = close;
        vm.formErrors = {};
        vm.modalOptions = {
            actionClass: 'alert-info',
            actionMessage: 'Saving...',
            proceedButton: {
                btnClass: 'btn-primary',
                btnSpanClass: 'glyphicon-floppy-save',
                btnText: 'Save'
            },
            template: 'view/coreapi.modals.edit_modal',
            title: 'Edit CoreApi Client'
        };
        vm.showActionSpinner = false;
        vm.proceed = proceed;
        vm.updateDisabled = false;

        var roleError = "You are not permitted to perform that task";

        if (vm.client.secret === null || vm.client.name === null) {
            vm.updateDisabled = true;
            coreApiClientService.get(vm.client.id)
                .then(function(response) {
                    vm.client = response;
                    vm.updateDisabled = false;
                }, function(error) {
                    switch (error.status) {
                        case 400:
                            Notification.error(error.data.message);
                            break;
                        case 403:
                            Notification.error(roleError);
                            close();
                            break;
                        default:
                            Notification.error("An unknown error has occurred");
                            console.log(error);
                    }
                });
        }

        function close() {
            $uibModalInstance.dismiss();
        }

        function proceed() {
            vm.formErrors = {};
            vm.showActionSpinner = true;
            coreApiClientService.save(vm.client)
                .then(function(response) {
                    vm.client = response.data;
                    Notification.success('Client updated');
                    if ($state.current.name == 'home') {
                        $state.go('coreapi.home', {});
                    } else if ($state.current.name == 'coreapi.home') {
                        $rootScope.$broadcast('reloadCoreapiClients');
                    } else if ($state.current.name == 'coreapi.view') {
                        $rootScope.$broadcast('reloadClient');
                    }
                    $uibModalInstance.dismiss();
                }).catch(function(error) {
                    switch (error.status) {
                        case 400:
                            angular.forEach(error.data.data, function(value, key) {
                                vm.formErrors[key] = value[0];
                            });
                            Notification.error("Form errors exist");
                            break;
                        case 403:
                            Notification.error(roleError);
                            close();
                            break;
                        default:
                            Notification.error("An unknown error has occurred");
                            break;
                    }
                }).finally(function() {
                    vm.showActionSpinner = false;
                });
        }
    }
})();
