(function() {
    'use strict';

    angular.module('adminApp.coreapi')
        .controller('ClientScopesController', ClientScopesController);

    ClientScopesController.$inject = ['$rootScope', '$scope', '$state', '$stateParams', '$uibModalInstance', 'breadcrumbsService', 'clientId', 'coreApiClientService', 'coreApiScopeService', 'Notification'];

    function ClientScopesController($rootScope, $scope, $state, $stateParams, $uibModalInstance, breadcrumbsService, clientId, coreApiClientService, coreApiScopeService, Notification) {
        var vm = this;

        vm.addAll = addAll;
        vm.client = {};
        vm.description = [];
        vm.disabled = [];
        vm.handleAssignScopeClick = handleAssignScopeClick;
        vm.handleDeleteClientScope = handleDeleteClientScope;
        vm.modalOptions = {
            actionClass: 'alert-info',
            actionMessage: 'Saving...',
            noShowClose: true,
            proceedButton: {
                btnClass: 'btn-primary',
                btnSpanClass: 'glyphicon-ok',
                btnText: 'Done'
            },
            template: 'view/coreapi.modals.edit_client_scopes',
            title: 'Select Client Scopes'
        };
        vm.proceed = proceed;
        vm.removeAll = removeAll;
        vm.scopes = {};
        vm.showActionSpinner = false;
        vm.showDescription = showDescription;
        vm.steps = {};
        vm.transferring = false;

        getClientScopes();

        coreApiScopeService.all()
            .then(function(response) {
                vm.scopes = response.data;
                angular.forEach(vm.scopes, function(val, key) {
                    vm.description[val.id] = false;
                    if (_.isUndefined(vm.disabled[val.id])) {
                        vm.disabled[val.id] = false;
                    }
                });
            }, function(error) {
                setErrorNotification(error);
            });


        function addAll() {
            var scopes = _.reject(vm.scopes, function(obj) {
                return (_.find(vm.client.scopes, function(cObj) {
                    return cObj.id == obj.id;
                }));
            });
            angular.forEach(scopes, function(val, key) {
                handleAssignScopeClick(val.id);
            });
        }

        function handleAssignScopeClick(id) {
            vm.transferring = true;
            var params = {
                scope_id: id,
                client_id: clientId
            };

            coreApiClientService.addClientScope(params)
                .then(function(response) {
                    vm.client.scopes.push(response.data);
                    vm.disabled[id] = true;
                }, function(error) {
                    setErrorNotification(error);
                }).finally(function() {
                    vm.transferring = false;
                });
        }

        function handleDeleteClientScope(scopeId) {
            var params = {
                scope_id:  scopeId,
                client_id: clientId
            };
            vm.transferring = true;
            coreApiClientService.removeClientScope(params)
                .then(function(response) {
                    vm.client.scopes = _.filter(vm.client.scopes, function(obj) {
                        return obj.id !== scopeId;
                    });
                    vm.disabled[scopeId] = false;
                }, function(error) {
                    setErrorNotification(error);
                }).finally(function() {
                    vm.transferring = false;
                });
        }

        function getClientScopes() {
            coreApiClientService.getClientScopes(clientId)
                .then(function(response) {
                    vm.client = response.data;
                    angular.forEach(vm.client.scopes, function(val, key) {
                        vm.disabled[val.id] = true;
                    });
                }, function(error) {
                    setErrorNotification(error);
                });
        }

        function proceed() {
            if ($state.current.name == 'coreapi.view') {
                $rootScope.$broadcast('reloadClient');
            }
            $uibModalInstance.dismiss();
        }

        function removeAll() {
            angular.forEach(vm.client.scopes, function(val, key) {
                handleDeleteClientScope(val.id)
            });
        }

        function setErrorNotification(error) {
            switch (error.status) {
                case 400:
                    Notification.error(error.data.message);
                    break;
                case 403:
                    Notification.error({message: 'You are not permitted to perform this action', replaceMessage: true});
                    break;
                default:
                    Notification.error("An unknown error has occurred");
                    console.log(error);
            }
        }

        function showDescription(scope, val) {
            vm.description[scope.id] = val;
        }
    }
})();
