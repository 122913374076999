(function() {
    'use strict';

    angular.module('adminApp.wit')
        .controller('WitViewAccountController', WitViewAccountController);

    WitViewAccountController.$inject = ['$state', '$stateParams', '$uibModalInstance', 'baseAccountService', 'witAccountService', 'Notification'];

    function WitViewAccountController($state, $stateParams, $uibModalInstance, baseAccountService, witAccountService, Notification) {
        var vm = this;

        vm.close = close;
        vm.dataLoaded = false;
        vm.plan = null;
        vm.token = null;
        vm.usage = {};
        vm.viewSubscription = viewSubscription;

        var witId = null;
        var userId = null;

        baseAccountService.get(witAccountService.resourceUrl, $stateParams.id).then(function(response) {
            vm.token = response.data.account.wurfl_tokens[0].token;
            witId = response.data.account.id;
            userId = response.data.account.user.id;
            vm.plan = response.data.account.plan.name;
            baseAccountService.billingPeriods(witAccountService.resourceUrl, $stateParams.id)
                .then(function(response) {
                    var currentPeriod = response.data[0];
                    baseAccountService.usage(witAccountService.resourceUrl, $stateParams.id, currentPeriod.start.date, currentPeriod.end.date)
                        .then(function(response) {
                            vm.usage.totalImages = response.data.total_images;
                            vm.usage.totalBandwidth = response.data.total_bandwidth;
                            vm.dataLoaded = true;
                        }).catch(function(error) {
                            console.log(error);
                            Notification.error(error.data.message);
                        });
                }).catch(function(error) {
                    console.log(error);
                    Notification.error(error.data.message);
                });
        }).catch(function(error) {
            console.log(error);
            Notification.error(error.data.message);
        });

        function close() {
            $uibModalInstance.dismiss();
            $state.go('users.myaccount', {id: userId});
        }

        function viewSubscription() {
            $uibModalInstance.dismiss();
            $state.go('wit.view', {id: witId});
        }
    }
})();
