(function() {
    'use strict';

    angular.module('adminApp')
        .directive('wurflDownloadsList', wurflDownloadsList);

    wurflDownloadsList.$inject = ['activityLogService'];

    function wurflDownloadsList(activityLogService) {
        var directive = {
            restrict: 'E',
            replace: true,
            templateUrl: '/view/components.wurfl_downloads',
            scope: {
                ngWurflDownloads: "=downloads"
            },
            link: link
        };

        return directive;

        function link(scope, element, attrs) {
            scope.loading = true;
            activityLogService.getWurflDownloads(attrs.userId).$promise
                .then(function(response) {
                    scope.ngWurflDownloads = response.data;
                    scope.loading = false;
                }, function(error) {
                    console.error(error);
                    scope.loading = false;
                });
        }
    }
})();
