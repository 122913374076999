(function() {
    'use strict';

    angular.module('adminApp')
        .controller('MasterController', MasterController);

    MasterController.$inject = ['$rootScope', '$scope', '$state', 'authenticate', 'endSession', 'historyService', 'loginService', 'pendingRequestsService', 'transferFlash'];

    function MasterController($rootScope, $scope, $state, authenticate, endSession, historyService, loginService, pendingRequestsService, transferFlash) {
        var vm = this;

        vm.authenticated = loginService.authInfo.authenticated;
        vm.clearHistory = clearHistory;
        vm.goToUser = goToUser;
        vm.history = [];
        vm.historyShow = false;
        vm.logout = logout;
        vm.toggleHistory = toggleHistory;
        vm.user = loginService.authInfo.user;

        $rootScope.$on('authenticated', function(event, args) {
            vm.authenticated = loginService.authInfo.authenticated;
            vm.user = loginService.authInfo.user;
        });

        function clearHistory() {
            historyService.clear();
        }

        function goToUser() {
            $state.go('users.view', {id: vm.user.id});
        }

        function logout() {
            loginService.intended = undefined;
            clearHistory();
            pendingRequestsService.cancelAll();
            return logoutUser().then(function() {});
        }

        function logoutUser() {
            return authenticate.logout()
                .then(function(data) {
                    if (data) {
                        transferFlash.set('message', "<h4>See you soon!!</h4><p>You have been logged out.");
                        transferFlash.set('type', 'success');
                        loginService.authInfo.authenticated = false;
                        loginService.authInfo.user = null;
                        $rootScope.$broadcast('authenticated');
                        endSession.end();
                        $state.go('login');
                    } else {
                        alert("Logout failed");
                    }
                });
        }

        function toggleHistory() {
            vm.history = historyService.list;
        }
    }
})();
