(function() {
    'use strict';

    angular.module('adminApp.home')
        .factory('adminlogService', adminlogService);

    adminlogService.$inject = ['$resource', '$uibModal', 'Notification'];

    function adminlogService($resource, $uibModal, Notification) {
        var resource = new $resource('/api/admin/adminlog/:id/:action', {id: '@id'}, {
            all: {
                method: 'GET',
                params: {
                    action: null,
                    page: '@page',
                    username: '@username'
                }
            },
            changelog: {
                method: 'GET',
                params: {
                    action: 'changelog'
                }
            },
            date: {
                method: 'GET',
                params: {
                    action: 'date'
                }
            },
        });

        var service = {
            all: all,
            changelog: changelog,
            date: date,
            viewLogData: viewLogData
        };

        return service;

        function all(params) {
            var obj = {
                id: null
            };
            angular.forEach(params, function(val, key) {
                obj[key] = val;
            });
            return resource.all(obj).$promise;
        }

        function changelog(id) {
            return resource.changelog({id: id}).$promise;
        }

        function date() {
            return resource.date({id: null}).$promise;
        }

        function viewLogData(previous, next) {
            $uibModal.open({
                templateUrl: 'view/home.changelog_modal',
                controller: 'ChangelogModalController',
                controllerAs: 'vm',
                size: 'lg',
                keyboard: false,
                backdrop: 'static',
                resolve: {
                    previous: function() {
                        return previous;
                    },
                    next: function() {
                        return next;
                    }
                }
            });
        }
    }
})();
