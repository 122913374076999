(function() {
    angular.module('adminApp.saas')
        .factory('baseAccountService', baseAccountService);

    baseAccountService.$inject = ['$filter', '$resource', 'accountDefaults', 'cloudAccountService', 'witAccountService', 'wjsAccountService', 'quwurflAccountService'];

    function baseAccountService($filter, $resource, accountDefaults, cloudAccountService, witAccountService, wjsAccountService, quwurflAccountService) {

        function createResource(url) {
            return new $resource(url + "/:id/:action/:action_id/:param1/:param2", {id: '@id'}, {
                addHostname: {
                    method: 'POST'
                },
                billingHistory: {
                    method: 'GET',
                    params: {
                        action: 'billing-history'
                    }
                },
                billingPeriods: {
                    method: 'GET',
                    params: {
                        action: 'periods'
                    }
                },
                cancel: {
                    method: 'PATCH',
                    params: {
                        action: 'cancel'
                    }
                },
                deleteHostname: {
                    method: 'DELETE',
                    params: {
                        force: '@force_delete'
                    }
                },
                gatherByUser: {
                    method: 'GET',
                    params: {
                        action: 'user',
                        user_id: '@user_id'
                    }
                },
                get: {
                    method: 'GET',
                    params: {
                        page: '@page'
                    }
                },
                getCancelLog: {
                    method: 'GET',
                    params: {
                        action: 'cancellog'
                    }
                },
                getChangeLog: {
                    method: 'GET',
                    params: {
                        action: 'changelog'
                    }
                },
                getConstants: {
                    method: 'GET'
                },
                getForUser: {
                    method: 'GET',
                    params: {
                        action: 'user'
                    }
                },
                query: {
                    method:'GET',
                    isArray:true
                },
                restart: {
                    method: 'PATCH',
                    params: {
                        action: 'restart'
                    }
                },
                restoreHostname: {
                    method: 'PATCH'
                },
                save: {
                    method:'POST'
                },
                search: {
                    method: 'GET'
                },
                suspend: {
                    method: 'PATCH',
                    params: {
                        action: 'suspend'
                    }
                },
                transfer: {
                    method: 'POST',
                    params: {
                        action: 'transfer'
                    }
                },
                unsuspend: {
                    method: 'PATCH',
                    params: {
                        action: 'unsuspend'
                    }
                },
                update: {
                    method:'PATCH'
                },
                usage: {
                    method: 'GET',
                    params: {
                        action: 'usage'
                    }
                }
            });
        }

        var service = {
            all: all,
            billingHistory: billingHistory,
            billingPeriods: billingPeriods,
            cancel: cancel,
            foundMetaInfo: foundMetaInfo,
            gatherByUser: gatherByUser,
            get: get,
            getForUser: getForUser,
            getCancelLog: getCancelLog,
            getChangeLog: getChangeLog,
            getConstants: getConstants,
            getService: getService,
            isFree: isFree,
            isStandardBilling: isStandardBilling,
            remapLimits: remapLimits,
            restart: restart,
            save: save,
            search: search,
            suspend: suspend,
            transfer: transfer,
            unsuspend: unsuspend,
            update: update,
            updateDefaults: updateDefaults,
            usage: usage
        };

        return service;

        function all(url, options) {
            var resource = createResource(url);

            var params = {id: null};
            if (options) {
                params = _.extend(params, options);
            }

            return resource.get(params).$promise;
        }

        function billingHistory(url, id) {
            var resource = createResource(url);
            return resource.billingHistory({id: id}).$promise;
        }

        function billingPeriods(url, id) {
            var resource = createResource(url);
            return resource.billingPeriods({id: id}).$promise
                .then(function(response) {
                    // Remap the data into Date objects
                    response.data = response.data.map(function(item) {
                        // Coreapi tells the timezone of the its billing period dates
                        // We use them to produce correct local date objects which has the correct timestamp in any timezone
                        var start_moment_object = moment.tz(item.start.date, item.start.timezone);
                        var end_moment_object = moment.tz(item.end.date, item.end.timezone);
                        item.start.date = start_moment_object.toDate();
                        item.end.date = end_moment_object.toDate();

                        return item;
                    });
                    return response;
                }, function(error) {
                    console.log(error);
                });
        }

        function cancel(url, id, reason) {
            var resource = createResource(url);
            return resource.cancel({id: id, reason: reason}).$promise;
        }

        function foundMetaInfo(array, key, search_keyword) {
            return _.chain(array).pluck(key).map(function(i) {
                return $filter('found')(i, search_keyword);
            }).join(', ');
        }

        function gatherByUser(url, user_id) {
            var resource = createResource(url);
            var params = {id: null, user_id: user_id};

            return resource.gatherByUser(params).$promise;
        }

        function get(url, account_id, options) {
            var resource = createResource(url);

            var params = {};
            if (account_id) {
                params.id = account_id;
            }

            if (options) {
                params = _.extend(params, options);
            }

            return resource.get(params).$promise;
        }

        function getForUser(url, user_id) {
            var resource = createResource(url);
            return resource.getForUser({id: null, action_id: user_id}).$promise;
        }

        function getCancelLog(type, id) {
            var resource = createResource(type);
            return resource.getCancelLog({id: id}).$promise;
        }

        function getChangeLog(url, id) {
            var resource = createResource(url);
            return resource.getChangeLog({id: id}).$promise;
        }

        function getConstants(url) {
            var resource = createResource(url);
            return resource.getConstants({id: null}).$promise;
        }

        function getService(saasType) {
            switch (saasType) {
                case 'cloud':
                    return cloudAccountService;
                case 'wjs':
                    return wjsAccountService;
                case 'wit':
                    return witAccountService;
                case 'quwurfl':
                    return quwurflAccountService;
                default:
                    Notification.error("Can't determine service");
                    console.error("Can't determine service");
            }
        }

        function isFree(subscription) {
            return parseInt(subscription.plan.price) === 0;
        }

        function isStandardBilling(subscription) {
            return subscription.payment_type === 'STANDARD';
        }

        /**
         * Remap the account limits into associated object for easier and direct access
         * ex. hostnames: {}, bandwidth: {}
         *
         * @param array account_limits
         * @param object plan_default_object_name The plan default object name
         */
        function remapLimits(account_limits, plan_default_object_name) {
            var return_data = {};

            angular.forEach(account_limits, function(item) {
                item.type = item[plan_default_object_name].type.toLowerCase();
                item.plan_default = item[plan_default_object_name];
                return_data[item.type] = item;
            });
            return return_data;
        }

        function restart(url, id) {
            var resource = createResource(url);
            return resource.restart({id: id}).$promise;
        }

        function save(url, data) {
            var resource = createResource(url);
            return resource.save(data).$promise;
        }

        function search(url, keyword, per_page, page) {
            var resource = createResource(url);
            return resource.search({
                id: null,
                action: 'search',
                action_id: keyword,
                paginate: per_page,
                page: page
            }).$promise;
        }

        function suspend(url, id) {
            var resource = createResource(url);
            return resource.suspend({id: id}).$promise;
        }

        function transfer(id, userId) {
            var resource = createResource('/api/admin/subscriptions');
            return resource.transfer({id: id}, {user_id: userId}).$promise;
        }

        function unsuspend(url, id) {
            var resource = createResource(url);
            return resource.unsuspend({id: id}).$promise;
        }

        function update(url, data) {
            var resource = createResource(url);
            return resource.update({id: data.id}, data).$promise;
        }

        function updateDefaults(url, id, data) {
            accountDefaults.setResourceUrl(url);
            return accountDefaults.update(id, data);
        }

        function usage(url, id, start, end) {
            var resource = createResource(url);
            return resource.usage({id: id, param1: start.getTime()/1000, param2: end.getTime()/1000}).$promise;
        }
    }
})();
