(function() {
    'use strict';

    angular.module('adminApp.wit')
        .controller('WitDeleteDomainController', WitDeleteDomainController);

    WitDeleteDomainController.$inject = ['$q', '$stateParams', '$uibModalInstance', 'domain', 'Notification', 'origin', 'witAccountService'];

    function WitDeleteDomainController($q, $stateParams, $uibModalInstance, domain, Notification, origin, witAccountService) {
        var vm = this;

        domain.wit_certificate = _.isUndefined(domain.wit_certificate) ? [] : domain.wit_certificate;

        vm.close = close;
        vm.domain = domain;
        vm.generalError = false;
        vm.origin = origin;
        vm.running = false;
        vm.submit = submit;

        var certificateFail = false;
        var certificatePromises = [];

        function close() {
            $uibModalInstance.dismiss();
        }

        function finish() {
            if (!certificateFail) {
                witAccountService.postDeleteResourceRecords($stateParams.id, domain.id).then(function(response) {
                    // Successfully deleted DNS records
                    witAccountService.deleteDomain($stateParams.id, domain.id).then(function(response) {
                        Notification.success("Deleted domain");
                        $uibModalInstance.close(response.data);
                    }).catch(function(error) {
                        console.log(error);
                        var message = "Your domain can not be deleted at this time";
                        if (!_.isNull(vm.domain.status.ssl)) {
                            message += ", however your SSL/TLS certificate and DNS record have been removed";
                        }
                        message += ". Please contact support@scientiamobile.com for assistance.";
                        vm.generalError = message;
                    }).finally(function() {
                        vm.running = false;
                    });
                }).catch(function(error) {
                    console.log(error);
                    var message = "Your domain can not be deleted at this time";
                    if (!_.isNull(vm.domain.status.ssl)) {
                        message += ", however your SSL/TLS certificate has been removed";
                    }
                    message += ". Please contact support@scientiamobile.com for assistance.";
                    vm.generalError = message;
                    vm.running = false;
                });
            } else {
                vm.generalError = "Your domain can not be deleted at this time. Please contact support@scientiamobile.com for assistance.";
                vm.running = false;
            }
        }

        function submit() {
            vm.running = true;
            if (domain.wit_certificate.length !== 0) {
                angular.forEach(domain.wit_certificate, function(obj, key) {
                    certificatePromises.push(
                        witAccountService.patchDeleteCertificate($stateParams.id, obj.id, {delete: true, cancel: false})
                            .then(function(response) {})
                            .catch(function(error) {
                                certificateFail = true;
                                $q.reject();
                            })
                    );
                });
                certificatePromises.push(
                    witAccountService.deleteUnstoredCertificate($stateParams.id, domain.id)
                        .then(function(response) {})
                        .catch(function(error) {
                            certificateFail = true;
                            $q.reject();
                        })
                );
                $q.all(certificatePromises).then(function() {
                    finish();
                });
            } else {
                finish();
            }
        }
    }
})();
