(function() {
    'use strict';

    angular.module('adminApp.saas')
        .controller('SaasAccountSearchQuWurflController', SaasAccountSearchQuWurflController);

        SaasAccountSearchQuWurflController.$inject = ['$scope', '$state', 'quwurflAccountService'];

    function SaasAccountSearchQuWurflController($scope, $state, quwurflAccountService) {
        var vm = this;

        vm.go = go;
        vm.loading = true;
        vm.placeholder = "loading subscriptions... please wait";
        vm.search = search;
        vm.subscription_id = undefined;

        var service = quwurflAccountService;

        service.all(service.resourceUrl, {paginate: 100000}).then(function(response) {
            vm.data = response;
            vm.placeholder = vm.successPlaceholder;
        }).catch(function(error) {
            vm.placeholder = 'Action not permitted';
            console.log(error);
        }).finally(function() {
            vm.loading = false;
        });

        function go() {
            $state.go(vm.product+".view", {id: vm.subscription_id});
        }

        function search(value) {
            alert('Not implemented');
            return;
        }
    }
})();
