(function() {
    'use strict';

    angular.module('adminApp.saas')
        .directive('accountInfo', accountInfo);

    accountInfo.$inject = ['$rootScope', '$stateParams', 'accountService', 'Notification', 'witAccountService', 'witModalService'];

    function accountInfo($rootScope, $stateParams, accountService, Notification, witAccountService, witModalService) {
        var directive = {
            restrict: 'AE',
            replace: false,
            templateUrl: '/view/subscriptions.common.account_base_info',
            scope: {
                subscription: '='
            },
            link: link,
            controller: controller
        };

        return directive;

        function link(scope, element, attrs) {
            scope.class = attrs.class;
        }

        function controller($scope) {
            var tier = null;
            var tierIds = null;
            $scope.$watch('subscription', function(newVal) {
                $scope.showTrialExtend = false;
                if (!_.isUndefined($scope.subscription)) {
                    if ($scope.subscription.type == 'imgeng') {
                        tier = witAccountService.remapLimits($scope.subscription, accountService.getValues('wit', 'plans'), true);
                        tierIds = angular.copy(tier.ids);
                        delete tier.ids;
                        var now = moment();
                        var trialExpire = moment($scope.subscription.trial_expiration_date);
                        $scope.trialExpire = trialExpire.diff(now, 'd');
                        if ($scope.trialExpire < 16) {
                            $scope.showTrialExtend = true;
                        }

                        var expiration = moment($scope.subscription.date_expiration);
                        $scope.accountExpire = expiration.diff(now, 'd');
                    }
                }
            });

            $scope.extendTrial = extendTrial;

            function extendTrial() {
                witModalService.openTrialDaysCalendar($scope.subscription.date_started, $scope.subscription.plan.trial_days)
                    .result.then(function(response) {
                        if (moment() > moment(response)) {
                            Notification.warning("The requested trial days would end the trial period. If this is desired, please change the account type instead.");
                            return;
                        } else {
                            tier.trial_days.value = moment(response).startOf('day').diff(moment($scope.subscription.date_started).startOf('day'), 'd') + 1;
                            witAccountService.updateDefaults($stateParams.id, tier, tierIds).then(function(response) {
                                accountService.clear();
                                Notification.success(response.message);
                                $rootScope.$broadcast('reloadWitDefaults', {data: response.data});
                                $rootScope.$broadcast('reloadWit', {});
                            }).catch(function(error) {
                                Notification.error(error.data.message);
                                console.log(error);
                            });
                        }
                    });
            }
        }
    }
})();
