(function() {
    'use strict';

    angular.module('adminApp.home')
        .controller('HomeSidebarController', HomeSidebarController);

    HomeSidebarController.$inject = ['$ngConfirm', '$uibModal'];

    function HomeSidebarController($ngConfirm, $uibModal) {
        var vm = this;

        vm.openModal = openModal;
        vm.show = false;
        vm.status = {
            devAdmin: false,
            devResources: false,
            external: false,
            kibana: false,
            sales: false,
            testing: false
        };

        function action(path) {
            switch (path) {
                case 'sales':
                    return {
                        title: 'Sales/Marketing',
                        url: 'view/layouts.app_menus.sales'
                    };
                    break;
                case 'testing':
                    return {
                        title: 'WURFL Data and API Testing/Management',
                        url: 'view/layouts.app_menus.wurfl_data_and_api_testing'
                    };
                    break;
                case 'devAdmin':
                    return {
                        title: 'Developer Administration',
                        url: 'view/layouts.app_menus.dev_admin'
                    };
                    break;
                case 'external':
                    return {
                        title: 'External <small>apps</small>',
                        url: 'view/layouts.app_menus.external'
                    };
                    break;
                case 'kibana':
                    return {
                        title: 'Kibana Reports',
                        url: 'view/layouts.app_menus.kibana'
                    };
                    break;
                case 'devResources':
                    return {
                        title: 'Developer Resources',
                        url: 'view/layouts.app_menus.developer_resources'
                    };
                    break;
                case 'monitoring':
                    return {
                        title: 'Support and Monitoring',
                        url: 'view/layouts.app_menus.monitoring'
                    };
                    break;
                case 'capabilities':
                    return {
                        title: 'WURFL Capabilities',
                        url: 'view/layouts.app_menus.capabilities'
                    };
                    break;
            }
        }

        function openModal(path) {
            var template = action(path);

            $uibModal.open({
                templateUrl: 'view/components.sidebar_modal',
                controller: 'HomeSidebarModalController',
                controllerAs: 'vm',
                keyboard: false,
                backdrop: 'static',
                resolve: {
                    view: function() {
                        return template.url;
                    },
                    title: function() {
                        return template.title
                    }
                }
            });
        }
    }
})();
