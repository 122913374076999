(function() {
    'use strict';

    angular.module('adminApp.cloud')
        .controller('CloudSearchController', CloudSearchController);

    CloudSearchController.$inject = ['$stateParams', 'breadcrumbsService', 'cloudAccountService'];

    function CloudSearchController($stateParams, breadcrumbsService, cloudAccountService) {
        var vm = this;

        vm.keyword = $stateParams.keyword;
        vm.listState = "cloud.list";
        vm.metaInfoLabel = 'API Key';
        vm.searchState = "cloud.search";
        vm.service = cloudAccountService;
        vm.steps = breadcrumbsService.get('cloud.search');
        vm.tableType = 'cloudSearch';
    }
})();
