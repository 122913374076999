(function() {
    'use strict';

    angular.module('adminApp.wit')
        .controller('QuickbooksController', QuickbooksController);

    QuickbooksController.$inject = ['$state', '$stateParams', '$uibModalInstance', 'Notification', 'witAccountService'];

    function QuickbooksController($state, $stateParams, $uibModalInstance, Notification, witAccountService) {
        var vm = this;

        vm.close = close;
        vm.token = null;

        witAccountService.refresh($stateParams.code, $stateParams.realmId).then(function(response) {
            vm.token = response.data.token;
        }).catch(function(error) {
            console.log(error);
            Notification.error("Could not generate new refresh token");
        });

        function close() {
            $state.go('home');
            $uibModalInstance.dismiss();
        }
    }
})();
