(function() {
    'use strict';

    angular.module('adminApp.users')
        .factory('teamService', teamService);

    teamService.$inject = ['$resource'];

    function teamService($resource) {
        var resource = new $resource('/api/admin/users/teams/:id/:action', {id: '@id'}, {
            create: {
                method: 'POST',
                params: {id: null}
            },
            delete: {
                method: 'DELETE',
                params: {id: null}
            },
            edit: {
                method: 'PUT',
                params: {id: null}
            },
            users: {
                method: 'GET',
                params: {id: null, action: "users"},
            },
            subscriptions: {
                method: 'GET',
                params: {id: null, action: "subscriptions"},
            },
        });

        var service = {
            all: all,
            byId: byId,
            create: create,
            delete: deleteTeam,
            edit: edit,
            users: users,
            subscriptions: subscriptions,
        };

        return service;

        function all(options) {
            return resource.get(options).$promise;
        }

        function byId(id) {
            return resource.get({id: id}).$promise;
        }

        function create(form) {
            return resource.create({}, form).$promise;
        }

        function deleteTeam(id) {
            return resource.delete({id: id}).$promise;
        }

        function edit(id, form) {
            return resource.edit({id: id}, form).$promise;
        }

        function users(teamId) {
            return resource.users({id: teamId}).$promise;
        }

        function subscriptions(teamId) {
            return resource.subscriptions({id: teamId}).$promise;
        }
    }
})();