(function() {
    'use strict';

    angular.module('adminApp.users')
        .controller('UsersTransferAccountsController', UsersTransferAccountsController);

    UsersTransferAccountsController.$inject = ['$filter', '$ngConfirm', '$rootScope', '$scope', '$state', '$stateParams', '$uibModalInstance', 'accountCounts', 'accountLabels', 'baseAccountService', 'id', 'licenseService', 'Notification', 'remove', 'revoke', 'userService'];

    function UsersTransferAccountsController($filter, $ngConfirm, $rootScope, $scope, $state, $stateParams, $uibModalInstance, accountCounts, accountLabels, baseAccountService, id, licenseService, Notification, remove, revoke, userService) {
        var vm = this;

        vm.accountAdded = {
            licenses: {},
            subscriptions: {}
        };
        vm.accountCounts = accountCounts;
        vm.accountError = null;
        vm.accounts = [];
        vm.accountLabels = accountLabels;
        vm.accountsLoading = true;
        vm.activeGroup = 1;
        vm.addAnotherGroup = addAnotherGroup;
        vm.addToActiveGroup = addToActiveGroup;
        vm.allLoading = {
            accounts: true,
            users: true
        };
        vm.changeWhichAccounts = changeWhichAccounts;
        vm.checkboxDisabled = checkboxDisabled;
        vm.close = close;
        vm.continue = continueOn;
        vm.currentGroup = 0;
        vm.displayAccounts = false;
        vm.error = null;
        vm.getClass = getClass;
        vm.loading = true;
        vm.modalOptions = {
            actionClass: 'alert-info',
            actionMessage: 'Transferring...',
            cancelButton: {
                btnClass: 'btn-custom-default',
                btnSpanClass: 'glyphicon-remove',
                btnText: 'Cancel'
            },
            includeView: 'view/users.modals.transfer',
            proceedButton: {
                btnClass: 'btn-primary',
                btnSpanClass: 'glyphicon-transfer',
                btnText: 'Transfer'
            },
            title: 'Transfer User Accounts'
        }
        vm.multiSelect = [];
        vm.removeAccount = removeAccount;
        vm.selectClass = null;
        vm.selected = [];
        vm.selectedUser = null;
        vm.showAccounts = showAccounts;
        vm.showAccountTitle = "Show Individual Accounts";
        vm.showActionSpinner = false;
        vm.status = {
            accounts: false,
            licenses: false,
            subscriptions: false
        };
        vm.transferComplete = {
            licenses: false,
            subscriptions: false
        };
        vm.transferTo = 'single';
        vm.whichAccounts = {
            all: {
                checked: true,
                label: 'All'
            }
        };

        var userId = $stateParams.id === undefined ? id : $stateParams.id;

        if (accountCounts === null || accountLabels === null) {
            vm.accountLabels = {
                data: 'Data',
                infuze: 'InFuze',
                onsite: 'OnSite',
                insight: 'InSight',
                cloud: 'Cloud',
                wjs: 'WURFL.js',
                imgeng: 'ImageEngine'
            };
            userService.getAccountCount(userId).then(function(response) {
                vm.accountCounts = response.data;
                angular.forEach(vm.accountLabels, function(val, key) {
                    if (vm.accountCounts[key] == undefined) {
                        vm.accountCounts[key] = {
                            active: 0,
                            total: 0
                        };
                    }
                });
                vm.accountsLoading = false;
                createWhichAccounts();
            }).catch(function(error) {
                Notification.error('Could not determine user accounts');
            });
        } else {
            createWhichAccounts();
        }

        function createWhichAccounts() {
            angular.forEach(vm.accountLabels, function(val, key) {
                vm.whichAccounts[key] = {
                    checked: false,
                    label: val,
                    accounts: {}
                };
            });
            angular.forEach(vm.accountCounts, function(val, key) {
                if (val.total != 0) {
                    vm.whichAccounts[key].checked = true;
                }
            });
            vm.allLoading.accounts = false;
        }

        var allAccounts = [];
        var transferUser = null;

        var licenses = [];
        var licensesComplete = 0;
        var successLicenses = 0;
        var failedLicenses = 0;
        var failuresLicenses = [];

        var subscriptions = [];
        var subscriptionsComplete = 0;
        var successSubscriptions = 0;
        var failedSubscriptions = 0;
        var failuresSubscriptions = [];

        $rootScope.$on('usersDirectiveLoaded', function(event, args) {
            vm.users = args.users;
            angular.forEach(vm.users, function(val, key) {
                val.name = val.name.trim();
            });
            vm.allLoading.users = false;
        });

        $scope.$watch('vm.allLoading', function(newVal) {
            if (!newVal.accounts && !newVal.users) {
                vm.loading = false;
            } else {
                vm.loading = true;
            }
        }, true);

        $scope.$watch('vm.selectedUser', function(newVal, oldVal) {
            if (newVal !== null) {
                vm.selectClass = null;
                vm.error = null;
                if (!_.isObject(newVal)) {
                    var user = _.findWhere(vm.users, {name: newVal});
                    if (user !== undefined) {
                        transferUser = user;
                    } else {
                        transferUser = null;
                    }
                } else {
                    transferUser = newVal;
                }
            }
        });

        $scope.$watch('vm.transferComplete', function(newVal, oldVal) {
            if (vm.transferComplete.licenses && vm.transferComplete.subscriptions) {
                if (successLicenses != 0) {
                    Notification.success(successLicenses+' licenses transfered successfully');
                }
                if (failedLicenses != 0) {
                    Notification.error(failedLicenses+' licenses failed to transfer: '+failuresLicenses.join(', '));
                }
                if (successSubscriptions != 0) {
                    Notification.success(successSubscriptions+' subscriptions transfered successfully');
                }
                if (failedSubscriptions != 0) {
                    Notification.error(failedSubscriptions+' subscriptions failed to transfer: '+failuresSubscriptions.join(', '));
                }
                $uibModalInstance.dismiss();
                $rootScope.$broadcast('transferredAccounts');
                vm.showActionSpinner = false;
                if (revoke) {
                    $rootScope.$broadcast('revokeUser', {id: $stateParams.id});
                } else if (remove) {
                    $rootScope.$broadcast('removeUser', {id: $stateParams.id});
                } else {
                    $state.go('users.view', {id: $stateParams.id});
                }
            }
        }, true);

        $scope.$watch('vm.transferTo', function(newVal, oldVal) {
            vm.selectedUser = null;
            vm.selected = [];
            vm.multiSelect = [];
            vm.accountAdded = {
                licenses: {},
                subscriptions: {}
            };
            transferUser = null;
            if (newVal == 'multiple') {
                vm.displayAccounts = true;
                angular.forEach(vm.whichAccounts, function(val, key) {
                    val.checked = false;
                    angular.forEach(val.accounts, function(iVal, iKey) {
                        val.accounts[iKey] = false;
                    });
                });
            } else if (newVal == 'single' && oldVal == 'multiple') {
                vm.displayAccounts = false;
                angular.forEach(vm.whichAccounts, function(val, key) {
                    if (key == 'all' ||
                        (vm.accountCounts[key] !== undefined && vm.accountCounts[key].total > 0)
                    ) {
                        val.checked = true;
                        angular.forEach(val.accounts, function(iVal, iKey) {
                            val.accounts[iKey] = true;
                        });
                    }
                });
            }
        });

        userService.gatherAccounts(userId).then(function(response) {
            allAccounts = response.data;
            angular.forEach(response.data.licenses, function(val, key) {
                var name = val.product.product_name;
                var expire = $filter('createDate')({date: val.license_expire, format: 'YMD'});
                var expired = false;
                if (expire < new Date()) {
                    name += ' (expired)';
                    expired = true;
                } else if (val.revoked) {
                    name += ' (revoked)';
                }
                vm.accounts.push({
                    group: 'license',
                    product_name: name,
                    license_type_name: val.license_type.license_type_name,
                    expired: expired,
                    revoked: val.revoked,
                    type: val.product.type,
                    licenseId: val.id
                });
            });
            angular.forEach(response.data.subscriptions, function(val, key) {
                var name = val.account_name + " - " + val.plan.label;
                var expired = false;
                if (val.date_expiration !== null) {
                    var expire = $filter('createDate')({date: val.date_expiration, format: 'YMD'});
                }
                if (expire !== undefined && expire < new Date()) {
                    name += ' (expired)';
                    expired = true;
                } else if (val.status == 'SUSPENDED') {
                    name += ' (suspended)';
                } else if (val.status == 'CANCELED') {
                    name += ' (canceled)';
                }
                vm.accounts.push({
                    group: 'subscription',
                    plan_name: name,
                    expired: expired,
                    status: val.status,
                    type: val.type,
                    subscriptionId: val.id
                });
            });
            vm.accountsLoading = false;
            angular.forEach(vm.accounts, function(val, key) {
                var value = null;
                if (val.licenseId !== undefined) {
                    value = val.licenseId;
                } else {
                    value = val.subscriptionId;
                }
                vm.whichAccounts[val.type].accounts[value] = true;
            });
        }).catch(function(error) {
            console.log(error);
        });

        function addAnotherGroup() {
            if (!_.isObject(transferUser)) {
                vm.error = 'error';
            } else if (_.isEmpty(vm.selected)) {
                vm.accountError = 'error';
            } else {
                vm.multiSelect.push({
                    user: transferUser,
                    accounts: vm.selected
                });

                angular.forEach(vm.selected.licenses, function(val, key) {
                    vm.accountAdded.licenses[val.id] = true;
                    vm.whichAccounts[val.product.type].accounts[val.id] = false;
                });
                angular.forEach(vm.selected.subscriptions, function(val, key) {
                    vm.accountAdded.subscriptions[val.id] = true;
                    vm.whichAccounts[val.type].accounts[val.id] = false;
                });
                vm.selected = [];
                vm.selectedUser = null;
            }
        }

        function addToActiveGroup() {
            vm.accountError = false;
            var selectedAccounts = {
                licenses: [],
                subscriptions: []
            };
            angular.forEach(allAccounts.licenses, function(val, key) {
                if (vm.whichAccounts[val.product.type].accounts[val.id]) {
                    selectedAccounts.licenses.push(val);
                }
            });
            angular.forEach(allAccounts.subscriptions, function(val, key) {
                if (vm.whichAccounts[val.type].accounts[val.id]) {
                    selectedAccounts.subscriptions.push(val);
                }
            });
            vm.selected = selectedAccounts;
        }

        function changeWhichAccounts(type, id) {
            if (type == 'all') {
                if (vm.whichAccounts.all.checked) {
                    angular.forEach(vm.whichAccounts, function(val, key) {
                        if (vm.accountCounts[key] !== undefined && vm.accountCounts[key].total > 0) {
                            vm.whichAccounts[key].checked = true;
                            angular.forEach(val.accounts, function(iVal, iKey) {
                                if (
                                    _.find(_.keys(vm.accountAdded.licenses), function(indx) {
                                        return iKey.toString() == indx;
                                    }) === undefined &&
                                    _.find(_.keys(vm.accountAdded.subscriptions), function(indx) {
                                        return iKey.toString() == indx;
                                    }) === undefined
                                ) {
                                    val.accounts[iKey] = true;
                                }
                            });
                        }
                    });
                } else {
                    angular.forEach(vm.whichAccounts, function(val, key) {
                        vm.whichAccounts[key].checked = false;
                        angular.forEach(val.accounts, function(iVal, iKey) {
                            val.accounts[iKey] = false;
                        });
                    });
                }
            } else {
                if (id == undefined) {
                    // Group
                    if (vm.whichAccounts[type].checked) {
                        angular.forEach(vm.whichAccounts[type].accounts, function(val, key) {
                            if (
                                _.find(_.keys(vm.accountAdded.licenses), function(indx) {
                                    return key.toString() == indx;
                                }) === undefined &&
                                _.find(_.keys(vm.accountAdded.subscriptions), function(indx) {
                                    return key.toString() == indx;
                                }) === undefined
                            ) {
                                vm.whichAccounts[type].accounts[key] = true;
                            }
                        });
                        var groups = true;
                        angular.forEach(vm.whichAccounts, function(val, key) {
                            if (!val.checked && vm.accountCounts[key] !== undefined && vm.accountCounts[key].total > 0) {
                                groups = false;
                            }
                        });
                        vm.whichAccounts.all.checked = groups;
                    } else {
                        angular.forEach(vm.whichAccounts[type].accounts, function(val, key) {
                            vm.whichAccounts[type].accounts[key] = false;
                        });
                        vm.whichAccounts.all.checked = false;
                    }
                } else {
                    // Account
                    if (vm.whichAccounts[type].accounts[id]) {
                        var group = true;
                        angular.forEach(vm.whichAccounts[type].accounts, function(val, key) {
                            if (!val) {
                                group = false;
                            }
                        });
                        vm.whichAccounts[type].checked = group;
                        var groups = true;
                        angular.forEach(vm.whichAccounts, function(val, key) {
                            if (!val.checked && vm.accountCounts[key] !== undefined && vm.accountCounts[key].total > 0) {
                                groups = false;
                            }
                        });
                        vm.whichAccounts.all.checked = groups;
                    } else {
                        vm.whichAccounts[type].checked = false;
                        vm.whichAccounts.all.checked = false;
                    }
                }
            }
        }

        function checkboxDisabled(key) {
            if (vm.allLoading.accounts || vm.accountsLoading) {
                return true;
            } else if (key == 'all') {
                return false;
            } else if (vm.accountCounts[key].total == 0) {
                return true;
            }
            return false;
        }

        function close() {
            $uibModalInstance.dismiss();
        }

        function continueOn() {
            if (transferUser == null) {
                vm.selectClass = 'error';
                vm.error = 'error'
                return;
            }
            if (vm.transferTo == 'single') {
                vm.showActionSpinner = true;
                var accountsToTransfer = {
                    licenses: [],
                    subscriptions: []
                };
                angular.forEach(allAccounts.licenses, function(val, key) {
                    if (vm.whichAccounts[val.product.type].checked || vm.whichAccounts[val.product.type].accounts[val.id]) {
                        accountsToTransfer.licenses.push(val);
                    }
                });

                angular.forEach(allAccounts.subscriptions, function(val, key) {
                    if (vm.whichAccounts[val.type].checked || vm.whichAccounts[val.type].accounts[val.id]) {
                        accountsToTransfer.subscriptions.push(val);
                    }
                });

                if (_.isEmpty(accountsToTransfer.licenses)) {
                    vm.transferComplete.licenses = true;
                }

                if (_.isEmpty(accountsToTransfer.subscriptions)) {
                    vm.transferComplete.subscriptions = true;
                }
                runTransfer(accountsToTransfer, transferUser.id, accountsToTransfer.licenses.length, accountsToTransfer.subscriptions.length);
            } else {
                if (!_.isEmpty(vm.selected) || vm.selectedUser !== null) {
                    addAnotherGroup();
                }
                var numLicenses = _.reduce(
                    _.map(vm.multiSelect, function(item) {
                        return _.size(item.accounts.licenses);
                    }), function(memo, num){
                        return memo + num;
                    }, 0);
                var numSubscriptions = _.reduce(
                    _.map(vm.multiSelect, function(item) {
                        return _.size(item.accounts.subscriptions);
                    }), function(memo, num){
                        return memo + num;
                    }, 0);

                if (numLicenses == 0 && numSubscriptions == 0) {
                    vm.accountError = 'error';
                    return;
                }
                if (numLicenses == 0) {
                    vm.transferComplete.licenses = true;
                }
                if (numSubscriptions == 0) {
                    vm.transferComplete.subscriptions = true;
                }
                angular.forEach(vm.multiSelect, function(item, key) {
                    runTransfer(item.accounts, item.user.id, numLicenses, numSubscriptions);
                });
            }
        }

        function getClass(account) {
            if (account.expired) {
                return 'text-danger';
            }
            if (account.revoked) {
                return 'text-danger';
            }
            if (account.status == 'CANCELED' || account.status == 'SUSPENDED') {
                return 'text-danger';
            }
        }

        function removeAccount(type, account) {
            vm.selected[type]= _.reject(vm.selected[type], function(val) {
                return val.id == account.id;
            });

            if (account.product !== undefined) {
                vm.whichAccounts[account.product.type].accounts[account.id] = false;
            } else {
                vm.whichAccounts[account.type].accounts[account.id] = false;
            }
        }

        function showAccounts() {
            vm.displayAccounts = vm.displayAccounts ? false : true;
            vm.showAccountTitle = !vm.displayAccounts ? "Show Individual Accounts" : "Hide Individual Accounts";
        }

        function runTransfer(accountsToTransfer, userId, numLicenses, numSubscriptions) {
            if (remove && (numLicenses !== allAccounts.licenses.length || numSubscriptions !== allAccounts.subscriptions.length)) {
                $ngConfirm({
                    title: 'Alert!',
                    theme: 'dark',
                    content: 'All accounts must be transfered in order to process a user deletion. Please select a user(s) for all accounts.',
                    buttons: {
                        close: {
                            text: 'OK',
                            btnClass: 'btn-danger'
                        }
                    }
                });
                vm.showActionSpinner = false;
                return;
            }
            angular.forEach(accountsToTransfer.licenses, function(val, key) {
                licenseService.transfer({id: val.id}, {id: userId}).then(function(response) {
                    licenses.push({id: val.id, success: true});
                }).catch(function(error) {
                    console.log(error);
                    licenses.push({id: val.id, success: false});
                }).finally(function() {
                    licensesComplete++;
                    if (licensesComplete == numLicenses) {
                        angular.forEach(licenses, function(val, key) {
                            if (val.success) {
                                successLicenses++;
                            } else {
                                failedLicenses++;
                                failuresLicenses.push(val.id);
                            }
                        });
                        vm.transferComplete.licenses = true;
                    }
                });
            });
            angular.forEach(accountsToTransfer.subscriptions, function(val, key) {
                baseAccountService.transfer(val.id, userId).then(function(response) {
                    subscriptions.push({id: val.id, success: true});
                }).catch(function(error) {
                    console.log(error);
                    subscriptions.push({id: val.id, success: false});
                }).finally(function() {
                    subscriptionsComplete++;
                    if (subscriptionsComplete == numSubscriptions) {
                        angular.forEach(subscriptions, function(val, key) {
                            if (val.success) {
                                successSubscriptions++;
                            } else {
                                failedSubscriptions++;
                                failuresSubscriptions.push(val.id);
                            }
                        });
                        vm.transferComplete.subscriptions = true;
                    }
                });
            });
        }
    }
})();
