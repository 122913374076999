(function() {
    'use strict';

    angular.module('adminApp.wit')
        .controller('WitTrialStatisticsController', WitTrialStatisticsController);

    WitTrialStatisticsController.$inject = ['$q', '$rootScope', '$scope', 'breadcrumbsService', 'paginationService', 'witAccountService', 'witModalService', 'witStatisticsService'];

    function WitTrialStatisticsController($q, $rootScope, $scope, breadcrumbsService, paginationService, witAccountService, witModalService, witStatisticsService) {
        var vm = this;

        vm.accordion = {
            statistics: true,
            stages: false
        };
        vm.accounts = null;
        vm.activeTab = 0;
        vm.addAccount = addAccount;
        vm.addAll = addAll;
        vm.breakdown = {
            bandwidth: false,
            cache: false,
            formats: false,
            requests: false
        };
        vm.chartLoading = false;
        vm.charts = {
            bandwidth: null,
            cache: null,
            format: null,
            payload: null,
            request: null
        };
        vm.chunk = 0;
        vm.disableAddButton = disableAddButton;
        vm.download = download;
        vm.downloadPng = downloadPng;
        vm.downloading = false;
        vm.end = moment().utc().endOf('day').format('YYYY-MM-DD HH:mm:ss');
        vm.error = false;
        vm.itemsPerPage = null;
        vm.loaded = {
            stages: false,
            trials: false
        };
        vm.loading = false;
        vm.openDailyBreakdown = openDailyBreakdown;
        vm.openDateRangePicker = openDateRangePicker;
        vm.openUpdateDateRange = openUpdateDateRange;
        vm.paginationData = null;
        vm.readyHandler = readyHandler;
        vm.refreshStatistics = refreshStatistics;
        vm.removeAll = removeAll
        vm.removeFromDisplay = removeFromDisplay;
        vm.search = null;
        vm.selected = false;
        vm.selectedAccounts = [];
        vm.selectPage = selectPage;
        vm.selectPageTraffic = selectPageTraffic;
        vm.selectPageTrialLinks = selectPageTrialLinks;
        vm.show = {
            cache: 'count',
            bandwidth: 'date'
        }
        vm.showAddButton = showAddButton;
        vm.sort = sort;
        vm.stagesError = false;
        vm.start = moment().utc().subtract(90, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss');
        vm.steps = breadcrumbsService.get('wit.statistics');
        vm.tokens = null;
        vm.traffic = null;
        vm.trafficBreaks = witStatisticsService.trafficBreaks;
        vm.trafficChunk = 0;
        vm.trafficSearch = null;
        vm.trafficSort = trafficSort;
        vm.trialLinks = null;
        vm.trialLinksChunk = 0;
        vm.trialLinksPagination = null;
        vm.trialLinksSearch = null;
        vm.trialLinksSort = trialLinksSort;
        vm.updateBandwidth = updateBandwidth;
        vm.updateCache = updateCache;
        vm.updateFormats = updateFormats;
        vm.updatePerPage = updatePerPage;
        vm.updateRequests = updateRequests;
        vm.updatingStats = false;
        vm.viewTokens = viewTokens;

        var addAllData = {
            length: null,
            accounts: null,
            current: null
        };

        runGetTrials();

        $scope.$watch('vm.search', function(newVal) {
            if (!_.isNull(witStatisticsService.allAccounts)) {
                witStatisticsService.filterSearch(vm.search);
                var perPage = _.findWhere(vm.itemsPerPage, {selected: true}).value;
                setPagination(perPage, 1);
            }
        }, true);

        $scope.$watch('vm.trafficSearch', function(newVal) {
            if (!_.isNull(witStatisticsService.allTraffic)) {
                witStatisticsService.filterSearchTraffic(vm.trafficSearch);
                setPaginationTraffic(1);
            }
        }, true);

        $scope.$watch('vm.trialLinksSearch', function(newVal) {
            if (!_.isNull(witStatisticsService.allTrialLinks)) {
                witStatisticsService.filterSearchTrialLinks(vm.trialLinksSearch);
                setPaginationTrialLinks(1);
            }
        }, true);

        function addAccount(account, broadcast) {
            broadcast = _.isUndefined(broadcast) ? false : broadcast;
            vm.chartLoading = true;
            witStatisticsService.addAccount(
                account,
                vm.charts,
                vm.breakdown,
                vm.show.cache,
                vm.start,
                vm.end,
                vm.selectedAccounts
            ).then(function(response) {
                vm.charts.bandwidth.data = response.data.bandwidth;
                updateCache(vm.show.cache, false);
                vm.charts.formats.data = response.data.formats;
                vm.charts.payload.data.rows = response.data.payload.rows;
                if (!_.isUndefined(response.data.payload.columns)) {
                    vm.charts.payload.data.columns = response.data.payload.columns;
                }
                vm.charts.request.data = response.data.requests;

                if (response.error) {
                    vm.error = true;
                }

                vm.selectedAccounts.push(account);
                vm.chartLoading = false;

                if (broadcast) {
                    finishAdd();
                }
            });
        }

        function finishAdd() {
            addAllData.current++;
            if (addAllData.current != addAllData.length) {
                addAccount(addAllData.accounts[addAllData.current], true)
            } else {
                addAllData.current = 0;
            }
        }

        function addAll() {
            var idsToAdd = _.filter(witStatisticsService.originalStatsIds, function(id) {
                return _.isUndefined(_.find(vm.selectedAccounts, function(obj) {
                    return obj.id == id;
                }))
            });
            addAllData.accounts = _.filter(witStatisticsService.allAccounts, function(obj) {
                return _.indexOf(idsToAdd, obj.id) !== -1;
            });
            addAllData.current = 0;
            addAllData.length = addAllData.accounts.length;

            addAccount(addAllData.accounts[addAllData.current], true);
        }

        function disableAddButton(id) {
            return !_.isUndefined(_.findWhere(vm.selectedAccounts, {id: id}));
        }

        function download(type) {
            vm.downloading = true;
            switch (type) {
                case 'payload':
                    var breakdown = vm.breakdown.bandwidth;
                    break;
                default:
                    var breakdown = vm.breakdown[type];
            }
            var download = witStatisticsService.downloadData(vm.start, vm.end, vm.show, vm.charts, type, breakdown);
            download.then(function() {
                vm.downloading = false;
            });
        }

        function downloadPng(chart) {
            var uri = witStatisticsService.download[chart];
            witModalService.openDownloadTrialStats(uri);
        }

        function openDailyBreakdown(trial) {
            if (trial.token === trial.identifier) {
                var account = _.find(witStatisticsService.allAccounts, function(obj) {
                    return obj.token == trial.identifier;
                });
            } else {
                var account = _.find(witStatisticsService.allAccounts, function(obj) {
                    return obj.id == trial.identifier;
                });
            }

            witModalService.openDailyTrafficBreakdown(trial, account, vm.start, vm.end);
        }

        function openDateRangePicker(type) {
            var search = null;
            switch (type) {
                case 'claimed_at':
                    search = angular.copy(vm.trialLinksSearch.claimed_at);
                    break;
                case 'registered':
                    search = angular.copy(vm.trafficSearch.registered);
                    break;
                default:
                    search = angular.copy(vm.search[type]);
            }

            var modal = witModalService.openDateRangeStats(search, type);
            modal.result.then(function(response) {
                switch (type) {
                    case 'claimed_at':
                        vm.trialLinksSearch.claimed_at = response;
                        break;
                    case 'registered':
                        vm.trafficSearch.registered = response;
                        break;
                    default:
                        vm.search[type] = response;
                }
            });
        }

        function openUpdateDateRange() {
            var modal = witModalService.openUpdateDateRangeStats(angular.copy(vm.start), angular.copy(vm.end));
            modal.result.then(function(response) {
                vm.start = response.start;
                vm.end = response.end;
                runGetTrials();
            });
        }

        function readyHandler(chart, chartWrapper) {
            var uri = chartWrapper.getChart().getImageURI();
            witStatisticsService.download[chart] = uri;
        }

        function refreshStatistics() {
            runGetTrials();
        }

        function removeAll() {
            vm.selectedAccounts = [];
            vm.charts.bandwidth.data = angular.copy(witStatisticsService.bandwidthChart.data);
            vm.charts.payload.data = angular.copy(witStatisticsService.payloadChart.data);
            vm.charts.cache.data = angular.copy(witStatisticsService.cacheChart.data);
            vm.charts.formats.data = angular.copy(witStatisticsService.formatChart.data);
            vm.charts.request.data = angular.copy(witStatisticsService.requestChart.data);
        }

        function removeFromDisplay(id) {
            vm.selectedAccounts = _.reject(vm.selectedAccounts, function(obj) {
                return obj.id == id;
            });

            if (vm.selectedAccounts.length == 0) {
                vm.charts.bandwidth.data = angular.copy(witStatisticsService.bandwidthChart.data);
                vm.charts.payload.data = angular.copy(witStatisticsService.payloadChart.data);
                vm.charts.cache.data = angular.copy(witStatisticsService.cacheChart.data);
                vm.charts.formats.data = angular.copy(witStatisticsService.formatChart.data);
                vm.charts.request.data = angular.copy(witStatisticsService.requestChart.data);

                return;
            }

            vm.charts.bandwidth.data = witStatisticsService.removeBandwidth(
                id, vm.charts.bandwidth.data,
                vm.breakdown.bandwidth
            );

            vm.charts.payload.data = witStatisticsService.removePayload(
                vm.charts.payload.data,
                id, vm.breakdown.bandwidth
            );

            vm.charts.cache.data = witStatisticsService.removeCache(
                vm.charts.cache.data, id, vm.breakdown.cache
            );

            vm.charts.formats.data = witStatisticsService.removeFormat(
                vm.charts.formats.data, id, vm.breakdown.formats
            );

            vm.charts.request.data = witStatisticsService.removeRequest(
                vm.charts.request.data, id,
                vm.breakdown.requests
            );
        }

        function runCheckLoaded() {
            if (vm.loaded.trials && vm.loaded.stages) {
                vm.loading = false;
            }
        }

        function runGetTrials() {
            vm.activeTab = 0;
            vm.breakdown = {
                bandwidth: false,
                cache: false,
                formats: false,
                requests: false
            };
            vm.charts.bandwidth = angular.copy(witStatisticsService.bandwidthChart);
            vm.charts.cache = angular.copy(witStatisticsService.cacheChart);
            vm.chunk = 0;
            vm.charts.formats = angular.copy(witStatisticsService.formatChart);
            vm.itemsPerPage = angular.copy(witStatisticsService.itemsPerPage);
            vm.loaded = {
                stages: false,
                trials: false
            };
            vm.charts.payload = angular.copy(witStatisticsService.payloadChart);
            vm.charts.request = angular.copy(witStatisticsService.requestChart);
            vm.search = angular.copy(witStatisticsService.search);
            vm.selected = false;
            vm.selectedAccounts = [];
            vm.trafficSearch = angular.copy(witStatisticsService.trafficSearch);
            vm.trialLinksSearch = angular.copy(witStatisticsService.trialLinksSearch);

            witStatisticsService.originalStatsIds = null;
            witStatisticsService.selectedAccounts = {}

            vm.loading = true;
            witAccountService.getAllTrials(vm.start, vm.end)
                .then(function(response) {
                    var originalAccounts = angular.copy(response.data.accounts);
                    vm.tokens = response.data.tokens;
                    witStatisticsService.filteredIds = angular.copy(response.data.filtered_ids);
                    response.data.accounts = _.map(response.data.accounts, function(obj) {
                        if (!_.isNull(obj.domains)) {
                            obj.domains = obj.domains.split(",");
                        }
                        return obj;
                    });
                    witStatisticsService.allAccounts = angular.copy(response.data.accounts);
                    witStatisticsService.filteredAccounts = angular.copy(response.data.accounts);
                    witStatisticsService.accountsWithData = angular.copy(response.data.keep);
                    witStatisticsService.compileAccounts();
                    witStatisticsService.filterSearch(vm.search);

                    setPagination(10, 1);

                    witAccountService.postTrialStageStatistics(vm.start, vm.end, response.data.keep)
                        .then(function(response) {
                            response.data.accounts = _.map(response.data.accounts, function(obj) {
                                if (!_.isNull(obj.domains)) {
                                    obj.domains = obj.domains.split(",");
                                }
                                return obj;
                            });
                            response.data.links = _.map(response.data.links, function(obj) {
                                if (!_.isNull(obj.domains)) {
                                    obj.domains = obj.domains.split(",");
                                }
                                return obj;
                            });
                            vm.traffic = _.chunk(response.data.accounts, 10);
                            vm.trialLinks = _.chunk(response.data.links, 10);
                            witStatisticsService.filteredTraffic = angular.copy(response.data.accounts);
                            witStatisticsService.allTraffic = angular.copy(response.data.accounts);
                            witStatisticsService.filteredTrialLinks = angular.copy(response.data.links);
                            witStatisticsService.allTrialLinks = angular.copy(response.data.links);

                            setPaginationTrialLinks(1);
                            setPaginationTraffic(1);
                        }).catch(function(error) {
                            console.log(error);
                            vm.stagesError = true;
                        }).finally(function() {
                            vm.loading = false;
                        });
                }).catch(function(error) {
                    console.log(error);
                    vm.error = true;
                    vm.loading = false;
                });

        }

        function selectPage(page) {
            vm.chunk = page - 1;
            var perPage = _.findWhere(vm.itemsPerPage, {selected: true}).value;
            setPagination(perPage, page);
        }

        function selectPageTrialLinks(page) {
            vm.trialLinksChunk = page - 1;
            setPaginationTrialLinks(page);
        }

        function selectPageTraffic(page) {
            vm.trafficChunk = page - 1;
            setPaginationTraffic(page);
        }

        function showAddButton(id) {
            return _.indexOf(witStatisticsService.originalStatsIds, id) !== -1;
        }

        function setPagination(itemsPerPage, current) {
            var pagination = witStatisticsService.updatePagination(itemsPerPage, current);
            vm.accounts = pagination.accounts;
            vm.paginationData = pagination.paginationData;
            vm.chunk = pagination.chunk;
        }

        function setPaginationTraffic(current) {
            var pagination = witStatisticsService.updatePaginationTraffic(current);
            vm.traffic = pagination.traffic;
            vm.trafficChunk = pagination.chunk;
            vm.trafficPagination = pagination.paginationData;
        }

        function setPaginationTrialLinks(current) {
            var pagination = witStatisticsService.updatePaginationTrialLinks(current);
            vm.trialLinks = pagination.links;
            vm.trialLinksChunk = pagination.chunk;
            vm.trialLinksPagination = pagination.paginationData;
        }

        function sort(name) {
            witStatisticsService.sort(name);
            var perPage = _.findWhere(vm.itemsPerPage, {selected: true}).value;
            setPagination(perPage, 1);
            $rootScope.$broadcast('changetablearrows', {name: name, direction: witStatisticsService.currentSort.direction});
        }

        function trafficSort(name) {
            witStatisticsService.trafficSort(name);
            setPaginationTraffic(1);
            $rootScope.$broadcast('changetablearrows', {name: name, direction: witStatisticsService.trafficCurrentSort.direction});
        }

        function trialLinksSort(name) {
            witStatisticsService.trialLinksSort(name);
            setPaginationTrialLinks(1);
            $rootScope.$broadcast('changetablearrows', {name: name, direction: witStatisticsService.trialLinksCurrentSort.direction});
        }

        function updateBandwidth(type) {
            if (!_.isUndefined(type)) {
                vm.show.bandwidth = type;
            } else {
                if (vm.breakdown.bandwidth) {
                    var breakdown = witStatisticsService.setBandwidthBreakdown(vm.selectedAccounts);
                    vm.charts.bandwidth.data.cols = breakdown.cols;
                    vm.charts.bandwidth.data.rows = breakdown.rows;

                    vm.charts.payload.data.rows = witStatisticsService.setPayloadBreakdown(vm.selectedAccounts);
                } else {
                    var bandwidth = witStatisticsService.setBandwidth(vm.selectedAccounts, true);
                    vm.charts.bandwidth.data.cols = bandwidth.cols;
                    vm.charts.bandwidth.data.rows = bandwidth.rows;

                    var payload = witStatisticsService.setPayload(vm.selectedAccounts, true);
                    vm.charts.payload.data.rows = payload;
                }
            }
        }

        function updateCache(type, map) {
            if (!_.isNull(type)) {
                vm.show.cache = type;
            } else {
                type = angular.copy(vm.show.cache);
            }

            if (type == 'count') {
                vm.charts.cache.options.vAxis.title = 'Count';
                vm.charts.cache.options.vAxis.format = '#';
                vm.charts.cache.options.isStacked = true;
                vm.charts.cache.options.legend = {
                    position: 'right'
                };
            } else {
                vm.charts.cache.options.vAxis.title = 'Hits Percentage';
                vm.charts.cache.options.vAxis.format = '#\'%\'';
                vm.charts.cache.options.legend = {
                    position: 'none'
                };
                vm.charts.cache.options.isStacked = false;
            }

            vm.charts.cache.data = witStatisticsService.setCache(type, vm.breakdown.cache, vm.selectedAccounts, map);
        }

        function updateFormats() {
            if (!vm.breakdown.formats) {
                var formats = witStatisticsService.setFormats(vm.selectedAccounts, true);
            } else {
                var formats = witStatisticsService.setFormatsBreakdown(vm.selectedAccounts, true);
            }

            vm.charts.formats.data = formats;
        }

        function updatePerPage(item) {
            _.map(vm.itemsPerPage, function(obj) {
                if (obj.value === item.value) {
                    obj.selected = true;
                    obj.class = "btn btn-default active";
                } else {
                    obj.selected = false;
                    obj.class = "btn btn-default";
                }
                return obj;
            });

            vm.chunk = 0;
            setPagination(item.value, 1);
        }

        function updateRequests() {
            if (!vm.breakdown.requests) {
                var requests = witStatisticsService.setRequests(vm.selectedAccounts, true);
            } else {
                var requests = witStatisticsService.setRequestsBreakdown(vm.selectedAccounts);
            }

            vm.charts.request.data = requests;
        }

        function viewTokens() {
            witModalService.viewTrialTokens(vm.tokens);
        }
    }
})();
