(function() {
    'use strict';

    angular.module('adminApp.wit')
        .controller('WitEditTierController', WitEditTierController);

    WitEditTierController.$inject = ['$filter', '$rootScope', '$state', '$stateParams', '$uibModalInstance', 'accountService', 'baseAccountService', 'Notification', 'witAccountService', 'witModalService'];

    function WitEditTierController($filter, $rootScope, $state, $stateParams, $uibModalInstance, accountService, baseAccountService, Notification, witAccountService, witModalService) {
        var vm = this;

        vm.close = close;
        vm.form = undefined;
        vm.getClass = getClass;
        vm.loading = false;
        vm.resetDefaults = resetDefaults;
        vm.saving = false;
        vm.subscription = accountService.getValues('wit', 'subscription');
        vm.updateDefaults = updateDefaults;
        vm.tier = undefined;
        vm.toggleCalendar = toggleCalendar;
        vm.warning = false;

        var service = baseAccountService.getService('wit');

        if (vm.tier == undefined || vm.subscription == undefined) {
            vm.loading = true;
            baseAccountService.get(service.resourceUrl, $stateParams.id)
                .then(function(response) {
                    vm.subscription = response.data.account;
                    vm.tier = witAccountService.remapLimits(vm.subscription, response.data.plans, true);
                    vm.tier_ids = angular.copy(vm.tier.ids);
                    delete vm.tier.ids;
                    vm.form = angular.copy(vm.tier);
                    vm.loading = false;
                }).catch(function(error) {
                    console.log(error);
                    Notification.error("The account tier could not be loaded");
                }).finally(function() {
                    vm.loading = false;
                });
        }

        function close() {
            $uibModalInstance.dismiss();
        }

        function getClass(field) {
            if (vm.form !== undefined && vm.tier !== undefined) {
                if (vm.form[field].value != vm.tier[field].plan_default.value) {
                    return 'label-danger'
                } else {
                    return 'label-default'
                }
            }
        }

        function resetDefaults() {
            angular.forEach(vm.tier, function(val, key) {
                vm.form[key].value = val.plan_default.value;
            });
        }

        function toggleCalendar() {
            witModalService.openTrialDaysCalendar(vm.subscription.date_started, vm.form.trial_days.value)
                .result.then(function(response) {
                    vm.form.trial_days.value = moment(response).startOf('day').diff(moment(vm.subscription.date_started).startOf('day'), 'd') + 1;
                });
        }

        function updateDefaults() {
            vm.warning = false;
            var startDate = $filter('createDate')({date: vm.subscription.date_started, format: 'YMD HIS'});
            var today = new Date();
            var expireDate = angular.copy(startDate);
            expireDate = new Date(expireDate.setDate(expireDate.getDate() + vm.subscription.plan.trial_days));
            var newExpireDate = angular.copy(startDate);
            newExpireDate = new Date(newExpireDate.setDate(newExpireDate.getDate() + parseInt(vm.form.trial_days.value)));

            // NOTE Allowing for changing trial days due to suspension behavior
            // if (today > expireDate) {
            //     vm.warning = "Trial period is already complete. To extend the trial, please create a new subscription.";
            //     return;
            // } else
            if (vm.subscription.plan.trial_days !== parseInt(vm.form.trial_days.value) && today > newExpireDate) {
                vm.warning = "The requested trial days would end the trial period. If this is desired, please change the account type instead.";
                return;
            }
            angular.forEach(vm.form, function(val, key) {
                if (val.value == undefined) {
                    val.value = null;
                }
            });

            vm.saving = true;
            witAccountService.updateDefaults($stateParams.id, vm.form, vm.tier_ids).then(function(response) {
                accountService.clear();
                Notification.success(response.message);
                $rootScope.$broadcast('reloadWitDefaults', {data: response.data});
                $rootScope.$broadcast('reloadWit', {});
                $uibModalInstance.dismiss();
            }).catch(function(error) {
                Notification.error(error.data.message);
                console.log(error);
            }).finally(function() {
                vm.saving = false;
            });
        }
    }
})();
