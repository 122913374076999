(function() {
    'use strict';

    angular.module('adminApp.saas')
        .controller('SaasMenuController', SaasMenuController);

    SaasMenuController.$inject = ['menuService', 'reportsService', 'subscriptionModalService', 'witModalService', 'quwurflAccountService'];
    function SaasMenuController(menuService, reportsService, subscriptionModalService, witModalService, quwurflAccountService) {
        var vm = this;

        vm.buttonsDisabled = !(!menuService.getMenu('salesadmin') || !menuService.getMenu('admin'));
        vm.createNew = createNew;
        vm.download = download;

        function createNew(type) {
            if (type != 'wit') {
                subscriptionModalService.createNew(type);
            } else {
                witModalService.createNew();
            }
        }

        function download(type, other) {
            if (type == 'expiredtrials') {
                witModalService.downloadExpiredTrialStats();
            } else if (type == 'quwurfl') {
                quwurflAccountService.download();
            } else if ((type != 'imgeng' && type != 'triallinks') || other == 'all') {
                subscriptionModalService.download(type);
            } else if (other == 'pt') {
                witModalService.download();
            } else if (type == 'triallinks') {
                reportsService.downloadTrialLinksReport();
            }
        }
    }
})();
