(function() {
    'use strict';

    angular.module('adminApp.cut')
        .controller('CutQuickAdminController', CutQuickAdminController);

    CutQuickAdminController.$inject = ['$filter', '$rootScope', '$scope', '$state', '$timeout', '$uibModal', '$window', 'breadcrumbsService', 'centraltestTestsService', 'cutModalService', 'cutRunTests', 'NgTableParams', 'Notification'];

    function CutQuickAdminController($filter, $rootScope, $scope, $state, $timeout, $uibModal, $window, breadcrumbsService, centraltestTestsService, cutModalService, cutRunTests, NgTableParams, Notification) {
        var vm = this;


        var fullResponse = []; // Should never change
        var testResponse = []; // Should never change after initial run
        var hiding = true;

        var currentSort = {
            key: 'name',
            direction: 'asc'
        };
        var externalResults = [];
        var timeout = null;

        vm.changeIntrospector = changeIntrospector;
        vm.completedTests = 0;
        vm.createNew = createNew;
        vm.data = [];
        vm.hideSuccessful = "true";
        vm.introspectorPreset = null;
        vm.introspectorUrl = null;
        vm.isLoading = true;
        vm.newWindow = newWindow;
        vm.popup = {};
        vm.results = {
            total: {
                header: "Total Tests",
                value: 0
            },
            successful: {
                header: "Successful",
                value: 0
            },
            failed: {
                header: "Failed",
                value: 0
            },
            exception: {
                header: "Exceptions",
                value: 0
            },
            skipped: {
                header: "Skipped",
                value: 0
            }
        };
        vm.runInfo = runInfo;
        vm.runTests = runTests;
        vm.runningTests = false;
        vm.searchFilter = undefined;
        vm.showAll = showAll;
        vm.showTestInfo = false;
        vm.showTestResults = false;
        vm.sort = sort;
        vm.steps = breadcrumbsService.get('centraltest.quick_admin');
        vm.tests = [];
        vm.testInfo = null;
        vm.total = null;
        vm.use = 'data';

        var currentTest = 0;
        var errorOccurred = false;
        var totalTests = fullResponse.length;

        centraltestTestsService.getQuickAdmin()
            .then(function(response) {
                fullResponse = angular.copy(response.data);
                vm.total = response.data.length;
                vm.currentTotal = angular.copy(vm.total);
                vm.data = response.data;
            }).catch(function(error) {
                console.log(error);
                Notification.error("Could not gather tests");
            }).finally(function() {
                vm.isLoading = false;
            });

        $scope.$watch('vm.searchFilter', function(newVal, oldVal) {
            // Clear any existing timeouts
            if (timeout !== null) {
                $timeout.cancel(timeout);
            }
            timeout = $timeout(function() {
                var updated = false;
                if (newVal !== undefined || oldVal !== undefined) {
                    var filteredResponse = [];
                    vm.isLoading = true;
                    $timeout(function() {
                        if (vm.use == 'data') {
                            var arr = fullResponse;
                        } else {
                            var arr = testResponse;
                        }
                        angular.forEach(arr, function(value, key) {
                            if (
                                value.name.indexOf(newVal) !== -1 ||
                                value.header_value.indexOf(newVal) !== -1 ||
                                value.assertions.indexOf(newVal) !== -1
                            ) {
                                if (vm.use == 'tests' && !hiding) {
                                    value.hidden = false;
                                }
                                filteredResponse.push(value);
                            }
                        });
                        if (vm.use == 'data') {
                            vm.data = filteredResponse;
                        } else {
                            vm.tests = filteredResponse;
                        }
                        vm.currentTotal = filteredResponse.length;
                        $rootScope.$broadcast('changetablearrows', {name: "name", direction: "asc"});
                        vm.isLoading = false;
                    }, 200);
                }
            });
        });

        $rootScope.$on('switchtablesort', function(event, args) {
            vm.isLoading = true;
            $timeout(function() {
                currentSort = {};
                currentSort.key = args.name;
                currentSort.direction = args.direction;

                var direction = currentSort.direction === "asc" ? false : true;
                if (vm.use == 'data') {
                    vm.data = $filter('orderBy')(vm.data, currentSort.key, direction);
                } else {
                    vm.tests = $filter('orderBy')(vm.tests, currentSort.key, direction);
                }
                vm.isLoading = false;
            }, 200);
        });

        function changeIntrospector() {
            vm.introspectorUrl = vm.introspectorPreset;
        }
        function createNew() {
            cutModalService.create('test', [], 'none', 0, {ua_override: 0, enabled: 0}, false);
        }

        function newWindow() {
            cutRunTests.externalResults = externalResults;
            $uibModal.open({
                templateUrl: 'view/centraltests.test_results',
                controller: 'CutTestResultsController',
                controllerAs: 'vm',
                size: 'lg',
                backdrop: 'static',
                keyboard: false
            });
        }

        function runInfo() {
            cutRunTests.runInfo({
                url: vm.introspectorUrl
            }).then(function(response) {
                vm.testInfo = response.data.html;
            }).catch(function(error) {
                vm.testInfo = '<strong>Error contacting remote introspector info: '+error.data.message+'</strong>';
            }).finally(function() {
                vm.showTestInfo = true;
            });
        }

        function runSingleTest(val, sourceUrl) {
            cutRunTests.runTest({
                action: 'request',
                url: sourceUrl,
                id: val.short_name
            }).then(function(response) {
                if (!response.success) {
                    errorOccurred = false;
                } else {
                    vm.completedTests++;
                    var processed = cutRunTests.processTest(val, response.response, vm.hideSuccessful);
                    vm.tests.push(processed.data);
                    angular.forEach(processed.external, function(val, key) {
                        externalResults.push(val);
                    });
                    if (processed.results !== null) {
                        vm.results[processed.results].value++;
                    }
                    vm.popup[val.id] = response.message;
                    if (vm.completedTests == totalTests) {
                        vm.runningTests = false;
                        vm.showTestResults = true;
                        vm.isLoading = false;
                        vm.use = 'tests';
                        testResponse = angular.copy(vm.tests);
                    }
                    currentTest++;
                    if (currentTest < totalTests) {
                        runSingleTest(fullResponse[currentTest], sourceUrl);
                    }
                }
            });
        }

        function runTests(source) {
            vm.isLoading = true;
            var sourceUrl = source === 'local' ? null : vm.introspectorUrl;
            vm.results.total.value = totalTests;

            vm.completedTests = 0;
            vm.runningTests = true;
            currentTest = 0;
            runSingleTest(fullResponse[currentTest], sourceUrl);
        }

        function showAll(action) {
            hiding = !action ? true : false;
            vm.tests = cutRunTests.showAll(action, vm.tests);
        }

        function sort(name) {
            var direction = "asc";
            if (currentSort.key == name) {
                switch (currentSort.direction) {
                    case 'desc':
                        direction = 'asc';
                        break;
                    case 'asc':
                        direction = 'desc';
                        break;
                    default:
                        direction = 'asc';
                }
            }
            $rootScope.$broadcast('switchtablesort', {name: name, direction: direction});
            $rootScope.$broadcast('changetablearrows', {name: name, direction: direction});
        }
    }
})();
