(function() {
    'use strict';

    angular.module('adminApp.wit')
        .controller('WitDomainController', WitDomainController);

    WitDomainController.$inject = ['$ngConfirm', '$q', '$stateParams', '$uibModalInstance', 'domainId', 'generalService', 'isNew', 'loaderService', 'Notification', 'origins', 'subscription', 'witAccountService'];

    function WitDomainController($ngConfirm, $q, $stateParams, $uibModalInstance, domainId, generalService, isNew, loaderService, Notification, origins, subscription, witAccountService) {
        var vm = this;

        vm.accordion = {
            config: false,
            prefix: false,
            cors: false
        };
        vm.close = close;
        vm.errors = null;
        vm.form = {
            domainName: null,
            ssl: (subscription.payment_plan === 'IMAGEENGINE_PRO' || subscription.payment_plan === 'IMAGEENGINE_STANDARD') ? true : false,
            name: null,
            origin: null,
            originProtocol: 'http',
            hostHeader: null,
            username: null,
            password: null,
            port: null,
            path: null,
            originId: null,
            bucketname: null,
            validationType: 'DNS',
            ie_only: false,
            allow_origin_prefix: 1,
            whitelist: [null],
            transition_time: 300,
            corsEnabled: 1,
            corsAllowedOrigins: ['*'],
            corsAllowedHeaders: '*',
            corsAllowedMethods: 'GET',
            corsMaxAge: null
        };
        vm.generalError = false;
        vm.isNew = isNew;
        vm.loading = false;
        vm.origins = origins;
        vm.resetWhitelist = resetWhitelist;
        vm.running = false;
        vm.selectedTab = 0;
        vm.showIeDomainNotify = showIeDomainNotify;
        vm.submit = submit;
        vm.subscription = subscription;
        vm.title = vm.title = isNew ? "Add New Domain Name" : "Edit Domain Name";
        vm.updateWhitelist = updateWhitelist;
        vm.resetCors = resetCors;
        vm.setSuggestedCorsValues = setSuggestedCorsValues;
        vm.removeAllowedOrigin = removeAllowedOrigin;
        vm.addAllowedOrigin = addAllowedOrigin;

        var accountResponse = null;
        var awsError = {
            error_type: []
        };
        var certificateError = false;
        var certificatePromises = [];
        var witCertificate = null;

        var origAllow = null;
        var origWhitelist = null;
        var origOrigin = null;

        var origCorsEnabled = null;
        var origCorsOrigins = null;
        var origCorsHeaders = null;
        var origCorsMethods = null;
        var origCorsMaxAge = null;

        var editFinish = {
            origin: false,
            whitelist: false,
            cors: false
        };
        var editError = {
            origin: false,
            whitelist: false,
            cors: false
        };

        setErrors();

        if (!isNew) {
            vm.loading = true;
            witAccountService.getDomain($stateParams.id, domainId).then(function(response) {
                vm.form.domainName = response.data.conf.cname;
                vm.form.ssl = response.data.ssl;
                vm.form.allow_origin_prefix = response.data.conf.allow_origin_prefix;
                origAllow = angular.copy(vm.form.allow_origin_prefix);
                origOrigin = angular.copy(response.data.origin);
                vm.form.ie_only = response.data.conf.ie_only_flag === 1 ? true : false;
                if (!_.isNull(response.data.origin)) {
                    vm.form.originId = response.data.origin.id;
                    vm.selectedTab = 1;
                }
                if (!_.isEmpty(response.data.whitelist)) {
                    vm.form.whitelist = _.pluck(response.data.whitelist, 'origin');
                    origWhitelist = angular.copy(vm.form.whitelist);
                } else {
                    vm.form.whitelist = [null];
                }

                vm.form.corsEnabled = response.data.conf.cors_enabled;
                origCorsEnabled = angular.copy(vm.form.corsEnabled);
                vm.form.corsAllowedOrigins = _.pluck(response.data.cors_allowed_origins, 'origin');
                origCorsOrigins = angular.copy(vm.form.corsAllowedOrigins);
                origCorsHeaders = _.pluck(response.data.cors_allowed_headers, 'header');
                vm.form.corsAllowedHeaders = origCorsHeaders.join(',');
                vm.form.corsMaxAge = response.data.conf.cors_max_age;
                origCorsMaxAge = angular.copy(vm.form.corsMaxAge);
                vm.form.corsAllowedMethods = response.data.conf.cors_allowed_methods;
                origCorsMethods = angular.copy(vm.form.corsAllowedMethods);
            }).catch(function(error) {
                console.log(error);
            }).finally(function() {
                vm.loading = false;
            });
        }

        function close() {
            $uibModalInstance.close(null);
        }

        function finish() {
            if (_.isUndefined(accountResponse.conf.wit_allowed_prefix)) {
                accountResponse.conf.wit_allowed_prefix = !_.isNull(vm.form.whitelist) ?
                    _.map(_.reject(vm.form.whitelist, function(val) {
                        return _.isNull(val);
                    }), function(val) {
                        return {origin: val};
                    }) : [];
            }

            if (_.indexOf(awsError.error_type, 'certificate') != -1 || !vm.form.ssl) {
                accountResponse.conf.status.ssl = null;
                vm.running = false;
                if (!_.isEmpty(awsError.error_type)) {
                    accountResponse.type = awsError.type;
                    accountResponse.error_type = awsError.error_type;
                    $uibModalInstance.dismiss(accountResponse);
                } else {
                    $uibModalInstance.close(accountResponse);
                }
            } else {
                if (vm.form.ie_only) {
                    witAccountService.getIeOnlyCertificateStatus($stateParams.id, accountResponse.conf.cname)
                        .then(function(response) {
                            accountResponse.conf.status.ssl = response.data.status;
                            accountResponse.conf.certificates = response.data.regions;
                            accountResponse.conf.status.attached = response.data.attached;
                        }).catch(function(error) {
                            console.log(error);
                            accountResponse.conf.status.ssl = 'PENDING_VALIDATION';
                        }).finally(function() {
                            accountResponse.conf.wit_certificate = witCertificate;
                            accountResponse.conf.validation.type = vm.form.validationType;
                            vm.running = false;
                            if (!_.isEmpty(awsError.error_type)) {
                                accountResponse.type = awsError.type;
                                accountResponse.error_type = awsError.error_type;
                                $uibModalInstance.dismiss(accountResponse);
                            } else {
                                $uibModalInstance.close(accountResponse);
                            }
                        });
                } else if (accountResponse.conf.custom_wildcard_flag) {
                    witAccountService.getCustomWildcardCertificateStatus($stateParams.id, accountResponse.conf.cname)
                        .then(function(response) {
                            accountResponse.conf.status.ssl = response.data.status;
                            accountResponse.conf.certificates = response.data.certificates;
                            accountResponse.conf.validation = response.data.domain_validation;
                            accountResponse.conf.status.attached = response.data.attached;
                        }).catch(function(error) {
                            console.log(error);
                            Notification.error("Cannot determine certificate status for "+domain.cname);
                            accountResponse.conf.status.ssl = 'error';
                        }).finally(function() {
                            accountResponse.conf.wit_certificate = witCertificate;
                            accountResponse.conf.validation.type = vm.form.validationType;
                            vm.running = false;
                            if (!_.isEmpty(awsError.error_type)) {
                                accountResponse.type = awsError.type;
                                accountResponse.error_type = awsError.error_type;
                                $uibModalInstance.dismiss(accountResponse);
                            } else {
                                $uibModalInstance.close(accountResponse);
                            }
                        });
                } else {
                    witAccountService.getCertificateStatus($stateParams.id, accountResponse.conf.cname)
                        .then(function(response) {
                            accountResponse.conf.status.ssl = response.data.status;
                            accountResponse.conf.test_enabled = response.data.test_enabled;
                            accountResponse.conf.status.attached = response.data.attached;
                        }).catch(function(error) {
                            accountResponse.conf.status.ssl = 'PENDING_VALIDATION';
                        }).finally(function() {
                            accountResponse.conf.wit_certificate = witCertificate;
                            accountResponse.conf.validation.type = vm.form.validationType;
                            vm.running = false;
                            if (!_.isEmpty(awsError.error_type)) {
                                accountResponse.type = awsError.type;
                                accountResponse.error_type = awsError.error_type;
                                $uibModalInstance.dismiss(accountResponse);
                            } else {
                                $uibModalInstance.close(accountResponse);
                            }
                        });
                }
            }
        }

        function finishUpdate() {
            if (!(editFinish.whitelist && editFinish.origin && editFinish.cors)) {
                return;
            }

            var success = [];
            var error = {};
            if (editError.origin) {
                error.origin = [];
                if (!_.isUndefined(editError.origin.data.type) && editError.origin.data.type == 'form') {
                    angular.forEach(editError.origin.data.errors, function(val, key) {
                        error.origin.push(key+": "+val);
                    });
                } else {
                    error.origin.push(editError.origin);
                }
            } else {
                success.push('Origin configuration updated successfully');
            }

            if (editError.whitelist) {
                error.whitelist = [];
                if (editError.whitelist.general) {
                    error.whitelist.push(editError.whitelist.errors);
                } else {
                    success.push("Allowed prefix preference updated, however errors occurred with the specified origins");
                    angular.forEach(editError.whitelist.errors, function(val, key) {
                        error.whitelist.push(val.origin+": "+val.reason);
                    });
                }
            } else {
                success.push('Allowed prefix configuration updated successfully');
            }

            var message = '';

            if (!_.isEmpty(_.keys(error))) {
                message += success.join("<br />");
                message += "<br />";
                if (!_.isUndefined(error.origin)) {
                    message += "Errors occurred with the origin configuration:<br />";
                    message += error.origin.join("<br />");
                }
                if (!_.isUndefined(error.whitelist)) {
                    message += "Errors occurred with the allowed prefix configuration:<br />";
                    message += error.whitelist.join("<br />");
                }
                vm.running = false;
                Notification.error(message);
                return;
            } else {
                loaderService.confirm();
                witAccountService.getDomainConfs($stateParams.id).then(function(response) {
                    $uibModalInstance.close(response.data);
                }).catch(function(error) {
                    console.log(error);
                }).finally(function() {
                    loaderService.close();
                });
            }
        }

        function setErrors() {
            vm.errors = {
                domainName: false,
                ssl: false,
                name: false,
                origin: false,
                originProtocol: false,
                hostHeader: false,
                username: false,
                password: false,
                port: false,
                path: false,
                originId: false,
                bucketname: false,
                validationType: false,
                corsAllowedOrigins: false
            };
            vm.generalError = false;
        }

        function resetWhitelist() {
            if (vm.form.allow_origin_prefix === 0) {
                vm.form.whitelist = null;
            } else {
                vm.form.whitelist = [null];
            }
        }

        function resetCors(val) {
            if (val == 1) {
                vm.form.corsAllowedOrigins = ['*'];
                vm.form.corsAllowedMethods = 'GET';
                vm.form.corsAllowedHeaders = '*';
            } else {
                vm.form.corsAllowedOrigins = [];
                vm.form.corsAllowedMethods = null;
                vm.form.corsAllowedHeaders = null;
                vm.form.corsMaxAge = null;
            }
        }

        function setSuggestedCorsValues() {
            vm.form.corsAllowedOrigins = ['*'];
            vm.form.corsAllowedMethods = 'GET,POST,OPTIONS';
            vm.form.corsAllowedHeaders = '*';
            vm.form.corsMaxAge = 7200;
        }

        function showIeDomainNotify() {
            if (vm.form.ie_only) {
                $ngConfirm({
                    title: 'Alert',
                    theme: 'dark',
                    content: "This will enable SSL for "+vm.form.domainName.toLowerCase()+".imgeng.in only. Please ensure that the domain name does NOT include `.imgeng.in`.",
                    buttons: {
                        close: {
                            text: 'OK',
                            btnClass: 'btn-info'
                        }
                    }
                });
                vm.form.validationType = "DNS";
            }
        }

        function runUpdateCors() {
            if (vm.form.corsEnabled == origCorsEnabled &&
                generalService.isEqual(vm.form.corsAllowedOrigins, origCorsOrigins) &&
                vm.form.corsAllowedMethods == origCorsMethods &&
                generalService.isEqual(vm.form.corsAllowedHeaders.split(','), origCorsHeaders) &&
                vm.form.corsMaxAge == origCorsMaxAge) {
                editFinish.cors = true;
                finishUpdate();
                return;
            }

            var allowedOrigins = _.isNull(vm.form.corsAllowedOrigins) ? [] : vm.form.corsAllowedOrigins;

            // get the list of headers, trim the space from each item, and remove the empty items
            var allowedHeaders = vm.form.corsAllowedHeaders ? vm.form.corsAllowedHeaders.split(',') : [];
            for (var i = 0; i < allowedHeaders.length; i++) {
                allowedHeaders[i] = allowedHeaders[i].trim();
            }
            allowedHeaders = _.without(allowedHeaders, '');
            // set the form value to our trimmed version of the headers list
            if (allowedHeaders.length > 0) {
                vm.form.corsAllowedHeaders = allowedHeaders.join(',');
            }

            witAccountService.postUpdateCors(
                $stateParams.id,
                domainId,
                {
                    enabled: vm.form.corsEnabled,
                    allowed_origins: allowedOrigins,
                    allowed_methods: vm.form.corsAllowedMethods,
                    allowed_headers: allowedHeaders,
                    max_age: vm.form.corsMaxAge
                }
            ).then(function(response) {
                if (!_.isEmpty(response.data)) {
                    editError.cors = {
                        general: false,
                        errors: response.data
                    };
                }
            }).catch(function(error) {
                editError.cors = {
                    general: true,
                    errors: error.data.message
                };
            }).finally(function() {
                editFinish.cors = true;
                finishUpdate();
            });
        }

        function submit() {
            setErrors();

            var errors = false;
            if (_.isNull(vm.form.domainName)) {
                errors = true;
                vm.errors.domainName = true;
            }
            if (vm.selectedTab == 0) {
                vm.form.originId = null;
                if (_.isNull(vm.form.name)) {
                    errors = true;
                    vm.errors.name = true;
                }
                if (vm.form.originProtocol != 's3') {
                    if (_.isNull(vm.form.origin)) {
                        errors = true;
                        vm.errors.origin = true;
                    }
                } else {
                    if (_.isNull(vm.form.bucketname)) {
                        errors = true;
                        vm.errors.bucketname = true;
                    }
                }
            } else {
                if (_.isNull(vm.form.originId)) {
                    errors = true;
                    vm.errors.originId = true;
                }
            }

            if (!_.isNull(vm.form.whitelist)) {
                var distinctWhitelist = new Set(vm.form.whitelist);
                if (vm.form.whitelist.length !== distinctWhitelist.size) {
                    Notification.error("Duplicate allowed origins exist");
                    errors = true;
                }
            }

            vm.form.corsAllowedOrigins = _.without(vm.form.corsAllowedOrigins, null, '');
            if (vm.form.corsAllowedOrigins.length === 0) {
                if (vm.form.corsEnabled == 1) {
                    vm.errors.corsAllowedOrigins = "At least one origin is required when CORS is enabled (use * for all origins)"
                    errors = true;
                    vm.form.corsAllowedOrigins = [''];
                } else {
                    vm.form.corsAllowedOrigins = null;
                }
            }

            if (!_.isNull(vm.form.corsAllowedOrigins)) {
                var distinctOrigins = new Set(vm.form.corsAllowedOrigins);
                if (vm.form.corsAllowedOrigins.length !== distinctOrigins.size) {
                    vm.generalError = "Duplicate cors origins exist";
                    errors = true;
                }
            }

            if (errors) {
                return;
            }

            var form = angular.copy(vm.form);
            if (!_.isNull(form.whitelist)) {
                if (form.whitelist.length == 1 && _.isNull(form.whitelist[0])) {
                    form.whitelist = null;
                } else {
                    form.whitelist = _.reject(form.whitelist, function(val) {
                        return _.isNull(val);
                    })
                }
            }

            form.domainName = form.domainName.toLowerCase();

            vm.running = true;
            if (isNew) {
                witAccountService.postCreateDomain($stateParams.id, form).then(function(response) {
                    accountResponse = response.data;
                    witAccountService.getAwsRegionsByAccount($stateParams.id).then(function(response) {
                        var regions = response.data;
                        var records = [];
                        var certificateIds = [];
                        angular.forEach(regions, function(val, key) {
                            if (val.Deploy !== 'provisioning') {
                                var record = {
                                    domain: vm.form.domainName.toLowerCase(),
                                    region: val.RegionName,
                                    type: 'A'
                                };
                            }
                            if (vm.form.ssl) {
                                var certForm = {
                                    domain: vm.form.domainName.toLowerCase(),
                                    region: val,
                                    validationType: vm.form.validationType,
                                    db_store: true
                                };

                                if (!vm.form.ie_only) {
                                    certificatePromises.push(
                                        witAccountService.postRequestCertificate($stateParams.id, certForm)
                                            .then(function(response) {
                                                accountResponse.conf.validation = {validation: response.data.validation};
                                                if (_.isUndefined(accountResponse.conf.certificates)) {
                                                    accountResponse.conf.certificates = {};
                                                }
                                                accountResponse.conf.certificates[val.RegionName] = {
                                                    status: response.data.validation.ValidationStatus,
                                                    in_use: response.data.validation.InUse ? 'yes' : 'no'
                                                };
                                                witCertificate = response.data.certificates;
                                                if (!_.isUndefined(response.data.validation.hash)) {
                                                    if (val.Deploy !== 'provisioning') {
                                                        record['hash'] = response.data.validation.hash;
                                                    }
                                                    accountResponse.conf.custom_wildcard_flag = true;
                                                }
                                            }).catch(function(error) {
                                                awsError.type = "aws";
                                                awsError.error_type.push('certificate');
                                                $q.reject();
                                            }).finally(function() {
                                                if (val.Deploy !== 'provisioning') {
                                                    records.push(record);
                                                }
                                            })
                                    );
                                } else {
                                    certificatePromises.push(
                                        witAccountService.postRequestTestCertificate($stateParams.id, certForm)
                                            .then(function(response) {
                                                accountResponse.conf.validation = {validation: null};
                                                if (_.isUndefined(accountResponse.conf.certificates)) {
                                                    accountResponse.conf.certificates = {};
                                                }
                                                accountResponse.conf.certificates[val.RegionName] = {
                                                    status: response.data.dns ? 'PENDING_VALIDATION' : 'ISSUED',
                                                    in_use: response.data.dns ? 'no' : 'yes',
                                                };
                                                witCertificate = response.data.certificates;
                                                if (response.data.dns) {
                                                    certificateIds.push({
                                                        region: val.RegionName,
                                                        id: response.data.certificate_id
                                                    });
                                                }
                                            }).catch(function(error) {
                                                console.log(error);
                                                $q.reject();
                                                awsError.type = "aws";
                                                awsError.error_type.push('certificate');
                                            }).finally(function() {
                                                if (val.Deploy !== 'provisioning') {
                                                    records.push(record);
                                                }
                                            })
                                    );
                                }
                            } else {
                                if (val.Deploy !== 'provisioning') {
                                    records.push(record);
                                }
                            }
                        });

                        $q.all(certificatePromises).then(function() {
                            witAccountService.postResourceRecords($stateParams.id, records)
                                .then(function() {})
                                .catch(function(error) {
                                    awsError.type = "aws";
                                    awsError.error_type.push('resource record');
                                }).finally(function() {
                                    if (!vm.form.ie_only) {
                                        finish();
                                    } else {
                                        if (certificateIds.length !== 0) {
                                            var detailsPromises = [];
                                            witAccountService.getCertificateValidationDetails($stateParams.id, certificateIds[0].id)
                                                .then(function(response) {
                                                    var records = [{
                                                        domain: vm.form.domainName,
                                                        region: 'global',
                                                        type: 'CNAME',
                                                        certificate: response.data.ResourceRecord,
                                                        test: true
                                                    }];
                                                    witAccountService.postResourceRecords($stateParams.id, records)
                                                        .then(function() {})
                                                        .catch(function(error) {
                                                            console.log(error);
                                                            awsError.type = "aws";
                                                            awsError.error_type.push('certificate');
                                                        }).finally(function() {
                                                            finish();
                                                        });
                                                }).catch(function(error) {
                                                    console.log(error);
                                                    awsError.type = "aws";
                                                    awsError.error_type.push('certificate');
                                                    finish();
                                                });
                                        } else {
                                            finish();
                                        }
                                    }
                                })
                        });
                    });
                }).catch(function(error) {
                    console.log(error);
                    vm.running = false;
                    switch (error.data.data.type) {
                        case 'general':
                            vm.generalError = error.data.message;
                            break;
                        case 'form':
                            angular.forEach(error.data.data.errors, function(val, key) {
                                vm.errors[key] = val;
                            });
                            break;
                        case 'domain':
                            vm.generalError = "We are unable to create the domain name. Please contact support@scientiamobile.com for assistance.";
                            break;
                        default:
                            vm.generalError = "An unknown error occurred. Please contact support@scientiamobile.com for assistance.";
                    }
                });
            } else {
                editFinish.whitelist = false;
                editFinish.origin = false;
                editFinish.cors = false;
                editError.whitelist = false;
                editError.origin = false;
                editError.cors = false;

                var updateForm = {
                    is_new: _.isNull(form.originId)
                };

                if (_.isNull(form.originId)) {
                    updateForm.origin = {
                        originProtocol: form.originProtocol,
                        origin: form.origin,
                        username: form.username,
                        password: form.password,
                        port: form.port,
                        path: form.path,
                        bucketname: form.bucketname,
                        name: form.name,
                        hostHeader: form.hostHeader
                    };
                } else {
                    updateForm.origin_id = form.originId;
                }

                if (_.isNull(origOrigin) || form.originId != origOrigin.id) {
                    witAccountService.patchUpdateDomainOrigin($stateParams.id, domainId, updateForm)
                        .then(function(response) {})
                        .catch(function(error) {
                            console.log(error.data);
                            editError.origin = error.data;
                        }).finally(function() {
                            editFinish.origin = true;
                            finishUpdate();
                        });
                } else {
                    editFinish.origin = true;
                    finishUpdate();
                }

                if (
                    form.allow_origin_prefix != origAllow ||
                    (!generalService.isEqual(form.whitelist, origWhitelist))
                ) {
                    witAccountService.postUpdatePrefixes(
                        $stateParams.id, domainId,
                        {allow: form.allow_origin_prefix, whitelist: form.whitelist}
                    ).then(function(response) {
                        if (!_.isEmpty(response.data)) {
                            editError.whitelist = {
                                general: false,
                                errors: response.data
                            };
                        }
                    }).catch(function(error) {
                        editError.whitelist = {
                            general: true,
                            errors: error.data.message
                        };
                    }).finally(function() {
                        editFinish.whitelist = true;
                        finishUpdate();
                    })
                } else {
                    editFinish.whitelist = true;
                    finishUpdate();
                }

                runUpdateCors();
            }
        }

        function updateWhitelist(key) {
            if (_.isUndefined(key)) {
                vm.form.whitelist.push(null)
            } else {
                vm.form.whitelist.splice(key, 1);
                if (vm.form.whitelist.length === 0) {
                    vm.form.whitelist = [null];
                }
            }
        }

        function removeAllowedOrigin(index) {
            vm.form.corsAllowedOrigins.splice(index, 1);
        }

        function addAllowedOrigin() {
            vm.form.corsAllowedOrigins.push('');
        }
    }
})();
