(function() {
    'use strict';

    angular.module('adminApp.wit')
        .controller('WitDomainConfController', WitDomainConfController);

    WitDomainConfController.$inject = ['$scope'];

    function WitDomainConfController($scope) {
        var vm = this;

        var httpEmpty = ['username', 'password', 'path', 'port'];
        var s3Empty = ['path'];

        vm.clear = $scope.deleteDomain;
        vm.clearBtn = $scope.default ? 'Clear' : 'Delete';
        vm.conf = $scope.conf;
        vm.default = $scope.default;
        vm.formErrors = $scope.formErrors;
        vm.id = !_.isUndefined($scope.conf.frontend_uniq_id) ? $scope.conf.frontend_uniq_id : 'default';
        vm.panel = {
            http: vm.conf.url_type == 'http' ? !_.every(_.map(httpEmpty, function(item) {
                    return vm.conf.http[item] != '';
                }), function(item) {
                    return item == false;
                }) : false,
            s3:  vm.conf.url_type == 's3' ? !_.every(_.map(s3Empty, function(item) {
                    return vm.conf.s3[item] != '';
                }), function(item) {
                    return item == false;
                }) : false
        }
        vm.submit = submit;

        function submit() {
            if (vm.conf.url_type == 'http') {
                if (_.isUndefined(vm.conf.http.is_secure)) {
                    vm.conf.http.is_secure = false;
                }
                if (_.isUndefined(vm.conf.http.username)) {
                    vm.conf.http.username = '';
                }
                if (_.isUndefined(vm.conf.http.password)) {
                    vm.conf.http.password = '';
                }
                if (_.isUndefined(vm.conf.http.path)) {
                    vm.conf.http.path = '';
                }
                if (_.isUndefined(vm.conf.http.port)) {
                    vm.conf.http.port = '';
                }
            } else {
                if (_.isUndefined(vm.conf.s3.path)) {
                    vm.conf.s3.path = '';
                }
            }

            if (vm.conf.url_type == 'http') {
                delete vm.conf.s3;
            } else {
                delete vm.conf.http;
            }

            vm.panel = {
                http: vm.conf.url_type == 'http' ? !_.every(_.map(httpEmpty, function(item) {
                        return vm.conf.http[item] != '';
                    }), function(item) {
                        return item == false;
                    }) : false,
                s3:  vm.conf.url_type == 's3' ? !_.every(_.map(s3Empty, function(item) {
                        return vm.conf.s3[item] != '';
                    }), function(item) {
                        return item == false;
                    }) : false
            };

            $scope.saveDomain();
        }
    }
})();
