(function() {
    'use strict';

    angular.module('adminApp.reports')
        .controller('AccountDownloadsSubscriptionController', AccountDownloadsSubscriptionController);

    AccountDownloadsSubscriptionController.$inject = ['$rootScope', '$scope', '$uibModalInstance', 'reportsService', 'type'];

    function AccountDownloadsSubscriptionController($rootScope, $scope, $uibModalInstance, reportsService, type) {
        var vm = this;

        vm.progress = 0;
        switch (type) {
            case 'cloud':
                var title = 'WURFL Cloud';
                break;
            case 'wjs':
                var title = 'WURFL.js';
                break;
            case 'imgeng':
                var title = 'ImageEngine';
                break;
        }
        vm.title = title + " Accounts Report";

        var updateSubscriptionsProgress = $rootScope.$on('updateSubscriptionsProgress', function(event, args) {
            vm.progress = reportsService.calculateProgress(args.complete);
            if (vm.progress == 100) {
                $uibModalInstance.close();
            }
            $scope.$on('$destroy', updateSubscriptionsProgress);
        });

        var subscriptionErrorClose = $rootScope.$on('subscriptionErrorClose', function() {
            $uibModalInstance.close();
            $scope.$on('$destroy', subscriptionErrorClose);
        });

        reportsService.downloadSubscriptionsReport(type);
    }
})();
