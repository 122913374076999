(function() {
    'use strict';

    angular.module('adminApp.licenses')
        .directive('licenseInsight', licenseInsight);

    function licenseInsight() {
        var directive = {
            retruct: 'E',
            templateUrl: 'view/licenses.templates.insight',
            scope: {
                ngLicense: "=license",
                type: "@"
            },
            controller: 'LicenseDirectiveController',
            controllerAs: 'vm'
        };

        return directive;
    }
})();
