(function() {
    'use strict';

    angular.module('adminApp.wit')
        .factory('settingsService', settingsService);

    function settingsService() {
        var service = {
            browserCacheTtl: browserCacheTtl,
            cacheTtl: cacheTtl,
            cmpr: cmpr,
            cmprdpicl: cmprdpicl,
            cors: cors,
            f: f,
            formatSetting: formatSetting,
            h: h,
            m: m,
            meta: meta,
            naf: naf,
            originReturnable: originReturnable,
            originTimeout: originTimeout,
            pass: pass,
            ppi: ppi,
            responseHeaders: responseHeaders,
            s: s,
            xWitSync: xWitSync,
            w: w,
            wAuto: wAuto
        };

        return service;

        function browserCacheTtl(setting) {
            return {
                value: parseInt(setting.value),
                selected: {
                    field: 'browser_cache_ttl',
                    value: 'custom'
                }
            };
        }

        function cacheTtl(setting) {
            return {
                value: parseInt(setting.value),
                selected: {
                    field: 'cache_ttl',
                    value: 'custom'
                }
            };
        }

        function cmpr(setting) {
            return {
                value: parseInt(setting.value),
                selected: {
                    field: 'image_info',
                    value: 'custom'
                },
                radio: [{
                    field: 'quality',
                    value: 'specify'
                }]
            };
        }

        function cmprdpicl(setting) {
            return {
                value: JSON.parse(setting.value),
                selected: {
                    field: 'image_info',
                    value: 'custom'
                },
                radio: [{
                    field: 'quality',
                    value: 'auto'
                }]
            };
        }

        function cors(setting) {
            var value = setting.value.split(",");
            var cors = [];
            angular.forEach(value, function(val) {
                try {
                    var url = new URL('', val);

                    var protocol = url.protocol.replace(":", "");
                    var port = null;
                    if (protocol === 'http' && url.port === "") {
                        var pattern = /:80$/;
                        var result = val.match(pattern);
                        if (!_.isNull(result)) {
                            port = 80;
                        }
                    } else if (url.port !== "") {
                        port = parseInt(url.port);
                    }

                    cors.push({
                        protocol: url.protocol.replace(":", ""),
                        hostname: url.hostname,
                        port: port
                    });
                } catch (err) {
                    // Check for s3
                    var components = val.split(":");
                    if (components[0] === 's3') {
                        cors.push({
                            protocol: 's3',
                            hostname: components[1].replace("//", ""),
                            port: components.length == 3 ? parseInt(components[2]) : null
                        });
                    }
                }
            });

            return {
                value: cors,
                selected: {
                    field: 'cors',
                    value: 'custom'
                }
            };
        }

        function f(setting) {
            return {
                value: setting.value,
                selected: {
                    field: 'format',
                    value: 'custom'
                },
                radio: [
                    {
                        field: 'format',
                        value: 'force'
                    }
                ]
            };
        }

        function formatSetting(setting) {
            // Get the function name
            return snakeToCamel(setting.wit_setting.directive);
        }

        function h(setting) {
            return {
                value: parseInt(setting.value),
                selected: {
                    field: 'image_info',
                    value: 'custom'
                },
                radio: [{
                    field: 'size',
                    value: 'force'
                }]
            };
        }

        function m(setting) {
            return {
                value: JSON.parse(setting.value),
                selected: {
                    field: 'image_info',
                    value: 'custom'
                },
                radio: [{
                    field: 'size',
                    value: 'force'
                }]
            };
        }

        function meta(setting) {
            return {
                value: setting.value,
                selected: {
                    field: 'meta',
                    value: 'custom'
                }
            };
        }

        function naf(setting) {
            return {
                value: setting.value.split(","),
                selected: {
                    field: 'format',
                    value: 'custom'
                },
                radio: [
                    {
                        field: 'format',
                        value: 'auto'
                    }
                ]
            };
        }

        function originReturnable(setting) {
            var values = setting.value.split(';'),
                behavior = 'default',
                ttl = null;

            if (values.length > 0) {
                behavior = values[0];
            }
            if (values.length > 1) {
                ttl = parseInt(values[1].replace('ttl=', ''));
                if (isNaN(ttl)) {
                    ttl = null;
                }
            }

            var value = behavior;
            if (ttl !== null) {
                value += '; ttl=' + ttl;
            }

            return {
                value: value,
                selected: {
                    field: 'origin_returnable',
                    value: behavior === 'off' ? 'default' : 'custom'
                },
                radio: [{
                    field: 'origin_returnable_behavior',
                    value: behavior === 'off' ? 'default' : behavior
                }],
                form: [{
                    field: 'origin_returnable_ttl',
                    value: ttl
                }]
            };
        }

        function originTimeout(setting) {
            return {
                value: parseInt(setting.value),
                selected: {
                    field: 'origin_timeout',
                    value: 'custom'
                }
            };
        }

        function pass(setting) {
            return {
                value: setting.value,
                selected: {
                    field: 'pass',
                    value: 'custom'
                }
            };
        }

        function ppi(setting) {
            return {
                value: setting.value,
                selected: {
                    field: 'image_info',
                    value: 'custom'
                },
                radio: [{
                    field: 'size',
                    value: 'auto'
                }]
            };
        }

        function s(setting) {
            return {
                value: parseInt(setting.value),
                selected: {
                    field: 's',
                    value: 'custom'
                }
            };
        }

        function responseHeaders(setting) {
            return {
                map_value: setting.map_value,
                selected: {
                    field: 'response_headers',
                    value: setting.map_value ? 'custom' : 'default'
                }
            };
        }

        function snakeToCamel(s){
            return s.replace(/(\_\w)/g, function(m){return m[1].toUpperCase();});
        }

        function xWitSync(setting) {
            return {
                value: setting.value,
                selected: {
                    field: 'x_wit_sync',
                    value: 'custom'
                }
            };
        }

        function w(setting) {
            return {
                value: parseInt(setting.value),
                selected: {
                    field: 'image_info',
                    value: 'custom'
                },
                radio: [{
                    field: 'size',
                    value: 'force'
                }]
            };
        }

        function wAuto(setting) {
            return {
                value: parseInt(setting.value),
                selected: {
                    field: 'image_info',
                    value: 'custom'
                },
                radio: [{
                    field: 'size',
                    value: 'auto'
                }]
            };
        }
    }
})();
