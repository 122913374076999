(function() {
    'use strict';

    angular.module('adminApp.coreapi')
        .controller('ScopesTableController', ScopesTableController);

    ScopesTableController.$inject = ['$filter', '$rootScope', '$timeout', 'breadcrumbsService', 'coreApiModalService', 'coreApiScopeService', 'NgTableParams', 'Notification'];

    function ScopesTableController($filter, $rootScope, $timeout, breadcrumbsService, coreApiModalService, coreApiScopeService, NgTableParams, Notification) {
        var vm = this;

        vm.createNew = createNew;
        vm.deleteScope = deleteScope;
        vm.edit = edit;
        vm.isLoading = true;
        vm.steps = breadcrumbsService.get('scopes.home');
        vm.tableParams = new NgTableParams(
            {
                page: 1,
                count: 10,
                sorting: {
                    scope: "asc"
                }
            },
            {
                total: 0,
                getData: function ($defer, params) {
                    getData($defer, params)
                }
            }
        );

        $rootScope.$on('reloadScopes', function() {
            vm.isLoading = true;
            vm.tableParams = new NgTableParams({
                page: 1,
                count: 10,
                sorting: {
                    name: "asc"
                }
            }, {
                total: 0,
                getData: function ($defer, params) {
                    getData($defer, params)
                }
            });
        });

        function getData($defer, params) {
            coreApiScopeService.all()
                .then(function(response) {
                    $timeout(function() {
                        var collection = response.data;
                        var total = collection.length;
                        var ordered = params.sorting() ? $filter('orderBy')(collection, params.orderBy()) : collection;
                        params.total(total);
                        $defer.resolve(ordered.slice((params.page() - 1) * params.count(), params.page() * params.count()));
                        vm.isLoading = false;
                    }, 500);
                }, function(error) {
                    switch (error.status) {
                        case 400:
                            Notification.error(error.data.message);
                            break;
                        case 403:
                            Notification.error({message: 'You are not permitted to perform this action', replaceMessage: true});
                            break;
                        default:
                            Notification.error('An unexpected error occurred');
                            console.log(error);
                            break;
                    }
                    vm.isLoading = false;
                });
        }

        function createNew() {
            coreApiModalService.createScope();
        }

        function deleteScope(scope) {
            coreApiModalService.deleteScope(scope);
        }

        function edit(scope) {
            coreApiModalService.editScope(scope);
        }
    }
})();
