(function() {
    'use strict';

    angular.module('adminApp.users')
        .controller('UserMenuController', UserMenuController);

    UserMenuController.$inject = ['$scope', '$state', 'menuService', 'Notification', 'userModalService', 'userService'];

    function UserMenuController($scope, $state, menuService, Notification, userModalService, userService) {
        var vm = this;

        vm.buttonsDisabled = vm.buttonsDisabled = !(!menuService.getMenu('salesadmin') || !menuService.getMenu('admin'));
        vm.createNew = createNew;
        vm.download = download;
        vm.selected_user = undefined;
        vm.userGoClick = userGoClick;

        var user;

        $scope.$watch('vm.selected_user', function(newVal) {
            user = newVal;
        });

        function createNew() {
            userModalService.createNew();
        }

        function download() {
            userModalService.downloadAccounts();
        }

        function userGoClick(event) {
            if (user !== undefined) {
                if (angular.isObject(user)) {
                    $state.go('users.view', {id: user.id});
                } else {
                    userService.getByName(user).then(function(response){
                        $state.go('users.view', {id: response.data.id});
                    }, function(error) {
                        Notification.error('Could not find user '+ user);
                    });
                }
            } else {
                Notification.warning('Please select a user');
            }
        }
    }
})();
