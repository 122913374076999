(function() {
    'use strict';

    angular.module('adminApp.licenses')
        .factory('buildProductService', buildProductService);

    buildProductService.$inject = ['$resource'];

    function buildProductService($resource) {
        var resource = new $resource('/api/admin/infuze/profiles/build_product_options/:action/:action_id', {action_id: '@action_id'}, {
            asOptions: {
                method: 'GET',
                cache: true,
                params: {action: null}
            }
        });

        var service = {
            asOptions: asOptions
        };

        return service;

        function asOptions(license_id) {
            return resource.asOptions({action_id: license_id}).$promise;
        }
    }
})();
