(function() {
    'use strict';

    angular.module('adminApp.users')
        .factory('userModalService', userModalService);

    userModalService.$inject = ['$state', '$uibModal'];

    function userModalService($state, $uibModal) {
        var service = {
            changePassword: changePassword,
            changeRoles: changeRoles,
            createNew: createNew,
            deleteUser: deleteUser,
            downloadAccounts: downloadAccounts,
            edit: edit,
            editPlatforms: editPlatforms,
            editZendeskTier: editZendeskTier,
            revoke: revoke,
            transferAccounts: transferAccounts
        };

        return service;

        function changePassword(user) {
            var modal = $uibModal.open({
                controller: 'UsersPasswordController',
                controllerAs: 'vm',
                templateUrl: 'view/users.templates.modal_user_form',
                resolve: {
                    user: function() {
                        return user;
                    }
                },
                backdrop: 'static',
                keyboard: false
            });
        }

        function changeRoles(roles, allRoles, user, authRoles) {
            $uibModal.open({
                controller: function($scope, $uibModalInstance, roles, allRoles, Notification, userService) {
                    var vm = this;

                    allRoles = userService.compareRoles(authRoles, allRoles);
                    vm.allRoles = allRoles;
                    vm.close = close;
                    vm.modalOptions = {
                        closeButtonText: 'Cancel',
                        okButtonText: 'Save',
                        template: 'view/users.modals.roles',
                        title: 'Roles'
                    };
                    vm.roles = roles;
                    vm.submit = submit;

                    function close() {
                        $uibModalInstance.dismiss();
                    }

                    function submit() {
                        userService.updateRoles(user, vm.roles)
                            .then(function(response) {
                                Notification.success('Roles updated');
                                $uibModalInstance.dismiss();
                            }, function(error) {
                                console.error(error);
                                Notification.error(error.data.message);
                            });
                    }
                },
                controllerAs: 'vm',
                templateUrl: 'view/users.templates.modal_user_form',
                resolve: {
                    roles: function() {
                        return roles;
                    },
                    allRoles: function() {
                        return allRoles
                    }
                },
                size: 'md',
                backdrop: 'static',
                keyboard: false
            });
        }

        function createNew() {
            $uibModal.open({
                templateUrl: 'view/users.templates.modal_user_form',
                controller: 'CreateUserController',
                controllerAs: 'vm',
                size: 'md',
                keyboard: false,
                backdrop: 'static'
            }).result.then(function(result) {
                if (result) {
                    return $state.transitionTo("users.view");
                }
            });
        }

        function deleteUser(params) {
            var accountCounts = params.accountCounts === undefined ? null : params.accountCounts;
            var accountLabels = params.accountLabels === undefined ? null : params.accountLabels;
            $uibModal.open({
                templateUrl: 'view/users.templates.action_modal',
                controller: 'UsersDeleteController',
                controllerAs: 'vm',
                size: 'lg',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    accountCounts: function() {
                        return accountCounts;
                    },
                    accountLabels: function() {
                        return accountLabels;
                    },
                    id: function() {
                        return params.id
                    }
                }
            });
        }

        function downloadAccounts() {
            $uibModal.open({
                templateUrl: 'view/components.account_download_tracker',
                controller: 'AccountDownloadsUsersController',
                controllerAs: 'vm',
                size: 'md',
                backdrop: 'static',
                keyboard: false
            });
        }

        function edit(form, platforms) {
            $uibModal.open({
                templateUrl: 'view/users.templates.modal_user_form',
                controller: 'UsersEditController',
                controllerAs: 'vm',
                size: 'lg',
                keyboard: false,
                backdrop: 'static',
                resolve: {
                    form: function() {
                        return form;
                    },
                    platforms: function() {
                        return platforms;
                    }
                }
            });
        }

        function editPlatforms(userPlatforms, platforms) {
            $uibModal.open({
                templateUrl: 'view/users.templates.modal_user_form',
                controller: 'UsersPlatformsController',
                controllerAs: 'vm',
                size: 'md',
                keyboard: false,
                backdrop: 'static',
                resolve: {
                    userPlatforms: function() {
                        return userPlatforms;
                    },
                    platforms: function() {
                        return platforms;
                    }
                }
            });
        }

        function editZendeskTier(params) {
            $uibModal.open({
                templateUrl: 'view/users.templates.modal_user_form',
                controller: 'UsersZendeskController',
                controllerAs: 'vm',
                size: 'lg',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    zendeskTierId: function() {
                        return params.zendeskTierId;
                    },
                    zendeskTiers: function() {
                        return params.zendeskTiers;
                    },
                    id: function() {
                        return params.id;
                    }
                }
            });
        }

        function revoke(params) {
            $uibModal.open({
                templateUrl: 'view/users.templates.action_modal',
                controller: 'UsersRevokeController',
                controllerAs: 'vm',
                size: 'lg',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    accountCounts: function() {
                        return params.accountCounts;
                    },
                    accountLabels: function() {
                        return params.accountLabels;
                    },
                    id: function() {
                        return params.id
                    }
                }
            });
        }

        function transferAccounts(params) {
            var accountCounts = params.accountCounts === undefined ? null : params.accountCounts;
            var accountLabels = params.accountLabels === undefined ? null : params.accountLabels;
            var revoke = params.revoke === undefined ? false : params.revoke;
            var remove = params.remove === undefined ? false : params.remove;
            var id = params.id;

            $uibModal.open({
                templateUrl: 'view/users.templates.action_modal',
                controller: 'UsersTransferAccountsController',
                controllerAs: 'vm',
                size: 'lg',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    accountCounts: function() {
                        return accountCounts;
                    },
                    accountLabels: function() {
                        return accountLabels;
                    },
                    remove: function() {
                        return remove;
                    },
                    revoke: function() {
                        return revoke;
                    },
                    id: function() {
                        return id;
                    }
                }
            });
        }
    }
})();
