(function() {
    'use strict';

    angular.module('adminApp.saas')
        .controller('SaasAccountSearchController', SaasAccountSearchController);

    SaasAccountSearchController.$inject = ['$scope', '$state', 'baseAccountService', 'cloudAccountService', 'witAccountService', 'wjsAccountService'];

    function SaasAccountSearchController($scope, $state, baseAccountService, cloudAccountService, witAccountService, wjsAccountService) {
        var vm = this;

        vm.go = go;
        vm.loading = true;
        vm.placeholder = "loading subscriptions... please wait";
        vm.search = search;
        vm.subscription_id = undefined;

        var services = {
            wit: witAccountService,
            wjs: wjsAccountService,
            cloud: cloudAccountService
        };

        var service = services[vm.product];

        baseAccountService.all(service.resourceUrl, {paginate: 100000}).then(function(response) {
            vm.accounts = response.data.subscriptions;
            vm.placeholder = vm.successPlaceholder;
        }).catch(function(error) {
            vm.placeholder = 'Action not permitted';
            console.log(error);
        }).finally(function() {
            vm.loading = false;
        });

        function go() {
            $state.go(vm.product+".view", {id: vm.subscription_id});
        }

        function search(value) {
            return baseAccountService.search(service.resourceUrl, value, 20).then(function(response) {
                return response.data.subscriptions;
            });
        }
    }
})();
