(function() {
    'use strict';
    angular.module('adminApp')
        .directive('breadcrumbs', breadcrumbs);

    function breadcrumbs() {
        var directive = {
            templateUrl: 'view/components.breadcrumbs',
            restrict: 'A',
            replace: true,
            scope: {
                steps: '='
            }
        };

        return directive;
    }
})();
