(function() {
    'use strict';

    angular.module('adminApp.licenses')
        .controller('LicensesEditController', LicensesEditController);

    LicensesEditController.$inject = ['$rootScope', '$scope', '$uibModalInstance', 'form', 'licenseService', 'Notification'];

    function LicensesEditController($rootScope, $scope, $uibModalInstance, form, licenseService, Notification) {
        var vm = this;

        vm.allLoaded = {
            product: false,
            productLevel: false,
            licenseType: false,
            licenseCategory: false,
            country: false
        };
        vm.close = close;
        vm.dateOptions = {'format': 'MM/DD/YY'};
        vm.datepicker = {
            date1: {opened: false},
            date2: {opened: false},
        };
        vm.form = form;
        vm.loading = true;
        vm.modalOptions = {
            actionClass: 'alert-info',
            actionMessage: 'Saving...',
            proceedButton: {
                btnClass: 'btn-primary',
                btnSpanClass: 'glyphicon-floppy-save',
                btnText: 'Save'
            },
            template: 'view/licenses.modals.edit',
            title: 'Edit License'
        }
        vm.openDate = openDate;
        vm.proceed = proceed;
        vm.showActionSpinner = false;

        $rootScope.$on('loadedProductDirective', function(event, args) {
            vm.allLoaded.product = true;
        });
        $rootScope.$on('loadedProductLevelDirective', function(event, args) {
            vm.allLoaded.productLevel = true;
        });
        $rootScope.$on('loadedLicenseTypeDirective', function(event, args) {
            vm.allLoaded.licenseType = true;
        });
        $rootScope.$on('loadedLicenseCategoryDirective', function(event, args) {
            vm.allLoaded.licenseCategory = true;
        });
        $rootScope.$on('loadedCountryDirective', function(event, args) {
            vm.allLoaded.country = true;
        });

        $rootScope.$on('updateProductDirective', function(event, args) {
            vm.form.product_id = args.id;
        });

        $rootScope.$on('updateProductLevelDirective', function(event, args) {
            vm.form.product_level_id = args.id;
        });

        $rootScope.$on('updateLicenseTypeDirective', function(event, args) {
            vm.form.license_type_id = args.id;
        });

        $rootScope.$on('updateLicenseCategoryDirective', function(event, args) {
            vm.form.license_cat_id = args.id;
        });

        $rootScope.$on('updateCountryDirective', function(event, args) {
            vm.form.country = args.model;
        });

        $scope.$watch('vm.allLoaded', function(newVal) {
            var loaded = true;
            angular.forEach(newVal, function(val, key) {
                if (!val) {
                    loaded = false;
                }
            });
            vm.loading = loaded ? false : true;
        }, true);

        function close() {
            $uibModalInstance.dismiss();
        }

        function openDate(date) {
            vm.datepicker[date].opened = true;
        }

        function proceed() {
            var date_format = 'YYYY-MM-DD HH:mm:ss';
            vm.form.license_date = moment(vm.form.date).format(date_format);
            vm.form.license_expire = moment(vm.form.expire).format(date_format);
            vm.form.licensee_country_id = vm.form.country.country_id;
            vm.showActionSpinner = true;
            licenseService.update(vm.form).then(function(response) {
                Notification.success(response.message);
                $rootScope.$broadcast('reloadLicense');
                $uibModalInstance.dismiss();
            }).catch(function(error) {
                console.log(error);
                Notification.error('Could not update license');
            }).finally(function() {
                vm.showActionSpinner = false;
            });
        }
    }
})();
