(function () {
    'use strict';

    angular.module('adminApp.home')
        .controller('VaultChangelogController', VaultChangelogController);

    VaultChangelogController.$inject = [];

    function VaultChangelogController() {
        var vm = this;
    }
})();
