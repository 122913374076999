(function() {
    'use strict';

    angular.module('adminApp.wit')
        .controller('WitStatsDisplayRangeController', WitStatsDisplayRangeController);

    WitStatsDisplayRangeController.$inject = ['$scope', '$uibModalInstance', 'end', 'start'];

    function WitStatsDisplayRangeController($scope, $uibModalInstance, end, start) {
        var vm = this;

        vm.activeTab = 0;
        vm.close = close;
        vm.end = end;
        vm.options = {
            start: {
                showWeeks: false
            },
            end: {
                showWeeks: false,
                minDate: moment(start).toDate()
            }
        };
        vm.start = start;
        vm.submit = submit;
        vm.updateDates = updateDates;
        vm.updateDisplay = updateDisplay;

        $scope.$watch('vm.start', function(newVal, oldVal) {
            if (newVal != oldVal) {
                vm.options.end.minDate = moment(newVal).toDate();
                if (vm.end < newVal) {
                    vm.end = moment(angular.copy(newVal)).toDate();
                }
            }
        });

        function close() {
            $uibModalInstance.dismiss();
        }

        function submit() {
            var end = angular.copy(vm.end);
            var start = angular.copy(vm.start);
            if (_.isObject(start)) {
                start = moment(start).startOf('day').format('YYYY-MM-DD HH:mm:ss');
            }
            if (_.isObject(end)) {
                end = moment(end).endOf('day').format('YYYY-MM-DD HH:mm:ss');
            }
            $uibModalInstance.close({start: start, end: end});
        }

        function updateDates(expression, amount, type) {
            var start = null;
            var end = null;
            switch (expression) {
                case 'today':
                    start = moment().utc().startOf('day');
                    end = moment().utc().endOf('day');
                    break;
                case 'week':
                    start = moment().utc().startOf('week').startOf('day');
                    end = moment().utc().endOf('day');
                    break;
                case 'month':
                    start = moment().utc().startOf('month').startOf('day');
                    end = moment().utc().endOf('day');
                    break;
                case 'year':
                    start = moment().utc().startOf('year').startOf('day');
                    end = moment().utc().endOf('day');
                    break;
                case 'sub':
                    start = moment().subtract(amount, type);
                    end = moment();
                    break;
            }
            vm.start = start.format('YYYY-MM-DD HH:mm:ss');
            vm.end = end.format('YYYY-MM-DD HH:mm:ss');
        }

        function updateDisplay() {
            if (vm.activeTab === 0) {
                vm.start = moment(vm.start).startOf('day').format('YYYY-MM-DD HH:mm:ss');
                vm.end = moment(vm.end).endOf('day').format('YYYY-MM-DD HH:mm:ss');
            } else {
                vm.start = moment(vm.start).toDate();
                vm.end = moment(vm.end).toDate();
            }
        }
    }
})();
