(function() {
    'use strict';

    angular.module('adminApp.wit')
        .factory('witStatisticsService', witStatisticsService);

    witStatisticsService.$inject = ['$q', '$resource', '$window', 'Notification', 'paginationService', 'witAccountService'];

    function witStatisticsService($q, $resource, $window, Notification, paginationService, witAccountService) {
        var resource = new $resource("/api/admin/wit/statistics" + "/:action", {}, {
            postFindBandwidthSaved: {
                method: 'POST',
                params: {
                    action: 'bandwidth'
                }
            },
            postFindCache: {
                method: 'POST',
                params: {
                    action: 'cache'
                }
            },
            postFindFormatSavings: {
                method: 'POST',
                params: {
                    action: 'formats'
                }
            },
            postFindPayload: {
                method: 'POST',
                params: {
                    action: 'payload'
                }
            },
            postFindRequests: {
                method: 'POST',
                params: {
                    action: 'requests'
                }
            }
        });

        var service = {
            addAccount: addAccount,
            addBandwidth: addBandwidth,
            addCache: addCache,
            addFormat: addFormat,
            addPayload: addPayload,
            addRequest: addRequest,
            alphabetically: alphabetically,
            checkAccountTraffic: checkAccountTraffic,
            compileAccounts: compileAccounts,
            downloadData: downloadData,
            filterSearch: filterSearch,
            filterSearchTraffic: filterSearchTraffic,
            filterSearchTrialLinks: filterSearchTrialLinks,
            postFindBandwidthSaved: postFindBandwidthSaved,
            postFindCache: postFindCache,
            postFindFormatSavings: postFindFormatSavings,
            postFindPayload: postFindPayload,
            postFindRequests: postFindRequests,
            removeBandwidth: removeBandwidth,
            removeCache: removeCache,
            removeFormat: removeFormat,
            removePayload: removePayload,
            removeRequest: removeRequest,
            setBandwidth: setBandwidth,
            setBandwidthBreakdown: setBandwidthBreakdown,
            setCache: setCache,
            setFormats: setFormats,
            setFormatsBreakdown: setFormatsBreakdown,
            setPayload: setPayload,
            setPayloadBreakdown: setPayloadBreakdown,
            setRequests: setRequests,
            setRequestsBreakdown: setRequestsBreakdown,
            sort: sort,
            trafficSort: trafficSort,
            trialLinksSort: trialLinksSort,
            updatePagination: updatePagination,
            updatePaginationTraffic: updatePaginationTraffic,
            updatePaginationTrialLinks: updatePaginationTrialLinks
        };

        service.accountsWithData = null;
        service.allAccounts = null;
        service.allTraffic = null;
        service.allTrialLinks = null;
        service.bandwidthChart = {
            type: 'LineChart',
            data: {
                cols: [
                    {
                        id: 'date',
                        label: 'Date',
                        type: 'string'
                    }
                ],
                rows: []
            },
            options: {
                height: 600,
                vAxis: {
                    title: 'Bandwidth Saved (GB)'
                },
                explorer: {
                    actions: ['dragToZoom', 'rightClickToReset'],
                    maxZoomIn: .01
                },
                backgroundColor: {
                    fill: 'transparent'
                },
                tooltip: {
                    isHtml: true
                }
            }
        };
        service.cacheChart = {
            type: 'ColumnChart',
            data: {
                cols: [],
                rows: []
            },
            options: {
                height: 600,
                vAxis: {
                    title: 'Count'
                },
                backgroundColor: {
                    fill:'transparent'
                },
                isStacked: true,
                explorer: {
                    actions: ['dragToZoom', 'rightClickToReset'],
                    maxZoomIn: .01
                },
                hAxis: {
                    slantedText: true,
                    slantedTextAngle: 45,
                    title: 'Account'
                },
                chartArea: {
                    top: 55,
                    height: '40%'
                },
                tooltip: {
                    isHtml: true
                }
            }
        };
        service.currentSort = {
            key: 'id',
            direction: 'asc'
        };
        service.trafficCurrentSort = {
            key: 'trial_expiration',
            direction: 'desc'
        };
        service.trialLinksCurrentSort = {
            key: 'time_since_creation',
            direction: 'asc'
        }
        service.dateRanges = {
            started: {
                start: null,
                end: null
            },
            trial: {
                start: null,
                end: null
            },
            expiration: {
                start: null,
                end: null
            },
            claimed_at: {
                start: null,
                end: null
            },
            registered: {
                start: null,
                end: null
            }
        };
        service.download = {
            bandwidth: null,
            cache: null,
            format: null,
            payload: null,
            requests: null
        };
        service.filteredAccounts = null;
        service.filteredIds = null;
        service.filteredTraffic = null;
        service.filteredTrialLinks = null;
        service.formatChart = {
            type: 'ColumnChart',
            data: {
                cols: [
                    {
                        id: 'account',
                        label: 'Account',
                        type: 'string'
                    }
                ],
                rows: []
            },
            options: {
                height: 600,
                vAxis: {
                    title: 'Savings Percentage',
                    format: '#\'%\''
                },
                backgroundColor: {
                    fill:'transparent'
                },
                explorer: {
                    actions: ['dragToZoom', 'rightClickToReset'],
                    maxZoomIn: .01
                },
                hAxis: {
                    slantedText: true,
                    slantedTextAngle: 45,
                    title: 'Account'
                },
                chartArea: {
                    top: 55,
                    height: '40%'
                },
                tooltip: {
                    isHtml: true
                }
            }
        };
        service.itemsPerPage = [
            {
                value: 10,
                class: "btn btn-default active",
                selected: true
            },
            {
                value: 25,
                class: "btn btn-default",
                selected: false
            },
            {
                value: 50,
                class: "btn btn-default",
                selected: false
            },
            {
                value: 100,
                class: "btn btn-default",
                selected: false
            }
        ];
        service.originalData = {
            bandwidth: null,
            bandwidthBreakdown: null,
            cache: null,
            cacheBreakdown: null,
            formats: null,
            formatsBreakdown: null,
            payload: null,
            payloadBreakdown: null,
            requests: null,
            requestsBreakdown: null
        };
        service.originalStatsIds = null;
        service.payloadChart = {
            type: 'ColumnChart',
            data: {
                cols: [
                    {
                        id: 'account',
                        label: 'Account',
                        type: 'string'
                    },
                    {
                        id: 'orig',
                        label: 'Original',
                        type: 'number'
                    },
                    {
                        id: 'ie',
                        label: 'ImageEngine',
                        type: 'number'
                    },
                    {
                        role: 'tooltip',
                        type: 'string'
                    }
                ],
                rows: []
            },
            options: {
                height: 600,
                vAxis: {
                    title: 'Payload (GB)'
                },
                backgroundColor: {
                    fill:'transparent'
                },
                explorer: {
                    actions: ['dragToZoom', 'rightClickToReset'],
                    maxZoomIn: .01
                },
                hAxis: {
                    slantedText: true,
                    slantedTextAngle: 45,
                    title: 'Account'
                },
                chartArea: {
                    top: 55,
                    height: '40%'
                },
                tooltip: {
                    isHtml: true
                }
            }
        };
        service.requestChart = {
            type: 'LineChart',
            data: {
                cols: [
                    {
                        id: 'date',
                        label: 'Date',
                        type: 'string'
                    }
                ],
                rows: []
            },
            options: {
                height: 600,
                vAxis: {
                    title: 'Requests Count'
                },
                explorer: {
                    actions: ['dragToZoom', 'rightClickToReset'],
                    maxZoomIn: .01
                },
                backgroundColor: {
                    fill: 'transparent'
                },
                tooltip: {
                    isHtml: true
                }
            }
        };
        service.search = {
            availableOnly: 'available',
            canceled: false,
            converted: false,
            current: false,
            domains: null,
            email: null,
            expiration: {
                startDate: null,
                endDate: null
            },
            level: "all",
            started: {
                startDate: null,
                endDate: null
            },
            status: "all",
            token: null,
            trial: {
                startDate: null,
                endDate: null
            },
            type: "all",
            username: null
        };
        service.selectedAccounts = {};
        service.trafficBreaks = {
            danger: {
                amount: 100000000,
                label: '100M'
            },
            warning: {
                amount: 1000000000,
                label: '1B'
            }
        }
        service.trafficChart = {
            type: 'LineChart',
            data: {
                cols: [
                    {
                        id: 'date',
                        label: 'Date',
                        type: 'string'
                    },
                    {
                        id: 'traffic',
                        label: 'Traffic (bytes)',
                        type: 'number'
                    }
                ],
                rows: []
            },
            options: {
                height: 600,
                vAxis: {
                    title: 'Traffic (bytes)'
                },
                explorer: {
                    actions: ['dragToZoom', 'rightClickToReset'],
                    maxZoomIn: .01
                },
                backgroundColor: {
                    fill: 'transparent'
                },
                tooltip: {
                    isHtml: true
                },
                legend: {
                    position: 'none'
                }
            }
        };
        service.trafficSearch = {
            identifier: null,
            registered: {
                startDate: null,
                endDate: null
            },
            trial_expiration: 'all',
            traffic: 'all',
            domains: null,
            status: 'all'
        };
        service.trialLinksSearch = {
            email: null,
            time_since_creation: 'all',
            claimed_at: {
                startDate: null,
                endDate: null
            },
            account_identifier: null,
            domains: null,
            claimed: 'all',
            time_to_claim: 'all'
        };

        return service;

        function addAccount(account, charts, breakdown, show, start, end, selectedAccounts) {
            var promises = [];

            var trials = _.filter(service.accountsWithData, function(obj) {
                return obj.id == account.id;
            });
            var run = {
                bandwidth: true,
                cache: true,
                formats: true,
                payload: true,
                requests: true
            };

            var deferred = $q.defer();

            if (!_.isNull(service.originalData.bandwidth)) {
                var keys = _.keys(service.originalData.bandwidth[0].buckets);
                if (_.indexOf(keys, account.id.toString()) !== -1) {
                    run.bandwidth = false;
                }
            }

            if (!_.isNull(service.originalData.payload)) {
                var keys = _.keys(service.originalData.payload);
                if (_.indexOf(keys, account.id.toString()) !== -1) {
                    run.payload = false;
                }
            }

            if (!_.isNull(service.originalData.cache)) {
                var keys = _.keys(service.originalData.cache);
                if (_.indexOf(keys, account.id.toString()) !== -1) {
                    run.cache = false;
                }
            }

            if (!_.isNull(service.originalData.formats)) {
                if (!_.isUndefined(_.find(service.originalData.formats, function(obj) {
                    return obj.id == account.id;
                }))) {
                    run.formats = false;
                }
            }

            if (!_.isNull(service.originalData.requests)) {
                var keys = _.keys(service.originalData.requests[0].buckets);
                if (_.indexOf(keys, account.id.toString()) !== -1) {
                    run.requests = false;
                }
            }

            var chartData = {
                bandwidth: null,
                payload: {
                    rows: null
                },
                cache: null,
                formats: null,
                requests: null
            };
            var promiseError = false;

            if (run.bandwidth) {
                promises.push(
                    service.postFindBandwidthSaved(start, end, trials)
                        .then(function(response) {
                            // This is the only account
                            if (_.isNull(service.originalData.bandwidth)) {
                                service.originalData.bandwidth = response.data.bandwidth;
                                service.originalData.bandwidthBreakdown = response.data.bandwidth_breakdown;
                                if (response.data.bandwidth.length !== 0) {
                                    if (!breakdown.bandwidth) {
                                        chartData.bandwidth = service.setBandwidth([], false);
                                    } else {
                                        var accounts = angular.copy(selectedAccounts);
                                        accounts.push(account);
                                        chartData.bandwidth = service.setBandwidthBreakdown(accounts)
                                    }
                                }
                            } else {
                                angular.forEach(response.data.bandwidth, function(obj) {
                                    var key = _.findKey(service.originalData.bandwidth, function(b) {
                                        return b.date == obj.date;
                                    });
                                    service.originalData.bandwidth[key].buckets[account.id] = obj.buckets[account.id];
                                });

                                angular.forEach(response.data.bandwidth_breakdown.columns, function(val) {
                                    if (_.isObject(val)) {
                                        service.originalData.bandwidthBreakdown.columns.push(val);
                                    }
                                });

                                angular.forEach(response.data.bandwidth_breakdown.rows, function(row, key) {
                                    angular.forEach(row, function(val, rkey) {
                                        if (rkey !== 0) {
                                            service.originalData.bandwidthBreakdown.rows[key].push(val);
                                        }
                                    });
                                });

                                if (response.data.bandwidth.length) {
                                    if (!breakdown.bandwidth) {
                                        chartData.bandwidth = service.setBandwidth([], false);
                                    } else {
                                        var accounts = angular.copy(selectedAccounts);
                                        accounts.push(account);
                                        chartData.bandwidth = service.setBandwidthBreakdown(accounts)
                                    }
                                }
                            }
                        }).catch(function(error) {
                            console.log(error);
                            if (error.status !== 504) {
                                promiseError = true;
                            }
                            $q.reject();
                        })
                )
            } else {
                chartData.bandwidth = service.addBandwidth(
                    account.id,
                    charts.bandwidth.data,
                    breakdown.bandwidth
                );
            }

            if (run.payload) {
                promises.push(
                    service.postFindPayload(start, end, trials)
                        .then(function(response) {
                            if (_.isNull(service.originalData.payload)) {
                                service.originalData.payload = response.data.payload;
                                service.originalData.payloadBreakdown = response.data.payload_breakdown;
                                if (response.data.payload.length !== 0) {
                                    if (!breakdown.payload) {
                                        chartData.payload.rows = service.setPayload([], false);
                                    } else {
                                        var accounts = angular.copy(selectedAccounts);
                                        accounts.push(account);
                                        chartData.payload.rows = service.setPayloadBreakdown(accounts)
                                    }
                                }
                            } else {
                                service.originalData.payload[account.id] = response.data.payload[account.id];
                                angular.forEach(response.data.payload_breakdown, function(obj) {
                                    service.originalData.payloadBreakdown.push(obj);
                                });
                                if (response.data.payload.length !== 0) {
                                    if (!breakdown.bandwidth) {
                                        chartData.payload.rows = service.setPayload([], false);
                                    } else {
                                        var accounts = angular.copy(selectedAccounts);
                                        accounts.push(account);
                                        chartData.payload.rows = service.setPayloadBreakdown(accounts)
                                    }
                                }
                            }
                        }).catch(function(error) {
                            console.log(error);
                            if (error.status !== 504) {
                                promiseError = true;
                            }
                            $q.reject();
                        })
                );
            } else {
                chartData.payload = service.addPayload(
                    account.id,
                    charts.payload.data,
                    breakdown.bandwidth
                );
            }

            if (run.cache) {
                promises.push(
                    service.postFindCache(start, end, trials)
                        .then(function(response) {
                            if (_.isNull(service.originalData.cache)) {
                                service.originalData.cache = response.data.cache;
                                service.originalData.cacheBreakdown = response.data.cache_breakdown;
                            } else {
                                service.originalData.cache[account.id] = response.data.cache[account.id];
                                angular.forEach(response.data.cache_breakdown, function(obj) {
                                    service.originalData.cacheBreakdown.push(obj);
                                });
                            }
                        }).catch(function(error) {
                            console.log(error);
                            if (error.status !== 504) {
                                promiseError = true;
                            }
                            $q.reject();
                        })
                );
            } else {
                chartData.cache = service.addCache(
                    account.id, charts.cache.data,
                    breakdown.cache, show
                );
            }

            if (run.formats) {
                promises.push(
                    service.postFindFormatSavings(start, end, trials)
                        .then(function(response) {
                            if (_.isNull(service.originalData.formats)) {
                                service.originalData.formats = response.data.formats;
                                service.originalData.formatsBreakdown = response.data.formats_breakdown;
                                if (response.data.formats.length != 0) {
                                    if (!breakdown.formats) {
                                        chartData.formats = service.setFormats([], false);
                                    } else {
                                        var accounts = angular.copy(selectedAccounts);
                                        accounts.push(account);
                                        chartData.formats = service.setFormatsBreakdown(accounts)
                                    }
                                }
                            } else {
                                angular.forEach(response.data.formats, function(obj) {
                                    service.originalData.formats.push(obj);
                                });
                                angular.forEach(response.data.formats_breakdown, function(obj, key) {
                                    service.originalData.formatsBreakdown[key] = obj;
                                });

                                if (response.data.formats.length !== 0) {
                                    if (!breakdown.formats) {
                                        chartData.formats = service.setFormats([], false);
                                    } else {
                                        var accounts = angular.copy(selectedAccounts);
                                        accounts.push(account);
                                        chartData.formats = service.setFormatsBreakdown(accounts)
                                    }
                                }
                            }
                        }).catch(function(error) {
                            console.log(error);
                            if (error.status !== 504) {
                                promiseError = true;
                            }
                            $q.reject();
                        })
                );
            } else {
                chartData.formats = service.addFormat(
                    charts.formats.data, account.id,
                    breakdown.formats
                );
            }

            if (run.requests) {
                promises.push(
                    service.postFindRequests(start, end, trials)
                        .then(function(response) {
                            if (_.isNull(service.originalData.requests)) {
                                service.originalData.requests = response.data.requests;
                                service.originalData.requestsBreakdown = response.data.requests_breakdown;
                                if (response.data.requests.length != 0) {
                                    if (!breakdown.requests) {
                                        chartData.requests = service.setRequests([], false);
                                    } else {
                                        var accounts = angular.copy(selectedAccounts);
                                        accounts.push(account);
                                        chartData.requests = service.setRequestsBreakdown(accounts)
                                    }
                                }
                            } else {
                                angular.forEach(response.data.requests, function(obj) {
                                    var key = _.findKey(service.originalData.requests, function(b) {
                                        return b.date == obj.date;
                                    });
                                    service.originalData.requests[key].buckets[account.id] = obj.buckets[account.id];
                                });

                                angular.forEach(response.data.requests_breakdown, function(row) {
                                    var key = _.findKey(service.originalData.requestsBreakdown, function(b) {
                                        return b.date == row.date;
                                    });
                                    angular.forEach(row.buckets, function(bucket, title) {
                                        service.originalData.requestsBreakdown[key].buckets[title] = bucket;
                                    });
                                });

                                if (response.data.requests.length) {
                                    if (!breakdown.requests) {
                                        chartData.requests = service.setRequests([], false);
                                    } else {
                                        var accounts = angular.copy(selectedAccounts);
                                        accounts.push(account);
                                        chartData.requests = service.setRequestsBreakdown(accounts)
                                    }
                                }
                            }
                        }).catch(function(error) {
                            console.log(error);
                            if (error.status !== 504) {
                                promiseError = true;
                            }
                            $q.reject();
                        })
                );
            } else {
                chartData.requests = service.addRequest(
                    charts.request.data, account.id,
                    breakdown.requests
                );
            }

            $q.all(promises).then(function() {
                deferred.resolve({
                    data: chartData,
                    error: promiseError
                });
            });

            return deferred.promise;
        }

        function addBandwidth(id, chart, breakdown) {
            if (!breakdown) {
                if (!_.isNull(service.originalData.bandwidth) && service.originalData.bandwidth.length !== 0) {
                    chart.cols.push({
                        id: "bandwidth_saved",
                        label: id,
                        type: "number"
                    });
                    var buckets = _.keys(service.originalData.bandwidth[0].buckets);
                    var indx = _.indexOf(buckets, id);
                    angular.forEach(service.originalData.bandwidth, function(row, key) {
                        if (!_.isUndefined(chart.rows[key])) {
                            chart.rows[key].c.push({v: row.buckets[id]});
                        } else {
                            chart.rows[key] = {
                                c: [
                                    {v: row.date},
                                    {v: row.buckets[id]}
                                ]
                            };
                        }
                    });
                }
            } else {
                if (!_.isNull(service.originalData.bandwidthBreakdown) && service.originalData.bandwidthBreakdown.rows.length !== 0) {
                    var titles = _.pluck(_.filter(service.originalData.bandwidthBreakdown.columns, function(obj) {
                        return obj.id == id;
                    }), 'title');
                    angular.forEach(titles, function(title) {
                        chart.cols.push({
                            id: "bandwidth_saved",
                            label: title,
                            type: "number"
                        });
                    });
                    var keys = [];
                    angular.forEach(service.originalData.bandwidthBreakdown.columns, function(obj, key) {
                        if (_.indexOf(titles, obj.title) !== -1) {
                            keys.push(parseInt(key));
                        }
                    });

                    if (chart.rows.length !== 0) {
                        angular.forEach(chart.rows, function(row, rowKey) {
                            angular.forEach(keys, function(key) {
                                row.c.push(
                                    {v: service.originalData.bandwidthBreakdown.rows[rowKey][key]}
                                );
                            })
                        });
                    } else {
                        angular.forEach(service.originalData.bandwidthBreakdown.rows, function(row) {
                            angular.forEach(keys, function(key) {
                                chart.rows.push({
                                    c: [
                                        {v: row[0]},
                                        {v: row[key]}
                                    ]
                                });
                            });
                        });
                    }
                }
            }

            return chart;
        }

        function addCache(id, chart, breakdown, type) {
            if (!_.isNull(service.originalData.cache) && service.originalData.cache.length !== 0) {
                if (type == 'count') {
                    if (chart.cols.length === 0) {
                        chart.cols = [
                            {
                                id: 'account',
                                label: "Account",
                                type: "string"
                            },
                            {
                                id: 'hits',
                                label: 'Hits',
                                type: "number"
                            },
                            {
                                id: 'miss',
                                label: 'Miss',
                                type: 'number'
                            }
                        ];
                    }
                    if (!breakdown) {
                        chart.rows.push({
                            c: [
                                {v: id},
                                {v: service.originalData.cache[id].hits},
                                {v: service.originalData.cache[id].miss}
                            ]
                        });
                    } else {
                        var accounts = _.filter(service.originalData.cacheBreakdown, function(row) {
                            return row.id == id;
                        });
                        angular.forEach(accounts, function(acc) {
                            chart.rows.push({
                                c: [
                                    {v: acc.key},
                                    {v: acc.hits},
                                    {v: acc.miss}
                                ]
                            });
                        });
                    }
                } else {
                    if (chart.cols.length === 0) {
                        chart.cols = [
                            {
                                id: 'account',
                                label: "Account",
                                type: "string"
                            },
                            {
                                id: 'hits_percentage',
                                label: 'Hits Percentage',
                                type: "number"
                            }
                        ];
                    }
                    if (!breakdown) {
                        chart.rows.push({
                            c: [
                                {v: id},
                                {v: service.originalData.cache[id].hits_percentage}
                            ]
                        });
                    } else {
                        var accounts = _.filter(service.originalData.cacheBreakdown, function(row) {
                            return row.id == id;
                        });
                        angular.forEach(accounts, function(acc) {
                            chart.rows.push({
                                c: [
                                    {v: acc.key},
                                    {v: acc.hits_percentage}
                                ]
                            });
                        });
                    }
                }
            }

            return chart;
        }

        function addFormat(chart, id, breakdown) {
            if (!_.isNull(service.originalData.formats) && service.originalData.formats.length !== 0) {
                if (chart.rows.length == 0) {
                    angular.forEach(service.originalData.formats[0].formats, function(value, format) {
                        chart.cols.push({
                            id: format,
                            label: format,
                            type: 'number'
                        });
                    });
                }
                if (!breakdown) {
                    var account = _.find(service.originalData.formats, function(obj) {
                        return obj.id == id;
                    });
                    if (!_.isUndefined(account)) {
                        var row = [
                            {v: id}
                        ];
                        angular.forEach(account.formats, function(format) {
                            row.push({v: format});
                        });
                        chart.rows.push({c: row});
                    }
                } else {
                    var rows = _.pick(service.originalData.formatsBreakdown, function(obj) {
                        return obj.id == id;
                    });

                    angular.forEach(rows, function(row, key) {
                        var add = [
                            {v: key}
                        ];
                        angular.forEach(row.formats, function(format) {
                            add.push({v: format});
                        });
                        chart.rows.push({c: add});
                    });
                }
            }

            return chart;
        }

        function addPayload(id, chart, breakdown) {
            if (!breakdown) {
                if (!_.isNull(service.originalData.payload) && service.originalData.payload.length !== 0) {
                    var account = _.find(service.originalData.payload, function(obj, key) {
                        return parseInt(key) == id;
                    });
                    if (!_.isUndefined(account)) {
                        chart.rows.push({
                            c: [
                                {v: id},
                                {v: account.bytes_orig},
                                {v: account.bytes},
                                {v: "ImageEngine: "+account.bytes+" = "+account.savings+"% savings"}
                            ]
                        });
                    }
                }
            } else {
                if (!_.isNull(service.originalData.payloadBreakdown) && service.originalData.payloadBreakdown.length !== 0) {
                    var additions = _.filter(service.originalData.payloadBreakdown, function(obj) {
                        return obj.id == id;
                    });

                    angular.forEach(additions, function(account) {
                        chart.rows.push({
                            c: [
                                {v: account.key},
                                {v: account.bytes_orig},
                                {v: account.bytes},
                                {v: "ImageEngine: "+account.bytes+" = "+account.savings+"% savings"}
                            ]
                        });
                    });
                }
            }

            return chart;
        }

        function addRequest(chart, id, breakdown) {
            if (!breakdown) {
                if (!_.isNull(service.originalData.requests) && service.originalData.requests.length !== 0) {
                    chart.cols.push({
                        id: "account",
                        label: id,
                        type: "number"
                    });

                    var buckets = _.keys(service.originalData.requests[0].buckets);
                    var indx = _.indexOf(buckets, id);
                    angular.forEach(service.originalData.requests, function(row, key) {
                        if (!_.isUndefined(chart.rows[key])) {
                            chart.rows[key].c.push({v: row.buckets[id]});
                        } else {
                            chart.rows[key] = {
                                c: [
                                    {v: row.date},
                                    {v: row.buckets[id]}
                                ]
                            };
                        }
                    });
                }
            } else {
                if (!_.isNull(service.originalData.requestsBreakdown) && service.originalData.requestsBreakdown.length !== 0) {
                    angular.forEach(service.originalData.requestsBreakdown[0].buckets, function(bucket, key) {
                        if (parseInt(bucket.id) == id) {
                            chart.cols.push({
                                id: "account",
                                label: key,
                                type: "number"
                            });
                        }
                    });

                    if (chart.rows.length !== 0) {
                        angular.forEach(chart.rows, function(row, key) {
                            angular.forEach(service.originalData.requestsBreakdown[key].buckets, function(bucket) {
                                if (parseInt(bucket.id) == id) {
                                    row.c.push({v: bucket.value});
                                }
                            });
                        });
                    } else {
                        angular.forEach(service.originalData.requestsBreakdown, function(row, key) {
                            var c = [
                                {v: row.date}
                            ];
                            angular.forEach(row.buckets, function(val) {
                                if (val.id == id) {
                                    c.push({v: val.value});
                                }
                            });
                            chart.rows.push({c: c});
                        });
                    }
                }
            }

            return chart;
        }

        function alphabetically (direction, name) {
            return function(a, b) {
                if (direction == 'asc') {
                    if (_.isNull(a[name])) {
                        return -1;
                    } else if (_.isNull(b[name])) {
                        return 1;
                    } else {
                        a[name] = a[name].toString().toLowerCase();
                        b[name] = b[name].toString().toLowerCase();
                        if (a[name] === b[name]) {
                            return 0;
                        } else {
                            return a[name] < b[name] ? -1 : 1;
                        }
                    }
                } else {
                    if (_.isNull(a[name])) {
                        return 1;
                    } else if (_.isNull(b[name])) {
                        return -1;
                    } else {
                        a[name] = a[name].toString().toLowerCase();
                        b[name] = b[name].toString().toLowerCase();
                        if (a[name] === b[name]) {
                            return 0;
                        } else {
                            return a[name] > b[name] ? -1 : 1;
                        }
                    }
                }
            }
        }

        function checkAccountTraffic(id) {
            if (!_.isNull(service.originalData.bandwidth)) {
                if (_.indexOf(_.keys(service.originalData.bandwidth[0].buckets), id.toString()) !== -1) {
                    return false;
                }
            }

            if (!_.isNull(service.originalData.bandwidthBreakdown)) {
                if (_.indexOf(_.pluck(service.originalData.bandwidthBreakdown.columns, 'id'), id) !== -1) {
                    return false;
                }
            }

            if (!_.isNull(service.originalData.cache)) {
                if (_.indexOf(_.keys(service.originalData.cache), id.toString()) !== -1) {
                    return false;
                }
            }

            if (!_.isNull(service.originalData.cacheBreakdown)) {
                if (_.indexOf(_.pluck(service.originalData.cacheBreakdown, 'id'), id) !== -1) {
                    return false;
                }
            }

            if (!_.isNull(service.originalData.formats)) {
                if (_.indexOf(_.pluck(service.originalData.formats, 'id'), id) !== -1) {
                    return false;
                }
            }

            if (!_.isNull(service.originalData.formatsBreakdown)) {
                if (_.indexOf(_.pluck(service.originalData.formatsBreakdown, 'id'), id) !== -1) {
                    return false;
                }
            }

            if (!_.isNull(service.originalData.payload)) {
                if (_.indexOf(_.keys(service.originalData.payload), id.toString()) !== -1) {
                    return false;
                }
            }

            if (!_.isNull(service.originalData.payloadBreakdown)) {
                if (_.indexOf(_.pluck(service.originalData.payloadBreakdown, 'id'), id) !== -1) {
                    return false;
                }
            }

            if (!_.isNull(service.originalData.requestsBreakdown)) {
                if (_.indexOf(_.pluck(service.originalData.requestsBreakdown[0].buckets, id), id) !== -1) {
                    return false;
                }
            }

            return true;;
        }

        function compileAccounts() {
            var accounts = [];
            angular.forEach(service.accountsWithData, function(obj) {
                var account = _.findWhere(service.allAccounts, {id: obj.id});
                if (!_.isUndefined(account)) {
                    accounts.push(account);
                }
            });

            service.originalStatsIds = _.pluck(accounts, 'id');

            return accounts;
        }

        function downloadData(start, end, show, charts, type, breakdown) {
            var name = start.replace(" ", "_")+"_to_"+end.replace(" ", "_")+".csv";
            if (!breakdown) {
                var data = [
                    [
                        'Account ID',
                        'Username',
                        'Email',
                        'Level',
                        'Type',
                        'Status',
                        'Token',
                        'Domains',
                        'Started',
                        'Trial End',
                        'Expiration'
                    ]
                ];
                switch (type) {
                    case 'bandwidth':
                        if (show.bandwidth === 'date') {
                            data[0].push('data');
                            angular.forEach(charts.bandwidth.data.cols, function(col, key) {
                                if (key !== 0) {
                                    var account = _.find(service.allAccounts, function(acc) {
                                        return acc.id === parseInt(col.label);
                                    });
                                    var row = [
                                        account.id, account.username, account.email,
                                        account.payment_plan, account.payment_type, account.status,
                                        account.token, account.date_started, account.date_trial_end, account.date_expiration
                                    ];
                                    var domains = !_.isNull(account.domains) ? account.domains.join(",") : null;
                                    row.splice(7, 0, domains);

                                    var rowData = {};
                                    angular.forEach(charts.bandwidth.data.rows, function(r) {
                                        rowData[r.c[0].v] = r.c[key].v;
                                    })
                                    row.push(JSON.stringify(rowData));
                                    data.push(row);
                                }
                            });

                            var prefix = "bandwidth_saved_";
                        } else {
                            data[0].push('Original (GB)');
                            data[0].push('ImageEngine (GB)');
                            data[0].push('Savings Percentage');

                            angular.forEach(charts.payload.data.rows, function(r, key) {
                                var account = _.find(service.allAccounts, function(acc) {
                                    return acc.id === parseInt(r.c[0].v);
                                });
                                var row = [
                                    account.id, account.username, account.email,
                                    account.payment_plan, account.payment_type, account.status,
                                    account.token, account.date_started, account.date_trial_end, account.date_expiration,
                                    r.c[1].v, r.c[2].v
                                ];
                                var domains = !_.isNull(account.domains) ? account.domains.join(",") : null;
                                row.splice(7, 0, domains);
                                var match = r.c[3].v.match(/^ImageEngine: .+ = (.*?)% savings$/);
                                row.push(match[1]);
                                data.push(row);
                            });

                            var prefix = "payload_reduction_";
                        }
                        break;
                    case 'cache':
                        if (show.cache == 'count') {
                            var prefix = 'cache_hit_miss_';
                            data[0].push("Hits");
                            data[0].push("Miss");
                        } else {
                            var prefix = 'cache_hits_percentage_';
                            data[0].push("Hits Percentage");
                        }

                        angular.forEach(charts.cache.data.rows, function(r, key) {
                            var account = _.find(service.allAccounts, function(acc) {
                                return acc.id === parseInt(r.c[0].v);
                            });
                            var row = [
                                account.id, account.username, account.email,
                                account.payment_plan, account.payment_type, account.status,
                                account.token, account.date_started, account.date_trial_end, account.date_expiration,
                                r.c[1].v
                            ];
                            var domains = !_.isNull(account.domains) ? account.domains.join(",") : null;
                            row.splice(7, 0, domains);

                            if (show.cache == 'count') {
                                row.push(r.c[2].v);
                            }
                            data.push(row);
                        });
                        break;
                    case 'formats':
                        angular.forEach(charts.formats.data.cols, function(col, key) {
                            if (key !== 0) {
                                data[0].push(col.label+" Savings Percentage");
                            }
                        });
                        angular.forEach(charts.formats.data.rows, function(r) {
                            var account = _.find(service.allAccounts, function(acc) {
                                return acc.id === parseInt(r.c[0].v);
                            });
                            var row = [
                                account.id, account.username, account.email,
                                account.payment_plan, account.payment_type, account.status,
                                account.token, account.date_started, account.date_trial_end, account.date_expiration
                            ];

                            var domains = !_.isNull(account.domains) ? account.domains.join(",") : null;
                            row.splice(7, 0, domains);

                            var rowData = _.pluck(r.c, 'v');
                            rowData.shift();
                            data.push(row.concat(rowData));
                        });

                        var prefix = "image_format_savings_percentage_";
                        break;
                    case 'requests':
                        var prefix = "requests_";
                        data[0].push('Data');
                        angular.forEach(charts.request.data.cols, function(col, key) {
                            if (key !== 0) {
                                var account = _.find(service.allAccounts, function(acc) {
                                    return acc.id === parseInt(col.label);
                                });
                                var row = [
                                    account.id, account.username, account.email,
                                    account.payment_plan, account.payment_type, account.status,
                                    account.token, account.date_started, account.date_trial_end, account.date_expiration
                                ];
                                var domains = !_.isNull(account.domains) ? account.domains.join(",") : null;
                                row.splice(7, 0, domains);

                                var rowData = {};
                                angular.forEach(charts.request.data.rows, function(r) {
                                    rowData[r.c[0].v] = r.c[key].v;
                                })
                                row.push(JSON.stringify(rowData));
                                data.push(row);
                            }
                        });
                        break;
                }
            } else {
                var data = [
                    [
                        'Label',
                        'Account ID',
                        'Username',
                        'Email',
                        'Level',
                        'Type',
                        'Status',
                        'Started',
                        'Trial End',
                        'Expiration'
                    ]
                ];
                switch (type) {
                    case 'bandwidth':
                        if (show.bandwidth === 'date') {
                            data[0].push('data');
                            angular.forEach(charts.bandwidth.data.cols, function(col, key) {
                                if (key !== 0) {
                                    var match = col.label.match(/^Token (.+)$/);
                                    if (!_.isNull(match)) {
                                        var account = _.find(service.allAccounts, function(acc) {
                                            return acc.token == match[1];
                                        });
                                    } else {
                                        var account = _.find(service.allAccounts, function(acc) {
                                            return _.indexOf(acc.domains, col.label) !== -1;
                                        });
                                    }
                                    var row = [
                                        col.label, account.id, account.username, account.email,
                                        account.payment_plan, account.payment_type, account.status,
                                        account.date_started, account.date_trial_end, account.date_expiration
                                    ];
                                    var rowData = {};
                                    angular.forEach(charts.bandwidth.data.rows, function(r) {
                                        rowData[r.c[0].v] = r.c[key].v;
                                    })
                                    row.push(JSON.stringify(rowData));
                                    data.push(row);
                                }
                            });

                            var prefix = "bandwidth_saved_breakdown_";
                        } else {
                            data[0].push('Original (GB)');
                            data[0].push('ImageEngine (GB)');
                            data[0].push('Savings Percentage');
                            angular.forEach(charts.payload.data.rows, function(r, key) {
                                var match = r.c[0].v.match(/^Token (.+)$/);
                                if (!_.isNull(match)) {
                                    var account = _.find(service.allAccounts, function(acc) {
                                        return acc.token == match[1];
                                    });
                                } else {
                                    var account = _.find(service.allAccounts, function(acc) {
                                        return _.indexOf(acc.domains, r.c[0].v) !== -1;
                                    });
                                }

                                var row = [
                                    r.c[0].v, account.id, account.username, account.email,
                                    account.payment_plan, account.payment_type, account.status,
                                    account.date_started, account.date_trial_end, account.date_expiration,
                                    r.c[1].v, r.c[2].v
                                ];

                                var match = r.c[3].v.match(/^ImageEngine: .+ = (.*?)% savings$/);
                                row.push(match[1]);
                                data.push(row);
                            });
                            var prefix = "payload_reduction_";
                        }
                        break;
                    case 'cache':
                        if (show.cache == 'count') {
                            var prefix = 'cache_hit_miss_';
                            data[0].push("Hits");
                            data[0].push("Miss");
                        } else {
                            var prefix = 'cache_hits_percentage_';
                            data[0].push("Hits Percentage");
                        }

                        angular.forEach(charts.cache.data.rows, function(r, key) {
                            var match = r.c[0].v.match(/^Token (.+)$/);
                            if (!_.isNull(match)) {
                                var account = _.find(service.allAccounts, function(acc) {
                                    return acc.token == match[1];
                                });
                            } else {
                                var account = _.find(service.allAccounts, function(acc) {
                                    return _.indexOf(acc.domains, r.c[0].v) !== -1;
                                });
                            }
                            var row = [
                                r.c[0].v, account.id, account.username, account.email,
                                account.payment_plan, account.payment_type, account.status,
                                account.date_started, account.date_trial_end, account.date_expiration,
                                r.c[1].v
                            ];

                            if (show.cache == 'count') {
                                row.push(r.c[2].v);
                            }
                            data.push(row);
                        });
                        break;
                    case 'formats':
                        angular.forEach(charts.formats.data.cols, function(col, key) {
                            if (key !== 0) {
                                data[0].push(col.label+" Savings Percentage");
                            }
                        });
                        angular.forEach(charts.formats.data.rows, function(r) {
                            var match = r.c[0].v.match(/^Token (.+)$/);
                            if (!_.isNull(match)) {
                                var account = _.find(service.allAccounts, function(acc) {
                                    return acc.token == match[1];
                                });
                            } else {
                                var account = _.find(service.allAccounts, function(acc) {
                                    return _.indexOf(acc.domains, r.c[0].v) !== -1;
                                });
                            }
                            var row = [
                                r.c[0].v, account.id, account.username, account.email,
                                account.payment_plan, account.payment_type, account.status,
                                account.date_started, account.date_trial_end, account.date_expiration
                            ];

                            var rowData = _.pluck(r.c, 'v');
                            rowData.shift();
                            data.push(row.concat(rowData));
                        });

                        var prefix = "image_format_savings_percentage_";
                        break;
                    case 'requests':
                        var prefix = "requests_";
                        data[0].push('Data');
                        angular.forEach(charts.request.data.cols, function(col, key) {
                            if (key !== 0) {
                                var match = col.label.match(/^Token (.+)$/);
                                if (!_.isNull(match)) {
                                    var account = _.find(service.allAccounts, function(acc) {
                                        return acc.token == match[1];
                                    });
                                } else {
                                    var account = _.find(service.allAccounts, function(acc) {
                                        return _.indexOf(acc.domains, col.label) !== -1;
                                    });
                                }

                                var row = [
                                    col.label, account.id, account.username, account.email,
                                    account.payment_plan, account.payment_type, account.status,
                                    account.date_started, account.date_trial_end, account.date_expiration
                                ];

                                var rowData = {};
                                angular.forEach(charts.request.data.rows, function(r) {
                                    rowData[r.c[0].v] = r.c[key].v;
                                })
                                row.push(JSON.stringify(rowData));
                                data.push(row);
                            }
                        });
                        break;
                }
            }

            return witAccountService.postTrialStatistics(data)
                .then(function(response) {
                    var blob = new Blob([response.data[0]], {type: 'text/csv'}),
                        url = $window.URL || $window.webkitURL;
                    var a = $("<a style='display: none;'/>");
                    var url = url.createObjectURL(blob);
                    a.attr("href", url);
                    a.attr("download", prefix+name);
                    $("body").append(a);
                    a[0].click();
                    window.URL.revokeObjectURL(url);
                    a.remove();
                }).catch(function(error) {
                    console.log(error);
                    Notification.error("Unable to download statistics");
                });
        }

        function filterSearch(search) {
            service.filteredAccounts = angular.copy(service.allAccounts);

            if (!_.isNull(search.username) && search.username !== '') {
                service.filteredAccounts = _.filter(service.filteredAccounts, function(obj) {
                    return obj.username.indexOf(search.username) !== -1;
                });
            }

            if (!_.isNull(search.email) && search.email !== '') {
                service.filteredAccounts = _.filter(service.filteredAccounts, function(obj) {
                    return obj.email.indexOf(search.email) !== -1;
                });
            }

            if (!_.isNull(search.level) && search.level != '' && search.level != 'all') {
                service.filteredAccounts = _.filter(service.filteredAccounts, function(obj) {
                    return obj.payment_plan === search.level;
                });
            }

            if (!_.isNull(search.type) && search.type != '' && search.type != 'all') {
                service.filteredAccounts = _.filter(service.filteredAccounts, function(obj) {
                    return obj.payment_type === search.type;
                });
            }

            if (!_.isNull(search.status) && search.status != '' && search.status != 'all') {
                service.filteredAccounts = _.filter(service.filteredAccounts, function(obj) {
                    return obj.status === search.status;
                });
            }

            if (!_.isNull(search.token) && search.token !== '') {
                service.filteredAccounts = _.filter(service.filteredAccounts, function(obj) {
                    return !_.isNull(obj.token) && obj.token.indexOf(search.token) !== -1;
                });
            }

            if (!_.isNull(search.domains) && search.domains !== '') {
                service.filteredAccounts = _.filter(service.filteredAccounts, function(obj) {
                    var combined = "";
                    if (!_.isNull(obj.domains)) {
                        combined = obj.domains.join("");
                    }
                    return !_.isNull(obj.domains) && combined.indexOf(search.domains) !== -1;
                });
            }

            if (!_.isNull(search.started.startDate) || !_.isNull(search.started.endDate)) {
                service.filteredAccounts = _.filter(service.filteredAccounts, function(obj) {
                    var startDate = moment(obj.date_started, 'YYYY-MM-DD h:mm:ss');
                    return search.started.startDate < startDate && startDate < search.started.endDate;
                });
            }

            if (!_.isNull(search.trial.startDate) || !_.isNull(search.trial.endDate)) {
                service.filteredAccounts = _.filter(service.filteredAccounts, function(obj) {
                    var startDate = moment(obj.date_trial_end, 'YYYY-MM-DD h:mm:ss');
                    return search.trial.startDate < startDate && startDate < search.trial.endDate;
                });
            }

            if (!_.isNull(search.expiration.startDate) || !_.isNull(search.expiration.endDate)) {
                service.filteredAccounts = _.filter(service.filteredAccounts, function(obj) {
                    var startDate = moment(obj.date_expiration, 'YYYY-MM-DD h:mm:ss');
                    return search.expiration.startDate < startDate && startDate < search.expiration.endDate;
                });
            }

            if (search.availableOnly) {
                switch (search.availableOnly) {
                    case 'all':
                        break;
                    case 'available':
                        service.filteredAccounts = _.filter(service.filteredAccounts, function(obj) {
                            return _.indexOf(service.originalStatsIds, obj.id) !== -1;
                        });
                        break;
                    case 'notavailable':
                        service.filteredAccounts = _.filter(service.filteredAccounts, function(obj) {
                            return _.indexOf(service.originalStatsIds, obj.id) === -1;
                        });
                        break;
                }
            }

            if (search.current) {
                service.filteredAccounts = _.filter(service.filteredAccounts, function(obj) {
                    return _.indexOf(service.filteredIds.current, obj.id) !== -1;
                });
            }

            if (search.converted) {
                service.filteredAccounts = _.filter(service.filteredAccounts, function(obj) {
                    return _.indexOf(service.filteredIds.converted, obj.id) !== -1;
                });
            }

            if (search.canceled) {
                service.filteredAccounts = _.filter(service.filteredAccounts, function(obj) {
                    return _.indexOf(service.filteredIds.canceled, obj.id) !== -1;
                });
            }

            service.filteredAccounts = _.sortBy(service.filteredAccounts, service.currentSort.key);
            if (service.currentSort.direction == 'desc') {
                service.filteredAccounts = service.filteredAccounts.reverse();
            }
        }

        function filterSearchTraffic(search) {
            service.filteredTraffic = angular.copy(service.allTraffic);

            if (!_.isNull(search.identifier) && search.identifier !== '') {
                service.filteredTraffic = _.filter(service.filteredTraffic, function(obj) {
                    return obj.identifier.toString().indexOf(search.identifier) !== -1;
                });
            }

            if (!_.isNull(search.registered.startDate) || !_.isNull(search.registered.endDate)) {
                service.filteredTraffic = _.filter(service.filteredTraffic, function(obj) {
                    var startDate = moment(obj.registered, 'YYYY-MM-DD h:mm:ss');
                    return search.registered.startDate < startDate && startDate < search.registered.endDate;
                });
            }

            if (search.trial_expiration !== 'all') {
                switch (search.trial_expiration) {
                    case 'expired':
                        service.filteredTraffic = _.filter(service.filteredTraffic, function(obj) {
                            return obj.trial_expiration <= 0;
                        });
                        break;
                    case 'week':
                        service.filteredTraffic = _.filter(service.filteredTraffic, function(obj) {
                            return obj.trial_expiration <= 7 && obj.trial_expiration > 0;
                        });
                        break;
                    case 'fifteen':
                        service.filteredTraffic = _.filter(service.filteredTraffic, function(obj) {
                            return obj.trial_expiration <= 15 && obj.trial_expiration > 0;
                        });
                        break;
                }
            }

            if (search.traffic !== 'all') {
                switch (search.traffic) {
                    case 'none':
                        service.filteredTraffic = _.filter(service.filteredTraffic, function(obj) {
                            return obj.traffic == 0;
                        });
                        break;
                    case 'danger':
                        service.filteredTraffic = _.filter(service.filteredTraffic, function(obj) {
                            return obj.traffic < service.trafficBreaks.danger.amount && obj.traffic > 0;
                        });
                        break;
                    case 'warning':
                        service.filteredTraffic = _.filter(service.filteredTraffic, function(obj) {
                            return obj.traffic < service.trafficBreaks.warning.amount && obj.traffic > 0;
                        });
                        break;
                    case 'success':
                        service.filteredTraffic = _.filter(service.filteredTraffic, function(obj) {
                            return obj.traffic >= service.trafficBreaks.warning.amount && obj.traffic > 0;
                        });
                        break;
                }
            }

            if (!_.isNull(search.domains) && search.domains !== '') {
                service.filteredTraffic = _.filter(service.filteredTraffic, function(obj) {
                    return !_.isNull(obj.domains) ?
                        obj.domains.join(",").indexOf(search.domains) !== -1 : false;
                });
            }

            if (search.status !== 'all') {
                service.filteredTraffic = _.filter(service.filteredTraffic, function(obj) {
                    return obj.status == search.status;
                });
            }
        }

        function filterSearchTrialLinks(search) {
            service.filteredTrialLinks = angular.copy(service.allTrialLinks);

            if (search.claimed !== 'all') {
                switch (search.claimed) {
                    case 'claimed':
                        service.filteredTrialLinks = _.filter(service.filteredTrialLinks, function(obj) {
                            return !_.isNull(obj.account_identifier);
                        });
                        break;
                    case 'unclaimed':
                        service.filteredTrialLinks = _.filter(service.filteredTrialLinks, function(obj) {
                            return _.isNull(obj.account_identifier);
                        });
                        break;
                }
            }

            if (!_.isNull(search.email) && search.email !== '') {
                service.filteredTrialLinks = _.filter(service.filteredTrialLinks, function(obj) {
                    return obj.email.indexOf(search.email) !== -1;
                });
            }

            if (!_.isNull(search.time_since_creation)) {
                switch (search.time_since_creation) {
                    case 'day':
                        service.filteredTrialLinks = _.filter(service.filteredTrialLinks, function(obj) {
                            return obj.time_since_creation <= 24;
                        });
                        break;
                    case 'week':
                        service.filteredTrialLinks = _.filter(service.filteredTrialLinks, function(obj) {
                            return obj.time_since_creation <= 168;
                        });
                        break;
                    case 'greater':
                        service.filteredTrialLinks = _.filter(service.filteredTrialLinks, function(obj) {
                            return obj.time_since_creation > 168;
                        });
                        break;
                }
            }

            if (!_.isNull(search.time_to_claim)) {
                switch (search.time_to_claim) {
                    case 'day':
                        service.filteredTrialLinks = _.filter(service.filteredTrialLinks, function(obj) {
                            return obj.time_to_claim <= 24 && !_.isNull(obj.time_to_claim);
                        });
                        break;
                    case 'week':
                        service.filteredTrialLinks = _.filter(service.filteredTrialLinks, function(obj) {
                            return obj.time_to_claim <= 168 && !_.isNull(obj.time_to_claim);
                        });
                        break;
                    case 'greater':
                        service.filteredTrialLinks = _.filter(service.filteredTrialLinks, function(obj) {
                            return obj.time_to_claim > 168 && !_.isNull(obj.time_to_claim);
                        });
                        break;
                }
            }

            if (!_.isNull(search.claimed_at.startDate) || !_.isNull(search.claimed_at.endDate)) {
                service.filteredTrialLinks = _.filter(service.filteredTrialLinks, function(obj) {
                    var startDate = moment(obj.claimed_at, 'YYYY-MM-DD h:mm:ss');
                    return search.claimed_at.startDate < startDate && startDate < search.claimed_at.endDate;
                });
            }

            if (!_.isNull(search.account_identifier) && search.account_identifier !== '') {
                service.filteredTrialLinks = _.filter(service.filteredTrialLinks, function(obj) {
                    return !_.isNull(obj.account_identifier) ?
                        obj.account_identifier.toString().indexOf(search.account_identifier) !== -1 : false;
                });
            }

            if (!_.isNull(search.domains) && search.domains !== '') {
                service.filteredTrialLinks = _.filter(service.filteredTrialLinks, function(obj) {
                    return !_.isNull(obj.domains) ?
                        obj.domains.join(",").indexOf(search.domains) !== -1 : false;
                });
            }
        }

        function postFindBandwidthSaved(start, end, accounts) {
            return resource.postFindBandwidthSaved({}, {start: start, end: end, accounts: accounts}).$promise;
        }

        function postFindCache(start, end, accounts) {
            return resource.postFindCache({}, {start: start, end: end, accounts: accounts}).$promise;
        }

        function postFindFormatSavings(start, end, accounts) {
            return resource.postFindFormatSavings({}, {start: start, end: end, accounts: accounts}).$promise;
        }

        function postFindPayload(start, end, accounts) {
            return resource.postFindPayload({}, {start: start, end: end, accounts: accounts}).$promise;
        }

        function postFindRequests(start, end, accounts) {
            return resource.postFindRequests({}, {start: start, end: end, accounts: accounts}).$promise;
        }

        function removeBandwidth(id, chart, breakdown) {
            if (!breakdown) {
                var indx = _.findIndex(chart.cols, function(obj) {
                    return obj.label == id;
                });
                chart.cols = _.toArray(_.omit(chart.cols, indx));
                angular.forEach(chart.rows, function(row) {
                    row.c = _.toArray(_.omit(row.c, indx))
                });
            } else {
                var titles = _.pluck(_.filter(service.originalData.bandwidthBreakdown.columns, function(obj) {
                    return obj.id == id;
                }), 'title');
                var keys = [];
                angular.forEach(chart.cols, function(obj, key) {
                    if (_.indexOf(titles, obj.label) !== -1) {
                        keys.push(parseInt(key));
                    }
                });
                chart.cols = _.reject(chart.cols, function(obj) {
                    return _.indexOf(titles, obj.label) !== -1;
                });
                angular.forEach(chart.rows, function(row) {
                    row.c = _.toArray(_.omit(row.c, function(val, key) {
                        return _.indexOf(keys, parseInt(key)) !== -1;
                    }));
                });
            }

            return chart;
        }

        function removeCache(chart, id, breakdown) {
            if (!breakdown) {
                chart.rows = _.toArray(_.omit(chart.rows, function(row, key) {
                    return parseInt(row.c[0].v) == id;
                }));
            } else {
                var keys = _.pluck(_.filter(service.originalData.cacheBreakdown, function(obj) {
                    return obj.id == id;
                }), 'key');

                chart.rows = _.toArray(_.omit(chart.rows, function(row, key) {
                    return _.indexOf(keys, row.c[0].v) !== -1;
                }));
            }

            return chart;
        }

        function removeFormat(chart, id, breakdown) {
            if (!breakdown) {
                chart.rows = _.toArray(_.omit(chart.rows, function(obj, key) {
                    return parseInt(obj.c[0].v) == id;
                }));
            } else {
                var titles = _.keys(_.pick(service.originalData.formatsBreakdown, function(obj) {
                    return obj.id == id;
                }));
                chart.rows = _.toArray(_.omit(chart.rows, function(row, key) {
                    return _.indexOf(titles, row.c[0].v) !== -1;
                }));
            }

            return chart;
        }

        function removePayload(chart, id, breakdown) {
            if (!breakdown) {
                chart.rows = _.toArray(_.omit(chart.rows, function(obj, key) {
                    return parseInt(obj.c[0].v) == id;
                }));
            } else {
                var titles = _.pluck(_.filter(service.originalData.payloadBreakdown, function(obj) {
                    return obj.id == id;
                }), 'key');

                chart.rows = _.toArray(_.omit(chart.rows, function(row, key) {
                    return _.indexOf(titles, row.c[0].v) !== -1;
                }));
            }

            return chart;
        }

        function removeRequest(data, id, breakdown) {
            if (!breakdown) {
                var indx = _.findIndex(data.cols, function(obj) {
                    return obj.label == id;
                });
                data.cols = _.toArray(_.omit(data.cols, indx));
                angular.forEach(data.rows, function(row) {
                    row.c = _.toArray(_.omit(row.c, indx))
                });
            } else {
                var titles = _.keys(_.pick(service.originalData.requestsBreakdown[0].buckets, function(bucket) {
                    return bucket.id == id;
                }));
                var indx = [];
                angular.forEach(titles, function(title) {
                    indx.push(_.findIndex(data.cols, function(col) {
                        return col.label == title;
                    }));
                });
                data.cols = _.toArray(_.omit(data.cols, indx));
                angular.forEach(data.rows, function(row) {
                    row.c = _.toArray(_.omit(row.c, indx));
                });
            }

            return data;
        }

        function setBandwidth(accounts, map) {
            if (map) {
                var data = _.map(service.originalData.bandwidth, function(obj) {
                    return {
                        date: obj.date,
                        buckets: _.omit(obj.buckets, function(value, key) {
                            return _.isUndefined(_.find(accounts, function(acc) {
                                return acc.id == key;
                            }));
                        })
                    };
                });
            } else {
                var data = service.originalData.bandwidth;
                service.selectedAccounts.bandwidth = _.map(_.keys(data[0].buckets), function(id) {
                    return _.findWhere(service.allAccounts, {id: parseInt(id)});
                });
            }

            var cols = angular.copy(service.bandwidthChart.data.cols);
            angular.forEach(data[0].buckets, function(val, key) {
                cols.push({
                    id: 'bandwidth_saved',
                    label: key,
                    type: "number"
                });
            });

            var rows = [];
            angular.forEach(data, function(obj) {
                var row = {
                    c: [
                        {v: obj.date}
                    ]
                };
                angular.forEach(obj.buckets, function(val, key) {
                    row.c.push({
                        v: val
                    });
                });
                rows.push(row);
            });

            return {
                cols: cols,
                rows: rows
            };
        }

        function setBandwidthBreakdown(accounts) {
            var cols = [];
            var indx = [];

            angular.forEach(service.originalData.bandwidthBreakdown.columns, function(col, key) {
                if (!_.isUndefined(col.id) && !_.isUndefined(_.find(accounts, function(acc) {
                    return col.id == acc.id;
                }))) {
                    indx.push(key);
                    cols.push({
                        id: 'bandwidth_saved',
                        label: col.title,
                        type: 'number'
                    })
                } else if (_.isUndefined(col.id)) {
                    cols.push({
                        id: 'date',
                        label: 'Date',
                        type: 'string'
                    });
                    indx.push(key);
                }
            });

            var rows = _.map(service.originalData.bandwidthBreakdown.rows, function(row) {
                var newRow = [];
                angular.forEach(row, function(val, key) {
                    if (_.indexOf(indx, key) !== -1) {
                        newRow.push(val);
                    }
                });
                newRow = _.map(newRow, function(v) {
                    return {v: v};
                });

                return {
                    c: newRow
                };
            });

            return {
                cols: cols,
                rows: rows
            };
        }

        function setCache(type, breakdown, accounts, map) {
            var cols = [];
            var rows = [];

            if (!breakdown && map) {
                var data = _.pick(service.originalData.cache, function(obj, key) {
                    return !_.isUndefined(_.findWhere(accounts, {id: parseInt(key)}));
                });
            } else if (!breakdown) {
                var data = service.originalData.cache;
                service.selectedAccounts.cache = _.map(_.keys(data), function(id) {
                    return _.findWhere(service.allAccounts, {id: parseInt(id)});
                });
            } else if (breakdown && map) {
                var data = _.map(service.originalData.cacheBreakdown, function(obj) {
                    if (!_.isUndefined(_.findWhere(accounts, {id: parseInt(obj.id)}))) {
                        return obj;
                    }
                });
            } else {
                var data = service.originalData.cacheBreakdown;
                service.selectedAccounts.cache = _.map(data, function(row) {
                    return _.findWhere(service.allAccounts, {id: parseInt(row.id)});
                });
            }

            data = _.omit(data, function(value) {
                return _.isUndefined(value);
            });
            if (breakdown) {
                data = _.toArray(data);
            }

            if (type == 'count') {
                cols = [
                    {
                        id: 'account',
                        label: "Account",
                        type: "string"
                    },
                    {
                        id: 'hits',
                        label: 'Hits',
                        type: "number"
                    },
                    {
                        id: 'miss',
                        label: 'Miss',
                        type: 'number'
                    }
                ];

                if (!breakdown) {
                    angular.forEach(data, function(row, id) {
                        rows.push({
                            c: [
                                {v: id},
                                {v: row.hits},
                                {v: row.miss}
                            ]
                        });
                    });
                } else {
                    angular.forEach(data, function(row) {
                        rows.push({
                            c: [
                                {v: row.key},
                                {v: row.hits},
                                {v: row.miss}
                            ]
                        });
                    });
                }
            } else {
                cols = [
                    {
                        id: 'account',
                        label: "Account",
                        type: "string"
                    },
                    {
                        id: 'hits_percentage',
                        label: 'Hits Percentage',
                        type: "number"
                    }
                ];

                if (!breakdown) {
                    angular.forEach(data, function(row, id) {
                        rows.push({
                            c: [
                                {v: id},
                                {v: row.hits_percentage}
                            ]
                        });
                    });
                } else {
                    angular.forEach(data, function(row) {
                        rows.push({
                            c: [
                                {v: row.key},
                                {v: row.hits_percentage}
                            ]
                        });
                    });
                }
            }

            return {
                cols: cols,
                rows: rows
            };
        }

        function setFormats(accounts, map) {
            var cols = angular.copy(service.formatChart.data.cols);
            var rows = [];

            if (map) {
                var data = _.pick(service.originalData.formats, function(obj) {
                    return !_.isUndefined(_.findWhere(accounts, {id: parseInt(obj.id)}));
                });
            } else {
                var data = service.originalData.formats;
                service.selectedAccounts.formats = _.map(data, function(obj) {
                    return _.findWhere(service.allAccounts, {id: parseInt(obj.id)});
                });
            }
            data = _.toArray(data);

            angular.forEach(data[0].formats, function(value, format) {
                cols.push({
                    id: format,
                    label: format,
                    type: 'number'
                });
            });

            angular.forEach(data, function(obj, key) {
                var row = [
                    {v: obj.id}
                ];
                angular.forEach(obj.formats, function(value, key) {
                    row.push({v: value});
                });
                rows.push({
                    c: row
                });
            });

            return {
                cols: cols,
                rows: rows
            };
        }

        function setFormatsBreakdown(accounts) {
            var cols = angular.copy(service.formatChart.data.cols);
            var rows = [];

            var data = {};

            angular.forEach(service.originalData.formatsBreakdown, function(obj, key) {
                if (!_.isUndefined(_.findWhere(accounts, {id: parseInt(obj.id)}))) {
                    data[key] = obj;
                }
            });

            var formats = _.pluck(data, 'formats');
            angular.forEach(formats[0], function(val, key) {
                cols.push({
                    id: key,
                    label: key,
                    type: 'number'
                });
            });

            angular.forEach(data, function(obj, key) {
                var row = [
                    {v: key}
                ];
                angular.forEach(obj.formats, function(val, format) {
                    row.push({
                        v: val
                    });
                });
                rows.push({c: row});
            });

            return {
                cols: cols,
                rows: rows
            };
        }

        function setPayload(accounts, map) {
            if (map) {
                var data = _.omit(service.originalData.payload, function(obj, key) {
                    return _.isUndefined(_.find(accounts, function(acc) {
                        return acc.id == key;
                    }));
                });
            } else {
                var data = service.originalData.payload;
                service.selectedAccounts.payload = _.map(_.keys(data), function(id) {
                    return _.findWhere(service.allAccounts, {id: parseInt(id)});
                });
            }

            var rows = [];
            angular.forEach(data, function(obj, key) {
                var account = _.findWhere(service.allAccounts, {id: parseInt(key)});
                if (!_.isUndefined(obj)) {
                    rows.push({
                        c: [
                            {v: key},
                            {v: obj.bytes_orig},
                            {v: obj.bytes},
                            {v: "ImageEngine: "+obj.bytes+" = "+obj.savings+"% savings"}
                        ]
                    });
                }
            });

            return rows;
        }

        function setPayloadBreakdown(accounts) {
            var rows = [];
            angular.forEach(service.originalData.payloadBreakdown, function(obj) {
                if (!_.isUndefined(_.find(accounts, function(acc) {
                    return acc.id == obj.id;
                }))) {
                    rows.push({
                        c: [
                            {v: obj.key},
                            {v: obj.bytes_orig},
                            {v: obj.bytes},
                            {v: "ImageEngine: "+obj.bytes+" = "+obj.savings+"% savings"}
                        ]
                    });
                }
            });

            return rows;
        }

        function setRequests(accounts, map) {
            var cols = angular.copy(service.requestChart.data.cols);
            var rows = [];

            if (map) {
                var data = _.map(angular.copy(service.originalData.requests), function(obj) {
                    obj.buckets = _.pick(obj.buckets, function(bucket, key) {
                        return !_.isUndefined(_.findWhere(accounts, {id: parseInt(key)}));
                    });
                    return obj;
                });
            } else {
                var data = angular.copy(service.originalData.requests);
                service.selectedAccounts.requests = _.map(_.keys(data[0].buckets), function(id) {
                    return _.findWhere(service.allAccounts, {id: parseInt(id)});
                });
            }

            angular.forEach(data[0].buckets, function(val, key) {
                cols.push({
                    id: 'account',
                    label: key,
                    type: "number"
                });
            });

            angular.forEach(data, function(obj) {
                var row = {
                    c: [
                        {v: obj.date}
                    ]
                };
                angular.forEach(obj.buckets, function(val, key) {
                    row.c.push({v: val});
                });
                rows.push(row);
            });

            return {
                cols: cols,
                rows: rows
            };
        }

        function setRequestsBreakdown(accounts) {
            var cols = angular.copy(service.requestChart.data.cols);
            var rows = [];

            var data = _.map(angular.copy(service.originalData.requestsBreakdown), function(obj) {
                obj.buckets = _.pick(obj.buckets, function(bucket, key) {
                    return !_.isUndefined(_.findWhere(accounts, {id: parseInt(bucket.id)}));
                });
                return obj;
            });

            angular.forEach(data[0].buckets, function(bucket, key) {
                cols.push({
                    id: 'account',
                    label: key,
                    type: "number"
                });
            });

            angular.forEach(data, function(obj) {
                var row = [
                    {v: obj.date}
                ];
                angular.forEach(obj.buckets, function(bucket) {
                    row.push({v: bucket.value});
                });
                rows.push({c: row});
            });

            return {
                cols: cols,
                rows: rows
            };
        }

        function sort(name) {
            var direction = "asc";
            if (service.currentSort.key == name) {
                switch (service.currentSort.direction) {
                    case 'asc':
                        direction = 'desc';
                        break;
                    case 'desc':
                    default:
                        direction = 'asc';
                        break;
                }
                service.filteredAccounts = service.filteredAccounts.reverse();
            } else {
                service.currentSort.key = name;
                switch (name) {
                    case 'started':
                        service.filteredAccounts = _.sortBy(service.filteredAccounts, function(obj) {
                            return (new Date(obj.date_started));
                        });
                        break;
                    case 'trial':
                        service.filteredAccounts = _.sortBy(service.filteredAccounts, function(obj) {
                            return (new Date(obj.date_trial_end));
                        });
                        break;
                    case 'expiration':
                        service.filteredAccounts = _.sortBy(service.filteredAccounts, function(obj) {
                            return (new Date(obj.date_expiration));
                        });
                        break;
                    default:
                        service.filteredAccounts = _.sortBy(service.filteredAccounts, name);
                }
            }

            service.currentSort.direction = direction;
        }

        function trafficSort(name) {
            var direction = "asc";

            if (service.trafficCurrentSort.key == name) {
                switch (service.trafficCurrentSort.direction) {
                    case 'asc':
                        direction = 'desc';
                        break;
                    case 'desc':
                    default:
                        direction = 'asc';
                        break;
                }
                service.filteredTraffic = service.filteredTraffic.reverse();
            } else {
                service.trafficCurrentSort.key = name;
                switch (name) {
                    case 'registered':
                        service.filteredTraffic = _.sortBy(service.filteredTraffic, function(obj) {
                            return (new Date(obj.registered));
                        });
                        break;
                    case 'identifier':
                    case 'domains':
                        service.filteredTraffic.sort(service.alphabetically(direction, name));
                        break;
                    default:
                        service.filteredTraffic = _.sortBy(service.filteredTraffic, name);
                }
            }

            service.trafficCurrentSort.direction = direction;
        }

        function trialLinksSort(name) {
            var direction = "asc";
            if (service.trialLinksCurrentSort.key == name) {
                switch (service.trialLinksCurrentSort.direction) {
                    case 'asc':
                        direction = 'desc';
                        break;
                    case 'desc':
                    default:
                        direction = 'asc';
                        break;
                }
                service.filteredTrialLinks = service.filteredTrialLinks.reverse();
            } else {
                service.trialLinksCurrentSort.key = name;
                switch (name) {
                    case 'claimed_at':
                        service.filteredTrialLinks = _.sortBy(service.filteredTrialLinks, function(obj) {
                            return (new Date(obj.claimed_at));
                        });
                        break;
                    case 'time_since_creation':
                        service.filteredTrialLinks = _.sortBy(service.filteredTrialLinks, name);
                        break;
                    default:
                        service.filteredTrialLinks.sort(service.alphabetically(direction, name));
                }
            }

            service.trialLinksCurrentSort.direction = direction;
        }

        function updatePagination(itemsPerPage, current) {
            var accounts = _.chunk(service.filteredAccounts, itemsPerPage);
            var numPages = accounts.length;
            var paginationData = {};
            var to = (itemsPerPage*(current - 1) + 1) + (itemsPerPage - 1);
            paginationData.pages = paginationService.createPages(current, numPages);
            paginationData.numPages = numPages;
            paginationData.currentPage = current;
            paginationData.from = itemsPerPage*(current - 1) + 1;
            paginationData.to = service.filteredAccounts.length >= to ? to : service.filteredAccounts.length;
            paginationData.total = service.allAccounts.length;
            paginationData.filtered = service.filteredAccounts.length;

            return {
                accounts: accounts,
                paginationData: paginationData,
                chunk: current -1
            };
        }

        function updatePaginationTraffic(current) {
            var links = _.chunk(service.filteredTraffic, 10);
            var numPages = links.length;
            var paginationData = {};
            var to = (10*(current - 1) + 1) + (10 - 1);
            paginationData.pages = paginationService.createPages(current, numPages);
            paginationData.numPages = numPages;
            paginationData.currentPage = current;
            paginationData.from = 10*(current - 1) + 1;
            paginationData.to = service.filteredTraffic.length >= to ? to : service.filteredTraffic.length;
            paginationData.total = service.allTraffic.length;
            paginationData.filtered = service.filteredTraffic.length;

            return {
                traffic: links,
                paginationData: paginationData,
                chunk: current -1
            };
        }

        function updatePaginationTrialLinks(current) {
            var links = _.chunk(service.filteredTrialLinks, 10);
            var numPages = links.length;
            var paginationData = {};
            var to = (10*(current - 1) + 1) + (10 - 1);
            paginationData.pages = paginationService.createPages(current, numPages);
            paginationData.numPages = numPages;
            paginationData.currentPage = current;
            paginationData.from = 10*(current - 1) + 1;
            paginationData.to = service.filteredTrialLinks.length >= to ? to : service.filteredTrialLinks.length;
            paginationData.total = service.allTrialLinks.length;
            paginationData.filtered = service.filteredTrialLinks.length;

            return {
                links: links,
                paginationData: paginationData,
                chunk: current -1
            };
        }
    }
})();
