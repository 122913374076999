(function() {
    'use strict';

    angular.module('adminApp.coreapi')
        .controller('CoreApiNewScopeModalController', CoreApiNewScopeModalController);

    CoreApiNewScopeModalController.$inject = ['$rootScope', '$state', '$uibModalInstance', 'coreApiScopeService', 'Notification']

    function CoreApiNewScopeModalController($rootScope, $state, $uibModalInstance, coreApiScopeService, Notification) {
        var vm = this;

        vm.close = close;
        vm.formErrors = {};
        vm.modalOptions = {
            actionClass: 'alert-info',
            actionMessage: 'Saving...',
            proceedButton: {
                btnClass: 'btn-primary',
                btnSpanClass: 'glyphicon-floppy-save',
                btnText: 'Save'
            },
            template: 'view/coreapi.modals.scopes_modal',
            title: 'Save CoreApi Scope'
        };
        vm.proceed = proceed;
        vm.scope = {};
        vm.showActionSpinner = false;

        function close() {
            $uibModalInstance.dismiss();
        }

        function proceed() {
            vm.formErrors = {};
            vm.showActionSpinner = true;
            if (vm.scope.scope == undefined || vm.scope.scope == '') {
                vm.formErrors.scope = 'The scope field is required';
            }
            if (vm.scope.name == undefined || vm.scope.name == '') {
                vm.formErrors.name = 'The name field is required';
            }
            if (_.isEmpty(vm.formErrors)) {
                coreApiScopeService.submit(vm.scope).then(function(response) {
                    $state.go('scopes.view', {id: response.data.scope});
                    $uibModalInstance.dismiss();
                }).catch(function(error) {
                    console.log(error);
                    vm.formErrors = error.data.data;
                }).finally(function() {
                    vm.showActionSpinner = false;
                });
            } else {
                vm.showActionSpinner = false;
            }
        }
    }
})();
