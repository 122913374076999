(function() {
    'use strict';

    angular.module('adminApp')
        .directive('smartTable', smartTable);

    function smartTable() {
        var directive = {
            restrict: 'EA',
            replace: true,
            templateUrl: 'view/components.smarttable',
            scope: {
                columns: '=',
                tableType: '@',
                searchParams: '=',
                includeView: '@'
            },
            controller: 'SmartTableController',
            controllerAs: 'vm'
        };

        return directive;
    }
})();
