(function() {
    'use strict';

    angular.module('adminApp.licenses')
        .directive('productLevel', productLevel);

    productLevel.$inject = ['$rootScope', 'asOptionsFactory'];

    function productLevel($rootScope, asOptionsFactory) {
        var directive = {
            restrict: 'E',
            transclude: true,
            replace: true,
            template: '<div><input class="form-control" placeholder="loading product levels, please wait..." ng-if="product_level_disable" disabled><select ng-if="!product_level_disable" class="form-control" ng-disabled="product_level_disable" id="product_level" name="product_level" ng-model="current" ng-options="option.id as option.name for option in product_levels" ng-change="updateProductLevel(current)"></select></div>',
            scope: {
                current: '=model'
            },
            link: link,
            controller: controller
        };
        return directive;

        function link(scope, element, attrs) {
            scope.product_level_disable = true;
        }

        function controller($scope, $rootScope) {
            var columns = "id,level_name?orderBy=level_name&order=asc";

            asOptionsFactory.asOptions('ProductLevel', columns).$promise
                .then(function(response) {
                    $scope.product_levels = response.data;
                    $scope.product_level_disable = false;
                    $rootScope.$broadcast('loadedProductLevelDirective');
                });

            $scope.updateProductLevel = function(value) {
                $rootScope.$broadcast('updateProductLevelDirective', {id: value});
            };
        }
    }
})();
