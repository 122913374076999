(function() {
    'use strict';

    angular.module('adminApp.home')
        .controller('ChangelogModalController', ChangelogModalController);

    ChangelogModalController.$inject = ['$uibModalInstance', 'next', 'previous'];

    function ChangelogModalController($uibModalInstance, next, previous) {
        var vm = this;

        vm.close = close;
        vm.columns = {
            previous: 'col-md-6',
            next: 'col-md-6'
        };
        vm.next = next;
        vm.previous = previous;

        if (_.isNull(vm.next)) {
            vm.columns.previous = 'col-md-12';
        }
        if (_.isNull(vm.previous)) {
            vm.columns.next = 'col-md-12';
        }

        function close() {
            $uibModalInstance.dismiss();
        }
    }
})();
