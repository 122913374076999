(function() {
    'use strict';

    angular.module('adminApp.cut')
        .factory('cutModalService', cutModalService);

    cutModalService.$inject = ['$filter', '$ngConfirm', '$rootScope', '$state', '$uibModal', 'centraltestAssertionsService', 'centraltestHttpHeaders', 'centraltestHttpRequestsService', 'centraltestTestsService', 'centraltestXmlService', 'Notification'];

    function cutModalService($filter, $ngConfirm, $rootScope, $state, $uibModal, centraltestAssertionsService, centraltestHttpHeaders, centraltestHttpRequestsService, centraltestTestsService, centraltestXmlService, Notification) {
        var service = {
            create: create,
            edit: edit,
            export: exportXml,
            remove: remove,
            view: view
        };
        return service;

        function factory(type) {
            switch (type) {
                case 'assertion':
                    return centraltestAssertionsService;
                    break;
                case 'header':
                    return centraltestHttpHeaders;
                    break;
                case 'request':
                    return centraltestHttpRequestsService;
                    break;
                case 'test':
                    return centraltestTestsService;
                    break;
            }
        }

        function template(type) {
            switch (type) {
                case 'assertion':
                    return 'view/centraltests.modals.assertion';
                    break;
                case 'header':
                    return 'view/centraltests.modals.header';
                    break;
                case 'request':
                    return 'view/centraltests.modals.request';
                    break;
                case 'test':
                    return 'view/centraltests.modals.test';
                    break;
            }
        }

        function create(type, ids, idType, numPages, form, open, disabled) {
            var form = form === undefined ? {} : form;
            var idDisabled = disabled === undefined ? false : disabled;
            var open = open === undefined ? true : open;
            var contentView = template(type);
            var f = factory(type);
            var typeCap = $filter('capitalize')(type);
            var title = 'Create New '+typeCap;
            $uibModal.open({
                templateUrl: 'view/centraltests.modals.modal_container',
                keyboard: false,
                backdrop: 'static',
                resolve: {
                    items: function() {
                        return {
                            action: 'create',
                            creating: true,
                            f: f,
                            form: form,
                            disabled: false,
                            idDisabled: idDisabled,
                            ids: ids,
                            idType: idType,
                            include: contentView,
                            numPages: numPages,
                            open: open,
                            title: title,
                            type: type
                        }
                    }
                },
                controller: 'CutModalController',
                controllerAs: 'vm'
            });
        }

        function edit(type, id, open) {
            var open = open === undefined ? true : open;
            var contentView = template(type);
            var f = factory(type);
            var form = {};
            var typeCap = $filter('capitalize')(type)
            var title = 'Edit '+typeCap;
            f.get(id).then(function(response) {
                form = response.data;
                form.id = form.id === undefined ? id : form.id;
                $uibModal.open({
                    templateUrl: 'view/centraltests.modals.modal_container',
                    keyboard: false,
                    backdrop: 'static',
                    resolve: {
                        items: function() {
                            return {
                                action: 'edit',
                                creating: false,
                                f: f,
                                form: form,
                                disabled: false,
                                idDisabled: true,
                                ids: [],
                                idType: null,
                                include: contentView,
                                numPages: null,
                                open: open,
                                title: title,
                                type: type
                            }
                        }
                    },
                    controller: 'CutModalController',
                    controllerAs: 'vm'
                });
            });
        }

        function exportXml() {
            $ngConfirm({
                title: 'Export XML Tests',
                theme: 'dark',
                columnClass: 'col-md-8',
                contentUrl: 'view/centraltests.export',
                buttons: {
                    close: {
                        text: 'Cancel',
                        btnClass: 'btn-danger'
                    },
                    proceed: {
                        text: 'Export',
                        btnClass: 'btn-primary',
                        action: function(scope) {
                            if (!scope.all && !scope.type_unit && !scope.type_regression) {
                                $ngConfirm({
                                    title: 'Alert!',
                                    theme: 'dark',
                                    content: '<p>Please select one or more lists to export</p>',
                                    buttons: {
                                        close: {
                                            text: 'OK',
                                            btnClass: 'btn-danger'
                                        }
                                    }
                                });
                                return false;
                            }
                            centraltestXmlService.export(scope.all, scope.type_unit, scope.type_regression);
                        }
                    }
                },
                onScopeReady: function(scope) {
                    scope.all = false;
                    scope.autoChecked = autoChecked;
                    scope.type_regression = false;
                    scope.type_unit = false;

                    function autoChecked() {
                        if (scope.all) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                }
            });
        }

        function remove(type, id) {
            var f = factory(type);
            var typeCap = $filter('capitalize')(type)
            $ngConfirm({
                title: 'Confirm?',
                theme: 'dark',
                content: 'Are you sure you want to delete this '+type+'?',
                buttons: {
                    close: {
                        text: 'Cancel',
                        btnClass: 'btn-blue'
                    },
                    proceed: {
                        text: '<strong>OK</strong>',
                        btnClass: 'btn-red',
                        action: function(button){
                            f.remove(id).then(function() {
                                Notification.success('Removed '+typeCap);
                                $rootScope.$broadcast('reload'+typeCap+'s', {type: 'remove', id: id});
                            }).catch(function(error) {
                                Notification.error("Could not remove "+type);
                                console.log(error);
                            });
                        }
                    }
                }
            });
        }

        function view(type, id, open) {
            var open = open === undefined ? true : open;
            var contentView = template(type);
            var f = factory(type);
            var form = {};
            var title = 'View '+$filter('capitalize')(type);
            f.get(id).then(function(response) {
                form = response.data;
                $uibModal.open({
                    templateUrl: 'view/centraltests.modals.modal_container',
                    keyboard: false,
                    backdrop: 'static',
                    resolve: {
                        items: function() {
                            return {
                                action: 'view',
                                creating: false,
                                f: f,
                                form: form,
                                disabled: true,
                                idDisabled: true,
                                ids: [],
                                idType: null,
                                include: contentView,
                                numPages: null,
                                open: open,
                                title: title,
                                type: type
                            }
                        }
                    },
                    controller: 'CutModalController',
                    controllerAs: 'vm'
                });
            }).catch(function(error) {
                console.log(error);
            });
        }
    }
})();
