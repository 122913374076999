(function() {
    'use strict';

    angular.module('adminApp.wurfl')
        .directive('capability', capability);

    capability.$inject = ['$compile', 'capabilitiesService']

    function capability($compile, capabilitiesService) {
        var directive = {
            restrict: 'E',
            transclude: true,
            replace: true,
            template: '<input type="text" autocomplete="off" ng-disabled="disable" uib-typeahead="option as option.capability for option in capabilities | filter:$viewValue | limitTo:10" typeahead-on-select="getDescription($item)" typeahead-min-length="0">',
            link: link
        };

        return directive;

        function link(scope, element, attrs) {
            scope.capabilities = [];
            scope.disable = true;
            scope.getDescription = getDescription;

            element.attr("placeholder", attrs.preholder);
            capabilitiesService.getData({paginate: 100000}).then(function(response) {
                scope.capabilities = response.data;
                element.attr('placeholder',attrs.placeholder);
                scope.disable = false;
            });

            function getDescription($item) {
                capabilitiesService.selectedCapability = {
                    description: $item.description,
                    group: $item.group.group_name,
                    aspect: $item.group.aspect_name
                };
            }
        }
    }
})();
