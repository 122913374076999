(function() {
    'use strict';

    angular.module('adminApp.cloud')
        .controller('CloudTotalUsageHistoryController', CloudTotalUsageHistoryController);

    CloudTotalUsageHistoryController.$inject = ['$filter', 'breadcrumbsService', 'cloudAccountService', 'Notification'];

    function CloudTotalUsageHistoryController($filter, breadcrumbsService, cloudAccountService, Notification) {
        var vm = this;

        vm.chart = {
            type: "AnnotationChart",
            options: {
                displayAnnotations: true
            }
        };
        vm.dataExists = false;
        vm.loaded = false;
        vm.period = '10';
        vm.run = run;
        vm.steps = breadcrumbsService.get('cloud.usage');

        var data = [];

        run();

        function run() {
            vm.loaded = false;
            cloudAccountService.getTotalUsageStats(vm.period).then(function(response) {
                data = response.data;
                if (data.length > 0) {
                    vm.dataExists = true;
                    init();
                } else {
                    vm.loaded = true;
                }
            }).catch(function(error) {
                console.log(error);
                Notification.error("Could not gather usage history");
            });
        }

        function init() {
            var rows = [];
            angular.forEach(data, function(val, key) {
                var date = $filter('createDate')({date: val.start_date, format: 'YMD HIS'});
                rows.push(
                    {
                        c: [{
                            v: date
                        }, {
                            v: parseInt(val.detections)
                        }]
                    }
                );
            })
            vm.chart.data = {
                "cols": [
                    {
                        id: "sample-date",
                        label: "Date",
                        type: "datetime"
                    },
                    {
                        id: 'detections',
                        label: 'Detections',
                        type: 'number'
                    }
                ],
                "rows": rows
            };

            vm.loaded = true;
        }
    }
})();
