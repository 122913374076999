(function() {
    'use strict';

    angular.module('adminApp.licenses')
        .factory('asOptionsFactory', asOptionsFactory);

    asOptionsFactory.$inject = ['$resource'];

    function asOptionsFactory($resource) {
        var resourceUrl = "";

        var actions = {
            asOptions: {
                method: "GET",
                cache: true
            },
            asBuildArchOptions: {
                method: "GET",
                cache: true
            },
            asBuildOsOptions: {
                method: "GET",
                cache: true
            }
        };

        var service = {
            asOptions: asOptions,
            asBuildArchOptions: asBuildArchOptions,
            asBuildOsOptions: asBuildOsOptions
        };

        return service;

        function asOptions(model, columns) {
            actions.asOptions.url = "/api/admin/ng-options/"+model+"/columns/"+columns;
            var Api = $resource(resourceUrl, {}, actions);
            return Api.asOptions();
        }

        function asBuildArchOptions(build_product_id) {
            actions.asBuildArchOptions.url = "/api/admin/build-arch-options/"+build_product_id;
            var Api = $resource(resourceUrl, {}, actions);
            return Api.asBuildArchOptions();
        }

        function asBuildOsOptions(build_product_id) {
            actions.asBuildOsOptions.url = "/api/admin/build-os-options/"+build_product_id;
            var Api = $resource(resourceUrl, {}, actions);
            return Api.asBuildOsOptions();
        }

    }
})();
