(function() {
    'use strict';

    angular.module('adminApp.home')
        .controller('YiiLoginController', YiiLoginController);

    YiiLoginController.$inject = ['$location', '$state', '$stateParams', 'endSession', 'Flash', 'userService', 'yiiService'];

    function YiiLoginController($location, $state, $stateParams, endSession, Flash, userService, yiiService) {
        if (_.isNull($stateParams.token)) {
            return runError();
        }
        var token = $stateParams.token;
        $location.search('token', null);
        return yiiService.getCheckToken(token).then(function(response) {
            userService.getRoles(response.data.user.id).then(function(res) {
                if (res.data.length == 1 && res.data[0] == 'user') {
                    return runError();
                }
                localStorage.setItem('auth_token', response.data.token);
                localStorage.setItem('access_time', Math.round((new Date()).getTime() / 1000));
                localStorage.setItem('refresh_time', Math.round((new Date()).getTime() / 1000));
                $state.go('home');
                return;
            });
        }).catch(function(error) {
            console.log(error);
            return runError();
        });

        function runError() {
            endSession.end();
            Flash.clear();
            var message = "<h4>I'm sorry, but I can't let you do that.</h4><p>You are not authorized to access this application.</p>";
            Flash.create('danger', message, 0, {class: 'custom-class', id: 'custom-id'}, true);
            $state.go('login');
            return;
        }
    }
})();
