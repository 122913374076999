(function() {
    'use strict';

    angular.module('authApp')
        .factory('sessionService', sessionService);

    sessionService.$inject = ['$state', '$rootScope', '$q', 'userService', 'loginService', 'authenticate', 'endSession', 'menuService', 'historyService', 'rolePermissions', 'Notification'];

    function sessionService($state, $rootScope, $q, userService, loginService, authenticate, endSession, menuService, historyService, rolePermissions, Notification) {
        var session = {
            errorMessage: errorMessage,
            ensure: ensure
        };

        var message = null;
        var type = null;
        var refreshTokenPromise = null;

        return session;

        function errorMessage(request, empty) {
            empty = typeof empty !== 'undefined' ? empty : false;
            if (request === 'set') {
                if (!empty) {
                    message = "<h4>I'm sorry, but I can't let you do that.</h4><p>You must be logged in first</p>";
                    type = "danger";
                } else {
                    message = null;
                    type = null;
                }
            } else if (request === 'get') {
                return {
                    message: message,
                    type: type
                };
            }
        }

        function ensure(event, toState, toParams) {
            if (loginService.authInfo.authenticated) {
                return;
            }

            if (!authenticate.canRefreshToken()) {
                return;
            }

            event.preventDefault();

            if (refreshTokenPromise) {
                stateChange(refreshTokenPromise, toState, toParams);
                return;
            }
            
            refreshTokenPromise = authenticate.refreshToken().then(function() {
                refreshTokenPromise = null;
            });
            stateChange(refreshTokenPromise, toState, toParams);
        }

        function stateChange(promise, toState, toParams) {
            $q.when(promise).then(function(data) {
                if (toState.name === 'login') {
                    $state.go('home', {});
                    return;
                } else if (toState.name === 'yii' || toState.name === 'external.filex' || toState.name === 'external.vault') {
                    $rootScope.$broadcast('stateChangeOccurred', {name: toState.name});
                    $state.go(toState.name, toParams);
                    return;
                }
                if (rolePermissions.roles !== undefined) {
                    checkRoles(rolePermissions.roles, toState, toParams);
                    return;
                }
                    
                userService.get(loginService.authInfo.user.id).then(function(response) {
                    var allRoles = [];
                    angular.forEach(response.data.user_roles, function(val, key) {
                        allRoles.push(val.role);
                    });
                    if (allRoles.length == 1 && allRoles[0] == 'user') {
                        endSession.end();
                        session.errorMessage('set', false, true);
                        $state.go('login', {});
                        return;
                    }
                    rolePermissions.roles = allRoles;
                    checkRoles(allRoles, toState, toParams);
                    return;
                }).catch(function(error) {
                    switch (error.status) {
                        case 403:
                            return userService.getRoles(loginService.authInfo.user.id).then(function(res) {
                                if (res.data.length == 1 && res.data[0] == 'user') {
                                    endSession.end();
                                    session.errorMessage('set', false, true);
                                    $state.go('login', {});
                                    return;
                                } else if (toState.name === 'home') {
                                    menuService.setMenu('admin', true);
                                    menuService.setMenu('salesadmin', true);
                                } else {
                                    menuService.setMenu('admin', true);
                                    menuService.setMenu('salesadmin', true);
                                    Notification.error({message: 'You are not permitted to perform this action', replaceMessage: true});
                                }
                            });
                        default:
                            Notification.error("An unexpected error has occurred");
                            console.log(error);
                    }
                });
            });
        }

        function checkRoles(allRoles, toState, toParams) {
            if (toState.name === 'home') {
                if (allRoles.indexOf('admin') === -1) {
                    menuService.setMenu('admin', true);
                }
                if (allRoles.indexOf('salesadmin') === -1) {
                    menuService.setMenu('salesadmin', true);
                }
            }
            if (rolePermissions.admin_salesadmin.indexOf(toState.name.split(".")[0]) !== -1) {
                if (allRoles.indexOf('admin') === -1 && allRoles.indexOf('salesadmin') === -1) {
                    Notification.error({message: 'You are not permitted to access that page', replaceMessage: true});
                    $rootScope.$broadcast('stateChangeOccurred', {name: 'home'});
                    if ($state.current.name !== 'home') {
                        historyService.set('home', {}, 'Home');
                        $state.go('home', {});
                    } else if (!_.isNull(toParams.session)) {
                        $state.go('home', {});
                    }
                    return;
                } else {
                    $rootScope.$broadcast('stateChangeOccurred', {name: toState.name});
                    historyService.set(toState.name, toParams, toState.data);
                    if (toState.name == 'home' && !_.isNull(toParams.session)) {
                        toParams.session = null;
                    }
                    $state.go(toState.name, toParams);
                    return;
                }
            } else if (rolePermissions.admin.indexOf(toState.name.split(".")[0]) !== -1) {
                if (allRoles.indexOf('admin') === -1) {
                    Notification.error({message: 'You are not permitted to access that page', replaceMessage: true});
                    $rootScope.$broadcast('stateChangeOccurred', {name: 'home'});
                    if ($state.current.name !== 'home') {
                        historyService.set('home', {}, 'Home');
                        $state.go('home', {});
                    } else if (!_.isNull(toParams.session)) {
                        $state.go('home', {});
                    }
                    return;
                } else {
                    historyService.set(toState.name, toParams, toState.data);
                    $rootScope.$broadcast('stateChangeOccurred', {name: toState.name});
                    if (toState.name == 'home' && !_.isNull(toParams.session)) {
                        toParams.session = null;
                    }
                    $state.go(toState.name, toParams);
                    return;
                }
            }
            historyService.set(toState.name, toParams, toState.data);
            $rootScope.$broadcast('stateChangeOccurred', {name: toState.name});
            if (toState.name == 'home' && !_.isNull(toParams.session)) {
                toParams.session = null;
            }
            $state.go(toState.name, toParams);
            return;
        }
    }
})();
