(function() {
    'use strict';

    angular.module('adminApp')
        .factory('breadcrumbsService', breadcrumbsService);

    function breadcrumbsService() {
        var service = {
            get: get
        };

        var home = {
            link: 'home',
            label: 'Home'
        };

        var steps = {};

        return service;

        function get(state, other) {
            other = typeof other !== 'undefined' ? other : {};
            switch (state) {
                case 'users.list':
                    steps = {
                        home: home,
                        current: {
                            label: 'Users Management'
                        }
                    };
                    break;
                case 'users.view':
                    steps = {
                        home: home,
                        parents: [
                            {
                                link: 'users.list',
                                label: 'Users Management'
                            }
                        ],
                        current: {
                            label: other.username
                        }
                    };
                    break;
                case 'users.teams':
                    steps = {
                        home: home,
                        current: {
                            label: 'Teams Management'
                        }
                    };
                    break;
                case 'users.myaccount':
                    steps = {
                        home: home,
                        parents: [
                            {
                                link: 'users.list',
                                label: 'Users Management'
                            },
                            {
                                link: 'users.view({id: '+other.id+'})',
                                label: 'Edit '+other.username
                            }
                        ],
                        current: {
                            label: 'My Account '+other.username
                        }
                    };
                    break;
                case 'licenses.list':
                    steps = {
                        home: home,
                        current: {
                            label: 'Licenses Management'
                        }
                    };
                    break;
                case 'licenses.view':
                    steps = {
                        home: home,
                        parents: [
                            {
                                link: 'licenses.list',
                                label: 'Licenses Management'
                            },
                            {
                                link: 'users.view({id: '+other.userid+'})',
                                label: other.username+'\'s Licenses'
                            }
                        ],
                        current: {
                            label: other.name
                        }
                    };
                    break;
                case 'licenses.insight.manage':
                    steps = {
                        home: home,
                        parents: [
                            {
                                link: 'licenses.list',
                                label: 'Licenses Management'
                            }
                        ],
                        current: {
                            label: 'Insight Accounts'
                        }
                    };
                    break;
                case 'licenses.insight.index':
                    steps = {
                        home: home,
                        parents: [
                            {
                                link: 'licenses.list',
                                label: 'Licenses Management'
                            },
                            {
                                link: 'licenses.insight.manage',
                                label: 'Insight Accounts'
                            }
                        ],
                        current: {
                            label: 'InSight Basic Account Details ('+other.username+')'
                        }
                    };
                    break;
                case 'cloud.list':
                    steps = {
                        home: home,
                        current: {
                            label: 'Cloud Accounts'
                        }
                    };
                    break;
                case 'cloud.search':
                    steps = {
                        home: home,
                        parents: [
                            {
                                link: 'cloud.list',
                                label: 'Cloud Accounts'
                            }
                        ],
                        current: {
                            label: 'Cloud Search'
                        }
                    };
                    break;
                case 'cloud.view':
                    steps = {
                        home: home,
                        parents: [
                            {
                                link: 'cloud.list',
                                label: 'Cloud Accounts'
                            },
                            {
                                link: 'users.view({id:'+other.userid+'})',
                                label: other.username+'\'s subscriptions'
                            }
                        ],
                        current: {
                            label: other.name
                        }
                    };
                    break;
                case 'cloud.view.configuration':
                    steps = {
                        home: home,
                        parents: [
                            {
                                link: 'cloud.list',
                                label: 'Cloud Accounts'
                            },
                            {
                                link: 'users.view({id:'+other.userid+'})',
                                label: other.username+'\'s subscriptions'
                            },
                            {
                                link: 'cloud.view({id:'+other.id+'})',
                                label: 'View '+other.name
                            }
                        ],
                        current: {
                            label: other.name + ' Configuration'
                        }
                    };
                    break;
                case 'cloud.view.usage':
                    steps = {
                        home: home,
                        parents: [
                            {
                                link: 'cloud.list',
                                label: 'Cloud Accounts'
                            },
                            {
                                link: 'users.view({id:'+other.userid+'})',
                                label: other.username+'\'s subscriptions'
                            },
                            {
                                link: 'cloud.view({id:'+other.id+'})',
                                label: 'View '+other.name
                            }
                        ],
                        current: {
                            label: other.name + ' Usage'
                        }
                    };
                    break;
                case 'cloud.view.billing':
                    console.log('here');
                    steps = {
                        home: home,
                        parents: [
                            {
                                link: 'cloud.list',
                                label: 'Cloud Accounts'
                            },
                            {
                                link: 'users.view({id:'+other.userid+'})',
                                label: other.username+'\'s subscriptions'
                            },
                            {
                                link: 'cloud.view({id:'+other.id+'})',
                                label: 'View '+other.name
                            }
                        ],
                        current: {
                            label: other.name + ' Billing'
                        }
                    };
                    break;
                case 'cloud.topusers':
                    steps = {
                        home: home,
                        parents: [
                            {
                                link: 'cloud.list',
                                label: 'Cloud Accounts'
                            }
                        ],
                        current: {
                            label: 'Cloud Top Users'
                        }
                    };
                    break;
                case 'cloud.history':
                    steps = {
                        home: home,
                        parents: [
                            {
                                link: 'cloud.list',
                                label: 'Cloud Accounts'
                            },
                            {
                                link: 'cloud.top',
                                label: 'Cloud Top Users'
                            },
                            {
                                link: 'cloud.view({id: '+other.id+'})',
                                label: 'View Cloud Account ('+other.id+')'
                            }
                        ],
                        current: {
                            label: 'Cloud Usage ('+other.id+')'
                        }
                    };
                    break;
                case 'cloud.usage':
                    steps = {
                        home: home,
                        parents: [
                            {
                                link: 'cloud.list',
                                label: 'Cloud Accounts'
                            }
                        ],
                        current: {
                            label: 'Cloud Total Usage'
                        }
                    };
                    break;
                case 'cloud.signups':
                    steps = {
                        home: home,
                        parents: [
                            {
                                link: 'cloud.list',
                                label: 'Cloud Accounts'
                            }
                        ],
                        current: {
                            label: 'Cloud Subscription Signups'
                        }
                    };
                    break;
                case 'wjs.list':
                    steps = {
                        home: home,
                        current: {
                            label: 'WURFL.js Accounts'
                        }
                    };
                    break;
                case 'wjs.search':
                    steps = {
                        home: home,
                        parents: [
                            {
                                link: 'wjs.list',
                                label: 'WURFL.js Accounts'
                            }
                        ],
                        current: {
                            label: 'WURFL.js Search'
                        }
                    };
                    break;
                case 'wjs.view':
                    steps = {
                        home: home,
                        parents: [
                            {
                                link: 'wjs.list',
                                label: 'WURFL.js Accounts'
                            },
                            {
                                link: 'users.view({id:'+other.userid+'})',
                                label: other.username+'\'s subscriptions'
                            }
                        ],
                        current: {
                            label: other.name
                        }
                    };
                    break;
                case 'wjs.view.configuration':
                    steps = {
                        home: home,
                        parents: [
                            {
                                link: 'wjs.list',
                                label: 'WURFL.js Accounts'
                            },
                            {
                                link: 'users.view({id:'+other.userid+'})',
                                label: other.username+'\'s subscriptions'
                            },
                            {
                                link: 'wjs.view({id:'+other.id+'})',
                                label: 'View '+other.name
                            }
                        ],
                        current: {
                            label: other.name + ' Configuration'
                        }
                    };
                    break;
                case 'wjs.view.usage':
                    steps = {
                        home: home,
                        parents: [
                            {
                                link: 'wjs.list',
                                label: 'WURFL.js Accounts'
                            },
                            {
                                link: 'users.view({id:'+other.userid+'})',
                                label: other.username+'\'s subscriptions'
                            },
                            {
                                link: 'wjs.view({id:'+other.id+'})',
                                label: 'View '+other.name
                            }
                        ],
                        current: {
                            label: other.name + ' Usage'
                        }
                    };
                    break;
                case 'wjs.view.billing':
                    steps = {
                        home: home,
                        parents: [
                            {
                                link: 'wjs.list',
                                label: 'WURFL.js Accounts'
                            },
                            {
                                link: 'users.view({id:'+other.userid+'})',
                                label: other.username+'\'s subscriptions'
                            },
                            {
                                link: 'wjs.view({id:'+other.id+'})',
                                label: 'View '+other.name
                            }
                        ],
                        current: {
                            label: other.name + ' Billing'
                        }
                    };
                    break;
                case 'wit.list':
                    steps = {
                        home: home,
                        current: {
                            label: 'ImageEngine Accounts'
                        }
                    };
                    break;
                case 'wit.search':
                    steps = {
                        home: home,
                        parents: [
                            {
                                link: 'wit.list',
                                label: 'ImageEngine Accounts'
                            }
                        ],
                        current: {
                            label: 'ImageEngine Search'
                        }
                    };
                    break;
                case 'wit.view':
                    steps = {
                        home: home,
                        parents: [
                            {
                                link: 'wit.list',
                                label: 'ImageEngine Accounts'
                            },
                            {
                                link: 'users.view({id:'+other.userid+'})',
                                label: other.username+'\'s subscriptions'
                            }
                        ],
                        current: {
                            label: other.name
                        }
                    };
                    break;
                case 'wit.view.configuration':
                    steps = {
                        home: home,
                        parents: [
                            {
                                link: 'wit.list',
                                label: 'ImageEngine Accounts'
                            },
                            {
                                link: 'users.view({id:'+other.userid+'})',
                                label: other.username+'\'s subscriptions'
                            },
                            {
                                link: 'wit.view({id:'+other.id+'})',
                                label: 'View '+other.name
                            }
                        ],
                        current: {
                            label: other.name + ' Configuration Summary'
                        }
                    };
                    break;
                case 'wit.view.domain':
                    steps = {
                        home: home,
                        parents: [
                            {
                                link: 'wit.list',
                                label: 'ImageEngine Accounts'
                            },
                            {
                                link: 'users.view({id:'+other.userid+'})',
                                label: other.username+'\'s subscriptions'
                            },
                            {
                                link: 'wit.view({id:'+other.id+'})',
                                label: 'View '+other.name
                            }
                        ],
                        current: {
                            label: other.name + ' Cache Purging'
                        }
                    };
                    break;
                case 'wit.view.usage':
                    steps = {
                        home: home,
                        parents: [
                            {
                                link: 'wit.list',
                                label: 'ImageEngine Accounts'
                            },
                            {
                                link: 'users.view({id:'+other.userid+'})',
                                label: other.username+'\'s subscriptions'
                            },
                            {
                                link: 'wit.view({id:'+other.id+'})',
                                label: 'View '+other.name
                            }
                        ],
                        current: {
                            label: other.name + ' Usage'
                        }
                    };
                    break;
                case 'wit.view.billing':
                    steps = {
                        home: home,
                        parents: [
                            {
                                link: 'wit.list',
                                label: 'ImageEngine Accounts'
                            },
                            {
                                link: 'users.view({id:'+other.userid+'})',
                                label: other.username+'\'s subscriptions'
                            },
                            {
                                link: 'wit.view({id:'+other.id+'})',
                                label: 'View '+other.name
                            }
                        ],
                        current: {
                            label: other.name + ' Billing'
                        }
                    };
                    break;
                case 'wit.billing':
                    steps = {
                        home: home,
                        current: {
                            label: 'ImageEngine Billing'
                        }
                    };
                    break;
                case 'wit.triallinks':
                    steps = {
                        home: home,
                        current: {
                            label: 'Links for ImageEngine Trial Accounts'
                        }
                    };
                    break;
                case 'wit.regions':
                    steps = {
                        home: home,
                        parents: [
                            {
                                link: 'wit.list',
                                label: 'ImageEngine Accounts'
                            }
                        ],
                        current: {
                            label: 'Attach Domain(s) to New Region'
                        }
                    };
                    break;
                case 'wit.statistics':
                    steps = {
                        home: home,
                        current: {
                            label: 'ImageEngine Trial Statistics'
                        }
                    };
                    break;
                case 'coreapi.home':
                    steps = {
                        home: home,
                        current: {
                            label: 'CoreApi OAuth Applications'
                        }
                    };
                    break;
                case 'coreapi.view':
                    steps = {
                        home: home,
                        parents: [
                            {
                                link: 'coreapi.home',
                                label: 'CoreApi OAuth Applications'
                            }
                        ],
                        current: {
                            label: 'View Client ('+other.client+')'
                        }
                    };
                    break;
                case 'scopes.home':
                    steps = {
                        home: home,
                        current: {
                            label: 'CoreApi OAuth Scopes'
                        }
                    };
                    break;
                case 'scopes.view':
                    steps = {
                        home: home,
                        parents: [
                            {
                                link: 'scopes.home',
                                label: 'CoreApi OAuth Scopes'
                            }
                        ],
                        current: {
                            label: 'View Scope ('+other.scope+')'
                        }
                    };
                    break;
                case 'capabilities.list':
                    steps = {
                        home: home,
                        current: {
                            label: 'WURFL Capabilities'
                        }
                    };
                    break;
                case 'centraltest.default':
                    steps = {
                        home: home,
                        current: {
                            label: 'WURFL API Centralized Testing Center'
                        }
                    };
                    break;
                case 'centraltest.quick_admin':
                    steps = {
                        home: home,
                        parents: [
                            {
                                link: 'centraltest.default',
                                label: 'WURFL API Centralized Testing Center'
                            }
                        ],
                        current: {
                            label: 'Manage Existing WURFL API Centralized Unit Tests'
                        }
                    };
                    break;
                    case 'centraltest.view_test':
                        steps = {
                            home: home,
                            parents: [
                                {
                                    link: 'centraltest.default',
                                    label: 'WURFL API Centralized Testing Center'
                                },
                                {
                                    link: 'centraltest.admin.tests',
                                    label: 'Manage Centralized Unit Tests'
                                }
                        ],
                        current: {
                            label: 'View Centralized Unit Test'
                        }
                    };
                    break;
                case 'centraltest.import_xml':
                    steps = {
                        home: home,
                        parents: [
                            {
                                link: 'centraltest.default',
                                label: 'WURFL API Centralized Testing Center'
                            }
                        ],
                        current: {
                            label: 'Import WURFL API XML Centralized Unit Tests'
                        }
                    };
                    break;
                case 'centraltest.admin.tests':
                    steps = {
                        home: home,
                        parents: [
                            {
                                link: 'centraltest.default',
                                label: 'WURFL API Centralized Testing Center'
                            }
                        ],
                        current: {
                            label: 'Manage WURFL API Centralized Unit Tests'
                        }
                    };
                    break;
                case 'centraltest.admin.requests':
                    steps = {
                        home: home,
                        parents: [
                            {
                                link: 'centraltest.default',
                                label: 'WURFL API Centralized Testing Center'
                            }
                        ],
                        current: {
                            label: 'Manage Central Test Http Requests'
                        }
                    };
                    break;
                case 'centraltest.admin.headers':
                    steps = {
                        home: home,
                        parents: [
                            {
                                link: 'centraltest.default',
                                label: 'WURFL API Centralized Testing Center'
                            }
                        ],
                        current: {
                            label: 'Manage Central Test Http Headers'
                        }
                    };
                    break;
                case 'centraltest.admin.assertions':
                    steps = {
                        home: home,
                        parents: [
                            {
                                link: 'centraltest.default',
                                label: 'WURFL API Centralized Testing Center'
                            }
                        ],
                        current: {
                            label: 'Manage Central Test Assertions'
                        }
                    };
                    break;
                case 'apikey.manage':
                    steps = {
                        home: home,
                        current: {
                            label: 'Manage Api Keys'
                        }
                    };
                    break;
                case 'apikey.view':
                    steps = {
                        home: home,
                        parents: [
                            {
                                link: 'apikey.manage',
                                label: 'Manage Api Keys'
                            }
                        ],
                        current: {
                            label: 'View Api Key #'+other.id
                        }
                    };
                    break;
                case 'apikey.edit':
                    steps = {
                        home: home,
                        parents: [
                            {
                                link: 'apikey.manage',
                                label: 'Manage Api Keys'
                            },
                            {
                                link: 'apikey.view({id: '+other.id+'})',
                                label: 'View Api Key #'+other.id
                            }
                        ],
                        current: {
                            label: 'Edit Api Key #'+other.id
                        }
                    };
                    break;
                case 'apikey.create':
                    steps = {
                        home: home,
                        parents: [
                            {
                                link: 'apikey.manage',
                                label: 'Manage Api Keys'
                            }
                        ],
                        current: {
                            label: 'Create New Api Key'
                        }
                    };
                    break;
                case 'users.supporttiers':
                    steps = {
                        home: home,
                        current: {
                            label: 'Update Support Tiers'
                        }
                    };
                    break;
                case 'changelog':
                    steps = {
                        home: home,
                        current: {
                            label: 'Admin Changelog'
                        }
                    };
                    break;
                case 'quwurfl.list':
                    steps = {
                        home: home,
                        current: {
                            label: 'QuWURFL Accounts'
                        }
                    };
                    break;
                case 'quwurfl.view':
                    steps = {
                        home: home,
                        parents: [
                            {
                                link: 'quwurfl.list',
                                label: 'QuWURFL Accounts'
                            },
                            {
                                link: 'users.view({id:'+other.userid+'})',
                                label: other.username+'\'s subscriptions'
                            }
                        ],
                        current: {
                            label: other.name
                        }
                    };
                    break;
                default:
                    steps = {};
                    break;
            }

            return steps;
        }
    }
})();
