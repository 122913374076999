(function() {
    'use strict';

    angular.module('adminApp.wit')
        .controller('WitDeleteSettingController', WitDeleteSettingController);

    WitDeleteSettingController.$inject = ['$stateParams', '$uibModalInstance', 'Notification', 'pattern', 'settingId', 'witAccountService'];

    function WitDeleteSettingController($stateParams, $uibModalInstance, Notification, pattern, settingId, witAccountService) {
        var vm = this;

        vm.close = close;
        vm.pattern = pattern;
        vm.running = false;
        vm.submit = submit;

        function close() {
            $uibModalInstance.dismiss();
        }

        function submit() {
            vm.running = true;
            witAccountService.deleteSetting($stateParams.id, settingId)
                .then(function(response) {
                    Notification.success("Deleted settings");
                    $uibModalInstance.close();
                }).catch(function(error) {
                    console.log(error.data);
                    Notification.error("Unable to delete setting at this time");
                }).finally(function() {
                    vm.running = false;
                });
        }
    }
})();
