(function() {
    'use strict';

    angular.module('adminApp.wjs')
        .factory('wjsModalService', wjsModalService);

    wjsModalService.$inject = ['$ocLazyLoad', '$uibModal'];

    function wjsModalService($ocLazyLoad, $uibModal) {
        var service = {
            changePlan: changePlan,
            makeStandardBilling: makeStandardBilling
        };

        return service;

        function changePlan(direction, subscription) {
            $uibModal.open({
                templateUrl: 'view/subscriptions.wjs.components.plan_change',
                controller: 'WjsPlanChangeController',
                controllerAs: 'vm',
                size: 'lg',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    braintree: () => $ocLazyLoad.load(
                        'https://js.braintreegateway.com/web/dropin/1.9.3/js/dropin.min.js'
                    ),
                    direction: function() {
                        return direction;
                    },
                    subscription: function() {
                        return subscription;
                    }
                }
            });
        }

        function makeStandardBilling() {
            $uibModal.open({
                templateUrl: 'view/subscriptions.wjs.components.payment_modal',
                controller: 'WjsAddPaymentController',
                controllerAs: 'vm',
                size: 'lg',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    braintree: () => $ocLazyLoad.load(
                        'https://js.braintreegateway.com/web/dropin/1.9.3/js/dropin.min.js'
                    )
                }
            });
        }
    }
})();
