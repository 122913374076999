(function() {
    'use strict';

    angular.module('adminApp')
        .directive('country', country);

    country.$inject = ['$compile', 'countriesService'];

    function country($compile, countriesService) {
        var directive = {
            restrict: 'E',
            transclude: true,
            replace: true,
            template: '<div><input class="form-control" placeholder="loading countries, please wait..." ng-if="vm.country_disable" disabled autocomplete="country"><select class="form-control" ng-if="!vm.country_disable" ng-disabled="vm.country_disable" ng-change="vm.set(vm.country_id)" id="country" name="country" ng-model="vm.country_id" ng-init="vm.country_id = vm.initSelect()" ng-options="option.id as option.name for option in vm.countries" autocomplete="country"></select></div>',
            scope: {
                model: '='
            },
            controller: 'CountryController',
            controllerAs: 'vm',
            bindToController: true
        };

        return directive;
    }
})();
