(function() {
    'use strict';

    angular.module('adminApp.wit')
        .controller('WitPurgeInfoController', WitPurgeInfoController);

    WitPurgeInfoController.$inject = ['$uibModalInstance', 'json', 'witAccountService'];

    function WitPurgeInfoController($uibModalInstance, json, witAccountService) {
        var vm = this;

        vm.checkStatusView = checkStatusView;
        vm.close = close;
        vm.download = download;
        vm.json = json;
        vm.statusView = {
            backend: ['success', 'error'],
            frontend: ['success', 'error'],
            origincache: ['success', 'error']
        }
        vm.updateStatusView = updateStatusView;
        vm.view = "all";

        function checkStatusView(layer, status) {
            return _.indexOf(vm.statusView[layer], status) !== -1;
        }

        function close() {
            $uibModalInstance.dismiss();
        }

        function download() {
            var jsonData = angular.copy(vm.json);
            var layers = angular.copy(jsonData.report.layers);
            jsonData.report.layers = {};
            angular.forEach(layers, function(val, key) {
                if (key !== 'origin_cache') {
                    jsonData.report.layers[key] = val;
                }
            })
            witAccountService.downloadPurgeRequest(jsonData);
        }

        function updateStatusView(layer, status) {
            if (_.indexOf(vm.statusView[layer], status) !== -1) {
                vm.statusView[layer] = _.without(vm.statusView[layer], status);
            } else {
                vm.statusView[layer].push(status);
            }
        }
    }
})();
