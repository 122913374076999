(function() {
    'use strict';

    angular.module('adminApp.wit')
        .controller('WitDownloadTrialStatsController', WitDownloadTrialStatsController);

    WitDownloadTrialStatsController.$inject = ['$uibModalInstance', 'uri'];

    function WitDownloadTrialStatsController($uibModalInstance, uri) {
        var vm = this;

        vm.close = close;
        vm.transparent = false;
        vm.uri = uri;

        function close() {
            $uibModalInstance.dismiss();
        }
    }
})();
