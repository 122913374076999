(function() {
    'use strict';

    angular.module('adminApp.wit')
        .factory('domainConfValidator', domainConfValidator);

    function domainConfValidator() {
        var service = {
            validate: validate,
            validateBucketname: validateBucketname,
            validateCredentials: validateCredentials,
            validateCname: validateCname,
            validateHostname: validateHostname,
            validatePath: validatePath,
            validatePort: validatePort
        };

        return service;

        function validate(domainConf) {
            var domainType = domainConf.url_type;

            switch (domainType) {
                case 'http':
                    domainConf = domainConf.http;

                    if (!(
                        _.has(domainConf, 'is_secure') &&
                        _.has(domainConf, 'hostname') &&
                        _.has(domainConf, 'username') &&
                        _.has(domainConf, 'password') &&
                        _.has(domainConf, 'port')
                    )) {
                        return {
                            field: 'general',
                            message: 'Invalid HTTP Domain Configuration'
                        };
                    }

                    if (
                        (domainConf.username != '' && domainConf.password == '') ||
                        (domainConf.username == '' && domainConf.password != '')
                    ) {
                        return {
                            field: 'general',
                            message: 'Invalid credentials. Both username and password required.'
                        };
                    }

                    if (domainConf.username != '' && domainConf.password != '') {
                        var validUsernamePassword = service.validateCredentials(domainConf.username, domainConf.password);
                        if (validUsernamePassword != true) {
                            return validUsernamePassword;
                        }
                    }

                    if (!_.isBoolean(domainConf.is_secure)) {
                        return {
                            field: 'general',
                            message: 'Invalid HTTP Domain Configuration'
                        };
                    }

                    if (domainConf.hostname == '') {
                        return {
                            field: 'hostname',
                            message: "Hostname can't be empty"
                        };
                    }

                    var validHostname = service.validateHostname(domainConf.hostname);
                    if (validHostname != true) {
                        return validHostname;
                    }

                    if (domainConf.port != '' && domainConf.port != null) {
                        var validPort = service.validatePort(domainConf.port);
                        if (validPort != true) {
                            return validPort;
                        }
                    }

                    return true;
                case 's3':
                    if (!(_.has(domainConf.s3, 'bucketname') && _.has(domainConf.s3, 'path'))) {
                        return {
                            field: 'general',
                            message: 'Invalid S3 Domain Configuration'
                        };
                    }

                    if (domainConf.s3.bucketname == '') {
                        return {
                            field: 'bucketname',
                            message: "Bucketname can't be empty"
                        };
                    }

                    var validBucketname = service.validateBucketname(domainConf.s3.bucketname);
                    if (validBucketname != true) {
                        return validBucketname;
                    }

                    if (domainConf.s3.path != '') {
                        var validPath = service.validatePath(domainConf.s3.path);
                        if (validPath != true) {
                            return validPath;
                        }
                    }
                    return true;
                default:
                    return {
                        field: 'general',
                        message: "Invalid domain conf type"
                    };
            }
        }

        function validateBucketname(bucketname) {
            if (bucketname.length >= 256) {
                return {
                    field: 'bucketname',
                    message: "Bucketname can't be longer than 255 chars"
                };
            }

            if (!(/^[\w\.-]+$/.test(bucketname))) {
                return {
                    field: 'bucketname',
                    message: "Invalid bucketname"
                };
            }

            return true;
        }

        function validateCredentials(username, password) {
            if (password.indexOf(".") > -1 || password.indexOf(":") > -1 || password.indexOf("@") > -1) {
                return {
                    field: 'password',
                    message: "Passwords cannot contain \":\", \".\", or \"@\""
                };
            }
            if (username.indexOf(".") > -1 || username.indexOf(":") > -1 || username.indexOf("@") > -1) {
                return {
                    field: 'username',
                    message: "Usernames cannot contain \":\", \".\", or \"@\""
                };
            }

            return true;
        }

        function validateCname(cname) {
            if (cname.cname == '') {
                return {
                    field: "cname",
                    message: "Cnames can't be empty!"
                };
            }

            if (!(/^([a-z\d](-*[a-z\d])*)(\.([a-z\d](-*[a-z\d])*))*$/i.test(cname.cname)) ||
                !(/^.{1,253}$/.test(cname.cname)) ||
                !(/^[^\.]{1,63}(\.[^\.]{1,63})*$/.test(cname.cname))
            ) {
                return {
                    field: "cname",
                    message: 'Invalid Cname'
                };
            }

            if (cname.cname.split('.').length <= 1) {
                return {
                    field: "cname",
                    message: 'Only top level cnames allowed'
                };
            }

            return true;
        }

        function validateHostname(hostname) {
            if(!(/^([a-z\d](-*[a-z\d])*)(\.([a-z\d](-*[a-z\d])*))*$/i.test(hostname)) || !(/^.{1,253}$/.test(hostname)) || !(/^[^\.]{1,63}(\.[^\.]{1,63})*$/.test(hostname))) {
                return {
                    field: 'hostname',
                    message: 'Invalid hostname'
                };
            }

            if(hostname.split('.').length <= 1) {
                return {
                    field: 'hostname',
                    message: 'Only top level hostnames allowed'
                };
            }

            return true;
        }

        function validatePath(path) {
            if (!(/^[/\w]+$/.test(path))) {
                return {
                    field: 'path',
                    message: 'Invalid path'
                };
            }
            return true;
        }

        function validatePort(port) {
            var numberPort = Number(port);

            if(isNaN(numberPort) || numberPort<=0 || numberPort >=65536) {
                return {
                    field: 'port',
                    message: 'Invalid Port Number'
                };
            }
            if(isNaN(numberPort) || numberPort == 80) {
                return {
                    field: 'port',
                    message: "Port number can't be 80"
                };
            }
            return true;
        }
    }
})();
