(function() {
    'use strict';

    angular.module('adminApp.auth')
        .controller('AuthController', AuthController);

    AuthController.$inject = ['$state', '$window', 'authenticate', 'Flash', 'loginService', 'sessionService', 'transferFlash'];

    function AuthController($state, $window, authenticate, Flash, loginService, sessionService, transferFlash) {
        var vm = this;

        vm.errors = {
            username: {
                show: false,
                message: null
            },
            password: {
                show: false,
                message: null
            }
        };
        vm.loginSubmit = loginSubmit;
        vm.password = null;
        vm.remember = null;
        vm.username = null;

        displayMessage();

        function displayMessage() {
            Flash.clear();
            var flash = transferFlash.get();

            var sessionFlash = sessionService.errorMessage('get');

            if (flash.message !== null) {
                Flash.create(flash.type, flash.message, 0, {class: 'custom-class', id: 'custom-id'}, true);
            } else if (sessionFlash.message) {
                Flash.create(sessionFlash.type, sessionFlash.message, 0, {class: 'custom-class', id: 'custom-id'}, true);
                sessionService.errorMessage('set', true);
            }
        }

        function loginSubmit() {
            Flash.clear();
            var credentials = {
                username: vm.username,
                password: vm.password
            };

            if (
                vm.username === undefined ||
                vm.username.length < 5 ||
                vm.password === undefined ||
                vm.password.length < 5
            ) {
                var message = "<h4>I apologize for the inconvenience:</h4><p>Your form contains errors</p>";
                Flash.create('danger', message, 0, {class: 'custom-class', id: 'custom-id'}, true);
                if (vm.username === undefined) {
                    vm.errors.username.show = true;
                    vm.errors.username.message = "The username field is required.";
                } else if (vm.username.length < 5) {
                    vm.errors.username.show = true;
                    vm.errors.username.message = "The username must be at least 5 characters.";
                } else {
                    vm.errors.username.show = false;
                    vm.errors.username.message = null;
                }
                if (vm.password === undefined) {
                    vm.errors.password.show = true;
                    vm.errors.password.message = "The password field is required.";
                } else if (vm.password.length < 5) {
                    vm.errors.password.show = true;
                    vm.errors.password.message = "The password must be at least 5 characters.";
                } else {
                    vm.errors.password.show = false;
                    vm.errors.password.message = null;
                }
                return;
            }

            authenticate.auth(credentials).then(function(response) {
                if (!response) {
                    vm.password = null;
                    vm.remember = false;
                    return vm;
                } else {
                    transferFlash.set('message', "<h4>Hello " + response.first_name + "!</h4>");
                    transferFlash.set('type', 'success');
                    loginService.authInfo.authenticated = true;
                    loginService.authInfo.user = response;
                    if (loginService.intended !== undefined) {
                        var intended = loginService.intended;
                        $state.go(intended.name, intended.params);
                    } else {
                        $state.go('home');
                    }
                    return;
                }
            });
        }
    }
})();
