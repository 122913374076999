(function() {
    'use strict';

    angular.module('adminApp.licenses')
        .directive('licenseOnsite', licenseOnsite);

    function licenseOnsite() {
        var directive = {
            restrict: 'E',
            templateUrl: 'view/licenses.templates.onsite',
            scope: {
                ngLicense: "=license",
                type: "@"
            },
            controller: "LicenseDirectiveController",
            controllerAs: "vm"
        };

        return directive;
    }
})();
