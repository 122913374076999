(function() {
    'use strict';

    angular.module('adminApp.licenses')
        .controller('LicenseEnableController', LicenseEnableController);

    LicenseEnableController.$inject = ['$rootScope', '$state', '$uibModalInstance', 'licenseId', 'licenseService', 'Notification'];

    function LicenseEnableController($rootScope, $state, $uibModalInstance, licenseId, licenseService, Notification) {
        var vm = this;

        vm.close = close;
        vm.classes = ['form-control'];
        vm.labelClasses = ['control-label'];
        vm.modalOptions = {
            actionClass: 'alert-success',
            actionMessage: 'Enabling license...',
            proceedButton: {
                btnClass: 'btn-primary',
                btnSpanClass: 'glyphicon-refresh',
                btnText: 'Enable'
            },
            template: 'view/licenses.modals.enable',
            title: 'Are you sure?'
        };
        vm.proceed = proceed;
        vm.showActionSpinner = false;

        function close() {
            $uibModalInstance.dismiss();
        }

        function proceed() {
            vm.classes = [vm.classes[0]];
            vm.labelClasses = [vm.labelClasses[0]];
            vm.showActionSpinner = true;
            licenseService.postEnable(licenseId).then(function(response) {
                 Notification.success("The license has been enabled");
                 if ($state.current.name == 'licenses.view') {
                     $rootScope.$broadcast('reloadLicense');
                 } else if ($state.current.name == 'licenses.list') {
                     $rootScope.$broadcast('reloadLicenses');
                 }
                 $uibModalInstance.dismiss();
            }).catch(function(error) {
                console.log(error);
                Notification.error("An error occurred");
            }).finally(function() {
                vm.showActionSpinner = false;
            });
        }
    }
})();
