(function() {
    'use strict';

    angular.module('adminApp.licenses')
        .controller('BasicInsightController', BasicInsightController);

    BasicInsightController.$inject = ['$stateParams', 'breadcrumbsService', 'licenseService', 'Notification', 'userService'];

    function BasicInsightController($stateParams, breadcrumbsService, licenseService, Notification, userService) {
        var vm = this;

        vm.changeMode = changeMode;
        vm.enableFile = enableFile;
        vm.error = {
            readonly: false,
            readwrite: false
        };
        vm.form = {};
        vm.idData = {
            licenseId: $stateParams.id
        };
        vm.licenseText = null;
        vm.loading = {
            files: false,
            readonly: false,
            readwrite: false,
            wurfl_mode: false
        };
        vm.loadCapabilities = false;
        vm.maxCapabilities = null;
        vm.profile = {};
        vm.readOnlyCredentials = {};
        vm.readOnlyTitle = "Generate Credentials";
        vm.readWriteCredentials = {};
        vm.readWriteTitle = "Generate Credentials";
        vm.runCredentials = runCredentials;
        vm.serverAddress = "insight.scientiamobile.com:21";
        vm.steps = {};
        vm.username = null;

        licenseService.insight_profile($stateParams.id).then(function(response) {
            vm.profile = response.data;
            vm.idData.profileId = response.data.id;
            vm.form = angular.copy(response.data);
            if (vm.profile.keys.readonly !== undefined) {
                vm.readOnlyTitle = "Regenerate Credentials";
            }
            if (vm.profile.keys.readwrite !== undefined) {
                vm.readWriteTitle = "Regenerate Credentials";
            }
            vm.maxCapabilities = vm.profile.max_capabilities == 0 ? 'unlimited' : vm.profile.max_capabilities;
            vm.loadCapabilities = true;
        }).catch(function(error) {
            Notification.error("Could not get profile");
            console.log(error);
        });

        licenseService.getText($stateParams.id).then(function(response) {
            vm.licenseText = response.data.text;
        }).catch(function(error) {
            console.log(error);
            Notification.error("Could not get license text");
        })

        userService.get($stateParams.userId).then(function(response) {
            vm.steps = breadcrumbsService.get('licenses.insight.index', {username: response.data.username});
            vm.username = response.data.username;
        }).catch(function(error) {
            console.log(error);
            Notification.error("Could not get user information");
        });

        function changeMode() {
            vm.loading.wurfl_mode = true;
            licenseService.profile(vm.form, {wurfl_mode: vm.form.wurfl_mode}).then(function(response) {
                vm.profile = angular.copy(vm.form);
            }).catch(function(error) {
                console.log(error);
                Notification.error("Could not update max capabilities");
                vm.form = angular.copy(vm.profile);
            }).finally(function() {
                vm.loading.wurfl_mode = false;
            });
        }

        function createKey(type) {
            if (type == 'readwrite') {
                vm.loading.readwrite = true;
            } else {
                vm.loading.readonly = true;
            }
            licenseService.new_insight_key(vm.form, type)
                .then(function(response) {
                    var key = response.data;
                    vm.form.keys[type] = key;
                    Notification.success('The key has been created successfully');
                    if (type == 'readonly') {
                        vm.readOnlyTitle = "Regenerate Credentials";
                    } else {
                        vm.readWriteTitle = "Regenerate Credentials";
                    }
                    vm.profile = angular.copy(vm.form);
                }, function(error) {
                    vm.error[type] = true;
                    console.log(error);
                    vm.form = angular.copy(vm.profile);
                }).finally(function() {
                    if (type == 'readwrite') {
                        vm.loading.readwrite = false;
                    } else {
                        vm.loading.readonly = false;
                    }
                });
        }

        function enableFile(file, action) {
            vm.loading.files = true;
            if (action == 'file') {
                licenseService.insightFile(file, {enabled: file.enabled}).then(function(response) {
                    vm.profile = angular.copy(vm.form);
                }).catch(function(error) {
                    console.log(error);
                    Notification.error("Could not update file");
                    vm.form = angular.copy(vm.profile);
                }).finally(function() {
                    vm.loading.files = false;
                });
            } else {
                licenseService.profile(vm.form, {enable_default: vm.form.enable_default}).then(function(response) {
                    vm.profile = angular.copy(vm.form);
                }).catch(function(error) {
                    console.log(error);
                    Notification.error("Could not update enable");
                    vm.form = angular.copy(vm.profile);
                }).finally(function(error) {
                    vm.loading.files = false;
                });
            }

        }

        function runCredentials(type) {
            if (vm.profile.keys[type] === undefined) {
                createKey(type);
            } else {
                updateKey(vm.profile.keys[type], type);
            }
        }

        function updateKey(key, type) {
            // type -> 'readwrite', 'readonly'
            if (type == 'readwrite') {
                vm.loading.readwrite = true;
            } else {
                vm.loading.readonly = true;
            }
            licenseService.insight_key(vm.form, key)
                .then(function(response) {
                    key = response.data;
                    vm.form.keys[type] = key;

                    Notification.success('The key hes been set successfully');
                    vm.profile = angular.copy(vm.form);
                }, function(error) {
                    vm.error[type] = true;
                    console.error(error);
                    vm.form = angular.copy(vm.profile);
                }).finally(function() {
                    if (type == 'readwrite') {
                        vm.loading.readwrite = false;
                    } else {
                        vm.loading.readonly = false;
                    }
                });
        }
    }
})();
