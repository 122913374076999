(function() {
    'use strict';

    angular.module('adminApp.home')
        .controller('LayoutController', LayoutController);

    LayoutController.$inject = ['$rootScope', '$scope'];

    function LayoutController($rootScope, $scope) {
        var vm = this;

        vm.title = 'Control Panel';

        var stateChangeStart = $rootScope.$on('stateChangeOccurred', function(event, args) {
            var parentState = args.name.split(".")[0];

            switch (parentState) {
                case 'home':
                    vm.title = 'Control Panel';
                    break;
                case 'users':
                    vm.title = 'User Management';
                    break;
                case 'capabilities':
                    vm.title = 'Capabilities app initialized';
                    break;
                case 'cloud':
                    vm.title = 'Cloud app initialized';
                    break;
                case 'coreapi':
                    vm.title = 'CoreApi OAuth Applications';
                    break;
                case 'scopes':
                    vm.title = 'CoreApi OAuth Scopes';
                    break;
                case 'licenses':
                    vm.title = 'License Management';
                    break;
                case 'wit':
                    vm.title = 'ImageEngine app initialized';
                    break;
                case 'wjs':
                    vm.title = 'WURFL.js accounts';
                    break;
                case 'login':
                    vm.title = 'Login to Control Panel';
                    break;
                case 'otherwise':
                    vm.title = 'Control Panel - Page Not Found';
                    break;
                case 'api':
                    vm.title = 'WURFL API Centralized Testing Center';
                    break;
                default:
                    vm.title = 'Control Panel';
            }
            $scope.$on('$destroy', stateChangeStart);
        });
    }
})();
