(function() {
    'use strict';

    angular.module('adminApp.saas')
        .controller('SaasAccountsTableQuWurflController', SaasAccountsTableQuWurflController);

        SaasAccountsTableQuWurflController.$inject = ['$location', '$rootScope', '$scope', '$state', '$stateParams', '$timeout', 'quwurflAccountService', 'Notification', 'paginationService', 'saasConstants'];

    function SaasAccountsTableQuWurflController($location, $rootScope, $scope, $state, $stateParams, $timeout, quwurflAccountService, Notification, paginationService, saasConstants) {
        var vm = this;

        vm.columns = [
            {
                name: 'ID',
                search: {
                    isSearchable: false
                },
                model: 'id',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: 'Name',
                search: {
                    isSearchable: false, // Disabled for now, see WQ-92
                    //type: 'text'
                },
                model: 'account_name',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: 'E-Mail',
                search: {
                    isSearchable: false, // Disabled for now, see WQ-92
                    //type: 'text'
                },
                model: 'email',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: 'Level',
                search: {
                    isSearchable: false, // Disabled for now, see WQ-92
                    // type: 'select',
                    // id: 'payment_plan'
                },
                model: 'payment_plan',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                display: false,
                model: 'payment_plan',
                search: {
                    isSearchable: false // Disabled for now, see WQ-92
                }
            },
            {
                name: 'Type',
                search: {
                    isSearchable: false, // Disabled for now, see WQ-92
                    // type: 'select',
                    // id: 'payment_type'
                },
                model: 'payment_type',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: 'Started',
                search: {
                    isSearchable: false
                },
                model: 'date_started',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: 'Status',
                search: {
                    isSearchable: false
                },
                model: 'status',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: '',
                search: {
                    isSearchable: false
                },
                model: '',
                value: {
                    fromData: false
                },
                display: true
            }
        ];
        vm.search = {
            account_name: '',
            email: '',
            // Search by payment plan is disabled for now see WQ-92
            // payment_plan: '',
            // payment_type: ''
        };
        vm.tableType = $scope.vm.tableType;

        var currentPage = $stateParams.page;
        var service = $scope.vm.service;

        // TODO: verify if we need to load constants for QuWURFL or if response data is good enough

        // $timeout(function() {
        //     // Let the smart table controller load before sending constants
        //     if (vm.tableType == 'wit') {
        //         $rootScope.$broadcast(vm.tableType+"UpdateSelect", {
        //             id: 'payment_plan',
        //             data: saasConstants.getConstants(vm.tableType, 'paymentPlans'),
        //             keyNeeded: false,
        //             value: 'payment_plan',
        //             show: 'tier_name'
        //         });
        //     } else {
        //         $rootScope.$broadcast(vm.tableType+"UpdateSelect", {
        //             id: 'payment_plan',
        //             data: saasConstants.getConstants(vm.tableType, 'paymentPlans'),
        //             keyNeeded: false,
        //             value: 'key',
        //             show: 'nice_name'
        //         });
        //     }
        //     $rootScope.$broadcast(vm.tableType+"UpdateSelect", {
        //         id: 'payment_type',
        //         data: saasConstants.getConstants(vm.tableType, 'paymentTypes'),
        //         keyNeeded: true,
        //         value: 'key',
        //         show: 'value'
        //     });
        // }, 1000);

        // check for existing landing search queries
        var search_params = _.clone($location.search());
        // used to display the currently shown results at the bottom of the table
        getSubscriptions();
        var saasSearchParamsUpdated = $rootScope.$on(vm.tableType+"SearchParamsUpdated", function(event, args) {
            vm.search = args;
            currentPage = 1;
            getSubscriptions(vm.search);
        });
        var pageChanged = $rootScope.$on(vm.tableType+"PageChanged", function(event, args) {
            vm.search = args;
            currentPage = args.page;
            getSubscriptions(vm.search);
        });
        var itemsPerPageChanged = $rootScope.$on(vm.tableType+"ItemsPerPageChanged", function(event, args) {
            vm.search = args;
            currentPage = 1;
            getSubscriptions(vm.search);
        })
        var reloadList = $rootScope.$on(vm.tableType+"ReloadList", function() {
            currentPage = 1;
            getSubscriptions();
        });

        function getSubscriptions(searchParams) {
            searchParams = typeof searchParams !== 'undefined' ? searchParams : null;

            vm.subscriptions = undefined;

            var start = 0;
            var number = 10;

            var query_params = {};
            var next_page = 1;

            if (search_params && _.isUndefined(search_params.page) === false) { // if defined
                next_page = search_params.page;
            } else {
                next_page = Math.floor(start/number) + 1;
            }

            query_params = {page: next_page};

            if (searchParams !== null) {
                query_params = _.extend(searchParams);
                number = searchParams.paginate;
            }

            quwurflAccountService.all(vm.service.resourceUrl, undefined, query_params)
                .then(function(response) {
                    angular.forEach(response, function(item, key) {
                        item.id = item.subscription.id
                        item.account_name = item.subscription.account_name
                        item.email = item.user.email
                        item.payment_plan = item.subscription.payment_plan
                        item.payment_type = item.subscription.payment_type
                        item.date_started = item.subscription.date_started
                        item.status = item.subscription.status
                        
                        switch (item.subscription.status) {
                            case 'CANCELED':
                            case 'SUSPENDED':
                                item.addedClass = 'color: red;';
                                break;
                            case 'PENDING_SMS_VERIFY':
                            case 'PENDING_EMAIL_VERIFY':
                            case 'PENDING_PAYMENT':
                                val.addedClass = 'color: yellow;';
                                break;
                            default:
                                var expireDate = new Date(item.subscription.date_expiration);
                                var today = new Date();
                                if (
                                    today > expireDate &&
                                    item.subscription.date_expiration !== null &&
                                    item.subscription.date_expiration !== '0000-00-00 00:00:00'
                                ) {
                                    item.addedClass = 'color: orange;';
                                } else {
                                    item.addedClass = '';
                                }
                                break;
                        }
                    });

                    // TODO pagination is not available in this sprint
                    // Note: showPerPageTop is disabled in smarttable.controller.js
                    //var numPages = response.pagination.last_page;
                    var paginationData = {};
                    // paginationData.pages = paginationService.createPages(currentPage, numPages);
                    // paginationData.numPages = numPages;
                    // paginationData.currentPage = currentPage;
                    // paginationData.currentState = $state.current.name;
                    // paginationData.stateParams = $stateParams;
                    // paginationData.from = paginationService.from(response.pagination);
                    // paginationData.to = paginationService.to(response.pagination);
                    // paginationData.total = response.pagination.total;
                    
                    $rootScope.$broadcast(vm.tableType+'DataLoaded', {
                        data: response,
                        showPerPageTop: false,
                        paginationData: paginationData,
                        itemsPerPage: number
                    });
                    $scope.$on('$destroy', saasSearchParamsUpdated);
                    $scope.$on('$destroy', pageChanged);
                    $scope.$on('$destroy', itemsPerPageChanged);
                }, function(error) {
                    Notification.error(error.data.message);
                    console.log(error);
                    $scope.$on('$destroy', saasSearchParamsUpdated);
                    $scope.$on('$destroy', pageChanged);
                    $scope.$on('$destroy', itemsPerPageChanged);
                });
        }
    }
})();
