(function() {
    'use strict';

    angular.module('adminApp.home')
        .factory('yiiService', yiiService);

    yiiService.$inject = ['$resource'];

    function yiiService($resource) {
        var resource = new $resource('/api/admin/yii/:token', {token: '@token'}, {
            getCheckToken: {
                method: 'GET'
            }
        });

        var service = {
            getCheckToken: getCheckToken
        };

        return service;

        function getCheckToken(token) {
            return resource.getCheckToken({token: token}).$promise;
        }
    }
})();
