(function() {
    'use strict';

    angular.module('adminApp.saas')
        .controller('SaasEditDefaultsController', SaasEditDefaultsController);

    SaasEditDefaultsController.$inject = ['$rootScope', '$state', '$stateParams', '$uibModal', '$uibModalInstance', 'accountService', 'baseAccountService', 'capabilitiesService', 'Notification', 'plan', 'saasType', 'wjsAccountService'];

    function SaasEditDefaultsController($rootScope, $state, $stateParams, $uibModal, $uibModalInstance, accountService, baseAccountService, capabilitiesService, Notification, plan, saasType, wjsAccountService) {
        var vm = this;

        vm.close = close;
        vm.defaults = undefined;
        vm.resetDefaults = resetDefaults;
        vm.saasType = saasType;
        vm.subscription = accountService.getValues(saasType, 'subscription');
        vm.updateCapabilities = updateCapabilities;
        vm.updateDefaults = updateDefaults;
        vm.warning = false;

        var service = baseAccountService.getService(saasType);

        if (vm.defaults === undefined || vm.subscription === undefined) {
            baseAccountService.get(service.resourceUrl, $stateParams.id)
                .then(function(response) {
                    vm.subscription = response.data.account;
                    if (saasType === 'cloud') {
                        vm.defaults = baseAccountService.remapLimits(vm.subscription.limits, 'wurfl_cloud_default_limit');
                    } else if (saasType === 'wjs') {
                        vm.defaults = baseAccountService.remapLimits(vm.subscription.wurfl_js_accounts_defaults, 'wurfl_js_default');
                    }
                }, function(error) {
                    console.log(error);
                    Notification.error("The account defaults could not be loaded");
                });
        }

        function close() {
            $uibModalInstance.dismiss();
        }

        function updateCapabilities() {
            capabilitiesService.findCapabilities(vm.defaults.capabilities.value).then(function(response) {
                $uibModal.open({
                    templateUrl: 'view/subscriptions.wjs.capabilities',
                    controller: 'WjsCapabilitiesController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    keyboard: false,
                    size: 'lg',
                    resolve: {
                        capabilities: function() {
                            return response.data;
                        },
                        defaults: function() {
                            return vm.defaults;
                        }
                    }
                });
            });
        }

        function finishUpdateDefaults(data) {
            Notification.success('Updated account details');
            if ($state.current.name == saasType+'.view') {
                $rootScope.$broadcast('reloadDefaults', {type: saasType, data: data});
            }
            $uibModalInstance.dismiss();
        }

        function updateDefaults(submitted_defaults) {
            baseAccountService.updateDefaults(service.resourceUrl, $stateParams.id, _.values(submitted_defaults))
                .then(function(response) {
                    if (response.debug && response.debug.length) {
                        Notification.warning({message: response.message, priority: 2});
                        Notification.error({message: response.debug, priority: 2});
                    } else {
                        if (saasType == 'cloud') {
                            angular.forEach(response.data, function(val, key) {
                                val.plan_default = _.findWhere(_.values(submitted_defaults), {type: val.type}).plan_default;
                                val.wurfl_cloud_default_limit = _.findWhere(_.values(submitted_defaults), {type: val.type}).wurfl_cloud_default_limit;
                            });
                            finishUpdateDefaults(response.data);
                        } else if (saasType == 'wjs') {
                            wjsAccountService.getDefaults(plan).then(function(defaults) {
                                angular.forEach(response.data, function(val, key) {
                                    val.wurfl_js_default = _.findWhere(defaults.data, {id: val.wurfl_js_default_id});
                                });
                                finishUpdateDefaults(response.data);
                            }).catch(function(error) {
                                console.log(error);
                            });
                        }


                    }
                }, function(error) {
                    Notification.error(error.message);
                    console.log(error);
                });
        }

        function resetDefaults() {
            angular.forEach(vm.defaults, function(val, key) {
                val.value = val.plan_default.value;
            });
        }
    }
})();
