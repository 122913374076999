(function() {
    angular.module('adminApp.licenses')
        .factory('infuzeService', infuzeService);

    infuzeService.$inject = ['$resource'];

    function infuzeService($resource) {
        var resource = new $resource('/api/admin/infuze/profiles/:action/:action_id', {action_id: '@action_id'}, {
            getProfiles: {
                method: 'GET',
                params: {action: null}
            },
            newProfile: {
                method: 'POST',
                params: {action: 'create'}
            },
            deleteProfile: {
                method: 'DELETE',
                params: {action: null}
            },
            updateProfile: {
                method: 'PATCH',
                params: {action: null}
            }
        });

        var service = {
            deleteProfile: deleteProfile,
            getProfiles: getProfiles,
            newProfile: newProfile,
            updateProfile: updateProfile
        };

        return service;

        function deleteProfile(profileId) {
            return resource.deleteProfile({action_id: profileId}).$promise;
        }

        function getProfiles(infuzeId) {
            return resource.getProfiles({action_id: infuzeId}).$promise;
        }

        function newProfile(profile) {
            return resource.newProfile({action_id: null}, profile).$promise;
        }

        function updateProfile(profile) {
            return resource.updateProfile({action_id: profile.id}, profile).$promise;
        }
    }
})();
