(function() {
    'use strict';

    angular.module('adminApp.wit')
        .controller('WitDownloadExpiredTrialStatsController', WitDownloadExpiredTrialStatsController);

    WitDownloadExpiredTrialStatsController.$inject = ['$rootScope', '$scope', '$uibModalInstance', 'reportsService'];

    function WitDownloadExpiredTrialStatsController($rootScope, $scope, $uibModalInstance, reportsService) {
        var vm = this;

        vm.progress = 0;
        vm.title = "Expired Trial Statistics Report";

        var updateTrialStatsProgress = $rootScope.$on('updateTrialStatsProgress', function(event, args) {
            vm.progress = args.completePercentage;
            if (vm.progress == 100) {
                $uibModalInstance.close();
            }
            $scope.$on('$destory', updateTrialStatsProgress);
        })

        reportsService.downloadExpiredTrialStats();
    }
})();
