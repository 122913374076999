(function() {
    'use strict';

    angular.module('adminApp.cloud')
        .controller('CloudViewController', CloudViewController);

    CloudViewController.$inject = ['$filter', '$ngConfirm', '$rootScope', '$scope', '$state', '$stateParams', '$uibModal', 'accountService', 'baseAccountService', 'breadcrumbsService', 'cloudAccountService', 'cloudModalService', 'Notification', 'subscriptionModalService'];

    function CloudViewController($filter, $ngConfirm, $rootScope, $scope, $state, $stateParams, $uibModal, accountService, baseAccountService, breadcrumbsService, cloudAccountService, cloudModalService, Notification, subscriptionModalService) {
        var vm = this;

        vm.billingInfoAvailable = false;
        vm.cancel = cancel;
        vm.cancelReason = accountService.getValues('cloud', 'cancelReason');
        vm.createNew = createNew;
        vm.currentTab = 0;
        vm.defaults = accountService.getValues('cloud', 'defaults');
        vm.edit = edit;
        vm.editDefaults = editDefaults;
        vm.endTrial = endTrial;
        vm.loading = true;
        vm.otherAccounts = accountService.getValues('cloud', 'otherAccounts');
        vm.restart = restart;
        vm.stateParams = $stateParams;
        vm.steps = {};
        vm.subscription = accountService.getValues('cloud', 'subscription');
        vm.suspend = suspend;
        vm.swapPaths = swapPaths;
        vm.upgrade = upgrade;
        vm.unsuspend = unsuspend;
        vm.updateBreadcrumbs = updateBreadcrumbs;

        if ($state.current.name === 'cloud.view') {
            if (accountService.getId('cloud') === undefined || accountService.getId('cloud') !== $stateParams.id) {
                accountService.clear();
                vm.subscription = undefined;
                vm.defaults = undefined;
                vm.otherAccounts = undefined;
                vm.billingInfoAvailable = undefined;
            }
            if (vm.subscription === undefined) {
                run();
            } else {
                vm.steps = breadcrumbsService.get('cloud.view', {
                    username: vm.subscription.user.username,
                    userid: vm.subscription.user_id,
                    name: vm.subscription.account_name
                });
                vm.loading = false;
            }
        } else {
            vm.loading = false;
        }

        var updateCloudSubscription = $rootScope.$on('updateCloudSubscription', function() {
            vm.subscription = accountService.getValues('cloud', 'subscription');
            vm.otherAccounts = accountService.getValues('cloud', 'otherAccounts');
            vm.defaults = accountService.getValues('cloud', 'defaults');
            vm.billingInfoAvailable = accountService.getValues('cloud', 'billingInfoAvailable');
            $scope.$on('$destroy', updateCloudSubscription);
            vm.steps = breadcrumbsService.get($state.current.name, {
                username: vm.subscription.user.username,
                userid: vm.subscription.user_id,
                name: vm.subscription.account_name,
                id: vm.subscription.id
            });
        });

        var changeCloudTab = $rootScope.$on('changeCloudTab', function(event, args) {
            vm.currentTab = args.tab;
            vm.billingInfoAvailable = accountService.getValues('cloud', 'billingInfoAvailable');
            $scope.$on('$destroy', changeCloudTab);
        });

        var completeReload = $rootScope.$on('completeReload', function() {
            accountService.clear();
            vm.subscription = undefined;
            vm.defaults = undefined;
            vm.otherAccounts = undefined;
            vm.billingInfoAvailable = undefined;
            run();
        })

        var reloadSubscription = $rootScope.$on('reloadSubscription', function(event, args) {
            if (args.type == 'cloud') {
                vm.subscription.account_name = args.data.account_name;
                vm.subscription.status = args.data.status;
                vm.subscription.payment_plan = args.data.payment_plan;
                vm.subscription.payment_type = args.data.payment_type;
                vm.subscription.date_started = args.data.date_started;
                vm.subscription.date_expiration = args.data.date_expiration;
                vm.subscription.date_last_modified = $filter('date')($filter('createDate')({date: vm.subscription.date_last_modified, format: 'YMD HIS'}), 'yyyy-MM-dd HH:mm:ss');
                accountService.setValues('cloud', 'subscription', vm.subscription);
            }
            $scope.$on('$destroy', reloadSubscription);
        });

        var reloadDefaults = $rootScope.$on('reloadDefaults', function(event, args) {
            if (args.type == 'cloud') {
                vm.defaults = baseAccountService.remapLimits(args.data, 'wurfl_cloud_default_limit');
            }
            $scope.$on('$destroy', reloadDefaults);
        });

        var disableButtons = $rootScope.$on('disableButtons', function() {
            vm.loading = true;
            $scope.$on('$destroy', disableButtons);
        });

        var suspendedAccount = $rootScope.$on('suspendedAccount', function(event, args) {
            if (args.type == 'cloud') {
                if (args.success) {
                    vm.subscription.status = 'SUSPENDED';
                    vm.subscription.date_last_modified = $filter('date')(new Date(), 'yyyy-MM-dd HH:mm:ss');
                    Notification.success("Account has been suspended");
                } else {
                    Notification.error("Could not suspend account");
                }
                vm.loading = false;
            }
            $scope.$on('$destroy', suspendedAccount);
        });

        var unsuspendedAccount = $rootScope.$on('unsuspendedAccount', function(event, args) {
            if (args.type == 'cloud') {
                if (args.success) {
                    vm.subscription.status = 'ACTIVE';
                    vm.subscription.date_last_modified = $filter('date')(new Date(), 'yyyy-MM-dd HH:mm:ss');
                    Notification.success("Account has been reactivated");
                } else {
                    Notification.error("Could not unsuspend account");
                }
                vm.loading = false;
            }
            $scope.$on('$destroy', unsuspendedAccount);
        });

        switch ($state.current.name) {
            case 'cloud.view':
                vm.currentTab = 0;
                break;
            case 'cloud.view.configuration':
                vm.currentTab = 1;
                break;
            case 'cloud.view.usage':
                vm.currentTab = 2;
                break;
            case 'cloud.view.billing':
                vm.currentTab = 3;
                break;
            default:

        }

        function cancel() {
            subscriptionModalService.cancel('cloud');
        }

        function createNew() {
            subscriptionModalService.createNew('cloud');
        }

        function edit() {
            subscriptionModalService.edit('cloud');
        }

        function editDefaults() {
            subscriptionModalService.editDefaults('cloud');
        }

        function endTrial() {
            cloudModalService.upgrade('trial');
        }

        function run() {
            baseAccountService.get(cloudAccountService.resourceUrl, $stateParams.id)
                .then(function(response) {
                    accountService.setId('cloud', $stateParams.id);
                    vm.subscription = response.data.account;
                    vm.steps = breadcrumbsService.get('cloud.view', {
                        username: vm.subscription.user.username,
                        userid: vm.subscription.user_id,
                        name: vm.subscription.account_name
                    });
                    vm.otherAccounts = response.data.other_accounts;
                    accountService.setValues('cloud', 'otherAccounts', vm.otherAccounts);
                    accountService.setValues('cloud', 'plans', response.data.plans);
                    vm.defaults = baseAccountService.remapLimits(vm.subscription.limits, 'wurfl_cloud_default_limit');
                    accountService.setValues('cloud', 'defaults', vm.defaults);
                    vm.billingInfoAvailable = baseAccountService.isFree(vm.subscription) === false && baseAccountService.isStandardBilling(vm.subscription);
                    accountService.setValues('cloud', 'billingInfoAvailable', vm.billingInfoAvailable);
                    vm.subscription.cloud_capabilities_records = _.pluck(vm.subscription.cloud_capabilities, 'capability');
                    accountService.setValues('cloud', 'subscription', vm.subscription);
                    if (vm.subscription.status == 'CANCELED') {
                        baseAccountService.getCancelLog(cloudAccountService.resourceUrl, $stateParams.id)
                            .then(function(response) {
                                vm.cancelReason = response.data.reason == '' ? "No reason provided" : response.data.reason;
                                accountService.setValues('cloud', 'cancelReason', vm.cancelReason);
                            }).catch(function(error) {
                                console.log(error);
                            });
                    }
                    if ($stateParams.edit) {
                        edit();
                    } else if ($stateParams.editDefaults) {
                        editDefaults();
                    }
                }).catch(function(error) {
                    console.log(error);
                    Notification.error("The account could not be loaded");
                }).finally(function() {
                    vm.loading = false;
                });
        }

        function restart() {
            subscriptionModalService.restart('cloud', vm.subscription);
        }

        function suspend() {
            subscriptionModalService.suspend('cloud');
        }

        function swapPaths() {
            if (vm.subscription.payment_type == 'STANDARD') {
                $ngConfirm({
                    title: 'Are you sure?',
                    theme: 'dark',
                    content: 'This will cancel recurring payments for the subscription.<br /><br />Are you sure you want to continue?',
                    buttons: {
                        close: {
                            text: 'Cancel',
                            btnClass: 'btn-default'
                        },
                        proceed: {
                            text: 'Continue',
                            btnClass: 'btn-primary',
                            action: function() {
                                vm.subscription.removePayment = true;
                                vm.subscription.payment_type = 'EXTERNAL';
                                baseAccountService.update("/api/admin/cloud", vm.subscription)
                                    .then(function(response) {
                                        Notification.success('Updated account');
                                        $rootScope.$broadcast('reloadSubscription', {type: 'cloud', data: response.data});
                                    }, function(error) {
                                        Notification.error(error.data.message);
                                        console.log(error);
                                    });
                            }
                        }
                    }
                });
            } else {
                cloudModalService.upgrade('standard');
            }
        }

        function upgrade() {
            cloudModalService.upgrade();
        }

        function unsuspend() {
            subscriptionModalService.unsuspend('cloud');
        }

        function updateBreadcrumbs(tab) {
            if (tab == 'view') {
                var state = 'cloud.view';
                vm.currentTab = 0;
            } else {
                var state = 'cloud.view.'+tab;
                switch (tab) {
                    case 'configuration':
                        vm.currentTab = 1;
                        break;
                    case 'usage':
                        vm.currentTab = 2;
                        break;
                    case 'billing':
                        vm.currentTab = 3;
                        break;
                }
            }
            vm.steps = breadcrumbsService.get(state, {
                username: vm.subscription.user.username,
                userid: vm.subscription.user_id,
                name: vm.subscription.account_name,
                id: vm.subscription.id
            });
        }
    }
})();
