(function() {
    'use strict';

    angular.module('adminApp')
        .directive('externalLink', externalLink);

    function externalLink() {
        var directive = {
            restrict: 'AE',
            replace: true,
            template: '<span style="font-size: 10px; vertical-align: top;" class="fa fa-external-link"></span>'
        };
        return directive;
    }
})();
