(function() {
    'use strict';

    angular.module('adminApp')
        .directive('pagination', pagination);

    pagination.$inject = ['$state'];

    function pagination($state) {
        var directive = {
            restrict: 'A',
            replace: false,
            templateUrl: 'view/components.pagination',
            scope: {
                numPages: '@',
                pages: '=',
                currentPage: '@',
                currentState: '@',
                stateParams: '=',
                searchParams: '='
            },
            link: link
        };

        return directive;

        function link(scope) {
            scope.selectPage = selectPage;

            function selectPage(page) {
                scope.stateParams.page = page;
                $state.go(scope.currentState, scope.stateParams, {reload: false});
            }
        }
    }
})();
