(function() {
    'use strict';

    angular.module("adminApp.licenses")
        .directive('licensesSearchByUser', licensesSearchByUser);

    licensesSearchByUser.$inject = ['licenseService'];

    function licensesSearchByUser(licenseService) {
        var directive = {
            restrict: 'E',
            transclude: true,
            replace: true,
            template: '<input type="text" autocomplete="off" ng-disabled="disable" uib-typeahead="license.id as (license.user.username+\'->\'+license.product.product_name+\' [\'+license.id+\']\') for license in licenses | filter:$viewValue | limitTo:10" typeahead-min-length="0">',
            link: link
        };

        return directive;

        function link(scope, element, attrs) {
            scope.disable = true;

            element.attr("placeholder", attrs.preholder);
            element.attr("class", attrs.class);

            licenseService.all({paginate: 100000}).then(function(response) {
                scope.licenses = response.data;
                element.attr('placeholder',attrs.placeholder);

                scope.disable = false;
            }, function(error) {
                element.attr('placeholder','Action not permitted');
            });
        }
    }
})();
