(function() {
    'use strict';

    angular.module('adminApp.saas')
        .controller('WitEditAccountFormController', WitEditAccountFormController);

    WitEditAccountFormController.$inject = ['$filter', '$ocLazyLoad', '$rootScope', '$scope', '$state', '$stateParams', '$uibModal', '$uibModalInstance', 'accountService', 'baseAccountService', 'IE', 'Notification', 'saasConstants', 'saasType', 'witAccountService', 'witModalService'];

    function WitEditAccountFormController($filter, $ocLazyLoad, $rootScope, $scope, $state, $stateParams, $uibModal, $uibModalInstance, accountService, baseAccountService, IE, Notification, saasConstants, saasType, witAccountService, witModalService) {
        var vm = this;

        vm.calendar = {
            open: false
        };
        vm.cleanPaymentTypes = null;
        vm.close = close;
        vm.form = angular.copy(accountService.getValues(saasType, 'subscription'));
        vm.loading = true;
        vm.options = {
            minDate: new Date(),
            showWeeks: true,
            formatDay: 'dd',
            formatMonth: 'MM',
            formatYear: 'yyyy'
        };
        vm.simpleUpdate = simpleUpdate;
        vm.subscription = accountService.getValues(saasType, 'subscription');
        vm.tier = null;
        vm.tier_ids = null;
        vm.toggleCalendar = toggleCalendar;

        vm.cleanPaymentTypes = saasConstants.getConstants(saasType, 'paymentTypes');

        var service = baseAccountService.getService(saasType);

        if (vm.subscription === undefined) {
            loadSubscription();
        } else {
            var plans = accountService.getValues(saasType, 'plans');
            vm.tier = witAccountService.remapLimits(vm.subscription, plans, true);
            vm.tier_ids = angular.copy(vm.tier.ids);

            delete vm.tier.ids;
            vm.loading = false;
            if (!_.isNull(vm.subscription.date_expiration)) {
                var expireDate = $filter('createDate')({date: angular.copy(vm.subscription.date_expiration), format: 'YMD HIS'});
                vm.form.date_expiration = angular.copy(expireDate);
            }
        }

        function loadSubscription() {
            baseAccountService.get(service.resourceUrl, $stateParams.id)
                .then(function(response) {
                    console.log(response.data);
                    vm.subscription = response.data.account;
                    vm.tier = witAccountService.remapLimits(vm.subscription, response.data.plans, true);
                    vm.tier_ids = angular.copy(vm.tier.ids);
                    delete vm.tier.ids;
                    accountService.setValues('wit', 'subscription', response.data.account);
                    vm.form = angular.copy(response.data.account);
                    if (!_.isNull(vm.subscription.date_expiration)) {
                        var expireDate = $filter('createDate')({date: angular.copy(vm.subscription.date_expiration), format: 'YMD HIS'});
                        vm.form.date_expiration = angular.copy(expireDate);
                    }

                    vm.loading = false;
                }, function(error) {
                    console.log(error);
                    Notification.error("The account could not be loaded");
                });
        }

        function close() {
            $uibModalInstance.dismiss();
        }

        function simpleUpdate() {
            expireDate = _.isUndefined(expireDate) ? null : expireDate;
            vm.loading = true;
            // NEED MORE INFO ON PAYMENT PLAN PRO WITH PAYMENT TYPE STANDARD
            if (vm.subscription.payment_plan === 'IMAGEENGINE_PRO' && vm.subscription.payment_type !== 'STANDARD') {
                var originalDate = angular.copy(vm.form.date_expiration);
                vm.form.date_expiration = $filter('date')(vm.form.date_expiration, "yyyy-MM-dd");
            }

            if (
                vm.subscription.payment_plan === 'IMAGEENGINE_PRO' &&
                !angular.equals(expireDate, originalDate) &&
                vm.form.payment_type == 'TRIAL'
            ) {
                // Expiration date has changed during trial
                var trialDaysLeft = moment(vm.form.date_started).add(vm.form.plan.trial_days - 1, 'd').startOf('day').diff(moment().startOf('day'), 'd');
                var trialExpireDate = moment(vm.form.date_started).add(vm.form.plan.trial_days - 1, 'd').format('YYYY-MM-DD');
                witModalService.openExpirationDateUpdate(trialDaysLeft, trialExpireDate)
                    .result.then(function(response) {
                        if (response == 'extend') {
                            vm.tier.trial_days.value = moment(originalDate).startOf('day').diff(moment(vm.form.date_started).startOf('day'), 'd') + 1;
                            witAccountService.updateDefaults($stateParams.id, vm.tier, vm.tier_ids)
                                .then(function(response) {
                                    accountService.clear();
                                    Notification.success(response.message);
                                    $rootScope.$broadcast('reloadWitDefaults', {data: response.data});
                                    $rootScope.$broadcast('reloadWit', {});
                                    $uibModalInstance.dismiss();
                                }).catch(function(error) {
                                    Notification.error(error.data.message);
                                    console.log(error);
                                });
                        } else {
                            witAccountService.update(vm.form, 'update').then(function(response) {
                                vm.loading = false;
                                Notification.success('Updated account information');
                                $uibModalInstance.dismiss();
                                accountService.setValues('wit', 'subscription', undefined);
                                $rootScope.$broadcast('reloadWit', {data: response.data});
                            }).catch(function(error) {
                                vm.loading = false;
                                vm.form = angular.copy(accountService.getValues('wit', 'subscription'));
                                vm.form.date_expiration = originalDate;
                                Notification.error("Unable to update account");
                                console.log(error);
                            })
                        }
                    }).catch(function() {
                        $uibModalInstance.dismiss();
                    });
            } else {
                witAccountService.update(vm.form, 'update').then(function(response) {
                    vm.loading = false;
                    Notification.success('Updated account information');
                    $uibModalInstance.dismiss();
                    accountService.setValues('wit', 'subscription', undefined);
                    $rootScope.$broadcast('reloadWit', {data: response.data});
                }).catch(function(error) {
                    vm.loading = false;
                    vm.form = angular.copy(accountService.getValues('wit', 'subscription'));
                    vm.form.date_expiration = originalDate;
                    Notification.error("Unable to update account");
                    console.log(error);
                })
            }
        }

        function toggleCalendar() {
            vm.calendar.opened = vm.calendar.opened == true ? false : true;
        }
    }
})();
