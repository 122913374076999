(function() {
    'use strict';

    angular.module('adminApp.users')
        .controller('UserBulkSupportController', UserBulkSupportController);

    UserBulkSupportController.$inject = ['$location', '$ngConfirm', '$rootScope', '$scope', '$state', '$stateParams', '$timeout', 'breadcrumbsService', 'Notification', 'paginationService', 'userService'];

    function UserBulkSupportController($location, $ngConfirm, $rootScope, $scope, $state, $stateParams, $timeout, breadcrumbsService, Notification, paginationService, userService) {
        var vm = this;

        vm.bulkUpdate = bulkUpdate;
        vm.bulkUsers = [];
        vm.clear = clear;
        vm.columns = [
            {
                name: 'Username',
                search: {
                    isSearchable: true,
                    type: 'text'
                },
                model: 'username',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: 'Email',
                search: {
                    isSearchable: true,
                    type: 'text'
                },
                model: 'email',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: 'Support Tier',
                search: {
                    isSearchable: true,
                    type: 'select',
                    id: 'tier_name'
                },
                model: 'tier_name',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: '',
                search: {
                    isSearchable: false
                },
                model: '',
                value: {
                    fromData: false,
                },
                display: true
            }
        ];
        vm.moveUsers = moveUsers;
        vm.saving = false;
        vm.search = {};
        vm.selectedTier = null;
        vm.selectError = false;
        vm.steps = breadcrumbsService.get('users.supporttiers');
        vm.zendeskTiers = null;

        var currentPage = $stateParams.page;
        var search_params = _.clone($location.search());

        var supporttiersSearchParamsUpdated = $rootScope.$on("supporttiersSearchParamsUpdated", function(event, args) {
            vm.search = args;
            currentPage = 1;
            getUsers(args);
        });

        var supporttiersPageChanged = $rootScope.$on("supporttiersPageChanged", function(event, args) {
            vm.search = args;
            currentPage = args.page;
            getUsers(args);
        });

        var supporttiersItemsPerPageChanged = $rootScope.$on("supporttiersItemsPerPageChanged", function(event, args) {
            vm.search = args;
            currentPage = 1;
            getUsers(args);
        });

        $timeout(function() {
            userService.getZendeskTiers().then(function(response) {
                vm.zendeskTiers = response.data;
                getUsers();
                $rootScope.$broadcast('supporttiersUpdateSelect', {
                    id: 'tier_name',
                    data: vm.zendeskTiers,
                    keyNeeded: false,
                    value: 'tier_name',
                    show: 'tier_name'
                });
            }).catch(function(error) {
                console.log(error);
                Notification.error("An error occurred while loading support tiers");
            });
        }, 1000);

        function getUsers(searchParams) {
            searchParams = typeof searchParams !== 'undefined' ? searchParams : null;
            var start = 0;
            var number = 10;

            var query_params = {};
            var next_page = 1;

            if (searchParams !== null) {
                next_page = searchParams.page;
                number = searchParams.paginate;
            } else if (search_params && _.isUndefined(search_params.page) === false) { // if defined
                next_page = search_params.page;
            } else {
                next_page = Math.floor(start/number) + 1;
            }

            query_params = {page: next_page};
            if (searchParams !== null) {
                query_params = _.extend(searchParams);
            }
            if (!_.isUndefined(query_params.tier_name)) {
                if (query_params.tier_name != '') {
                    var tier = _.findWhere(vm.zendeskTiers, {tier_name: query_params.tier_name});
                    if (!_.isUndefined(tier)) {
                        query_params.zendesk_tier_id = tier.id;
                    }
                } else {
                    query_params = _.omit(query_params, function(value, key, object) {
                        return key == 'zendesk_tier_id' || key == 'tier_name';
                    });
                }
            }

            userService.all(query_params).then(function(response) {
                angular.forEach(response.data, function(val, key) {
                    if (val.country !== null) {
                        val.country_name = val.country.name;
                    }
                    if (val.enabled == 0) {
                        val.addedClass = 'color: red;';
                    } else {
                        val.addedClass = '';
                    }
                    if (val.zendesk_tier_id != null) {
                        val.tier_name = _.findWhere(vm.zendeskTiers, {id: val.zendesk_tier_id}).tier_name;
                    }
                });

                var paginationData = {};
                var numPages = response.pagination.last_page;

                paginationData.pages = paginationService.createPages(currentPage, numPages);
                paginationData.numPages = numPages;
                paginationData.currentPage = currentPage;
                paginationData.currentState = $state.current.name;
                paginationData.stateParams = $stateParams;
                paginationData.from = paginationService.from(response.pagination);
                paginationData.to = paginationService.to(response.pagination);
                paginationData.total = response.pagination.total;
                console.log(paginationData);
                $rootScope.$broadcast('supporttiersDataLoaded', {
                    data: response.data,
                    paginationData: paginationData,
                    itemsPerPage: number
                });
                $scope.$on('$destroy', supporttiersSearchParamsUpdated);
                $scope.$on('$destroy', supporttiersPageChanged);
                $scope.$on('$destroy', supporttiersItemsPerPageChanged);
            }, function(error) {
                console.error(error);
                Notification.error("Could not fetch the user records");
                $scope.$on('$destroy', supporttiersSearchParamsUpdated);
                $scope.$on('$destroy', supporttiersPageChanged);
            });
        }

        $rootScope.$on('reloadSupporttiers', function(event, args) {
            if (args.page === undefined) {
                getUsers($stateParams);
            } else {
                getUsers(args);
            }
        });

        $rootScope.$on('moveSupportTier', function(event, args) {
            moveUsers(args);
        });

        // getUsers();

        function bulkUpdate() {
            vm.selectError = false;
            if (_.isNull(vm.selectedTier)) {
                vm.selectError = true;
                return;
            }
            if (_.isEmpty(vm.bulkUsers)) {
                $ngConfirm({
                    title: 'Alert!',
                    theme: 'dark',
                    content: 'Please select at least one user to update.',
                    buttons: {
                        close: {
                            text: 'OK',
                            btnClass: 'btn-primary'
                        }
                    }
                });
                return;
            }
            vm.saving = true;
            userService.postUpdateZendeskTierBulk(vm.selectedTier, _.pluck(vm.bulkUsers, 'id')).then(function(response) {
                Notification.success('Updated support tiers!');
                $rootScope.$broadcast('supporttiersTriggerLoading');
                getUsers($stateParams);
                vm.bulkUsers = [];
                vm.selectedTier = null;
            }).catch(function(error) {
                console.log(error);
                Notification.error("Unable to update support tiers");
            }).finally(function() {
                vm.saving = false;
            });
        }

        function clear() {
            vm.bulkUsers = [];
            vm.selectedTier = null;
        }

        function moveUsers(args) {
            if (args.add) {
                if (_.isUndefined((_.find(vm.bulkUsers, function(obj) {
                    return obj.id === args.id;
                })))) {
                    vm.bulkUsers.push({id: args.id, username: args.username});
                }
            } else {
                vm.bulkUsers = _.reject(vm.bulkUsers, function(obj) {
                    return obj.id == args.id;
                });
            }
        }
    }
})();
