(function() {
    'use strict';

    angular.module('adminApp.cloud')
        .controller('CloudBillingController', CloudBillingController);

    CloudBillingController.$inject = ['$rootScope', '$state', '$stateParams', 'accountService', 'baseAccountService', 'cloudAccountService', 'Notification'];

    function CloudBillingController($rootScope, $state, $stateParams, accountService, baseAccountService, cloudAccountService, Notification) {
        var vm = this;

        vm.payments = accountService.getValues('cloud', 'payments');

        var subscription = accountService.getValues('cloud', 'subscription');

        if (accountService.getId('cloud') === undefined || accountService.getId('cloud') !== $stateParams.id) {
            accountService.clear();
            vm.payments = undefined;
            subscription = undefined;
        }

        if (subscription === undefined || vm.payments === undefined) {
            baseAccountService.get(cloudAccountService.resourceUrl, $stateParams.id)
                .then(function(response) {
                    accountService.setId('cloud', $stateParams.id);
                    subscription = response.data.account;
                    accountService.setValues('cloud', 'otherAccounts', response.data.other_accounts);
                    accountService.setValues('cloud', 'plans', response.data.plans);
                    accountService.setValues('cloud', 'defaults', baseAccountService.remapLimits(subscription.limits, 'wurfl_cloud_default_limit'));
                    accountService.setValues('cloud', 'billingInfoAvailable',
                        baseAccountService.isFree(subscription) === false && baseAccountService.isStandardBilling(subscription));
                    subscription.cloud_capabilities_records = _.pluck(subscription.cloud_capabilities, 'capability');
                    accountService.setValues('cloud', 'subscription', subscription);
                    $rootScope.$broadcast('updateCloudSubscription');
                    initBillingHistory();
                }, function(error) {
                    console.log(error);
                    Notification.error("The account could not be loaded");
                });
        }

        function initBillingHistory() {
            if (baseAccountService.isFree(subscription) || baseAccountService.isStandardBilling(subscription) === false) {
                var message;

                if (baseAccountService.isFree(subscription)) {
                    message = 'The Account is free. No billing information is available';
                } else {
                    message = 'The Account has not been set up with standard billing.';
                }

                Notification.warning(message);
                vm.currentTab = 0;
                accountService.setValues('cloud', 'billingInfoAvailable', false);
                $rootScope.$broadcast('changeCloudTab', {tab: 0});
                $state.go('cloud.view', $stateParams);
            } else {
                baseAccountService.billingHistory(cloudAccountService.resourceUrl, $stateParams.id)
                    .then(function(response) {
                        vm.payments = response.data;
                        accountService.setValues('cloud', 'payments', vm.payments);
                    }, function(error) {
                        console.log(error);
                        Notification.error(error.data.message);
                        accountService.setValues('cloud', 'billingInfoAvailable', false);
                        $rootScope.$broadcast('changeCloudTab', {tab: 0});
                        $state.go('cloud.view', $stateParams);
                    });
            }
        }

    }
})();
