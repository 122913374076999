(function() {
    'use strict';

    angular.module('adminApp.home')
        .controller('HomeSidebarModalController', HomeSidebarModalController);

    HomeSidebarModalController.$inject = ['$rootScope', '$scope', '$uibModalInstance', 'devAdminService', 'loginService', 'title', 'view'];

    function HomeSidebarModalController($rootScope, $scope, $uibModalInstance, devAdminService, loginService, title, view) {
        var vm = this;

        vm.close = close;
        vm.title = title;
        vm.view = view;

        var sidebarDismiss = $rootScope.$on('sidebarDismiss', function() {
            close();
            $scope.$on('$destroy', sidebarDismiss);
        });

        function close() {
            $uibModalInstance.dismiss();
        }

    }
})();
