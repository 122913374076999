(function() {
    'use strict';

    angular.module('adminApp.licenses')
        .controller('LicensesViewController', LicensesViewController);

    LicensesViewController.$inject = ['$location', '$rootScope', '$scope', '$sce', '$state', '$stateParams', '$uibModal', '$window', 'breadcrumbsService', 'licensesModalService', 'licenseService', 'Notification', 'paginationService'];

    function LicensesViewController($location, $rootScope, $scope, $sce, $state, $stateParams, $uibModal, $window, breadcrumbsService, licensesModalService, licenseService, Notification, paginationService) {
        var vm = this;

        vm.activeTab = 0;
        vm.capabilitiesShow = licenseService.getValues($stateParams.id, 'capabilitiesShow');
        vm.configurationDisabled = licenseService.getValues($stateParams.id, 'configurationDisabled');
        vm.configurationShow = licenseService.getValues($stateParams.id, 'configurationShow');
        vm.createNew = createNew;
        vm.enable = enable;
        vm.goProfile = goProfile;
        vm.license = licenseService.getValues($stateParams.id, 'license');
        vm.remove = remove;
        vm.revoke = revoke;
        vm.setEditingInfo = setEditingInfo;
        vm.stateParams = $stateParams;
        vm.steps = {};
        vm.transfer = transfer;
        vm.usersAppView = usersAppView;

        if (vm.license === undefined || vm.configurationDisabled === undefined) {
            licenseService.get($stateParams.id).then(function(response) {
                vm.license = response.data;
                vm.steps = breadcrumbsService.get('licenses.view', {
                    username: vm.license.user.username,
                    name: vm.license.product.product_name,
                    userid: vm.license.user.id
                });

                loadLicenseText(vm.license);
                if (
                    vm.license.product.type.toLowerCase() !== 'infuze' &&
                    vm.license.product.type.toLowerCase() !== 'insight' &&
                    vm.license.product.type.toLowerCase() !== 'onsite'
                ) {
                    vm.configurationDisabled = true;
                } else {
                    vm.configurationDisabled = false;
                }
                if (vm.license.product.product_code == 'INSIGHT') {
                    vm.capabilitiesShow = false;
                    vm.configurationShow = false;
                } else if (vm.license.product.type.toLowerCase() === 'insight') {
                    vm.capabilitiesShow = true;
                    vm.configurationShow = false;
                } else {
                    vm.capabilitiesShow = true;
                    vm.configurationShow = true;
                }
                licenseService.setValues($stateParams.id, 'capabilitiesShow', false);
                licenseService.setValues($stateParams.id, 'configurationShow', false);
                licenseService.setValues($stateParams.id, 'configurationDisabled', vm.configurationDisabled);
            });
        }

        $scope.$watch('vm.license', function(newVal) {
            if (!_.isUndefined(newVal) && $stateParams.edit) {
                setEditingInfo();
            }
        });

        switch ($state.current.name) {
            case 'licenses.view':
                vm.activeTab = 0;
                break;
            case 'licenses.view.capabilities':
                vm.activeTab = 1;
                break;
            case 'licenses.view.configuration':
                vm.activeTab = 2;
                break;
            case 'licenses.view.text':
                vm.activeTab = 3;
                break;
            default:

        }

        $scope.$on('reinitializeBreadcrumbs', function() {
            vm.steps = breadcrumbsService.get('licenses.view', {
                username: vm.license.user.username,
                name: vm.license.product.product_name,
                userid: vm.license.user.id
            });
        });

        $rootScope.$on('reloadLicense', function() {
            vm.license = {};
            licenseService.get($stateParams.id).then(function(response) {
                vm.license = response.data;
                vm.steps = breadcrumbsService.get('licenses.view', {
                    username: vm.license.user.username,
                    name: vm.license.product.product_name,
                    userid: vm.license.user.id
                });
            });
        })

        function createNew() {
            licensesModalService.create();
        }

        function enable() {
            licensesModalService.enable($stateParams.id);
        }

        function goProfile() {
            $state.go('licenses.insight.index', {userId: vm.license.user.id, id: vm.license.id});
        }

        function loadLicenseText(license) {
            licenseService.text(license)
                .then(function(response) {
                    vm.license.text = $sce.trustAsHtml(response.data.text);
                    licenseService.setValues($stateParams.id, 'license', vm.license);
                }, function(error) {
                    console.log(error);
                });
        }

        function remove() {
            licensesModalService.remove(vm.license.id);
        }

        function revoke(license) {
            licensesModalService.revoke($stateParams.id);
        }

        function setEditingInfo() {
            var form = _.clone(vm.license);

            form.date = new Date(vm.license.license_date);
            form.expire = new Date(vm.license.license_expire);
            licensesModalService.edit(form);
        }

        function transfer(license) {
            licensesModalService.transfer({license: license});
        }

        function usersAppView(id) {
            $state.go('users.view', {id: id});
        }
    }
})();
