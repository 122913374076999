(function() {
    angular.module('adminApp.saas')
        .service('accountDefaults', accountDefaults);

    accountDefaults.$inject = ['$resource'];

    function accountDefaults($resource) {
        var service = {
            setResourceUrl: setResourceUrl,
            update: update
        };

        var resourceUrl;
        return service;

        function setResourceUrl(url) {
            resourceUrl = url + '/:id/defaults';
        }

        function update(account_id, data) {
            var defaults = $resource(resourceUrl, {id: '@subscription_id'}, {
                'updateAll': {method: 'PATCH'}
            });
            return defaults.updateAll({id: account_id}, data).$promise;
        }
    }
})();
