(function() {
    'use strict';

    angular.module('adminApp.licenses')
        .directive('buildProductSelect', buildProductSelect);

    buildProductSelect.$inject = ['$stateParams', 'buildProductService'];

    function buildProductSelect($stateParams, buildProductService) {
        var directive = {
            restrict: 'E',
            transclude: false,
            replace: true,
            template: '<select></select>',
            scope: true,
            link: link
        };

        return directive;

        function link(scope, element, attr) {
            scope.product_disable = true;
            buildProductService.asOptions($stateParams.id)
                .then(function(response) {

                    var build_products = [];

                    response.data.forEach(function(bp) {
                        if(bp.active != 0) {
                            build_products.push(bp);
                        }
                    });

                    scope.products = build_products;
                    scope.product_disable  = false;
                }
            );
        }
    }
})();
