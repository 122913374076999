(function() {
    'use strict';

    angular.module('adminApp.cut')
        .controller('CutCenterController', CutCenterController);

    CutCenterController.$inject = ['breadcrumbsService', 'cutModalService'];

    function CutCenterController(breadcrumbsService, cutModalService) {
        var vm = this;

        vm.createTest = createTest;
        vm.exportTests = exportTests;
        vm.steps = breadcrumbsService.get('centraltest.default');

        function createTest() {
            cutModalService.create('test', [], 'none', 0, {ua_override: 0, enabled: 0}, false);
        }

        function exportTests() {
            cutModalService.export();
        }
    }
})();
