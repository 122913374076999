(function() {
    'use strict';

    angular.module('adminApp.users')
        .controller('UsersController', UsersController);

    UsersController.$inject = ['$ngConfirm', '$rootScope', '$scope', '$state', '$stateParams', '$uibModal', '$window', 'breadcrumbsService', 'cloudAccountService', 'externalService', 'loginService', 'Notification', 'userModalService', 'userRoles', 'userService', 'witAccountService', 'wjsAccountService', 'teamService', 'quwurflAccountService', 'adminlogService', 'teamModalService'];

    function UsersController($ngConfirm, $rootScope, $scope, $state, $stateParams, $uibModal, $window, breadcrumbsService, cloudAccountService, externalService, loginService, Notification, userModalService, userRoles, userService, witAccountService, wjsAccountService, teamService, quwurflAccountService, adminlogService, teamModalService) {
        var vm = this;

        vm.accountLabels = {
            data: 'Data',
            infuze: 'InFuze',
            onsite: 'OnSite',
            insight: 'InSight',
            cloud: 'Cloud',
            wjs: 'WURFL.js',
            imgeng: 'ImageEngine',
            quwurfl: 'QuWURFL'
        };
        vm.accounts = {};
        vm.accountsExist = false;
        vm.accountsLoaded = false;
        vm.activeTab = 0;
        vm.changePassword = changePassword;
        vm.changeRoles = true;
        vm.confirmUser = confirmUser;
        vm.createNew = createNew;
        vm.deleteUser = deleteUser;
        vm.edit = edit;
        vm.editPlatforms = editPlatforms;
        vm.editRoles = editRoles;
        vm.editZendeskTier = editZendeskTier;
        vm.enableUser = enableUser;
        vm.errors = [];
        vm.impersonate = impersonate;
        vm.loaded = {
            resellers: false,
            platforms: false,
            user: false,
            userPlatforms: false,
            zendesk: false
        };
        vm.loading = true;
        vm.logins = [];
        vm.loginTabDisabled = loginTabDisabled;
        vm.platformDisplay = null;
        vm.platforms = {};
        vm.resellers = {};
        vm.teams = {};
        vm.revokeUser = revokeUser;
        vm.save = save;
        vm.services = {
            wit: witAccountService,
            cloud: cloudAccountService,
            wjs: wjsAccountService
        };
        vm.stateParams = $stateParams;
        vm.steps = {};
        vm.tabs = {};
        vm.tabsDisabled = false;
        vm.transferAccounts = transferAccounts;
        vm.user = {};
        vm.userZendeskTier = null;
        vm.zendeskTiers = null;
        vm.viewChangelog = viewChangelog;
        vm.showTeamUsers = showTeamUsers;

        var authRoles = [];
        var config = {};
        config.userRoles = userRoles;

        switch ($state.current.name) {
            case 'users.view':
                vm.activeTab = 0;
                break;
            case 'users.view.logins':
                vm.activeTab = 1;
                break;
            case 'users.view.infuze':
                vm.activeTab = 2;
                break;
            case 'users.view.onsite':
                vm.activeTab = 3;
                break;
            case 'users.view.insight':
                vm.activeTab = 4;
                break;
            case 'users.view.data':
                vm.activeTab = 5;
                break;
            case 'users.view.cloud':
                vm.activeTab = 6;
                break;
            case 'users.view.wjs':
                vm.activeTab = 7;
                break;
            case 'users.view.wit':
                vm.activeTab = 8;
                break;
            case 'users.view.downloads':
                vm.activeTab = 9;
                break;
            case 'users.view.filex':
                vm.activeTab = 10;
                break;
            // case 'users.view.witimpersonate':
            //     vm.activeTab = 11;
            //     break;
            case 'users.view.quwurfl':
                vm.activeTab = 11;
                break;
            case 'users.view.changelog':
                vm.activeTab = 12;
                break;    
            default:
                vm.activeTab = 0;
                break;
        }

        $rootScope.$on('reloadUser', function() {
            vm.loaded = {
                resellers: false,
                platforms: true,
                user: false,
                userPlatforms: false
            };
            vm.loading = true;
            getUser(false);
        });

        $scope.$watch('vm.loaded', function(newVal) {
            if (_.every(vm.loaded, function(val) {
                return val == true;
            })) {
                vm.loading = false;
                if ($stateParams.edit) {
                    $stateParams.edit = false;
                    edit();
                }
            }
        }, true);

        getUser(true);
        function getUser(load) {
            userService.get($stateParams.id).then(function(response) {
                vm.user = response.data;
                vm.steps = breadcrumbsService.get('users.view', {username: vm.user.username});
                vm.loaded.user = true;
                runOther(load);
                userService.get(loginService.authInfo.user.id).then(function(response) {
                    angular.forEach(response.data.user_roles, function(val, key) {
                        authRoles.push(val.role);
                        if (_.isUndefined(_.find(authRoles, function(val) {
                            return val == 'admin';
                        }))) {
                            if (!_.isUndefined(_.findWhere(vm.user.user_roles, {role: "admin"}))) {
                                vm.changeRoles = false;
                            }
                        }
                    });
                });
            }).catch(function(error) {
                Notification.error("An unexpected error has occurred");
                console.log(error);
                vm.loaded = {
                    resellers: true,
                    platforms: true,
                    user: true,
                    userPlatforms: true
                };
            });
        }
        var total = 0;

        function runOther(load) {
            if (vm.user.enabled !== 0) {
                vm.tabsDisabled = false;

                adminlogService.changelog($stateParams.id).then(function(response) {
                    vm.changelog = response.data;
                }).catch(function(resellerError) {
                    Notification.error("An unexpected error has occurred");
                    console.log(resellerError);
                }).finally(function() {
                    vm.loaded.changelog = true;
                });
                

                userService.getResellers().then(function(resellerResponse) {
                    vm.resellers[0] = {id: -1, name: '(none)'};
                    var i = 1;
                    angular.forEach(resellerResponse.data, function (value, key) {
                        vm.resellers[i] = value;
                        i++;
                    });
                    setResellerName();
                }).catch(function(resellerError) {
                    Notification.error("An unexpected error has occurred");
                    console.log(resellerError);
                }).finally(function() {
                    vm.loaded.resellers = true;
                });

                teamService.all().then(function(resellers) {
                    vm.teams[0] = {id: -1, name: '(none)'};
                    var i = 1;
                    angular.forEach(resellers.data, function (value, key) {
                        vm.teams[i] = value;
                        i++;
                    });
                    setTeamName();
                }).catch(function(teamError) {
                    Notification.error("An unexpected error has occurred");
                    console.log(teamError);
                }).finally(function() {
                    vm.loaded.teams = true;
                });

                if (load) {
                    userService.getAccountCount($stateParams.id).then(function(response) {
                        vm.accounts = response.data;
                        quwurflAccountService.userSubscriptions($stateParams.id).then(function(data) {
                        
                            vm.accounts["quwurfl"] = { "active": data.active, "total": data.total};

                            angular.forEach(vm.accountLabels, function(val, key) {
                                if (vm.accounts[key] == undefined) {
                                    vm.accounts[key] = {
                                        active: 0,
                                        total: 0
                                    };
                                } else {
                                    total += vm.accounts[key].total;
                                }
                            });
                            vm.accountsLoaded = true;
                            if (total > 0) {
                                vm.accountsExist = true;
                            }

                            $rootScope.$digest();
                        })

                        
                        
                    }).catch(function(error) {
                        Notification.error('Could not determine user accounts');
                    });

                    userService.getPlatforms().then(function(response) {
                        vm.platforms = response.data;
                        loadUserPlatforms();
                    }).catch(function(error) {
                        Notification.error("An unexpected error has occurred");
                        console.log(error);
                    }).finally(function() {
                        vm.loaded.platforms = true;
                    });

                    userService.getZendeskTiers().then(function(response) {
                        vm.zendeskTiers = response.data;
                        if (!_.isNull(vm.user.zendesk_tier_id)) {
                            vm.userZendeskTier = _.findWhere(vm.zendeskTiers, {id: vm.user.zendesk_tier_id}).tier_name;
                        }
                    }).catch(function(error) {
                        Notification.error("An error occurred while gathering Zendesk support tiers");
                        console.log(error);
                    }).finally(function() {
                        vm.loaded.zendesk = true;
                    })

                    userService.getLogins($stateParams.id).then(function(response) {
                        vm.logins = response.data.logins;
                    }).catch(function(error) {
                        console.log(error);
                        switch (error.status) {
                            case 404:
                                vm.logins = [];
                                break;
                            default:
                                Notification.error("Unable to determine recent logins");
                                break;
                        }
                    });
                } else {
                    loadUserPlatforms();
                }
            } else {
                vm.tabsDisabled = true;
                vm.loading = false;
            }
        }

        $rootScope.$on('transferredAccounts', function() {
            vm.accounts = {};
            userService.getAccountCount($stateParams.id).then(function(response) {
                vm.accounts = response.data;
                angular.forEach(vm.accountLabels, function(val, key) {
                    if (vm.accounts[key] == undefined) {
                        vm.accounts[key] = {
                            active: 0,
                            total: 0
                        };
                    }
                });
                vm.activeTab = 0;
                $state.go("users.view", $stateParams);
            }).catch(function(error) {
                Notification.error('Could not determine user accounts');
            });
        });

        $rootScope.$on('updatedSupportTier', function(event, args) {
            vm.userZendeskTier = args.name;
            vm.user.zendesk_tier_id = _.findWhere(vm.zendeskTiers, {tier_name: args.name}).id;
        });

        function changePassword() {
            userModalService.changePassword(vm.user);
        }

        function confirmUser() {
            $ngConfirm({
                title: 'Are you sure?',
                theme: 'dark',
                content: "This will fully activate the user's account, allowing them access to their account and providing the ability to create new accounts.<br /><br />Are you sure you want to continue?",
                buttons: {
                    close: {
                        text: 'Cancel',
                        btnClass: 'btn-default'
                    },
                    proceed: {
                        text: 'Continue',
                        btnClass: 'btn-primary',
                        action: function() {
                            userService.patchConfirmAccount(vm.user.id)
                                .then(function(response) {
                                    vm.user.confirmed = true;
                                    Notification.success('User account confirmed!');
                                }).catch(function(error) {
                                    console.log(error);
                                    Notification.error('Unable to confirm user account');
                                });
                        }
                    }
                }
            });
        }

        function createNew() {
            userModalService.createNew();
        }

        function deleteUser() {
            userModalService.deleteUser({accountCounts: vm.accounts, accountLabels: vm.accountLabels});
        }

        function edit() {
            userModalService.edit(_.clone(vm.user), vm.platforms);
        }

        function editPlatforms() {
            userModalService.editPlatforms(_.clone(vm.user.platforms), vm.platforms);
        }

        function editRoles() {
            if (!vm.changeRoles) {
                Notification.error("You are not permitted to do that");
                return;
            }
            userModalService.changeRoles(_.pluck(vm.user.user_roles, 'role'), config.userRoles, vm.user, authRoles);
        }

        function editZendeskTier() {
            userModalService.editZendeskTier({
                zendeskTierId: vm.user.zendesk_tier_id,
                zendeskTiers: vm.zendeskTiers,
                id: $stateParams.id
            });
        }


        function enableUser() {
            $ngConfirm({
                title: 'Are you sure?',
                theme: 'dark',
                content: 'This will return the user to enabled, allowing them access to their account and providing the ability to create new accounts.<br /><br />Are you sure you want to continue?',
                buttons: {
                    close: {
                        text: 'Cancel',
                        btnClass: 'btn-default'
                    },
                    proceed: {
                        text: 'Continue',
                        btnClass: 'btn-primary',
                        action: function() {
                            var form = {
                                id: $stateParams.id,
                                enabled: 1
                            };
                            userService.update(form).then(function(response) {
                                vm.user.enabled = 1;
                                runOther();
                            }).catch(function(error) {
                                console.log(error);
                                Notification.error("Could not enable user");
                            });
                        }
                    }
                }
            });
        }

        function impersonate() {
            // NOTE Window will open, data will appear when async is done
            var tabWindowId = $window.open('about:blank', '_blank');
            // var tabWindowId = $window.open(location.host + "/redirecting", '_blank');
            externalService.postSessionToken(loginService.authInfo.user.id, $stateParams.id).then(function(response) {
                var url = response.data.url;
                var session = response.data.session;
                tabWindowId.location.href = url + "/impersonate/app/admin?session="+session+"&access_token="+localStorage.getItem('access_token');
            }).catch(function(error) {
                Notification.error(error.data.message);
            });
        }

        function loadUserPlatforms() {
            userService.getUserPlatforms($stateParams.id).then(function(response) {
                vm.user.platforms = [];
                angular.forEach(response.data, function(value, key) {
                    vm.user.platforms.push(value.platform_id);
                });
                var platforms = [];
                angular.forEach(vm.user.platforms, function(val, key) {
                    var exists = _.find(vm.platforms, function(obj) {
                        return obj.id == val;
                    });
                    platforms.push(exists.platform_name);
                });
                vm.platformDisplay = _.isEmpty(platforms) ? 'None' : platforms.join(', ');
            }).catch(function(error) {
                Notification.error("An unexpected error has occurred");
                console.log(error);
            }).finally(function() {
                vm.loaded.userPlatforms = true;
            });
        }

        function loginTabDisabled() {
            if (vm.tabsDisabled || _.isEmpty(vm.logins)) {
                return true;
            }
            return false;
        }

        function revokeUser() {
            userModalService.revoke({accountCounts: vm.accounts, accountLabels: vm.accountLabels});
        }

        function save(form) {
            vm.errors = [];
            form.country_id = form.country.country_id;
            if (form.reseller_id == -1) {
                form.reseller_id = null;
            }

            userService.update(form).then(function(response) {
                vm.user = _.extendOwn(vm.user, _.omit(response.data, 'user_roles'));
                vm.user.country = form.country;
                setResellerName();
                userService.updateUserPlatforms(vm.user.id, vm.user.platforms).then(function(platformResponse) {
                    vm.editing = false;
                    var platforms = [];
                    angular.forEach(vm.user.platforms, function(val, key) {
                        var exists = _.find(vm.platforms, function(obj) {
                            return obj.id == val;
                        });
                        platforms.push(exists.platform_name);
                    });
                    vm.platformDisplay = _.isEmpty(platforms) ? 'None' : platforms.join(', ');
                    Notification.success("User "+vm.user.username+" was updated");
                }).catch(function(error) {
                    Notification.error("There was a problem updating "+vm.user.username);
                    console.log(error);
                });
            }, function(error) {
                angular.forEach(error.data.data, function(value, key) {
                    var message;
                    if (angular.isArray(value)) {
                        message = value.join(" ");
                    } else {
                        message = value;
                    }
                    vm.errors[key] = {show: true, message: message};
                });
                console.log(vm.errors);
                Notification.error("There was a problem updating "+vm.user.username);
            });
        }

        function setResellerName() {
            if (vm.user.reseller_id === null) {
                vm.user.reseller_name = "Not set";
            } else {
                var reseller_name = _.findWhere(vm.resellers, {id: vm.user.reseller_id});
                vm.user.reseller_name = reseller_name.name;
            }

            if (vm.user.reseller_admin_id === null) {
                vm.user.reseller_admin_name = "Not set";
            } else {
                var reseller_admin_name = _.findWhere(vm.resellers, {id: vm.user.reseller_admin_id});
                vm.user.reseller_admin_name = reseller_admin_name.name;
            }
            vm.loaded.resellers = true;
        }

        function setTeamName() {
            if (vm.user.team_id === null) {
                vm.user.team_name = "Not set";
            } else {
                var team_name = _.findWhere(vm.teams, {id: vm.user.team_id});
                vm.user.team_name = team_name.name;
            }

            if (vm.user.reseller_team_id === null) {
                vm.user.reseller_team_name = "Not set";
            } else {
                var reseller_team_name = _.findWhere(vm.teams, {id: vm.user.reseller_team_id});
                vm.user.reseller_team_name = reseller_team_name.name;
            }
            vm.loaded.teams = true;
        }

        function transferAccounts() {
            userModalService.transferAccounts({accountCounts: vm.accounts, accountLabels: vm.accountLabels});
        }

        function viewChangelog(entry) {
            $uibModal.open({
                templateUrl: 'view/users.modals.changelog',
                controller: ['$uibModalInstance', 'entry', function($uibModalInstance, entry) {
                    var vm = this;
                    vm.entry = entry;
                    vm.close = function() {
                        $uibModalInstance.dismiss();
                    }
                }],
                controllerAs: 'vm',
                size: 'lg',
                keyboard: false,
                backdrop: 'static',
                resolve: {
                    entry: function() {
                        return entry;
                    }
                }
            });
        }

        function showTeamUsers(teamId) {
            teamModalService.showTeamUsers(teamId);
        }
    }
})();
