(function() {
    'use strict';

    angular.module('adminApp.wjs')
        .controller('WjsCapabilitiesController', WjsCapabilitiesController);

    WjsCapabilitiesController.$inject = ['$rootScope', '$stateParams', '$uibModalInstance', 'baseAccountService', 'capabilities', 'defaults'];

    function WjsCapabilitiesController($rootScope, $stateParams, $uibModalInstance, baseAccountService, capabilities, defaults) {
        var vm = this;

        vm.capabilities = capabilities;
        vm.close = close;
        vm.defaults = defaults;
        vm.max = -1;

        $rootScope.$on('updateCapabilities', function(event, args) {
            vm.defaults.capabilities.value = args.capabilities;
            var service = baseAccountService.getService('wjs');
            var success = false;
            baseAccountService.updateDefaults(service.resourceUrl, $stateParams.id, _.values(vm.defaults))
                .then(function(response) {
                    if (response.debug && response.debug.length) {
                        Notification.warning({message: response.message, priority: 2});
                        Notification.error({message: response.debug, priority: 2});
                    } else {
                        console.log(response);
                        success = true;
                    }
                }, function(error) {
                    Notification.error(error.message);
                    console.log(error);
                }).finally(function() {
                    $rootScope.$broadcast('finishedUpdateCapabilities', {success: success});
                });
        });

        function close() {
            $uibModalInstance.dismiss();
        }
    }
})();
