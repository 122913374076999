(function() {
    'use strict';

    angular.module('adminApp.wit')
        .controller('WitSuspendController', WitSuspendController);

    WitSuspendController.$inject = ['$q', '$stateParams', '$uibModalInstance', 'message', 'subscription', 'witAccountService'];

    function WitSuspendController($q, $stateParams, $uibModalInstance, message, subscription, witAccountService) {
        var vm = this;

        vm.close = close;
        vm.message = message;
        vm.saving = false;
        vm.submit = submit;

        var certificateError = false;
        var certificatePromises = [];

        function close() {
            $uibModalInstance.dismiss();
        }

        function runSuspend() {
            var obj = {};
            witAccountService.suspend($stateParams.id, subscription.vault_subscription_id)
                .then(function(response) {
                    obj.success = true;
                }).catch(function(error) {
                    console.log(error);
                    obj.success = false;
                }).finally(function() {
                    obj.certificateSuccess = !certificateError;
                    $uibModalInstance.close(obj);
                });
        }

        function submit() {
            vm.saving = true;
            var certificates = _.flatten(_.pluck(subscription.wit_domain_conf, 'wit_certificate'));
            if (certificates.length !== 0) {
                angular.forEach(certificates, function(val, key) {
                    certificatePromises.push(witAccountService.patchSuspendCertificate(subscription.id, val.id, true)
                        .then(function() {})
                        .catch(function(error) {
                            console.log(error);
                            certificateError = true;
                            $q.reject();
                        }));
                });
                $q.all(certificatePromises).then(function() {
                    runSuspend();
                });
            } else {
                runSuspend();
            }
        }
    }
})();
