(function() {
    'use strict';

    angular.module('adminApp.coreapi')
        .controller('ScopesNewModalController', ScopesNewModalController);

    ScopesNewModalController.$inject = ['$state', '$uibModalInstance', 'coreApiScopeService', 'Notification'];

    function ScopesNewModalController($state, $uibModalInstance, coreApiScopeService, Notification) {
        var vm = this;

        vm.formErrors = {};
        vm.close = close;
        vm.scope = {};
        vm.spinner = false;
        vm.submitButton = "Save Scope";
        vm.submitForm = submitForm;
        vm.title = "Save CoreApi Scope";
        vm.updateDisabled = false;

        function close() {
            $uibModalInstance.dismiss();
            $state.go('scopes.home', {});
        }

        function submitForm() {
            vm.formErrors = {};
            vm.spinner = true;
            coreApiScopeService.submit(vm.scope)
                .then(function(response) {
                    vm.spinner = false;
                    Notification.success(response.message);
                    close();
                }, function(error) {
                    switch (error.status) {
                        case 400:
                            angular.forEach(error.data.data, function(value, key) {
                                vm.formErrors[key] = value[0];
                            });
                            Notification.error("Form errors exist");
                            break;
                        case 403:
                            Notification.error({message: 'You are not permitted to perform this action', replaceMessage: true});
                            break;
                        default:
                            Notification.error("An unknown error has occurred");
                            break;
                    }
                    vm.spinner = false;
                });
        }

    }
})();
