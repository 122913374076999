(function() {
    'use strict';

    angular.module('adminApp.home')
        .factory('devAdminService', devAdminService);

    devAdminService.$inject = ['$resource', 'Notification'];

    function devAdminService($resource, Notification) {
        var resource = new $resource('/api/admin/wurfl/download/generic', {}, {
            downloadGenericTrialWurfl: {
                method: 'POST'
            }
        });
        var service = {
            downloadGenericTrialWurfl: downloadGenericTrialWurfl
        };

        return service;

        function downloadGenericTrialWurfl(format, user) {
            resource.downloadGenericTrialWurfl({}, {format: format, user: user}).$promise
                .then(function(response) {
                    var a = $("<a style='display: none;' target='_blank'/>");
                    a.attr("href", 'files/temp/'+response.data.filename);
                    a.attr("download");
                    $("body").append(a);
                    a[0].click();
                    a.remove();
                }).catch(function(error) {
                    console.log(error);
                });
        }
    }
})();
