(function() {
    'use strict';

    angular.module('adminApp.cut')
        .controller('CutImportController', CutImportController);

    CutImportController.$inject = ['$ngConfirm', '$state', 'centraltestXmlService', 'breadcrumbsService', 'Notification'];

    function CutImportController($ngConfirm, $state, centraltestXmlService, breadcrumbsService, Notification) {
        var vm = this;

        var formData = new FormData();

        vm.clearUpload = clearUpload;
        vm.input = '';
        vm.process = process;
        vm.setTheFiles = setTheFiles;
        vm.steps = breadcrumbsService.get('centraltest.import_xml');
        vm.uploadFiles = uploadFiles;

        function clearUpload() {
            formData = new FormData();
        }

        function process() {
            centraltestXmlService.process(vm.input)
                .then(function(response) {
                    Notification.success('Your test(s) have been created');
                    $state.go('centraltest.admin.tests');
                }).catch(function(error) {
                    console.log(error);
                    Notification.error("Could not process XML data");
                });
        }

        function setTheFiles($files) {
            angular.forEach($files, function (value, key) {
                formData.append('xml', value);
            });
        }

        function uploadFiles() {
            var file_exists = false;
            for (var value of formData.values()) {
                file_exists = true;
            }
            if (!file_exists) {
                $ngConfirm({
                    title: 'Alert!',
                    theme: 'dark',
                    content: '<p>Please choose a file!</p>',
                    buttons: {
                        close: {
                            text: 'OK',
                            btnClass: 'btn-danger'
                        }
                    }
                });
                return;
            }

            centraltestXmlService.upload(formData)
                .then(function(response) {
                    Notification.success('Your test(s) have been created');
                    $state.go('centraltest.admin.tests');
                }).catch(function(error) {
                    console.log(error);
                    Notification.error("Could not upload file");
                });
        }
    }
})();
