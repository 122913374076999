(function() {
    'use strict';

    angular.module('adminApp.licenses')
        .directive('licenseType', licenseType);

    licenseType.$inject = ['$rootScope', 'asOptionsFactory'];

    function licenseType($rootScope, asOptionsFactory) {
        var directive = {
            restrict: 'E',
            transclude: true,
            replace: true,
            template: '<div><input class="form-control" placeholder="loading license types, please wait..." ng-if="license_type_disable" disabled><select ng-if="!license_type_disable" class="form-control" ng-disabled="license_type_disable" id="license_type" name="license_type" ng-model="current" ng-change="updateLicenseType(current)" ng-options="option.id as option.name for option in license_types"></select></div>',
            scope: {
                current: '=model'
            },
            link: link,
            controller: controller
        };

        function link(scope, element, attrs) {
            scope.license_type_disable = true;
        }

        function controller($rootScope, $scope) {
            var columns = "id,license_type_name?orderBy=license_type_name&order=asc";

            asOptionsFactory.asOptions('LicenseTypes', columns).$promise
                .then(function(response) {
                    $scope.license_types = response.data;
                    $scope.license_type_disable = false;
                    $rootScope.$broadcast('loadedLicenseTypeDirective');
                });

            $scope.updateLicenseType = function(value) {
                $scope.license_types = checkAddEmpty($scope.license_types);
                $rootScope.$broadcast('updateLicenseTypeDirective', {id: value});
            };
        }

        function checkAddEmpty(types) {
            var add = true;
            angular.forEach(types, function(val, key) {
                if (val.id === '' && val.name === '') {
                    add = false;
                    return;
                }
            });
            if (add) {
                types.unshift({id: '', name: ''});
            }
            return types;
        }

        return directive;
    }
})();
