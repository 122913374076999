(function() {
    'use strict';

    angular.module('adminApp.reports')
        .directive('reportBlock', reportBlock);

    function reportBlock() {
        var directive = {
            restrict: 'E',
            templateUrl: 'view/components.report',
            scope: {
                link: '@',
                reports: '=',
                state: '@',
                type: '@',
                data: '=',
                disabled: '@'
            },
            controller: 'ReportDirectiveController',
            controllerAs: 'vm',
            bindToController: true
        };

        return directive;
    }
})();
