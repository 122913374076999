(function() {
    'use strict';

    angular.module('adminApp.wit')
        .controller('WitViewController', WitViewController);

    WitViewController.$inject = ['$filter', '$rootScope', '$scope', '$state', '$stateParams', '$timeout', '$uibModal', 'accountService', 'baseAccountService', 'breadcrumbsService', 'IE', 'Notification', 'witAccountService', 'witModalService', 'teamService', 'teamModalService'];
    function WitViewController($filter, $rootScope, $scope, $state, $stateParams, $timeout,  $uibModal, accountService, baseAccountService, breadcrumbsService, IE, Notification, witAccountService, witModalService, teamService, teamModalService) {
        var vm = this;

        vm.activate = activate;
        vm.addPaymentInfo = addPaymentInfo;
        vm.billingInfoAvailable = accountService.getValues('wit', 'billingInfoAvailable');
        vm.cancel = cancel;
        vm.cancelDowngrade = cancelDowngrade;
        vm.cancelReason = accountService.getValues('wit', 'cancelReason');
        vm.checkDowngrade = checkDowngrade;
        vm.createNew = createNew;
        vm.currentTab = 0;
        vm.defaults = accountService.getValues('wit', 'defaults');
        vm.edit = edit;
        vm.editDefaults = editDefaults;
        vm.errorMessage = false;
        vm.externalPath = externalPath;
        vm.getClass = getClass;
        vm.getDefaultValue = getDefaultValue;
        vm.goToAccount = goToAccount;
        vm.loading = false;
        vm.newAccountToView = null;
        vm.other = [];
        vm.otherAccounts = accountService.getValues('wit', 'otherAccounts');
        vm.reactivate = {
            class: null,
            message: false,
            title: null
        };
        vm.restart = restart;
        vm.service = witAccountService;
        vm.stateParams = $stateParams;
        vm.subscription = accountService.getValues('wit', 'subscription');
        vm.suspend = suspend;
        vm.switchPaths = switchPaths;
        vm.trialExpire = {
            days: null,
            date: null
        };
        vm.unsuspend = unsuspend;
        vm.updateBreadcrumbs = updateBreadcrumbs;
        vm.upgrade = upgrade;
        vm.teams = {};
        vm.showTeamUsers = showTeamUsers;

        if ($state.current.name === 'wit.view') {
            if (accountService.getId('wit') === undefined || accountService.getId('wit') !== $stateParams.id) {
                accountService.clear();
                vm.subscription = undefined;
                vm.defaults = undefined;
                vm.otherAccounts = undefined;
                vm.billingInfoAvailable = undefined;
            }
            if (vm.subscription === undefined) {
                getSubscription();
            } else {
                vm.steps = breadcrumbsService.get('wit.view', {
                    username: vm.subscription.user.username,
                    userid: vm.subscription.user_id,
                    name: vm.subscription.account_name
                });
                angular.forEach(vm.otherAccounts, function(val, key) {
                    vm.other.push(val);
                });
            }
        } else {
            $scope.$watch('vm.otherAccounts', function(newVal, oldVal) {
                if (newVal !== undefined) {
                    angular.forEach(vm.otherAccounts, function(val, key) {
                        vm.other.push(val);
                    });
                }
            });
        }

        $scope.$watch('vm.subscription', function(newVal) {
            if (!_.isUndefined(newVal)) {
                vm.witImpersonationUrl = "/impersonate/imageengine/"+ vm.subscription.user.id;
                if (vm.subscription.payment_type == 'TRIAL') {
                    var now = moment().startOf('day');
                    var trialExpire = moment(vm.subscription.trial_expiration_date);
                    vm.trialExpire.days = trialExpire.startOf('day').diff(now, 'd');
                    vm.trialExpire.date = trialExpire.format('MM/DD/YYYY');
                }
            } else {
                vm.trialExpire.days = null;
            }
        }, true);

        var updateWitSubscription = $rootScope.$on('updateWitSubscription', function(event, args) {
            vm.subscription = accountService.getValues('wit', 'subscription');
            vm.otherAccounts = accountService.getValues('wit', 'otherAccounts');
            vm.defaults = accountService.getValues('wit', 'defaults');
            vm.billingInfoAvailable = accountService.getValues('wit', 'billingInfoAvailable');
            vm.cancelReason = args.cancelReason;
            $scope.$on('$destroy', updateWitSubscription);
            vm.steps = breadcrumbsService.get($state.current.name, {
                username: vm.subscription.user.username,
                userid: vm.subscription.user_id,
                name: vm.subscription.account_name,
                id: vm.subscription.id
            });
        });

        var changeWitTab = $rootScope.$on('changeWitTab', function(event, args) {
            vm.currentTab = args.tab;
            vm.billingInfoAvailable = accountService.getValues('wit', 'billingInfoAvailable');
            $scope.$on('$destroy', changeWitTab);
        });

        var reloadWit = $rootScope.$on('reloadWit', function(event, args) {
            accountService.clear();
            vm.subscription = undefined;
            vm.defaults = undefined;
            vm.otherAccounts = undefined;
            vm.billingInfoAvailable = undefined;
            vm.other = [];
            getSubscription();
            if (!_.isUndefined(args.errorMessage)) {
                if (_.isNull(args.errorMessage)) {
                    vm.reactivate.class = "alert-success";
                    vm.reactivate.message = "Successfully reactivated account!";
                    vm.reactivate.title = "Subscription Reactivated";
                } else {
                    vm.reactivate.class = "alert-warning";
                    vm.reactivate.message = args.errorMessage;
                    vm.reactivate.title = "Subscription Reactivated with Errors"
                }
                $timeout(function() {
                    vm.reactivate.message = false;
                }, 20000);
            }
            $scope.$on('$destroy', reloadWit);
        });

        var reloadWitDefaults = $rootScope.$on('reloadWitDefaults', function(event, args) {
            angular.forEach(vm.defaults, function(val, key) {
                if (key !== 'trial_expiration_date') {
                    val.value = args.data[key];
                } else {
                    vm.subscription.trial_expiration_date = val;
                }
            });
        });

        var upgradeWit = $rootScope.$on('upgradeWit', function() {
            vm.subscription = {};
            vm.otherAccounts = {};
            vm.defaults = {};
            vm.billingInfoAvailable = null;
            vm.cancelReason = null;
            vm.other = [];
            getSubscription();
        });

        var disableButtons = $rootScope.$on('disableButtons', function() {
            vm.loading = true;
            $scope.$on('$destroy', disableButtons);
        });

        var enableButtons = $rootScope.$on('enableButtons', function() {
            vm.loading = false;
            $scope.$on('$destroy', enableButtons);
        });

        switch ($state.current.name) {
            case 'wit.view':
                vm.currentTab = 0;
                break;
            case 'wit.view.configuration':
                vm.currentTab = 1;
                break;
            case 'wit.view.usage':
                vm.currentTab = 2;
                break;
            case 'wit.view.billing':
                vm.currentTab = 3;
                break;
            case 'wit.view.domain':
                vm.currentTab = 4;
                break;
            default:

        }

        function getSubscription() {
            vm.loading = true;
            baseAccountService.get(vm.service.resourceUrl, $stateParams.id)
                .then(function(response) {
                    accountService.setId('wit', $stateParams.id);
                    vm.subscription = response.data.account;
                    vm.steps = breadcrumbsService.get('wit.view', {
                        username: vm.subscription.user.username,
                        userid: vm.subscription.user_id,
                        name: vm.subscription.account_name
                    });
                    accountService.setValues('wit', 'subscription', vm.subscription);
                    accountService.setValues('wit', 'wit_domain_conf', vm.subscription.wit_domain_conf);
                    vm.otherAccounts = response.data.other_accounts;
                    angular.forEach(vm.otherAccounts, function(val, key) {
                        vm.other.push(val);
                    });
                    accountService.setValues('wit', 'otherAccounts', vm.otherAccounts);
                    accountService.setValues('wit', 'plans', response.data.plans);
                    vm.defaults = witAccountService.remapLimits(vm.subscription, response.data.plans);
                    accountService.setValues('wit', 'defaults', vm.defaults);
                    vm.billingInfoAvailable = baseAccountService.isFree(vm.subscription) === false && baseAccountService.isStandardBilling(vm.subscription);
                    accountService.setValues('wit', 'billingInfoAvailable', vm.billingInfoAvailable);
                    if (vm.subscription.status == 'CANCELED') {
                        baseAccountService.getCancelLog(witAccountService.resourceUrl, $stateParams.id)
                            .then(function(response) {
                                vm.cancelReason = response.data.reason == '' ? "No reason provided" : response.data.reason;
                                accountService.setValues('wit', 'cancelReason', vm.cancelReason);
                                vm.loading = false;
                            }).catch(function(error) {
                                console.log(error);
                            });
                    }
                    teamService.all().then(function(resellers) {
                        vm.teams[0] = {id: -1, name: '(none)'};
                        var i = 1;
                        angular.forEach(resellers.data, function (value, key) {
                            vm.teams[i] = value;
                            i++;
                        });
                        setTeamName();
                    }).catch(function(teamError) {
                        Notification.error("An unexpected error has occurred");
                        console.log(teamError);
                    });
                    vm.loading = false;
                }).catch(function(error) {
                    console.log(error);
                    Notification.error("The account could not be loaded");
                });
        }

        function activate() {
            witModalService.activate(angular.copy(vm.subscription), externalPath());
        }

        function addPaymentInfo() {
            witModalService.addPaymentInfo(null, angular.copy(vm.subscription), false);
        }

        function cancel() {
            witModalService.cancel(vm.subscription, externalPath())
                .result.then(function(response) {
                    vm.loading = false;
                    if (response.success) {
                        vm.subscription = response.subscription;
                        baseAccountService.getCancelLog(witAccountService.resourceUrl, $stateParams.id)
                            .then(function(response) {
                                vm.cancelReason = response.data.reason == '' ? "No reason provided" : response.data.reason;
                                accountService.setValues('wit', 'cancelReason', vm.cancelReason);
                            }).catch(function(error) {
                                console.log(error);
                            }).finally(function() {
                                vm.loading = false;
                            });
                    } else {
                        if (response.error.account) {
                            Notification.error("The account could not be canceled");
                        } else {
                            Notification.warning("The account was canceled, however errors occurred");
                        }

                        if (response.error.certificate) {
                            Notification.error("Certificate(s) could not be marked for deletion");
                        }

                        if (response.error.dns) {
                            Notification.error("DNS resource record(s) could not be deleted");
                        }
                    }
                });
        }

        function cancelDowngrade() {
            witModalService.cancelDowngrade();
        }

        function checkDowngrade() {
            var downgrade = false;
            if (vm.subscription !== undefined) {
                angular.forEach(vm.subscription.extras, function(val, key) {
                    if (val.type == 'downgrade') {
                        downgrade = true;
                    }
                });
            }
            return downgrade;
        }

        function createNew() {
            witModalService.createNew();
        }

        function edit() {
            witModalService.edit();
        }

        function editDefaults() {
            witModalService.editDefaults();
        }

        function externalPath() {
            if (!_.isUndefined(vm.subscription)) {
                if (vm.subscription.payment_plan !== IE.pro) {
                    return false;
                }
                if (vm.subscription.payment_type == 'EXTERNAL') {
                    return true;
                }
                if (vm.subscription.payment_type == 'STANDARD') {
                    return false;
                }
                var auto = true;
                angular.forEach(vm.subscription.extras, function(val, key) {
                    if (auto) {
                        if (val.type == 'auto_trial') {
                            auto = false;
                        }
                    }
                });
                return auto;
            }
       }

        function goToAccount() {
            $state.go('wit.view', {id: vm.newAccountToView.id});
        }

        function getClass(field) {
            if (vm.defaults !== undefined && !_.isEmpty(vm.defaults)) {
                return vm.defaults[field].value != vm.defaults[field].plan_default.value ? 'label-danger' : 'label-default';
            }
        }

        function getDefaultValue(field, type) {
            type = type === undefined ? true : type;
            var value = null;
            if (vm.defaults !== undefined && !_.isEmpty(vm.defaults)) {
                value = type ? vm.defaults[field].plan_default.value : vm.defaults[field].value;
                return value == null ? false : true;
            }
        }

        function restart() {
            witModalService.restart(vm.subscription).result.then(function(response) {
                $rootScope.$broadcast('reloadWit', {errorMessage: response});
            });
        }

        function suspend() {
            witModalService.suspend(vm.subscription).result.then(function(response) {
                if (response.success) {
                    vm.subscription.status = 'SUSPENDED';
                    vm.subscription.date_last_modified = $filter('date')(new Date(), 'yyyy-MM-dd HH:mm:ss');
                    Notification.success("Account has been suspended");
                    if (!response.certificateSuccess) {
                        Notification.warning("SSL certificates were not marked as suspended");
                    }
                } else {
                    Notification.error("Could not suspend account");
                }
            });
        }

        function switchPaths(path) {
            witModalService.switchPaths(path, angular.copy(vm.subscription));
        }

        function unsuspend() {
            witModalService.unsuspend(vm.subscription).result.then(function(response) {
                if (response.success) {
                    vm.subscription.status = response.data.status;
                    vm.subscription.date_last_modified = $filter('date')(new Date(), 'yyyy-MM-dd HH:mm:ss');
                    Notification.success("Account has been reactivated");
                    if (!response.certificateSuccess) {
                        Notification.warning("Suspended tag was not removed from SSL certificates");
                    }
                } else {
                    Notification.error("Could not unsuspend account");
                }
            });
        }

        function updateBreadcrumbs(tab) {
            if (tab == 'view') {
                var state = 'wit.view';
                vm.currentTab = 0;
            } else {
                var state = 'wit.view.'+tab;
                switch (tab) {
                    case 'configuration':
                        vm.currentTab = 1;
                        break;
                    case 'usage':
                        vm.currentTab = 2;
                        break;
                    case 'billing':
                        vm.currentTab = 3;
                        break;
                    case 'domain':
                        vm.currentTab = 4;
                        break;
                }
            }
            vm.steps = breadcrumbsService.get(state, {
                username: vm.subscription.user.username,
                userid: vm.subscription.user_id,
                name: vm.subscription.account_name,
                id: vm.subscription.id
            });
        }

        function upgrade(action) {
            witModalService.upgrade(action, angular.copy(vm.subscription));
        }

        function setTeamName() {
            if (vm.subscription.user.team_id === null) {
                vm.subscription.user.team_name = "Not set";
            } else {
                var team_name = _.findWhere(vm.teams, {id: vm.subscription.user.team_id});
                vm.subscription.user.team_name = team_name.name;
            }

            if (vm.subscription.user.reseller_team_id === null) {
                vm.subscription.user.reseller_team_name = "Not set";
            } else {
                var reseller_team_name = _.findWhere(vm.teams, {id: vm.subscription.user.reseller_team_id});
                vm.subscription.user.reseller_team_name = reseller_team_name.name;
            }
        }

        function showTeamUsers(teamId) {
            teamModalService.showTeamUsers(teamId);
        }
    }
})();
