(function() {
    'use strict';

    angular.module('authApp')
        .factory('loginService', loginService);

    function loginService() {
        var service = {};

        service.intended = undefined;
        service.authInfo = {
            authenticated: false,
            user: null
        };

        return service;
    }
})();
