(function() {
    'use strict';

    angular.module('adminApp.saas')
        .directive('accountsTable', accountsTable);

    function accountsTable() {
        var directive = {
            restrict: 'E',
            templateUrl: 'view/subscriptions.common.table',
            scope: {
                service: '=',
                tableType: '@'
            },
            controller: "SaasAccountsTableController",
            controllerAs: 'vm',
            bindToController: true
        };

        return directive;
    }
})();
