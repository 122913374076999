(function() {
    'use strict';

    angular.module('adminApp.saas')
        .factory('cloudAccountService', cloudAccountService);

    cloudAccountService.$inject = ['$resource'];

    function cloudAccountService($resource) {

        var service = {
            addApiKey: addApiKey,
            addPayment: addPayment,
            deleteApiKey: deleteApiKey,
            endTrial: endTrial,
            getActiveCapabilities: getActiveCapabilities,
            getSignups: getSignups,
            getSignupCounts: getSignupCounts,
            getTopUsers: getTopUsers,
            getTotalUsageStats: getTotalUsageStats,
            getUsageStats: getUsageStats,
            resourceUrl: "/api/admin/cloud",
            verifyPayment: verifyPayment,
            updateCapabilities: updateCapabilities,
            upgrade: upgrade
        };

        var resource = new $resource(service.resourceUrl + "/:id/:action/:action_id", {id: '@id'}, {
            addApiKey: {
                method: 'POST',
                params: {action: 'key'}
            },
            deleteApiKey: {
                method: 'DELETE',
                params: {action: 'key'}
            },
            getSignups: {
                method: 'GET',
                params: {action: 'signups'}
            },
            getSignupCounts: {
                method: 'GET',
                params: {action: 'counts'}
            },
            getTopUsers: {
                method: 'GET',
                params: {action: 'topusers'}
            },
            getTotalUsageStats: {
                method: 'GET',
                params: {action: 'stats'}
            },
            getUsageStats: {
                method: 'GET',
                params: {action: 'stats'}
            },
            patchAddPayment: {
                method: 'PATCH',
                params: {action: 'payment'}
            },
            patchEndTrial: {
                method: 'PATCH',
                params: {action: 'endtrial'}
            },
            postUpgrade: {
                method: 'PATCH',
                params: {action: 'upgrade'}
            },
            updateCapabilities: {
                method: 'PATCH',
                params: {action: 'capabilities'}
            }
        });

        return service;

        function addApiKey(id, value) {
            return resource.addApiKey({id: id,}, {key: value}).$promise;
        }

        function addPayment(form, id) {
            return resource.patchAddPayment({id: id}, {form: form}).$promise;
        }

        function deleteApiKey(id, key) {
            return resource.deleteApiKey({id: id,action_id: key.id}).$promise;
        }

        function endTrial(form, id) {
            return resource.patchEndTrial({id: id}, {form: form}).$promise;
        }

        function getActiveCapabilities(capabilities) {
            var active = {
                active: 0,
                inactive: 0
            };
            angular.forEach(capabilities, function(value, key) {
                if (value.active == 1) {
                    active.active++;
                } else {
                    active.inactive++;
                }
            });
            return active;
        }

        function getSignups() {
            return resource.getSignups({id: null}).$promise;
        }

        function getSignupCounts() {
            return resource.getSignupCounts({id: null}).$promise;
        }

        function getTopUsers() {
            return resource.getTopUsers({id: null}).$promise;
        }

        function getTotalUsageStats(period) {
            return resource.getTotalUsageStats({id: null, action_id: period}).$promise;
        }

        function getUsageStats(id) {
            return resource.getUsageStats({id: id}).$promise;
        }

        function verifyPayment(origValue, newValue) {
            if (newValue == 'TRIAL') {
                // Never change from one thing to a trial
                return false;
            }

            if (newValue == 'FREE' && origValue != 'TRIAL') {
                // Do not switch to free
                return false;
            }

            if (newValue == 'STANDARD') {
                // Not set up to add credit card right now
                return false;
            }

            return true;
        }

        function updateCapabilities(id, data) {
            return resource.updateCapabilities({id: id}, {capabilities: data}).$promise;
        }

        function upgrade(form, id) {
            return resource.postUpgrade({id: id}, {form: form}).$promise;
        }
    }
})();
