(function() {
    'use strict';

    angular.module('adminApp.cut')
        .controller('CutFullTestController', CutFullTestController);

    CutFullTestController.$inject = ['$rootScope', '$state', '$stateParams', 'breadcrumbsService', 'centraltestAssertionsService', 'centraltestHttpHeaders', 'centraltestHttpRequestsService', 'centraltestTestsService', 'cutModalService', 'Notification'];

    function CutFullTestController($rootScope, $state, $stateParams, breadcrumbsService, centraltestAssertionsService, centraltestHttpHeaders, centraltestHttpRequestsService, centraltestTestsService, cutModalService, Notification) {
        var vm = this;

        vm.addAssertion = addAssertion;
        vm.addHeader = addHeader;
        vm.addRequest = addRequest;
        vm.assertions = [];
        vm.changeRequestMethod = changeRequestMethod;
        vm.editAssertion = editAssertion;
        vm.editHeader = editHeader;
        vm.editTest = editTest;
        vm.headers = [];
        vm.loaded = false;
        vm.otherMethod = null;
        vm.removeAssertion = removeAssertion;
        vm.removeHeader = removeHeader;
        vm.steps = breadcrumbsService.get($state.current.name);
        vm.test = {};
        vm.viewAssertion = viewAssertion;
        vm.viewHeader = viewHeader;

        var requestId = null;
        var suggestedHeaderNames = [];

        $rootScope.$on('reloadAssertions', function(event, args) {
            if (args.type == 'remove') {
                angular.forEach(vm.assertions, function(value, key) {
                    if (value.id == args.id) {
                        vm.assertions.splice(key, 1);
                        return;
                    }
                });
            } else if (args.type == 'create') {
                if (vm.assertions === undefined || vm.assertions == null) {
                    vm.assertions = []
                }
                vm.assertions.push(args.data);
            } else if (args.type == 'edit') {
                var assertions = [];
                angular.forEach(vm.assertions, function(value, key) {
                    if (value.id == args.data.id) {
                        assertions.push(args.data);
                    } else {
                        assertions.push(value);
                    }
                });
                vm.assertions = assertions;
            }
        });

        $rootScope.$on('reloadHeaders', function(event, args) {
            if (args.type == 'remove') {
                angular.forEach(vm.headers, function(value, key) {
                    if (value.id == args.id) {
                        vm.headers.splice(key, 1);
                        return;
                    }
                });
            } else if (args.type == 'create') {
                if (vm.headers === undefined || vm.headers == null){
                    vm.headers = []
                }
                vm.headers.push(args.data);
            } else if (args.type == 'edit') {
                var headers = [];
                angular.forEach(vm.headers, function(value, key) {
                    if (value.id == args.data.id) {
                        headers.push(args.data);
                    } else {
                        headers.push(value);
                    }
                });
                vm.headers = headers;
            }
        });

        $rootScope.$on('reloadRequests', function(event, args) {
            vm.test.requests.method = args.data.method;
            requestId = args.data.id;
            getMethods();
        });

        $rootScope.$on('reloadTests', function(event, args) {
            if (args.type == 'edit') {
                vm.test = args.data;
            }
        });

        centraltestTestsService.get($stateParams.id).then(function(response) {
            vm.test = response.data;
            vm.loaded = true;
            if (vm.test.requests.method !== undefined) {
                getMethods();
                getHeaders();
                centraltestTestsService.getRequestId($stateParams.id).then(function(response) {
                    requestId = response.data.id;
                }).catch(function(error) {
                    console.log(error);
                    Notification.error("Could not find request ID");
                });
            } else {
                vm.test.requests.method = null;
            }
        }).catch(function(error) {
            console.log(error);
        });
        getAssertions();
        if ($stateParams.edit) {
            editTest();
        }

        centraltestHttpHeaders.suggested().then(function(response) {
            suggestedHeaderNames = response.data;
        }).catch(function(error) {
            console.log(error);
        });

        function getAssertions() {
            centraltestTestsService.gatherAssertions($stateParams.id).then(function(response) {
                vm.assertions = response.data;
            }).catch(function(error) {
                console.log(error);
                Notification.error('Could not gather assertions');
            });
        }

        function getHeaders() {
            centraltestTestsService.gatherHeaders($stateParams.id).then(function(response) {
                vm.headers = response.data;
            }).catch(function(error) {
                console.log(error);
                Notification.error("Could not gather headers");
            });
        }

        function getMethods() {
            if (vm.test.requests.method.toLowerCase() == 'get') {
                vm.otherMethod = 'POST'
            } else {
                vm.otherMethod = 'GET'
            }
        }

        function addAssertion() {
            cutModalService.create('assertion', [], 'none', 0, {test_id: parseInt($stateParams.id)}, false, true);
        }

        function addHeader() {
            cutModalService.create('header', [], 'none', 0, {request_id: requestId}, false, true);
        }

        function addRequest() {
            cutModalService.create('request', [], 'none', 0, {test_id: parseInt($stateParams.id)}, false, true);
        }

        function changeRequestMethod() {
            var method = angular.copy(vm.test.requests.method);
            if (method == 'get') {
                method = 'post';
            } else {
                method = 'get';
            }
            centraltestHttpRequestsService.save({
                test_id: $stateParams.id,
                method: method,
                id: requestId
            }).then(function(response) {
                if (response) {
                    vm.test.requests.method = method;
                    getMethods();
                }
            });
        }

        function editAssertion(id, value) {
            cutModalService.edit('assertion', id, false);
        }

        function editHeader(id, value) {
            cutModalService.edit('header', id, false);
        }

        function editTest() {
            cutModalService.edit('test', parseInt($stateParams.id), false);
        }

        function removeAssertion(id) {
            cutModalService.remove('assertion', id);
        }

        function removeHeader(id) {
            cutModalService.remove('header', id);
        }

        function viewAssertion(id) {
            cutModalService.view('assertion', id);
        }

        function viewHeader(id) {
            cutModalService.view('header', id, false);
        }
    }
})();
