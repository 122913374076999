(function() {
    'use strict';

    angular.module('adminApp.licenses')
        .controller('LicenseDirectiveController', LicenseDirectiveController);

    LicenseDirectiveController.$inject = ['$rootScope', '$scope', '$stateParams', '$window', 'licensesModalService', 'licenseService', 'Notification'];

    function LicenseDirectiveController($rootScope, $scope, $stateParams, $window, licensesModalService, licenseService, Notification) {
        var vm = this;

        vm.active = 0;
        vm.createNew = createNew;
        vm.loading = true;
        vm.ngLicense = null;

        var type = $scope.type;

        $rootScope.$on('transferredAccounts', function() {
            vm.loading = true;
            load();
        });

        load();

        function createNew() {
            licensesModalService.create(null, $stateParams.id, type);
        }

        function load() {
            licenseService.getByType(type, $stateParams.id)
                .then(function(response) {
                    var today = new Date();
                    angular.forEach(response.data, function(value, key) {
                        var expireDate = new Date(value.license_expire);
                        if (expireDate < today) {
                            value.expired = true;
                        } else {
                            value.expired = false;
                            if (!value.revoked) {
                                vm.active++;
                            }
                        }
                    });
                    vm.ngLicense = response.data;
                    $rootScope.$broadcast('licenseFinishReload', {type: type});
                }).catch(function(error) {
                    console.log(error);
                    Notification.error("Unable to load licenses");
                }).finally(function() {
                    vm.loading = false;
                });
        }
    }
})();
