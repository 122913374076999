(function() {
    'use strict';

    angular.module('adminApp.users')
        .factory('userService', userService);

    userService.$inject = ['$resource', '$rootScope'];

    function userService($resource, $rootScope) {
        var resource = new $resource('/api/admin/users/:id/:action/:action_id', {id: '@id'}, {
            deleteUser: {
                method: 'DELETE',
                params: {action: null, action_id: null}
            },
            gatherAccounts: {
                method: 'GET',
                params: {action: 'accounts', action_id: null}
            },
            getAccountCount: {
                method: 'GET',
                params: {action: 'accounts', action_id: 'count'}
            },
            getByName: {
                method: 'GET',
                params: {action: 'name'}
            },
            getBySubscription: {
                method: 'GET',
                params: {action: 'subscription', action_id: null}
            },
            getByTeam: {
                method: 'GET',
                params: {action: 'subscription', action_id: null}
            },
            getFilex: {
                method: 'GET',
                params: {action: 'filex', action_id: null}
            },
            getLogins: {
                method: 'GET',
                params: {action: 'activity-log', action_id: 'user-logins'}
            },
            getOptions: {
                method: 'GET',
                params: {action: 'options', action_id: null}
            },
            getPlatforms: {
                method: 'GET',
                params: {action: 'platforms', action_id: null}
            },
            getResellers: {
                method: 'GET',
                params: {action: 'resellers', action_id: null}
            },
            getRoles: {
                method: 'GET',
                params: {action: 'roles'}
            },
            getUserPlatforms: {
                method: 'GET',
                params: {action: 'platforms', action_id: null}
            },
            getWithSubscriptions: {
                method: 'GET',
                params: {action: 'subscriptions', action_id: null}
            },
            getZendeskTiers: {
                method: 'GET',
                params: {action: 'zendesktiers', action_id: null}
            },
            patchConfirmAccount: {
                method: 'PATCH',
                params: {action: 'confirm', action_id: null}
            },
            postUpdateZendeskTierBulk: {
                method: 'POST',
                params: {action: 'zendesktiers', action_id: 'bulk'}
            },
            postUpdateZendeskTier: {
                method: 'POST',
                params: {action: 'zendesktiers', action_id: null}
            },
            revoke: {
                method: 'POST',
                params: {action: 'revoke', action_id: null}
            },
            save: {
                method: 'POST',
                params: {action: null, action_id: null}
            },
            update: {
                method: 'PATCH',
            },
            updatePassword: {
                params: {action: 'password', action_id: null},
                method: 'PATCH'
            },
            updateRoles: {
                params: {action: 'roles', action_id: null},
                method: 'PATCH'
            },
            updateUserPlatforms: {
                method: 'POST',
                params: {action: 'platforms', action_id: null}
            }
        });

        var queryParams = [];

        var service = {
            all: all,
            broadcastDirectiveLoaded: broadcastDirectiveLoaded,
            compareRoles: compareRoles,
            deleteUser: deleteUser,
            gatherAccounts: gatherAccounts,
            get: get,
            getAccountCount: getAccountCount,
            getByName: getByName,
            getBySubscription: getBySubscription,
            getFilex: getFilex,
            getLogins: getLogins,
            getOptions: getOptions,
            getPlatforms: getPlatforms,
            getResellers: getResellers,
            getRoles: getRoles,
            getUserPlatforms: getUserPlatforms,
            getWithSubscriptions: getWithSubscriptions,
            getZendeskTiers: getZendeskTiers,
            patchConfirmAccount: patchConfirmAccount,
            postUpdateZendeskTierBulk: postUpdateZendeskTierBulk,
            postUpdateZendeskTier: postUpdateZendeskTier,
            revoke: revoke,
            save: save,
            update: update,
            updateRoles: updateRoles,
            updatePassword: updatePassword,
            updateUserPlatforms: updateUserPlatforms
        };

        return service;

        function all(options) {
            return resource.get(options).$promise;
        }

        function broadcastDirectiveLoaded(usersLoaded) {
            $rootScope.$broadcast('usersDirectiveLoaded', {users: usersLoaded});
        }

        function compareRoles(roles, allRoles) {
            if (!_.isUndefined(_.find(roles, function(val) {
                return val == 'admin';
            }))) {
                return allRoles;
            }

            if (!_.isUndefined(_.find(roles, function(val) {
                return val == 'salesadmin';
            }))) {
                var ret = {};
                angular.forEach(allRoles, function(val, key) {
                    if (key != 'admin') {
                        ret[key] = val;
                    }
                });
                return ret;
            }

            return {};
        }

        function deleteUser(id) {
            return resource.deleteUser({id: id}).$promise;
        }

        function gatherAccounts(id) {
            return resource.gatherAccounts({id: id}).$promise;
        }

        function get(id) {
            return resource.get({id : id}).$promise;
        }

        function getAccountCount(id) {
            return resource.getAccountCount({id: id}).$promise;
        }

        function getByName(name) {
            return resource.getByName({id: name}).$promise;
        }

        function getBySubscription(id) {
            return resource.getBySubscription({id: id}).$promise;
        }

        function getFilex() {
            return resource.getFilex({id: null}).$promise;
        }

        function getLogins(id) {
            return resource.getLogins({id: id}).$promise;
        }

        function getOptions() {
            return resource.getOptions({id: null}).$promise
        }

        function getPlatforms() {
            return resource.getPlatforms({id: null}).$promise;
        }

        function getResellers() {
            return resource.getResellers({id: null}).$promise;
        }

        function getRoles(id) {
            return resource.getRoles({id: null, action_id: id}).$promise;
        }

        function getUserPlatforms(id) {
            return resource.getUserPlatforms({id: id}).$promise;
        }

        function getWithSubscriptions(id) {
            id = id === undefined ? 'all' : id;
            return resource.getWithSubscriptions({id: id}).$promise;
        }

        function getZendeskTiers() {
            return resource.getZendeskTiers({id: null}).$promise;
        }

        function postUpdateZendeskTier(id, tier_id) {
            return resource.postUpdateZendeskTier({id: id}, {tier_id: tier_id}).$promise;
        }

        function patchConfirmAccount(id) {
            return resource.patchConfirmAccount({id: id}).$promise;
        }

        function postUpdateZendeskTierBulk(tier_id, users) {
            return resource.postUpdateZendeskTierBulk({id: null}, {tier_id: tier_id, users: users}).$promise;
        }

        function revoke(id) {
            return resource.revoke({id: id}).$promise;
        }

        function save(user) {
            return resource.save({id: null}, user).$promise;
        }

        function update(user) {
            return resource.update({id:user.id}, user).$promise;
        }

        function updateRoles(user, roles) {
            return resource.updateRoles({id: user.id}, {roles: roles}).$promise;
        }

        function updatePassword(user, new_password) {
            return resource.updatePassword({id: user.id}, {password: new_password}).$promise;
        }

        function updateUserPlatforms(id, platforms) {
            return resource.updateUserPlatforms({id: null}, {id: id, platforms: platforms}).$promise;
        }
    }
})();
