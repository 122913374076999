(function() {
    'use strict';

    angular.module('adminApp.saas')
        .directive('simpleUserInfo', simpleUserInfo);

    function simpleUserInfo() {
        var directive = {
            restrict: 'AE',
            replace: false,
            templateUrl: '/view/subscriptions.common.simple_user_info',
            scope: {
                user: '='
            },
            link: link
        };

        return directive;

        function link(scope, element, attrs) {
            scope.class = attrs.class;
        }
    }
})();
