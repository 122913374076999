(function() {
    'use strict';

    angular.module('adminApp.cut')
        .factory('centraltestHttpRequestsService', centraltestHttpRequestsService);

    centraltestHttpRequestsService.$inject = ['$resource', 'Notification','CUT_URL'];

    function centraltestHttpRequestsService($resource, Notification, CUT_URL) {
        var resource = new $resource(CUT_URL + '/api/admin/centraltests/request/:id/:action', {id: '@id'}, {
            all: {
                method: 'GET',
                params: {
                    action: 'all',
                    params: {
                        request_id: '@request_id',
                        test_id: '@test_id',
                        method: '@method'
                    }
                }
            },
            create: {
                method: 'POST',
                params: {action: 'create'}
            },
            gatherIds: {
                method: 'GET',
                params: {action: 'ids'}
            },
            get: {
                method: 'GET',
                params: {action: null}
            },
            remove: {
                method: 'DELETE',
                params: {action: null}
            },
            save: {
                method: 'PATCH',
                params: {action: null}
            }
        });
        var service = {
            all: all,
            create: create,
            gatherIds: gatherIds,
            get: get,
            remove: remove,
            save: save,
            validate: validate
        };

        return service;

        function all(params) {
            var obj = {
                id: null
            };
            angular.forEach(params, function(val, key) {
                key = key === 'id' ? 'request_id' : key;
                obj[key] = val;
            });
            return resource.all(obj).$promise;
        }

        function create(form) {
            var errors = validate(form);
            if (errors) {
                return Promise.reject(errors);
            }
            return resource.create({id: null}, form).$promise;
        }

        function gatherIds() {
            return resource.gatherIds({id: null}).$promise;
        }

        function get(id) {
            return resource.get({id: id}).$promise;
        }

        function remove(id) {
            return resource.remove({id: id}).$promise;
        }

        function save(form) {
            var errors = validate(form);
            if (errors) {
                return Promise.reject({status: false, errors: errors});
            }
            return resource.save({id: form.id}, form).$promise;
        }

        function validate(form) {
            var errors = {
                test_id: {
                    show: false
                },
                method: {
                    show: false
                }
            };
            if (form.test_id == null || form.method == null) {
                if (form.test_id == null) {
                    errors.test_id.show = true;
                    errors.test_id.message = 'Test ID is required';
                }
                if (form.method == null) {
                    errors.method.show = true
                    errors.method.message = 'Method is required';
                }
                return errors;
            }

            return false;
        }
    }
})();
