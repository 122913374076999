(function() {
    'use strict';

    angular.module('authApp')
        .factory('httpRequestInterceptor', httpRequestInterceptor);

    httpRequestInterceptor.$inject = ['$q', '$rootScope', '$injector', 'endSession', 'pendingRequestsService', 'loginService'];

    function httpRequestInterceptor($q, $rootScope, $injector, endSession, pendingRequestsService, loginService) {
        var $refreshTokenPromise;

        var httpService = {
            request: request,
            responseError: responseError
        };
        return httpService;

        function request(config) {
            var accessToken = localStorage.getItem('access_token');
            if (accessToken && accessToken !== 'null') {
                config.headers.Authorization = 'Bearer '+accessToken;
            }
            if (config.method === 'GET') {
                // Add routes not triggered by $http
                if (config.timeout === undefined && config.url !== 'api/auth/logout') {
                    var canceller = $q.defer();
                    pendingRequestsService.add({
                        url: config.url,
                        canceller: canceller
                    });
                    config.timeout = canceller.promise;
                }
            }

            return config;
        }

        function responseError(response) {
            // the request to get a new token failed - end the session
            if (response.config.url === 'api/auth/token') {
                endSession.end();
                $injector.get('$state').transitionTo('login');
                pendingRequestsService.cancelAll();
                return $q.reject(response);
            }

            if (response.status === 401) {
                // a login attempt failed - reject like normal
                if (response.config.url === 'api/auth/login' || response.config.isRetry) {
                    return $q.reject(response);
                }

                // the token likely expired
                // if we aren't already attempting to refresh the token, we should
                makeRefreshTokenPromise();

                response.config.isRetry = true;
                return retryRequestFromResponse($refreshTokenPromise, response);
            }

            if (response.status === 403) {
                if ($injector.get('$state').current.name !== 'home') {
                    $rootScope.$broadcast('stateChangeOccurred', {name: 'home'});
                    $injector.get('$state').transitionTo('home');
                }
            }
            return $q.reject(response);
        }

        function makeRefreshTokenPromise() {
            if ($refreshTokenPromise) {
                return;
            }
            
            var authenticate = $injector.get('authenticate');
            $refreshTokenPromise = authenticate.refreshToken();
            $q.when($refreshTokenPromise).finally(function() {
                $refreshTokenPromise = null;
            });
        }

        function retryRequestFromResponse($promise, response) {
            return $promise.then(function() {
                return $injector.get('$http')(response.config);
            }).catch(function() {
                return $q.reject(response);
            });
        }
    }
})();
