(function () {
    'use strict';

    angular.module('adminApp.saas')
        .factory('quwurflAccountService', quwurflAccountService);

    quwurflAccountService.$inject = ['$resource', 'WURFL_ADMIN_MICROSERVICE_URL'];

    function quwurflAccountService($resource, WURFL_ADMIN_MICROSERVICE_URL) {
        var service = {
            resourceUrl: WURFL_ADMIN_MICROSERVICE_URL + '/v2',
            all: all,
            create: create,
            cancel: cancel,
            reactivate: reactivate,
            download: download,
            get: get,
            update: update,
            addDomain: addDomain,
            deleteDomain: deleteDomain,
            addKey: addKey,
            deleteKey: deleteKey,
            addCapability: addCapability,
            deleteCapability: deleteCapability,
            userSubscriptions: userSubscriptions,
        };



        var resource = new $resource(service.resourceUrl + "/:product/:entity/:id/:action/:action_id", { id: '@id' }, {
            getAsArray: {
                method: 'GET',
                params: {
                    product: 'quwurfl',
                    entity: 'subscriptions',
                },
                isArray: true,
            },
            get: {
                method: 'GET',
                params: {
                    product: 'quwurfl',
                    entity: 'subscriptions',
                },
            },
            create: {
                method: 'POST',
                params: {
                    product: 'quwurfl',
                    entity: 'subscriptions',
                },
            },
            cancel: {
                method: 'POST',
                params: {
                    product: null,
                    entity: 'subscriptions',
                    action: 'cancel',
                },
            },
            update: {
                method: 'PATCH',
                params: {
                    product: 'quwurfl',
                    entity: 'subscriptions',
                },
            },
            addDomain: {
                method: 'POST',
                params: {
                    product: 'quwurfl',
                    entity: 'subscriptions',
                    action: 'domains',
                    action_id: null
                },
                isArray: true
            },
            deleteDomain: {
                method: 'DELETE',
                params: {
                    product: 'quwurfl',
                    entity: 'subscriptions',
                    action: 'domains',
                    action_id: null
                }
            },
            addKey: {
                method: 'POST',
                params: {
                    product: null,
                    entity: 'subscriptions',
                    action: 'apikeys',
                    action_id: null
                }
            },
            deleteKey: {
                method: 'DELETE',
                params: {
                    product: null,
                    entity: 'subscriptions',
                    action: 'apikeys',
                    action_id: null
                }
            },
            addCapability: {
                method: 'POST',
                params: {
                    product: 'quwurfl',
                    entity: 'subscriptions',
                    action: 'capabilities',
                    action_id: null
                },
                isArray: true
            },
            deleteCapability: {
                method: 'DELETE',
                params: {
                    product: 'quwurfl',
                    entity: 'subscriptions',
                    action: 'capabilities',
                    action_id: null
                }
            },
        });

        return service;

        function all(options) {
            var params = { id: null };
            if (options) {
                params = _.extend(params, options);
            }
            return resource.getAsArray(params).$promise;
        }

        function cancel(subscriptionId, payload) {
            return resource.cancel({ id: subscriptionId }, payload).$promise;
        }

        function download(options) {
            alert("Not implemented: WQ-98");
            return;
        }

        function create(data) {
            return resource.create(data).$promise;
        }

        function get(id, options) {
            var params = {};
            if (id) {
                params.id = id;
            }

            if (options) {
                params = _.extend(params, options);
            }
            return resource.get(params).$promise;
        }

        function update(subscriptionId, payload) {
            return resource.update({ id: subscriptionId }, payload).$promise;
        }

        function reactivate(subscriptionId) {
            alert("Not implemented");
            return;
        }

        function addDomain(subscriptionId, domain) {
            var payload = [domain];
            return resource.addDomain({ id: subscriptionId }, payload).$promise;
        }

        function deleteDomain(subscriptionId, domainId) {
            // Note: angular allows to post payload for DELETE requests since v1.6.3
            // current version is 1.5.8 so we need to pass as query parameter
            return resource.deleteDomain({ id: subscriptionId, 'did': domainId }).$promise;
        }

        function addKey(subscriptionId, keyName) {
            var payload = {
                'key_name': keyName
            }
            return resource.addKey({ id: subscriptionId }, payload).$promise;
        }

        function deleteKey(subscriptionId, keyId) {
            return resource.deleteKey({ id: subscriptionId, 'action_id': keyId }).$promise;
        }

        function addCapability(subscriptionId, capabilityId) {
            var payload = [capabilityId];
            return resource.addCapability({ id: subscriptionId }, payload).$promise;
        }

        function deleteCapability(subscriptionId, capabilityId) {
            // Note: angular allows to post payload for DELETE requests since v1.6.3
            // current version is 1.5.8 so we need to pass as query parameter
            return resource.deleteCapability({ id: subscriptionId, 'cid': capabilityId }).$promise;
        }

        // userSubscriptions returns the subscriptions for a specified user
        function userSubscriptions(userId) {
            var data = { "active": 0, "total": 0, subscriptions: [] };
            resource.getAsArray().$promise.then(function (response) {
                angular.forEach(response, function (val, key) {
                    if (val.subscription.user_id != userId) {
                        return;
                    }
                    if (val.subscription.status == "ACTIVE") {
                        data.active++;
                    }
                    data.total++;
                    data.subscriptions.push(val.subscription);
                });
            }).catch(function (error) {
                Notification.error("Unable to fetch QuWURFL subscriptions");
                console.log(error);
            });
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve(data);
                }, 1000);
            });
        }
    }
})();
