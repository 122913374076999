(function() {
    'use strict';

    angular.module('adminApp.cut')
        .controller('CutManageRequestsController', CutManageRequestsController);

    CutManageRequestsController.$inject = ['$location', '$rootScope', '$scope', '$state', '$stateParams', '$timeout', 'breadcrumbsService', 'centraltestHttpRequestsService', 'centraltestTestsService', 'cutModalService', 'paginationService'];

    function CutManageRequestsController($location, $rootScope, $scope, $state, $stateParams, $timeout, breadcrumbsService, centraltestHttpRequestsService, centraltestTestsService, cutModalService, paginationService) {
        var vm = this;

        vm.columns = [
            {
                name: 'ID',
                search: {
                    isSearchable: true,
                    type: 'text'
                },
                model: 'id',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: 'Test ID',
                search: {
                    isSearchable: true,
                    type: 'text'
                },
                model: 'test_id',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: 'Method',
                search: {
                    isSearchable: true,
                    type: 'select',
                    id: 'method'
                },
                model: 'method',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: '',
                search: {
                    isSearchable: false
                },
                model: '',
                value: {
                    fromData: false,
                },
                display: true
            }
        ];
        vm.createNew = createNew;
        vm.enableCreate = false;
        vm.search = {};
        vm.steps = breadcrumbsService.get('centraltest.admin.requests');

        var currentPage = $stateParams.page;
        var search_params = _.clone($location.search());
        var numPages = 1;
        var ids = [];

        $timeout(function() {
            $rootScope.$broadcast("apirequestsUpdateSelect", {
                id: 'method',
                data: {'get': 'get', 'post': 'post'},
                keyNeeded: true,
                value: 'key',
                show: 'value'
            });
        }, 1000);

        getRequests();
        centraltestTestsService.gatherIds().then(function(response) {
            ids = response.data.ids;
            vm.enableCreate = true;
        });

        $rootScope.$on('reloadRequests', function(event, args) {
            if (args.page === undefined) {
                getRequests($stateParams);
            } else {
                getRequests(args);
            }
        });

        var apirequestsSearchParamsUpdated = $rootScope.$on('apirequestsSearchParamsUpdated', function(event, args) {
            vm.search = args;
            currentPage = 1;
            getRequests(args);
        });

        var apirequestsPageChanged = $rootScope.$on("apirequestsPageChanged", function(event, args) {
            vm.search = args;
            currentPage = args.page;
            getRequests(args);
        });

        var apirequestsItemsPerPageChanged = $rootScope.$on("apirequestsItemsPerPageChanged", function(event, args) {
            vm.search = args;
            currentPage = 1;
            getRequests(args);
        });

        function createNew() {
            cutModalService.create('request', ids, 'test', numPages);
        }

        function getRequests(searchParams) {
            searchParams = typeof searchParams !== 'undefined' ? searchParams : null;
            var start = 0;
            var number = 10;

            var query_params = {};
            var next_page = 1;

            if (searchParams !== null) {
                next_page = searchParams.page;
                number = searchParams.paginate;
            } else if (search_params && _.isUndefined(search_params.page) === false) { // if defined
                next_page = search_params.page;
            } else {
                next_page = Math.floor(start/number) + 1;
            }

            query_params = {page: next_page};
            if (searchParams !== null) {
                angular.forEach(searchParams, function(value, key) {
                    if (value !== '' && value !== null) {
                        query_params[key] = value;
                    }
                });
            }

            centraltestHttpRequestsService.all(query_params).then(function(response) {
                if (response) {
                    var paginationData = {};
                    var numPages = response.pagination.last_page;

                    paginationData.pages = paginationService.createPages(currentPage, numPages);
                    paginationData.numPages = numPages;
                    paginationData.currentPage = currentPage;
                    paginationData.currentState = $state.current.name;
                    paginationData.stateParams = $stateParams;
                    paginationData.from = paginationService.from(response.pagination);
                    paginationData.to = paginationService.to(response.pagination);
                    paginationData.total = response.pagination.total;
                    paginationData.colspan = 2;

                    $rootScope.$broadcast('apirequestsDataLoaded', {
                        data: response.data,
                        paginationData: paginationData,
                        itemsPerPage: number
                    });
                }
                $scope.$on('$destroy', apirequestsSearchParamsUpdated);
                $scope.$on('$destroy', apirequestsPageChanged);
                $scope.$on('$destroy', apirequestsItemsPerPageChanged);
            });
        }
    }
})();
