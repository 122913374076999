(function() {
    'use strict';

    angular.module('adminApp.wit')
        .controller('WitCacheController', WitCacheController);

    WitCacheController.$inject = ['$rootScope', '$state', '$stateParams', 'accountService', 'baseAccountService', 'domainConfValidator', 'Notification', 'witAccountService', 'witModalService'];

    function WitCacheController($rootScope, $state, $stateParams, accountService, baseAccountService, domainConfValidator, Notification, witAccountService, witModalService) {
        var vm = this;

        vm.addNewPurgeRequest = addNewPurgeRequest;
        vm.banning = false;
        vm.domains = false;
        vm.errors = {
            expression: false,
            domain: false
        };
        vm.loading = false;
        vm.newPurgeRequest = {
            expression: null,
            domain: null
        };
        vm.origins = null;
        vm.purges = null;
        vm.redo = false;
        vm.viewPurgeInfo = viewPurgeInfo;

        var subscription = accountService.getValues('wit', 'subscription');

        if (_.isUndefined(subscription)) {
            vm.loading = true;
            baseAccountService.get(witAccountService.resourceUrl, $stateParams.id)
                .then(function(response) {
                    accountService.setId('wit', $stateParams.id);
                    subscription = response.data.account;

                    accountService.setValues('wit', 'subscription', subscription);
                    accountService.setValues('wit', 'wit_domain_conf', subscription.wit_domain_conf);
                    accountService.setValues('wit', 'otherAccounts', response.data.other_accounts);
                    accountService.setValues('wit', 'plans', response.data.plans);
                    accountService.setValues('wit', 'defaults', witAccountService.remapLimits(subscription, response.data.plans));
                    accountService.setValues('wit', 'billingInfoAvailable',
                        baseAccountService.isFree(subscription) === false && baseAccountService.isStandardBilling(subscription));
                    if (subscription == 'CANCELED') {
                        var cancelReason = null;
                        baseAccountService.getCancelLog(witAccountService.resourceUrl, $stateParams.id)
                            .then(function(response) {
                                cancelReason = response.data.reason == '' ? "No reason provided" : response.data.reason;
                                accountService.setValues('wit', 'cancelReason', cancelReason);
                                $rootScope.$broadcast('updateWitSubscription', {cancelReason: cancelReason});
                            }).catch(function(error) {
                                console.log(error);
                            }).finally(function() {
                                vm.loading = false;
                            });
                    } else {
                        $rootScope.$broadcast('updateWitSubscription', {cancelReason: null});
                    }
                    if (
                        subscription.payment_plan === 'WIT_FREE' ||
                        (subscription.payment_plan === 'IMAGEENGINE_TRIAL' && subscription.payment_type === 'FREE')
                    ) {
                        var message = 'The Account is free. This functionality is not available';
                        $rootScope.$broadcast('changeWitTab', {tab: 0});
                        $state.go('wit.view', {id: $stateParams.id});

                        Notification.warning(message);
                        return;
                    }
                    run();
                }, function(error) {
                    console.log(error);
                    Notification.error("The account could not be loaded");
                });

        } else {
            if (
                subscription.payment_plan === 'WIT_FREE' ||
                (subscription.payment_plan === 'IMAGEENGINE_TRIAL' && subscription.payment_type === 'FREE')
            ) {
                var message = 'The Account is free. No billing information is available';
                $rootScope.$broadcast('changeWitTab', {tab: 0});
                $state.go('wit.view', {id:subscription.id});

                Notification.warning(message);
                return;
            }
            run();
        }

        function addNewPurgeRequest(request, index) {
            var refId = _.isUndefined(index) ? 'ie-cache-ban' : 'ie-cache-purge-'+index;

            vm.errors = {
                expression: false,
                domain: false
            };

            if (_.isNull(request.expression)) {
                vm.errors.expression = true;
            }
            if (_.isNull(request.domain) || request.domain == '') {
                vm.errors.domain = true;
            }

            if (vm.errors.expression || vm.errors.domain) {
                return;
            }

            if (_.has(request, 'timestamp')) {
                vm.redo = true;
            } else {
                vm.banning = true;
            }

            var submitRequest = angular.copy(request);
            if (_.isArray(request.domain)) {
                submitRequest.domain = submitRequest.domain[0].id;
            }

            witAccountService.postAddPurgeRequest($stateParams.id, submitRequest).then(function(response) {
                Notification.success("Added purge request");
                run();
                vm.newPurgeRequest = {
                    expression: null,
                    domain: null
                };
            }).catch(function(error) {
                console.log(error);
                switch (error.status) {
                    case 500:
                        Notification.error(error.data.message);
                        break;
                    default:
                        Notification.error("There is a problem with your account configuration. Please contact us at support@scientiamobile.com.");
                }
            }).finally(function() {
                vm.redo = false;
                vm.banning = false;
            });
        }

        function run() {
            witAccountService.getCache($stateParams.id).then(function(response) {
                vm.origins = response.data.origins;
                vm.purges = response.data.purges;
                vm.domains = response.data.domains;
            }).catch(function(error) {
                console.log(error);
                Notification.error("Unable to gather cache purging");
            }).finally(function() {
                vm.loading = false;
            });
        }

        function viewPurgeInfo(json) {
            witModalService.viewPurgeInfo(json);
        }
    }
})();
