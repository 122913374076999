(function() {
    'use strict';

    angular.module('adminApp.home')
        .factory('menuService', menuService);

    menuService.$inject = [];
    function menuService() {
        var menu = {
            admin: false,
            salesadmin: false
        };
        var service = {
            getMenu: getMenu,
            reset: reset,
            setMenu: setMenu
        };

        return service;

        function getMenu(key) {
            return menu[key];
        }

        function reset() {
            menu = {
                admin: false,
                salesadmin: false
            };
        }

        function setMenu(key, value) {
            menu[key] = value;
        }
    }
})();
