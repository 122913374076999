(function() {
    'use strict';

    angular.module('adminApp.cloud')
        .controller('CloudConfigurationController', CloudConfigurationController);

    CloudConfigurationController.$inject = ['$rootScope', '$stateParams', '$uibModal', 'accountService', 'baseAccountService', 'cloudAccountService', 'Notification'];

    function CloudConfigurationController($rootScope, $stateParams, $uibModal, accountService, baseAccountService, cloudAccountService, Notification) {
        var vm = this;

        vm.addApiKey = addApiKey;
        vm.defaults = accountService.getValues('cloud', 'defaults');
        vm.deleteKey = deleteKey;
        vm.keyValue = null;
        vm.loadCapabilities = false;
        vm.loading = true;
        vm.subscription = accountService.getValues('cloud', 'subscription');

        if (accountService.getId('cloud') === undefined || accountService.getId('cloud') !== $stateParams.id) {
            accountService.clear();
            vm.subscription = undefined;
            vm.defaults = undefined;
        }

        if (vm.defaults === undefined || vm.subscription === undefined) {
            baseAccountService.get(cloudAccountService.resourceUrl, $stateParams.id)
                .then(function(response) {
                    accountService.setId('cloud', $stateParams.id);
                    vm.subscription = response.data.account;
                    accountService.setValues('cloud', 'otherAccounts', response.data.other_accounts);
                    accountService.setValues('cloud', 'plans', vm.plans);
                    vm.defaults = baseAccountService.remapLimits(vm.subscription.limits, 'wurfl_cloud_default_limit');
                    accountService.setValues('cloud', 'defaults', vm.defaults);
                    accountService.setValues('cloud', 'billingInfoAvailable',
                        baseAccountService.isFree(vm.subscription) === false && baseAccountService.isStandardBilling(vm.subscription));
                    vm.subscription.cloud_capabilities_records = _.pluck(vm.subscription.cloud_capabilities, 'capability');
                    accountService.setValues('cloud', 'subscription', vm.subscription);
                    $rootScope.$broadcast('updateCloudSubscription');
                    vm.loadCapabilities = true;
                    vm.loading = false;
                    console.log(vm.subscription.cloud_capabilities_records);
                }, function(error) {
                    console.log(error);
                    Notification.error("The account could not be loaded");
                });
        } else {
            vm.loadCapabilities = true;
            vm.loading = false;
        }

        function addApiKey() {
            cloudAccountService.addApiKey(vm.subscription.id, vm.keyValue)
                .then(function(response) {
                    vm.subscription.keys.push(response.data);
                    vm.keyValue = null;
                    Notification.success(response.message);
                }, function(error) {
                    Notification.error(response.message);
                    console.log(error);
                });
        }

        function deleteKey(key) {
            $uibModal.open({
                templateUrl: 'view/components.confirm',
                controller: controller,
                resolve: {
                    message: function() {
                        return "The API Key will be removed immediately. Confirm?";
                    }
                },
                size: 'lg',
                backdrop: 'static',
                keyboard: false
            }).result.then(function(result) {
                cloudAccountService.deleteApiKey(vm.subscription.id, key)
                    .then(function(response) {
                        Notification.success({message: response.message});
                        vm.subscription.keys = _.without(vm.subscription.keys, _.findWhere(vm.subscription.keys, {id:key.id}));
                    }, function(error) {
                        console.log(error);
                        Notification.error(response.message);
                    });
            }, function(result) {
                return false;
            });

            function controller($scope, $uibModalInstance, message) {
                $scope.message = message;
                $scope.confirm = function() {
                    $uibModalInstance.close();
                };
            }
        }
    }
})();
