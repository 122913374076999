(function() {
    'use strict';

    angular.module('adminApp')
        .factory('countriesService', countriesService);

    countriesService.$inject = ['$resource'];

    function countriesService($resource) {
        var resourceUrl = "";
        var actions = {
            asOptions: {
                method: 'GET',
                url: "/api/admin/ng-options/Countries/columns/country_id,name?orderBy=display_order&order=asc&limit=all",
                cache: true
            }
        };

        var Api = $resource(resourceUrl, {}, actions);

        var service = {
            asOptions: asOptions
        };

        return service;

        function asOptions() {
            return Api.asOptions();
        }
    }
})();
