(function() {
    'use strict';

    angular.module('adminApp.licenses')
        .controller('NewLicenseController', NewLicenseController);

    NewLicenseController.$inject = ['$filter', '$rootScope', '$scope', '$state', '$stateParams', '$uibModalInstance', '$window', 'licenseService', 'Notification', 'productId', 'productType', 'userId'];

    function NewLicenseController($filter, $rootScope, $scope, $state, $stateParams, $uibModalInstance, $window, licenseService, Notification, productId, productType, userId) {
        var vm = this;

        vm.allLoaded = {
            user: false,
            product: false,
            productLevel: false,
            licenseType: false,
            licenseCategory: false,
            country: false
        };
        vm.close = close;
        vm.dateOpened = false;
        vm.expireOpened = false;
        vm.formData = {
            country: {
                id: 230,
                name: "United States of America",
                country_id: 230
            },
            license_type_id: 1,
            license_cat_id: 2,
            max_capabilities: 5,
            product_level_id: 1
        };
        vm.formErrors = [];
        vm.loading = true;
        vm.modalOptions = {
            actionClass: 'alert-info',
            actionMessage: 'Saving...',
            proceedButton: {
                btnClass: 'btn-primary',
                btnSpanClass: 'glyphicon-floppy-save',
                btnText: 'Save'
            },
            template: 'view/licenses.modals.new_license_form',
            title: 'Create New License'
        };
        vm.openCalendar = openCalendar;
        vm.openExpireCalendar = openExpireCalendar;
        vm.proceed = proceed;
        vm.productType = productType;
        vm.setExpiration = setExpiration;
        vm.showActionSpinner = false;
        if (!_.isNull(productType)) {
            var type = undefined;
            switch (productType) {
                case 'infuze':
                    type = 'InFuze';
                    break;
                case 'insight':
                    type = 'InSight';
                    break;
                case 'onsite':
                    type = 'OnSite';
                    break;
                case 'data':
                    type = 'Data';
                    break;
                default:
                    type = '';
                    break;
            }
            vm.modalOptions.title = "Create New "+type+" License";
        }

        $rootScope.$on('usersDirectiveLoaded', function(event, args) {
            if (!_.isNull(userId)) {
                vm.formData.user = _.find(args.users, function(obj) {
                    return obj.id == userId;
                });
            }

            vm.allLoaded.user = true;
        });
        $rootScope.$on('loadedProductDirective', function(event, args) {
            $rootScope.$broadcast('updateProductByType', {type: productType});
            vm.allLoaded.product = true;
        });
        $rootScope.$on('loadedProductLevelDirective', function(event, args) {
            vm.allLoaded.productLevel = true;
        });
        $rootScope.$on('loadedLicenseTypeDirective', function(event, args) {
            vm.allLoaded.licenseType = true;
        });
        $rootScope.$on('loadedLicenseCategoryDirective', function(event, args) {
            vm.allLoaded.licenseCategory = true;
        });
        $rootScope.$on('loadedCountryDirective', function(event, args) {
            vm.allLoaded.country = true;
        });

        $rootScope.$on('updateProductDirective', function(event, args) {
            vm.formData.product_id = args.id;
        });

        $rootScope.$on('updateProductLevelDirective', function(event, args) {
            vm.formData.product_level_id = args.id;
        });

        $rootScope.$on('updateLicenseTypeDirective', function(event, args) {
            vm.formData.license_type_id = args.id;
        });

        $rootScope.$on('updateLicenseCategoryDirective', function(event, args) {
            vm.formData.license_cat_id = args.id;
        });

        $rootScope.$on('updateCountryDirective', function(event, args) {
            vm.formData.country = args.model;
        });

        var today = new Date();
        vm.formData.license_date = angular.copy(today);
        var license_expire = angular.copy(today);
        vm.formData.license_expire = license_expire.setFullYear(license_expire.getFullYear() + 1);

        if (productId !== null) {
            vm.formData.product_id = productId;
        }

        $scope.$watch('vm.allLoaded', function(newVal) {
            var loaded = true;
            angular.forEach(newVal, function(val, key) {
                if (!val) {
                    loaded = false;
                }
            });
            vm.loading = loaded ? false : true;
        }, true);

        function close() {
            $uibModalInstance.dismiss();
        }

        function openCalendar() {
            vm.dateOpened = true;
        }

        function openExpireCalendar() {
            vm.expireOpened = true;
        }

        function proceed() {
            vm.showActionSpinner = true;
            vm.formErrors = [];
            licenseService.save(vm.formData)
                .then(function(response) {
                    vm.formErrors = [];
                    Notification.success("License Saved.");
                    vm.showActionSpinner = false;
                    $uibModalInstance.dismiss();
                    if (vm.formData.product_id !== 12) {
                        $state.go('licenses.view.capabilities', {id: response.data.id});
                    } else {
                        var licenseId = response.data.id;
                        licenseService.createInsightProfile(response.data.id).then(function(response) {
                            $state.go('licenses.insight.index', {userId: vm.formData.user.id, id: licenseId});
                        }).catch(function(error) {
                            Notification.error("Could not create InSight profile");
                            console.log(error);
                        });
                    }
                }, function(error) {
                    console.log(error);
                    switch (error.status) {
                        case 500:
                            Notification.error("An unexpected error has occurred");
                            break;
                        case 400:
                            angular.forEach(error.data.data, function(value, key) {
                                vm.formErrors[key] = value[0];
                            });
                            if (error.data.data.confirm) {
                                Notification.error(error.data.message);
                            } else {
                                Notification.error("Form errors exist");
                            }
                            break;
                    }
                    vm.showActionSpinner = false;
                });
        }

        function setExpiration(val) {
            var today = new Date();
            var license_expire = null;
            switch (val) {
                case '1 month':
                    license_expire = today.setMonth(today.getMonth() + 1);
                    break;
                case '3 months':
                    license_expire = today.setMonth(today.getMonth() + 3);
                    break;
                case '6 months':
                    license_expire = today.setMonth(today.getMonth() + 6);
                    break;
                default:
                    license_expire = today.setFullYear(today.getFullYear() + 1);
                    break;
            }
            vm.formData.license_expire = license_expire;
        }
    }
})();
