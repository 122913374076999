(function() {
    'use strict';

    angular.module('adminApp.saas')
        .directive('accountDefaults', accountDefaults);

    function accountDefaults() {
        var directive = {
            restrict: 'AE',
            replace: false,
            templateUrl: '/view/subscriptions.common.account_defaults',
            scope: {
                defaults: '=defaults',
                plan_default_key: '@planDefaultKey'
            },
            link: link
        };

        return directive;

        function link(scope, element, attrs) {
            scope.class = attrs.class;
        }
    }
})();
