(function() {
    'use strict';

    angular.module('adminApp.cut')
        .factory('centraltestXmlService', centraltestXmlService);

    centraltestXmlService.$inject = ['$resource', '$state', '$window', 'Notification', 'CUT_URL'];

    function centraltestXmlService($resource, $state, $window, Notification, CUT_URL) {
        var resource = new $resource(CUT_URL + '/api/admin/centraltests/xml/:action/:action_id', {action_id: '@action_id'}, {
            exportSingle: {
                method: 'POST',
                params: {
                    action: 'export',
                    action_id: 'single'
                }
            },
            exportXml: {
                method: 'POST',
                params: {action: 'export'}
            },
            process: {
                method: 'POST',
                params: {action: 'process'}
            },
            upload: {
                method: 'POST',
                params: {action: 'upload'},
                headers: {'Content-Type': undefined}
            }
        });

        var service = {
            exportSingle: exportSingle,
            export: exportXml,
            process: processXml,
            upload: uploadXml
        };

        return service;

        function getDate() {
            var d = new Date();
            var year = d.getFullYear(),
                month = d.getMonth() + 1 < 10 ? '0'+(d.getMonth() + 1) : d.getMonth() + 1,
                day = d.getDate() < 10 ? '0'+d.getDate() : d.getDate(),
                h = d.getHours() < 10 ? '0'+d.getHours() : d.getHours(),
                m = d.getMinutes() < 10 ? '0'+d.getMinutes() : d.getMinutes(),
                s = d.getSeconds() < 10 ? '0'+d.getSeconds() : d.getSeconds(),
                date = year+'-'+month+'-'+day+'_'+h+':'+m+':'+s;
            return date;
        }

        function exportSingle(name) {
            resource.exportSingle({}, {name: name}).$promise
                .then(function(response) {
                    var date = getDate();
                    angular.forEach(response.data, function(value, key) {
                        var filename = 'centraltest_tests_'+name+'_'+date+'.xml';
                        var blob = new Blob([value], {type: 'text/xml'}),
                            url = $window.URL || $window.webkitURL;

                        var a = $("<a style='display: none;'/>");
                        var url = url.createObjectURL(blob);
                        a.attr("href", url);
                        a.attr("download", filename);
                        $("body").append(a);
                        a[0].click();
                        window.URL.revokeObjectURL(url);
                        a.remove();
                    });
                    Notification.success('Test exported successfully');
                }).catch(function(error) {
                    console.log(error);
                    Notification.error('Unable to export');
                }).finally(function() {
                    $state.go('centraltest.default');
                });
        }

        function exportXml(all, type_unit, type_regression) {
            resource.exportXml({}, {
                all: all,
                type_unit: type_unit,
                type_regression: type_regression
            }).$promise.then(function(response) {
                var date = getDate();
                angular.forEach(response.data, function(value, key) {
                    if (key == 'all') {
                        var filename = 'centraltest_tests_all_'+date+'.xml';
                    } else if (key == 'type_unit') {
                        var filename = 'centraltest_tests_type_unit_'+date+'.xml';
                    } else if (key == 'type_regression') {
                        var filename = 'centraltest_tests_type_regression_'+date+'.xml';
                    } else {
                        alert('Invalid key');
                        return;
                    }
                    var blob = new Blob([value], {type: 'text/xml'}),
                        url = $window.URL || $window.webkitURL;

                    var a = $("<a style='display: none;'/>");
                    var url = url.createObjectURL(blob);
                    a.attr("href", url);
                    a.attr("download", filename);
                    $("body").append(a);
                    a[0].click();
                    window.URL.revokeObjectURL(url);
                    a.remove();
                });
                Notification.success('List(s) exported successfully');
            }).catch(function(error) {
                console.log(error);
                Notification.error('Could not export list(s)');
            }).finally(function() {
                if ($state.current.name !== 'centraltest.default') {
                    $state.go('centraltest.default');
                }
            });
        }

        function processXml(xml) {
            return resource.process({}, {xml: xml}).$promise;
        }

        function uploadXml(formData) {
            return resource.upload({}, formData).$promise;
        }
    }
})();
