(function() {
    'use strict';

    angular.module('adminApp.cloud')
        .factory('cloudModalService', cloudModalService);

    cloudModalService.$inject = ['$ocLazyLoad', '$uibModal'];

    function cloudModalService($ocLazyLoad, $uibModal) {
        var service = {
            upgrade: upgrade
        };

        return service;

        function upgrade(type) {
            type = _.isUndefined(type) ? 'upgrade' : type;
            $uibModal.open({
                templateUrl: 'view/subscriptions.cloud.components.upgrade',
                controller: 'CloudUpgradeController',
                controllerAs: 'vm',
                size: 'lg',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    braintree: () => $ocLazyLoad.load(
                        'https://js.braintreegateway.com/web/dropin/1.9.3/js/dropin.min.js'
                    ),
                    type: function() {
                        return type;
                    }
                }
            });
        }
    }
})();
