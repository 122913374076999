(function() {
    'use strict';

    angular.module('adminApp.external')
        .factory('externalService', externalService);

    externalService.$inject = ['$resource', '$rootScope', '$state', 'authenticate', 'endSession', 'loginService', 'pendingRequestsService', 'sessionService', 'transferFlash'];

    function externalService($resource, $rootScope, $state, authenticate, endSession, loginService, pendingRequestsService, sessionService, transferFlash) {
        var resource = new $resource('/api/admin/external/:action', {}, {
            postCheckUserFilex: {
                method: 'POST',
                params: {
                    action: 'filex'
                }
            },
            postCheckUserVault: {
                method: 'POST',
                params: {
                    action: 'vault'
                }
            },
            postSessionToken: {
                method: 'POST',
                params: {
                    action: 'session'
                }
            }
        });

        var service = {
            postCheckUserFilex: postCheckUserFilex,
            postCheckUserVault: postCheckUserVault,
            postSessionToken: postSessionToken
        };

        return service;

        function postCheckUserFilex(token, authInfo) {
            var data = {
                token: token,
                authenticated: authInfo.authenticated
            };

            if (data.authenticated) {
                data.authId = authInfo.user.id;
            }

            resource.postCheckUserFilex({}, data).$promise.then(function(response) {
                localStorage.setItem('auth_token', response.data.token);
                localStorage.setItem('access_time', Math.round((new Date()).getTime() / 1000));
                localStorage.setItem('refresh_time', Math.round((new Date()).getTime() / 1000));
                loginService.authInfo.authenticated = true;
                loginService.authInfo.user = response.data.user;
                $state.go('home');
            }).catch(function(error) {
                console.log(error.data);
                switch (error.status) {
                    case 403:
                        runError(error.data.message);
                        break;
                    default:
                        runError("An unknown error occurred. Please <a href='https://support.scientiamobile.com' target=\"_blank\">contact us</a> for assistance.");
                }
            });
        }

        function postCheckUserVault(session, authInfo) {
            var data = {
                session: session,
                authenticated: authInfo.authenticated
            };

            if (data.authenticated) {
                data.authId = authInfo.user.id;
            }

            resource.postCheckUserVault({}, data).$promise.then(function(response) {
                localStorage.setItem('auth_token', response.data.token);
                localStorage.setItem('access_time', Math.round((new Date()).getTime() / 1000));
                localStorage.setItem('refresh_time', Math.round((new Date()).getTime() / 1000));
                loginService.authInfo.authenticated = true;
                loginService.authInfo.user = response.data.user;
                $state.go('home');
            }).catch(function(error) {
                console.log(error.data);
                switch (error.status) {
                    case 403:
                        runError(error.data.message);
                        break;
                    default:
                        runError("An unknown error occurred. Please <a href='https://support.scientiamobile.com' target=\"_blank\">contact us</a> for assistance.");
                }
            });
        }

        function postSessionToken(authId, id) {
            return resource.postSessionToken({}, {auth_id: authId, id: id}).$promise;
        }

        function runError(message) {
            $rootScope.$broadcast('authenticated');

            transferFlash.set('message', message);
            transferFlash.set('type', 'danger');

            $state.go('login');
            return;
        }
    }
})();
