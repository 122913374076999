(function() {
    'use strict';

    angular.module('adminApp.coreapi')
        .controller('CoreApiClientViewController', CoreApiClientViewController);

    CoreApiClientViewController.$inject = ['$rootScope', '$state', '$stateParams', 'breadcrumbsService', 'coreApiClientService', 'coreApiModalService', 'Notification'];

    function CoreApiClientViewController($rootScope, $state, $stateParams, breadcrumbsService, coreApiClientService, coreApiModalService, Notification) {
        var vm = this;

        vm.client = {};
        vm.createNew = createNew;
        vm.deleteClient = deleteClient;
        vm.edit = edit;
        vm.editScopes = editScopes;
        vm.loading = true;
        vm.loadingClient = true;
        vm.steps = breadcrumbsService.get('coreapi.view', {client: $stateParams.id});

        getClient();

        $rootScope.$on('reloadClient', function() {
            getClient();
        })

        function getClient() {
            vm.loading = true;
            vm.loadingClient = true;
            coreApiClientService.getClientScopes($stateParams.id).then(function(response) {
                vm.client = response.data;
                vm.loading = false;
            }).catch(function(error) {
                console.log(error);
                switch (error.status) {
                    case 404:
                        Notification.error('Could not find client');
                        $state.go('coreapi.home');
                        break;
                    default:
                        Notification.error('An unknown error occurred');
                        break;
                }
            }).finally(function() {
                vm.loadingClient = false;
            });
        }

        function createNew() {
            coreApiModalService.create();
        }

        function deleteClient() {
            coreApiModalService.deleteClient(vm.client.id);
        }

        function edit() {
            coreApiModalService.edit(vm.client);
        }

        function editScopes() {
            coreApiModalService.editClientScopes(vm.client.id);
        }
    }
})();
