(function() {
    'use strict';

    angular.module('adminApp.wit')
        .controller('WitEditSettingsController', WitEditSettingsController);

    WitEditSettingsController.$inject = ['$stateParams', '$uibModal', '$uibModalInstance', 'domain', 'Notification', 'witAccountService', 'witModalService'];

    function WitEditSettingsController($stateParams, $uibModal, $uibModalInstance, domain, Notification, witAccountService, witModalService) {
        var vm = this;

        vm.addSetting = addSetting;
        vm.checkTransitionTime = checkTransitionTime;
        vm.close = close;
        vm.deleteSetting = deleteSetting;
        vm.domain = domain;
        vm.editSetting = editSetting;
        vm.running = false;
        vm.transitionTime = angular.copy(vm.domain.transition_time);
        vm.updateTransitionTime = updateTransitionTime;

        function addSetting() {
            var open = witModalService.openEditSettings(domain, true, null);

            open.result.then(function(response) {
                vm.domain.wit_domain_setting = response;
            });
        }

        function checkTransitionTime() {
            return vm.domain.transition_time == vm.transitionTime;
        }

        function close() {
            $uibModalInstance.close(vm.domain.transition_time);
        }

        function deleteSetting(setting) {
            var confirm = witModalService.confirmDeleteSettings(setting.id, setting.path);
            confirm.result.then(function() {
                vm.domain.wit_domain_setting = _.reject(vm.domain.wit_domain_setting, function(obj) {
                    return obj.id == setting.id
                });
            })
        }

        function editSetting(setting) {
            var open = witModalService.openEditSettings(domain, false, setting);

            open.result.then(function(response) {
                vm.domain.wit_domain_setting = response;
            });
        }

        function updateTransitionTime() {
            vm.running = true;
            witAccountService.patchUpdateDomainTransitionTime($stateParams.id, vm.domain.id, {transition_time: vm.transitionTime})
                .then(function(response) {
                    Notification.success("Updated transition time");
                    vm.domain.transition_time = angular.copy(vm.transitionTime);
                }).catch(function(error) {
                    console.log(error.data);
                    Notification.error("Unable to update transition time");
                }).finally(function() {
                    vm.running = false;
                });
        }
    }
})();
