(function () {
    'use strict';

    angular.module('adminApp.saas')
        .factory('subscriptionModalService', subscriptionModalService);

    subscriptionModalService.$inject = ['$ngConfirm', '$rootScope', '$state', '$stateParams', '$uibModal', 'baseAccountService', 'Notification', 'quwurflAccountService'];

    function subscriptionModalService($ngConfirm, $rootScope, $state, $stateParams, $uibModal, baseAccountService, Notification, quwurflAccountService) {
        var service = {
            cancel: cancel,
            createNew: createNew,
            download: download,
            edit: edit,
            editDefaults: editDefaults,
            restart: restart,
            suspend: suspend,
            unsuspend: unsuspend,
            editQuWURFLCapabilities: editQuWURFLCapabilities,
        };

        return service;

        function cancel(type, id) {
            id = _.isUndefined(id) ? $stateParams.id : id;
            var message = 'This will cancel the subscription.';

            message += "<br /><br />Do you want to continue?";
            $ngConfirm({
                title: 'Are you sure?',
                theme: 'dark',
                contentUrl: 'view/subscriptions.common.cancel',
                buttons: {
                    close: {
                        text: 'Cancel',
                        btnClass: 'btn-danger'
                    },
                    proceed: {
                        text: 'Continue',
                        btnClass: 'btn btn-primary',
                        action: function (scope) {
                            if (scope.reason == null || scope.reason == '') {
                                scope.missingReason = true;
                                return false;
                            } else {
                                var obj = {
                                    type: type
                                };
                                $rootScope.$broadcast('disableButtons');

                                if (type === 'quwurfl') {
                                    quwurflAccountService.cancel(id, { reason: scope.reason }).then(function (response) {
                                        obj.data = { reason: scope.reason };
                                        obj.success = true;
                                    }).catch(function (error) {
                                        obj.success = false;
                                        console.log(error);
                                    }).finally(function () {
                                        if ($state.current.name == type + '.view') {
                                            $rootScope.$broadcast('completeReload');
                                        } else {
                                            $rootScope.$broadcast(type + 'ReloadList');
                                        }
                                    });
                                } else {
                                    baseAccountService.cancel('/api/admin/' + type, id, scope.reason).then(function (response) {
                                        obj.data = { reason: scope.reason };
                                        obj.success = true;
                                    }).catch(function (error) {
                                        obj.success = false;
                                        console.log(error);
                                    }).finally(function () {
                                        if ($state.current.name == type + '.view') {
                                            // $rootScope.$broadcast('canceledAccount', obj);
                                            $rootScope.$broadcast('completeReload');
                                        } else {
                                            $rootScope.$broadcast(type + 'ReloadList');
                                        }
                                    });
                                }
                            }
                        }
                    }
                },
                onScopeReady: function (scope) {
                    var self = this;
                    scope.message = message;
                    scope.missingReason = false;
                    scope.reason = '';
                }
            });
        }

        function createNew(type, userId) {
            userId = _.isUndefined(userId) ? null : userId;
            $uibModal.open({
                templateUrl: 'view/subscriptions.common.new_subscription',
                controller: 'NewSaasAccountController',
                controllerAs: "vm",
                size: 'md',
                keyboard: false,
                backdrop: 'static',
                resolve: {
                    saasType: function () {
                        return type;
                    },
                    userId: function () {
                        return userId;
                    }
                }
            });
        }

        function download(type) {
            $uibModal.open({
                templateUrl: 'view/components.account_download_tracker',
                controller: 'AccountDownloadsSubscriptionController',
                controllerAs: 'vm',
                size: 'md',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    type: function () {
                        return type;
                    }
                }
            });
        }

        function edit(type) {
            var templateUrl = 'view/subscriptions.' + type + '.components.edit_account';
            $uibModal.open({
                templateUrl: templateUrl,//'view/subscriptions.common.edit_account',
                controller: 'SaasEditController',
                controllerAs: 'vm',
                size: 'md',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    saasType: function () {
                        return type;
                    }
                }
            });
        }

        function editDefaults(type, plan) {
            $uibModal.open({
                templateUrl: 'view/subscriptions.common.edit_account_defaults',
                controller: 'SaasEditDefaultsController',
                controllerAs: 'vm',
                size: 'md',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    saasType: function () {
                        return type;
                    },
                    plan: function () {
                        return plan
                    }
                }
            });
        }

        function editQuWURFLCapabilities(subscriptionId, capabilities) {
            $uibModal.open({
                templateUrl: 'view/subscriptions.quwurfl.capabilities',
                controller: 'QuWurflCapabilitiesController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                size: 'lg',
                resolve: {
                    capabilities: function () {
                        return capabilities;
                    }
                }
            });
        }

        function restart(type, subscription) {
            var message = "This will reactivate the subscription.";
            if (!_.isNull(subscription.payment_method_token)) {
                message += "<br /><br />A new payment subscription will be started.";
            }
            message += "<br /><br />Do you want to continue?";

            $ngConfirm({
                title: 'Confirm',
                theme: 'dark',
                content: "<div>" + message + "</div>",
                buttons: {
                    close: {
                        text: 'Cancel',
                        btnClass: 'btn-danger'
                    },
                    proceed: {
                        text: 'Continue',
                        btnClass: 'btn btn-primary',
                        action: function (scope) {
                            var obj = {
                                type: type
                            };
                            $rootScope.$broadcast('disableButtons');
                            baseAccountService.restart('/api/admin/' + type, subscription.id).then(function (response) {
                                obj.data = { reason: scope.reason };
                                obj.success = true;
                                Notification.success("Successfully reactivated account!");
                            }).catch(function (error) {
                                obj.success = false;
                                console.log(error);
                                Notification.error("Unable to reactivate account");
                            }).finally(function () {
                                if ($state.current.name == type + '.view') {
                                    $rootScope.$broadcast('completeReload');
                                } else {
                                    $rootScope.$broadcast(type + 'ReloadList');
                                }
                            });
                        }
                    }
                }
            })
        }

        function suspend(type) {
            var message = "This action will indefinitely suspend the account.";

            message += "<br /><br />Do you want to continue?";

            $ngConfirm({
                title: 'Confirm',
                theme: 'dark',
                content: message,
                buttons: {
                    close: {
                        text: 'Cancel',
                        btnClass: 'btn-danger'
                    },
                    proceed: {
                        text: 'Continue',
                        btnClass: 'btn-primary',
                        action: function (button) {
                            var obj = {
                                type: type
                            };
                            $rootScope.$broadcast('disableButtons');
                            baseAccountService.suspend('/api/admin/' + type, $stateParams.id).then(function (response) {
                                obj.success = true;
                            }).catch(function (error) {
                                obj.success = false;
                                console.log(error);
                            }).finally(function () {
                                $rootScope.$broadcast('suspendedAccount', obj);
                            });
                        }
                    }
                }
            });
        }

        function unsuspend(type) {
            var message = "This action will return the account to active.";
            message += "<br /><br />Do you want to continue?";

            $ngConfirm({
                title: 'Confirm',
                theme: 'dark',
                content: message,
                buttons: {
                    close: {
                        text: 'Cancel',
                        btnClass: 'btn-danger'
                    },
                    proceed: {
                        test: 'Continue',
                        btnClass: 'btn-primary',
                        action: function (button) {
                            var obj = {
                                type: type
                            };
                            $rootScope.$broadcast('disableButtons');
                            baseAccountService.unsuspend('/api/admin/' + type, $stateParams.id).then(function (response) {
                                obj.success = true;
                            }).catch(function (error) {
                                console.log(error);
                                obj.success = false;
                            }).finally(function () {
                                $rootScope.$broadcast('unsuspendedAccount', obj);
                            });
                        }
                    }
                }
            });
        }
    }
})();
