(function() {
    'use strict';

    angular.module('adminApp.reports')
        .factory('reportsService', reportsService);

    reportsService.$inject = ['$filter', '$q', '$resource', '$rootScope', '$uibModal', '$window', 'Notification', 'witAccountService'];

    function reportsService($filter, $q, $resource, $rootScope, $uibModal, $window, Notification, witAccountService) {
        var resource = new $resource('/api/admin/download/:field/:action', {field: ':@field', action: '@action'}, {
            download: {
                method: 'POST',
                params: {
                    field: 'report'
                }
            },
            downloadImageEngineTrialReport: {
                method: 'POST',
                params: {
                    field: 'imageengine',
                    action: null
                }
            },
            downloadLicenseReport: {
                method: 'POST',
                params: {
                    field: 'license',
                    action: null
                }
            },
            downloadSubscriptionsReport: {
                method: 'POST',
                params: {
                    field: 'subscription',
                    action: null
                }
            },
            downloadTrialLinksReport: {
                method: 'POST',
                params: {
                    field: 'triallinks',
                    action: null
                }
            },
            downloadUserAccounts: {
                method: 'POST',
                params: {
                    field: 'users',
                    action: null
                }
            },
            downloadUserRoles: {
                method: 'POST',
                params: {
                    field: 'roles',
                    action: null
                }
            }
        });

        var service = {
            calculateProgress: calculateProgress,
            downloadExpiredTrialStats: downloadExpiredTrialStats,
            downloadImageEngineTrialReport: downloadImageEngineTrialReport,
            downloadLicenseReport: downloadLicenseReport,
            downloadReports: downloadReports,
            downloadSubscriptionsReport: downloadSubscriptionsReport,
            downloadTrialLinksReport: downloadTrialLinksReport,
            downloadUserAccounts: downloadUserAccounts,
            downloadUserRoles: downloadUserRoles,
            getParams: getParams
        };

        service.chunks = [];
        service.chunkIndx = 0;
        service.stats = [];

        return service;

        function calculateProgress(complete) {
            var counts = _.countBy(complete, function(obj) {
                return obj ? 'done' : 'undone'
            });
            var done = !_.isUndefined(counts.done) ? counts.done : 0;
            var undone = !_.isUndefined(counts.undone) ? counts.undone : 0;
            return Math.round((done / (done + undone)) * 100);
        }

        function runDownloadStats() {
            var promises = [];
            angular.forEach(service.chunks[service.chunkIndx], function(account) {
                promises.push(
                    witAccountService.getTrialStatistics(account.id).then(function(response) {
                        service.stats.push(response.data);
                        var completePercentage = Math.round((service.stats.length / _.flatten(service.chunks).length) * 100);
                        $rootScope.$broadcast('updateTrialStatsProgress', {completePercentage: completePercentage});
                    }).catch(function(error) {
                        console.log(error);
                        $q.reject();
                    })
                );
            });
            $q.all(promises).then(function() {
                service.chunkIndx++;
                if (service.chunkIndx < service.chunks.length) {
                    runDownloadStats();
                } else {
                    service.stats.unshift([
                        'ID',
                        'Name',
                        'E-Mail',
                        'Level',
                        'Type',
                        'Token',
                        'Domain Name(s)',
                        'Started Date',
                        'Expiration Date',
                        'Status',
                        'Cache Hit Percentage',
                        'Cache Miss Percentage',
                        'Original Image Payload',
                        'ImageEngine Smart Bytes',
                        'Payload Savings Percentage',
                        'WebP Savings Percentage',
                        'JPEG2000 Savings Percentage'
                    ]);
                    var results = [];
                    angular.forEach(service.stats, function(obj) {
                        results.push(_.values(obj));
                    });

                    service.chunks = [];
                    service.chunkIndx = 0;
                    service.stats = [];

                    witAccountService.postTrialStatistics(results)
                        .then(function(response) {
                            var blob = new Blob([response.data[0]], {type: 'text/csv'}),
                                url = $window.URL || $window.webkitURL;

                            var a = $("<a style='display: none;'/>");
                            var url = url.createObjectURL(blob);
                            a.attr("href", url);
                            var date = getDate();
                            a.attr("download", "ie_expired_trial_stats_"+date+".csv");
                            $("body").append(a);
                            a[0].click();
                            window.URL.revokeObjectURL(url);
                            a.remove();
                        }).catch(function(error) {
                            console.log(error);
                            Notification.error("Unable to download expired trial statistics");
                        });
                }
            });
        }

        function downloadExpiredTrialStats() {
            witAccountService.getExpiredTrials().then(function(response) {
                for (var i = 0; i < response.data.length; i+=10) {
                    service.chunks.push(response.data.slice(i, i+10));
                }

                runDownloadStats();
            }).catch(function(error) {
                console.log(error);
                Notification.error("Unable to download expired trial statistics");
            });
        }

        function getDate() {
            var d = new Date();
            var year = d.getFullYear(),
                month = d.getMonth() + 1 < 10 ? '0'+(d.getMonth() + 1) : d.getMonth() + 1,
                day = d.getDate() < 10 ? '0'+d.getDate() : d.getDate(),
                h = d.getHours() < 10 ? '0'+d.getHours() : d.getHours(),
                m = d.getMinutes() < 10 ? '0'+d.getMinutes() : d.getMinutes(),
                s = d.getSeconds() < 10 ? '0'+d.getSeconds() : d.getSeconds(),
                date = year+'-'+month+'-'+day+'_'+h+':'+m+':'+s;
            return date;
        }

        function formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;

            return [year, month, day].join('-');
        }

        function getParams(name) {
            var params = {};
            switch (name) {
                case 'Snapshots':
                    var end = new Date();
                    end.setHours(23, 59, 59, 999);
                    var start = new Date();
                    start.setDate(start.getDate()-180);
                    params = {
                        start: formatDate(start),
                        end: formatDate(end)
                    };
                    break;
                case 'CloudUsage':
                    var end = new Date();
                    end.setHours(23, 59, 59, 999);
                    var start = new Date();
                    start.setDate(start.getDate()-90);
                    params = {
                        start: formatDate(start),
                        end: formatDate(end)
                    };
                    break;
                case 'ExpiringEvaluationLicenses':
                    var start = new Date();
                    start.setHours(0, 0, 0, 0);
                    var end = new Date(start.getTime());
                    end.setHours(23, 59, 59, 999);

                    params = {
                        start: formatDate(start),
                        end: formatDate(end)
                    };
                    break;
                case 'ExpiringLicenses':
                    var end = new Date();
                    end.setHours(23, 59, 59, 999);
                    var start = new Date();
                    start.setDate(start.getDate()-5);
                    params = {
                        start: formatDate(start),
                        end: formatDate(end)
                    };
                    break;
                case 'ExpiringSubscriptions':
                    var start = new Date();
                    start.setHours(0, 0, 0, 0);
                    var end = new Date(start.getTime());
                    end.setHours(23, 59, 59, 999);

                    params = {
                        start: formatDate(start),
                        end: formatDate(end)
                    };
                    break;
            }
            return params;
        }

        function downloadReports(name, params) {
            params.start = formatDate(params.start);
            params.end = formatDate(params.end);
            var date = getDate();
            resource.download({action: name}, params).$promise
                .then(function(response) {
                    var blob = new Blob([response.data[0]], {type: 'text/csv'}),
                        url = $window.URL || $window.webkitURL;

                    var a = $("<a style='display: none;'/>");
                    var url = url.createObjectURL(blob);
                    a.attr("href", url);
                    name = name.split(/(?=[A-Z])/);
                    name = name.join("_");
                    var prefix = $filter('lowercase')(name)
                    a.attr("download", prefix+"_report_"+date+".csv");
                    $("body").append(a);
                    a[0].click();
                    window.URL.revokeObjectURL(url);
                    a.remove();
                }).catch(function (error) {
                    console.log(error);
                    Notification.error("Unable to download "+name+" report");
                });
        }

        function downloadImageEngineTrialReport() {
            var date = getDate();

            var promises = {};

            resource.downloadImageEngineTrialReport({header: true}).$promise
                .then(function(response) {
                    var fname = response.data.fname;
                    promises[0] = true;
                    var starts = [];
                    for (var i = 100; i<= response.data.count; i += 100) {
                        starts.push(i);
                        promises[i] = false;
                    }
                    $rootScope.$broadcast('updateImageEngineProgress', {complete: promises});
                    if (_.every(promises, function(c) {
                        return c == true;
                    })) {
                        return resource.downloadImageEngineTrialReport({output: true, fname: fname}).$promise
                            .then(function(response) {
                                var blob = new Blob([response.data[0]], {type: 'text/csv'}),
                                    url = $window.URL || $window.webkitURL;

                                var a = $("<a style='display: none;'/>");
                                var url = url.createObjectURL(blob);
                                a.attr("href", url);
                                a.attr("download", "imageengine_trials_report_"+date+".csv");
                                $("body").append(a);
                                a[0].click();
                                window.URL.revokeObjectURL(url);
                                a.remove();
                            });
                    }
                    angular.forEach(starts, function(val, key) {
                        resource.downloadImageEngineTrialReport({page: key+2, fname: fname}).$promise
                            .then(function(response) {
                                promises[val] = true;
                                $rootScope.$broadcast('updateImageEngineProgress', {complete: promises});
                                if (_.every(promises, function(c) {
                                    return c == true;
                                })) {
                                    resource.downloadImageEngineTrialReport({output: true, fname: fname}).$promise
                                        .then(function(response) {
                                            var blob = new Blob([response.data[0]], {type: 'text/csv'}),
                                                url = $window.URL || $window.webkitURL;

                                            var a = $("<a style='display: none;'/>");
                                            var url = url.createObjectURL(blob);
                                            a.attr("href", url);
                                            a.attr("download", "imageengine_trials_report_"+date+".csv");
                                            $("body").append(a);
                                            a[0].click();
                                            window.URL.revokeObjectURL(url);
                                            a.remove();
                                        });
                                }
                            }).catch(function(error) {
                                Notification.error('An error occurred. Download cannot be completed.');
                                console.log(error);
                                promises[val] = true;
                                if (_.every(promises, function(c) {
                                    return c == true;
                                })) {
                                    $rootScope.$broadcast('ieErrorClose');
                                }
                            });
                    });
                }).catch(function (error) {
                    console.log(error);
                    Notification.error("Unable to download ImageEngine trials report");
                    $rootScope.$broadcast('ieErrorClose');
                });
        }

        function downloadLicenseReport(type) {
            var date = getDate();

            var promises = {};

            resource.downloadLicenseReport({type: type, header: true}).$promise
                .then(function(response) {
                    var fname = response.data.fname;
                    promises[0] = true;
                    var starts = [];
                    for (var i = 1000; i <= response.data.count; i += 1000) {
                        starts.push(i);
                        promises[i] = false;
                    }
                    $rootScope.$broadcast('updateLicensesProgress', {complete: promises});
                    if (_.every(promises, function(c) {
                        return c == true;
                    })) {
                        return resource.downloadLicenseReport({type: type, output: true, fname: fname}).$promise
                            .then(function(response) {
                                var blob = new Blob([response.data[0]], {type: 'text/csv'}),
                                    url = $window.URL || $window.webkitURL;

                                var a = $("<a style='display: none;'/>");
                                var url = url.createObjectURL(blob);
                                a.attr("href", url);
                                a.attr("download", type+"_licenses_report_"+date+".csv");
                                $("body").append(a);
                                a[0].click();
                                window.URL.revokeObjectURL(url);
                                a.remove();
                            });
                    }
                    angular.forEach(starts, function(val, key) {
                        resource.downloadLicenseReport({type: type, page: key+2, fname: fname}).$promise
                            .then(function(response) {
                                promises[val] = true;
                                $rootScope.$broadcast('updateLicensesProgress', {complete: promises});
                                if (_.every(promises, function(c) {
                                    return c == true;
                                })) {
                                    resource.downloadLicenseReport({type: type, output: true, fname: fname}).$promise
                                        .then(function(response) {
                                            var blob = new Blob([response.data[0]], {type: 'text/csv'}),
                                                url = $window.URL || $window.webkitURL;

                                            var a = $("<a style='display: none;'/>");
                                            var url = url.createObjectURL(blob);
                                            a.attr("href", url);
                                            a.attr("download", type+"_licenses_report_"+date+".csv");
                                            $("body").append(a);
                                            a[0].click();
                                            window.URL.revokeObjectURL(url);
                                            a.remove();
                                        });
                                }
                            }).catch(function(error) {
                                Notification.error('An error occurred. Download cannot be completed.');
                                console.log(error);
                                promises[val] = true;
                                if (_.every(promises, function(c) {
                                    return c == true;
                                })) {
                                    $rootScope.$broadcast('licenseErrorClose');
                                }
                            });
                    });
                }).catch(function(error) {
                    console.log(error);
                    Notification.error("Unable to download "+type+" licenses report");
                    $rootScope.$broadcast('licenseErrorClose');
                });
        }

        function downloadSubscriptionsReport(type) {
            var date = getDate();
            // var data = [];

            var promises = {};

            resource.downloadSubscriptionsReport({type: type, header: true}).$promise
                .then(function(response) {
                    // data.push(response.data.data);
                    var fname = response.data.fname;
                    promises[0] = true;
                    var starts = [];
                    for (var i = 100; i <= response.data.count; i += 100) {
                        starts.push(i);
                        promises[i] = false;
                    }
                    $rootScope.$broadcast('updateSubscriptionsProgress', {complete: promises});
                    if (_.every(promises, function(c) {
                        return c == true;
                    })) {
                        // data = _.sortBy(_.flatten(data, true), function(obj) {
                        //     return obj.id;
                        // });
                        // return resource.downloadSubscriptionsReport({type: type, output: true, data: data}).$promise
                        return resource.downloadSubscriptionsReport({type: type, output: true, fname: fname}).$promise
                            .then(function(response) {
                                var blob = new Blob([response.data[0]], {type: 'text/csv'}),
                                    url = $window.URL || $window.webkitURL;

                                var a = $("<a style='display: none;'/>");
                                var url = url.createObjectURL(blob);
                                a.attr("href", url);
                                a.attr("download", type+"_accounts_report_"+date+".csv");
                                $("body").append(a);
                                a[0].click();
                                window.URL.revokeObjectURL(url);
                                a.remove();
                            });
                    }
                    angular.forEach(starts, function(val, key) {
                        resource.downloadSubscriptionsReport({type: type, page: key+2, fname: fname}).$promise
                            .then(function(response) {
                                promises[val] = true;
                                $rootScope.$broadcast('updateSubscriptionsProgress', {complete: promises});
                                // if (!_.isEmpty(response.data.data)) {
                                //     data.push(response.data.data);
                                // }
                                if (_.every(promises, function(c) {
                                    return c == true;
                                })) {
                                    // data = _.sortBy(_.flatten(data, true), function(obj) {
                                    //     return obj.id;
                                    // });
                                    // resource.downloadSubscriptionsReport({type: type, output: true, data: data}).$promise
                                    resource.downloadSubscriptionsReport({type: type, output: true, fname: fname}).$promise
                                        .then(function(response) {
                                            var blob = new Blob([response.data[0]], {type: 'text/csv'}),
                                                url = $window.URL || $window.webkitURL;

                                            var a = $("<a style='display: none;'/>");
                                            var url = url.createObjectURL(blob);
                                            a.attr("href", url);
                                            a.attr("download", type+"_accounts_report_"+date+".csv");
                                            $("body").append(a);
                                            a[0].click();
                                            window.URL.revokeObjectURL(url);
                                            a.remove();
                                        });
                                }
                            }).catch(function(error) {
                                Notification.error('An error occurred. Download cannot be completed.');
                                console.log(error);
                                promises[val] = true;
                                if (_.every(promises, function(c) {
                                    return c == true;
                                })) {
                                    $rootScope.$broadcast('subscriptionErrorClose');
                                }
                            });
                    });
                }).catch(function(error) {
                    console.log(error);
                    Notification.error("Unable to download "+type+" accounts report");
                    $rootScope.$broadcast('subscriptionErrorClose');
                });
        }

        function downloadTrialLinksReport() {
            var date = getDate();

            return resource.downloadTrialLinksReport().$promise
                .then(function(response) {
                    var blob = new Blob([response.data[0]], {type: 'text/csv'}),
                        url = $window.URL || $window.webkitURL;

                    var a = $("<a style='display: none;'/>");
                    var url = url.createObjectURL(blob);
                    a.attr("href", url);
                    a.attr("download", "imageengine_triallinks_report_"+date+".csv");
                    $("body").append(a);
                    a[0].click();
                    window.URL.revokeObjectURL(url);
                    a.remove();
                    return true;
                });
        }

        function downloadUserAccounts() {
            var date = getDate();

            var promises = {};

            resource.downloadUserAccounts({header: true}).$promise
                .then(function(response) {
                    var fname = response.data.fname;
                    promises[0] = true;
                    var starts = [];
                    for (var i = 1000; i <= response.data.count; i += 1000) {
                        starts.push(i);
                        promises[i] = false;
                    }
                    $rootScope.$broadcast('updateUsersProgress', {complete: promises});
                    if (_.every(promises, function(c) {
                        return c == true;
                    })) {
                        return resource.downloadUserAccounts({output: true, fname: fname}).$promise
                            .then(function(response) {
                                var blob = new Blob([response.data[0]], {type: 'text/csv'}),
                                    url = $window.URL || $window.webkitURL;

                                var a = $("<a style='display: none;'/>");
                                var url = url.createObjectURL(blob);
                                a.attr("href", url);
                                a.attr("download", "user_accounts_report_"+date+".csv");
                                $("body").append(a);
                                a[0].click();
                                window.URL.revokeObjectURL(url);
                                a.remove();
                            });
                    }
                    angular.forEach(starts, function(val, key) {
                        resource.downloadUserAccounts({page: key+2, fname: fname}).$promise
                            .then(function(response) {
                                promises[val] = true;
                                $rootScope.$broadcast('updateUsersProgress', {complete: promises});
                                if (_.every(promises, function(c) {
                                    return c == true;
                                })) {
                                    resource.downloadUserAccounts({output: true, fname: fname}).$promise
                                        .then(function(response) {
                                            var blob = new Blob([response.data[0]], {type: 'text/csv'}),
                                                url = $window.URL || $window.webkitURL;

                                            var a = $("<a style='display: none;'/>");
                                            var url = url.createObjectURL(blob);
                                            a.attr("href", url);
                                            a.attr("download", "user_accounts_report_"+date+".csv");
                                            $("body").append(a);
                                            a[0].click();
                                            window.URL.revokeObjectURL(url);
                                            a.remove();
                                        });
                                }
                            }).catch(function(error) {
                                Notification.error('An error occurred. Download cannot be completed.');
                                console.log(error);
                                promises[val] = true;
                                if (_.every(promises, function(c) {
                                    return c == true;
                                })) {
                                    $rootScope.$broadcast('userErrorClose');
                                }
                            });
                    });
                }).catch(function(error) {
                    console.log(error);
                    Notification.error("Unable to download user accounts report");
                    $rootScope.$broadcast('userErrorClose');
                });
        }

        function downloadUserRoles() {
            var date = getDate();
            resource.downloadUserRoles().$promise
                .then(function(response) {
                    var blob = new Blob([response.data[0]], {type: 'text/csv'}),
                        url = $window.URL || $window.webkitURL;

                    var a = $("<a style='display: none;'/>");
                    var url = url.createObjectURL(blob);
                    a.attr("href", url);
                    a.attr("download", "sentry_user_roles_report_"+date+".csv");
                    $("body").append(a);
                    a[0].click();
                    window.URL.revokeObjectURL(url);
                    a.remove();
                }).catch(function (error) {
                    console.log(error);
                    Notification.error("Unable to download user roles report");
                });
        }
    }
})();
