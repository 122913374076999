(function() {
    'use strict';

    angular.module('adminApp.coreapi')
        .factory('coreApiClientService', coreApiClientService);

    coreApiClientService.$inject = ['$resource'];

    function coreApiClientService($resource) {
        var resource = new $resource('/api/admin/coreapi/client/:id/:action/:action_id', {id: '@id', action_id: '@action_id'}, {
            addClientScope: {
                method: 'POST',
                params: {action: null}
            },
            all: {
                method: 'GET',
                params: {action: 'all', action_id: null}
            },
            deleteClient: {
                method: 'DELETE',
                params: {action: null, action_id: null}
            },
            get: {
                method: 'GET',
                params: {action: null, action_id: null}
            },
            getClientScopes: {
                method: 'GET',
                params: {action: 'scopes', action_id: null}
            },
            removeClientScope: {
                method: 'DELETE',
                params: {action: null}
            },
            save: {
                method: 'PATCH',
                params: {action: null, action_id: null}
            },
            submit: {
                method: 'POST',
                params: {action: null, action_id: null}
            }
        });
        var service = {
            addClientScope: addClientScope,
            all: all,
            deleteClient: deleteClient,
            get: get,
            getClientScopes: getClientScopes,
            save: save,
            removeClientScope: removeClientScope,
            submit: submit
        };

        return service;

        function addClientScope(params) {
            return resource.addClientScope({id: params.client_id, action_id: params.scope_id}).$promise;
        }

        function all() {
            return resource.all({id: null}).$promise;
        }

        function deleteClient(id) {
            return resource.deleteClient({id: id}).$promise;
        }

        function get(id) {
            return resource.get({id: id}).$promise;
        }

        function getClientScopes(id) {
            return resource.getClientScopes({id: id}).$promise;
        }

        function removeClientScope(params) {
            return resource.removeClientScope({id: params.client_id, action_id: params.scope_id}).$promise;
        }

        function save(client) {
            return resource.save({id: client.id}, client).$promise;
        }

        function submit(client) {
            return resource.submit({id: null}, client).$promise;
        }
    }
})();
