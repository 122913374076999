(function() {
    'use strict';

    angular.module('adminApp.saas')
        .factory('saasConstants', saasConstants);

    saasConstants.$inject = ['baseAccountService', 'Notification'];

    function saasConstants(baseAccountService, Notification) {
        var constants = {
            cloud: {},
            quwurfl: {},
            wit: {},
            wjs: {}
        };
        var service = {
            getConstants: getConstants,
            setConstants: setConstants
        };

        return service;

        function getConstants(type, key) {
            return constants[type][key];
        }

        function setConstants() {
            // TODO: fetch from APIs once WQ-92 is implemented
            constants.quwurfl.statusTypes = {"PENDING_SMS_VERIFY":"PENDING SMS VERIFY","PENDING_EMAIL_VERIFY":"PENDING EMAIL VERIFY","PENDING_PAYMENT":"PENDING PAYMENT","PENDING_PAYMENT_INFORMATION":"PENDING_PAYMENT_INFORMATION","ACTIVE":"ACTIVE","SUSPENDED":"SUSPENDED","CANCELED":"CANCELED"};
            constants.quwurfl.paymentTypes = {"EXTERNAL":"External (invoiced)"};
            constants.quwurfl.paymentPlans = [{"key": "QUWURFL_BUSINESS", "nice_name": "QuWURFL Business", "plan": "QuWURFL Business"}];
            constants.quwurfl.defaults = {
                capabilities: [
                    "brand_name",
                    "model_name",
                    "is_wireless_device",
                    "device_os",
                    "mobile_browser",
                    "mobile_browser_version",
                    "device_os_version",
                    "pointing_method",
                    "marketing_name",
                    "can_assign_phone_number",
                    "is_tablet",
                    "xhtml_support_level",
                    "preferred_markup",
                    "resolution_width",
                    "resolution_height",
                    "physical_screen_width",
                    "physical_screen_height",
                    "is_smarttv",
                    "ux_full_desktop",
                    "is_smartphone",
                    "form_factor",
                ],
                maxDomains: 10,
            }

            var url = '/api/admin/constants';
            baseAccountService.getConstants(url + '/cloud')
                .then(function(response) {
                    constants.cloud.statusTypes = response.data.status_types;
                    constants.cloud.paymentTypes = response.data.payment_types;
                    constants.cloud.paymentPlans = response.data.payment_plans;
                }, function(error) {
                    Notification.error('Unable to gather cloud constants');
                    console.log(error);
                });

            baseAccountService.getConstants(url + '/wit')
                .then(function(response) {
                    constants.wit.statusTypes = response.data.status_types;
                    constants.wit.paymentTypes = response.data.payment_types;
                    constants.wit.paymentPlans = response.data.payment_plans;
                }, function(error) {
                    Notification.error('Unable to gather wit constants');
                    console.log(error);
                });

            baseAccountService.getConstants(url + '/wjs')
                .then(function(response) {
                    constants.wjs.statusTypes = response.data.status_types;
                    constants.wjs.paymentTypes = response.data.payment_types;
                    constants.wjs.paymentPlans = response.data.payment_plans;
                }, function(error) {
                    Notification.error('Unable to gather wjs constants');
                    console.log(error);
                });
        }
    }
})();
