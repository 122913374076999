(function() {
    'use strict';

    angular.module('adminApp.licenses')
        .controller('LicenseTransferController', LicenseTransferController);

    LicenseTransferController.$inject = ['$rootScope', '$state', '$uibModalInstance', 'license', 'licenseId', 'licenseService', 'Notification'];

    function LicenseTransferController($rootScope, $state, $uibModalInstance, license, licenseId, licenseService, Notification) {
        var vm = this;

        vm.close = close;
        vm.loading = true;
        vm.modalOptions = {
            actionClass: 'alert-info',
            actionMessage: 'Transferring...',
            proceedButton: {
                btnClass: 'btn-primary',
                btnSpanClass: 'glyphicon-transfer',
                btnText: 'Transfer'
            },
            template: 'view/licenses.modals.transfer',
            title: 'Transfer License'
        };
        vm.newUser = undefined;
        vm.proceed = proceed;
        vm.showActionSpinner = false;
        vm.user = !_.isUndefined(license) ? license.user : undefined;

        var license = license;

        if (_.isUndefined(license)) {
            licenseService.get(licenseId).then(function(response) {
                vm.user = response.data.user;
                license = response.data;
            });
        }

        $rootScope.$on('usersDirectiveLoaded', function() {
            vm.loading = false;
        });

        function close() {
            $uibModalInstance.dismiss();
        }

        function proceed() {
            vm.showActionSpinner = true;
            licenseService.transfer(license, vm.newUser).then(function(response) {
                Notification.success("The license has been transferred to " + vm.newUser.name);

                if ($state.current.name == 'licenses.view') {
                    $rootScope.$broadcast('reloadLicense');
                } else if ($state.current.name == 'licenses.list') {
                    $rootScope.$broadcast('reloadLicenses');
                }
                $uibModalInstance.dismiss();
            }).catch(function(error) {
                Notification.error("Could not transfer license");
                console.log(error);
            }).finally(function() {
                vm.showActionSpinner = false;
            });
        }
    }
})();
