(function() {
    'use strict';

    angular.module('adminApp.licenses')
        .directive('licenseCategory', licenseCategory);

    licenseCategory.$inject = ['$rootScope', 'asOptionsFactory'];

    function licenseCategory($rootScope, asOptionsFactory) {
        var directive = {
            restrict: 'E',
            transclude: true,
            replace: true,
            template: '<div><input class="form-control" placeholder="loading license categories, please wait..." ng-if="license_cat_disable" disabled><select class="form-control" ng-if="!license_cat_disable" ng-disabled="license_cat_disable" id="license_category" name="license_category" ng-model="current" ng-options="option.id as option.name for option in license_categories" ng-change="updateLicenseCategory(current)"></select></div>',
            scope: {
                current: '=model'
            },
            link: link,
            controller: controller
        };

        return directive;

        function link(scope, element, attrs) {

            scope.license_cat_disable = true;
        }

        function controller($rootScope, $scope) {
            var columns = "id,license_cat_name?orderBy=license_cat_name&order=asc";

            asOptionsFactory.asOptions('LicenseCategory', columns).$promise
                .then(function(response) {
                    $scope.license_categories = response.data;
                    $scope.license_cat_disable = false;
                    $rootScope.$broadcast('loadedLicenseCategoryDirective');
                }
            );

            $scope.updateLicenseCategory = function(value) {
                $rootScope.$broadcast('updateLicenseCategoryDirective', {id: value});
            };
        }
    }
})();
