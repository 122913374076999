(function() {
    'use strict';

    angular.module('adminApp.wit')
        .controller('WitStatsDateRangeController', WitStatsDateRangeController);

    WitStatsDateRangeController.$inject = ['$scope', '$uibModalInstance', 'search', 'type', 'witStatisticsService'];

    function WitStatsDateRangeController($scope, $uibModalInstance, search, type, witStatisticsService) {
        var vm = this;

        vm.close = close;
        vm.options = {
            start: {
                showWeeks: false
            },
            end: {
                showWeeks: false,
                minDate: search.startDate
            }
        };
        vm.reset = reset;
        vm.search = search;
        vm.submit = submit;

        setSearch();

        $scope.$watch('vm.search', function(newVal, oldVal) {
            if (newVal.startDate != oldVal.startDate) {
                vm.options.end.minDate = newVal.startDate;
                if (newVal.endDate < newVal.startDate) {
                    vm.search.endDate = angular.copy(newVal.startDate);
                }
            }
        }, true);

        function close() {
            $uibModalInstance.dismiss();
        }

        function reset() {
            if (type == 'trial') {
                vm.search = {
                    endDate: null,
                    startDate: null
                };
            } else {
                vm.search = {
                    endDate: witStatisticsService.dateRanges[type].end,
                    startDate: witStatisticsService.dateRanges[type].start
                };
            }
        }

        function setSearch() {
            if (type !== 'trial') {
                if (_.isNull(vm.search.startDate)) {
                    vm.search.startDate = witStatisticsService.dateRanges[type].start;
                }
                if (_.isNull(vm.search.endDate)) {
                    vm.search.endDate = witStatisticsService.dateRanges[type].end;
                }
            }
        }

        function submit() {
            $uibModalInstance.close(vm.search);
        }
    }
})();
