(function() {
    'use strict';

    angular.module('adminApp.saas')
        .factory('wjsAccountService', wjsAccountService);

    wjsAccountService.$inject = ['$resource'];

    function wjsAccountService($resource) {

        var service = {
            addCertificate: addCertificate,
            addCname: addCname,
            addHostname: addHostname,
            deleteCertificate: deleteCertificate,
            deleteCname: deleteCname,
            deleteHostname: deleteHostname,
            endTrial: endTrial,
            getDefaults: getDefaults,
            patchChangePlan: patchChangePlan,
            patchMakeStandard: patchMakeStandard,
            resourceUrl: "/api/admin/wjs",
            restoreHostname: restoreHostname
        };

        var resource = new $resource(service.resourceUrl + "/:id/:action/:action_id", {id: '@id'}, {
            addCertificate: {
                method: 'POST',
                params: {action: 'ssl'}
            },
            addCname: {
                method: 'POST',
                params: {action: 'cname'}
            },
            addHostname: {
                method: 'POST',
                params: {action: 'hostname'}
            },
            deleteCertificate: {
                method: 'DELETE',
                params: {action: 'ssl'}
            },
            deleteCname: {
                method: 'DELETE',
                params: {action: 'cname'}
            },
            deleteHostname: {
                method: 'DELETE',
                params: {
                    force: '@force_delete',
                    action: 'hostname'
                }
            },
            getDefaults: {
                method: 'GET',
                params: {
                    action: 'defaults'
                }
            },
            patchEndTrial: {
                method: 'PATCH',
                params: {action: 'endtrial'}
            },
            patchChangePlan: {
                method: 'PATCH',
                params: {action: 'changeplan'}
            },
            patchMakeStandard: {
                method: 'PATCH',
                params: {action: 'standard'}
            },
            restoreHostname: {
                method: 'PATCH',
                params: {action: 'hostname'}
            },
        });

        return service;

        function addCertificate(id, cname, data) {
            return resource.addCertificate({id: id,action_id: cname.id}, {ssl: data}).$promise;
        }

        function addCname(id, value) {
            return resource.addCname({id: id}, {cname: value}).$promise;
        }

        function addHostname(id, value) {
            return resource.addHostname({id: id}, {hostname: value}).$promise;
        }

        function deleteCertificate(id, certificate) {
            return resource.deleteCertificate({id: id, action_id: certificate.wurfl_js_cname_id}).$promise;
        }

        function deleteCname(id, cname) {
            return resource.deleteCname({id: id, action_id: cname.id}).$promise;
        }

        function deleteHostname(id, domain_id, force) {
            return resource.deleteHostname({
                id: id,
                action_id: domain_id,
                force_delete: force
            }).$promise;
        }

        function endTrial(id, form) {
            return resource.patchEndTrial({id: id}, {form: form}).$promise;
        }

        function getDefaults(plan) {
            return resource.getDefaults({id: null, action_id: plan}).$promise;
        }

        function patchChangePlan(id, form) {
            return resource.patchChangePlan({id: id}, form).$promise;
        }

        function patchMakeStandard(id, form) {
            return resource.patchMakeStandard({id: id}, form).$promise;
        }

        function restoreHostname(id, domain_id) {
            return resource.restoreHostname({id: id}, {hostname: domain_id}).$promise;
        }
    }
})();
