(function () {
    'use strict';

    angular.module('adminApp.wit')
        .controller('WitNewAccountController', WitNewAccountController);

    WitNewAccountController.$inject = ['$ngConfirm', '$ocLazyLoad', '$rootScope', '$q', '$scope', '$state', '$uibModal', '$uibModalInstance', 'IE', 'Notification', 'paymentService', 'saasConstants', 'userId', 'userService', 'witAccountService', 'witModalService'];

    function WitNewAccountController($ngConfirm, $ocLazyLoad, $rootScope, $q, $scope, $state, $uibModal, $uibModalInstance, IE, Notification, paymentService, saasConstants, userId, userService, witAccountService, witModalService) {
        var vm = this;

        vm.class = {};
        vm.close = close;
        vm.defaults = {};
        vm.externalAlert = externalAlert;
        vm.form = {
            pro_standard: false,
            url_type: 'http',
            allow_origin_prefix: 1,
            whitelist: [null],
            transition_time: 300
        };
        vm.loading = true;
        vm.payment = false;
        vm.paymentInfoNeeded = paymentInfoNeeded;
        vm.paymentPlans = saasConstants.getConstants('wit', 'paymentPlans');
        vm.paymentTypeDisabled = paymentTypeDisabled;
        vm.paymentTypes = [];
        vm.required = required;
        vm.resetWhitelist = resetWhitelist;
        vm.running = false;
        vm.save = save;
        vm.showIeDomainNotify = showIeDomainNotify;
        vm.updatePaymentPlan = updatePaymentPlan;
        vm.updatePaymentType = updatePaymentType;
        vm.updateWhitelist = updateWhitelist;
        vm.viewUsers = viewUsers;
        vm.viewUsersDisabled = true;

        var accountResponse = null;
        var certificatePromises = [];
        var certificateResponse = null;
        var show = {
            origin: true,
            domain: true,
            certificate: true,
            resource: true
        };

        var tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        var dd = tomorrow.getDate();
        if (dd < 10) {
            dd = "0"+dd;
        }
        var mm = tomorrow.getMonth() + 1;
        if (mm < 10) {
            mm = "0"+mm;
        }
        var yy = tomorrow.getFullYear();

        tomorrow = yy+"-"+mm+"-"+dd;

        var users = {};
        var usersDirectiveLoaded = $rootScope.$on('usersDirectiveLoaded', function(event, args) {
            vm.viewUsersDisabled = false;
            users = args.users;
            if (!_.isNull(userId)) {
                vm.form.user = _.find(args.users, function(obj) {
                    return obj.id == userId;
                });
            }
            vm.loading = false;
            $scope.$on('$destroy', usersDirectiveLoaded);
        });

        resetClasses();

        angular.forEach(vm.paymentPlans, function(val, key) {
            if (val.payment_plan === IE.trial) {
                vm.paymentPlans.splice(key, 1);
            }
        });

        function close() {
            $uibModalInstance.dismiss();
        }

        function externalAlert() {
            $ngConfirm({
                title: 'Alert',
                theme: 'dark',
                content: "Invoices for external billing must be set up separately.",
                buttons: {
                    close: {
                        text: 'OK',
                        btnClass: 'btn-info'
                    }
                }
            });
        }

        function finish() {
            vm.running = false;
            $uibModalInstance.dismiss();
            $state.go('wit.view', {id: accountResponse.subscription.id});
        }

        function generateToken() {
            return paymentService.getBraintreeAuthorization().then(function(response) {
                return response.data.authorization;
            });
        }

        function paymentInfoNeeded() {
            if (vm.form.payment_type == 'STANDARD') {
                return true;
            }
            if (vm.form.payment_plan == IE.basic || vm.form.payment_plan == IE.standard) {
                return true;
            } else if (vm.form.payment_plan == IE.pro && vm.form.pro_standard) {
                return true;
            }
            return false;
        }

        function paymentTypeDisabled() {
            if (vm.form.payment_plan == undefined) {
                return true;
            }
            return false;
        }

        function resetClasses() {
            vm.class = {
                account_name: "form-control",
                payment_plan: "form-control",
                payment_type: "form-control",
                user: "form-control",
                duration_count: "form-control",
                duration_interval: "form-control col-md-6",
                usage_limit: "form-control",
                flat_rate: "form-control",
                overage_fee: "form-control",
                trial_days: "form-control"
            };
        }

        function required() {
            if (vm.form.payment_plan == IE.basic || vm.form.payment_plan == IE.standard) {
                return true;
            }
            if (vm.form.payment_plan == IE.pro) {
                if (vm.form.payment_type == 'STANDARD' || vm.form.pro_standard) {
                    return true;
                }
            }
            return false;
        }

        function save() {
            resetClasses();
            if (vm.form.user !== undefined && vm.form.user.id === undefined) {
                userService.getByName(vm.form.user).then(function(response) {
                    vm.form.user = {};
                    vm.form.user.id = response.data.id;
                    vm.form.user.name = response.data.username;
                    runSave();
                }).catch(function(error) {
                    Notification.error("Could not determine user information");
                    console.log(error);
                });
            } else {
                runSave();
            }
        }

        function runSave() {
            var form = angular.copy(vm.form);
            if (form.whitelist.length == 1 && _.isNull(form.whitelist[0])) {
                form.whitelist = null;
            }
            var errors = witAccountService.validateNew(form, required());
            if (errors.status) {
                angular.forEach(errors.fields, function(val, key) {
                    if (val) {
                        vm.class[key] += ' error';
                    }
                });
                return;
            }

            if (vm.payment) {
                $uibModalInstance.dismiss();
                witModalService.addPaymentInfo(null, form, true);
            } else {
                vm.running = true;
                witAccountService.create(form).then(function(response) {
                    accountResponse = response.data;
                    finish();
                }).catch(function(error) {
                    console.log(error);
                    vm.running = false;
                    switch (error.status) {
                        case 412:
                            angular.forEach(error.data.data, function(val, key) {
                                Notification.error(val);
                            });
                            break;
                        default:
                            Notification.error(error.data.message);
                            if (!_.isUndefined(error.data.data.errors)) {
                                finish();
                            }
                    }
                });
            }
        }

        function resetWhitelist() {
            if (vm.form.allow_origin_prefix == 1) {
                vm.form.whitelist = [null];
            } else {
                vm.form.whitelist = null;
            }
        }

        function showIeDomainNotify() {
            if (vm.form.ie_only) {
                $ngConfirm({
                    title: 'Alert',
                    theme: 'dark',
                    content: "This will enable SSL for "+vm.form.domain_name.toLowerCase()+".imgeng.in only. Please ensure that the domain name does NOT include `.imgeng.in`.",
                    buttons: {
                        close: {
                            text: 'OK',
                            btnClass: 'btn-info'
                        }
                    }
                });
                vm.form.validationType = "DNS";
            }
        }

        function updatePaymentPlan() {
            resetClasses();
            vm.form.payment_type = 'TRIAL';
            angular.forEach(vm.paymentPlans, function(val, key) {
                if (val.payment_plan == vm.form.payment_plan) {
                    vm.form.usage_limit = angular.copy(val.usage_limit);
                    vm.form.flat_rate = angular.copy(val.flat_rate);
                    vm.form.overage_fee = angular.copy(val.overage_fee);
                    vm.form.trial_days = angular.copy(val.trial_days);
                    vm.defaults.usage_limit = angular.copy(val.usage_limit);
                    vm.defaults.flat_rate = angular.copy(val.flat_rate);
                    vm.defaults.overage_fee = angular.copy(val.overage_fee);
                    vm.defaults.trial_days = angular.copy(val.trial_days);
                }
            });
            if (vm.form.payment_plan == IE.basic || vm.form.payment_plan == IE.standard) {
                vm.paymentTypes = [
                    {
                        payment_type: 'TRIAL',
                        name: 'Trial'
                    },
                    {
                        payment_type: 'STANDARD',
                        name: 'Standard Billing (recurring CC)'
                    }
                ];
                vm.form.ssl = false;
                vm.form.validationType = null;
            } else if (vm.form.payment_plan == IE.pro) {
                vm.paymentTypes = [
                    {
                        payment_type: 'TRIAL',
                        name: 'Trial'
                    },
                    {
                        payment_type: 'STANDARD',
                        name: 'Standard Billing (recurring CC)'
                    },
                    {
                        payment_type: 'EXTERNAL',
                        name: 'External (invoiced)'
                    },
                    {
                        payment_type: 'INTERNAL',
                        name: 'Internal Use Only'
                    }
                ];
                vm.form.ssl = true;
                vm.form.validationType = 'DNS';
            }
            if (vm.form.payment_plan == IE.pro) {
                var message = 'Invoices for external billing must be set up separately.';
                $ngConfirm({
                    title: 'Alert',
                    theme: 'dark',
                    content: message,
                    buttons: {
                        close: {
                            text: 'OK',
                            btnClass: 'btn-info'
                        }
                    }
                });
            }
        }

        function updatePaymentType() {
            resetClasses();
            if (vm.form.payment_type == 'STANDARD') {
                vm.payment = true;
                $ngConfirm({
                    title: 'Alert',
                    theme: 'dark',
                    content: "First billing cycle will begin tomorrow ("+tomorrow+").",
                    buttons: {
                        close: {
                            text: 'OK',
                            btnClass: 'btn-info'
                        }
                    }
                });
            } else {
                vm.payment = false;
            }
            if (vm.form.payment_plan == IE.pro) {
                if (vm.form.payment_type == 'STANDARD') {
                    vm.form.pro_standard = true;
                } else {
                    vm.form.pro_standard = false;
                }
            }
        }

        function updateWhitelist(key) {
            if (_.isUndefined(key)) {
                vm.form.whitelist.push(null);
            } else {
                vm.form.whitelist.splice(key, 1);
                if (vm.form.whitelist.length === 0) {
                    vm.form.whitelist = [null];
                }
            }
        }

        function viewUsers() {
            $uibModal.open({
                templateUrl: 'view/components.pick_list_modal',
                controller: controller,
                size: 'sm',
                resolve: {
                    listTitle: function() {
                        return 'All Users';
                    },
                    items: function() {
                        return users;
                    },
                    label: function() {
                        return 'name';
                    }
                },
                backdrop: 'static',
                keyboard: false
            }).result.then(function(selected) {
                vm.form.user = selected;
            });

            function controller($scope, $uibModalInstance, items, listTitle, label) {
                $scope.listTitle = listTitle;
                $scope.items = items;
                $scope.label = label;

                $scope.select = select;

                function select(item) {
                    $uibModalInstance.close(item);
                }
            }
        }
    }
})();
