(function() {
    'use strict';

    angular.module('adminApp.saas')
        .directive('saasSearchForm', saasSearchForm);

    function saasSearchForm() {
        var directive = {
            restrict: 'E',
            scope: {
                action: '@',
                state: '@',
                placeholder: '@',
                model: '='
            },
            controller: 'SaasSearchFormController',
            controllerAs: 'vm',
            bindToController: true,
            templateUrl: 'view/subscriptions.common.search_form'
        };

        return directive;
    }
})();
