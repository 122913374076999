(function() {
    'use strict';

    angular.module('adminApp.saas')
        .controller('UserSaasSubscriptionsController', UserSaasSubscriptionsController);

    UserSaasSubscriptionsController.$inject = ['$rootScope', '$state', '$stateParams', 'baseAccountService', 'Notification', 'subscriptionModalService', 'witModalService', '$location', 'quwurflAccountService'];

    function UserSaasSubscriptionsController($rootScope, $state, $stateParams, baseAccountService, Notification, subscriptionModalService, witModalService, $location, quwurflAccountService) {
        var vm = this;

        vm.active = 0;
        vm.createNew = createNew;
        vm.loading = false;
        vm.states = {
            view: vm.app + ".view",
            configuration: vm.app + ".view.configuration",
            usage: vm.app + ".view.usage",
            billing: vm.app + ".view.billing",
            domain: vm.app + ".view.domain"
        };
        vm.subscriptions = [];
        if (vm.app === 'wit') {
            vm.witImpersonationUrl = '/impersonate/imageengine/'+$stateParams.id;
        }

        load();

        $rootScope.$on('transferredAccounts', function() {
            load();
        });

        function createNew() {
            if (vm.app != 'wit') {
                subscriptionModalService.createNew(vm.app, $stateParams.id);
            } else {
                witModalService.createNew($stateParams.id);
            }
        }

        function load() {
            vm.loading = true;

            if (vm.app === "quwurfl") {
                quwurflAccountService.userSubscriptions($stateParams.id).then(function(data) {
                    vm.subscriptions = data.subscriptions;
                    vm.active = data.active;
                    vm.loading = false;
                });
                return;
            }

            var url = '/api/admin/'+vm.app;
            baseAccountService.getForUser(url, vm.user).then(function(response) {
                vm.active = response.data.active;
                vm.subscriptions = response.data.subscriptions;
            }).catch(function(error) {
                console.log(error);
                Notification.error('Could not gather '+vm.app+' subscriptions');
            }).finally(function() {
                vm.loading = false;
            });
        }
    }
})();
