(function() {
    'use strict';

    angular.module('adminApp.licenses')
        .controller('LicenseViewCapabilitiesController', LicenseViewCapabilitiesController);

    LicenseViewCapabilitiesController.$inject = ['$sce', '$stateParams', 'licenseService', 'Notification'];

    function LicenseViewCapabilitiesController($sce, $stateParams, licenseService, Notification) {
        var vm = this;

        vm.capabilities = licenseService.getValues($stateParams.id, 'capabilities');
        vm.license = licenseService.getValues($stateParams.id, 'license');
        vm.loadCapabilities = false;


        if (vm.capabilities === undefined) {
            licenseService.fetchCapabilities($stateParams.id)
                .then(function(response) {
                    response.data.forEach(function(item) {
                        item.capability = item.name;
                    });
                    vm.capabilities = response.data;
                    licenseService.setValues($stateParams.id, 'capabilities', vm.capabilities);
                    vm.loadCapabilities = true;
                }, function(error) {
                    Notification.error(error.data.message);
                });
        } else {
            vm.loadCapabilities = true;
        }

        if (vm.license === undefined) {
            vm.loadCapabilities = false;
            licenseService.get($stateParams.id).then(function(response) {
                vm.license = response.data;
                loadLicenseText(vm.license);
                if (
                    vm.license.product.type.toLowerCase() !== 'infuze' &&
                    vm.license.product.type.toLowerCase() !== 'insight' &&
                    vm.license.product.type.toLowerCase() !== 'onsite'
                ) {
                    licenseService.setValues($stateParams.id, 'configurationDisabled', false);
                }
                vm.loadCapabilities = true;
            });
        } else {
            vm.loadCapabilities = true;
        }

        function loadLicenseText(license) {
            licenseService.text(license)
                .then(function(response) {
                    vm.license.text = $sce.trustAsHtml(response.data.text);
                    licenseService.setValues($stateParams.id, 'license', vm.license);
                }, function(error) {
                    console.log(error);
                });
        }
    }
})();
