(function() {
    'use strict';

    angular.module('adminApp')
        .controller('SmartTableController', SmartTableController);

    SmartTableController.$inject = ['$rootScope', '$scope', '$state', '$stateParams', '$timeout'];
    function SmartTableController($rootScope, $scope, $state, $stateParams, $timeout) {
        var vm = this;

        vm.blockPagination = true;
        vm.changeSelect = changeSelect;
        vm.columns = $scope.columns;
        vm.columnLength = vm.columns.length - 2;
        vm.data = undefined;
        vm.fullState = $state.current.name;
        vm.includeView = $scope.includeView;
        vm.itemsPerPage = 50;
        vm.itemsPerPageClass = itemsPerPageClass;
        vm.itemPerPageOptions = [10, 25, 50, 100];
        vm.isLoading = true;
        vm.linkClick = linkClick;
        vm.noDataFound = false;
        vm.options = [];
        vm.paginationData = undefined;
        vm.search = $scope.searchParams;
        vm.selectModels = {};
        vm.selectPage = selectPage;
        vm.showPerPageTop = ($scope.tableType === 'witbilling' || $scope.tableType === 'quwurfl') ? false : true;
        vm.state = $state.current.name.split(".")[0];
        vm.tableType = $scope.tableType;
        vm.updatePerPage = updatePerPage;

        var timeout = null;

        var noDataFound = $rootScope.$on($scope.tableType+"NoDataFound", function() {
            vm.isLoading = false;
            vm.noDataFound = true;
            $scope.$on('$destroy', noDataFound);
        });

        var triggerLoading = $rootScope.$on($scope.tableType+"TriggerLoading", function() {
            vm.data = undefined;
            vm.isLoading = true;
            vm.blockPagination = true;
            vm.noDataFound = false;
            $scope.$on('$destroy', triggerLoading);
        });

        var dataLoaded = $rootScope.$on($scope.tableType+"DataLoaded", function(event, args) {
            vm.data = args.data;
            vm.paginationData = args.paginationData;
            if (!_.isNull(vm.paginationData) && !vm.paginationData.hasOwnProperty('colspan')) {
                vm.paginationData.colspan = 8;
            }
            vm.isLoading = false;
            vm.blockPagination = false;
            vm.noDataFound = false;
            vm.itemsPerPage = args.itemsPerPage;
            $scope.$on('$destroy', dataLoaded);
        });

        var updateProductDirective = $rootScope.$on('updateProductDirective', function(event, args) {
            vm.search.product_id = args.id;
            $scope.$on('$destroy', updateProductDirective);
        });

        var updateLicenseTypeDirective = $rootScope.$on('updateLicenseTypeDirective', function(event, args) {
            vm.search.license_type_id = args.id;
            $scope.$on('$destroy', updateLicenseTypeDirective);
        });

        var updateSelect = $rootScope.$on($scope.tableType+"UpdateSelect", function(event, args) {
            vm.options[args.id] = [];
            angular.forEach(args.data, function(val, key) {
                if (!args.keyNeeded) {
                    vm.options[args.id].push({
                        value: val[args.value],
                        name: val[args.show]
                    });
                } else {
                    var value;
                    var name;
                    if (args.value === 'key') {
                        value = key;
                    }
                    if (args.show === 'value') {
                        name = val;
                    }
                    vm.options[args.id].push({
                        value: value,
                        name: name
                    });
                }
            });
            $scope.$on('$destroy', updateSelect);
        });

        $scope.$watch('vm.search', function(newVal, oldVal) {
            // Clear any existing timeouts
            if (timeout !== null) {
                $timeout.cancel(timeout);
            }

            // Give the user a chance to finish typing
            timeout = $timeout(function () {
                var updated = false;
                if (newVal !== undefined || oldVal !== undefined) {
                    angular.forEach(vm.columns, function(val, key) {
                        if (val.search.isSearchable) {
                            if (newVal[val.model] !== oldVal[val.model]) {
                                updated = true;
                            }
                        }
                    });
                    if (updated) {
                        vm.isLoading = true;
                        vm.data = undefined;
                        vm.blockPagination = true;
                        vm.paginationData.stateParams = _.extend(newVal);
                        vm.paginationData.stateParams.page = 1;
                        vm.paginationData.currentPage = 1;
                        $rootScope.$broadcast($scope.tableType+"SearchParamsUpdated", newVal);
                        $state.go(vm.paginationData.currentState, {page: 1}, {notify: false});
                    }
                }
            }, 500);
        }, true);

        function changeSelect(id) {
            vm.search[id] = vm.selectModels[id];
        }

        function itemsPerPageClass(val) {
            var classes = ["btn btn-default"];
            if (val == vm.itemsPerPage) {
                classes.push("active");
            }
            return classes;
        }

        function linkClick(val, state) {
            var goState = vm.state + "." + state;
            $state.go(goState, {id: val});
        }

        function selectPage(page) {
            vm.isLoading = true;
            vm.data = undefined;
            vm.noDataFound = false;
            vm.blockPagination = true;
            vm.search.page = page;
            $rootScope.$broadcast($scope.tableType+"PageChanged", vm.search);
            $state.go(vm.paginationData.currentState, {page: page}, {notify: false});
        }

        function updatePerPage(item) {
            vm.isLoading = true;
            vm.data = undefined;
            vm.noDataFound = false;
            vm.blockPagination = true;
            vm.search.page = 1;
            vm.search.paginate = item;
            vm.itemsPerPage = item;
            $rootScope.$broadcast($scope.tableType+"ItemsPerPageChanged", vm.search);
            $state.go(vm.paginationData.currentState, {page: 1}, {notify: false});
        }
    }
})();
