(function() {
    'use strict';

    angular.module('adminApp')
        .factory('paymentService', paymentService);

    paymentService.$inject = ['$resource']

    function paymentService($resource) {
        var service = {
            getBraintreeAuthorization: getBraintreeAuthorization
        };

        return service;

        function getBraintreeAuthorization() {
            var resouce = $resource('/api/admin/braintree/config', {}, {
                'getBraintreeAuthorization': {method: 'GET'}
            });
            return resouce.getBraintreeAuthorization().$promise;
        }
    }
})();
