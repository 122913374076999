(function() {
    'use strict';

    angular.module('adminApp.licenses')
        .controller('InfuzeProfileController', InfuzeProfileController);

    InfuzeProfileController.$inject = ['$animate', '$scope', '$stateParams', '$timeout', 'infuzeService'];

    function InfuzeProfileController($animate, $scope, $stateParams, $timeout, infuzeService) {
        var vm = this;

        vm.deletingSpinner = false;
        vm.flash = {
            show: false,
            message: "Message",
            type: "success"
        };
        vm.label = $stateParams.type == "infuze" ? "Infuze" : "Build";
        vm.onDeleteProfileClick = onDeleteProfileClick;
        vm.onSaveProfileClick = onSaveProfileClick;
        vm.profile = $scope.profile;
        vm.savingSpinner = false;

        function onDeleteProfileClick(event, profileId) {
            event.stopPropagation();
            event.preventDefault();
            console.log("Attempting to Delete " + profileId);
            vm.deletingSpinner = true;

            infuzeService.deleteProfile(profileId).then(function(response) {
                vm.flash.show = false;
                vm.flash.type = 'success';
                vm.flash.message = "";
                vm.deletingSpinner = false;
                $animate.leave($scope.element, {});
            }).catch(function(error) {
                console.log(error);
                vm.flash.show = true;
                vm.flash.type = 'danger';
                vm.flash.message = error.message;
                vm.deletingSpinner = false;
            });
        }

        function onSaveProfileClick(event, profileId) {
            event.stopPropagation();
            event.preventDefault();

            console.log('Saving ' + profileId);

            vm.flash = {
                show: true,
                message: "Saving...",
                type: "success"
            };

            vm.savingSpinner = true;

            infuzeService.updateProfile(vm.profile)
                .then(function(response) {
                    vm.savingSpinner = false;
                    vm.flash.message = "Save Successful";
                    $timeout(clearFlash, 3000);
                }, function(error) {
                    vm.savingSpinner = false;
                    vm.flash.message = error.data.message;
                    vm.flash.type = 'danger';
                });
        }

        function clearFlash() {
            vm.savingSpinner = false;
            vm.deletingSpinner = false;
            vm.flash.show = false;
        }
    }
})();
