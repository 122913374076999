(function() {
    'use strict';

    angular.module('adminApp.licenses')
        .directive('productSearch', productSearch);

    productSearch.$inject = ['licenseService'];

    function productSearch(licenseService) {
        var directive = {
            restrict: 'E',
            transclude: true,
            replace: true,
            templateUrl: '/view/components.product_select',
            scope: {
                current: '=',
                all: '=',
                type: '=',
                disabled: '='
            },
            controller: 'ProductSearchController',
            controllerAs: 'vm'
        };

        return directive;
    }
})();
