(function() {
    'use strict';

    angular.module('adminApp.cloud')
        .controller('CloudTopUsersController', CloudTopUsersController);

    CloudTopUsersController.$inject = ['breadcrumbsService', 'cloudAccountService', 'Notification'];

    function CloudTopUsersController(breadcrumbsService, cloudAccountService, Notification) {
        var vm = this;

        vm.charts = [];
        vm.data = [];
        vm.loaded = false;
        vm.steps = breadcrumbsService.get('cloud.topusers');

        cloudAccountService.getTopUsers().then(function(response) {
            vm.data = response.data;
            init();
        }).catch(function(error) {
            console.log(error);
            Notification.error("Could not load top WURFL Cloud users");
        });

        function init() {
            angular.forEach(vm.data, function(row, key) {
                var object = {
                    type: "LineChart",
                    displayed: true
                };
                var rows = [];
                angular.forEach(row.hourly_detections, function(iVal, iKey) {
                    var row = {
                        c: [{
                            v: iVal.start_date
                        }, {
                            v: iVal.detections
                        }]
                    };
                    rows.push(row);
                });
                object.data = {
                    "cols": [{
                        id: "date",
                        type: "string"
                    }, {
                        id: "detections",
                        type: "number"
                    }],
                    "rows": rows
                };
                object.options = {
                    "vAxis": {
                        textPosition: 'none',
                        gridlines: {
                            color: 'transparent'
                        },
                        baselineColor: '#FFFFFF'

                    },
                    "hAxis": {
                        textPosition: 'none',
                        gridlines: {
                            color: 'transparent'
                        },
                        baselineColor: '#FFFFFF'
                    },
                    "legend": {
                        position: 'none'
                    },
                    'height': 30
                };
                vm.charts[row.subscription_id] = object;
            });
            vm.loaded = true;
        }
    }
})();
