(function() {
    'use strict';

    angular.module('adminApp.wit')
        .controller('WitPlanChangeController', WitPlanChangeController);

    WitPlanChangeController.$inject = ['$filter', '$ngConfirm', '$ocLazyLoad', '$q', '$rootScope', '$state', '$stateParams', '$uibModal', '$uibModalInstance', 'accountService', 'IE', 'Notification', 'paymentService', 'saasConstants', 'witAccountService', 'witModalService'];

    function WitPlanChangeController($filter, $ngConfirm, $ocLazyLoad, $q, $rootScope, $state, $stateParams, $uibModal, $uibModalInstance, accountService, IE, Notification, paymentService, saasConstants, witAccountService, witModalService) {
        var vm = this;

        var planChange = witAccountService.planChangeStorage('get');

        if (planChange.selectedPlan === null) {
            $uibModalInstance.dismiss();
        }

        vm.action = planChange.action;
        vm.addPayment = false;
        vm.addPaymentDisabled = false;
        vm.cancel = cancel;
        vm.changeStatus = changeStatus;
        vm.checkProvidePayment = checkProvidePayment;
        vm.checkRequired = checkRequired;
        vm.class = {
            usage_limit: 'form-control',
            flat_rate: 'form-control',
            overage_fee: 'form-control',
            trial_days: 'form-control'
        };
        vm.form = angular.copy(planChange.subscription);
        vm.originalSubscription = angular.copy(planChange.subscription);
        vm.running = false;
        vm.save = save;
        vm.selectedPlan = planChange.selectedPlan;
        vm.trialDisabled = trialDisabled;

        vm.form.payment_plan = planChange.selectedPlan;

        var defaultTiers = saasConstants.getConstants('wit', 'paymentPlans');
        var subscription = planChange.subscription;

        var setDefaults = null;
        var setTierIds = null;

        var certificateError = false;
        var certificatePromises = [];

        angular.forEach(defaultTiers, function(val, key) {
            if (val.payment_plan === vm.selectedPlan) {
                vm.form.plan.limit = val.usage_limit;
                vm.form.plan.overage_fee = val.overage_fee;
                vm.form.plan.price = val.flat_rate;
                vm.form.plan.trial_days = val.trial_days;
                vm.form.plan.plan = vm.selectedPlan;
            }
        });
        vm.form.pro_standard = false;
        if (vm.selectedPlan == IE.pro) {
            vm.form.status = 'ACTIVE';
            if (subscription.payment_type == 'STANDARD') {
                vm.form.payment_type = 'EXTERNAL';
            }
        }

        function cancel() {
            $rootScope.$broadcast('enableButtons');
            $uibModalInstance.dismiss();
        }

        function changeStatus() {
            if (!vm.form.pro_standard) {
                vm.form.status = 'ACTIVE';
                if (subscription.payment_type == 'STANDARD') {
                    vm.form.payment_type = 'EXTERNAL';
                }
            } else {
                vm.form.status = angular.copy(subscription.status);
                vm.form.payment_type = angular.copy(subscription.payment_type)
            }
        }

        function checkProvidePayment() {
            var startDate = $filter('createDate')({date: subscription.date_started, format: 'YMD HIS'});
            var today = new Date();
            var newExpireDate = angular.copy(startDate);
            newExpireDate = new Date(newExpireDate.setDate(newExpireDate.getDate() + parseInt(vm.form.plan.trial_days)));
            if (today > newExpireDate && !trialDisabled()) {
                var standardPath = false;
                angular.forEach(subscription.extras, function(val, key) {
                    if (val.type == 'auto_trial') {
                        standardPath = true;
                    }
                });
                if (subscription.payment_plan != IE.pro || standardPath) {
                    vm.addPaymentDisabled = false;
                    return;
                } else {
                    vm.addPaymentDisabled = true;
                    vm.addPayment = true;
                    return;
                }
            }
            vm.addPaymentDisabled = false;
            return;
        }

        function checkRequired() {
            if (vm.selectedPlan !== IE.pro) {
                return true;
            } else if (vm.form.pro_standard) {
                return true;
            }
            return false;
        }

        function generateToken() {
            return paymentService.getBraintreeAuthorization().then(function(response) {
                return response.data.authorization;
            });
        }

        function save() {
            var startDate = $filter('createDate')({date: subscription.date_started, format: 'YMD HIS'});
            var today = new Date();
            var newExpireDate = angular.copy(startDate);
            newExpireDate = new Date(newExpireDate.setDate(newExpireDate.getDate() + parseInt(vm.form.plan.trial_days)));
            if (today > newExpireDate && !trialDisabled()) {
                var standardPath = false;
                angular.forEach(subscription.extras, function(val, key) {
                    if (val.type == 'auto_trial') {
                        standardPath = true;
                    }
                });
                if (subscription.payment_plan != IE.pro || standardPath) {
                    $ngConfirm({
                        title: 'Alert!',
                        theme: 'dark',
                        content: 'The entered Trial Period would cause the trial to end. <br /><br />If this is the desired effect, please complete the plan upgrade, then Activate the account.',
                        buttons: {
                            close: {
                                text: 'OK',
                                btnClass: 'btn-info'
                            }
                        }
                    });
                    vm.form.plan.trial_days = subscription.plan.trial_days;
                    return;
                } else {
                    $ngConfirm({
                        title: 'Confirm',
                        theme: 'dark',
                        content: 'The entered Trial Period would cause the trial to end. <br /><br />Automated billing will begin tomorrow at a rate of $'+vm.form.plan.price+' per month.<br /><br />You will be required to enter payment information on the next page.<br /><br />Do you want to continue?',
                        buttons: {
                            close: {
                                text: 'Cancel',
                                btnClass: 'btn-warning'
                            },
                            proceed: {
                                text: 'Continue',
                                btnClass: 'btn-primary',
                                action: function(buttons) {
                                    var defaults = witAccountService.remapLimits(vm.form, saasConstants.getConstants('wit', 'paymentPlans'), true);
                                    var tierIds = defaults.ids;
                                    delete defaults['ids'];
                                    defaults.flat_rate.value = vm.form.plan.price;
                                    defaults.overage_fee.value = vm.form.plan.overage_fee;
                                    defaults.trial_days.value = vm.form.plan.trial_days;
                                    defaults.usage_limit.value = vm.form.plan.limit;
                                    if (subscription.vault_subscription_id !== null) {
                                        upgrade(defaults, tierIds);
                                    } else {
                                        $uibModalInstance.dismiss();
                                        witModalService.addPaymentInfo(planChange.action, vm.form, false);
                                    }
                                }
                            }
                        }
                    });
                }
                return;
            }

            var message = '';
            if (
                (vm.selectedPlan == IE.basic ||
                vm.selectedPlan == IE.standard) &&
                planChange.action
            ) {
                if (subscription.payment_type == 'STANDARD') {
                    message += "This account will be upgraded to "+vm.form.payment_plan+".";
                    message += "<br /><br />Any overage fees associated with the account at "+subscription.payment_plan+" and a proration fee based on where the account is in its current cycle will be charged at the end of the billing cycle.";
                    message += "<br /><br />The user will be charged a monthly rate of $"+vm.form.plan.price+" starting at the next billing cycle.";
                } else if (subscription.payment_type == 'TRIAL') {
                    message += "This account will be upgraded to "+vm.form.payment_plan+".";
                    message += "<br /><br />The user will be charged a monthly rate of $"+vm.form.plan.price+" starting after the conclusion of the trial period on "+$filter('date')(newExpireDate, "MMM dd, yyyy")+".";
                }
            } else if (planChange.action) {
                if (!vm.form.pro_standard) {
                   message += "This account will be upgraded to "+vm.form.payment_plan+" with external (invoiced) billing.";
                   if (subscription.payment_type == 'TRIAL') {
                       message += "<br /><br />Automated billing will no longer start at the end of the trial period."
                   } else if (subscription.payment_type == 'STANDARD') {
                       message += "<br /><br />Automated billing will be canceled. Any overage fees already occurred for this cycle will be charged.";
                       message += "<br /><br />Proration fees should be charged manually.";
                   }
                   message += "<br /><br />Please note that invoiced payment must be set up manually.";
                } else {
                    $ngConfirm({
                        title: 'Alert!',
                        theme: 'dark',
                        content: 'Standard (automated) billing is not currently available for ImageEngine Pro accounts.<br /><br />If this is required, please contact the development team.',
                        buttons: {
                            close: {
                                text: 'OK',
                                btnClass: 'btn-info'
                            }
                        }
                    });
                    vm.form.payment_type = 'EXTERNAL';
                    vm.form.status = 'ACTIVE';
                    vm.form.pro_standard = false;
                    return;
                    // NOTE Below works, but cron is not set up to handle Pro Standard billing accounts
                    var errors = witAccountService.validateTier(vm.form);
                    if (errors.status) {
                        angular.forEach(vm.class, function(val, key) {
                            if (errors.fields[key]) {
                                vm.class[key] += " error";
                            }
                        });
                        return;
                    }
                    if (vm.form.payment_type == 'STANDARD') {
                        message += "This account will be upgraded to "+vm.form.payment_plan+".";
                        message += "<br /><br />Any overage fees associated with the account at "+subscription.payment_plan+" and a proration fee based on where the account is in its current cycle will be charged at the end of the billing cycle.";
                        message += "<br /><br />The user will be charged a monthly rate of $"+vm.form.plan.price+" starting at the next billing cycle.";
                    } else if (vm.form.payment_type == 'TRIAL') {
                        message += "This account will be upgraded to "+vm.form.payment_plan+".";
                        message += "<br /><br />The user will be charged a monthly rate of $"+vm.form.plan.price+" starting after the conclusion of the trial period on "+$filter('date')(newExpireDate, "MMM dd, yyyy")+".";
                    }
               }
           } else if (!planChange.action) {
               if (subscription.payment_type == 'STANDARD') {
                   message += "This account will be marked for downgrade to "+vm.form.payment_plan+" at the end of its current billing cycle.";
                   message += "<br /><br />The remainder of the billing cycle will remain at its current plan, "+subscription.payment_plan+", and any overage fees will be charged accordingly.";
                   message += "<br /><br />The user will be charged a monthly rate of $"+vm.form.plan.price+" starting at the next billing cycle.";
               } else if (subscription.payment_type == 'TRIAL') {
                   message += "This account will be downgraded to "+vm.form.payment_plan+".";
                   message += "<br /><br />The user will be charged a monthly rate of $"+vm.form.plan.price+" starting after the conclusion of the trial period on "+$filter('date')(newExpireDate, "MMM dd, yyyy")+".";
               } else if (subscription.payment_type == 'EXTERNAL') {
                   message += "This account will be downgraded to "+vm.form.payment_plan+".";
                   if (subscription.vault_subscription_id === null) {
                       message += "<br /><br />You will be required to enter payment information on the next page.";
                       vm.addPayment = true;
                   }
                   message += "<br /><br />The user will be charged an automated monthly rate of $"+vm.form.plan.price+" starting tomorrow.";
               }
           }

            message += "<br /><br />Do you want to continue?";
            $ngConfirm({
                title: 'Confirm',
                theme: 'dark',
                content: message,
                buttons: {
                    close: {
                        text: 'Cancel',
                        btnClass: 'btn-danger'
                    },
                    proceed: {
                        text: 'Continue',
                        btnClass: "btn-primary",
                        action: function(button) {
                            var defaults = witAccountService.remapLimits(vm.form, saasConstants.getConstants('wit', 'paymentPlans'), true);
                            var tierIds = defaults.ids;
                            delete defaults['ids'];
                            defaults.flat_rate.value = vm.form.plan.price;
                            defaults.overage_fee.value = vm.form.plan.overage_fee;
                            defaults.trial_days.value = vm.form.plan.trial_days;
                            defaults.usage_limit.value = vm.form.plan.limit;
                            if (vm.addPayment) {
                                if (vm.form.payment_type == 'EXTERNAL') {
                                    vm.form.payment_type = 'STANDARD';
                                }
                                $uibModalInstance.dismiss();
                                witModalService.addPaymentInfo(planChange.action, vm.form, false);
                            } else {
                                if (vm.selectedPlan === IE.pro && vm.originalSubscription.payment_type == 'STANDARD') {
                                    witAccountService.chargeOverageFees($stateParams.id).then(function(response) {
                                        upgrade(defaults, tierIds);
                                    }).catch(function(error) {
                                        var data = error.data.data;
                                        $ngConfirm({
                                            title: 'Overage Billing Failed!',
                                            theme: 'dark',
                                            contentUrl: 'view/subscriptions.wit.components.overage_fee_issue',
                                            buttons: {
                                                close: {
                                                    text: 'Cancel',
                                                    btnClass: 'btn-danger'
                                                },
                                                proceed: {
                                                    text: 'Proceed',
                                                    btnClass: 'btn-warning',
                                                    action: function(button) {
                                                        upgrade();
                                                    }
                                                }
                                            },
                                            onScopeReady: function(scope) {
                                                var self = this;
                                                scope.allNull = true;
                                                if (data.start_date !== undefined) {
                                                    scope.start_date = data.start_date.date;
                                                    scope.allNull = false;
                                                } else {
                                                    scope.start_date = null;
                                                }
                                                if (data.end_date !== undefined) {
                                                    scope.end_date = data.end_date.date;
                                                    scope.allNull = false;
                                                }
                                                if (data.overage_fees !== undefined) {
                                                    scope.amount = data.overage_fees;
                                                    scope.allNull = false;
                                                }
                                                if (data.total_usage !== undefined) {
                                                    scope.usage = data.total_usage[vm.originalSubscription.payment_plan].amount / 1073741824;
                                                    scope.limit = data.total_usage[vm.originalSubscription.payment_plan].limit;
                                                    scope.overage_fee = data.total_usage[vm.originalSubscription.payment_plan].overage;
                                                    scope.allNull = false;
                                                }
                                            }
                                        });
                                    });
                                } else {
                                    upgrade(defaults, tierIds);
                                }
                            }
                        }
                    }
                }
            });
        }

        function trialDisabled() {
            if (subscription.payment_type == 'STANDARD' || subscription.payment_type == 'EXTERNAL') {
                return true;
            }

            var startDate = $filter('createDate')({date: subscription.date_started, format: 'YMD HIS'});
            var today = new Date();
            var expireDate = angular.copy(startDate);
            expireDate = new Date(expireDate.setDate(expireDate.getDate() + subscription.plan.trial_days));
            if (today > expireDate) {
                return true;
            }

            return false;
        }

        function upgrade(defaults, tierIds) {
            vm.running = true;
            setDefaults = defaults;
            setTierIds = tierIds;
            if (vm.originalSubscription.payment_plan == 'IMAGEENGINE_PRO' || vm.originalSubscription.payment_plan == 'IMAGEENGINE_STANDARD') {
                var certificates = _.flatten(_.pluck(vm.originalSubscription.wit_domain_conf, 'wit_certificate'));
                if (!certificates.length) {
                    runPlanChange();
                } else {
                    angular.forEach(certificates, function(val, key) {
                        certificatePromises.push(
                            witAccountService.patchDeleteCertificate($stateParams.id, val.id, {delete: true, cancel: false})
                                .then(function() {})
                                .catch(function(error) {
                                    certificateError = true;
                                    $q.reject();
                                })
                        );
                    });
                    certificatePromises.push(
                        witAccountService.deleteUnstoredCertificate($stateParams.id, vm.originalSubscription.wit_domain_conf.id)
                            .then(function(response) {})
                            .catch(function(error) {
                                certificateFail = true;
                                $q.reject();
                            })
                    );
                    $q.all(certificatePromises).then(function() {
                        runPlanChange();
                    });
                }
            } else {
                runPlanChange();
            }
        }

        function runPlanChange() {
            witAccountService.upgrade({
                id: $stateParams.id,
                form: vm.form,
                defaults: setDefaults,
                tier_ids: setTierIds,
                action: planChange.action
            }).then(function(response) {
                Notification.success("Successfully changed account");
                if (certificateError) {
                    Notification.warning("SSL certificates were not removed");
                }
                accountService.setValues('wit', 'subscription', undefined);
                $rootScope.$broadcast('upgradeWit');
                $uibModalInstance.dismiss();
            }).catch(function(error) {
                Notification.error("Could not upgrade account");
                if (!certificateError && (vm.originalSubscription.payment_plan === 'IMAGEENGINE_PRO' || vm.originalSubscription.payment_plan === 'IMAGEENGINE_STANDARD')) {
                    Notification.warning("SSL certificates were removed");
                }
                console.log(error);
            }).finally(function() {
                vm.running = false;
            });
        }
    }
})();
