(function() {
    'use strict';

    angular.module('adminApp.cloud')
        .controller('CloudListController', CloudListController);

    CloudListController.$inject = ['breadcrumbsService', 'cloudAccountService', 'subscriptionModalService'];

    function CloudListController(breadcrumbsService, cloudAccountService, subscriptionModalService) {
        var vm = this;

        vm.createNew = createNew;
        vm.download = download;
        vm.service = cloudAccountService;
        vm.steps = breadcrumbsService.get('cloud.list');

        function createNew() {
            subscriptionModalService.createNew('cloud');
        }

        function download() {
            subscriptionModalService.download('cloud');
        }
    }
})();
