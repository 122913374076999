(function() {
    'use strict';

    angular.module('adminApp.wit')
        .controller('WitPaymentController', WitPaymentController);

    WitPaymentController.$inject = ['$rootScope', '$scope', '$state', '$stateParams', '$timeout', '$uibModal', '$uibModalInstance', 'accountService', 'action', 'defaults', 'form', 'isNew', 'Notification', 'tierIds', 'token', 'witAccountService'];

    function WitPaymentController($rootScope, $scope, $state, $stateParams, $timeout, $uibModal, $uibModalInstance, accountService, action, defaults, form, isNew, Notification, tierIds, token, witAccountService) {
        var vm = this;

        vm.cancel = cancel;
        vm.class = {};
        vm.disablePay = true;
        vm.form = angular.copy(form);
        vm.running = false;
        vm.submit = submit;

        var accountResponse = null;
        var certificateResponse = null;
        var isNew = _.isUndefined(isNew) ? false : isNew;
        var show = {
            origin: true,
            domain: true,
            certificate: true,
            resource: true
        };

        var formFields = [
            'first_name',
            'last_name',
            'address',
            'city',
            'state',
            'zip',
            'country_id'
        ];
        var loadedInstance = null;

        angular.forEach(formFields, function(val) {
            if (val !== 'country_id') {
                vm.class[val] = "form-control";
            } else {
                vm.class[val] = "form-group col-md-6";
            }
        });


        braintree.dropin.create({
            authorization: token,
            container: '#payment-form',
            card: {
                cardholderName: {
                    required: true
                }
            }
        }, function(createErr, instance) {
            $timeout(function() {
                vm.disablePay = false;
            }, 1);
            loadedInstance = instance;
        });

        function cancel() {
            $uibModalInstance.dismiss();
        }

        function finish() {
            vm.running = false;
            $uibModalInstance.dismiss();
            $uibModal.open({
                templateUrl: 'view/subscriptions.wit.components.ssl',
                controller: function($uibModalInstance, accountResponse, certificateResponse, show) {
                    var vm = this;

                    vm.close = close;
                    vm.accountResponse = accountResponse;
                    vm.certificateResponse = certificateResponse;
                    vm.show = show;

                    function close() {
                        $uibModalInstance.close();
                    }
                },
                size: 'lg',
                resolve: {
                    accountResponse: function() {
                        return accountResponse;
                    },
                    certificateResponse: function() {
                        return certificateResponse;
                    },
                    show: function() {
                        return show;
                    }
                },
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false
            }).result.then(function() {
                $state.go('wit.view', {id: accountResponse.subscription.id});
            });
        }

        function submit() {
            if (vm.form.country_id === undefined) {
                vm.form.country_id = 230;
            } else {
                vm.form.country_id = vm.form.country_id.id;
            }
            var errors = false;
            angular.forEach(formFields, function(val) {
                if (
                    vm.form[val] === '' ||
                    vm.form[val] === null ||
                    vm.form[val] === undefined
                ) {
                    vm.class[val] += ' error';
                    errors = true;
                }
            });

            if ($scope.paymentForm.$valid && !errors) {
                loadedInstance.requestPaymentMethod(function(requestPaymentMethodErr, payload) {
                    if (payload !== undefined) {
                        vm.form.nonce = payload.nonce;
                        if (defaults === null && tierIds === null) {
                            if (action !== null) {
                                switch (action) {
                                    case 'swap':
                                        var path = 'swap';
                                        break;
                                    default:
                                        var path = 'activate';
                                        break;
                                }
                            } else {
                                var path = 'payment';
                            }
                            vm.running = true;
                            if (!isNew) {
                                witAccountService.update(vm.form, path).then(function(response) {
                                    accountService.setValues('wit', 'subscription', undefined);
                                    $rootScope.$broadcast('reloadWit', {data: response.data});
                                    $uibModalInstance.dismiss();
                                }).catch(function(error) {
                                    console.log(error);
                                    Notification.error('Could not update account');
                                }).finally(function() {
                                    vm.running = false;
                                });
                            } else {
                                witAccountService.create(vm.form).then(function(response) {
                                    accountResponse = response.data;
                                    // Trial account succeeded
                                    // Get the regions
                                    var records = [];
                                    witAccountService.getAwsRegionsByAccount(response.data.subscription.id).then(function(response) {
                                        var regions = response.data;
                                        angular.forEach(regions, function(val, key) {
                                            if (val.Deploy !== 'provisioning') {
                                                records.push({
                                                    domain: vm.form.domain_name,
                                                    region: val.RegionName,
                                                    type: 'A'
                                                });
                                            }
                                        });
                                        witAccountService.postResourceRecords(accountResponse.subscription.id, records)
                                            .then(function() {})
                                            .catch(function(error) {
                                                show.resource = false;
                                            }).finally(function() {
                                                finish();
                                            });
                                    });
                                }).catch(function(error) {
                                    console.log(error);
                                    switch (error.status) {
                                        case 412:
                                            angular.forEach(error.data.data, function(val, key) {
                                                Notification.error(val);
                                            });
                                            break;
                                        default:
                                            Notification.error(error.data.message);
                                            if (!_.isUndefined(error.data.data.errors)) {

                                                if (_.indexOf(error.data.data.errors, "domain configuration") != -1) {
                                                    show.domain = false;
                                                }

                                                if (_.indexOf(error.data.data.errors, "origin") != -1) {
                                                    show.origin = false;
                                                }
                                                finish();
                                            }
                                    }
                                });
                            }
                        } else {
                            witAccountService.upgrade({
                                id: $stateParams.id,
                                form: vm.form,
                                defaults: defaults,
                                tier_ids: tierIds,
                                action: action
                            }).then(function(response) {
                                Notification.success("Successfully changed account plan");
                                accountService.setValues('wit', 'subscription', undefined);
                                $rootScope.$broadcast('upgradeWit');
                                $uibModalInstance.dismiss();
                            }).catch(function(error) {
                                Notification.error("Could not upgrade account");
                                console.log(error);
                            });
                        }
                    }
                });
            }
        }
    }
})();
