(function() {
    'use strict';

    angular.module('adminApp.users')
        .controller('UsersPlatformsController', UsersPlatformsController);

    UsersPlatformsController.$inject = ['$rootScope', '$stateParams', '$uibModalInstance', 'Notification', 'platforms', 'userPlatforms', 'userService'];

    function UsersPlatformsController($rootScope, $stateParams, $uibModalInstance, Notification, platforms, userPlatforms, userService) {
        var vm = this;

        vm.close = close;
        vm.form = {
            platforms: userPlatforms
        };
        vm.modalOptions = {
            closeButtonText: 'Cancel',
            okButtonText: 'Save',
            template: 'view/users.modals.platforms',
            title: 'Update Development Platforms'
        };
        vm.platforms = platforms;
        vm.platformSelected = platformSelected;
        vm.submit = submit;
        vm.togglePlatforms = togglePlatforms;

        function close() {
            $uibModalInstance.dismiss();
        }

        function platformSelected(id) {
            if (vm.form.platforms.indexOf(id) !== -1) {
                return true;
            }
            return false;
        }

        function submit() {
            vm.spinner = true;
            vm.errors = [];
            userService.updateUserPlatforms($stateParams.id, vm.form.platforms).then(function(response) {
                Notification.success("Successfully updated platforms!");
                $uibModalInstance.dismiss();
                $rootScope.$broadcast('reloadUser');
            }).catch(function(error) {
                Notification.error("An error occurred while updating platforms");
                console.log(error);
            }).finally(function() {
                vm.spinner = false;
            });
        }

        function togglePlatforms(id) {
            if (vm.form.platforms.indexOf(id) === -1) {
                vm.form.platforms.push(id);
            } else {
                var index = vm.form.platforms.indexOf(id);
                vm.form.platforms.splice(index, 1);
            }
        }
    }

})();
