(function() {
    'use strict';

    angular.module('adminApp.users')
        .directive('userAc', userAc);

    userAc.$inject = ['$compile', 'userService'];

    function userAc($compile, userService) {
        var directive = {
            restrict: 'E',
            transclude: true,
            replace: true,
            template: '<input type="text" autocomplete="off" ng-disabled="disable" uib-typeahead="option as option.name for option in users | filter:$viewValue | limitTo:10" typeahead-min-length="0">',
            link: link
        };

        return directive;

        function link(scope, element, attrs) {
            scope.disable = true;
            scope.users = [];

            element.attr("placeholder", attrs.preholder);
            element.attr("class", attrs.class);

            userService.getOptions().then(function(response) {
                scope.users = response.data;
                element.attr('placeholder',attrs.placeholder);

                scope.disable = false;
                userService.broadcastDirectiveLoaded(scope.users);
            }).catch(function(error) {
                element.attr('placeholder','Action not permitted');
            });
        }
    }
})();
