(function() {
    'use strict';

    angular.module('adminApp.wjs')
        .controller('WjsBillingController', WjsBillingController);

    WjsBillingController.$inject = ['$rootScope', '$state', '$stateParams', 'accountService', 'baseAccountService', 'Notification', 'wjsAccountService'];

    function WjsBillingController($rootScope, $state, $stateParams, accountService, baseAccountService, Notification, wjsAccountService) {
        var vm = this;

        vm.payments = accountService.getValues('wjs', 'payments');

        var subscription = accountService.getValues('wjs', 'subscription');

        if (subscription === undefined) {
            baseAccountService.get(wjsAccountService.resourceUrl, $stateParams.id)
                .then(function(response) {
                    subscription = response.data.account;
                    accountService.setValues('wjs', 'subscription', subscription);
                    accountService.setValues('wjs', 'otherAccounts', response.data.other_accounts);
                    accountService.setValues('wjs', 'plans', response.data.plans);
                    accountService.setValues('wjs', 'defaults', baseAccountService.remapLimits(subscription.wurfl_js_accounts_defaults, 'wurfl_js_default'));
                    accountService.setValues('wjs', 'billingInfoAvailable',
                        baseAccountService.isFree(subscription) === false && baseAccountService.isStandardBilling(subscription));
                    $rootScope.$broadcast('updateWjsSubscription');
                    initBillingHistory(subscription);
                }, function(error) {
                    console.log(error);
                    Notification.error("The account could not be loaded");
                });
        } else {
            if (vm.payments === undefined) {
                initBillingHistory(subscription);
            }
        }

        function initBillingHistory(subscription) {
            if (baseAccountService.isFree(subscription) || baseAccountService.isStandardBilling(subscription) === false) {
                var message;

                if (baseAccountService.isFree(subscription)) {
                    message = 'The Account is free. No billing information is available';
                } else {
                    message = 'The Account has not been set up with standard billing.';
                }

                Notification.warning(message);
                accountService.setValues('wjs', 'billingInfoAvailable', false);
                $rootScope.$broadcast('changeWjsTab', {tab: 0});
                $state.go('wjs.view', $stateParams);
            } else {
                baseAccountService.billingHistory(wjsAccountService.resourceUrl, $stateParams.id)
                    .then(function(response) {
                        vm.payments = response.data;
                        accountService.setValues('wjs', 'payments', vm.payments);
                    }, function(error) {
                        console.log(error);
                        Notification.error(error.data.message);
                        accountService.setValues('wjs', 'billingInfoAvailable', false);
                        $rootScope.$broadcast('changeWjsTab', {tab: 0});
                        $state.go('wjs.view', $stateParams);
                    });
            }
        }
    }
})();
