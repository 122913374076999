(function() {
    'use strict';

    angular.module('adminApp')
        .factory('activityLogService', activityLogService);

    activityLogService.$inject = ['$resource', '$rootScope'];

    function activityLogService($resource, $rootScope) {
        var resourceUrl = "";
        var actions = {
            getWurflDownloads: {
                url: "/api/admin/activity-log/wurfl-downloads/:userId",
                method: "GET",
                cache: true
            }
        };

        var Api = $resource(resourceUrl, {}, actions);

        var service = {
            getWurflDownloads: getWurflDownloads
        };

        return service;

        function getWurflDownloads(userId) {
            return Api.getWurflDownloads({userId:userId});
        }
    }
})();
