(function() {
    'use strict';

    angular.module('adminApp.coreapi')
        .controller('ClientsTableController', ClientsTableController);

    ClientsTableController.$inject = ['$rootScope', '$timeout', '$filter', '$state', 'breadcrumbsService', 'coreApiClientService', 'coreApiModalService', 'Notification', 'NgTableParams'];

    function ClientsTableController($rootScope, $timeout, $filter, $state, breadcrumbsService, coreApiClientService, coreApiModalService, Notification, NgTableParams) {
        var vm = this;

        vm.createNew = createNew;
        vm.deleteClient = deleteClient;
        vm.edit = edit;
        vm.editScopes = editScopes;
        vm.isLoading = true;
        vm.steps = breadcrumbsService.get('coreapi.home');
        vm.tableParams = new NgTableParams({
            page: 1,
            count: 10,
            sorting: {
                name: "asc"
            }
        }, {
            total: 0,
            getData: function ($defer, params) {
                getData($defer, params)
            }
        });

        $rootScope.$on('reloadCoreapiClients', function() {
            vm.isLoading = true;
            vm.tableParams = new NgTableParams({
                page: 1,
                count: 10,
                sorting: {
                    name: "asc"
                }
            }, {
                total: 0,
                getData: function ($defer, params) {
                    getData($defer, params)
                }
            });
        });

        function createNew() {
            coreApiModalService.create();
        }

        function deleteClient(id) {
            coreApiModalService.deleteClient(id);
        }

        function edit(client) {
            coreApiModalService.edit(client);
        }

        function editScopes(id) {
            coreApiModalService.editClientScopes(id);
        }

        function getData($defer, params) {
            coreApiClientService.all()
                .then(function(response) {
                    $timeout(function() {
                        var collection = response.data;
                        var total = collection.length;
                        var ordered = params.sorting() ? $filter('orderBy')(collection, params.orderBy()) : collection;
                        params.total(total);
                        $defer.resolve(ordered.slice((params.page() - 1) * params.count(), params.page() * params.count()));
                        vm.isLoading = false;
                    }, 500);
                }, function(error) {
                    switch (error.status) {
                        case 400:
                            Notification.error(error.data.message);
                            break;
                        case 403:
                            Notification.error('You do not have access to that page');
                            $state.go('home', {});
                            break;
                        default:
                            Notification.error('An unexpected error occurred');
                            console.log(error);
                            break;
                    }
                    vm.isLoading = false;
                });
        }
    }
})();
