(function() {
    'use strict';

    angular.module('adminApp.external')
        .controller('ExternalVaultController', ExternalVaultController);

    ExternalVaultController.$inject = ['$location', '$rootScope', '$state', '$stateParams', 'endSession', 'externalService', 'loginService', 'transferFlash'];

    function ExternalVaultController($location, $rootScope, $state, $stateParams, endSession, externalService, loginService, transferFlash) {
        if (_.isNull($stateParams.session)) {
            runError();
            return;
        }

        var session = $stateParams.session;
        $location.search('session', null);
        var authInfo = angular.copy(loginService.authInfo);

        deauthenticate();

        externalService.postCheckUserVault(session, authInfo);

        function deauthenticate() {
            loginService.authInfo.authenticated = false;
            loginService.authInfo.user = null;
            loginService.intended = undefined;
            endSession.end();
        }

        function runError() {
            deauthenticate();
            $rootScope.$broadcast('authenticated');

            transferFlash.set('message', "Unable to log in with the provided credentials. Please try again.");
            transferFlash.set('type', 'danger');

            $state.go('login');
            return;
        }
    }
})();
