(function() {
    'use strict';

    angular.module('adminApp.users')
        .controller('TeamListController', TeamListController);

    TeamListController.$inject = ['$rootScope', '$scope', '$state', '$stateParams', '$location', 'teamService', 'teamModalService', 'breadcrumbsService', 'Notification', 'paginationService'];
    function TeamListController($rootScope, $scope, $state, $stateParams, $location, teamService, teamModalService, breadcrumbsService, Notification, paginationService) {
        var vm = this;

        var allData = null;
        var paginationData = null;
        var numPages = null;
        var number = null;

        vm.columns = [
            {
                name: 'ID',
                search: {
                    isSearchable: false
                },
                model: 'id',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: 'Name',
                search: {
                    isSearchable: true,
                    type: 'text'
                },
                model: 'name',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: 'Partner',
                search: {
                    isSearchable: false,
                    type: 'bool'
                },
                model: 'partner',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: 'Reseller',
                search: {
                    isSearchable: false,
                    type: 'bool'
                },
                model: 'reseller',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: '',
                search: {
                    isSearchable: false
                },
                model: '',
                value: {
                    fromData: false,
                },
                display: true
            }
        ];

        vm.createNew = createNew;
        vm.edit = edit;
        
        vm.search = {};
        vm.steps = breadcrumbsService.get($state.current.name);

        var currentPage = $stateParams.page;
        var search_params = _.clone($location.search());

        $rootScope.$on('reloadTeams', function(event, args) {
            if (args && args.page === undefined) {
                getTeams($stateParams);
            } else {
                getTeams(args);
            }
        });

        getTeams();

        var teamsSearchParamsUpdated = $rootScope.$on("teamsSearchParamsUpdated", function(event, args) {
            vm.search = args;
            currentPage = 1;
            getTeams(args);
        });

        var teamsPageChanged = $rootScope.$on("teamsPageChanged", function(event, args) {
            vm.search = args;
            currentPage = args.page;
            getTeams(args);
        });

        var teamsItemsPerPageChanged = $rootScope.$on("teamsItemsPerPageChanged", function(event, args) {
            vm.search = args;
            currentPage = 1;
            getTeams(args);
        });

        function getTeams(searchParams) {
            searchParams = typeof searchParams !== 'undefined' ? searchParams : null;
            var start = 0;
            number = 10;

            var query_params = {};
            var next_page = 1;

            if (searchParams !== null) {
                next_page = searchParams.page;
                number = searchParams.paginate;
            } else if (search_params && _.isUndefined(search_params.page) === false) { // if defined
                next_page = search_params.page;
            } else {
                next_page = Math.floor(start/number) + 1;
            }

            query_params = {page: next_page};
            if (searchParams !== null) {
                query_params = _.extend(searchParams);
            }

            teamService.all(query_params).then(function(response) {
                allData = response.data;
                paginationData = {};
                numPages = response.pagination.last_page;

                paginationData.pages = paginationService.createPages(currentPage, numPages);
                paginationData.numPages = numPages;
                paginationData.currentPage = currentPage;
                paginationData.currentState = $state.current.name;
                paginationData.stateParams = $stateParams;
                paginationData.from = paginationService.from(response.pagination);
                paginationData.to = paginationService.to(response.pagination);
                paginationData.total = response.pagination.total;
                $rootScope.$broadcast('teamsDataLoaded', {
                    data: response.data,
                    paginationData: paginationData,
                    itemsPerPage: number
                });
                $scope.$on('$destroy', teamsSearchParamsUpdated);
                $scope.$on('$destroy', teamsPageChanged);
                $scope.$on('$destroy', teamsItemsPerPageChanged);
            }, function(error) {
                console.error(error);
                Notification.error("Could not fetch the teams records");
                $scope.$on('$destroy', teamsSearchParamsUpdated);
                $scope.$on('$destroy', teamsPageChanged);
            });
        }

        function createNew() {
            teamModalService.createNew();
        }

        function edit(id) {
            teamModalService.edit(id);
        }
    }
})();