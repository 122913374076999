(function() {
    'use strict';

    angular.module('adminApp.wurfl')
        .controller('CapabilityPickerController', CapabilityPickerController);

    CapabilityPickerController.$inject = ['$ngConfirm', '$rootScope', '$scope', '$stateParams', 'accountService', 'baseAccountService', 'capabilitiesService', 'cloudAccountService', 'licenseService', 'Notification', 'quwurflAccountService'];

    function CapabilityPickerController($ngConfirm, $rootScope, $scope, $stateParams, accountService, baseAccountService, capabilitiesService, cloudAccountService, licenseService, Notification, quwurflAccountService) {
        var vm = this;

        vm.accountCapabilities = !_.isUndefined(vm.accountCapabilities)
            ? vm.accountCapabilities : [];
        vm.addCapability = addCapability;
        vm.allCapabilities = [];
        vm.capability = {
            name: null,
            description: null
        };
        vm.capabilities = [];
        vm.capabilitiesLeft = null;
        vm.changeGroup = changeGroup;
        vm.changeMax = changeMax;
        vm.data = vm.data;
        vm.error = false;
        vm.exists = false;
        vm.capGroups = [];
        vm.loading = false;
        vm.maxCapabilities = vm.max;
        vm.maxDisabled = vm.type == 'wjs' || vm.type == 'cloud' || vm.type == 'quwurfl' ? true : false;
        vm.removeCapability = removeCapability;
        vm.search = null;
        vm.selectedGroup = null;
        vm.showDescription = showDescription;

        var capabilityGroups = [];
        var type = vm.type;
        var defaults = accountService.getValues('cloud', 'defaults')

        if (type == 'profile') {
            if (vm.maxCapabilities == 0) {
                vm.maxCapabilities = 'unlimited';
            }
            licenseService.insightCapabilityGroups("docs").then(function(response) {
                gatherCapabilities(response.data[0].wurfl_capabilities);
            }).catch(function(error) {
                console.log(error);
            });
        } else if (type == 'license') {
            if (vm.maxCapabilities == -1) {
                vm.maxCapabilities = 'unlimited';
            }
            capabilitiesService.getLicenseCapabilities().then(function(response) {
                gatherCapabilities(response.data);
            }).catch(function(error) {
                console.log(error);
            });
        } else if (type == 'cloud') {
            if (vm.maxCapabilities == -1) {
                vm.maxCapabilities = 'unlimited';
            }
            capabilitiesService.getSaasCapabilities().then(function(response) {
                angular.forEach(response.data, function(val, key) {
                    val.name = val.capability;
                });
                gatherCapabilities(response.data);
            }).catch(function(error) {
                console.log(error);
            });
        } else if (type == 'wjs') {
            vm.maxCapabilities = 'N/A';

            capabilitiesService.getSaasCapabilities().then(function(response) {
                angular.forEach(response.data, function(val, key) {
                    val.name = val.capability;
                });
                gatherCapabilities(response.data);
            }).catch(function(error) {
                console.log(error);
            });
        } else if (type == 'quwurfl') {
            vm.maxCapabilities = 'N/A';
            capabilitiesService.getLicenseCapabilities().then(function(response) {
                gatherCapabilities(response.data);
            }).catch(function(error) {
                console.log(error);
            });
        }
        switch (vm.maxCapabilities) {
            case 'unlimited':
                vm.capabilitiesLeft = 'unlimited';
                break;
            case 'N/A':
                vm.capabilitiesLeft = 'N/A';
                break;
            default:
                vm.capabilitiesLeft = vm.maxCapabilities - vm.accountCapabilities.length;
                break;
        }

        function gatherCapabilities(response) {
            var group_ids = [];
            var groups = [];
            vm.allCapabilities = response;

            angular.forEach(response, function(val, key) {
                var inArray = _.findWhere(group_ids, {id: val.group_id});
                if (inArray === undefined) {
                    groups[val.group_id] = {name: val.group.group_name, capabilities: _.filter(response, {
                        group_id: val.group_id
                    })};
                    group_ids.push({id: val.group_id});
                }
            });

            for (var i = 0; i < groups.length; i++) {
                if (groups[i]) {
                    capabilityGroups.push(groups[i]);
                }
            }
            angular.forEach(capabilityGroups, function(val, key) {
                vm.capGroups.push(val.name);
            });
        }

        $scope.$watch('vm.search', function(newVal, oldVal) {
            if (newVal !== null) {
                if (newVal == '') {
                    vm.capabilities = [];
                } else {
                    vm.capabilities = _.filter(vm.allCapabilities, function(obj){
                        return obj.name.indexOf(newVal) !== -1;
                    });
                }
                vm.selectedGroup = null;
            }
        });

        $scope.$watch('vm.selectedGroup', function(newVal, oldVal) {
            if (newVal !== null) {
                changeGroup();
            }
        });

        function add(capability, success) {
            if (success) {
                var input = angular.copy(capability);
                input.capability = input.name;
                vm.accountCapabilities.push(input);
                if (vm.capabilitiesLeft !== 'unlimited' && vm.capabilitiesLeft !== 'N/A') {
                    vm.capabilitiesLeft--;
                }
                vm.originalCapabilities = angular.copy(vm.accountCapabilities);
            } else {
                vm.error = true;
                vm.accountCapabilities = angular.copy(vm.originalCapabilities);
            }
        }

        function addCapability(capability) {
            vm.loading = true;
            if (_.findWhere(vm.accountCapabilities, {capability: capability.name}) !== undefined) {
                vm.loading = false;
                return;
            }
            if (vm.capabilitiesLeft == 0) {
                vm.loading = false;
                return;
            }
            var capabilities = angular.copy(vm.accountCapabilities);

            capabilities.push(capability);
            if (type == 'profile') {
                licenseService.insight_capabilities({license_id: vm.data.licenseId, id: vm.data.profileId}, capabilities).then(function(response) {
                    add(capability, true);
                }).catch(function(error) {
                    console.log(error);
                    add(capability, false);
                }).finally(function(error) {
                    vm.loading = false;
                });
            } else if (type == 'license') {
                licenseService.updateCapabilities($stateParams.id, capabilities).then(function(response) {
                    add(capability, true);
                }).catch(function(error) {
                    console.log(error);
                    add(capability, false);
                }).finally(function() {
                    vm.loading = false;
                });
            } else if (type == 'cloud') {
                cloudAccountService.updateCapabilities($stateParams.id, capabilities).then(function(response) {
                    add(capability, true);
                }).catch(function(error) {
                    console.log(error);
                    add(capability, false);
                }).finally(function() {
                    vm.loading = false;
                });
            } else if (type == 'wjs') {
                var names = [];
                angular.forEach(capabilities, function(val, key) {
                    names.push(val.capability);
                });
                $rootScope.$broadcast('updateCapabilities', {capabilities: names.join(',')});
                $rootScope.$on('finishedUpdateCapabilities', function(event, args) {
                    add(capability, args.success);
                    vm.loading = false;
                });
            } else if (type == 'quwurfl') {
                quwurflAccountService.addCapability($stateParams.id, capability.id).then(function(response) {
                    add(capability, true);
                }).catch(function(error) {
                    console.log(error);
                    add(capability, false);
                }).finally(function() {
                    vm.loading = false;
                });
            }
        }

        function changeGroup() {
            vm.capabilities = _.findWhere(capabilityGroups, {name: vm.selectedGroup}).capabilities;
            vm.search = null;
        }

        function changeMax() {
            $ngConfirm({
                title: 'Max Capabilities',
                theme: 'dark',
                contentUrl: 'view/components.max_capabilities',
                buttons: {
                    close: {
                        text: 'Cancel',
                        btnClass: 'btn-warning'
                    },
                    proceed: {
                        text: 'Save',
                        btnClass: 'btn-primary',
                        action: function(scope) {
                            if (scope.max !== scope.unlimited && vm.accountCapabilities.length > scope.max) {
                                scope.error = true;
                                return false;
                            }
                            vm.loading = true;
                            if (type == 'profile') {
                                licenseService.profile({license_id: vm.data.licenseId, id: vm.data.profileId}, {max_capabilities: scope.max}).then(function(response) {
                                    vm.maxCapabilities = scope.max == 0 ? 'unlimited' : scope.max;
                                    vm.capabilitiesLeft = scope.max == 0 ? 'unlimited' : vm.maxCapabilities - vm.originalCapabilities.length;
                                }).catch(function(error) {
                                    console.log(error);
                                    Notification.error("Could not update max capabilities");
                                    vm.accountCapabilities = angular.copy(vm.originalCapabilities);
                                }).finally(function() {
                                    vm.loading = false;
                                });
                            } else if (type == 'license') {
                                licenseService.update({id: $stateParams.id, max_capabilities: scope.max}).then(function(response) {
                                    vm.maxCapabilities = scope.max == -1 ? 'unlimited' : scope.max;
                                    vm.capabilitiesLeft = scope.max == -1 ? 'unlimited' : vm.maxCapabilities - vm.originalCapabilities.length;
                                    Notification.success('Updated maximum capabilities');
                                }).catch(function(error) {
                                    Notification.error('An error occurred while updating maximum capabilities');
                                    console.log(error);
                                }).finally(function() {
                                    vm.loading = false;
                                });
                            } else if (type == 'cloud') {
                                defaults.capabilities.value = scope.max;
                                var service = baseAccountService.getService('cloud');
                                baseAccountService.updateDefaults(service.resourceUrl, $stateParams.id, _.values(defaults))
                                    .then(function(response) {
                                        if (response.debug && response.debug.length) {
                                            Notification.warning({message: response.message, priority: 2});
                                            Notification.error({message: response.debug, priority: 2});
                                        } else {
                                            vm.maxCapabilities = scope.max == -1 ? 'unlimited' : scope.max;
                                            vm.capabilitiesLeft = scope.max == -1 ? 'unlimited' : vm.maxCapabilities - vm.originalCapabilities.length;
                                        }
                                    }, function(error) {
                                        Notification.error(error.message);
                                        console.log(error);
                                    }).finally(function() {
                                        vm.loading = false;
                                    });
                            }
                        }
                    }
                },
                onScopeReady: function(scope) {
                    var self = this;
                    scope.count = count;
                    scope.error = false;
                    scope.max = null;
                    scope.unlimited = vm.type === 'profile' ? 0 : -1;
                    if (vm.maxCapabilities == 'unlimited') {
                        scope.max = scope.unlimited;
                    } else {
                        scope.max = parseInt(angular.copy(vm.maxCapabilities));
                    }

                    function count() {
                        if (vm.accountCapabilities.length > scope.max && scope.max !== scope.unlimited) {
                            scope.error = true;
                        } else {
                            scope.error = false;
                        }
                    }
                }
            });
        }

        function remove(capabilities, success) {
            if (success) {
                vm.accountCapabilities = capabilities;
                if (vm.capabilitiesLeft !== 'unlimited' && vm.capabilitiesLeft !== 'N/A') {
                    vm.capabilitiesLeft++;
                }
                vm.originalCapabilities = angular.copy(vm.accountCapabilities);
            } else {
                vm.error = true;
                vm.accountCapabilities = angular.copy(vm.originalCapabilities);
            }
        }

        function removeCapability(id) {
            vm.loading = true;
            vm.error = false;
            var capabilities = _.without(vm.accountCapabilities, _.findWhere(vm.accountCapabilities, {
                id: id
            }));

            if (type == 'profile') {
                licenseService.insight_capabilities({license_id: vm.data.licenseId, id: vm.data.profileId}, capabilities).then(function(response) {
                    remove(capabilities, true);
                }).catch(function(error) {
                    console.log(error);
                    remove(capabilities, false);
                }).finally(function() {
                    vm.loading = false;
                });
            } else if (type == 'license') {
                licenseService.updateCapabilities($stateParams.id, capabilities).then(function(response) {
                    remove(capabilities, true);
                }).catch(function(error) {
                    remove(capabilities, false);
                    console.log(error);
                }).finally(function() {
                    vm.loading = false;
                });
            } else if (type == 'cloud') {
                cloudAccountService.updateCapabilities($stateParams.id, capabilities).then(function(response) {
                    remove(capabilities, true);
                }).catch(function(error) {
                    remove(capabilities, false);
                    console.log(error);
                }).finally(function() {
                    vm.loading = false;
                });
            } else if (type == 'wjs') {
                var names = [];
                angular.forEach(capabilities, function(val, key) {
                    names.push(val.capability);
                });
                $rootScope.$broadcast('updateCapabilities', {capabilities: names.join(',')});
                $rootScope.$on('finishedUpdateCapabilities', function(event, args) {
                    remove(capabilities, args.success);
                    vm.loading = false;
                });
            } else if (type == 'quwurfl') {
                quwurflAccountService.deleteCapability($stateParams.id, id).then(function(response) {
                    remove(capabilities, true);
                }).catch(function(error) {
                    console.log(error);
                    remove(capabilities, false);
                }).finally(function() {
                    vm.loading = false;
                });
            }
        }

        function showDescription(name) {
            vm.capability.name = name;
            var capability = _.findWhere(vm.capabilities, {name: name});
            vm.capability.description = capability.description;
            if (_.findWhere(vm.originalCapabilities, {capability: name}) !== undefined) {
                vm.exists = true;
            } else {
                vm.exists = false;
            }
        }
    }
})();
