(function() {
    'use strict';

    angular.module('adminApp.wjs')
        .controller('WjsListController', WjsListController);

    WjsListController.$inject = ['breadcrumbsService', 'subscriptionModalService', 'wjsAccountService'];
    function WjsListController(breadcrumbsService, subscriptionModalService, wjsAccountService) {
        var vm = this;

        vm.createNew = createNew;
        vm.download = download;
        vm.service = wjsAccountService;
        vm.steps = breadcrumbsService.get('wjs.list');

        function createNew() {
            subscriptionModalService.createNew('wjs');
        }

        function download() {
            subscriptionModalService.download('wjs');
        }
    }
})();
