(function() {
    'use strict';

    angular.module('adminApp.licenses')
        .controller('ProductSearchController', ProductSearchController);

    ProductSearchController.$inject = ['$rootScope', '$scope', 'licenseService'];

    function ProductSearchController($rootScope, $scope, licenseService) {
        var vm = this;

        vm.productDisable = true;
        vm.products = null;
        vm.selectedOption = null;
        vm.shouldShow = shouldShow;
        vm.updateProduct = updateProduct;

        run();

        function run() {
            if (_.isNull(licenseService.allProducts)) {
                vm.productDisable = true;
                licenseService.getProductOptions().then(function(response) {
                    vm.products = response.data;
                    if (!_.isNull($scope.type) && !_.isUndefined($scope.type)) {
                        vm.products = _.where(vm.products, {type: $scope.type});
                    }
                    licenseService.allProducts = angular.copy(response.data);
                    if (!_.isUndefined($scope.current)) {
                        var product_id = $scope.current.product_id;
                        vm.selectedOption = _.findWhere(vm.products, {id: product_id}).id;
                    }
                    if (!$scope.disabled) {
                        vm.products = _.map(vm.products, function(obj) {
                            obj.active = 1;
                            return obj;
                        });
                    }
                    $rootScope.$broadcast('loadedProductDirective');
                    vm.productDisable = false;
                });
            } else {
                vm.products = licenseService.allProducts;
                if (!_.isNull($scope.type) && !_.isUndefined($scope.type)) {
                    vm.products = _.where(vm.products, {type: $scope.type});
                }
                if (!_.isUndefined($scope.current)) {
                    var product_id = $scope.current.product_id;
                    vm.selectedOption = _.findWhere(vm.products, {id: product_id}).id;
                }
                if (!$scope.disabled) {
                    vm.products = _.map(vm.products, function(obj) {
                        obj.active = 1;
                        return obj;
                    });
                }
                $rootScope.$broadcast('loadedProductDirective');
                vm.productDisable = false;
            }
        }

        function shouldShow(option) {
            if ($scope.all) {
                return true;
            }

            return option.active;
        }

        function updateProduct() {
            $rootScope.$broadcast('updateProductDirective', {id: vm.selectedOption});
        }
    }
})();
