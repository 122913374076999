(function() {
    'use strict';

    angular.module('adminApp.wit')
        .controller('WitViewBillingController', WitViewBillingController);

    WitViewBillingController.$inject = ['$filter', '$rootScope', '$state', '$stateParams', 'accountService', 'baseAccountService', 'Notification', 'witAccountService'];

    function WitViewBillingController($filter, $rootScope, $state, $stateParams, accountService, baseAccountService, Notification, witAccountService) {
        var vm = this;

        vm.billsData = accountService.getValues('wit', 'billsData');
        vm.currentStats = accountService.getValues('wit', 'currentStats');
        vm.open = false;
        vm.payments = accountService.getValues('wit', 'payments');

        vm.currentStats = _.isUndefined(vm.currentStats) ? {} : vm.currentStats;
        var subscription = accountService.getValues('wit', 'subscription');

        if (accountService.getId('wit') === undefined || accountService.getId('wit') !== $stateParams.id) {
            accountService.clear();
            subscription = undefined;
            vm.billsData = undefined;
            vm.payments = undefined;
            vm.currentStats = {};
        }
        if (subscription === undefined || vm.billsData === undefined || vm.payments === undefined) {
            baseAccountService.get(witAccountService.resourceUrl, $stateParams.id)
                .then(function(response) {
                    accountService.setId('wit', $stateParams.id);
                    subscription = response.data.account;
                    accountService.setValues('wit', 'subscription', subscription);
                    accountService.setValues('wit', 'wit_domain_conf', subscription.wit_domain_conf);
                    accountService.setValues('wit', 'otherAccounts', response.data.other_accounts);
                    accountService.setValues('wit', 'plans', response.data.plans);
                    accountService.setValues('wit', 'defaults', witAccountService.remapLimits(subscription, response.data.plans));
                    accountService.setValues('wit', 'billingInfoAvailable',
                        baseAccountService.isFree(subscription) === false && baseAccountService.isStandardBilling(subscription));

                        if (subscription.status == 'CANCELED') {
                            var cancelReason = null;
                            baseAccountService.getCancelLog(witAccountService.resourceUrl, $stateParams.id)
                                .then(function(response) {
                                    cancelReason = response.data.reason == '' ? "No reason provided" : response.data.reason;
                                    accountService.setValues('wit', 'cancelReason', cancelReason);
                                    $rootScope.$broadcast('updateWitSubscription', {cancelReason: cancelReason});
                                }).catch(function(error) {
                                    console.log(error);
                                }).finally(function() {
                                    vm.loading = false;
                                });
                        } else {
                            $rootScope.$broadcast('updateWitSubscription', {cancelReason: null});
                        }
                    initBillingHistory(subscription);
                }, function(error) {
                    console.log(error);
                    Notification.error("The account could not be loaded");
                });
        }

        function initBillingHistory(subscription) {
            if (baseAccountService.isFree(subscription) || baseAccountService.isStandardBilling(subscription) === false) {
                var message = '';
                if (baseAccountService.isFree(subscription)) {
                    message = 'The Account is free. No billing information is available';
                } else {
                    message = 'The Account has not been set up with standard billing.';
                }
                accountService.setValues('wit', 'billingInfoAvailable', false);
                $rootScope.$broadcast('changeWitTab', {tab: 0});
                $state.go('wit.view', {id:subscription.id});

                Notification.warning(message);
            } else {
                baseAccountService.billingHistory(witAccountService.resourceUrl, $stateParams.id)
                    .then(function(response) {
                        vm.payments = response.data.payments; // externally monthly recurring transactions
                        vm.currentStats.billing_day = vm.payments.transactions[0].billingDayOfMonth;
                        vm.currentStats.start = $filter('date')($filter('createDate')({date: vm.payments.transactions[0].subscription.billingPeriodStartDate.date, format: 'YMD HIS'}), 'MMM d, yyyy');
                        vm.currentStats.end = $filter('date')($filter('createDate')({date: vm.payments.transactions[0].subscription.billingPeriodEndDate.date, format: 'YMD HIS'}), 'MMM d, yyyy');
                        vm.currentStats.id = vm.payments.transactions[0].id;
                        vm.currentStats.cycles = vm.payments.transactions[0].currentBillingCycle;
                        vm.currentStats.status = vm.payments.transactions[0].subscriptionStatus;
                        vm.currentStats.balance = $filter('currency')(vm.payments.transactions[0].balance, '$');
                        vm.billsData = response.data.bills; // internally managed bills with track of current costs and monthly usage
                        accountService.setValues('wit', 'payments', vm.payments);
                        accountService.setValues('wit', 'billsData', vm.billsData);
                        accountService.setValues('wit', 'currentStats', vm.currentStats);
                    }, function(error) {
                        console.log(error);
                        Notification.error(error.data.message);
                        accountService.setValues('wit', 'billingInfoAvailable', false);
                        $rootScope.$broadcast('changeWitTab', {tab: 0});
                        $state.go('wit.view', $stateParams);
                    });
            }
        }
    }
})();
