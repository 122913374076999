(function() {
    'use strict';

    angular.module('adminApp.wit')
        .controller('WitDailyTrafficController', WitDailyTrafficController);

    WitDailyTrafficController.$inject = ['$uibModalInstance', 'account', 'end', 'start', 'trial', 'witStatisticsService'];

    function WitDailyTrafficController($uibModalInstance, account, end, start, trial, witStatisticsService) {
        var vm = this;

        vm.active = 0;
        vm.breakdown = {
            bandwidth: false,
            cache: false,
            formats: false,
            payload: false,
            requests: false
        };
        vm.chart = {
            bandwidth: angular.copy(witStatisticsService.bandwidthChart),
            bandwidthBreakdown: angular.copy(witStatisticsService.bandwidthChart),
            cache: angular.copy(witStatisticsService.cacheChart),
            cacheBreakdown: angular.copy(witStatisticsService.cacheChart),
            formats: angular.copy(witStatisticsService.formatChart),
            formatsBreakdown: angular.copy(witStatisticsService.formatChart),
            payload: angular.copy(witStatisticsService.payloadChart),
            payloadBreakdown: angular.copy(witStatisticsService.payloadChart),
            requests: angular.copy(witStatisticsService.requestChart),
            requestsBreakdown: angular.copy(witStatisticsService.requestChart),
            traffic: angular.copy(witStatisticsService.trafficChart)
        };
        vm.close = close;
        vm.loading = true;
        vm.trial = trial;

        setUp();

        function close() {
            $uibModalInstance.close();
        }

        function setUp() {
            var rows = [];
            angular.forEach(trial.traffic_by_date, function(value, key) {
                rows.push({
                    c: [
                        {v: key},
                        {v: value}
                    ]
                });
            });
            vm.chart.traffic.data.rows = rows;

            var run = witStatisticsService.checkAccountTraffic(account.id);

            if (run) {
                witStatisticsService.addAccount(
                    account,
                    [],
                    {
                        bandwidth: false,
                        cache: false,
                        formats: false,
                        requests: false
                    },
                    'count',
                    start,
                    end,
                    [account]
                ).then(function(response) {
                    finish();
                });

            } else {
                finish();
            }
        }

        function finish() {
            vm.chart.bandwidth.data = witStatisticsService.setBandwidth([account], true);
            vm.chart.bandwidth.options.legend = {
                position: 'none'
            };
            vm.chart.bandwidthBreakdown.data = witStatisticsService.setBandwidthBreakdown([account])
            vm.chart.cache.data = witStatisticsService.setCache('count', false, [account], true);
            vm.chart.cache.options.isStacked = false;
            vm.chart.cacheBreakdown.data = witStatisticsService.setCache('count', true, [account], true);
            vm.chart.cacheBreakdown.options.isStacked = false;
            vm.chart.formats.data = witStatisticsService.setFormats([account], true);
            vm.chart.formatsBreakdown.data = witStatisticsService.setFormatsBreakdown([account]);
            vm.chart.payload.data.rows = witStatisticsService.setPayload([account], true);
            vm.chart.payloadBreakdown.data.rows = witStatisticsService.setPayloadBreakdown([account]);
            vm.chart.requests.data = witStatisticsService.setRequests([account], true);
            vm.chart.requests.options.legend = {
                position: 'none'
            };
            vm.chart.requestsBreakdown.data = witStatisticsService.setRequestsBreakdown([account]);
            vm.loading = false;
        }
    }
})();
