(function() {
    angular.module('adminApp')
        .directive('filex', filex);

    filex.$inject = ['$http', '$sce'];

    function filex($http, $sce) {
        var directive = {
            restrict: 'E',
            templateUrl: '/view/components.filex',
            scope: {
                user: '='
            },
            link: link
        };

        return directive;

        function link(scope, element, attrs) {
            $http.get('api/admin/users/filex')
                .then(function(response) {
                    scope.filex_url = $sce.trustAsResourceUrl(response.data.data.filex_url + '/admin/user/'+scope.user+'?admin_session='+response.data.data.admin_session+'&iframe=1');
                }).catch(function(error) {
                    console.error(error);
                });
        }
    }
})();
