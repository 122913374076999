(function() {
    'use strict';

    angular.module('adminApp.wjs')
        .controller('WjsUsageController', WjsUsageController);

    WjsUsageController.$inject = ['$rootScope', '$stateParams', 'accountService', 'baseAccountService', 'Notification', 'wjsAccountService'];

    function WjsUsageController($rootScope, $stateParams, accountService, baseAccountService, Notification, wjsAccountService) {
        var vm = this;

        vm.billingPeriods = accountService.getValues('wjs', 'billingPeriods');
        vm.currentPeriod = accountService.getValues('wjs', 'currentPeriod');
        vm.defaults = accountService.getValues('wjs', 'defaults');
        vm.getUsage = getUsage;
        vm.initUsage = initUsage;
        vm.subscription = accountService.getValues('wjs', 'subscription');
        vm.usage = accountService.getValues('wjs', 'usage');

        if (accountService.getId('wjs') === undefined || accountService.getId('wjs') !== $stateParams.id) {
            accountService.clear();
            vm.subscription = undefined;
            vm.defaults = undefined;
            vm.usage = undefined;
        }

        if (vm.defaults === undefined || vm.subscription === undefined) {
            baseAccountService.get(wjsAccountService.resourceUrl, $stateParams.id)
                .then(function(response) {
                    vm.subscription = response.data.account;
                    accountService.setValues('wjs', 'subscription', vm.subscription);
                    vm.defaults = baseAccountService.remapLimits(vm.subscription.wurfl_js_accounts_defaults, 'wurfl_js_default');
                    accountService.setValues('wjs', 'defaults', vm.defaults);
                    accountService.setValues('wjs', 'otherAccounts', response.data.other_accounts);
                    accountService.setValues('wjs', 'plans', response.data.plans);
                    accountService.setValues('wjs', 'billingInfoAvailable',
                        baseAccountService.isFree(vm.subscription) === false && baseAccountService.isStandardBilling(vm.subscription));
                    $rootScope.$broadcast('updateWjsSubscription');
                }, function(error) {
                    console.log(error);
                    Notification.error("The account could not be loaded");
                });
        }

        function getUsage(period) {
            if (angular.isUndefined(period)) {
                period = vm.currentPeriod;
            } else {
                vm.currentPeriod = period;
            }

            baseAccountService.usage(wjsAccountService.resourceUrl, $stateParams.id, period.start.date, period.end.date)
                .then(function(response) {
                    vm.usage = response.data;
                    if (accountService.getValues('wjs', 'usage') === undefined) {
                        accountService.setValues('wjs', 'usage', vm.usage);
                    }
                }, function(response) {
                    Notification.error(response.data.message);
                });
        }

        function initUsage() {
            vm.billingPeriods = accountService.getValues('wjs', 'billingPeriods');
            vm.currentPeriod = accountService.getValues('wjs', 'currentPeriod');
            vm.usage = accountService.getValues('wjs', 'usage');
            baseAccountService.billingPeriods(wjsAccountService.resourceUrl, $stateParams.id)
                .then(function(response) {
                    vm.billingPeriods = response.data.reverse();
                    vm.currentPeriod = response.data[0];
                    accountService.setValues('wjs', 'billingPeriods', vm.billingPeriods);
                    accountService.setValues('wjs', 'currentPeriod', vm.currentPeriod);
                    getUsage(vm.currentPeriod);
                }, function(error) {
                    console.log(error);
                    Notification.error(error.data.message);
                });
        }
    }
})();
