(function() {
    'use strict';

    angular.module('adminApp.licenses')
        .controller('LicensesMenuController', LicensesMenuController);

    LicensesMenuController.$inject = ['$scope', '$state', 'licensesModalService', 'licenseService', 'Notification', 'menuService'];

    function LicensesMenuController($scope, $state, licensesModalService, licenseService, Notification, menuService) {
        var vm = this;

        vm.buttonsDisabled = vm.buttonsDisabled = !(!menuService.getMenu('salesadmin') || !menuService.getMenu('admin'));
        vm.createNew = createNew;
        vm.download = download;
        vm.license_id = undefined;
        vm.licenses = null;
        vm.licenseGoClick = licenseGoClick;

        $scope.$watch('vm.license_id', function(newVal) {
            vm.licenses = newVal;
        });

        function createNew(productId) {
            licensesModalService.create(productId);
        }

        function download(type) {
            licensesModalService.download(type);
        }

        function licenseGoClick(event) {
            if (vm.licenses !== undefined) {
                licenseService.get(vm.licenses).then(function(response) {
                    $state.go('licenses.view', {id: vm.licenses});
                }, function(error) {
                    Notification.error('Cannot find license for '+vm.licenses);
                });
            } else {
                Notification.warning('Please select a license');
            }
        }
    }
})();
