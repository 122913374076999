(function() {
    'use strict';

    angular.module('adminApp.wit')
        .controller('WitNewRegionController', WitNewRegionController);

    WitNewRegionController.$inject = ['$ngConfirm', '$q', '$scope', 'breadcrumbsService', 'Notification', 'witAccountService'];

    function WitNewRegionController($ngConfirm, $q, $scope, breadcrumbsService, Notification, witAccountService) {
        var vm = this;

        vm.additions = {
            certificate: [],
            dns: [],
            delete: []
        };
        vm.addTo = addTo;
        vm.checkAdded = checkAdded;
        vm.domains = null;
        vm.domainsExist = false;
        vm.domainSearch = null;
        vm.error = false;
        vm.loading = false;
        vm.planSearch = "pro";
        vm.regions = null;
        vm.removeAll = removeAll;
        vm.removeAllAllowed = false;
        vm.removeFrom = removeFrom;
        vm.removing = false;
        vm.running = false;
        vm.selectedRegion = null;
        vm.steps = breadcrumbsService.get('wit.regions');
        vm.submission = {
            certificate: false,
            dns: false,
            delete: false
        };
        vm.submit = submit;
        vm.updateDomains = updateDomains;

        var allDomains = null;
        var plan = null;

        witAccountService.getAwsRegions().then(function(response) {
            vm.regions = response.data;
        }).catch(function(error) {
            console.log(error);
        });

        $scope.$watch('vm.submission', function(newVal) {
            if (newVal.certificate && newVal.dns && newVal.delete) {
                vm.running = false;
                updateDomains();
            }
        }, true);

        $scope.$watch('vm.domainSearch', function(newVal) {
            if (!_.isNull(newVal)) {
                updatePlan(allDomains);
            }
        });

        $scope.$watch('vm.planSearch', function(newVal, oldVal) {
            if (newVal !== oldVal) {
                updatePlan(allDomains);
            }
        });

        function addTo(domain, indx) {
            vm.additions[indx].push(domain);
        }

        function checkAdded(key, type) {
            if (vm.running) {
                return true;
            }

            if (
                _.indexOf(vm.additions.certificate, key) !== -1 ||
                _.indexOf(vm.additions.dns, key) !== -1 ||
                _.indexOf(vm.additions.delete, key) !== -1
            ) {
                return true;
            }

            if (type ==='dns' && vm.selectedRegion.Deploy == 'provisioning') {
                return true;
            }

            return false;
        }

        function removeAll() {
            $ngConfirm({
                title: 'Are you sure?',
                theme: 'dark',
                content: "This will remove all domains (regardless of search selections) from the selected region. Are you sure you want to continue?",
                buttons: {
                    close: {
                        text: 'Cancel',
                        btnClass: 'btn-default'
                    },
                    proceed: {
                        text: 'Remove',
                        btnClass: 'btn-danger',
                        action: function() {
                            var error = false;
                            var promises = [];
                            vm.removing = true;
                            angular.forEach(allDomains, function(obj, key) {
                                if (!_.isNull(key.ACM_status)) {
                                    promises.push(
                                        witAccountService.postRemoveCertificateFromRegion(obj.subscription_id, {domain: key, region: vm.selectedRegion.RegionName})
                                            .then(function() {})
                                            .catch(function(error) {
                                                console.log(error);
                                                error = true;
                                                $q.reject();
                                            })
                                    );
                                }
                            });

                            $q.all(promises).then(function() {
                                if (error) {
                                    Notification.warning("Errors occurred while removing domains from the region");
                                } else {
                                    Notification.success("All domains have been removed from the region");
                                }
                                vm.removing = false;
                                updateDomains();
                            });
                        }
                    }
                }
            })
        }

        function removeFrom(domain, indx) {
            vm.additions[indx] = _.reject(vm.additions[indx], function(val) {
                return val === domain;
            });
        }

        function submit() {
            var certificatePromises = [];
            var deletePromises = [];
            var dnsPromises = [];
            var errors = {
                certificates: false,
                delete: false,
                dns: false
            };
            vm.submission = {
                certificate: false,
                dns: false,
                delete: false
            };

            vm.running = true;

            if (vm.additions.certificate.length !== 0) {
                angular.forEach(vm.additions.certificate, function(val, key) {
                    var domain = _.pick(allDomains, function(value, key, object) {
                        return key === val;
                    });

                    var certForm = {
                        domain: val,
                        region: vm.selectedRegion,
                        validationType: !_.isUndefined(domain[val].validation_method) ? domain[val].validation_method : 'DNS',
                        db_store: false
                    };
                    certificatePromises.push(
                        witAccountService.postRequestCertificate(domain[val].subscription_id, certForm)
                            .then(function() {})
                            .catch(function(error) {
                                console.log(error);
                                errors.certificates = true;
                                $q.reject();
                            })
                    );
                });

                $q.all(certificatePromises).then(function() {
                    vm.submission.certificate = true;
                    if (errors.certificates) {
                        Notification.warning("Certificates have been generated, however errors occurred");
                    } else {
                        Notification.success("Certificates have been generated")
                    }
                });
            } else {
                vm.submission.certificate = true;
            }
            if (vm.selectedRegion.Deploy !== 'provisioning' && vm.additions.dns.length !== 0) {
                var records = [];
                var basicRecords = [];
                var customWildcardRecords = [];
                angular.forEach(vm.additions.dns, function(val, key) {
                    var domain = _.pick(allDomains, function(value, key, object) {
                        return key === val;
                    });
                    if (domain[val].plan === 'IMAGEENGINE_PRO' || domain[val].plan === 'IMAGEENGINE_STANDARD') {
                        if (!domain[val].custom_wildcard_flag) {
                            records.push({
                                domain: val,
                                region: vm.selectedRegion.RegionName,
                                type: 'A'
                            });
                        } else {
                            customWildcardRecords.push({
                                domain_name: val,
                                base_domain: domain[val].base_domain
                            });
                        }
                    } else {
                        basicRecords.push({
                            domain: val,
                            region: vm.selectedRegion.RegionName,
                            type: 'A'
                        });
                    }
                });
                if (!_.isEmpty(records)) {
                    dnsPromises.push(
                        witAccountService.postAttachToShard({records: records, region: vm.selectedRegion.RegionName})
                            .then(function(response) {})
                            .catch(function(error) {
                                console.log(error);
                                errors.dns = true;
                            })
                    );
                }

                if (!_.isEmpty(customWildcardRecords)) {
                    dnsPromises.push(
                        witAccountService.postAttachCustomWildcardToShard({
                            region: vm.selectedRegion.RegionName,
                            domains: customWildcardRecords
                        }).then(function(response) {})
                        .catch(function(error) {
                            errors.dns = true;
                            console.log(error);
                        })
                    );
                }

                if (!_.isEmpty(basicRecords)) {
                    dnsPromises.push(
                        witAccountService.postAttachToShard({records: basicRecords, region: vm.selectedRegion.RegionName, basic: true})
                            .then(function(response) {})
                            .catch(function(error) {
                                console.log(error);
                                errors.dns = true;
                            })
                    );
                }

                $q.all(dnsPromises).then(function() {
                    vm.submission.dns = true;
                    if (errors.dns) {
                        Notification.warning("DNS records have been generated, however errors occurred");
                    } else {
                        Notification.success("DNS records have been generated");
                    }
                })
            } else {
                vm.submission.dns = true;
            }

            if (vm.additions.delete.length !== 0) {
                angular.forEach(vm.additions.delete, function(val) {
                    var domain = _.pick(allDomains, function(value, key, object) {
                        return key === val;
                    });
                    if (domain[val].plan === 'IMAGEENGINE_PRO' || domain[val].plan === 'IMAGEENGINE_STANDARD') {
                        deletePromises.push(
                            witAccountService.postRemoveCertificateFromRegion(domain[val].subscription_id, {domain: val, region: vm.selectedRegion.RegionName})
                                .then(function() {})
                                .catch(function(error) {
                                    console.log(error);
                                    errors.delete = true;
                                    $q.reject();
                                })
                        );
                    } else {
                        var records = [{
                            domain: val,
                            region: vm.selectedRegion.RegionName,
                            type: 'A'
                        }];
                        deletePromises.push(
                            witAccountService.postDetachFromShard({records: records, region: vm.selectedRegion.RegionName})
                                .then(function(response) {})
                                .catch(function(error) {
                                    console.log(error);
                                    errors.delete = true;
                                    $q.reject();
                                })
                        );
                    }

                    $q.all(deletePromises).then(function() {
                        vm.submission.delete = true;
                        if (errors.delete) {
                            Notification.warning("Errors occurred while removing domains from the region");
                        } else {
                            Notification.success("Selected domains have been removed from the region");
                        }
                    });
                })
            } else {
                vm.submission.delete = true;
            }
        }

        function runGetAws(awsNext, domains) {
            witAccountService.postDomainsNewRegionAws(vm.selectedRegion.RegionName, awsNext)
                .then(function(response) {
                    angular.forEach(response.data.domains, function(info, domain) {
                        domains[domain] = info;
                    });
                    if (!_.isNull(response.data.next)) {
                        runGetAws(response.data.next, domains);
                    } else {
                        witAccountService.postDomainsNewRegionDb(vm.selectedRegion.RegionName, domains)
                            .then(function(response) {
                                witAccountService.postDomainsNewRegionRoute53(vm.selectedRegion.RegionName, response.data.domains)
                                    .then(function(response) {
                                        allDomains = angular.copy(response.data);
                                        updatePlan(response.data);
                                        vm.removeAllAllowed = !_.every(_.pluck(vm.domains, 'ACM_status'), function(val) {
                                            return _.isNull(val);
                                        });
                                    }).catch(function(error) {
                                        console.log(error);
                                        vm.error = true;
                                        Notification.error("Unable to determine domains");
                                    }).finally(function() {
                                        vm.loading = false;
                                    });
                            }).catch(function(error) {
                                console.log(error);
                                Notification.error("Unable to determine domains");
                                vm.loading = false;
                                vm.error = true;
                            });
                    }
                }).catch(function(error) {
                    console.log(error);
                    Notification.error("Unable to determine domains");
                    vm.loading = false;
                    vm.error = true;
                });
        }

        function updateDomains() {
            vm.error = false;
            vm.additions = {
                certificate: [],
                dns: [],
                delete: []
            };
            vm.domainSearch = null;
            vm.domains = null;
            vm.loading = true;

            var domains = {};
            runGetAws(null, domains);
        }

        function updatePlan(data) {
            switch (vm.planSearch) {
                case "pro":
                    plan = ["IMAGEENGINE_PRO"];
                    break;
                case "basic":
                    plan = ["IMAGEENGINE_BASIC", "IMAGEENGINE_STANDARD"];
                    break;
                case "all":
                    plan = ["IMAGEENGINE_BASIC", "IMAGEENGINE_STANDARD", "IMAGEENGINE_PRO"];
                    break;
            }

            if (!_.isNull(vm.domainSearch)) {
                vm.domains = _.pick(data, function(value, key, object) {
                    return key.indexOf(vm.domainSearch) !== -1 && _.indexOf(plan, value.plan) !== -1;
                });
                vm.domainsExist = _.keys(vm.domains).length;
            } else {
                vm.domains = _.pick(data, function(value, key, object) {
                    return _.indexOf(plan, value.plan) !== -1;
                });

                vm.domainsExist = _.keys(vm.domains).length;
            }

            if (vm.planSearch === "pro" && !vm.domainsExist) {
                vm.planSearch = "all";
            }
        }
    }
})();
