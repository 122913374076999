(function() {
    'use strict';

    angular.module('adminApp')
        .controller('TableSortController', TableSortController);

    TableSortController.$inject = ['$rootScope'];

    function TableSortController($rootScope) {
        var vm = this;

        vm.sort = vm.sort;

        $rootScope.$on('changetablearrows', function(event, args) {
            if (vm.name !== args.name) {
                vm.sort = 'unsorted';
            } else {
                vm.sort = args.direction;
            }
        });
    }
})();
