(function() {
    'use strict';

    angular.module('adminApp.users')
        .controller('UsersRevokeController', UsersRevokeController);

    UsersRevokeController.$inject = ['$ngConfirm', '$rootScope', '$scope', '$state', '$stateParams', '$uibModalInstance', 'accountCounts', 'accountLabels', 'id', 'Notification', 'userModalService', 'userService'];

    function UsersRevokeController($ngConfirm, $rootScope, $scope, $state, $stateParams, $uibModalInstance, accountCounts, accountLabels, id, Notification, userModalService, userService) {
        var vm = this;

        vm.accountCounts = accountCounts;
        vm.accountsExist = accountsExist;
        vm.accountLabels = accountLabels;
        vm.close = close;
        vm.continue = continueOn;
        vm.loading = false;
        vm.modalOptions = {
            actionClass: 'alert-danger',
            actionMessage: 'Revoking User...',
            cancelButton: {
                btnClass: 'btn-custom-default',
                btnSpanClass: 'glyphicon-remove',
                btnText: 'Cancel'
            },
            includeView: 'view/users.modals.revoke',
            proceedButton: {
                btnClass: 'btn-danger',
                btnSpanClass: 'glyphicon-ban-circle',
                btnText: 'Revoke'
            },
            title: 'Revoke User'
        };
        vm.revoke = revoke;
        vm.revokeUser = revokeUser;
        vm.showActionSpinner = false;
        vm.transfer = false;
        vm.transferAccounts = transferAccounts;

        var userId = $stateParams.id === undefined ? id : $stateParams.id;

        if (
            accountCounts === null ||
            accountCounts == undefined ||
            accountLabels === null ||
            accountLabels === undefined
        ) {
            vm.loading = true;
            vm.accountLabels = {
                data: 'Data',
                infuze: 'InFuze',
                onsite: 'OnSite',
                insight: 'InSight',
                cloud: 'Cloud',
                wjs: 'WURFL.js',
                imgeng: 'ImageEngine'
            };
            userService.getAccountCount(userId).then(function(response) {
                vm.accountCounts = response.data;
                angular.forEach(vm.accountLabels, function(val, key) {
                    if (vm.accountCounts[key] == undefined) {
                        vm.accountCounts[key] = {
                            active: 0,
                            total: 0
                        };
                    }
                });
                vm.loading = false;
            }).catch(function(error) {
                Notification.error('Could not determine user accounts');
            });
        }

        $rootScope.$on('revokeUser', function(event, args) {
            revoke(args.id);
        });

        $scope.$watch('vm.transfer', function(newVal) {
            if (newVal) {
                vm.modalOptions.proceedButton = {
                    btnClass: 'btn-primary',
                    btnSpanClass: 'glyphicon-transfer',
                    btnText: 'Transfer Accounts'
                };
            } else {
                vm.modalOptions.proceedButton = {
                    btnClass: 'btn-danger',
                    btnSpanClass: 'glyphicon-ban-circle',
                    btnText: 'Revoke'
                };
            }
        });

        function accountsExist() {
            var total = 0;
            angular.forEach(vm.accountCounts, function(val, key) {
                total += val.total;
            });
            return total > 0 ? true : false;
        }

        function close() {
            $uibModalInstance.dismiss();
        }

        function continueOn() {
            if (vm.transfer) {
                transferAccounts();
            } else {
                revokeUser()
            }
        }

        function revoke(id) {
            id = id === undefined ? userId : id;
            vm.transfer = false;
            vm.loading = true;
            vm.showActionSpinner = true;
            userService.revoke(id).then(function(response) {
                Notification.success('User '+id+' has been revoked');
                $uibModalInstance.dismiss();
                if ($state.current.name == 'users.view') {
                    $rootScope.$broadcast('reloadUser');
                } else {
                    $rootScope.$broadcast('reloadUsers', $stateParams);
                }
            }).catch(function(error) {
                console.log(error);
                Notification.error('Could not revoke user');
            }).finally(function() {
                vm.showActionSpinner = false;
            });
        }

        function revokeUser() {
            $ngConfirm({
                title: 'Are you sure?',
                theme: 'dark',
                content: 'You are about to revoke the user. This will prevent them from accessing their account, will cancel/revoke all associated accounts, and will prevent new accounts from being created. Accounts cannot be returned to their previous state.<br /><br />Are you sure you want to continue?',
                buttons: {
                    close: {
                        text: 'Cancel',
                        btnClass: 'btn-default'
                    },
                    proceed: {
                        text: 'Continue',
                        btnClass: 'btn-danger',
                        action: function() {
                            revoke();
                        }
                    }
                }
            });
        }

        function transferAccounts() {
            $ngConfirm({
                title: 'Are you sure?',
                theme: 'dark',
                content: 'This will take you transfer you to the user accounts transfer page. At the conclusion of the transfer, the user will be revoked. Any account still associated with the user will be revoked/cancelled.<br /><br />Are you sure you want to continue?',
                buttons: {
                    close: {
                        text: 'Cancel',
                        btnClass: 'btn-default'
                    },
                    proceed: {
                        text: 'Continue',
                        btnClass: 'btn-danger',
                        action: function() {
                            $uibModalInstance.dismiss();
                            userModalService.transferAccounts({accountCounts: vm.accountCounts, accountLabels: vm.accountLabels, revoke: true, id: userId});
                        }
                    }
                }
            });
        }
    }
})();
