(function() {
    'use strict';

    angular.module('adminApp.licenses')
        .directive('product', product);

    product.$inject = ['$rootScope', 'asOptionsFactory'];

    function product($rootScope, asOptionsFactory) {
        var directive = {
            restrict: 'E',
            transclude: true,
            replace: true,
            template: '<div><input class="form-control" placeholder="loading products, please wait..." ng-if="product_disable" disabled><select ng-if="!product_disable" class="form-control" ng-disabled="product_disable" id="product" name="product" ng-model="current" ng-change="updateProduct(current)" ng-options="option.id as option.name for option in products"></select></div>',
            scope: {
                current: '=model'
            },
            link: link,
            controller: controller
        };

        return directive;

        function link(scope, element, attrs) {
            scope.product_disable = true;

        }

        function controller($rootScope, $scope) {
            var columns = "id,product_name,display_order?orderBy=display_order&order=asc";

            asOptionsFactory.asOptions('Product', columns).$promise
                .then(function(response) {
                    $scope.products = response.data;
                    $scope.origProducts = angular.copy($scope.products);
                    $scope.product_disable = false;
                    $rootScope.$broadcast('loadedProductDirective');
                });

            $scope.updateProduct = function(value) {
                $scope.products = checkAddEmpty($scope.products);
                $rootScope.$broadcast('updateProductDirective', {id: value});
            };

            $rootScope.$on('updateProductByType', function(event, args) {
                if (!_.isNull(args.type)) {
                    $scope.products = _.filter($scope.origProducts, function(obj) {
                        return obj.type == args.type;
                    });
                } else {
                    $scope.products = $scope.origProducts;
                }

            });
        }

        function checkAddEmpty(products) {
            var add = true;
            angular.forEach(products, function(val, key) {
                if (val.id === '' && val.name === '') {
                    add = false;
                    return;
                }
            });
            if (add) {
                products.unshift({id: '', name: ''});
            }
            return products;
        }
    }
})();
