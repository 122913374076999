(function() {
    'use strict';

    angular.module('adminApp.home')
        .controller('ChangelogController', ChangelogController);

    ChangelogController.$inject = ['$location', '$rootScope', '$scope', '$state', '$stateParams', '$timeout', 'adminlogService', 'breadcrumbsService', 'paginationService'];

    function ChangelogController($location, $rootScope, $scope, $state, $stateParams, $timeout, adminlogService, breadcrumbsService, paginationService) {
        var vm = this;

        vm.columns = [
            {
                name: 'Username',
                search: {
                    isSearchable: true,
                    type: 'text'
                },
                model: 'username',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: 'Source',
                search: {
                    isSearchable: true,
                    type: 'text'
                },
                model: 'source',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: 'Source ID',
                search: {
                    isSearchable: true,
                    type: 'text'
                },
                model: 'source_id',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: 'Action Type',
                search: {
                    isSearchable: true,
                    type: 'select',
                    id: 'type'
                },
                model: 'type',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: 'Date Modified',
                search: {
                    isSearchable: false
                },
                model: 'date_last_modified',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: '',
                search: {
                    isSearchable: false
                },
                model: '',
                value: {
                    fromData: false
                },
                display: true
            }
        ];
        vm.date = {
            start: null,
            end: new Date()
        };
        vm.dateOptions = {
            maxDate: new Date(),
            minDate: null,
            startingDay: 1
        };
        vm.format = 'yyyy/MM/dd';
        vm.open = open;
        vm.popup = {
            start: false,
            end: false
        };
        vm.reload = reload;
        vm.search = {
            username: '',
            source: '',
            source_id: '',
            type: '',
            date: {
                start: '',
                end: ''
            }
        };
        vm.steps = breadcrumbsService.get('changelog');

        var search_params = _.clone($location.search());
        var currentPage = $stateParams.page;

        $scope.$watch('vm.date', function(newVal, oldVal) {
            if (oldVal.start != null) {
                vm.search.date = newVal;
                getLog(vm.search);
            }
        }, true);

        $timeout(function() {
            $rootScope.$broadcast("changelogUpdateSelect", {
                id: 'type',
                data: {
                    "add": "add",
                    "remove": "remove",
                    "update": "update"
                },
                keyNeeded: true,
                value: 'key',
                show: 'value'
            });
        }, 1000);

        getLog();

        adminlogService.date().then(function(response) {
            vm.dateOptions.minDate = new Date(response.data.year, response.data.month - 1, response.data.day);
            vm.date.start = new Date(response.data.year, response.data.month - 1, response.data.day);
        }).catch(function(error) {
            console.log(error);
            Notification.error("Unable to find log date");
        });

        var changelogSearchParamsUpdated = $rootScope.$on("changelogSearchParamsUpdated", function(event, args) {
            vm.search = args;
            currentPage = 1;
            var search = angular.copy(args);
            search.search_id = search.id;
            search.id = '';
            getLog(search);
        });
        var changelogPageChanged = $rootScope.$on("changelogPageChanged", function(event, args) {
            vm.search = args;
            currentPage = args.page;
            var search = angular.copy(args);
            search.search_id = search.id;
            search.id = '';
            getLog(search);
        });
        var changelogItemsPerPageChanged = $rootScope.$on("changelogItemsPerPageChanged", function(event, args) {
            vm.search = args;
            currentPage = 1;
            var search = angular.copy(args);
            search.search_id = search.id;
            search.id = '';
            getLog(search);
        });

        function getLog(searchParams) {
            searchParams = typeof searchParams !== 'undefined' ? searchParams : null;

            var start = 0;
            var number = 10;

            var query_params = {};
            var next_page = 1;

            if (searchParams !== null) {
                next_page = searchParams.page;
                number = searchParams.paginate;
            } else if (search_params && _.isUndefined(search_params.page) === false) {
                next_page = search_params.page;
            } else {
                next_page = Math.floor(start/number) + 1;
            }

            query_params = {page: next_page};
            if (searchParams !== null) {
                query_params = _.extend(searchParams);
            }

            adminlogService.all(query_params).then(function(response) {
                var paginationData = {};

                var numPages = response.pagination.last_page;
                paginationData.pages = paginationService.createPages(currentPage, numPages);
                paginationData.numPages = numPages;
                paginationData.currentPage = currentPage;
                paginationData.currentState = $state.current.name;
                paginationData.stateParams = $stateParams;
                paginationData.from = paginationService.from(response.pagination);
                paginationData.to = paginationService.to(response.pagination);
                paginationData.total = response.pagination.total;
                $rootScope.$broadcast('changelogDataLoaded', {
                    data: response.data,
                    paginationData: paginationData,
                    itemsPerPage: number
                });
                $scope.$on('$destroy', changelogSearchParamsUpdated);
                $scope.$on('$destroy', changelogPageChanged);
                $scope.$on('$destroy', changelogItemsPerPageChanged);
            }).catch(function(error) {
                console.log(error);
            });
        }

        function open(key) {
            vm.popup[key] = !vm.popup[key];
        }

        function reload() {
            $rootScope.$broadcast('changelogTriggerLoading');
            getLog(vm.search);
        }
    }
})();
