(function() {
    'use strict';

    angular.module('adminApp.wit')
        .controller('WitBillingController', WitBillingController);

    WitBillingController.$inject = ['$scope', '$stateParams', '$filter', '$state', '$rootScope', 'breadcrumbsService', 'witAccountService', 'Notification'];

    function WitBillingController($scope, $stateParams, $filter, $state, $rootScope, breadcrumbsService, witAccountService, Notification) {
        var vm = this;

        vm.columns = [
            {
                name: 'Account',
                search: {
                    isSearchable: true,
                    type: 'text'
                },
                model: 'account_name',
                value: {
                    fromData: true,
                    link: true,
                    input: {
                        model: 'subscription_id',
                        state: 'wit.view',
                    }
                },
                display: true
            },
            {
                name: 'User',
                search: {
                    isSearchable: true,
                    type: 'text'
                },
                model: 'username',
                value: {
                    fromData: true,
                    link: true,
                    input: {
                        model: 'user_id',
                        state: 'users.view'
                    }
                },
                display: true
            },
            {
                name: 'Period',
                search: {
                    isSearchable: false
                },
                model: 'period',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: 'Total amount',
                search: {
                    isSearchable: false
                },
                model: 'total_amount',
                value: {
                    fromData: true,
                    bind: true
                },
                display: true
            },
            {
                name: 'Payment',
                search: {
                    isSearchable: true,
                    type: 'select',
                    id: 'payment_type'
                },
                model: 'payment_type',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: 'Payment Type',
                search: {
                    isSearchable: true,
                    type: 'select',
                    id: 'billType'
                },
                model: 'billType',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: 'Paid/Submit',
                search: {
                    isSearchable: false
                },
                model: 'paid_at',
                value: {
                    fromData: true,
                    bind: true,
                },
                display: true
            },
            {
                name: null,
                search: {
                    isSearchable: false
                },
                model: 'transaction_id',
                value: {
                    fromData: true,
                    bind: true
                },
                display: true
            }
        ];
        vm.dateOptions = {
            'format': 'MM/DD/YY'
        };
        vm.datepicker = {};
        vm.endPopup = false;
        vm.invalidDateRange = false;
        vm.itemsPerPage = 20;
        vm.itemsPerPageOptions = [10, 20, 30, 40];
        vm.period = {
            start: moment().subtract(1, 'months'),
            end: moment()
        };
        vm.search = {};
        vm.startPopup = false;
        vm.steps = breadcrumbsService.get('wit.billing');
        vm.updatePeriodData = updatePeriodData;
        vm.updating = false;


        var bills;
        var currentPage = 1;

        vm.datepicker = {
            start : vm.period.start.toDate(),
            end : vm.period.end.toDate(),
        };
        fetchBills();

        $scope.$watch('vm.itemsPerPage', function(newVal, oldVal) {
            if (newVal !== oldVal) {
                currentPage = 1;
                filterBills();
            }
        });

        var witbillingPageChanged = $rootScope.$on('witbillingPageChanged', function(event, args) {
            currentPage = args.page;
            filterBills();
        });

        var searchParams = null;
        var witbillingSearchParamsUpdated = $rootScope.$on('witbillingSearchParamsUpdated', function(event, args) {
            searchParams = args;
            currentPage = 1;
            filterBills(args);
        });

        function fetchBills(searchParams) {
            searchParams = typeof searchParams !== 'undefined' ? searchParams : null;
            vm.updating = true;
            vm.invalidDateRange = false;
            witAccountService.bills(vm.period.start.unix(), vm.period.end.unix())
                .then(function(response) {
                    bills = response.data;
                    vm.updating = true;
                    $rootScope.$broadcast("witbillingUpdateSelect", {
                        id: 'payment_type',
                        data: [
                            {value: 'FREE'},
                            {value: 'TRIAL'},
                            {value: 'EXTERNAL'},
                            {value: 'STANDARD'}
                        ],
                        keyNeeded: false,
                        value: 'value',
                        show: 'value'
                    });
                    $rootScope.$broadcast("witbillingUpdateSelect", {
                        id: 'billType',
                        data: [
                            {value: 'Recurring'},
                            {value: 'Overages'},
                            {value: 'Plan Change Proration'}
                        ],
                        keyNeeded: false,
                        value: 'value',
                        show: 'value'
                    });
                    if (bills.length == 0) {
                        $rootScope.$broadcast("witbillingNoDataFound");
                        vm.updating = false;
                        return [];
                    }
                    filterBills(searchParams);

                }, function(error) {
                    var message;
                    if (error.status >= 500) {
                        message = "An unexpected error occurred";
                        if (error.status === 504) {
                            vm.invalidDateRange = true;
                        }
                    } else {
                        message = error.data.message;
                    }
                    Notification.error(message);
                    vm.updating = false;
                    console.log(error);
                });
        }

        function updatePeriodData() {
            vm.period.start = moment(vm.datepicker.start, vm.dateOptions.format);
            vm.period.end = moment(vm.datepicker.end, vm.dateOptions.format);
            fetchBills(searchParams);
        }

        function filterBills(searchParams) {
            searchParams = typeof searchParams !== 'undefined' ? searchParams : null;
            var filteredBills = [];
            angular.forEach(bills, function(bill, key) {
                var account = true;
                var user = true;
                var payment = true;
                var type = true;
                if (searchParams !== null) {
                    if (
                        searchParams.account_name !== undefined &&
                        searchParams.account_name !== "" &&
                        bill.account_name.toLowerCase().indexOf(searchParams.account_name) === -1
                    ) {
                        account = false;
                    }
                    if (
                        searchParams.username !== undefined &&
                        searchParams.username !== "" &&
                        bill.username.toLowerCase().indexOf(searchParams.username) === -1
                    ) {
                        user = false;
                    }
                    if (
                        searchParams.payment_type !== undefined &&
                        searchParams.payment_type !== "" &&
                        bill.payment_type !== searchParams.payment_type
                    ) {
                        payment = false;
                    }
                    if (
                        searchParams.billType !== undefined &&
                        searchParams.billType !== ""
                    ) {
                        switch (bill.bill_type) {
                            case 'FLAT':
                                if (searchParams.billType != 'Recurring') {
                                    type = false;
                                }
                                break;
                            case 'OVERAGE':
                            case 'OVERAGE BASIC':
                            case 'OVERAGE STANDARD':
                            case 'OVERAGE PRO':
                                if (searchParams.billType != 'Overages') {
                                    type = false;
                                }
                                break;
                            case 'PRORATE':
                                if (searchParams.billType != 'Plan Change Proration') {
                                    type = false;
                                }
                            break;
                        }
                    }
                }

                if (account && user && payment && type) {
                    filteredBills.push(angular.copy(bill));
                }
            });

            angular.forEach(filteredBills, function(val, key) {
                val.id = val.subscription_id;
                switch (val.bill_type) {
                    case 'FLAT':
                        val.billType = 'Recurring';
                        break;
                    case 'OVERAGE':
                    case 'OVERAGE BASIC':
                    case 'OVERAGE STANDARD':
                    case 'OVERAGE PRO':
                        val.billType = 'Overages';
                        break;
                    case 'PRORATE':
                        val.billType = 'Plan Change Proration';
                        break;
                }
                val.period = $filter('date')(val.start_date*1000, 'MM/dd/yy') + ' - ' + $filter('date')(val.end_date*1000, 'MM/dd/yy');
                if (!val.total_amount) {
                    if (val.current_costs) {
                        val.total_amount = "<span><span class='glyphicon glyphicon-info-sign'></span> Current: " + $filter('currency')(val.current_costs, '$', 2);
                    }
                } else if (val.total_amount !== ""){
                    val.total_amount = $filter('currency')(val.total_amount, '$', 2);
                }
                if (val.paid_at && val.paid_at !== "") {
                    val.paid_at = "<span>" + $filter('date')($filter('createDate')({date: val.paid_at, format: 'YMD HIS'}), 'MM/dd/yy HH:mm') + "</span>";
                }
                if (val.transaction_id && val.transaction_id != "") {
                    val.transaction_id = "<span class='label label-default'>" + val.transaction_id + "</span>";
                }
            });
            var numPages = Math.ceil(filteredBills.length/vm.itemsPerPage);
            var pages = [];
            for (var i = 1; i <= numPages; i++) {
                pages.push(i);
            }
            var start = parseInt(vm.itemsPerPage*(currentPage - 1));
            var end = parseInt(start) + parseInt(vm.itemsPerPage) < parseInt(filteredBills.length) ? parseInt(start) + parseInt(vm.itemsPerPage) : parseInt(filteredBills.length);

            var paginationData = {};
            paginationData.total = filteredBills.length;
            filteredBills = filteredBills.slice(start, end);

            paginationData.pages = pages;
            paginationData.numPages = numPages;
            paginationData.currentPage = currentPage;
            paginationData.currentState = $state.current.name;
            paginationData.stateParams = $stateParams;
            paginationData.from = parseInt(start) + 1;
            paginationData.to = end;
            vm.updating = false;

            $rootScope.$broadcast("witbillingDataLoaded", {
                data: filteredBills,
                paginationData: paginationData
            });
            $scope.$on('$destroy', witbillingPageChanged);
            $scope.$on('$destroy', witbillingSearchParamsUpdated);
        }
    }
})();
