(function() {
    'use strict';

    angular.module('adminApp.wit')
        .controller('WitPathValidationController', WitPathValidationController);

    WitPathValidationController.$inject = ['$uibModalInstance', 'correctedPattern', 'Notification', 'pattern', 'witAccountService']

    function WitPathValidationController($uibModalInstance, correctedPattern, Notification, pattern, witAccountService) {
        var vm = this;

        vm.changePath = false;
        vm.close = close;
        vm.newPattern = null;
        vm.newPatternError = false;
        vm.paths = [null];
        vm.pattern = pattern;
        vm.running = false;
        vm.showAssumption = showAssumption;
        vm.singlePattern = "/"+pattern;
        vm.submit = submit;
        vm.updatePaths = updatePaths;
        vm.updatePattern = updatePattern;
        vm.valid = [null];

        function close() {
            $uibModalInstance.close(vm.pattern);
        }

        function showAssumption() {
            return !vm.pattern.match(/^\//);
        }

        function submit() {
            var paths = _.without(vm.paths, null, '');
            if (paths.length === 0) {
                Notification.warning("Please provide at least one path to validate");
                return;
            }

            var form = []
            angular.forEach(paths, function(val) {
                if (!val.match(/^\//)) {
                    form.push("/" + val);
                } else {
                    form.push(val);
                }
            });

            vm.running = true;
            witAccountService.postValidatePath({paths: form, pattern: correctedPattern})
                .then(function(response) {
                    angular.forEach(response.data, function(val, key) {
                        angular.forEach(response.data, function(val, key) {
                            var indx = _.indexOf(form, key);
                            vm.valid[indx] = val;
                        });
                    });
                }).catch(function(error) {
                    Notification.error("Unable to validate paths at this time");
                    console.log(error);
                }).finally(function() {
                    vm.running = false;
                });
        }

        function updatePaths(key) {
            if (_.isUndefined(key)) {
                vm.paths.push(null);
                vm.valid.push(null);
            } else {
                vm.paths.splice(key, 1);
                vm.valid.splice(key, 1);
                if (vm.paths.length === 0) {
                    vm.paths = [null];
                    vm.valid = [null];
                }
            }
        }

        function updatePattern(val) {
            if (val) {
                angular.forEach(vm.valid, function(val, key) {
                    vm.valid[key] = null;
                });
                if (_.isNull(vm.newPattern) || vm.newPattern == '') {
                    vm.newPatternError = true;
                    return;
                }
                vm.pattern = angular.copy(vm.newPattern);
                if (!vm.pattern.match(/^\//)) {
                    vm.singlePattern = "/" + vm.pattern;
                    correctedPattern = "/**/"+vm.pattern;
                } else {
                    correctedPattern = angular.copy(vm.pattern);
                }
            }
            vm.newPattern = null;
            vm.changePath = false;
        }
    }
})();
