(function() {
    'use strict';

    angular.module('adminApp.apikey')
        .controller('ApikeyViewController', ApikeyViewController);

    ApikeyViewController.$inject = ['$rootScope', '$state', '$stateParams', 'apikeyModalService', 'apikeyService', 'breadcrumbsService'];

    function ApikeyViewController($rootScope, $state, $stateParams, apikeyModalService, apikeyService, breadcrumbsService) {
        var vm = this;

        vm.apikey = {};
        vm.createNew = createNew;
        vm.loaded = false;
        vm.setEditing = setEditing;
        vm.steps = breadcrumbsService.get($state.current.name, {id: $stateParams.id});

        apikeyService.get($stateParams.id).then(function(response) {
            vm.apikey = response.data;
        }).catch(function(error) {
            console.log(error);
        }).finally(function() {
            vm.loaded = true;
        });

        $rootScope.$on('reloadApiKey', function(event, args) {
            vm.apikey.subscription_id = args.subscriptionId;
        });

        if ($stateParams.edit) {
            setEditing();
        }

        function createNew() {
            apikeyModalService.createNew();
        }

        function setEditing() {
            apikeyModalService.edit();
        }
    }
})();
