(function() {
    'use strict';

    angular.module('adminApp.wjs')
        .controller('WjsPlanChangeController', WjsPlanChangeController);

    WjsPlanChangeController.$inject = ['$rootScope', '$scope', '$stateParams', '$timeout', '$uibModalInstance', 'direction', 'Notification', 'paymentService', 'saasConstants', 'subscription', 'wjsAccountService'];

    function WjsPlanChangeController($rootScope, $scope, $stateParams, $timeout, $uibModalInstance, direction, Notification, paymentService, saasConstants, subscription, wjsAccountService) {
        var vm = this;

        var plans = saasConstants.getConstants('wjs', 'paymentPlans');
        var current = _.findWhere(plans, {key: subscription.payment_plan});
        var formFields = [
            'first_name',
            'last_name',
            'address',
            'city',
            'state',
            'zip',
            'country_id'
        ];
        var loadedInstance = null;

        vm.cancel = cancel;
        vm.class = {
            first_name: null,
            last_name: null,
            address: null,
            city: null,
            state: null,
            zip: null
        };
        vm.direction = direction;
        vm.form = {
            first_name: null,
            last_name: null,
            address: null,
            city: null,
            state: null,
            zip: null
        };
        vm.options = null;
        vm.running = false;
        vm.submit = submit;
        vm.subscription = subscription;
        vm.title = null;

        if (direction == 'up') {
            vm.title = 'Upgrade Plan';
            vm.options = (_.filter(plans, function(obj) {
                return obj.price > current.price;
            }));
        } else if (direction == 'down') {
            vm.title = 'Downgrade Plan';
            vm.options = (_.filter(plans, function(obj) {
                return obj.price < current.price;
            }));
        } else if (_.isNull(direction)) {
            vm.title = "End WURFL.js Trial";
        }

        $scope.$on('tokenGenerated', function(event, args) {
            braintree.dropin.create({
                authorization: args.token,
                container: '#wjs-payment-form',
                card: {
                    cardholderName: {
                        required: true
                    }
                }
            }, function(createErr, instance) {
                $timeout(function() {
                    vm.disablePay = false;
                }, 1);
                loadedInstance = instance;
            });
        });

        $scope.$watch('vm.form', function(newVal, oldVal) {
            if (newVal.type != oldVal.type && newVal.type == 'standard' && _.isNull(vm.subscription.vault_subscription_id)) {
                generateToken();
            }
        }, true);

        function cancel() {
            $uibModalInstance.dismiss();
        }

        function generateToken() {
            return paymentService.getBraintreeAuthorization().then(function(response) {
                $scope.$broadcast('tokenGenerated', {token: response.data.authorization});
            });
        }

        function runSubmit() {
            vm.running = true;
            vm.form.payment_type = vm.form.type == 'standard' ? 'STANDARD' : 'EXTERNAL';
            if (!_.isNull(direction)) {
                wjsAccountService.patchChangePlan($stateParams.id, vm.form).then(function(response) {
                    Notification.success("Successfully changed plan!");
                    $rootScope.$broadcast('completeReload');
                    $uibModalInstance.close();
                }).catch(function(error) {
                    console.log(error);
                    if (vm.form.type == 'standard' && _.isNull(vm.subscription.vault_subscription_id)) {
                        loadedInstance.teardown(function(err) {
                            generateToken();
                        });
                    }
                    Notification.error("Unable to change plan");
                }).finally(function() {
                    vm.running = false;
                });
            } else {
                wjsAccountService.endTrial($stateParams.id, vm.form).then(function(response) {
                    Notification.success("Successfully ended trial!");
                    $rootScope.$broadcast('completeReload');
                    $uibModalInstance.close();
                }).catch(function(error) {
                    console.log(error);
                    if (vm.form.type == 'standard' && _.isNull(vm.subscription.vault_subscription_id)) {
                        loadedInstance.teardown(function(err) {
                            generateToken();
                        });
                    }
                    Notification.error("Unable to end trial");
                }).finally(function() {
                    vm.running = false;
                });
            }
        }

        function submit() {
            if (vm.form.type == 'standard' && _.isNull(vm.subscription.vault_subscription_id)) {
                if (vm.form.country_id === undefined) {
                    vm.form.country_id = 230;
                } else {
                    vm.form.country_id = vm.form.country_id.id;
                }

                var errors = false;
                angular.forEach(formFields, function(val) {
                    if (
                        vm.form[val] === '' ||
                        _.isNull(vm.form[val]) ||
                        _.isUndefined(vm.form[val])
                    ) {
                        vm.class[val] = 'error';
                        errors = true;
                    }
                });

                if (!errors) {
                    loadedInstance.requestPaymentMethod(function(requestPaymentMethodErr, payload) {
                        if (!_.isUndefined(payload)) {
                            vm.form.nonce = payload.nonce;
                            runSubmit();
                        }
                    });
                }
            } else {
                runSubmit();
            }
        }
    }
})();
