(function() {
    'use strict';

    angular.module('adminApp.reports')
        .factory('reportDirectiveService', reportDirectiveService);

    reportDirectiveService.$inject = ['$rootScope', '$state', '$uibModal', '$window', 'devAdminService', 'loginService', 'reportsService', 'witAccountService'];

    function reportDirectiveService($rootScope, $state, $uibModal, $window, devAdminService, loginService, reportsService, witAccountService) {
        var service = {
            getButtonText: getButtonText,
            getClass: getClass,
            proceed: proceed
        };

        return service;

        function getButtonText(type) {
            var buttonText = null;
            switch (type) {
                case 'internal':
                    buttonText = 'Go';
                    break;
                case 'external':
                case 'multi-external':
                    buttonText = 'Open';
                    break;
                case 'wit':
                    buttonText = 'Generate';
                    break;
                default:
                    break;
            }

            return buttonText;
        }

        function getClass(type) {
            var className = null;

            switch (type) {
                case 'sales':
                    className = "fa fa-download"
                    break;
                case 'wurfl':
                    className = "fa fa-download"
                    break;
                case 'external':
                case 'multi-external':
                    className = 'fa fa-external-link';
                    break;
                default:
                    className = "fa fa-download"
                    break;
            }

            return className;
        }

        function proceed(val, type, reports, link, state) {
            switch (type) {
                case 'sales':
                    var date = _.findWhere(reports, {click: val}).date;
                    var type = _.findWhere(reports, {click: val}).type;
                    if (date) {
                        $uibModal.open({
                            templateUrl: 'view/components.download_modal',
                            controller: 'ReportsDatesController',
                            controllerAs: 'vm',
                            size: 'lg',
                            backdrop: 'static',
                            keyboard: false,
                            resolve: {
                                name: function() {
                                    return val;
                                }
                            }
                        });
                    } else {
                        reportsService.downloadReports(val, {});
                    }

                    break;
                case 'wurfl':
                    var user = loginService.authInfo.user;
                    devAdminService.downloadGenericTrialWurfl("zip", user);
                    break;
                case 'internal':
                    $state.go(state);
                    $rootScope.$broadcast('sidebarDismiss');
                    break;
                case 'external':
                    $window.open(link, '_blank');
                    break;
                case 'wit':
                    witAccountService.generateToken();
                    break;
                case 'multi-external':
                    $window.open(val, '_blank');
                    break;
                case 'users':
                    reportsService.downloadUserRoles();
                    break;
            }
        }
    }
})();
