(function() {
    'use strict';

    angular.module('adminApp.saas')
        .directive('accountForm', accountForm);

    accountForm.$inject = ['$state', '$uibModal', 'baseAccountService', 'saasConstants'];

    function accountForm($state, $uibModal, baseAccountService, saasConstants) {
        var directive = {
            restrict: 'AE',
            replace: false,
            templateUrl: '/view/subscriptions.common.edit_account_form',
            scope: {
                subscription: '=',
                plans: '=',
                service: '=',
                saasType: '@'
            },
            controller: 'SaasEditAccountFormController',
            controllerAs: 'vm',
            link: link
        };

        return directive;

        function link(scope, element, attrs) {
            function init_statuses(subscription) {
                var o = element.find("select[name='status'] > option[value='"+scope.vm.cancelled_status+"']");

                if (scope.vm.isFree(subscription)) {
                    o.attr('disabled', 'disabled');
                } else {
                    o.removeAttr('disabled');
                }
            }
            element.find("select[name='payment_plan']").on('change', function() {
                var plan_selected = _.findWhere(scope.vm.plans, {key: angular.element(this).val()});
                scope.vm.subscription.plan = plan_selected;
                return init_statuses(scope.vm.subscription);
            });
        }
    }
})();
