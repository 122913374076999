(function() {
    'use strict';

    angular.module('adminApp.apikey')
        .controller('ApikeyManageController', ApikeyManageController);

    ApikeyManageController.$inject = ['$location', '$rootScope', '$scope', '$state', '$stateParams', 'apikeyModalService', 'apikeyService', 'breadcrumbsService', 'paginationService'];

    function ApikeyManageController($location, $rootScope, $scope, $state, $stateParams, apikeyModalService, apikeyService, breadcrumbsService, paginationService) {
        var vm = this;

        vm.columns = [
            {
                name: 'ID',
                search: {
                    isSearchable: true,
                    type: 'text'
                },
                model: 'id',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: 'Username',
                search: {
                    isSearchable: true,
                    type: 'text'
                },
                model: 'username',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: 'Subscription',
                search: {
                    isSearchable: true,
                    type: 'text'
                },
                model: 'subscription_id',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: 'Key Name',
                search: {
                    isSearchable: true,
                    type: 'text'
                },
                model: 'key_name',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: 'Api Username',
                search: {
                    isSearchable: true,
                    type: 'text'
                },
                model: 'api_username',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: 'Api Password',
                search: {
                    isSearchable: true,
                    type: 'text'
                },
                model: 'api_password',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: 'Expiration',
                search: {
                    isSearchable: true,
                    type: 'text'
                },
                model: 'expiration',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: '',
                search: {
                    isSearchable: false
                },
                model: '',
                value: {
                    fromData: false,
                },
                display: true
            }
        ];
        vm.createNew = createNew;
        vm.search = {};
        vm.steps = breadcrumbsService.get('apikey.manage');

        var currentPage = $stateParams.page;
        var search_params = _.clone($location.search());

        getApiKeys();

        var apikeySearchParamsUpdated = $rootScope.$on('apikeySearchParamsUpdated', function(event, args) {
            vm.search = args;
            currentPage = 1;
            getApiKeys(args);
        });

        var apikeyPageChanged = $rootScope.$on("apikeyPageChanged", function(event, args) {
            vm.search = args;
            currentPage = args.page;
            getApiKeys(args);
        });

        var apikeyItemsPerPageChanged = $rootScope.$on("apikeyItemsPerPageChanged", function(event, args) {
            vm.search = args;
            currentPage = 1;
            getApiKeys(args);
        });

        $rootScope.$on('reloadApiKeys', function(event, args) {
            if (args.page === undefined) {
                getApiKeys($stateParams);
            } else {
                getApiKeys(args);
            }
        });

        function createNew() {
            apikeyModalService.createNew();
        }

        function getApiKeys(searchParams) {
            searchParams = typeof searchParams !== 'undefined' ? searchParams : null;
            var start = 0;
            var number = 50;

            var query_params = {};
            var next_page = 1;

            if (searchParams !== null) {
                next_page = searchParams.page;
                number = searchParams.paginate;
            } else if (search_params && _.isUndefined(search_params.page) === false) { // if defined
                next_page = search_params.page;
            } else {
                next_page = Math.floor(start/number) + 1;
            }

            query_params = {page: next_page};
            if (searchParams !== null) {
                angular.forEach(searchParams, function(value, key) {
                    if (value !== '' && value !== null) {
                        query_params[key] = value;
                    }
                });
            }

            apikeyService.all(query_params).then(function(response) {
                angular.forEach(response.data, function(val, key) {
                    val.username = val.user.username;
                });

                var paginationData = {};
                var numPages = response.pagination.last_page;

                paginationData.pages = paginationService.createPages(currentPage, numPages);
                paginationData.numPages = numPages;
                paginationData.currentPage = currentPage;
                paginationData.currentState = $state.current.name;
                paginationData.stateParams = $stateParams;
                paginationData.from = paginationService.from(response.pagination);
                paginationData.to = paginationService.to(response.pagination);
                paginationData.total = response.pagination.total;
                $rootScope.$broadcast('apikeyDataLoaded', {
                    data: response.data,
                    paginationData: paginationData,
                    itemsPerPage: number
                });
            }).catch(function(error) {
                console.log(error);
            }).finally(function() {
                $scope.$on('$destroy', apikeySearchParamsUpdated);
                $scope.$on('$destroy', apikeyPageChanged);
                $scope.$on('$destroy', apikeyItemsPerPageChanged);
            });
        }
    }
})();
