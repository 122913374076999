(function() {
    'use strict';

    angular.module('adminApp.cloud')
        .controller('CloudSignupsController', CloudSignupsController);

    CloudSignupsController.$inject = ['$filter', '$timeout', 'breadcrumbsService', 'cloudAccountService', 'Notification'];

    function CloudSignupsController($filter, $timeout, breadcrumbsService, cloudAccountService, Notification) {
        var vm = this;

        vm.chart = {
            type: "AnnotationChart",
            options: {
                displayAnnotations: true
            }
        };
        vm.dataExists = false;
        vm.loaded = {
            line: false,
            pie: false
        };
        vm.pieChart = {
            type: "PieChart",
            options: {
                'title': 'WURFL Cloud Signups',
                "width": "100%",
                "height": "600px"
            }
        };
        vm.status = {
            isFirstOpen: true,
            isSecondOpen: true
        };
        vm.steps = breadcrumbsService.get('cloud.signups');

        var data = [];
        var pieData = [];

        cloudAccountService.getSignups().then(function(response) {
            data = response.data;
            if (data.length > 0) {
                vm.dataExists = true;
                init();
            } else {
                vm.loaded.line = true;
            }
        }).catch(function(error) {
            console.log(error);
            Notification.error("Could not gather signups");
        });

        cloudAccountService.getSignupCounts().then(function(response) {
            pieData = response.data;
            initPieChart();
        }).catch(function(error) {
            console.log(error);
            Notification.error("Could not gather signup counts");
        });

        function init() {
            var rows = [];
            var i = 0;
            angular.forEach(data, function(val, key) {
                var date = $filter('createDate')({date: val[0], format: 'YMD'});
                rows.push(
                    {
                        c: [{
                            v: date
                        }, {
                            v: parseInt(val[1])
                        }, {
                            v: parseInt(val[3])
                        }, {
                            v: parseInt(val[4])
                        }, {
                            v: parseInt(val[5])
                        }]
                    }
                )
            });

            vm.chart.data = {
                "cols": [
                    {
                        id: "sample-date",
                        label: "Date",
                        type: "datetime"
                    },
                    {
                        id: "free",
                        label: "Free",
                        type: "number"
                    },
                    {
                        id: "basic",
                        label: "Basic",
                        type: "number"
                    },
                    {
                        id: "standard",
                        label: "Standard",
                        type: "number"
                    },
                    {
                        id: "premium",
                        label: "Premium",
                        type: "number"
                    }
                ],
                "rows": rows
            };
            vm.loaded.line = true;
        }

        function initPieChart() {
            var rows = [];
            angular.forEach(pieData, function(val, key) {
                rows.push({
                    c: [
                        {v: val[0]},
                        {v: val[1]}
                    ]
                });
            });
            vm.pieChart.data = {
                "cols": [
                    {
                        id: "account_type",
                        label: "Account Type",
                        type: "string"
                    },
                    {
                        id: "count",
                        label: "Total Signups",
                        type: "number"
                    }
                ],
                "rows": rows
            };
            vm.loaded.pie = true;

            $timeout( function(){
                vm.status.isSecondOpen = false;
            }, 100 );
        }
    }
})();
