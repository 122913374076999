(function() {
    'use strict';

    angular.module('adminApp.users')
        .controller('TeamsDeleteController', TeamsDeleteController);

    TeamsDeleteController.$inject = ['$rootScope', '$state', '$stateParams', '$uibModalInstance', 'id', 'Notification', 'teamService'];

    function TeamsDeleteController($rootScope, $state, $stateParams, $uibModalInstance, id, Notification, teamService) {
        var vm = this;
        
        vm.close = close;
        vm.continue = remove;
        vm.showActionSpinner = false;
        vm.message = '';
        vm.modalOptions = {
            actionClass: 'alert-danger',
            actionMessage: 'Deleting...',
            cancelButton: {
                btnClass: 'btn-custom-default',
                btnSpanClass: 'glyphicon-remove',
                btnText: 'Cancel'
            },
            includeView: 'view/users.modals.delete',
            proceedButton: {
                btnClass: 'btn-danger',
                btnSpanClass: 'glyphicon-trash',
                btnText: 'Delete'
            },
            title: 'Delete Team'
        };

        var teamId = $stateParams.id === undefined ? id : $stateParams.id;

        vm.message = "You are about to delete the team. <br /><br /><span class='text-danger'>This will completely and irreversibly remove the team from the database.</span><br /><br />Are you sure you want to continue?";

        $rootScope.$on('removeTeam', function(event, args) {
            remove(args.id);
        });

        function close() {
            $uibModalInstance.dismiss();
        }

        function remove(id) {
            vm.showActionSpinner = true;
            id = id === undefined ? teamId : id;
            vm.loading = true;
            teamService.delete(id).then(function(response) {
                Notification.success('Team '+id+' has been deleted');
                $uibModalInstance.dismiss();
                if ($state.current.name == 'users.teams') {
                    $rootScope.$broadcast('reloadTeams', $stateParams);
                } else {
                    $state.go('users.teams');
                }
            }).catch(function(error) {
                console.log(error);
                Notification.error('Could not delete team');
            }).finally(function() {
                vm.showActionSpinner = false;
            });
        }
    }
})();
