(function() {
    'use strict';

    angular.module('adminApp.wjs')
        .controller('WitListController', WitListController);

    WitListController.$inject = ['breadcrumbsService', 'subscriptionModalService', 'witAccountService', 'witModalService'];
    function WitListController(breadcrumbsService, subscriptionModalService, witAccountService, witModalService) {
        var vm = this;

        vm.createNew = createNew;
        vm.download = download;
        vm.service = witAccountService;
        vm.steps = breadcrumbsService.get('wit.list');

        function createNew() {
            witModalService.createNew();
        }

        function download(type) {
            if (type == 'pt') {
                witModalService.download();
            } else if (type == 'all') {
                subscriptionModalService.download('imgeng');
            }
        }
    }
})();
