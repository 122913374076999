(function() {
    'use strict';

    angular.module('adminApp.saas')
        .controller('NewSaasAccountController', NewSaasAccountController);

    NewSaasAccountController.$inject = ['$rootScope', '$scope', '$state', '$uibModal', '$uibModalInstance', 'baseAccountService', 'quwurflAccountService', 'Notification', 'saasConstants', 'saasType', 'userId'];

    function NewSaasAccountController($rootScope, $scope, $state, $uibModal, $uibModalInstance, baseAccountService, quwurflAccountService, Notification, saasConstants, saasType, userId) {
        var vm = this;

        vm.saasType = saasType;
        vm.close = close;
        vm.disabled = disabled;
        vm.loading = true;
        vm.new = {
            account_name: null,
            duration_count: null,
            duration_interval: null,
            payment_type: 'TRIAL',
            user: null
        };
        vm.payment_plans = _.omit(saasConstants.getConstants(saasType, 'paymentPlans'), 'wurfl cloud lite');
        vm.payment_types = _.omit(saasConstants.getConstants(saasType, 'paymentTypes'), 'STANDARD', 'AZURE', 'FREE');
        vm.saveAccount = saveAccount;
        vm.saving = false;
        vm.viewUsers = viewUsers;
        vm.viewUsersDisabled = true;

        var service = baseAccountService.getService(saasType);
        var users;
        if (saasType != 'quwurfl') {
            vm.new.payment_plan = _.last(_.values(vm.payment_plans)).key;
        }

        var usersDirectiveLoaded = $rootScope.$on('usersDirectiveLoaded', function(event, args) {
            vm.viewUsersDisabled = false;
            users = args.users;
            if (!_.isNull(userId)) {
                vm.new.user = _.find(args.users, function(obj) {
                    return obj.id == userId;
                });
            }
            vm.loading = false;
            $scope.$on('$destroy', usersDirectiveLoaded);
        });

        function close(state, id) {
            $uibModalInstance.dismiss();
        }

        function disabled(val) {
            if (saasType != 'cloud') {
                return false;
            }
            if (val.key !== 'WURFL_CLOUD_PREMIUM') {
                return true;
            }
            return false;
        }

        function saveAccount() {
            if (!$scope.new_account_form.$valid) {
                Notification.error("All fields are required");
                return false;
            }

            vm.saving = true;

            if (saasType == 'quwurfl') {
                var data = {
                    user_id: vm.new.user.id,
                    account_name: vm.new.account_name,
                    payment_plan: vm.new.payment_plan,
                    payment_type: vm.new.payment_type,
                    date_started: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                    date_expiration: null,
                }
                switch (vm.new.duration_interval) {
                    case 'D':
                        data.date_expiration = moment.utc().add(vm.new.duration_count, 'days').format('YYYY-MM-DD HH:mm:ss')
                        break;
                    case 'M':
                        data.date_expiration = moment.utc().add(vm.new.duration_count, 'months').format('YYYY-MM-DD HH:mm:ss')
                        break;
                    case 'Y':
                        data.date_expiration = moment.utc().add(vm.new.duration_count, 'years').format('YYYY-MM-DD HH:mm:ss')
                        break;
                }
                quwurflAccountService.create(data)
                .then(function(response) {
                    Notification.success("Subscription created");
                    $state.go(saasType+'.view', {id: response.subscription.id});
                    $uibModalInstance.dismiss();
                }, function(error) {
                    console.log(error);
                    Notification.error(error.data.error.message);
                }).finally(function() {
                    vm.saving = false;
                });
            } else {
                baseAccountService.save(service.resourceUrl, vm.new)
                    .then(function(response) {
                        Notification.success(response.message);
                        $state.go(saasType+'.view', {id: response.data[0].id});
                        $uibModalInstance.dismiss();
                    }, function(error) {
                        Notification.error(error.data.message);
                        console.log(error);
                    }).finally(function() {
                        vm.saving = false;
                    });
            }
        }

        function viewUsers() {
            $uibModal.open({
                templateUrl: 'view/components.pick_list_modal',
                controller: controller,
                size: 'sm',
                resolve: {
                    listTitle: function() {
                        return 'All Users';
                    },
                    items: function() {
                        return users;
                    },
                    label: function() {
                        return 'name';
                    }
                },
                backdrop: 'static',
                keyboard: false
            }).result.then(function(selected) {
                vm.new.user = selected;
            });

            function controller($scope, $uibModalInstance, items, listTitle, label) {
                $scope.listTitle = listTitle;
                $scope.items = items;
                $scope.label = label;

                $scope.select = select;

                function select(item) {
                    $uibModalInstance.close(item);
                }
            }
        }

    }
})();
