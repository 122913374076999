(function() {
    'use strict';

    angular.module('adminApp.quwurfl')
        .controller('QuWurflListController', QuWurflListController);

    QuWurflListController.$inject = ['breadcrumbsService', 'subscriptionModalService', 'quwurflAccountService'];
    function QuWurflListController(breadcrumbsService, subscriptionModalService, quwurflAccountService) {
        var vm = this;

        vm.createNew = createNew;
        vm.download = download;
        vm.service = quwurflAccountService;
        vm.steps = breadcrumbsService.get('quwurfl.list');

        function createNew() {
            subscriptionModalService.createNew('quwurfl');
        }

        function download() {
            quwurflAccountService.download();
        }
    }
})();
