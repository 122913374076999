(function() {
    'use strict';

    angular.module('adminApp.users')
        .controller('UsersZendeskController', UsersZendeskController);

    UsersZendeskController.$inject = ['$rootScope', '$uibModalInstance', 'id', 'Notification', 'userService', 'zendeskTierId', 'zendeskTiers']

    function UsersZendeskController($rootScope, $uibModalInstance, id, Notification, userService, zendeskTierId, zendeskTiers) {
        var vm = this;

        vm.close = close;
        vm.modalOptions = {
            closeButtonText: 'Cancel',
            okButtonText: 'Save',
            template: 'view/users.modals.zendesktiers',
            title: 'Set Support Tier'
        };
        vm.newZendeskTierId = angular.copy(zendeskTierId);
        vm.submit = submit;
        vm.zendeskTierId = zendeskTierId;
        vm.zendeskTiers = zendeskTiers;

        function close() {
            $uibModalInstance.dismiss();
        }

        function submit() {
            userService.postUpdateZendeskTier(id, vm.newZendeskTierId).then(function() {
                 var name = _.findWhere(vm.zendeskTiers, {id: vm.newZendeskTierId}).tier_name;
                 $rootScope.$broadcast('updatedSupportTier', {name: name});
                 $rootScope.$broadcast('reloadUsers', {});
                 Notification.success("Updated user to "+name+" support tier!");
                 $uibModalInstance.dismiss();
            }).catch(function(error) {
                console.log(error);
                Notification.error("Unable to update user support tier");
            });
        }
    }
})();
