(function() {
    'use strict';

    angular.module('adminApp.licenses')
        .directive('infuzeProfile', infuzeProfile);

    infuzeProfile.$inject = ['$stateParams', '$animate', '$timeout', 'infuzeService'];

    function infuzeProfile($stateParams, $animate, $timeout, infuzeService) {
        var directive = {
            restrict: 'E',
            transclude: false,
            replace: false,
            templateUrl: 'view/infuze.templates.infuze_profile',
            scope: {
                profile: "=",
                licenseId: "@"
            },
            link: link,
            controller: 'InfuzeProfileController',
            controllerAs: 'vm'
        };

        return directive;

        function link(scope, element, attrs) {
            scope.element = element;
        }
    }
})();
