(function() {
    'use strict';

    angular.module('adminApp.saas')
        .controller('SaasEditAccountFormController', SaasEditAccountFormController);

    SaasEditAccountFormController.$inject = ['$scope', '$state', '$uibModal', 'baseAccountService', 'saasConstants'];

    function SaasEditAccountFormController($scope, $state, $uibModal, baseAccountService, saasConstants) {
        var vm = this;

        vm.cancelledStatus = 'CANCELED';
        vm.checkDisabled = checkDisabled;
        vm.clear = clear;
        vm.dateOptions = {
            minDate: new Date(),
            showWeeks: false,
            formatDay: 'dd',
            formatMonth: 'MM',
            formatYear: 'yyyy'
        };
        vm.isFree = isFree;
        vm.open = open;
        vm.opened = false;
        vm.paymentTypes = [];
        vm.plans = $scope.plans;
        vm.statusChange = statusChange;
        vm.statusTypes = [];
        vm.subscription = $scope.subscription || {};
        vm.today = today;

        var statusMessages = {
            cancelledStatus : "This will cancel a subscription in the Payment Gateway and delete all capabilities, api keys, image scaling domains and limits.<br />A FREE ACCOUNT CANNOT BE CANCELLED!<br />THIS ACTION CANNOT BE REVERSED!"
        };

        vm.statusTypes = saasConstants.getConstants($scope.saasType, 'statusTypes');
        vm.paymentTypes = saasConstants.getConstants($scope.saasType, 'paymentTypes');
        delete vm.statusTypes.PENDING_PAYMENT_INFORMATION;

        function checkDisabled(key) {
            if (vm.subscription.status === 'ACTIVE') {
                if (key === 'PENDING_SMS_VERIFY' || key === 'PENDING_EMAIL_VERIFY' || key === 'PENDING_PAYMENT') {
                    return true;
                }
            }

            if (vm.isFree(vm.subscription) && key === vm.cancelledStatus) {
                return true;
            }

            return false;
        }

        function showAlert(message) {
            $uibModal.open({
                templateUrl: '/view/components.alert',
                controller: function($scope, $uibModalInstance, message) {
                    $scope.message = message;
                    $scope.close = function() {
                        $uibModalInstance.close();
                    };
                },
                resolve: {
                    message: function() { return message; }
                },
                size: 'lg',
                backdrop: 'static',
                keyboard: false
            }).result.then(function(result) {
                if (result) {
                    return $state.transitionTo("view");
                }
            });
        }

        function clear() {
            vm.subscription.date_expiration = null;
        }

        function isFree() {
            if (!_.isUndefined(vm.subscription) && !_.isUndefined(vm.subscription.plan)) {
                return baseAccountService.isFree(vm.subscription);
            }
        }

        function open() {
            vm.opened = true;
        }

        function statusChange() {
            if (vm.subscription.status === 'CANCELED') {
                showAlert(statusMessages.cancelledStatus);
            }
        }

        function today() {
            vm.subscription.date_expiration = new Date();
        }
    }
})();
