(function() {
    'use strict';

    angular.module('adminApp.cut')
        .controller('CutManageTestsController', CutManageTestsController);

    CutManageTestsController.$inject = ['$location', '$rootScope', '$scope', '$state', '$stateParams', '$timeout', 'breadcrumbsService', 'centraltestTestsService', 'cutModalService', 'paginationService'];

    function CutManageTestsController($location, $rootScope, $scope, $state, $stateParams, $timeout, breadcrumbsService, centraltestTestsService, cutModalService, paginationService) {
        var vm = this;

        vm.columns = [
            {
                name: 'Name',
                search: {
                    isSearchable: true,
                    type: 'text'
                },
                model: 'name',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: 'Description',
                search: {
                    isSearchable: true,
                    type: 'text'
                },
                model: 'description',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: 'Unit Test',
                search: {
                    isSearchable: true,
                    type: 'select',
                    id: 'type_unit'
                },
                model: 'type_unit',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: 'Regression Test',
                search: {
                    isSearchable: true,
                    type: 'select',
                    id: 'type_regression'
                },
                model: 'type_regression',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: 'Enabled',
                search: {
                    isSearchable: true,
                    type: 'select',
                    id: 'enabled'
                },
                model: 'enabled',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: '',
                search: {
                    isSearchable: false
                },
                model: '',
                value: {
                    fromData: false,
                },
                display: true
            }
        ];
        vm.createNew = createNew;
        vm.search = {};
        vm.steps = breadcrumbsService.get('centraltest.admin.tests');

        var currentPage = $stateParams.page;
        var search_params = _.clone($location.search());
        var numPages = 1;

        $timeout(function() {
            $rootScope.$broadcast("apitestsUpdateSelect", {
                id: 'type_unit',
                data: {'Yes': 'Yes', 'No': 'No'},
                keyNeeded: true,
                value: 'key',
                show: 'value'
            });
            $rootScope.$broadcast("apitestsUpdateSelect", {
                id: 'type_regression',
                data: {'Yes': 'Yes', 'No': 'No'},
                keyNeeded: true,
                value: 'key',
                show: 'value'
            });
            $rootScope.$broadcast("apitestsUpdateSelect", {
                id: 'enabled',
                data: {'Yes': 'Yes', 'No': 'No'},
                keyNeeded: true,
                value: 'key',
                show: 'value'
            });
        }, 1000);

        getTests();
        $rootScope.$on('reloadTests', function(event, args) {
            if (args.page === undefined) {
                getTests($stateParams);
            } else {
                getTests(args);
            }
        });

        var apitestsSearchParamsUpdated = $rootScope.$on('apitestsSearchParamsUpdated', function(event, args) {
            vm.search = args;
            currentPage = 1;
            getTests(args);
        });

        var apitestsPageChanged = $rootScope.$on("apitestsPageChanged", function(event, args) {
            vm.search = args;
            currentPage = args.page;
            getTests(args);
        });

        var apitestsItemsPerPageChanged = $rootScope.$on("apitestsItemsPerPageChanged", function(event, args) {
            vm.search = args;
            currentPage = 1;
            getTests(args);
        });

        function createNew() {
            cutModalService.create('test', [], 'none', numPages, {ua_override: 0, enabled: 0}, false);
        }

        function getTests(searchParams) {
            searchParams = typeof searchParams !== 'undefined' ? searchParams : null;
            var start = 0;
            var number = 50;

            var query_params = {};
            var next_page = 1;

            if (searchParams !== null) {
                next_page = searchParams.page;
                number = searchParams.paginate;
            } else if (search_params && _.isUndefined(search_params.page) === false) { // if defined
                next_page = search_params.page;
            } else {
                next_page = Math.floor(start/number) + 1;
            }

            query_params = {page: next_page};
            if (searchParams !== null) {
                angular.forEach(searchParams, function(value, key) {
                    if (value !== '' && value !== null) {
                        query_params[key] = value;
                    }
                });
            }

            centraltestTestsService.all(query_params).then(function(response) {
                if (response) {
                    angular.forEach(response.data, function(val, key) {
                        val.type_unit = val.type_unit == 0 ? 'No' : 'Yes';
                        val.type_regression = val.type_regression == 0 ? 'No' : 'Yes';
                        val.enabled = val.enabled == 0 ? 'No' : 'Yes';
                    });

                    var paginationData = {};
                    numPages = response.pagination.last_page;

                    paginationData.pages = paginationService.createPages(currentPage, numPages);
                    paginationData.numPages = numPages;
                    paginationData.currentPage = currentPage;
                    paginationData.currentState = $state.current.name;
                    paginationData.stateParams = $stateParams;
                    paginationData.from = paginationService.from(response.pagination);
                    paginationData.to = paginationService.to(response.pagination);
                    paginationData.total = response.pagination.total;
                    paginationData.colspan = 3;

                    $rootScope.$broadcast('apitestsDataLoaded', {
                        data: response.data,
                        paginationData: paginationData,
                        itemsPerPage: number
                    });
                }
                $scope.$on('$destroy', apitestsSearchParamsUpdated);
                $scope.$on('$destroy', apitestsPageChanged);
                $scope.$on('$destroy', apitestsItemsPerPageChanged);
            });
        }
    }
})();
