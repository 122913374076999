(function() {
    'use strict';

    angular.module('adminApp.licenses')
        .controller('ManageInsightController', ManageInsightController);

    ManageInsightController.$inject = ['$location', '$rootScope', '$scope', '$state', '$stateParams', 'breadcrumbsService', 'licenseService', 'Notification', 'paginationService'];

    function ManageInsightController($location, $rootScope, $scope, $state, $stateParams, breadcrumbsService, licenseService, Notification, paginationService) {
        var vm = this;

        vm.columns = [
            {
                name: 'User',
                search: {
                    isSearchable: true,
                    type: 'text'
                },
                model: 'username',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: 'License',
                search: {
                    isSearchable: true,
                    type: 'text'
                },
                model: 'license_id',
                value: {
                    fromData: false,
                    link: true,
                    input: {
                        model: 'license_id',
                        text: 'License Configuration',
                        state: 'view'
                    }
                },
                display: true
            },
            {
                name: 'Insight',
                search: {
                    isSearchable: true,
                    type: 'text'
                },
                model: 'license_id',
                value: {
                    fromData: false,
                    link: true,
                    input: {
                        model_id: 'license_id',
                        other: 'userId',
                        other_val: 'user_id',
                        text: 'InSight Details',
                        state: 'insight.index'
                    }
                },
                display: true
            }
        ];
        vm.search = {
            user: ''
        };
        vm.steps = breadcrumbsService.get('licenses.insight.manage');

        var search_params = _.clone($location.search());
        var currentPage = $stateParams.page;
        getInsight();

        var updateInsightSearchParams = $rootScope.$on("insightSearchParamsUpdated", function(event, args) {
            vm.search = args;
            currentPage = 1;
            var search = angular.copy(args);
            getInsight(search);
        });

        var insightPageChanged = $rootScope.$on("insightPageChanged", function(event, args) {
            vm.search = args;
            currentPage = args.page;
            var search = angular.copy(args);
            getInsight(search);
        });
        var insightItemsPerPageChanged = $rootScope.$on("insightItemsPerPageChanged", function(event, args) {
            vm.search = args;
            currentPage = 1;
            var search = angular.copy(args);
            getInsight(search);
        });

        function getInsight(searchParams) {
            searchParams = typeof searchParams !== 'undefined' ? searchParams : null;

            var start = 0;
            var number = 50;

            var query_params = {};
            var next_page = currentPage ? currentPage : 1;

            if (searchParams !== null) {
                next_page = searchParams.page;
                number = searchParams.paginate;
            } else if (search_params && _.isUndefined(search_params.page) === false) {
                next_page = search_params.page;
            } else {
                next_page = Math.floor(start/number) + 1;
            }

            query_params = {page: next_page};
            if (searchParams !== null) {
                query_params = _.extend(searchParams);
            }

            licenseService.profiles(query_params).then(function(response) {
                angular.forEach(response.data, function(val, key) {
                    val.username = val.license.user.username;
                    val.user_id = val.license.user.id;
                });

                var paginationData = {};
                var numPages = response.pagination.last_page;
                paginationData.pages = paginationService.createPages(currentPage, numPages);
                paginationData.numPages = numPages;
                paginationData.currentPage = currentPage;
                paginationData.currentState = $state.current.name;
                paginationData.stateParams = $stateParams;
                paginationData.from = paginationService.from(response.pagination);
                paginationData.to = paginationService.to(response.pagination);
                paginationData.total = response.pagination.total;

                $rootScope.$broadcast('insightDataLoaded', {
                    data: response.data,
                    paginationData: paginationData,
                    itemsPerPage: number
                });
                $scope.$on('$destroy', updateInsightSearchParams);
                $scope.$on('$destroy', insightPageChanged);
                $scope.$on('$destroy', insightItemsPerPageChanged);
            }).catch(function(error) {
                Notification.error('Could not fetch the InSight records');
                $scope.$on('$destroy', updateInsightSearchParams);
                $scope.$on('$destroy', insightPageChanged);
                $scope.$on('$destroy', insightItemsPerPageChanged);
                console.log(error);
            });
        }


    }
})();
