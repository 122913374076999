(function() {
    'use strict';

    angular.module('adminApp.saas')
        .directive('saasSearchResults', saasSearchResults);

    function saasSearchResults() {
        var directive = {
            restrict: 'E',
            scope: {
                keyword: '=',
                metaInfoColumn: '@',
                metaInfoCallback: '&',
                service: '=',
                tableType: '@'
            },
            templateUrl: 'view/subscriptions.common.search_table',
            controller: 'SaasSearchResultsController',
            controllerAs: 'vm',
            bindToController: true
        };

        return directive;
    }
})();
