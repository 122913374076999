(function() {
    'use strict';

    angular.module('adminApp.reports')
        .controller('AccountDownloadsLicenseController', AccountDownloadsLicenseController);

    AccountDownloadsLicenseController.$inject = ['$rootScope', '$scope', '$uibModalInstance', 'reportsService', 'type'];

    function AccountDownloadsLicenseController($rootScope, $scope, $uibModalInstance, reportsService, type) {
        var vm = this;

        vm.progress = 0;
        switch (type) {
            case 'onsite':
                var title = 'OnSite';
                break;
            case 'insight':
                var title = 'Insight';
                break;
            case 'infuze':
                var title = 'InFuze';
                break;
        }
        vm.title = title + " Licenses Report";

        var updateLicensesProgress = $rootScope.$on('updateLicensesProgress', function(event, args) {
            vm.progress = reportsService.calculateProgress(args.complete);
            if (vm.progress == 100) {
                $uibModalInstance.close();
            }
            $scope.$on('$destroy', updateLicensesProgress);
        });

        var licenseErrorClose = $rootScope.$on('licenseErrorClose', function() {
            $uibModalInstance.close();
            $scope.$on('$destroy', licenseErrorClose);
        });

        reportsService.downloadLicenseReport(type);
    }
})();
