(function() {
    'use strict';

    angular.module('adminApp.wit')
        .directive('billingTable', billingTable);

    function billingTable() {
        var directive = {
            restrict: 'E',
            templateUrl: 'view/subscriptions.wit.billing_table',
            scope: {
                bills: '=',
                itemsPerPage: '@',
                updating: '@'
            },
            controller: "WitBillingController",
            controllerAs: 'vm',
            bindToController: true
        };

        return directive;
    }
})();
