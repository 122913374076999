(function() {
    'use strict';

    angular.module('adminApp.saas')
        .controller('SaasSearchResultsController', SaasSearchResultsController);

    SaasSearchResultsController.$inject = ['$location', '$rootScope', '$scope', '$state', '$stateParams', 'baseAccountService', 'Notification', 'paginationService'];

    function SaasSearchResultsController($location, $rootScope, $scope, $state, $stateParams, baseAccountService, Notification, paginationService) {
        var vm = this;

        vm.columns = [
            {
                name: 'ID',
                search: {
                    isSearchable: false
                },
                model: 'id',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: 'Account<br />name',
                search: {
                    isSearchable: false
                },
                model: 'account_name',
                value: {
                    fromData: true,
                    bind: true,
                    found: vm.keyword
                },
                display: true
            },
            {
                name: 'User<br />username',
                search: {
                    isSearchable: false
                },
                model: 'username',
                value: {
                    fromData: true,
                    bind: true,
                    found: vm.keyword
                },
                display: true
            },
            {
                name: 'User<br />e-mail',
                search: {
                    isSearchable: false
                },
                model: 'user_email',
                value: {
                    fromData: true,
                    bind: true,
                    found: vm.keyword
                },
                display: true
            },
            {
                name: 'Account<br />plan',
                search: {
                    isSearchable: false
                },
                model: 'key',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: 'Payment<br />type',
                search: {
                    isSearchable: false
                },
                model: 'payment_type',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: $scope.vm.metaInfoColumn,
                search: {
                    isSearchable: false
                },
                model: 'metaInfo',
                value: {
                    fromData: true,
                    bind: true,
                },
                display: true
            },
            {
                name: 'Status',
                search: {
                    isSearchable: false
                },
                model: 'status',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: '',
                search: {
                    isSearchable: false
                },
                model: '',
                value: {
                    fromData: false
                },
                display: true
            }
        ];
        vm.currentPage = $stateParams.page;
        vm.currentState = $state.current.name;
        vm.isLoading = true;
        vm.keyword = $scope.vm.keyword;
        vm.listMetaInfo = listMetaInfo;
        vm.metaInfoCallback = $scope.vm.metaInfoCallback;
        vm.metaInfoColumn = $scope.vm.metaInfoColumn;
        vm.numPages = null;
        vm.pages = null;
        vm.pagination = {};
        vm.paginationData = {};
        vm.pagination_info = {
            from: null,
            to: null
        };
        vm.search = {};
        vm.searchSubscriptions = searchSubscriptions;
        vm.state = $state.current.name.split(".")[0];
        vm.stateParams = $stateParams;
        vm.subscriptions = {};
        vm.tableType = $scope.vm.tableType;

        if (vm.tableType == 'witSearch') {
            var temp = _.findWhere(vm.columns, {name: vm.metaInfoColumn});
            temp.value.found = vm.keyword;
            vm.columns.splice(7, 0, {
                name: 'Domain Name(s)',
                search: {
                    isSearchable: false
                },
                model: 'cname',
                value: {
                    fromData: true,
                    bind: true,
                    found: vm.keyword
                },
                display: true
            });
        }

        var search_params = _.clone($location.search());
        searchSubscriptions();
        var saasSearchPageChange = $rootScope.$on(vm.tableType+"PageChanged", function(event, args) {
            vm.search = args;
            vm.currentPage = args.page;
            searchSubscriptions(vm.search);
        });
        var saasSearchItemsPerPageChanged = $rootScope.$on(vm.tableType+"ItemsPerPageChanged", function(event, args) {
            vm.search = args;
            vm.currentPage = 1;
            searchSubscriptions(vm.search);
        });

        function listMetaInfo(item) {
            var values;
            switch (vm.state) {
                case 'wjs':
                    // string
                    var domains = baseAccountService.foundMetaInfo(item.domains, 'domain', vm.keyword).toString();
                    // string
                    var cnames = baseAccountService.foundMetaInfo(item.cnames, 'cname', vm.keyword).toString();

                    values = _.filter([domains, cnames]).join(', ');
                    break;
                case 'cloud':
                    values = baseAccountService.foundMetaInfo(item.keys, 'api_username', vm.keyword);
                    break;
                default:
                    return false;
            }

            return values;
        }

        function searchSubscriptions(searchParams) {
            searchParams = typeof searchParams !== 'undefined' ? searchParams : null;
            vm.isLoading = true;
            vm.subscriptions = undefined;
            var start = 0;
            var number = 10;

            var next_page;
            if (searchParams !== null) {
                next_page = searchParams.page;
                number = searchParams.paginate;
            } else if (search_params && _.isUndefined(search_params.page) === false) {
                next_page = search_params.page;
            } else {
                next_page = 1;
            }

            var per_page = 10;
            if (searchParams && _.isUndefined(searchParams.paginate) === false) {
                per_page = searchParams.paginate;
            }

            baseAccountService.search(vm.service.resourceUrl, vm.keyword, per_page, next_page)
                .then(function(response) {
                    angular.forEach(response.data.subscriptions, function(val, key) {
                        if (vm.state == 'wit') {
                            val.key = val.payment_plan;
                            val.metaInfo = val.token;
                        } else {
                            if (val.user !== null) {
                                val.username = val.user.username;
                                val.user_email = val.user.email;
                            }
                            if (val.plan !== null) {
                                val.key = val.plan.key;
                            }
                            val.metaInfo = listMetaInfo(val);
                        }

                    });

                    vm.isLoading = false;
                    vm.subscriptions = response.data.subscriptions;
                    vm.pagination = response.pagination;
                    vm.numPages = response.pagination.last_page;
                    vm.pages = paginationService.createPages(vm.currentPage, vm.numPages);
                    vm.paginationData.pages = vm.pages;
                    vm.paginationData.numPages = vm.numPages;
                    vm.paginationData.currentPage = vm.currentPage;
                    vm.paginationData.currentState = vm.currentState;
                    vm.paginationData.stateParams = vm.stateParams;
                    vm.paginationData.from = paginationService.from(response.pagination);
                    vm.paginationData.to = paginationService.to(response.pagination);
                    vm.paginationData.total = response.pagination.total;

                    $rootScope.$broadcast(vm.tableType+'DataLoaded', {
                        data: vm.subscriptions,
                        paginationData: vm.paginationData,
                        itemsPerPage: number
                    });
                    $scope.$on('$destroy', saasSearchPageChange);
                    $scope.$on('$destroy', saasSearchItemsPerPageChanged);
                }, function(error) {
                    Notification.error(error.data.message);
                    console.log(error);
                    $scope.$on('$destroy', saasSearchPageChange);
                    $scope.$on('$destroy', saasSearchItemsPerPageChanged);
                });
        }
    }
})();
