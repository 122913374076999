(function() {
    'use strict';

    angular.module('adminApp.users')
        .controller('UserListController', UserListController);

    UserListController.$inject = ['$location', '$rootScope', '$scope', '$state', '$stateParams', '$timeout', 'breadcrumbsService', 'Notification', 'paginationService', 'userModalService', 'userService'];
    function UserListController($location, $rootScope, $scope, $state, $stateParams, $timeout, breadcrumbsService,  Notification, paginationService, userModalService, userService) {
        var vm = this;

        var allData = null;
        var paginationData = null;
        var numPages = null;
        var number = null;

        vm.columns = [
            {
                name: 'ID',
                search: {
                    isSearchable: false
                },
                model: 'id',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: 'Username',
                search: {
                    isSearchable: true,
                    type: 'text'
                },
                model: 'username',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: 'Email',
                search: {
                    isSearchable: true,
                    type: 'text'
                },
                model: 'email',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: 'First Name',
                search: {
                    isSearchable: true,
                    type: 'text'
                },
                model: 'first_name',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: 'Last Name',
                search: {
                    isSearchable: true,
                    type: 'text'
                },
                model: 'last_name',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: 'Company',
                search: {
                    isSearchable: true,
                    type: 'text'
                },
                model: 'company_name',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: 'Country',
                search: {
                    isSearchable: true,
                    type: 'text'
                },
                model: 'country_name',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: 'Cust. ID',
                search: {
                    isSearchable: true,
                    type: 'text'
                },
                model: 'customer_id',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: 'Support Tier',
                search: {
                    isSearchable: true,
                    type: 'select',
                    id: 'tier_name'
                },
                model: 'tier_name',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: '',
                search: {
                    isSearchable: false
                },
                model: '',
                value: {
                    fromData: false,
                },
                display: true
            }//,
            // {
            //     name: '',
            //     search: {
            //         isSearchable: false
            //     },
            //     model: '',
            //     value: {
            //         fromData: false,
            //         link: true,
            //         input: {
            //             model: 'id',
            //             text: "View Account",
            //             state: 'myaccount'
            //         }
            //     },
            //     display: true
            // }
        ];
        vm.createNew = createNew;
        vm.download = download;
        vm.search = {};
        vm.steps = breadcrumbsService.get($state.current.name);
        vm.zendeskTiers = null;

        var currentPage = $stateParams.page;
        var search_params = _.clone($location.search());

        $rootScope.$on('reloadUsers', function(event, args) {
            if (args.page === undefined) {
                getUsers($stateParams);
            } else {
                getUsers(args);
            }
        });

        $timeout(function() {
            userService.getZendeskTiers().then(function(response) {
                vm.zendeskTiers = response.data;
                angular.forEach(allData, function(val, key) {
                    if (val.zendesk_tier_id != null) {
                        if (!_.isNull(vm.zendeskTiers)) {
                            val.tier_name = _.findWhere(vm.zendeskTiers, {id: val.zendesk_tier_id}).tier_name;
                        }
                    }
                });

                $rootScope.$broadcast('usersDataLoaded', {
                    data: allData,
                    paginationData: paginationData,
                    itemsPerPage: number
                });
            }).catch(function(error) {
                console.log(error);
                Notification.error("An error occurred while loading support tiers");
            });
        }, 1000);



        getUsers();

        var usersSearchParamsUpdated = $rootScope.$on("usersSearchParamsUpdated", function(event, args) {
            vm.search = args;
            currentPage = 1;
            getUsers(args);
        });

        var usersPageChanged = $rootScope.$on("usersPageChanged", function(event, args) {
            vm.search = args;
            currentPage = args.page;
            getUsers(args);
        });

        var usersItemsPerPageChanged = $rootScope.$on("usersItemsPerPageChanged", function(event, args) {
            vm.search = args;
            currentPage = 1;
            getUsers(args);
        });

        function getUsers(searchParams) {
            searchParams = typeof searchParams !== 'undefined' ? searchParams : null;
            var start = 0;
            number = 10;

            var query_params = {};
            var next_page = 1;

            if (searchParams !== null) {
                next_page = searchParams.page;
                number = searchParams.paginate;
            } else if (search_params && _.isUndefined(search_params.page) === false) { // if defined
                next_page = search_params.page;
            } else {
                next_page = Math.floor(start/number) + 1;
            }

            query_params = {page: next_page};
            if (searchParams !== null) {
                query_params = _.extend(searchParams);
            }
            if (!_.isUndefined(query_params.tier_name)) {
                if (query_params.tier_name != '') {
                    var tier = _.findWhere(vm.zendeskTiers, {tier_name: query_params.tier_name});
                    if (!_.isUndefined(tier)) {
                        query_params.zendesk_tier_id = tier.id;
                    }
                } else {
                    query_params = _.omit(query_params, function(value, key, object) {
                        return key == 'zendesk_tier_id' || key == 'tier_name';
                    });
                }
            }

            userService.all(query_params).then(function(response) {
                angular.forEach(response.data, function(val, key) {
                    if (val.country !== null) {
                        val.country_name = val.country.name;
                    }
                    if (val.enabled == 0) {
                        val.addedClass = 'color: red;';
                    } else {
                        val.addedClass = '';
                    }
                    if (val.zendesk_tier_id != null) {
                        if (!_.isNull(vm.zendeskTiers)) {
                            val.tier_name = _.findWhere(vm.zendeskTiers, {id: val.zendesk_tier_id}).tier_name;
                        }
                    }
                });
                allData = response.data;
                paginationData = {};
                numPages = response.pagination.last_page;

                paginationData.pages = paginationService.createPages(currentPage, numPages);
                paginationData.numPages = numPages;
                paginationData.currentPage = currentPage;
                paginationData.currentState = $state.current.name;
                paginationData.stateParams = $stateParams;
                paginationData.from = paginationService.from(response.pagination);
                paginationData.to = paginationService.to(response.pagination);
                paginationData.total = response.pagination.total;
                $rootScope.$broadcast('usersDataLoaded', {
                    data: response.data,
                    paginationData: paginationData,
                    itemsPerPage: number
                });
                $scope.$on('$destroy', usersSearchParamsUpdated);
                $scope.$on('$destroy', usersPageChanged);
                $scope.$on('$destroy', usersItemsPerPageChanged);
            }, function(error) {
                console.error(error);
                Notification.error("Could not fetch the user records");
                $scope.$on('$destroy', usersSearchParamsUpdated);
                $scope.$on('$destroy', usersPageChanged);
            });
        }

        function createNew() {
            userModalService.createNew();
        }

        function download() {
            userModalService.downloadAccounts();
        }
    }
})();
