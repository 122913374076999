(function() {
    'use strict';

    angular.module('adminApp.users')
        .controller('UsersWitImpersonateController', UsersWitImpersonateController);

    UsersWitImpersonateController.$inject = ['$stateParams', '$sce'];

    function UsersWitImpersonateController($stateParams, $sce) {
        var vm = this;

        var envPrefix = '',
        hostname = window.location.hostname;
        if (hostname.indexOf('dev-') === 0) {
            envPrefix = 'dev-';
        } else if (hostname.indexOf('staging-') === 0 || hostname.indexOf('.local') === 0) {
            envPrefix = 'staging-';
        }

        var postUrl = 'https://'+envPrefix+'auth.imageengine.io/api/v1/sso/scientiamobile';
        vm.postUrl = $sce.trustAsResourceUrl(postUrl);
        vm.redirectUrl = 'https://'+envPrefix+'control.imageengine.io/login?sso=scientiamobile&impersonation=true';
        vm.refreshToken = localStorage.getItem('refresh_token');
        vm.userId = $stateParams.id;

        setTimeout(function() {
            document.getElementById('ie-impersonate-form').submit();
        }, 500);
    }
})();
