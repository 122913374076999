(function() {
    'use strict';

    angular.module('adminApp.cloud')
        .controller('CloudUpgradeController', CloudUpgradeController);

    CloudUpgradeController.$inject = ['$rootScope', '$scope', '$stateParams', '$timeout', '$uibModalInstance', 'cloudAccountService', 'Notification', 'paymentService', 'type'];

    function CloudUpgradeController($rootScope, $scope, $stateParams, $timeout, $uibModalInstance, cloudAccountService, Notification, paymentService, type) {
        var vm = this;
        var formFields = [
            'first_name',
            'last_name',
            'address',
            'city',
            'state',
            'zip',
            'country_id'
        ];
        var loadedInstance = null;

        vm.cancel = cancel;
        vm.class = {
            first_name: null,
            last_name: null,
            address: null,
            city: null,
            state: null,
            zip: null
        };
        vm.form = {
            first_name: null,
            last_name: null,
            address: null,
            city: null,
            state: null,
            zip: null,
            payment_type: type === 'standard' ? 'standard' : 'external'
        };
        vm.submit = submit;
        vm.title = null;
        vm.type = type;

        switch (type) {
            case 'upgrade':
                vm.title = 'Upgrade to WURFL Cloud Account';
                break;
            case 'standard':
                vm.title = 'Switch to Standard Billing';
                break;
            case 'trial':
                vm.title = 'End WURFL Cloud Trial';
                break;
        }

        if (type === 'standard') {
            generateToken();
        }

        $scope.$on('tokenGenerated', function(event, args) {
            braintree.dropin.create({
                authorization: args.token,
                container: '#cloud-payment-form',
                card: {
                    cardholderName: {
                        required: true
                    }
                }
            }, function(createErr, instance) {
                $timeout(function() {
                    vm.disablePay = false;
                }, 1);
                loadedInstance = instance;
            });
        });

        $scope.$watch('vm.form', function(newVal, oldVal) {
            if (newVal.payment_type != oldVal.payment_type && newVal.payment_type == 'standard') {
                generateToken();
            }
        }, true);

        function cancel() {
            $uibModalInstance.close();
        }

        function generateToken() {
            return paymentService.getBraintreeAuthorization().then(function(response) {
                $scope.$broadcast('tokenGenerated', {token: response.data.authorization});
            });
        }

        function runSubmit() {
            vm.running = true;
            switch (type) {
                case 'upgrade':
                    cloudAccountService.upgrade(vm.form, $stateParams.id).then(function(response) {
                        Notification.success("Successfully upgraded account!");
                        $rootScope.$broadcast('completeReload');
                        $uibModalInstance.close();
                    }).catch(function(error) {
                        console.log(error);
                        if (vm.form.payment_type == 'STANDARD') {
                            loadedInstance.teardown(function(err) {
                                generateToken();
                            });
                        }
                        Notification.error("Unable to upgrade account");
                    }).finally(function() {
                        vm.running = false;
                    });
                    break;
                case 'standard':
                    cloudAccountService.addPayment(vm.form, $stateParams.id).then(function(response) {
                        Notification.success("Successfully changed account!");
                        $rootScope.$broadcast('completeReload');
                        $uibModalInstance.close();
                    }).catch(function(error) {
                        console.log(error);
                        loadedInstance.teardown(function(err) {
                            generateToken();
                        });
                        Notification.error("Unable to upgrade account");
                    }).finally(function() {
                        vm.running = false;
                    });
                    break;
                case 'trial':
                    cloudAccountService.endTrial(vm.form, $stateParams.id).then(function(response) {
                        Notification.success("Successfully activated paid account!");
                        $rootScope.$broadcast('completeReload');
                        $uibModalInstance.close();
                    }).catch(function(error) {
                        console.log(error);
                        if (vm.form.payment_type == 'STANDARD') {
                            loadedInstance.teardown(function(err) {
                                generateToken();
                            });
                        }
                        Notification.error("Unable to activate paid account");
                    }).finally(function() {
                        vm.running = false;
                    });
                    break;
            }
        }

        function submit() {
            if (vm.form.payment_type == 'standard') {
                if (vm.form.country_id === undefined) {
                    vm.form.country_id = 230;
                } else {
                    vm.form.country_id = vm.form.country_id.id;
                }

                var errors = false;
                angular.forEach(formFields, function(val) {
                    if (
                        vm.form[val] === '' ||
                        _.isNull(vm.form[val]) ||
                        _.isUndefined(vm.form[val])
                    ) {
                        vm.class[val] = 'error';
                        errors = true;
                    }
                });

                if (!errors) {
                    loadedInstance.requestPaymentMethod(function(requestPaymentMethodErr, payload) {
                        if (!_.isUndefined(payload)) {
                            vm.form.nonce = payload.nonce;
                            runSubmit();
                        }
                    });
                }
            } else {
                runSubmit();
            }
        }
    }
})();
