(function() {
    'use strict';

    angular.module('adminApp.coreapi')
        .controller('ScopesEditModalController', ScopesEditModalController);

    ScopesEditModalController.$inject = ['$rootScope', '$stateParams', '$state', '$uibModalInstance', 'coreApiScopeService', 'Notification', 'scope'];

    function ScopesEditModalController($rootScope, $stateParams, $state, $uibModalInstance, coreApiScopeService, Notification, scope) {
        var vm = this;

        vm.close = close;
        vm.formErrors = {};
        vm.modalOptions = {
            actionClass: 'alert-info',
            actionMessage: 'Saving...',
            proceedButton: {
                btnClass: 'btn-primary',
                btnSpanClass: 'glyphicon-floppy-save',
                btnText: 'Save'
            },
            template: 'view/coreapi.modals.scopes_modal',
            title: 'Edit CoreApi Scope'
        };
        vm.proceed = proceed;
        vm.scope = scope;
        vm.showActionSpinner = false;
        vm.submitButton = "Update Scope";
        vm.updateDisabled = false;

        if (_.isNull(vm.scope.name) || _.isUndefined(vm.scope.name)) {
            vm.updateDisabled = true;
            coreApiScopeService.getScope(vm.scope.scope)
                .then(function(response) {
                    vm.scope = response;
                    vm.updateDisabled = false;
                }, function(error) {
                    switch (error.status) {
                        case 400:
                            Notification.error(error.data.message);
                            break;
                        case 403:
                            Notification.error({message: 'You are not permitted to perform this action', replaceMessage: true});
                            break;
                        default:
                            Notification.error("An unknown error has occurred");
                            console.log(error);
                    }
                });
        }

        function close() {
            $uibModalInstance.dismiss();
        }

        function proceed() {
            vm.formErrors = {};
            vm.showActionSpinner = true;
            coreApiScopeService.updateScope(vm.scope)
                .then(function(response) {
                    vm.showActionSpinner = false;
                    switch (response.status) {
                        case 201:
                            Notification.warning(response.message);
                            break;
                        default:
                            Notification.success('Scope updated');
                            vm.scope = response.data;
                    }
                    if ($state.current.name == 'scopes.view') {
                        if ($stateParams.id != vm.scope.scope) {
                            $state.go($state.current.name, {id: vm.scope.scope});
                        } else {
                            $rootScope.$broadcast('reloadScope');
                        }
                    } else {
                        $rootScope.$broadcast('reloadScopes');
                    }
                    $uibModalInstance.dismiss();
                }, function(error) {
                    switch (error.status) {
                        case 400:
                            angular.forEach(error.data.data, function(value, key) {
                                vm.formErrors[key] = value[0];
                            });
                            Notification.error("Form errors exist");
                            break;
                        case 403:
                            Notification.error({message: 'You are not permitted to perform this action', replaceMessage: true});
                            break;
                        default:
                            Notification.error("An unknown error has occurred");
                            break;
                    }
                    vm.showActionSpinner = false;
                });
        }
    }
})();
