(function() {
    'use strict';

    angular.module('adminApp.licenses')
        .controller('LicenseTextController', LicenseTextController);

    LicenseTextController.$inject = ['$sce', '$stateParams', '$window', 'licenseService', 'Notification'];

    function LicenseTextController($sce, $stateParams, $window, licenseService, Notification) {
        var vm = this;

        vm.downloadLicenseText = downloadLicenseText;
        vm.license = licenseService.getValues($stateParams.id, 'license');

        if (vm.license === undefined) {
            licenseService.get($stateParams.id).then(function(response) {
                vm.license = response.data;
                licenseService.setValues($stateParams.id, 'license', response.data);
                if (
                    vm.license.product.type.toLowerCase() !== 'infuze' &&
                    vm.license.product.type.toLowerCase() !== 'insight' &&
                    vm.license.product.type.toLowerCase() !== 'onsite'
                ) {
                    licenseService.setValues($stateParams.id, 'configurationDisabled', true);
                }
                licenseService.text(vm.license)
                    .then(function(response) {
                        vm.license.text = $sce.trustAsHtml(response.data.text);
                    }, function(error) {
                        Notification.error("An unexpected error occurred");
                        console.log(error);
                    });
            });
        }

        function downloadLicenseText() {
            licenseService.postDownloadLicense($stateParams.id).then(function(response) {
                var blob = new Blob([response.data[0]], {type: 'text/plain'}),
                url = $window.URL || $window.webkitURL;
                var a = $("<a style='display: none;'/>");
                var url = url.createObjectURL(blob);
                a.attr("href", url);
                a.attr("download", "license.txt");
                $("body").append(a);
                a[0].click();
                window.URL.revokeObjectURL(url);
                a.remove();
            }).catch(function(error) {
                console.log(error);
                Notification.error("Unable to download license text");
            })
        }
    }
})();
