(function() {
    'use strict';

    angular.module('adminApp.coreapi')
        .controller('CoreApiNewModalController', CoreApiNewModalController);

    CoreApiNewModalController.$inject = ['$rootScope', '$state', '$uibModalInstance', 'coreApiClientService', 'Notification'];

    function CoreApiNewModalController($rootScope, $state, $uibModalInstance, coreApiClientService, Notification) {
        var vm = this;

        vm.client = {};
        vm.close = close;
        vm.formErrors = {};
        vm.modalOptions = {
            actionClass: 'alert-info',
            actionMessage: 'Saving...',
            proceedButton: {
                btnClass: 'btn-primary',
                btnSpanClass: 'glyphicon-floppy-save',
                btnText: 'Save'
            },
            template: 'view/coreapi.modals.new_modal',
            title: 'Save CoreApi Client'
        };
        vm.proceed = proceed;
        vm.showActionSpinner = false;

        function close() {
            $uibModalInstance.dismiss();
        }

        function proceed() {
            vm.showActionSpinner = true;
            vm.formErrors = {};
            coreApiClientService.submit(vm.client)
                .then(function(response) {
                    Notification.success(response.message);
                    $state.go('coreapi.view', {id: response.data.id});
                    $uibModalInstance.dismiss();
                }).catch(function(error) {
                    switch (error.status) {
                        case 400:
                            angular.forEach(error.data.data, function(value, key) {
                                vm.formErrors[key] = value[0];
                            });
                            Notification.error("Form errors exist");
                            break;
                        case 403:
                            Notification.error(roleError);
                            close();
                            break;
                        default:
                            Notification.error("An unknown error has occurred");
                            break;
                    }
                }).finally(function() {
                    vm.showActionSpinner = false;
                });
        }
    }
})();
