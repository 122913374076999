(function() {
    'use strict';

    angular.module('adminApp.home')
        .controller('SupportController', SupportController);

    SupportController.$inject = [];

    function SupportController() {
        var vm = this;

        vm.links = [
            {
                text: 'Status',
                click: 'http://status.scientiamobile.com/'
            },
            {
                text: 'Pingdom',
                click: 'https://my.pingdom.com/reports/public'
            },
            {
                text: 'WURFL.js CE',
                click: 'http://zabbix/zabbix/screens.php?elementid=31'
            },
            {
                text: 'WURFL.js BE',
                click: 'http://zabbix/zabbix/screens.php?elementid=32'
            },
            {
                text: 'WURFL Cloud',
                click: 'http://kibana.scientiamobile.com/goto/b016061cf11ff5ee180ca6722c821f9f'
            },
            {
                text: 'ImageEngine',
                click: 'http://status.scientiamobile.com/2318497'
            },
            {
                text: 'XML Downloads',
                click: 'http://status.scientiamobile.com/2318494'
            },
            {
                text: 'WURFL Microservice',
                click: 'http://status.aws.amazon.com/'
            }//,//NOTE Comment out below
            // {
            //     text: 'InSight ',
            //     click: 'http://zabbix/zabbix/screens.php?elementid=43'
            // },
            // {
            //     text: 'InSight / FTP',
            //     click:
            // },
            // {
            //     text: 'FileX',
            //     click: 'http://zabbix/zabbix/charts.php?hostid=10476'
            // },
            // {
            //
            // }
        ];
    }
})();
