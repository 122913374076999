(function() {
    'use strict';

    angular.module('adminApp.coreapi')
        .controller('ScopesViewController', ScopesViewController);

    ScopesViewController.$inject = ['$rootScope', '$stateParams', 'breadcrumbsService', 'coreApiModalService', 'coreApiScopeService'];

    function ScopesViewController($rootScope, $stateParams, breadcrumbsService, coreApiModalService, coreApiScopeService) {
        var vm = this;

        vm.createNew = createNew;
        vm.deleteScope = deleteScope;
        vm.edit = edit;
        vm.loading = true;
        vm.loadingScope = true;
        vm.scope = {};
        vm.steps = breadcrumbsService.get('scopes.view', {scope: $stateParams.id});

        getScope();

        $rootScope.$on('reloadScope', function() {
            getScope();
        });

        function getScope() {
            vm.loading = true;
            vm.loadingScope = true;
            coreApiScopeService.getScope($stateParams.id).then(function(response) {
                vm.scope = response;
                vm.loading = false;
            }).catch(function(error) {
                console.log(error);
            }).finally(function() {
                vm.loadingScope = false;
            });
        }

        function createNew() {
            coreApiModalService.createScope();
        }

        function deleteScope() {
            coreApiModalService.deleteScope(vm.scope);
        }

        function edit() {
            coreApiModalService.editScope(angular.copy(vm.scope));
        }
    }
})();
