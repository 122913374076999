(function() {
    'use strict';

    angular.module('adminApp.users')
        .controller('UsersAccountController', UsersAccountController);

    UsersAccountController.$inject = ['$filter', '$stateParams', 'baseAccountService', 'breadcrumbsService', 'cloudAccountService', 'licenseService', 'userService', 'witAccountService', 'wjsAccountService'];

    function UsersAccountController($filter, $stateParams, baseAccountService, breadcrumbsService, cloudAccountService, licenseService, userService, witAccountService, wjsAccountService)
    {
        var vm = this;

        vm.id = $stateParams.id;
        vm.cloud = [];
        vm.licenses = [];
        vm.show = {
            cloud: {
                exist: false,
                loading: true
            },
            license: {
                exist: false,
                loading: true
            },
            wit: {
                exist: false,
                loading: true
            },
            wjs: {
                exist: false,
                loading: true
            }
        };
        vm.showLicense = false;
        vm.steps = {};
        vm.username = null;
        vm.wit = [];
        vm.wjs = [];

        userService.get($stateParams.id).then(function(response) {
            vm.username = response.data.username;
            vm.steps = breadcrumbsService.get('users.myaccount', {
                username: vm.username,
                id: $stateParams.id
            });
        }).catch(function(error) {
            console.log(error);
        });

        licenseService.getByUser($stateParams.id)
            .then(function(response) {
                vm.licenses = response.data;
                vm.show.license.loading = false;
                if (response.data.length > 0) {
                    vm.show.license.exist = true;
                }
            }).catch(function(error) {
                vm.show.license.loading = false;
                console.log(error);
                Notification.error(error.data.message);
            });

        baseAccountService.gatherByUser(cloudAccountService.resourceUrl, vm.id).then(function(response) {
            if (response.data) {
                vm.cloud = response.data;
                vm.show.cloud.loading = false;
                if (response.data.length > 0) {
                    vm.show.cloud.exist = true;
                }
            }
        }).catch(function(error) {
            vm.show.cloud.loading = false;
            console.log(error);
            Notification.error(error.data.message);
        });

        baseAccountService.gatherByUser(wjsAccountService.resourceUrl, vm.id).then(function(response) {
            if (response.data) {
                vm.wjs = response.data;
                vm.show.wjs.loading = false;
                if (response.data.length > 0) {
                    vm.show.wjs.exist = true;
                }
            }
        }).catch(function(error) {
            vm.show.wjs.loading = false;
            console.log(error);
            Notification.error(error.data.message);
        });

        baseAccountService.gatherByUser(witAccountService.resourceUrl, vm.id).then(function(response) {
            if (response.data) {
                vm.wit = response.data;
                vm.show.wit.loading = false;
                if (response.data.length > 0) {
                    vm.show.wit.exist = true;
                }
            }
        }).catch(function(error) {
            vm.show.wit.loading = false;
            console.log(error);
            Notification.error(error.data.message);
        });
    }
})();
