(function() {
    'use strict';

    angular.module('authApp')
        .factory('endSession', endSession);

    endSession.$inject = ['rolePermissions', 'menuService'];

    function endSession(rolePermissions, menuService) {
        var service = {
            end: end
        };

        return service;

        function end() {
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('refresh_token_expiration');
            localStorage.removeItem('access_token');
            localStorage.removeItem('access_token_expiration');
            localStorage.removeItem('shortHistory');
            localStorage.removeItem('fullHistory');
            rolePermissions.roles = undefined;
            menuService.reset();
        }
    }
})();
