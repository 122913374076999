(function() {
    'use strict';

    angular.module('adminApp.licenses')
        .controller('LicenseRevokeController', LicenseRevokeController);

    LicenseRevokeController.$inject = ['$rootScope', '$state', '$uibModalInstance', 'licenseId', 'licenseService', 'Notification'];

    function LicenseRevokeController($rootScope, $state, $uibModalInstance, licenseId, licenseService, Notification) {
        var vm = this;

        vm.close = close;
        vm.classes = ['form-control'];
        vm.labelClasses = ['control-label'];
        vm.modalOptions = {
            actionClass: 'alert-danger',
            actionMessage: 'Revoking license...',
            proceedButton: {
                btnClass: 'btn-danger',
                btnSpanClass: 'glyphicon-ban-circle',
                btnText: 'Revoke'
            },
            template: 'view/licenses.modals.revoke',
            title: 'Are you sure?'
        };
        vm.proceed = proceed;
        vm.revokeReason = '';
        vm.showActionSpinner = false;

        function close() {
            $uibModalInstance.dismiss();
        }

        function proceed() {
            vm.classes = [vm.classes[0]];
            vm.labelClasses = [vm.labelClasses[0]];
            if (vm.revokeReason === '') {
                vm.classes.push('revokeissue');
                vm.labelClasses.push('revokelabel');
            } else {
                vm.showActionSpinner = true;
                licenseService.postRevoke(licenseId, vm.revokeReason).then(function(response) {
                     Notification.success("The license has been revoked");
                     if ($state.current.name == 'licenses.view') {
                         $rootScope.$broadcast('reloadLicense');
                     } else if ($state.current.name == 'licenses.list') {
                         $rootScope.$broadcast('reloadLicenses');
                     }
                     $uibModalInstance.dismiss();
                }).catch(function(error) {
                    console.log(error);
                    Notification.error("An error occurred");
                }).finally(function() {
                    vm.showActionSpinner = false;
                });
            }
        }
    }
})();
