(function() {
    'use strict';

    angular.module('adminApp.wjs')
        .controller('WjsSearchController', WjsSearchController);

    WjsSearchController.$inject = ['$stateParams', 'breadcrumbsService', 'wjsAccountService'];

    function WjsSearchController($stateParams, breadcrumbsService, wjsAccountService) {
        var vm = this;

        vm.keyword = $stateParams.keyword;
        vm.listState = "wjs.list";
        vm.metaInfoLabel = 'Domains;CNames';
        vm.searchState = "wjs.search";
        vm.service = wjsAccountService;
        vm.steps = breadcrumbsService.get('wjs.search');
        vm.tableType = 'wjsSearch';
    }
})();
