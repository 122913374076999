(function() {
    'use strict';

    angular.module('adminApp.licenses')
        .controller('InsightController', InsightController);

    InsightController.$inject = ['$state', '$stateParams', 'INSIGHT_SERVER_URL', 'licenseService', 'Notification'];

    function InsightController($state, $stateParams, INSIGHT_SERVER_URL, licenseService, Notification) {
        var vm = this;

        var settings = {
            wurfl_mode : 'WURFL mode',
            enable_default : 'New files',
        };

        vm.createKey = createKey;
        vm.form = {};
        vm.insight = {};
        vm.server = INSIGHT_SERVER_URL;
        vm.setting = setting;
        vm.toggleFile = toggleFile;
        vm.updateKey = updateKey;

        function getErrorMessage(error) {
            switch (error.status) {
                case 400:
                    return error.data.message;
                case 403:
                    return 'You are not permitted to perform this action';
                case 500:
                    return "An unexpected error occurred";
                default:
                    return "An unexpected error occurred";
            }
        }

        licenseService.insight_profile($stateParams.id).then(function(response) {
            vm.insight = response.data;

            licenseService.processInsightFiles(vm.insight.files);

            // copy the settings, so we can compare the new values
            vm.form = _.clone(vm.insight);
        }, function(error) {
            Notification.error(getErrorMessage(error));
            console.log(error);
        });

        function createKey(type) {
            licenseService.new_insight_key(vm.insight, type)
                .then(function(response) {
                    var key = response.data;
                    vm.insight.keys[type] = key;
                    Notification.success('The key hes been created successfully');
                }, function(error) {
                    Notification.error(getErrorMessage(error));
                    console.log(error);
                });
        }

        function setting(type) {
            var currentValue = vm.insight[type];
            var newValue = vm.form[type];
            if (currentValue == newValue) {
                return false;
            }

            var postData = {};
            postData[type] = newValue;

            licenseService.profile(vm.insight, postData)
                .then(function(response) {
                    if (_.isBoolean(newValue) || _.isNumber(newValue)) {
                        if (_.isBoolean(newValue) && newValue===true) {
                            newValue = 'On';
                        } else if (_.isBoolean(newValue) && newValue===false) {
                            newValue = 'Off';
                        } else if (_.isNumber(newValue) && newValue===0) {
                            newValue = 'Off';
                        } else if (_.isNumber(newValue) && newValue===1) {
                            newValue = 'On';
                        }
                    }
                    vm.insight[type] = newValue;
                    Notification.success(settings[type] + ' has been set to ' + newValue);
                }, function(error) {
                    vm.insight[type] = currentValue;
                    vm.form[type] = currentValue;
                    Notification.error(getErrorMessage(error));
                    console.log(error);
                });
        }

        function toggleFile(file) {
            var value = file.enabled === true ? 1 : 0;

            licenseService.insightFile(file, {enabled: value}).then(function(response) {

                var message = "File ("+file.filename+") has been ";
                if (file.enabled) {
                    message += "set";
                    Notification.success(message);
                } else {
                    message += "unset";
                    Notification.warning(message);
                }
            });
        }

        function updateKey(key, type) {
            // type -> 'readwrite', 'readonly'
            licenseService.insight_key(vm.insight, key)
                .then(function(response) {
                    key = response.data;
                    vm.insight.keys[type] = key;

                    Notification.success('The key hes been set successfully');
                }, function(error) {
                    Notification.error(getErrorMessage(error));
                    console.error(error);
                });
        }
    }
})();
