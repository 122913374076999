(function() {
    'use strict';

    angular.module('adminApp.licenses')
        .directive('buildArchSelect', buildArchSelect);

    buildArchSelect.$inject = ['asOptionsFactory'];

    function buildArchSelect(asOptionsFactory) {
        var directive = {
            restrict: 'E',
            transclude: true,
            replace: true,
            template: '<select></select>',
            scope:false,
            link: link
        };

        return directive;

        function link(scope, element, attrs) {

            scope.arch_disable = true;

            scope.$watch("profile.build_product_id", function(to, from) {

                if (to != undefined) {
                    asOptionsFactory.asBuildArchOptions(to)
                        .$promise.then(function(response) {
                            scope.architectures = response.data;
                            angular.forEach(scope.architectures, function(val, key) {
                                if (val.name == '32-bit') {
                                    val.disabled = true;
                                } else {
                                    val.disabled = false;
                                }
                            });
                            scope.arch_disable  = false;

                            if(scope.architectures.length == 1) {
                                scope.profile.build_arch_id = scope.architectures[0].id
                            }
                        }
                    );
                }
            });


        }
    }
})();
