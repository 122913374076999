(function() {
    'use strict';

    angular.module('adminApp')
        .service('historyService', historyService);

    historyService.$inject = ['$state', 'Notification']
    function historyService($state, Notification) {
        var service = {
            clear: clear,
            set: set
        };

        service.list = localStorage.getItem('shortHistory') != null ?
            JSON.parse(localStorage.getItem('shortHistory')) : [];

        service.full = localStorage.getItem('fullHistory') != null ?
            JSON.parse(localStorage.getItem('fullHistory')) : [];

        // service.full = [];
        // service.list = [];

        return service;

        function formatAMPM(date) {
            var hours = date.getHours();
            var minutes = date.getMinutes();
            var ampm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            minutes = minutes < 10 ? '0'+minutes : minutes;
            var strTime = hours + ':' + minutes + ' ' + ampm;
            return strTime;
        }

        function clear() {
            localStorage.removeItem('shortHistory');
            localStorage.removeItem('fullHistory');
            if ($state.current.name === 'history') {
                service.list = [];
                service.full = [];
            } else {
                service.list = [service.list[0]];
                service.full = [service.full[0]];
                localStorage.setItem('fullHistory', JSON.stringify(service.full));
                localStorage.setItem('shortHistory', JSON.stringify(service.list));
            }
        }

        function set(name, params, data) {

            if (data.niceName !== undefined) {
                var matches = data.niceName.match(/\{(.*?)\}/);
                var niceName = data.niceName;

                if (matches) {
                    niceName = data.niceName.replace(matches[0], params[matches[1]]);
                }
                service.list.unshift({
                    name: name,
                    params: params,
                    niceName: niceName
                });
                var date = new Date()
                var days = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
                var month = [
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sept",
                    "Oct",
                    "Nov",
                    "Dec"
                ];
                service.full.unshift({
                    name: name,
                    params: params,
                    niceName: niceName,
                    date: days[date.getDay()]+", "+month[date.getMonth()]+" "+date.getDay()+", "+date.getFullYear(),
                    time: formatAMPM(date)
                });
                localStorage.setItem('fullHistory', JSON.stringify(service.full));
                if (service.list.length == 11) {
                    service.list.pop();
                }
                localStorage.setItem('shortHistory', JSON.stringify(service.list));
            } else if (name != 'history') {
                Notification.error("No nice name");
            }
        }
    }
})();
