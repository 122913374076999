(function() {
    'use strict';

    angular.module('adminApp')
        .controller('CountryController', CountryController);

    CountryController.$inject = ['$rootScope', '$scope', 'countriesService', 'Notification'];

    function CountryController($rootScope, $scope, countriesService, Notification) {
        var vm = this;

        vm.countries = [];
        vm.country_disable = true;
        vm.country_id = (_.isUndefined(vm.model) || vm.model === null) ? 230 : vm.model.country_id;
        vm.initSelect = initSelect;
        vm.set = set;

        countriesService.asOptions().$promise
            .then(function(response) {
                vm.countries = response.data;
                vm.country_disable = false;
                $rootScope.$broadcast('loadedCountryDirective');
            }, function(error) {
                Notification.error('An unexpected error occurred');
                console.error(error);
            });

        function initSelect()
        {
            set(vm.country_id);
            return vm.country_id;
        }

        function set(newId) {
            vm.country_id = newId;
            vm.model = _.findWhere(vm.countries, {id: vm.country_id});
            vm.model.country_id = vm.country_id;
            $rootScope.$broadcast('updateCountryDirective', {model: vm.model});
        }
    }
})();
