(function() {
    'use strict';

    angular.module('adminApp.quwurfl')
        .controller('QuWurflCapabilitiesController', QuWurflCapabilitiesController);

    QuWurflCapabilitiesController.$inject = ['$rootScope', '$stateParams', '$uibModalInstance', 'baseAccountService', 'capabilities', 'saasConstants'];

    function QuWurflCapabilitiesController($rootScope, $stateParams, $uibModalInstance, baseAccountService, capabilities, saasConstants) {
        var vm = this;
        vm.capabilities = [];
        // remap capabilities to follow current implementation
        angular.forEach(capabilities, function(val, key) {
            vm.capabilities.push({
                capability: val.name,
                id: val.quwurfl_capabilities_id,
            });
        });
        vm.close = close;
        vm.max = -1;

        function close() {
            $uibModalInstance.dismiss();
            // We need to refresh data in the main view... 
            // Going to force a refresh :-)
            // TODO: figure how to refresh the main view data in the angular 1.x way 
            location.reload();
        }
    }
})();
