(function() {
    'use strict';

    angular.module('adminApp.licenses')
        .factory('licenseService', licenseService);

    licenseService.$inject = ['$filter', '$resource'];

    function licenseService($filter, $resource) {
        var resource = new $resource('/api/admin/licenses/:id/:action/:action_id', {id: '@id'}, {
            fetchCapabilities: {
                params: {action: 'capabilities', action_id: null},
                method: 'GET'
            },
            gatherAccountInfo: {
                params: {action: 'account', action_id: null},
                method: 'GET'
            },
            getByType: {
                method: 'GET'
            },
            getByUser: {
                method: 'GET',
                params: {action: 'user'}
            },
            getProductOptions: {
                method: 'GET',
                params: {
                    action: 'products',
                    action_id: null
                }
            },
            getText: {
                params: {action: 'text', action_id: null},
                method: 'GET'
            },
            getWithRelations: {
                params: {action: 'relations', action_id: null},
                method: 'GET'
            },
            insight: {
                params: {action: 'insight', action_id: null},
                method: 'GET'
            },
            postDownloadLicense: {
                method: 'POST',
                params: {
                    action: 'text',
                    action_id: null
                }
            },
            postEnable: {
                method: 'POST',
                params: {
                    action: 'enable',
                    action_id: null
                }
            },
            postRevoke: {
                params: {action: 'revoke', action_id: null},
                method: 'POST'
            },
            remove: {
                params: {action: null, action_id: null},
                method: 'DELETE'
            },
            save: {
                method: 'POST',
                params: {action: null, action_id: null}
            },
            text: {
                params: {action: 'text', action_id: null},
                method: 'GET'
            },
            transfer: {
                params: {action: 'transfer', action_id: null},
                method: 'POST'
            },
            update: {
                method: 'PATCH'
            },
            updateCapabilities: {
                params: {action: 'capabilities', action_id: null},
                method: 'PUT'
            }
        });

        var insight = new $resource('/api/admin/insight/:license_id/:action/:id', {}, {
            all: {
                method: 'GET',
                params: {
                    action: 'profiles',
                    page: '@page',
                    user: '@user',
                    username: '@username',
                    license_id_search: '@license_id_search'
                }
            },
            capabilityGroups: {
                params: {action: 'capability-groups'},
                method: 'GET'
            },
            capabilities: {
                params: {action: 'capabilities'},
                method: 'PATCH'
            },
            create: {
                params: {action: 'create'},
                method: 'POST'
            },
            key: {
                params: {action: 'key'},
                method: 'PATCH'
            },
            new_key: {
                params: {action: 'create-key'},
                method: 'POST'
            },
            profile: {
                params: {action: 'profile'},
                method: 'PATCH'
            },
            update_file : {
                params: {action: 'file'},
                method: 'PATCH'
            }
        });

        var values = {};

        var service = {
            all: all,
            createInsightProfile: createInsightProfile,
            fetchCapabilities: fetchCapabilities,
            gatherAccountInfo: gatherAccountInfo,
            get: get,
            getByType: getByType,
            getByUser: getByUser,
            getProductOptions: getProductOptions,
            getText: getText,
            getValues: getValues,
            getWithRelations: getWithRelations,
            insightCapabilityGroups: insightCapabilityGroups,
            insight_profile: insight_profile,
            insight_capabilities: insight_capabilities,
            insightFile: insightFile,
            insight_key: insight_key,
            new_insight_key: new_insight_key,
            postDownloadLicense: postDownloadLicense,
            postEnable: postEnable,
            postRevoke: postRevoke,
            processInsightFiles: processInsightFiles,
            profile: profile,
            profiles: profiles,
            remove: remove,
            save: save,
            setValues: setValues,
            text: text,
            transfer: transfer,
            update: update,
            updateCapabilities: updateCapabilities
        };

        service.allProducts = null;

        return service;

        function all(options) {
            return resource.get(options).$promise;
        }

        function createInsightProfile(licenseId) {
            return insight.create({id: null}, {license_id: licenseId}).$promise;
        }

        function fetchCapabilities(id) {
            return resource.fetchCapabilities({id:id}).$promise;
        }

        function gatherAccountInfo(id) {
            return resource.gatherAccountInfo({id: id}).$promise;
        }

        function get(id) {
            return resource.get({id : id}).$promise;
        }

        function getByType(type, userId) {
            return resource.getByType({id: null, action: type, action_id: userId}).$promise;
        }

        function getByUser(userId) {
            return resource.getByUser({id: null, action_id: userId}).$promise;
        }

        function getProductOptions() {
            return resource.getProductOptions({id: null}).$promise;
        }

        function getText(id) {
            return resource.getText({id: id}).$promise;
        }

        function getValues(id, key) {
            if (values[id] !== undefined) {
                return values[id][key];
            }
            return undefined;
        }

        function getWithRelations(id) {
            return resource.getWithRelations({id: id}).$promise;
        }

        function insightCapabilityGroups(name) {
            return insight.capabilityGroups({id: name, license_id: null}).$promise;
        }

        // Insight Profile
        function insight_profile(id) {
            return resource.insight({id: id}).$promise;
        }

        // Update the insight profile capabilities
        function insight_capabilities(profile, capabilities) {
            return insight.capabilities(
                {
                    license_id : profile.license_id,
                    id : profile.id
                },
                {
                    capabilities : capabilities
                }
            ).$promise;
        }

        function insightFile(file, patch_data) {
            return insight.update_file(
                {
                    license_id : file.insight_profile_id,
                    id : file.id
                }, patch_data
            ).$promise;
        }

        function insight_key(profile, key) {
            return insight.key(
                {
                    // license_id : profile.license_id,
                    license_id: profile.id,
                    id : key.id
                }, {}
            ).$promise;
        }

        function new_insight_key(profile, type) {
            return insight.new_key(
                {license_id : profile.license_id, id : profile.id},
                {access: type}
            ).$promise;
        }

        function postDownloadLicense(id) {
            return resource.postDownloadLicense({id: id}).$promise;
        }

        function postEnable(id) {
            return resource.postEnable({id: null}, {id: id}).$promise;
        }

        function postRevoke(id, reason) {
            return resource.postRevoke({id: null}, {id: id, reason: reason}).$promise;
        }

        // Process the insight files and convert the `enabled` property to boolean
        function processInsightFiles(files) {

            angular.forEach(files, function(value, key) {
                if (value.enabled == 1) {
                    value.enabled = true;
                } else {
                    value.enabled = false;
                }
            });
        }

        function profile(profile, data) {
            return insight.profile(
                {license_id : profile.license_id, id : profile.id},
                data
            ).$promise;
        }

        function profiles(options) {
            var obj = {
                id: null,
                license_id: null
            };
            angular.forEach(options, function(val, key) {
                key = key === 'license_id' ? 'license_id_search' : key;
                obj[key] = val;
            });
            return insight.all(obj).$promise;
        }

        function remove(id) {
            return resource.remove({id: id}).$promise;
        }

        function save(form) {
            if (form.license_date) {
                form.license_date = form.license_date.toDateString();
            }

            if (form.license_date) {
                form.license_expire = $filter('date')(form.license_expire, 'EEE MMM dd yyyy HH:mm:ss Z', 'GMT-0400');
            }
            return resource.save({id: null}, form).$promise;
        }

        function setValues(id, key, value) {
            if (values[id] === undefined) {
                values[id] = {};
            }
            values[id][key] = value;
        }

        function text(license) {
            return resource.text({id: license.id}).$promise;
        }

        function transfer(license, user) {
            return resource.transfer(
                {id: license.id}, {user_id: user.id}
            ).$promise;
        }

        function update(form) {
            return resource.update({id:form.id}, form).$promise;
        }

        function updateCapabilities(license_id, capabilities) {
            return resource.updateCapabilities(
                {id:license_id},
                {data: capabilities}
            ).$promise;
        }
    }
})();
