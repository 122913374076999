(function() {
    'use strict';

    angular.module('adminApp.wit')
        .controller('WitSearchController', WitSearchController);

    WitSearchController.$inject = ['$stateParams', 'breadcrumbsService', 'witAccountService'];

    function WitSearchController($stateParams, breadcrumbsService, witAccountService) {
        var vm = this;

        vm.keyword = $stateParams.keyword;
        vm.listState = "wit.list";
        vm.metaInfoLabel = 'Token';
        vm.searchState = "wit.search";
        vm.service = witAccountService;
        vm.steps = breadcrumbsService.get('wit.search');
        vm.tableType = 'witSearch';
    }
})();
