(function() {
    'use strict';

    angular.module('adminApp.reports')
        .controller('ReportsController', ReportsController);

    ReportsController.$inject = ['$uibModal', 'reportsService'];

    function ReportsController($uibModal, reportsService) {
        var vm = this;

        vm.downloadReports = downloadReports;
        vm.open = false;
        vm.reports = [
            {
                text: 'Snapshot Downloads by Customer (Default 180 days)',
                click: 'Snapshots',
                date: true
            },
            {
                text: 'Cloud Stats by Customer (Default 90 days)',
                click: 'CloudUsage',
                date: true
            },
            {
                text: 'Daily Expiring Evaluation Licenses (Default Today)',
                click: 'ExpiringEvaluationLicenses',
                date: true
            },
            {
                text: 'Daily Expiring Licenses (Default 5 days)',
                click: 'ExpiringLicenses',
                date: true
            },
            {
                text: 'Daily Expiring Subscriptions (Default Today)',
                click: 'ExpiringSubscriptions',
                date: true
            },
            {
                text: 'Users with Premium Capabilities',
                click: 'UserSubscriptionPremiumCapabilities',
                date: false
            }
        ];

        function downloadReports(name) {
            $uibModal.open({
                templateUrl: 'view/components.download_modal',
                controller: 'ReportsDatesController',
                controllerAs: 'vm',
                size: 'lg',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    name: function() {
                        return name;
                    }
                }
            });
        }

    }
})();
