(function() {
    'use strict';

    angular.module('adminApp.wjs')
        .controller('WjsViewAccountController', WjsViewAccountController);

    WjsViewAccountController.$inject = ['$filter', '$state', '$stateParams', '$uibModalInstance', 'baseAccountService', 'wjsAccountService', 'Notification'];

    function WjsViewAccountController($filter, $state, $stateParams, $uibModalInstance, baseAccountService, wjsAccountService, Notification) {
        var vm = this;

        vm.close = close;
        vm.dataLoaded = false;
        vm.endDate = false;
        vm.showAllowed = false;
        vm.startDate = null;
        vm.plan = null;
        vm.progressCss = "info";
        vm.viewSubscription = viewSubscription;

        var userId = null;
        var wjsId = null;
        baseAccountService.get(wjsAccountService.resourceUrl, $stateParams.id).then(function(response) {
            userId = response.data.account.user.id;
            wjsId = response.data.account.id;
            vm.plan = response.data.account.plan.label;
            var defaults = baseAccountService.remapLimits(response.data.account.wurfl_js_accounts_defaults, 'wurfl_js_default');
            var numCapabilityAddons = response.data.account.num_capability_addons;
            var allowedDetections = defaults.detections.value;
            baseAccountService.billingPeriods(wjsAccountService.resourceUrl, $stateParams.id)
                .then(function(response) {
                    var currentPeriod = response.data[0];
                    vm.startDate = $filter('dateSuffix')(currentPeriod.start.date);
                    vm.endDate = $filter('dateSuffix')(currentPeriod.end.date);
                    baseAccountService.usage(wjsAccountService.resourceUrl, $stateParams.id, currentPeriod.start.date, currentPeriod.end.date)
                        .then(function(response) {
                            var totalDetections = response.data.total_detections;
                            if (allowedDetections > 0) {
                                vm.usedPct = 0;
                                if (totalDetections > 0) {
                                    vm.usedPct = Math.round((totalDetections / allowedDetections) * 100);
                                    vm.progressCss = (vm.usedPct <= 60) ? "info" : (vm.usedPct > 60 && vm.usedPct <= 85) ? "warning" : "danger";
                                }
                                vm.showAllowed = true;
                                var millionsDetections = allowedDetections + (numCapabilityAddons*1000000);
                                vm.millionsDetections = parseInt(millionsDetections / 1000000);
                            } else {
                                vm.usedPct = totalDetections > 0 ? totalDetections : 'No Data';
                            }
                            vm.dataLoaded = true;
                        }).catch(function(error) {
                            Notification.error(error.data.message);
                            console.log(error);
                        });
                }).catch(function(error) {
                    Notification.error(error.data.message);
                    console.log(error);
                });
        }).catch(function(error) {
            Notification.error(error.data.message);
            console.log(error);
        });

        function close() {
            $uibModalInstance.dismiss();
            $state.go('users.myaccount', {id: userId});
        }

        function viewSubscription() {
            $uibModalInstance.dismiss();
            $state.go('wjs.view', {id: wjsId});
        }
    }
})();
