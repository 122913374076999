(function() {
    'use strict';

    angular.module('adminApp.apikey')
        .factory('apikeyModalService', apikeyModalService);

    apikeyModalService.$inject = ['$uibModal'];

    function apikeyModalService($uibModal) {
        var service = {
            createNew: createNew,
            edit: edit
        };

        return service;

        function createNew() {
            $uibModal.open({
                templateUrl: 'view/apikey.create',
                controller: 'ApikeyCreateController',
                controllerAs: 'vm',
                size: 'lg',
                backdrop: 'static',
                keyboard: false
            });
        }

        function edit() {
            $uibModal.open({
                templateUrl: 'view/apikey.edit',
                controller: 'ApikeyEditController',
                controllerAs: 'vm',
                size: 'lg',
                backdrop: 'static',
                keyboard: false
            });
        }
    }
})();
