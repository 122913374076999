(function() {
    'use strict';

    angular.module('authApp')
        .factory('authenticate', authenticate);

    authenticate.$inject = ['$rootScope', '$log', '$resource', 'Flash', 'loginService', 'userService'];

    function authenticate($rootScope, $log, $resource, Flash, loginService, userService) {
        var authFactory = {
            auth: auth,
            createIntended: createIntended,
            logout: logout,
            canRefreshToken: canRefreshToken,
            refreshToken: refreshToken
        };

        var resource = new $resource('api/auth/:action', {}, {
            token: {
                method: 'POST',
                params: {action: 'token'}
            },
            login: {
                method: 'POST',
                params: {action: 'login'}
            },
            logout: {
                method: 'POST',
                params: {action: 'logout'}
            }
        });

        return authFactory;

        function auth(credentials) {
            return resource.login({}, credentials).$promise
                .then(authSuccess)
                .catch(authFailed);

            function authSuccess(response) {
                return userService.getRoles(response.data.user.id).then(function(res) {
                    if (res.data.length == 1 && res.data[0] == 'user') {
                        var message = "<h4>I'm sorry, but I can't let you do that.</h4><p>You are not authorized to access this application.</p>";
                        Flash.create('danger', message, 0, {class: 'custom-class', id: 'custom-id'}, true);
                        return false;
                    }
                    localStorage.setItem('refresh_token', response.data.token.token);
                    localStorage.setItem('refresh_token_expiration', response.data.token.expiration);

                    var user = response.data.user;

                    loginService.authInfo.authenticated = true;
                    loginService.authInfo.user = user;

                    return authFactory.refreshToken().then(function() {
                        return user;
                    }).catch(authFailed);
                });
            }

            function authFailed(error) {
                $log.error(error);
                var message = "<h4>I'm sorry, but...</h4><p>I couldn't find your account.</p>";
                Flash.create('danger', message, 0, {class: 'custom-class', id: 'custom-id'}, true);
                return false;
            }
        }

        function canRefreshToken() {
            var userRefreshToken = localStorage.getItem('refresh_token');

            return userRefreshToken && userRefreshToken !== "null";
        }

        function refreshToken() {
            var userRefreshToken = localStorage.getItem('refresh_token');

            return resource.token({}, {refresh_token: userRefreshToken}).$promise
                .then(refreshTokenSuccess)
                .catch(refreshTokenFailed);

            function refreshTokenSuccess(response) {
                localStorage.setItem('access_token', response.data.token.token);
                localStorage.setItem('access_token_expiration', response.data.token.expiration);

                loginService.authInfo.authenticated = true;
                if (response.data.user) {
                    loginService.authInfo.user = response.data.user;
                }
                $rootScope.$broadcast('authenticated');
                
                return true;
            }
            
            function refreshTokenFailed(error) {
                return false;
            }
        }

        function createIntended(toState, toParams) {
            if (loginService.intended === undefined && toState.name !== 'login') {
                loginService.intended = {name: toState.name, params: toParams};
            }
            return;
        }

        function logout() {
            return resource.logout({}).$promise
                .then(logoutSuccess)
                .catch(logoutFailed);

            function logoutSuccess() {
                localStorage.removeItem('refresh_token');
                localStorage.removeItem('refresh_token_expiration');
                localStorage.removeItem('access_token');
                localStorage.removeItem('access_token_expiration');
                loginService.authInfo.authenticated = false;
                loginService.authInfo.user = null;

                return true;
            }

            function logoutFailed() {
                return false;
            }
        }
    }
})();
