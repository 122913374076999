(function() {
    'use strict';

    angular.module('adminApp.saas')
        .directive('accountsSearchQuWurfl', accountsSearchQuWurfl);

        accountsSearchQuWurfl.$inject = ['$state', 'quwurflAccountService'];

    function accountsSearchQuWurfl($state, quwurflAccountService) {
        var directive = {
            restrict: 'E',
            templateUrl: 'view/subscriptions.common.accounts_search_quwurfl_form',
            controller: 'SaasAccountSearchQuWurflController',
            controllerAs: 'vm',
            bindToController: true,
            scope: {
                product: '@'
            },
            link: link
        };

        return directive;

        function link(scope, element, attrs) {
            scope.vm.dir_disabled = attrs.dirDisabled == 'true' ? true : false;
            var input_element = angular.element('input[type=text]', element);

            scope.vm.label = attrs.label;
            scope.vm.button = attrs.button;
            scope.vm.successPlaceholder = attrs.placeholder;
            input_element.attr('placeholder', attrs.placeholder);

            input_element.attr('id', attrs.id);
            input_element.attr('name', attrs.name);

            input_element.addClass(attrs.class);
        }
    }
})();
