(function() {
    'use strict';

    angular.module('adminApp.licenses')
        .controller('LicensesListController', LicensesListController);

    LicensesListController.$inject = ['$location', '$rootScope', '$scope', '$state', '$stateParams', 'breadcrumbsService', 'licensesModalService', 'licenseService', 'Notification', 'paginationService'];

    function LicensesListController($location, $rootScope, $scope, $state, $stateParams, breadcrumbsService, licensesModalService, licenseService, Notification, paginationService) {
        var vm = this;

        vm.columns = [
            {
                name: 'ID',
                search: {
                    isSearchable: true,
                    type: 'text'
                },
                model: 'id',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                display: false,
                model: 'search_id',
                search: {
                    isSearchable: true
                }
            },
            {
                name: 'Username',
                search: {
                    isSearchable: true,
                    type: 'text'
                },
                model: 'user_name',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: 'Product',
                search: {
                    isSearchable: true,
                    type: 'product-directive',
                    model: 'search.product_id'
                },
                model: 'product_name',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                display: false,
                model: 'product_id',
                search: {
                    isSearchable: true
                }
            },
            {
                name: 'Type',
                search: {
                    isSearchable: true,
                    type: 'license-type-directive',
                    model: 'search.license_type_id'
                },
                model: 'license_type_name',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: 'Download Key',
                search: {
                    isSearchable: true,
                    type: 'text'
                },
                model: 'key',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                display: false,
                model: 'license_type_id',
                search: {
                    isSearchable: true
                }
            },
            {
                name: 'Licensee',
                search: {
                    isSearchable: true,
                    type: 'text'
                },
                model: 'licensee_name',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: 'Address',
                search: {
                    isSearchable: true,
                    type: 'text'
                },
                model: 'licensee_address',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: '',
                search: {
                    isSearchable: false
                },
                model: '',
                value: {
                    fromData: false,
                },
                display: true
            }
        ];
        vm.createNew = createNew;
        vm.download = download;
        vm.search = {
            search_id: '',
            user_name: '',
            product_id: '',
            license_type_id: '',
            licensee_name: '',
            licensee_address: ''
        };
        vm.steps = breadcrumbsService.get('licenses.list');

        var search_params = _.clone($location.search());
        var currentPage = $stateParams.page;
        getLicenses();

        var updateLicenseSearchParams = $rootScope.$on("licensesSearchParamsUpdated", function(event, args) {
            vm.search = args;
            currentPage = 1;
            var search = angular.copy(args);
            search.search_id = search.id;
            search.id = '';
            getLicenses(search);
        });
        var licensesPageChanged = $rootScope.$on("licensesPageChanged", function(event, args) {
            vm.search = args;
            currentPage = args.page;
            var search = angular.copy(args);
            search.search_id = search.id;
            search.id = '';
            getLicenses(search);
        });
        var licensesItemsPerPageChanged = $rootScope.$on("licensesItemsPerPageChanged", function(event, args) {
            vm.search = args;
            currentPage = 1;
            var search = angular.copy(args);
            search.search_id = search.id;
            search.id = '';
            getLicenses(search);
        });

        $rootScope.$on('reloadLicenses', function() {
            getLicenses();
        });


        function getLicenses(searchParams) {
            searchParams = typeof searchParams !== 'undefined' ? searchParams : null;

            var start = 0;
            var number = 50;

            var query_params = {};
            var next_page = 1;

            if (searchParams !== null) {
                next_page = searchParams.page;
                number = searchParams.paginate;
            } else if (search_params && _.isUndefined(search_params.page) === false) { // if defined
                next_page = search_params.page;
            } else {
                next_page = Math.floor(start/number) + 1;
            }

            query_params = {page: next_page};
            if (searchParams !== null) {
                query_params = _.extend(searchParams);
            }

            licenseService.all(query_params).then(function(response) {
                angular.forEach(response.data, function(val, key) {
                    if (val.user.username !== null) {
                        val.user_name = val.user.username;
                    }
                    if (val.product.product_name !== null) {
                        val.product_name = val.product.product_name;
                    }
                    if (val.type.license_type_name !== null) {
                        val.license_type_name = val.type.license_type_name;
                    }
                    if (val.revoked) {
                        val.addedClass = 'color: red;';
                    } else {
                        val.addedClass = '';
                    }
                    if (!_.isNull(val.download_key)) {
                        val.key = val.download_key.hash;
                    }
                    var expireDate = new Date(val.license_expire);
                    var today = new Date();
                    if (today > expireDate && !val.revoked) {
                        val.addedClass = 'color: orange;';
                    }
                });

                var paginationData = {};

                var numPages = response.pagination.last_page;
                paginationData.pages = paginationService.createPages(currentPage, numPages);
                paginationData.numPages = numPages;
                paginationData.currentPage = currentPage;
                paginationData.currentState = $state.current.name;
                paginationData.stateParams = $stateParams;
                paginationData.from = paginationService.from(response.pagination);
                paginationData.to = paginationService.to(response.pagination);
                paginationData.total = response.pagination.total;

                $rootScope.$broadcast('licensesDataLoaded', {
                    data: response.data,
                    paginationData: paginationData,
                    itemsPerPage: number
                });
                $scope.$on('$destroy', updateLicenseSearchParams);
                $scope.$on('$destroy', licensesPageChanged);
                $scope.$on('$destroy', licensesItemsPerPageChanged);
            }, function(error) {
                Notification.error('Could not fetch the licenses records');
                console.log(error);
                $scope.$on('$destroy', updateLicenseSearchParams);
                $scope.$on('$destroy', licensesPageChanged);
                $scope.$on('$destroy', licensesItemsPerPageChanged);
            });

            search_params = null;
        }

        function changeNumber() {
            getLicenses();
        }

        function createNew() {
            licensesModalService.create();
        }

        function download(type) {
            licensesModalService.download(type);
        }
    }
})();
