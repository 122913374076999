(function() {
    'use strict';

    angular.module('adminApp.wit')
        .directive('domainConf', domainConf);

    function domainConf() {
        var directive = {
            templateUrl: 'view/subscriptions.wit.components.domainconf',
            restrict: 'AE',
            replace: true,
            scope: {
                conf: '=',
                default: '=',
                deleteDomain: '&',
                formErrors: '=',
                saveDomain: '&'
            },
            controller: 'WitDomainConfController',
            controllerAs: 'vm',
            link: link
        };

        return directive;

        function link(scope, elem, attr, vm) {
            scope.$watch('formErrors', function(newVal) {
                vm.formErrors = newVal;
            });
            scope.$watch('conf', function(newVal) {
                vm.conf = newVal;
            });
        }
    }
})();
