(function() {
    'use strict';

    angular.module('adminApp.home')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['transferFlash', 'Flash'];

    function HomeController(transferFlash, Flash) {
        var flash = transferFlash.get();
        if (flash.message !== null) {
            Flash.create(flash.type, flash.message, 0, {class: 'custom-class', id: 'custom-id'}, true);
            transferFlash.set('message', null);
            transferFlash.set('type', null);
        } else {
            Flash.clear();
        }

        var vm = this;
    }
})();
