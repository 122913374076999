(function() {

    angular.module("adminApp.licenses")
        .directive("buildOsSelect", buildOsSelect);

    buildOsSelect.$inject = ['asOptionsFactory'];

    function buildOsSelect(asOptionsFactory) {
        var directive = {
            restrict: 'E',
            transclude: true,
            replace: true,
            template: '<select></select>',
            link: link
        };

        return directive;

        function link(scope, element, attrs) {

            scope.os_disable = true;

            scope.$watch("profile.build_product_id", function(to, from) {

                if (to != undefined) {
                    asOptionsFactory.asBuildOsOptions(to)
                        .$promise.then(function(response) {

                            var build_oses = [];

                            response.data.forEach(function(v) {
                                build_oses.push({
                                    'id' : v.id,
                                    'name' : {
                                        'value' : v.name,
                                        'active' : v.active
                                    }
                                })
                            });

                            scope.os = build_oses;
                            scope.os_disable  = false;

                        }
                    );
                }
            });
        }
    }
})();
