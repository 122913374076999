(function() {
    'use strict';

    angular.module('adminApp.users')
        .controller('UsersDeleteController', UsersDeleteController);

    UsersDeleteController.$inject = ['$rootScope', '$state', '$stateParams', '$uibModalInstance', 'accountCounts', 'accountLabels', 'id', 'Notification', 'userModalService', 'userService'];

    function UsersDeleteController($rootScope, $state, $stateParams, $uibModalInstance, accountCounts, accountLabels, id, Notification, userModalService, userService) {
        var vm = this;

        vm.accountCounts = accountCounts;
        vm.accountLabels = accountLabels;
        vm.close = close;
        vm.continue = continueOn;
        vm.showActionSpinner = false;
        vm.message = '';
        vm.modalOptions = {
            actionClass: 'alert-danger',
            actionMessage: 'Deleting...',
            cancelButton: {
                btnClass: 'btn-custom-default',
                btnSpanClass: 'glyphicon-remove',
                btnText: 'Cancel'
            },
            includeView: 'view/users.modals.delete',
            proceedButton: {
                btnClass: 'btn-danger',
                btnSpanClass: 'glyphicon-trash',
                btnText: 'Delete'
            },
            title: 'Delete User'
        };

        var total = 0;
        var userId = $stateParams.id === undefined ? id : $stateParams.id;

        generateMessage();

        if (accountCounts === null || accountLabels === null) {
            vm.loading = true;
            vm.accountLabels = {
                data: 'Data',
                infuze: 'InFuze',
                onsite: 'OnSite',
                insight: 'InSight',
                cloud: 'Cloud',
                wjs: 'WURFL.js',
                imgeng: 'ImageEngine'
            };
            userService.getAccountCount(userId).then(function(response) {
                vm.accountCounts = response.data;
                angular.forEach(vm.accountLabels, function(val, key) {
                    if (vm.accountCounts[key] == undefined) {
                        vm.accountCounts[key] = {
                            active: 0,
                            total: 0
                        };
                    }
                });
                vm.loading = false;
                generateMessage();
            }).catch(function(error) {
                Notification.error('Could not determine user accounts');
            });
        }

        $rootScope.$on('removeUser', function(event, args) {
            remove(args.id);
        });

        function generateMessage() {
            angular.forEach(vm.accountLabels, function(val, key) {
                if (vm.accountCounts[key] == undefined) {
                    vm.accountCounts[key] = {
                        active: 0,
                        total: 0
                    };
                } else {
                    total += vm.accountCounts[key].total;
                }
            });
            vm.message = "You are about to delete the user. <br /><br /><span class='text-danger'>This will completely and irreversibly remove the user from the database.</span>";
            if (total > 0) {
                vm.message += "<br /><br />You will first be required to transfer all accounts associated with the user to another user(s).";
                vm.modalOptions.proceedButton.btnText = 'Continue';
                vm.modalOptions.proceedButton.btnSpanClass = 'glyphicon-step-forward';
            }

            vm.message += "<br /><br />Are you sure you want to continue?";
        }

        function close() {
            $uibModalInstance.dismiss();
        }

        function continueOn() {
            if (total > 0) {
                $uibModalInstance.dismiss();
                userModalService.transferAccounts({accountCounts: vm.accountCounts, accountLabels: vm.accountLabels, revoke: false, remove: true, id: userId});
            } else {
                remove();
            }
        }

        function remove(id) {
            vm.showActionSpinner = true;
            id = id === undefined ? userId : id;
            vm.loading = true;
            userService.deleteUser(id).then(function(response) {
                Notification.success('User '+id+' has been deleted');
                $uibModalInstance.dismiss();
                if ($state.current.name == 'users.list') {
                    $rootScope.$broadcast('reloadUsers', $stateParams);
                } else {
                    $state.go('users.list');
                }
            }).catch(function(error) {
                console.log(error);
                Notification.error('Could not delete user');
            }).finally(function() {
                vm.showActionSpinner = false;
            });
        }
    }
})();
