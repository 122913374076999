(function() {
    'use strict';

    angular.module('adminApp.coreapi')
        .controller('CoreapiMenuController', CoreapiMenuController);

    CoreapiMenuController.$inject = ['$ngConfirm', '$state', 'coreApiModalService', 'menuService'];
    function CoreapiMenuController($ngConfirm, $state, coreApiModalService, menuService) {
        var vm = this;

        vm.buttonsDisabled = menuService.getMenu('admin');
        vm.client = undefined;
        vm.createNew = createNew;
        vm.createNewScope = createNewScope;
        vm.scopes = undefined;
        vm.viewClient = viewClient;
        vm.viewScope = viewScope;

        function createNew() {
            coreApiModalService.create();
        }

        function createNewScope() {
            coreApiModalService.createScope();
        }

        function viewClient() {
            if (!_.isUndefined(vm.client.id)) {
                $state.go('coreapi.view', {id: vm.client.id});
            } else {
                $ngConfirm({
                    title: 'Alert!',
                    theme: 'dark',
                    content: "<p>Please select a client</p>",
                    buttons: {
                        close: {
                            text: 'OK',
                            btnClass: 'btn-danger'
                        }
                    }
                });
            }
        }

        function viewScope() {
            if (!_.isUndefined(vm.scopes)) {
                $state.go('scopes.view', {id: vm.scopes.scope});
            } else {
                $ngConfirm({
                    title: 'Alert!',
                    theme: 'dark',
                    content: "<p>Please select a scope</p>",
                    buttons: {
                        close: {
                            text: 'OK',
                            btnClass: 'btn-danger'
                        }
                    }
                });
            }
        }
    }
})();
