(function() {
    'use strict';

    angular.module('adminApp.licenses')
        .directive('licenseInfuze', licenseInfuze);

    function licenseInfuze() {
        var directive = {
            restrict: 'E',
            templateUrl: 'view/licenses.templates.infuze',
            scope: {
                ngLicense: "=license",
                userId: "=",
                type: "@"
            },
            controller: 'LicenseDirectiveController',
            controllerAs: 'vm'
        };

        return directive;
    }
})();
