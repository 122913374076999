(function() {
    'use strict';

    angular.module('adminApp')
        .factory('vaultService', vaultService);

    vaultService.$inject = ['$resource'];

    function vaultService($resource) {
        var resource = new $resource('/api/admin/vault/session', {}, {
            postValidateVault: {
                method: 'POST'
            }
        });

        var service = {
            postValidateVault: postValidateVault
        };

        return service;

        function postValidateVault(session) {
            return resource.postValidateVault({}, {session: session}).$promise;
        }
    }
})();
