(function() {
    'use strict';

    angular.module('adminApp.saas')
        .controller('SaasEditController', SaasEditController);

    SaasEditController.$inject = ['$filter', '$ngConfirm', '$rootScope', '$state', '$stateParams', '$uibModal', '$uibModalInstance', 'accountService', 'baseAccountService', 'cloudAccountService', 'Notification', 'saasConstants', 'saasType', 'quwurflAccountService'];

    function SaasEditController($filter, $ngConfirm, $rootScope, $state, $stateParams, $uibModal, $uibModalInstance, accountService, baseAccountService, cloudAccountService, Notification, saasConstants, saasType, quwurflAccountService) {
        var vm = this;

        vm.cleanPaymentTypes = saasConstants.getConstants(saasType, 'paymentTypes');
        vm.close = close;
        vm.dateOptions = {'format': 'MM/DD/YY'};
        vm.plans = accountService.getValues(saasType, 'plans');
        vm.saasType = saasType;
        vm.service = baseAccountService.getService(saasType);
        vm.subscription = angular.copy(accountService.getValues(saasType, 'subscription'));
        vm.updateAccount = updateAccount;
        vm.updateQuWURFLAccount = updateQuWURFLAccount;

        if (vm.subscription.payment_type !== 'STANDARD') {
            vm.subscription.removePayment = false;
        }

        if (saasType !='quwurfl' && (vm.plans === undefined || vm.subscription === undefined)) {
            baseAccountService.get(vm.service.resourceUrl, $stateParams.id)
                .then(function(response) {
                    vm.subscription = response.data.account;
                    vm.plans = _.omit(response.data.plans, 'basic', 'standard');
                    vm.subscription.date_expiration = !_.isNull(vm.subscription.date_expiration) ?
                        $filter('createDate')({date: vm.subscription.date_expiration, format: 'YMD HIS'}) : null;
                }, function(error) {
                    console.log(error);
                    Notification.error("The account could not be loaded");
                });
        } else {
            vm.subscription.date_expiration = !_.isNull(vm.subscription.date_expiration) ?
                $filter('createDate')({date: vm.subscription.date_expiration, format: 'YMD HIS'}) : null;
            vm.plans = _.omit(vm.plans, 'basic', 'standard');
        }

        function close(state) {
            $uibModalInstance.dismiss();
        }

        function runUpdate() {
            vm.loading = true;
            baseAccountService.update(vm.service.resourceUrl, vm.subscription)
                .then(function(response) {
                    Notification.success('Updated account');
                    if ($state.current.name == saasType+'.view') {
                        $rootScope.$broadcast('reloadSubscription', {type: saasType, data: response.data});
                    }
                    $uibModalInstance.dismiss();
                }, function(error) {
                    Notification.error(error.data.message);
                    console.log(error);
                }).finally(function() {
                    vm.loading = false;
                    vm.subscription.removePayment = false;
                });
        }

        function updateQuWURFLAccount() {
            vm.loading = true;
            var payload = vm.subscription
            payload.date_expiration = $filter('date')(vm.subscription.date_expiration, "yyyy-MM-dd HH:mm:ss", "UTC"); 
            quwurflAccountService.update(vm.subscription.id, payload)
                .then(function(response) {
                    Notification.success('Updated account');
                    if ($state.current.name == saasType+'.view') {
                        var sub = response.subscription;
                        sub.account_name = payload.account_name; 
                        $rootScope.$broadcast('updateQuWURFLSubscription', {type: saasType, data: response.subscription});
                    }
                    $uibModalInstance.dismiss();
                }, function(error) {
                    Notification.error(error.data.error.message);
                    console.log(error);
                }).finally(function() {
                    vm.loading = false;
                });
        }

        // NOTE This needs to be tested on staging because there is no local service discover API
        function updateAccount() {
            if (vm.subscription.payment_type !== 'STANDARD') {
                vm.subscription.date_expiration = $filter('date')(vm.subscription.date_expiration, "yyyy-MM-dd");
            }
            if (vm.subscription.type == 'cloud' || vm.subscription.type == 'wjs') {
                var original = angular.copy(accountService.getValues(saasType, 'subscription'));
                if (original.payment_type !== vm.subscription.payment_type) {
                    if (!cloudAccountService.verifyPayment(original.payment_type, vm.subscription.payment_type)) {
                        Notification.error("Invalid payment type change");
                        return;
                    }
                    if (vm.subscription.payment_type == 'EXTERNAL' && original.payment_type == 'STANDARD') {
                        $ngConfirm({
                            title: 'Are you sure?',
                            theme: 'dark',
                            content: 'This will cancel recurring payments for the subscription.<br /><br />Are you sure you want to continue?',
                            buttons: {
                                close: {
                                    text: 'Cancel',
                                    btnClass: 'btn-default'
                                },
                                proceed: {
                                    text: 'Continue',
                                    btnClass: 'btn-primary',
                                    action: function() {
                                        vm.subscription.removePayment = true;
                                        runUpdate();
                                    }
                                }
                            }
                        });
                    } else {
                        runUpdate();
                    }
                } else {
                    runUpdate();
                }
            } else {
                runUpdate();
            }
        }
    }
})();
