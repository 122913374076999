(function() {
    'use strict';

    angular.module('adminApp')
        .service('transferFlash', transferFlash);

    function transferFlash() {
        var flash = {
            message: null,
            type: null
        };

        var service = {
            get: get,
            set: set
        };

        function get() {
            return flash;
        }

        function set(key, value) {
            switch (key) {
                case 'message':
                    flash.message = value;
                    break;
                case 'type':
                    flash.type = value;
                    break;
                default:
                    console.log('An issue occurred in transfer flash');
            }
        }

        return service;
    }
})();
