(function() {
    'use strict';

    angular.module('adminApp.wurfl')
        .factory('capabilitiesService', capabilitiesService);

    capabilitiesService.$inject = ['$resource'];

    function capabilitiesService($resource) {
        var resource = new $resource('/api/admin/capabilities/:action', {}, {
            allCapabilities: {
                method: 'GET',
                params: {
                    action: 'all',
                    type: '@type'
                }
            },
            findCapabilities: {
                method: 'GET',
                params: {
                    action: 'list',
                    capabilities: '@capabilities'
                }
            }
        });

        var queryParams = [];

        var service = {
            findCapabilities: findCapabilities,
            getData: getData,
            getLicenseCapabilities: getLicenseCapabilities,
            getSaasCapabilities: getSaasCapabilities
        };
        service.selectedCapability = null;

        return service;

        function findCapabilities(capabilities) {
            return resource.findCapabilities({capabilities: capabilities}).$promise;
        }

        function getLicenseCapabilities() {
            return resource.allCapabilities({type: 'db'}).$promise;
        }

        function getSaasCapabilities() {
            return resource.allCapabilities({type: 'wurfl'}).$promise;
        }

        function getData(options) {
            return resource.get(options).$promise;
        }
    }
})();
