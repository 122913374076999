(function() {
    'use strict';

    angular.module('adminApp.coreapi')
        .factory('coreApiScopeService', coreApiScopeService);

    coreApiScopeService.$inject = ['$resource'];

    function coreApiScopeService($resource) {
        var resource = new $resource('/api/admin/coreapi/scope/:id', {id: '@id'}, {
            all: {
                method: 'GET'
            },
            deleteScope: {
                method: 'DELETE'
            },
            getScope: {
                method: 'GET'
            },
            submit: {
                method: 'POST'
            },
            updateScope: {
                method: 'PATCH'
            }
        });
        var service = {
            all: all,
            deleteScope: deleteScope,
            getScope: getScope,
            submit: submit,
            updateScope: updateScope
        };

        return service;

        function all() {
            return resource.all({id: 'all'}).$promise;
        }

        function deleteScope(id) {
            return resource.deleteScope({id: id}).$promise;
        }

        function getScope(scope) {
            return resource.getScope({id: scope}).$promise;
        }

        function submit(scope) {
            return resource.submit({id: null}, scope).$promise;
        }

        function updateScope(scope) {
            return resource.updateScope({id: scope.id}, scope).$promise;
        }
    }
})();
