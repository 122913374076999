(function() {
    'use strict';

    angular.module('adminApp.apikey')
        .controller('ApikeyCreateController', ApikeyCreateController);

    ApikeyCreateController.$inject = ['$scope', '$state', '$uibModalInstance', 'apikeyService', 'breadcrumbsService', 'Notification', 'userService'];

    function ApikeyCreateController($scope, $state, $uibModalInstance, apikeyService, breadcrumbsService, Notification, userService) {
        var vm = this;

        vm.allLoaded = false;
        vm.back = back;
        vm.calendar = {
            open: false
        };
        vm.clear = clear;
        vm.errors = {};
        vm.form = {
            expiration: new Date(),
            user: {
                id: null,
                username: null
            },
            subscription: {
                id: undefined
            }
        };
        vm.generate = generate;
        vm.loading = {
            credentials: true,
            subscriptions: true,
            users: true
        };
        vm.options = {
            minDate: new Date(),
            showWeeks: true
        };
        vm.placeholder = {
            subscriptions: "loading subscriptions, please wait...",
            users: "loading users, please wait..."
        };
        vm.saving = false;
        vm.selectedUser = undefined;
        vm.toggleCalendar = toggleCalendar;
        vm.save = save;
        vm.subscriptionIds = [];
        vm.updateValues = updateValues;

        var allSubscriptions = [];
        var allUsers = [];

        $scope.$watch('vm.selectedUser', function(newVal) {
            if (!_.isObject(newVal) && newVal != undefined) {
                vm.subscriptionIds = allSubscriptions;
                vm.form.subscription.id = undefined;
                vm.form.user = {
                    id: null,
                    username: null
                };
            }
        });

        $scope.$watch('vm.loading.credentials', function(newVal) {
            $scope.$broadcast('loadingComplete');
        });
        $scope.$watch('vm.loading.subscriptions', function(newVal) {
            $scope.$broadcast('loadingComplete');
        });
        $scope.$watch('vm.loading.users', function(newVal) {
            $scope.$broadcast('loadingComplete');
        });

        $scope.$on('loadingComplete', function() {
            var loaded = true;
            angular.forEach(vm.loading, function(val, key) {
                if (val) {
                    loaded = false;
                }
            });
            vm.allLoaded = loaded;
        });

        function sortNumber(a,b) {
            return a - b;
        }

        userService.getWithSubscriptions().then(function(response) {
            allUsers = angular.copy(response.data);
            vm.users = response.data;
            vm.subscriptionIds = _.flatten(_.reject(_.pluck(response.data, 'subscriptions'), function(arr) {
                return arr.length == 0;
            })).sort(sortNumber);
            allSubscriptions = angular.copy(vm.subscriptionIds);
            vm.placeholder.subscriptions = "Search and select subscription";
            vm.placeholder.users = "Search and select user";
        }).catch(function(error) {
            console.log(error);
            vm.placeholder.subscriptions = "Unable to find subscriptions";
            vm.placeholder.users = "Unable to find users";
        }).finally(function() {
            vm.loading.subscriptions = false;
            vm.loading.users = false;
        });

        generate();

        function back() {
            $uibModalInstance.dismiss();
        }

        function clear() {
            vm.selectedUser = undefined;
            vm.form.user.id = null;
            vm.form.user.username = null;
            vm.form.subscription.id = undefined;
            vm.users = allUsers;
            vm.subscriptionIds = allSubscriptions;
            vm.loading.subscriptions = false;
            vm.loading.users = false;
            vm.placeholder.subscriptions = "Search and select subscription";
            vm.placeholder.user = "Search and select user";
        }

        function generate() {
            vm.loading.credentials = true;
            vm.form.api_username = null;
            vm.form.api_password = null;
            apikeyService.generateCredentials().then(function(response) {
                vm.form.api_username = response.data.api_username;
                vm.form.api_password = response.data.api_password;
            }).catch(function(error) {
                console.log(error);
            }).finally(function() {
                vm.loading.credentials = false;
            });
        }

        function save() {
            vm.errors = {};
            if (_.isNull(vm.form.user.id)) {
                vm.errors.user = 'Please select a user';
            }
            if (_.isNull(vm.form.subscription.id) || _.isUndefined(vm.form.subscription.id)) {
                vm.errors.subscription = 'Please select a subscription';
            }
            if (_.isNull(vm.form.key_name) || _.isUndefined(vm.form.key_name)) {
                vm.errors.key_name = 'Please enter a key name';
            }
            if (_.isNull(vm.form.api_username)) {
                vm.errors.api_username = 'Please enter an API username';
            }
            if (_.isNull(vm.form.api_password)) {
                vm.errors.api_password = 'Please enter an API password';
            }
            if (_.isNull(vm.form.expiration)) {
                vm.errors.expiration = 'Please enter an expiration date';
            }
            if (!_.isEmpty(vm.errors)) {
                return;
            }
            var form = {
                user: vm.form.user,
                subscription_id: vm.form.subscription.id,
                key_name: vm.form.key_name,
                api_username: vm.form.api_username,
                api_password: vm.form.api_password,
                expiration: vm.form.expiration
            };
            vm.saving = true;
            apikeyService.create(form).then(function(response) {
                Notification.success('Api Key created!');
                $uibModalInstance.dismiss();
                $state.go('apikey.view', {id: response.data.id});
            }).catch(function(error) {
                vm.errors = error.data.data;
            }).finally(function() {
                vm.saving = false;
            });
        }

        function toggleCalendar() {
            vm.calendar.opened = vm.calendar.opened == true ? false : true;
        }

        function updateValues(type) {
            if (type == 'user') {
                vm.subscriptionIds = _.findWhere(allUsers, {id: vm.selectedUser.id}).subscriptions;
                if (_.isEmpty(vm.subscriptionIds)) {
                    vm.placeholder.subscriptions = "No results found";
                    vm.loading.subscriptions = true;
                } else {
                    vm.placeholder.subscriptions = "Search and select subscription";
                    vm.loading.subscriptions = false;
                }
                vm.form.user = vm.selectedUser;
            } else {
                var user = (_.find(allUsers, function(obj) {
                    return _.contains(obj.subscriptions, vm.form.subscription.id);
                }));
                vm.selectedUser = {
                    id: user.id,
                    username: user.username
                };
            }
        }
    }
})();
