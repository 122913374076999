(function() {
    'use strict';

    angular.module('adminApp.reports')
        .controller('ReportDirectiveController', ReportDirectiveController);

    ReportDirectiveController.$inject = ['$rootScope', 'reportDirectiveService'];

    function ReportDirectiveController($rootScope, reportDirectiveService) {
        var vm = this;

        vm.buttonText = reportDirectiveService.getButtonText(vm.type);
        vm.class = reportDirectiveService.getClass(vm.type);
        vm.disabled = !_.isUndefined(vm.disabled) ? vm.disabled : false;
        vm.link = vm.link;
        vm.proceed = proceed;
        vm.reports = vm.reports;
        vm.type = vm.type;

        $rootScope.$on('enablefilex', function() {
            vm.disabled = false;
        });

        function proceed(val) {
            reportDirectiveService.proceed(val, vm.type, vm.reports, vm.link, vm.state);
        }
    }
})();
