(function() {
    'use strict';

    angular.module('adminApp.licenses')
        .factory('licensesModalService', licensesModalService);

    licensesModalService.$inject = ['$ngConfirm', '$rootScope', '$state', '$uibModal', 'licenseService', 'Notification'];

    function licensesModalService($ngConfirm, $rootScope, $state, $uibModal, licenseService, Notification) {
        var service = {
            create: create,
            download: download,
            edit: edit,
            enable: enable,
            remove: remove,
            revoke: revoke,
            transfer: transfer
        };

        var template = 'view/licenses.templates.license_modal_template';

        return service;

        function create(productId, userId, productType) {
            productId = _.isUndefined(productId) ? null : productId;
            userId = _.isUndefined(userId) ? null : userId;
            productType = _.isUndefined(productType) ? null : productType;
            $uibModal.open({
                templateUrl: template,
                controller: 'NewLicenseController',
                controllerAs: 'vm',
                size: 'lg',
                keyboard: false,
                backdrop: 'static',
                resolve: {
                    productId: function() {
                        return productId;
                    },
                    productType: function() {
                        return productType;
                    },
                    userId: function() {
                        return userId;
                    }
                }
            });
        }

        function download(type) {
            $uibModal.open({
                templateUrl: 'view/components.account_download_tracker',
                controller: 'AccountDownloadsLicenseController',
                controllerAs: 'vm',
                size: 'md',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    type: function() {
                        return type;
                    }
                }
            });
        }

        function edit(form) {
            $uibModal.open({
                templateUrl: template,
                controller: 'LicensesEditController',
                controllerAs: 'vm',
                size: 'lg',
                keyboard: false,
                backdrop: 'static',
                resolve: {
                    form: function() {
                        return form;
                    }
                }
            });
        }

        function enable(id) {
            $uibModal.open({
                templateUrl: template,
                controller: 'LicenseEnableController',
                controllerAs: 'vm',
                size: 'md',
                keyboard: false,
                backdrop: 'static',
                resolve: {
                    licenseId: function() {
                        return id;
                    }
                }
            });
        }

        function remove(id) {
            licenseService.getWithRelations(id).then(function(response) {
                var message = "Are you sure you want to delete this license?";
                if (
                    !_.isNull(response.data.download_key) ||
                    response.data.infuze_profile.length ||
                    response.data.insight_profile.length ||
                    response.data.wurfl_capabilities.length
                ) {
                    message += "<br /><br />This will also remove:";
                    if (!_.isNull(response.data.download_key)) {
                        message += "<br />1 download key";
                    }
                    if (response.data.infuze_profile.length) {
                        message += "<br />" + response.data.infuze_profile.length + " infuze profile(s)";
                    }
                    if (response.data.insight_profile.length) {
                        message += "<br />" + response.data.insight_profile.length + " insight profile(s)";
                    }
                    if (response.data.wurfl_capabilities.length) {
                        message += "<br />" + response.data.wurfl_capabilities.length + " capabilities";
                    }
                }
                $ngConfirm({
                    title: 'Confirm?',
                    theme: 'dark',
                    content: message,
                    buttons: {
                        close: {
                            text: 'Cancel',
                            btnClass: 'btn-default'
                        },
                        proceed: {
                            text: 'Delete',
                            btnClass: 'btn-danger',
                            action: function() {
                                licenseService.remove(id).then(function(response) {
                                    if (_.isEmpty(response.data)) {
                                        Notification.success(response.message);
                                    } else {
                                        Notification.warning(response.message);
                                    }
                                    if ($state.current.name == 'licenses.view') {
                                        $state.go('licenses.list');
                                    } else {
                                        $rootScope.$broadcast('reloadLicenses');
                                    }
                                }).catch(function(error) {
                                    console.log(error);
                                    Notification.error('Could not delete license');
                                });
                            }
                        }
                    }
                });
            });
        }

        function revoke(id) {
            $uibModal.open({
                templateUrl: template,
                controller: 'LicenseRevokeController',
                controllerAs: 'vm',
                size: 'md',
                keyboard: false,
                backdrop: 'static',
                resolve: {
                    licenseId: function() {
                        return id;
                    }
                }
            });
        }

        function transfer(params) {
            $uibModal.open({
                templateUrl: template,
                controller: 'LicenseTransferController',
                controllerAs: 'vm',
                size: 'md',
                keyboard: false,
                backdrop: 'static',
                resolve: {
                    license: function() {
                        return params.license;
                    },
                    licenseId: function() {
                        return params.id
                    }
                }
            });
        }
    }
})();
