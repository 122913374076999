(function() {
    'use strict';

    angular.module('adminApp.cloud')
        .controller('CloudViewAccountController', CloudViewAccountController);

    CloudViewAccountController.$inject = ['$state', '$stateParams', '$uibModalInstance', 'baseAccountService', 'cloudAccountService', 'Notification'];

    function CloudViewAccountController($state, $stateParams, $uibModalInstance, baseAccountService, cloudAccountService, Notification) {
        var vm = this;

        vm.accountName = null;
        vm.activeCapabilities = 0;
        vm.close = close;
        vm.currentMonthlyDetections = 0;
        vm.dataLoaded = false;
        vm.inactiveCapabilities = 0;
        vm.isLegacy = false;
        vm.isPremium = false;
        vm.maxCapabilities = 0;
        vm.maxDetections = 0;
        vm.percentDetectionsNice = 0;
        vm.progressCss = null;
        vm.viewSubscription = viewSubscription;

        var cloudId = null;
        var userId = null;

        baseAccountService.get(cloudAccountService.resourceUrl, $stateParams.id).then(function(response) {
            vm.accountName = response.data.account.plan.name;
            vm.isLegacy = response.data.account.is_legacy;
            vm.isPremium = response.data.account.payment_plan == 'WURFL_CLOUD_PREMIUM' ? true : false;
            var defaults = baseAccountService.remapLimits(response.data.account.limits, 'wurfl_cloud_default_limit');
            vm.maxCapabilities = defaults.capabilities.limit + response.data.account.num_capability_addons;
            var activeCapabilities = cloudAccountService.getActiveCapabilities(response.data.account.cloud_capabilities);
            vm.activeCapabilities = activeCapabilities.active;
            vm.inactiveCapabilities = activeCapabilities.inactive;
            vm.maxDetections = defaults.detections.limit;
            cloudId = response.data.account.id;
            userId = response.data.account.user.id;

            baseAccountService.billingPeriods(cloudAccountService.resourceUrl, $stateParams.id)
                .then(function(response) {
                    var currentPeriod = response.data[0];
                    baseAccountService.usage(cloudAccountService.resourceUrl, $stateParams.id, currentPeriod.start.date, currentPeriod.end.date)
                        .then(function(response) {
                            var usage = response.data;
                            vm.currentMonthlyDetections = usage.total_detections;
                            var percentDetections = vm.currentMonthlyDetections / vm.maxDetections;
                            var percentDetectionsNice = Math.round(percentDetections * 100);
                            if (percentDetectionsNice > 100) {
                                vm.progressCss = "danger";
                                vm.percentDetectionsNice = 100;
                            } else {
                                vm.progressCss = "info";
                                vm.percentDetectionsNice = percentDetectionsNice;
                            }
                            vm.dataLoaded = true;
                        }, function(error) {
                            Notification.error(error.data.message);
                        });
                }, function(error) {
                    console.log(error);
                    Notification.error(error.data.message);
                });
        }).catch(function(error) {
            console.log(error);
            Notification.error(error.data.message);
        });

        function close() {
            $uibModalInstance.dismiss();
            $state.go('users.myaccount', {id: userId});
        }

        function viewSubscription() {
            $uibModalInstance.dismiss();
            $state.go('cloud.view', {id: cloudId});
        }
    }
})();
