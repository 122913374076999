(function() {
    'use strict';

    angular.module('adminApp.cut')
        .factory('centraltestHttpHeaders', centraltestHttpHeaders);

    centraltestHttpHeaders.$inject = ['$resource', 'Notification','CUT_URL'];

    function centraltestHttpHeaders($resource, Notification, CUT_URL) {
        var resource = new $resource(CUT_URL + '/api/admin/centraltests/header/:id/:action', {id: '@id'}, {
            all: {
                method: 'GET',
                params: {
                    action: 'all',
                    header_id: '@header_id',
                    request_id: '@request_id',
                    header_name: '@header_name',
                    header_value: '@header_value'
                }
            },
            create: {
                method: 'POST',
                params: {action: 'create'}
            },
            get: {
                method: 'GET',
                params: {action: null}
            },
            remove: {
                method: 'DELETE',
                params: {action: null}
            },
            save: {
                method: 'PATCH',
                params: {action: null}
            },
            suggested: {
                method: 'GET',
                params: {action: 'suggested'}
            }
        });

        var service = {
            all: all,
            create: create,
            get: get,
            remove: remove,
            save: save,
            suggested: suggested,
            validate: validate,
            validateLength: validateLength
        };

        return service;

        function all(params) {
            var obj = {
                id: null
            };
            angular.forEach(params, function(val, key) {
                key = key === 'id' ? 'header_id' : key;
                obj[key] = val;
            });
            return resource.all(obj).$promise;
        }

        function create(form) {
            var errors = validate(form);
            if (errors) {
                return Promise.reject({status: false, errors: errors});
            }
            return resource.create({id: null}, form).$promise;
        }

        function get(id) {
            return resource.get({id: id}).$promise;
        }

        function remove(id) {
            return resource.remove({id: id}).$promise;
        }

        function save(form) {
            var errors = validate(form);
            if (errors) {
                return Promise.reject({status: false, errors: errors});
            }
            return resource.save({id: form.id}, {
                id: form.id,
                request_id: form.request_id,
                header_name: form.header_name,
                header_value: form.header_value
            }).$promise;
        }

        function suggested() {
            return resource.suggested({id: null}).$promise;
        }

        function validate(form) {
            var errors = {
                request_id: {
                    show: false
                },
                header_name: {
                    show: false
                },
                header_value: {
                    show: false
                }
            };
            if (
                form.request_id == null ||
                form.request_id == '' ||
                form.header_name == null ||
                form.header_name == '' ||
                form.header_value == null ||
                form.header_value == ''
            ) {
                if (form.request_id == null || form.request_id == '') {
                    errors.request_id.show = true;
                    errors.request_id.message = 'Request ID is required';
                }
                if (form.header_name == null || form.header_name == '') {
                    errors.header_name.show = true
                    errors.header_name.message = 'Header name is required';
                }
                if (form.header_value == null || form.header_value == '') {
                    errors.header_value.show = true
                    errors.header_value.message = 'Header value is required';
                }
                return errors;
            }
            var length = false;
            angular.forEach(['header_name', 'header_value'], function(val, key) {
                var response = validateLength(form, val);
                if (response) {
                    length = true;
                    errors[val] = response;
                }
            });
            if (length) {
                return errors;
            }
            return false;
        }

        function validateLength(form, field) {
            if (field == 'header_name') {
                var length = 255;
            } else {
                var length = 512;
            }
            if (form[field] !== undefined && form[field].length > length) {
                return {
                    show: true,
                    message: field + " must be no more than "+length+" characters"
                };
            }
            return false;
        }
    }
})();
