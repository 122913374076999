(function() {
    'use strict';

    angular.module('adminApp.wit')
        .controller('TrialLinksController', TrialLinksController);

    TrialLinksController.$inject = ['$location', '$rootScope', '$scope', '$stateParams', '$state', '$timeout', 'baseAccountService', 'breadcrumbsService', 'Notification', 'reportsService', 'paginationService'];

    function TrialLinksController($location, $rootScope, $scope, $stateParams, $state, $timeout, baseAccountService, breadcrumbsService, Notification, reportsService, paginationService) {
        var vm = this;

        vm.create = create;
        vm.download = download;
        vm.downloading = false;
        vm.isLoading = false;
        vm.itemsPerPage = [
            {
                value: 10,
                class: "btn btn-default active",
                selected: true
            },
            {
                value: 25,
                class: "btn btn-default",
                selected: false
            },
            {
                value: 50,
                class: "btn btn-default",
                selected: false
            },
            {
                value: 100,
                class: "btn btn-default",
                selected: false
            }
        ];
        vm.paginationData = null;
        vm.running = false;
        vm.search = {
            email: null,
            claimed: null
        };
        vm.selectPage = selectPage;
        vm.steps = breadcrumbsService.get('wit.triallinks');
        vm.triallink = {
            email : ''
        };
        vm.triallinks = null;
        vm.updatePerPage = updatePerPage;

        var currentPage = $stateParams.page;
        var currentState = $state.current.name;
        var searchParams = _.clone($location.search());
        getTrialLinks();

        var timeout = null;


        $scope.$watch('vm.search', function(newVal, oldVal) {
            // Clear any existing timeouts
            if (timeout !== null) {
                $timeout.cancel(timeout);
            }

            // Give the user a chance to finish typing
            timeout = $timeout(function () {
                var updated = false;
                if (!_.isEmpty(newVal) || !_.isEmpty(oldVal)) {
                    angular.forEach(vm.search, function(val, key) {
                        if (newVal[key] !== oldVal[key]) {
                            updated = true;
                        }
                    });
                    if (updated) {
                        vm.isLoading = true;
                        var search = {
                            page: 1,
                            paginate: _.findWhere(vm.itemsPerPage, {selected: true}).value
                        };
                        currentPage = 1;
                        _.extend(search, newVal);
                        getTrialLinks(search);
                    }
                }
            }, 500);
        }, true)

        function create() {
            vm.running = true;
            baseAccountService.save('/api/admin/triallinks', vm.triallink)
                .then(function(response) {
                    Notification.success(response.message);
                    vm.triallinks.unshift(response.data);
                    vm.triallinks.splice(-1,1)
                }).catch(function(error) {
                    var message;
                    switch (error.status) {
                        case 500:
                            message = 'An unexpected error occurred';
                            break;
                        default:
                            message = error.data.data.email[0];
                    }
                    Notification.error(message);
                    console.log(error);
                }).finally(function() {
                    vm.running = false;
                });
        }

        function download() {
            vm.downloading = true;
            var download = reportsService.downloadTrialLinksReport();
            download.then(function() {
                vm.downloading = false;
            });
        }

        function getTrialLinks(searchParams) {
            vm.isLoading = true;
            searchParams = !_.isUndefined(searchParams) ? searchParams : null;
            var start = 0;
            var number = 10;

            var query_params = {};
            var next_page = 1;

            if (!_.isNull(searchParams)) {
                next_page = searchParams.page;
                number = searchParams.paginate;
            } else if (searchParams && _.isUndefined(searchParams.page) === false) { // if defined
                next_page = searchParams.page;
            } else {
                next_page = Math.floor(start/number) + 1;
            }

            query_params = {page: next_page};
            if (searchParams !== null) {
                query_params = _.extend(searchParams);
            }

            baseAccountService.get('/api/admin/triallinks', undefined, query_params)
                .then(function(response) {
                    vm.triallinks = response.data.triallinks;
                    var numPages = response.pagination.last_page;
                    var paginationData = {};
                    paginationData.pages = paginationService.createPages(currentPage, numPages);
                    paginationData.numPages = numPages;
                    paginationData.currentPage = currentPage;
                    paginationData.currentState = currentState;
                    paginationData.stateParams = $stateParams;
                    paginationData.from = paginationService.from(response.pagination);
                    paginationData.to = paginationService.to(response.pagination);
                    paginationData.total = response.pagination.total;

                    vm.paginationData = paginationData;
                }).catch(function(error) {
                    console.log(error);
                    Notification.error(error.data.message);
                    $scope.$on('$destroy', triallinksSearchParamsUpdated);
                    $scope.$on('$destroy', triallinksPageChanged);
                }).finally(function() {
                    vm.isLoading = false;
                });
        }

        function selectPage(page) {
            var num = _.findWhere(vm.itemsPerPage, {selected: true}).value;
            currentPage = page;
            var search = {paginate: num, page: page};
            _.extend(search, vm.search);
            getTrialLinks(search);
        }

        function updatePerPage(item) {
            _.map(vm.itemsPerPage, function(obj) {
                if (obj.value === item.value) {
                    obj.selected = true;
                    obj.class = "btn btn-default active";
                } else {
                    obj.selected = false;
                    obj.class = "btn btn-default";
                }
                return obj;
            });
            currentPage = 1;
            var search = {paginate: item.value, page: 1};
            _.extend(search, vm.search);
            getTrialLinks(search);
        }
    }
})();
