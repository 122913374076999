(function() {
    'use strict';

    angular.module('adminApp.cloud')
        .controller('CloudUsageController', CloudUsageController);

    CloudUsageController.$inject = ['$rootScope', '$stateParams', 'accountService', 'baseAccountService', 'cloudAccountService', 'Notification'];

    function CloudUsageController($rootScope, $stateParams, accountService, baseAccountService, cloudAccountService, Notification) {
        var vm = this;

        vm.billingPeriods = undefined;
        vm.currentPeriod = null;
        vm.defaults = accountService.getValues('cloud', 'defaults');
        vm.getUsage = getUsage;
        vm.initUsage = initUsage;
        vm.subscription = accountService.getValues('cloud', 'subscription');
        vm.usage = undefined;

        if (accountService.getId('cloud') === undefined || accountService.getId('cloud') !== $stateParams.id) {
            accountService.clear();
            vm.subscription = undefined;
            vm.defaults = undefined;
        }

        if (vm.defaults === undefined || vm.subscription === undefined) {
            baseAccountService.get(cloudAccountService.resourceUrl, $stateParams.id)
                .then(function(response) {
                    accountService.setId('cloud', $stateParams.id);
                    vm.subscription = response.data.account;
                    accountService.setValues('cloud', 'otherAccounts', response.data.other_accounts);
                    accountService.setValues('cloud', 'plans', vm.plans);
                    vm.defaults = baseAccountService.remapLimits(vm.subscription.limits, 'wurfl_cloud_default_limit');
                    accountService.setValues('cloud', 'defaults', vm.defaults);
                    accountService.setValues('cloud', 'billingInfoAvailable',
                        baseAccountService.isFree(vm.subscription) === false && baseAccountService.isStandardBilling(vm.subscription));
                    vm.subscription.cloud_capabilities_records = _.pluck(vm.subscription.cloud_capabilities, 'capability');
                    accountService.setValues('cloud', 'subscription', vm.subscription);
                    $rootScope.$broadcast('updateCloudSubscription');
                }, function(error) {
                    console.log(error);
                    Notification.error("The account could not be loaded");
                });
        }

        function getUsage(period) {
            if (angular.isUndefined(period)) {
                period = vm.currentPeriod;
            } else {
                vm.currentPeriod = period;
            }

            baseAccountService.usage(cloudAccountService.resourceUrl, $stateParams.id, period.start.date, period.end.date)
                .then(function(response) {
                    vm.usage = response.data;
                    if (accountService.getValues('cloud', 'usage') === undefined) {
                        accountService.setValues('cloud', 'usage', vm.usage);
                    }
                }, function(error) {
                    Notification.error(error.data.message);
                });
        }

        function initUsage() {
            vm.billingPeriods = accountService.getValues('cloud', 'billingPeriods');
            vm.currentPeriod = accountService.getValues('cloud', 'currentPeriod');
            vm.usage = accountService.getValues('cloud', 'usage');
            if (vm.billingPeriods === undefined || vm.currentPeriod === undefined || vm.usage === undefined) {
                baseAccountService.billingPeriods(cloudAccountService.resourceUrl, $stateParams.id)
                    .then(function(response) {
                        vm.billingPeriods = response.data.reverse();
                        vm.currentPeriod = response.data[0];
                        accountService.setValues('cloud', 'billingPeriods', vm.billingPeriods);
                        accountService.setValues('cloud', 'currentPeriod', vm.currentPeriod);
                        getUsage(vm.currentPeriod);
                    }, function(error) {
                        console.log(error);
                        Notification.error(error.data.message);
                    });
            }
        }
    }
})();
