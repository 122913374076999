(function() {
    'use strict';

    angular.module('adminApp.cut')
        .factory('centraltestAssertionsService', centraltestAssertionsService);

    centraltestAssertionsService.$inject = ['$resource', 'Notification','CUT_URL'];

    function centraltestAssertionsService($resource, Notification, CUT_URL) {
        var resource = new $resource(CUT_URL + '/api/admin/centraltests/assertion/:id/:action', {id: '@id'}, {
            all: {
                method: 'GET',
                params: {
                    action: 'all',
                    page: '@page',
                    paginate: '@paginate',
                    assertion_id: '@assertion_id',
                    test_id: '@test_id',
                    assertion_type: '@assertion_type',
                    expected_id: '@expected_id',
                    exact_match: '@exact_match'
                }
            },
            create: {
                method: 'POST',
                params: {action: 'create'}
            },
            get: {
                method: 'GET',
                params: {action: null}
            },
            remove: {
                method: 'DELETE',
                params: {action: null}
            },
            save: {
                method: 'PATCH',
                params: {action: null}
            }
        });
        var service = {
            all: all,
            create: create,
            get: get,
            remove: remove,
            save: save,
            validate: validate,
            validateLength: validateLength
        };

        return service;

        function all(params) {
            var obj = {
                id: null
            };
            angular.forEach(params, function(val, key) {
                key = key === 'id' ? 'assertion_id' : key;
                obj[key] = val;
            });
            return resource.all(obj).$promise;
        }

        function create(form) {
            var errors = validate(form);
            if (errors) {
                return Promise.reject({status: false, errors: errors});
            }
            return resource.create({id: null}, form).$promise;
        }

        function get(id) {
            return resource.get({id: id}).$promise;
        }

        function remove(id) {
            return resource.remove({id: id}).$promise;
        }

        function save(form) {
            var errors = validate(form);
            if (errors) {
                return Promise.reject({status: false, errors: errors});
            }

            return resource.save({id: form.id}, form).$promise;
        }

        function validate(form) {
            var errors = {
                test_id: {
                    show: false
                },
                assertion_type: {
                    show: false
                },
                expected_id: {
                    show: false
                },
                expected_value: {
                    show: false
                },
                exact_match: {
                    show: false
                }
            };

            if (
                form.test_id == null || form.test_id == '' ||
                form.assertion_type == null || form.assertion_type == '' ||
                form.expected_id == null || form.expected_id == ''
            ) {
                if (form.test_id == null || form.test_id == '') {
                    errors.test_id.show = true;
                    errors.test_id.message = 'Test ID is required';
                }
                if (form.assertion_type == null || form.assertion_type == '') {
                    errors.assertion_type.show = true;
                    errors.assertion_type.message = 'Assertion type is required';
                }
                if (form.expected_id == null || form.expected_id == '') {
                    errors.expected_id.show = true;
                    errors.expected_id.message = 'Expected ID is required';
                }
                return errors;
            }

            var length = false;
            angular.forEach(['assertion_type', 'expected_id', 'expected_value'], function(val, key) {
                var response = validateLength(form, val);
                if (response) {
                    length = true;
                    errors[val] = response;
                }
            });
            if (length) {
                return errors;
            }
            return false;
        }

        function validateLength(form, field) {
            if (form[field] !== undefined && form[field] !== null && form[field].length > 255) {
                return {
                    show: true,
                    message: field + " must be no more than 255 characters"
                };
            }
            return false;
        }
    }
})();
