(function() {
    angular.module('adminApp.users')
        .controller('CreateTeamController', CreateTeamController);

    CreateTeamController.$inject = ['$rootScope', '$uibModalInstance', '$window', 'Notification', 'teamService'];

    function CreateTeamController($rootScope, $uibModalInstance, $window, Notification, teamService) {
        var vm = this;

        vm.close = close;
        vm.formData = {
            partner: false,
            reseller: false,
        };
        vm.formErrors = {};
        vm.modalOptions = {
            template: 'view/users.modals.new_team_form',
            title: "Create Team Record",
            closeButtonText: "Back",
            okButtonText: "Save"
        };
        vm.spinner = false;
        vm.submit = submit;

        function close() {
            $uibModalInstance.dismiss();
        }

        function runSave() {
            vm.spinner = true;
            teamService.create(vm.formData)
                .then(function(response) {
                    Notification.success("Team "+response.data.name+" created!");
                    $uibModalInstance.dismiss();
                    vm.spinner = false;
                    vm.formErrors = {};
                    $rootScope.$broadcast('reloadTeams');
                }, function(error) {
                    console.log(error);
                    switch (error.status) {
                        case 400:
                            angular.forEach(error.data.data, function(value, key) {
                                vm.formErrors[key] = value[0];
                            });
                            Notification.error("Form errors exist");
                            vm.spinner = false;
                            $window.scrollTo(0, 0);
                            break;
                        case 500:
                            Notification.error("An unexpected error has occurred");
                            console.log(error);
                            break;
                        default:
                            Notification.error(error.message);
                            console.log(error);
                            break;
                    }
                });
        }

        function submit() {
            vm.formData.partner = !!vm.formData.partner;
            vm.formData.reseller = !!vm.formData.reseller;

            runSave();
        }
    }
})();