(function() {
    'use strict';

    angular.module('adminApp.saas')
        .factory('witAccountService', witAccountService);

    witAccountService.$inject = ['$resource', '$window'];

    function witAccountService($resource, $window) {
        var service = {
            bills: bills,
            calculateMatchings: calculateMatchings,
            cancel: cancel,
            cancelDowngrade: cancelDowngrade,
            changeReferenceConfiguration: changeReferenceConfiguration,
            chargeOverageFees: chargeOverageFees,
            create: create,
            defaultFields: defaultFields,
            // deleteDomainConf: deleteDomainConf,
            deleteDomain: deleteDomain,
            deleteOrigin: deleteOrigin,
            deleteSetting: deleteSetting,
            deleteUnstoredCertificate: deleteUnstoredCertificate,
            downloadPurgeRequest: downloadPurgeRequest,
            generateToken: generateToken,
            getAllTrials: getAllTrials,
            getAwsRegions: getAwsRegions,
            getAwsRegionsByAccount: getAwsRegionsByAccount,
            // getBraintreeAuthorization: getBraintreeAuthorization,
            getBandwidthSaved: getBandwidthSaved,
            getCache: getCache,
            getCertificateStatus: getCertificateStatus,
            getCertificateValidationDetails: getCertificateValidationDetails,
            getCustomWildcardCertificateStatus: getCustomWildcardCertificateStatus,
            getDomainConfs: getDomainConfs,
            getDomain: getDomain,
            getDomainsNewRegion: getDomainsNewRegion,
            getExpiredTrials: getExpiredTrials,
            getIeOnlyCertificateStatus: getIeOnlyCertificateStatus,
            getOrigin: getOrigin,
            getSearchCertificates: getSearchCertificates,
            getTrialStatistics: getTrialStatistics,
            patchDeleteCertificate: patchDeleteCertificate,
            patchEditOrigin: patchEditOrigin,
            patchEditSettings: patchEditSettings,
            patchResourceRecords: patchResourceRecords,
            patchRestartCertificate: patchRestartCertificate,
            patchRestartUnstoredCertificate: patchRestartUnstoredCertificate,
            patchSuspendCertificate: patchSuspendCertificate,
            patchUpdateDomainOrigin: patchUpdateDomainOrigin,
            patchUpdateDomainTransitionTime: patchUpdateDomainTransitionTime,
            planChangeStorage: planChangeStorage,
            postAddNewOrigin: postAddNewOrigin,
            postAddPurgeRequest: postAddPurgeRequest,
            postAttachToShard: postAttachToShard,
            postAttachCustomWildcardToShard: postAttachCustomWildcardToShard,
            postCreateDomain: postCreateDomain,
            postCreateSettings: postCreateSettings,
            postDeleteResourceRecords: postDeleteResourceRecords,
            postDetachFromShard: postDetachFromShard,
            postDomainsNewRegionAws: postDomainsNewRegionAws,
            postDomainsNewRegionDb: postDomainsNewRegionDb,
            postDomainsNewRegionRoute53: postDomainsNewRegionRoute53,
            postRemoveCertificateFromRegion: postRemoveCertificateFromRegion,
            postRequestCertificate: postRequestCertificate,
            postRequestTestCertificate: postRequestTestCertificate,
            postResourceRecords: postResourceRecords,
            postTrialStageStatistics: postTrialStageStatistics,
            postTrialStatistics: postTrialStatistics,
            postUpdateCors: postUpdateCors,
            postUpdatePrefixes: postUpdatePrefixes,
            postValidatePath: postValidatePath,
            refresh: refresh,
            remapLimits: remapLimits,
            resourceUrl: "/api/admin/wit",
            restart: restart,
            restoreHostname: restoreHostname,
            suspend: suspend,
            unsuspend: unsuspend,
            update: update,
            updateDefaults: updateDefaults,
            upgrade: upgrade,
            validateNew: validateNew,
            validateTier: validateTier,
            validateWhitelist: validateWhitelist
        };

        var witResource = new $resource(service.resourceUrl + "/:id/:action/:action_id/:spec", {id: '@id'}, {
            bills: {
                method: 'GET',
                params: {
                    action: 'bills',
                    spec: null
                }
            },
            cancel: {
                method: 'PATCH',
                params: {
                    action: 'cancel',
                    action_id: null,
                    spec: null
                }
            },
            cancelDowngrade: {
                method: 'PATCH',
                params: {
                    action: 'downgrade',
                    action_id: 'cancel',
                    spec: null
                }
            },
            chargeOverageFees: {
                method: 'POST',
                params: {
                    action: 'overage',
                    spec: null
                }
            },
            create: {
                method: 'POST',
                params: {
                    action: null,
                    spec: null
                }
            },
            // deleteDomainConf: {
            //     method: 'DELETE',
            //     params: {
            //         action: 'domain'
            //     }
            // },
            deleteDomain: {
                method: 'DELETE',
                params: {
                    action: 'domain',
                    spec: null
                }
            },
            deleteOrigin: {
                method: 'DELETE',
                params: {
                    action: 'origin',
                    spec: null
                }
            },
            deleteSetting: {
                method: 'DELETE',
                params: {
                    action: 'settings',
                    spec: null
                }
            },
            deleteUnstoredCertificate: {
                method: 'DELETE',
                params: {
                    action: 'certificate',
                    action_id: 'unstored'
                }
            },
            getAllTrials: {
                method: 'GET',
                params: {
                    action: 'all',
                    start: '@start',
                    end: '@end',
                    spec: null
                }
            },
            getAwsRegions: {
                method: 'GET',
                params: {
                    action: 'regions',
                    spec: null
                }
            },
            getAwsRegionsByAccount: {
                method: 'GET',
                params: {
                    action: 'regions',
                    spec: null
                }
            },
            getBandwidthSaved: {
                method: 'GET',
                params: {
                    action: 'statistics',
                    spec: 'bandwidth'
                }
            },
            getCache: {
                method: 'GET',
                params: {
                    action: 'cache',
                    spec: null
                }
            },
            getCertificateStatus: {
                method: 'GET',
                params: {
                    action: 'certificate',
                    spec: 'status'
                }
            },
            getCertificateValidationDetails: {
                method: 'GET',
                params: {
                    action: 'validation',
                    spec: null
                }
            },
            getCustomWildcardCertificateStatus: {
                method: 'GET',
                params: {
                    action: 'certificate',
                    spec: 'wc'
                }
            },
            getDomainConfs: {
                method: 'GET',
                params: {
                    action: 'domain-confs',
                    spec: null
                }
            },
            getDomain: {
                method: 'GET',
                params: {
                    action: 'domain',
                    spec: null
                }
            },
            getDomainsNewRegion: {
                method: 'GET',
                params: {
                    action: 'domains',
                    spec: null
                }
            },
            getExpiredTrials: {
                method: 'GET',
                params: {
                    action: 'trial',
                    spec: 'expired'
                }
            },
            getIeOnlyCertificateStatus: {
                method: 'GET',
                params: {
                    action: 'certificate',
                    spec: 'ie'
                }
            },
            getOrigin: {
                method: 'GET',
                params: {
                    action: 'origin',
                    spec: null
                }
            },
            getSearchCertificates: {
                method: 'GET',
                params: {
                    action: 'certificates',
                    spec: null
                }
            },
            getTrialStatistics: {
                method: 'GET',
                params: {
                    action: 'trial',
                    spec: 'statistics'
                }
            },
            patchDeleteCertificate: {
                method: 'PATCH',
                params: {
                    action: 'certificate',
                    spec: 'delete'
                }
            },
            patchEditOrigin: {
                method: 'PATCH',
                params: {
                    action: 'origin',
                    spec: null
                }
            },
            patchEditSettings: {
                method: 'PATCH',
                params: {
                    action: 'settings',
                    spec: null
                }
            },
            patchResourceRecords: {
                method: 'PATCH',
                params: {
                    action: 'dns',
                    spec: null
                }
            },
            patchRestartCertificate: {
                method: 'PATCH',
                params: {
                    action: 'certificate',
                    spec: 'restart'
                }
            },
            patchRestartUnstoredCertificate: {
                method: 'PATCH',
                params: {
                    action: 'domain',
                    spec: 'restart'
                }
            },
            patchSuspendCertificate: {
                method: 'PATCH',
                params: {
                    action: 'certificate',
                    spec: 'suspend'
                }
            },
            patchUpdateDomainOrigin: {
                method: 'PATCH',
                params: {
                    action: 'domain',
                    spec: null
                }
            },
            patchUpdateDomainTransitionTime: {
                method: 'PATCH',
                params: {
                    action: 'domain',
                    spec: 'transition'
                }
            },
            postAddNewOrigin: {
                method: 'POST',
                params: {
                    action: 'origin',
                    spec: null
                }
            },
            postAddPurgeRequest: {
                method: 'POST',
                params: {
                    action: 'purge',
                    spec: null
                }
            },
            postAttachToShard: {
                method: 'POST',
                params: {
                    action: 'shard',
                    spec: null
                }
            },
            postAttachCustomWildcardToShard: {
                method: 'POST',
                params: {
                    action: 'shard',
                    spec: 'wc'
                }
            },
            postCreateDomain: {
                method: 'POST',
                params: {
                    action: 'domain',
                    spec: null
                }
            },
            postCreateSettings: {
                method: 'POST',
                params: {
                    action: 'settings',
                    spec: null
                }
            },
            postDeleteResourceRecords: {
                method: 'POST',
                params: {
                    action: 'dns',
                    spec: null
                }
            },
            postDetachFromShard: {
                method: 'POST',
                params: {
                    action: 'shard',
                    action_id: 'detach',
                    spec: null
                }
            },
            postDomainsNewRegionAws: {
                method: 'POST',
                params: {
                    action: 'domains',
                    spec: 'aws'
                }
            },
            postDomainsNewRegionDb: {
                method: 'POST',
                params: {
                    action: 'domains',
                    spec: 'db'
                }
            },
            postDomainsNewRegionRoute53: {
                method: 'POST',
                params: {
                    action: 'domains',
                    spec: 'r53'
                }
            },
            postRemoveCertificateFromRegion: {
                method: 'POST',
                params: {
                    action: 'certificate',
                    action_id: 'remove',
                    spec: null
                }
            },
            postRequestCertificate: {
                method: 'POST',
                params: {
                    action: 'certificate',
                    spec: null
                }
            },
            postRequestTestCertificate: {
                method: 'POST',
                params: {
                    action: 'certificate',
                    spec: 'test'
                }
            },
            postResourceRecords: {
                method: 'POST',
                params: {
                    action: 'dns',
                    spec: null
                }
            },
            postTrialStageStatistics: {
                method: 'POST',
                params: {
                    action: 'stages'
                }
            },
            postTrialStatistics: {
                method: 'POST',
                params: {
                    action: 'trial',
                    spec: 'statistics'
                }
            },
            postUpdateCors: {
                method: 'POST',
                params: {
                    action: 'cors',
                    spec: null
                }
            },
            postUpdatePrefixes: {
                method: 'POST',
                params: {
                    action: 'prefix',
                    spec: null
                }
            },
            postValidatePath: {
                method: 'POST',
                params: {
                    action: 'validatepath',
                    spec: null
                }
            },
            refresh: {
                method: 'POST',
                params: {
                    action: 'refresh',
                    spec: null
                }
            },
            restart: {
                method: 'PATCH',
                params: {
                    action: 'restart',
                    spec: null
                }
            },
            restoreHostname: {
                method: 'PATCH',
                params: {
                    action: 'hostname',
                    spec: null
                }
            },
            suspend: {
                method: 'PATCH',
                params: {
                    action: 'suspend',
                    spec: null
                }
            },
            unsuspend: {
                method: 'PATCH',
                params: {
                    action: 'unsuspend',
                    spec: null
                }
            },
            update: {
                method: 'PATCH',
                params: {
                    action: 'account',
                    spec: null
                }
            },
            updateDefaults: {
                method: 'PATCH',
                params: {
                    action: 'defaults',
                    spec: null
                }
            },
            upgrade: {
                method: 'PATCH',
                params: {
                    action: 'upgrade',
                    spec: null
                }
            }
        });

        var planChange = {
            selectedPlan: null,
            subscription: null,
            action: true
        };

        return service;

        function bills(start_timestamp, end_timestamp, query_params) {
            return witResource.bills({
                id: null,
                start: start_timestamp,
                end: end_timestamp
            }).$promise;
        }

        function calculateMatchings(rawConfigurations) {
            var partitions = _.partition(rawConfigurations, function(conf) {
                return conf.cname == "DEFAULT";
            });

            var results = [];
            var defaultConfs = partitions[0];

            if (defaultConfs.length != 1) {
                return [];
            }

            var defaultConf = defaultConfs[0];

            defaultConf.pointsTo = true;
            defaultConf.isCnamed = false;

            if (defaultConf.url == '') {
                defaultConf.pointsTo = false;
            }

            defaultConf.pointingUrl = defaultConf.url;

            var cnamesConfs = partitions[1];

            _.map(cnamesConfs, function(cn) {
                cn.isCnamed = true;
                cn.pointsTo = false;

                if (cn.origin_conf_id != 0) {
                    cn.pointingUrl = defaultConf.url;
                } else {
                    cn.pointingUrl = cn.url;
                }

                if (cn.pointingUrl != '') {
                    cn.pointsTo = true;
                }
            });

            results = _.union(defaultConfs, cnamesConfs);
            return results;
        }

        function cancel(id, reason) {
            return witResource.cancel({id: id}, {reason: reason}).$promise;
        }

        function cancelDowngrade(id) {
            return witResource.cancelDowngrade({id: id}).$promise;
        }

        function changeReferenceConfiguration(conf) {
            var source_type = conf.source_type;

            if (source_type == 'another') { // we want to define our source ourselves
                var default_origin_type = 'http';

                if (!(_.has(conf, 'http')) || !(_.has(conf,'s3'))) { // there is no origin type defined before
                    conf.url_type = default_origin_type;
                    conf[default_origin_type] = service.defaultFields(default_origin_type);
                }
            }

            if (source_type == 'default') {} // we want to use default origin source

            return conf;
        }

        function chargeOverageFees(id) {
            return witResource.chargeOverageFees({id: id}).$promise;
        }

        function create(form) {
            return witResource.create({id: null}, {form: form}).$promise;
        }

        function defaultFields(type) {
            switch(type) {
                case 'http':
                    return {
                        "is_secure": false,
                        "hostname": "",
                        "username": "",
                        "password": "",
                        "port": "",
                        "path": "",
                    };
                case 's3':
                    return {
                        "bucketname": "",
                        "path": "",
                    };
                default:
                    return false;
            }
        }

        // function deleteDomainConf(id, keyId) {
        //     return witResource.deleteDomainConf({id: id, action_id: keyId}).$promise;
        // }

        function deleteDomain(id, domainId) {
            return witResource.deleteDomain({id: id, action_id: domainId}).$promise;
        }

        function deleteOrigin(id, originId) {
            return witResource.deleteOrigin({id: id, action_id: originId}).$promise;
        }

        function postCreateSettings(id, domainId, form) {
            return witResource.postCreateSettings({id: id, action_id: domainId}, form).$promise;
        }

        function postDeleteResourceRecords(id, domainId, store, cname) {
            store = !_.isUndefined(store) ? store : true;
            cname = !_.isUndefined(cname) ? cname : true;
            return witResource.postDeleteResourceRecords({id: id, action_id: domainId}, {store: store, cname: cname}).$promise;
        }

        function deleteSetting(id, settingId) {
            return witResource.deleteSetting({id: id, action_id: settingId}).$promise;
        }

        function deleteUnstoredCertificate(id, domainId) {
            return witResource.deleteUnstoredCertificate({id: id, spec: domainId}).$promise;
        }

        function downloadPurgeRequest(data) {
            var blob = new Blob([angular.toJson(data)], {type: 'application/json'}),
                url = $window.URL || $window.webkitURL;
            var a = $("<a style='display: none;'/>");
            var url = url.createObjectURL(blob);
            a.attr("href", url);
            a.attr("download", "request.json");
            $("body").append(a);
            a[0].click();
            window.URL.revokeObjectURL(url);
            a.remove();
        }

        function generateToken() {
            $window.open("/wit/imageengine/generate-token", "_self");
        }

        function getAllTrials(start, end) {
            return witResource.getAllTrials({id: 'trial', action_id: null, start: start, end: end}).$promise;
        }

        function getAwsRegions() {
            return witResource.getAwsRegions({id: null}).$promise;
        }

        function getAwsRegionsByAccount(id) {
            return witResource.getAwsRegionsByAccount({id: null, action_id: id}).$promise;
        }

        // function getBraintreeAuthorization() {
        //     var resouce = $resource('/api/admin/braintree/config', {}, {
        //         'getBraintreeAuthorization': {method: 'GET'}
        //     });
        //     return resouce.getBraintreeAuthorization().$promise;
        // }

        function getBandwidthSaved(id) {
            return witResource.getBandwidthSaved({id: id, action_id: null}).$promise;
        }

        function getCache(id) {
            return witResource.getCache({id: id}).$promise;
        }

        // function getCertificateStatus(id, certId) {
        function getCertificateStatus(id, domain) {
            return witResource.getCertificateStatus({id: id, action_id: domain}).$promise;
        }

        function getCertificateValidationDetails(id, certificateId) {
            return witResource.getCertificateValidationDetails({id: id, action_id: certificateId}).$promise;
        }

        function getCustomWildcardCertificateStatus(id, domainId) {
            return witResource.getCustomWildcardCertificateStatus({id: id, action_id: domainId}).$promise;
        }

        function getDomainConfs(id) {
            return witResource.getDomainConfs({id: id}).$promise;
        }

        function getDomain(id, domainId) {
            return witResource.getDomain({id: id, action_id: domainId}).$promise;
        }

        function getDomainsNewRegion(region) {
            return witResource.getDomainsNewRegion({id: null, action_id: region}).$promise;
        }

        function getExpiredTrials() {
            return witResource.getExpiredTrials({id: null, action_id: null}).$promise;
        }

        function getIeOnlyCertificateStatus(id, domain) {
            return witResource.getIeOnlyCertificateStatus({id: id, action_id: domain}).$promise;
        }

        function getOrigin(id, originId) {
            return witResource.getOrigin({id: id, action_id: originId}).$promise;
        }

        function getSearchCertificates(id, domainId) {
            return witResource.getSearchCertificates({id: id, action_id: domainId}).$promise;
        }

        function getTrialStatistics(id) {
            return witResource.getTrialStatistics({id: null, action_id: id}).$promise;
        }

        function patchDeleteCertificate(id, certificateId, form) {
            return witResource.patchDeleteCertificate({id: id, action_id: certificateId}, form).$promise;
        }

        function patchEditSettings(id, settingId, form) {
            return witResource.patchEditSettings({id: id, action_id: settingId}, form).$promise;
        }

        function patchResourceRecords(id, records) {
            return witResource.patchResourceRecords({id: id}, {records: records}).$promise;
        }

        function patchRestartCertificate(id, certificateId) {
            return witResource.patchRestartCertificate({id: id, action_id: certificateId}, {}).$promise;
        }

        function patchRestartUnstoredCertificate(id, domainId) {
            return witResource.patchRestartUnstoredCertificate({id: id, action_id: domainId}, {}).$promise;
        }

        function patchSuspendCertificate(id, certificateId, suspend) {
            return witResource.patchSuspendCertificate({id: id, action_id: certificateId}, {suspend: suspend}).$promise;
        }

        function patchEditOrigin(id, originId, form) {
            return witResource.patchEditOrigin({id: id, action_id: originId}, form).$promise;
        }

        function planChangeStorage(action, selectedPlan, subscription, direction) {
            selectedPlan = typeof selectedPlan !== 'undefined' ? selectedPlan : null;
            subscription = typeof subscription !== 'undefined' ? subscription : null;
            direction = typeof direction !== 'undefined' ? direction : null;
            if (action == 'set') {
                planChange.selectedPlan = selectedPlan;
                planChange.subscription = subscription;
                planChange.action = direction;
            } else {
                return planChange;
            }
        }

        function patchUpdateDomainOrigin(id, domainId, form) {
            return witResource.patchUpdateDomainOrigin({id: id, action_id: domainId}, form).$promise;
        }

        function patchUpdateDomainTransitionTime(id, domainId, form) {
            return witResource.patchUpdateDomainTransitionTime({id: id, action_id: domainId}, form).$promise;
        }

        function postAddNewOrigin(id, origin) {
            return witResource.postAddNewOrigin({id: id}, origin).$promise;
        }

        function postAddPurgeRequest(id, request) {
            return witResource.postAddPurgeRequest({id: id}, request).$promise;
        }

        function postAttachToShard(form) {
            return witResource.postAttachToShard({id: null}, form).$promise;
        }

        function postAttachCustomWildcardToShard(form) {
            return witResource.postAttachCustomWildcardToShard({id: null, action_id: null}, form).$promise;
        }

        function postCreateDomain(id, form) {
            return witResource.postCreateDomain({id: id}, form).$promise;
        }

        function postDetachFromShard(form) {
            return witResource.postDetachFromShard({id: null}, form).$promise;
        }

        function postDomainsNewRegionAws(region, next) {
            return witResource.postDomainsNewRegionAws({id: null, action_id: region}, {next: next}).$promise;
        }

        function postDomainsNewRegionDb(region, domains) {
            return witResource.postDomainsNewRegionDb({id: null, action_id: region}, {domains: domains}).$promise;
        }

        function postDomainsNewRegionRoute53(region, domains) {
            return witResource.postDomainsNewRegionRoute53({id: null, action_id: region}, {domains: domains}).$promise;
        }

        function postRemoveCertificateFromRegion(id, form) {
            return witResource.postRemoveCertificateFromRegion({id: id}, form).$promise;
        }

        function postRequestCertificate(id, form) {
            return witResource.postRequestCertificate({id: id}, form).$promise;
        }

        function postRequestTestCertificate(id, form) {
            return witResource.postRequestTestCertificate({id: id, action_id: null}, form).$promise;
        }

        function postResourceRecords(id, records, store) {
            store = !_.isUndefined(store) ? store : true;
            return witResource.postResourceRecords({id: id}, {action: 'CREATE', records: records, store: store}).$promise;
        }

        function postTrialStageStatistics(start, end, trials) {
            return witResource.postTrialStageStatistics({id: 'trial', action_id: null}, {start: start, end: end, accounts: trials}).$promise;
        }

        function postTrialStatistics(data) {
            return witResource.postTrialStatistics({id: null, action_id: null}, {data: data}).$promise;
        }

        function postUpdateCors(id, domainId, form) {
            return witResource.postUpdateCors({id: id, action_id: domainId}, form).$promise;
        }

        function postUpdatePrefixes(id, domainId, form) {
            return witResource.postUpdatePrefixes({id: id, action_id: domainId}, form).$promise;
        }

        function postValidatePath(form) {
            return witResource.postValidatePath({id: null, action_id: null}, form).$promise;
        }

        function refresh(code, realmId) {
            return witResource.refresh({id: null}, {code: code, realmId: realmId}).$promise;
        }

        function remapLimits(subscription, plans, addIds) {
            addIds = typeof addIds !== 'undefined' ? addIds : false;
            var defaults = {
                usage_limit: {
                    plan_default: {
                        name: 'Usage Limit'
                    }
                },
                overage_fee: {
                    plan_default: {
                        name: 'Overage Fee'
                    }
                },
                flat_rate: {
                    plan_default: {
                        name: 'Monthly Fee'
                    }
                },
                trial_days: {
                    plan_default: {
                        name: 'Trial Days'
                    }
                }
            };
            angular.forEach(plans, function(val, key) {
                if (subscription.plan.plan == val.payment_plan) {
                    defaults.usage_limit.plan_default.value = val.usage_limit;
                    defaults.overage_fee.plan_default.value = val.overage_fee;
                    defaults.flat_rate.plan_default.value = val.flat_rate;
                    defaults.trial_days.plan_default.value = val.trial_days;

                    defaults.usage_limit.value = subscription.plan.limit;
                    defaults.overage_fee.value = subscription.plan.overage_fee;
                    defaults.flat_rate.value = subscription.plan.price;
                    defaults.trial_days.value = subscription.plan.trial_days;
                    if (addIds) {
                        defaults.ids = {
                            plan_default: {
                                value: val.id
                            },
                            value: subscription.image_engine_tier_id
                        };
                    }
                }
            });
            return defaults;
        }

        function restart(id) {
            return witResource.restart({id: id}).$promise;
        }

        function restoreHostname(id, hostname_id) {
            return witResource.restoreHostname({id: id}, {hostname: hostname_id}).$promise;
        }

        function suspend(id, vault_subscription_id) {
            return witResource.suspend(
                {id: id},
                {id: id,vault_subscription_id: vault_subscription_id}
            ).$promise;
        }

        function unsuspend(id, vault_subscription_id) {
            return witResource.unsuspend(
                {id: id},
                {id: id, vault_subscription_id: vault_subscription_id}
            ).$promise;
        }

        function update(form, action) {
            return witResource.update({id: form.id}, {
                form: form,
                action: action
            }).$promise;
        }

        function updateDefaults(id, defaults, tier_ids) {
            return witResource.updateDefaults({id: id}, {
                id: id,
                defaults: defaults,
                tier_ids: tier_ids
            }).$promise;
        }

        function upgrade(data) {
            return witResource.upgrade({id: data.id}, data).$promise;
        }

        function validateNew(form, required) {
            var errors = {
                status: false,
                fields: {
                    account_name: false,
                    payment_plan: false,
                    payment_type: false,
                    user: false,
                    usage_limit: false,
                    flat_rate: false,
                    overage_fee: false,
                    trial_days: false,
                }
            };
            var reqFields = ['usage_limit', 'flat_rate', 'overage_fee', 'trial_days'];
            angular.forEach(errors.fields, function (val, key) {
                if ((reqFields.indexOf(key) !== -1 && required) || reqFields.indexOf(key) === -1) {
                    if (form[key] === '' || form[key] === null || form[key] === undefined) {
                        errors.status = true;
                        errors.fields[key] = true;
                    }
                }
            });

            return errors;
        }

        function validateTier(form) {
            var errors = {
                status: false,
                fields: {
                    usage_limit: false,
                    flat_rate: false,
                    overage_fee: false,
                    trial_days: false
                }
            };
            if (form.plan.limit == '' || form.plan.limit == null) {
                errors.status = true;
                errors.fields.usage_limit = true;
            }
            if (form.plan.price == '' || form.plan.price == null) {
                errors.status = true;
                errors.fields.flat_rate = true;
            }

            if (form.plan.overage_fee == '' || form.plan.overage_fee == null) {
                errors.status = true;
                errors.fields.overage_fee = true;
            }

            if (form.plan.trial_days == '' || form.plan.trial_days == null) {
                errors.status = true;
                errors.fields.trial_days = true;
            }

            return errors;
        }

        function validateWhitelist(form) {
            if (form.allow_origin_prefix == 1 && !_.isNull(form.whitelist)) {
                var distinctWhitelist = new Set(form.whitelist);
                if (form.whitelist.length !== distinctWhitelist.size) {
                    return false;
                }
            }

            return true;
        }
    }
})();
