(function() {
    'use strict';

    angular.module('adminApp.reports')
        .controller('AccountDownloadsImageEngineController', AccountDownloadsImageEngineController);

    AccountDownloadsImageEngineController.$inject = ['$rootScope', '$scope', '$uibModalInstance', 'reportsService'];

    function AccountDownloadsImageEngineController($rootScope, $scope, $uibModalInstance, reportsService) {
        var vm = this;

        vm.progress = 0;
        vm.title = "ImageEngine Accounts Report";

        var updateImageEngineProgress = $rootScope.$on('updateImageEngineProgress', function(event, args) {
            vm.progress = reportsService.calculateProgress(args.complete);
            if (vm.progress == 100) {
                $uibModalInstance.close();
            }
            $scope.$on('$destroy', updateImageEngineProgress);
        });

        reportsService.downloadImageEngineTrialReport();

        var ieErrorClose = $rootScope.$on('ieErrorClose', function() {
            $uibModalInstance.close();
            $scope.$on('$destroy', ieErrorClose);
        });
    }
})();
