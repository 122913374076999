(function() {
    'use strict';

    angular.module('adminApp.cut')
        .controller('CutModalController', CutModalController);

    CutModalController.$inject = ['$filter', '$rootScope', '$state', '$uibModalInstance', 'centraltestHttpHeaders', 'cutModalService', 'items', 'Notification'];

    function CutModalController($filter, $rootScope, $state, $uibModalInstance, centraltestHttpHeaders, cutModalService, items, Notification) {
        var vm = this;

        vm.action = items.action;
        vm.close = close;
        vm.creating = items.creating;
        vm.disabled = items.disabled;
        vm.edit = edit;
        vm.errors = {};
        vm.form = items.form;
        vm.idDisabled = items.idDisabled;
        vm.include = items.include;
        vm.lengthCheck = lengthCheck;
        vm.save = save;
        vm.suggestedHeaderNames = [];
        vm.title = items.title;

        var f = items.f;
        var ids = items.ids;
        var idType = items.idType;
        var numPages = items.numPages;
        var open = items.open;
        var type = items.type;
        var typeCap = $filter('capitalize')(items.type)

        if (items.type == 'header') {
            centraltestHttpHeaders.suggested().then(function(response) {
                vm.suggestedHeaderNames = response.data.header_names;
            }).catch(function(error) {
                console.log(error);
            });
        }

        function close() {
            $uibModalInstance.dismiss();
        }

        function edit() {
            $uibModalInstance.dismiss();
            cutModalService.edit(type, vm.form.id);
        }

        function lengthCheck(field) {
            var response = f.validateLength(vm.form, field);
            if (response) {
                vm.errors[field] = response;
            } else {
                vm.errors[field] = {
                    show: false
                };
            }
        }

        function save() {
            if (vm.action == 'create') {
                create();
            } else {
                update();
            }
        }

        function create() {
            if (idType == 'test') {
                if (_.indexOf(ids, parseInt(vm.form.test_id)) == -1) {
                    vm.errors.test_id = {
                        show: true,
                        message: 'Test ID cannot be found'
                    };
                    return false;
                }
            }
            if (idType == 'request') {
                if (_.indexOf(ids, parseInt(vm.form.request_id)) == -1) {
                    vm.errors.request_id = {
                        show: true,
                        message: 'Request ID cannot be found'
                    };
                    return false;
                }
            }
            vm.errors = f.validate(vm.form);
            vm.errors = !vm.errors ? {} : vm.errors;
            if (_.isEmpty(vm.errors)) {
                f.create(vm.form).then(function(response) {
                    Notification.success('Created new '+typeCap);
                    $rootScope.$broadcast('reload'+typeCap+'s', {page: numPages, data: response.data, type: 'create'});
                    if (open) { //NOTE This
                        cutModalService.view(type, response.data.id);
                    } else if (type == 'test') {
                        $state.go('centraltest.view_test', {id: response.data.id});
                    }
                    $uibModalInstance.dismiss();
                }).catch(function(error) {
                    Notification.error("Could not create "+type);
                    console.log(error);
                });
            } else {
                return false;
            }
        }

        function update() {
            vm.errors = f.validate(vm.form);
            vm.errors = !vm.errors ? {} : vm.errors;
            if (_.isEmpty(vm.errors)) {
                f.save(vm.form).then(function() {
                    Notification.success('Updated '+typeCap);
                    $rootScope.$broadcast('reload'+typeCap+'s', {data: vm.form, type: 'edit'});
                    if (open) {
                        cutModalService.view(type, vm.form.id);
                    }
                    $uibModalInstance.dismiss();
                }).catch(function(error) {
                    console.log(error);
                    Notification.error("An error occurred");
                });
            } else {
                return false;
            }
        }
    }
})();
