(function() {
    'use strict';

    angular.module('adminApp.licenses')
        .directive('buildProfiles', buildProfiles);

    buildProfiles.$inject = ['$animate', '$compile', '$state', 'infuzeService', 'Notification'];

    function buildProfiles($animate, $compile, $state, infuzeService, Notification) {
        var directive = {
            restrict: 'E',
            transclude: true,
            replace: false,
            templateUrl: 'view/infuze.templates.build_profiles',
            scope: {
                ngInfuzeProfiles: "=infuzeProfile"
            },
            link: link,
            controller: 'OnsiteController',
            controllerAs: 'vm',
            bindToController: true
        };

        return directive;

        function link(scope, element, attrs) {
            scope.addProfile = addProfile;
            scope.infuze_id = attrs.infuzeId;
            scope.newElement = null;
            scope.profile = null;

            var exists = true;

            loadProfiles();

            function loadProfiles() {
                infuzeService.getProfiles(attrs.infuzeId)
                    .then(function(response) {
                        scope.ngInfuzeProfiles = response.data;
                    }, function(error) {
                        switch (error.status) {
                            case 400:
                                Notification.error(error.data.message);
                                console.log(error);
                                break;
                            case 404:
                                Notification.warning(error.data.message);
                                exists = false;
                                break;
                            default:
                                Notification.error('An unexpected error occurred');
                                console.log(error);
                                break;
                        }
                    });
            }

            function addProfile($event, license_id) {
                $event.stopPropagation();
                $event.preventDefault();

                infuzeService.newProfile({license_id: license_id})
                    .then(function(response) {
                        scope.profile = response.data;

                        scope.newElement = '<infuze-profile profile="profile"></infuze-profile>';
                        var compiledElement  = $compile(scope.newElement)(scope);
                        var rootElement = angular.element("build-profiles[infuze-id="+attrs.infuzeId+"]");
                        var afterElement = rootElement.find('infuze-profile').last();
                        if (exists) {
                            $animate.enter(compiledElement, rootElement, afterElement);
                        } else {
                            loadProfiles();
                        }
                    }, function(error) {
                        var message = error.data.message ? error.data.message : 'An Unknown Error Occurred';
                        Notification.error(message);
                    });
            }
        }
    }
})();
