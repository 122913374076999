(function() {
    'use strict';

    angular.module('adminApp.home')
        .controller('HistoryController', HistoryController);

    HistoryController.$inject = ['historyService'];

    function HistoryController(historyService) {
        var vm = this;

        vm.clearHistory = clearHistory;
        gatherHistory();

        function gatherHistory() {
            var history = historyService.full;

            var groups = history.reduce(function(obj, item) {
                obj[item.date] = obj[item.date] || [];
                obj[item.date].push(item);
                return obj;
            }, {});
            vm.history = Object.keys(groups).map(function(key) {
                return {date: key, item: groups[key]};
            });
        }


        function clearHistory() {
            historyService.clear();
            gatherHistory();
        }
    }
})();
