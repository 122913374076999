(function() {
    'use strict';

    angular.module('adminApp.cut')
        .controller('CutExportController', CutExportController);

    CutExportController.$inject = ['centraltestXmlService', 'Notification'];

    function CutExportController(centraltestXmlService, Notification) {
        var vm = this;

        vm.all = false;
        vm.autoChecked = autoChecked;
        vm.exportLists = exportLists;
        vm.type_regression = false;
        vm.type_unit = false;

        function autoChecked() {
            if (vm.all) {
                return true;
            } else {
                return false;
            }
        }

        function exportLists() {
            if (!vm.all && !vm.type_unit && !vm.type_regression) {
                Notification.warning('Please select one or more lists to export');
                return;
            }
            centraltestXmlService.export(vm.all, vm.type_unit, vm.type_regression);
        }
    }
})();
