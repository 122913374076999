(function() {
    'use strict';

    angular.module('adminApp')
        .directive('capabilityPicker', capabilityPicker);

    capabilityPicker.$inject = [];

    function capabilityPicker() {
        var directive = {
            restrict: 'EA',
            templateUrl: 'view/components.capabilitypicker',
            bindToController: true,
            controller: 'CapabilityPickerController',
            controllerAs: 'vm',
            scope: {
                accountCapabilities: '=',
                data: '=',
                max: '@',
                originalCapabilities: '=',
                type: '@'
            }
        };
        return directive;
    }
})();
