(function() {
    'use strict';

    angular.module('adminApp.wjs')
        .controller('WjsViewController', WjsViewController);

    WjsViewController.$inject = ['$filter', '$ngConfirm', '$rootScope', '$scope', '$state', '$stateParams', 'accountService', 'baseAccountService', 'breadcrumbsService', 'Notification', 'subscriptionModalService', 'wjsAccountService', 'wjsModalService'];

    function WjsViewController($filter, $ngConfirm, $rootScope, $scope, $state, $stateParams, accountService, baseAccountService, breadcrumbsService, Notification, subscriptionModalService, wjsAccountService, wjsModalService) {
        var vm = this;

        vm.billingInfoAvailable = undefined;
        vm.cancel = cancel;
        vm.cancelReason = accountService.getValues('wjs', 'cancelReason');
        vm.createNew = createNew;
        vm.changePlan = changePlan;
        vm.currentTab = 0;
        vm.defaults = undefined;
        vm.edit = edit;
        vm.editDefaults = editDefaults;
        vm.endTrial = endTrial;
        vm.loading = true;
        vm.otherAccounts = undefined;
        vm.restart = restart;
        vm.stateParams = $stateParams;
        vm.steps = {};
        vm.subscription = undefined;
        vm.suspend = suspend;
        vm.swapPaths = swapPaths;
        vm.unsuspend = unsuspend;
        vm.updateBreadcrumbs = updateBreadcrumbs;

        if ($state.current.name === 'wjs.view') {
            if (
                vm.subscription === undefined ||
                vm.defaults === undefined ||
                vm.otherAccounts === undefined ||
                vm.billingInfoAvailable === undefined
            ) {
                run();
            } else {
                vm.steps = breadcrumbsService.get('wjs.view', {
                    username: vm.subscription.user.username,
                    userid: vm.subscription.user_id,
                    name: vm.subscription.account_name
                });
                vm.loading = false;
            }
        } else {
            vm.loading = false;
        }

        var updateWjsSubscription = $rootScope.$on('updateWjsSubscription', function() {
            vm.subscription = accountService.getValues('wjs', 'subscription');
            vm.defaults = accountService.getValues('wjs', 'defaults');
            vm.otherAccounts = accountService.getValues('wjs', 'otherAccounts');
            vm.billingInfoAvailable = accountService.getValues('wjs', 'billingInfoAvailable');
            $scope.$on('$destroy', updateWjsSubscription);
            vm.steps = breadcrumbsService.get($state.current.name, {
                username: vm.subscription.user.username,
                userid: vm.subscription.user_id,
                name: vm.subscription.account_name,
                id: vm.subscription.id
            });
        });

        var changeWjsTab = $rootScope.$on('changeWjsTab', function(event, args) {
            vm.currentTab = args.tab;
            vm.billingInfoAvailable = accountService.getValues('wjs', 'billingInfoAvailable');
            $scope.$on('$destroy', changeWjsTab);
        });

        var disableButtons = $rootScope.$on('disableButtons', function() {
            vm.loading = true;
            $scope.$on('$destroy', disableButtons);
        });

        var reloadSubscription = $rootScope.$on('reloadSubscription', function(event, args) {
            if (args.type == 'wjs') {
                vm.subscription.account_name = args.data.account_name;
                vm.subscription.status = args.data.status;
                vm.subscription.payment_plan = args.data.payment_plan;
                vm.subscription.payment_type = args.data.payment_type;
                vm.subscription.date_started = args.data.date_started;
                vm.subscription.date_expiration = args.data.date_expiration;
                vm.subscription.date_last_modified = $filter('date')($filter('createDate')({date: vm.subscription.date_last_modified, format: 'YMD HIS'}), 'yyyy-MM-dd HH:mm:ss');
                accountService.setValues('wjs', 'subscription', vm.subscription);
            }
            $scope.$on('$destroy', reloadSubscription);
        });

        var completeReload = $rootScope.$on('completeReload', function() {
            vm.subscription = undefined;
            vm.defaults = undefined;
            vm.otherAccounts = undefined;
            vm.billingInfoAvailable = undefined;
            run();
        })

        var reloadDefaults = $rootScope.$on('reloadDefaults', function(event, args) {
            if (args.type == 'wjs') {
                vm.defaults = baseAccountService.remapLimits(args.data, 'wurfl_js_default');
            }
            $scope.$on('$destroy', reloadDefaults);
        });

        var canceledAccount = $rootScope.$on('canceledAccount', function(event, args) {
            if (args.type == 'wjs') {
                if (args.success) {
                    vm.subscription.status = 'CANCELED';
                    vm.subscription.date_expiration = $filter('date')(new Date(), 'yyyy-MM-dd HH:mm:ss');
                    vm.subscription.payment_type = 'EXTERNAL';
                    vm.cancelReason = args.data.reason;
                    baseAccountService.getChangeLog('/api/admin/wjs', $stateParams.id).then(function(response) {
                        vm.subscription.plan_changes = response.data;
                    }).catch(function(error) {
                        console.log(error);
                    }).finally(function() {
                        vm.loading = false;
                    });
                } else {
                    Notification.error('Could not cancel account');
                    vm.loading = false;
                }
            }
        });

        var suspendedAccount = $rootScope.$on('suspendedAccount', function(event, args) {
            if (args.type == 'wjs') {
                if (args.success) {
                    vm.subscription.status = 'SUSPENDED';
                    vm.subscription.date_last_modified = $filter('date')(new Date(), 'yyyy-MM-dd HH:mm:ss');
                    Notification.success("Account has been suspended");
                } else {
                    Notification.error("Could not suspend account");
                }
                vm.loading = false;
            }
            $scope.$on('$destroy', suspendedAccount);
        });

        var unsuspendedAccount = $rootScope.$on('unsuspendedAccount', function(event, args) {
            if (args.type == 'wjs') {
                if (args.success) {
                    vm.subscription.status = 'ACTIVE';
                    vm.subscription.date_last_modified = $filter('date')(new Date(), 'yyyy-MM-dd HH:mm:ss');
                    Notification.success("Account has been reactivated");
                } else {
                    Notification.error("Could not unsuspend account");
                }
                vm.loading = false;
            }
            $scope.$on('$destroy', unsuspendedAccount);
        });

        switch ($state.current.name) {
            case 'wjs.view':
                vm.currentTab = 0;
                break;
            case 'wjs.view.configuration':
                vm.currentTab = 1;
                break;
            case 'wjs.view.usage':
                vm.currentTab = 2;
                break;
            case 'wjs.view.billing':
                vm.currentTab = 3;
                break;
            default:

        }

        function cancel() {
            subscriptionModalService.cancel('wjs');
        }

        function changePlan(direction) {
            wjsModalService.changePlan(direction, vm.subscription)
        }

        function createNew() {
            subscriptionModalService.createNew('wjs');
        }

        function edit() {
            subscriptionModalService.edit('wjs');
        }

        function editDefaults() {
            subscriptionModalService.editDefaults('wjs', vm.subscription.payment_plan);
        }

        function endTrial() {
            wjsModalService.changePlan(null, vm.subscription);
        }

        function restart() {
            subscriptionModalService.restart('wjs', vm.subscription);
        }

        function run() {
            baseAccountService.get(wjsAccountService.resourceUrl, $stateParams.id)
                .then(function(response) {
                    vm.subscription = response.data.account;
                    vm.steps = breadcrumbsService.get('wjs.view', {
                        username: vm.subscription.user.username,
                        userid: vm.subscription.user_id,
                        name: vm.subscription.account_name
                    });
                    accountService.setValues('wjs', 'subscription', vm.subscription);
                    vm.otherAccounts = response.data.other_accounts;
                    accountService.setValues('wjs', 'plans', response.data.plans);
                    vm.defaults = baseAccountService.remapLimits(vm.subscription.wurfl_js_accounts_defaults, 'wurfl_js_default');
                    accountService.setValues('wjs', 'defaults', vm.defaults);
                    vm.billingInfoAvailable = baseAccountService.isFree(vm.subscription) === false && baseAccountService.isStandardBilling(vm.subscription);
                    if (vm.subscription.status == 'CANCELED') {
                        baseAccountService.getCancelLog(wjsAccountService.resourceUrl, $stateParams.id)
                            .then(function(response) {
                                vm.cancelReason = response.data.reason == '' ? "No reason provided" : response.data.reason;
                                accountService.setValues('wjs', 'cancelReason', vm.cancelReason);
                            }).catch(function(error) {
                                console.log(error);
                            });
                    }
                    if ($stateParams.edit) {
                        edit();
                    } else if ($stateParams.editDefaults) {
                        editDefaults();
                    }
                }).catch(function(error) {
                    console.log(error);
                    Notification.error("The account could not be loaded");
                }).finally(function() {
                    vm.loading = false;
                });
        }

        function suspend() {
            subscriptionModalService.suspend('wjs');
        }

        function swapPaths() {
            if (vm.subscription.payment_type == 'STANDARD') {
                $ngConfirm({
                    title: 'Are you sure?',
                    theme: 'dark',
                    content: 'This will cancel recurring payments for the subscription.<br /><br />Are you sure you want to continue?',
                    buttons: {
                        close: {
                            text: 'Cancel',
                            btnClass: 'btn-default'
                        },
                        proceed: {
                            text: 'Continue',
                            btnClass: 'btn-primary',
                            action: function() {
                                vm.subscription.removePayment = true;
                                vm.subscription.payment_type = 'EXTERNAL';
                                vm.loading = true;
                                baseAccountService.update("/api/admin/wjs", vm.subscription)
                                    .then(function(response) {
                                        Notification.success('Updated account');
                                        $rootScope.$broadcast('reloadSubscription', {type: 'wjs', data: response.data});
                                    }, function(error) {
                                        Notification.error(error.data.message);
                                        console.log(error);
                                    }).finally(function() {
                                        vm.loading = false;
                                    });
                            }
                        }
                    }
                });
            } else {
                wjsModalService.makeStandardBilling();
            }
        }

        function unsuspend() {
            subscriptionModalService.unsuspend('wjs');
        }

        function updateBreadcrumbs(tab) {
            if (tab == 'view') {
                var state = 'wjs.view';
                vm.currentTab = 0;
            } else {
                var state = 'wjs.view.'+tab;
                switch (tab) {
                    case 'configuration':
                        vm.currentTab = 1;
                        break;
                    case 'usage':
                        vm.currentTab = 2;
                        break;
                    case 'billing':
                        vm.currentTab = 3;
                        break;
                }
            }
            vm.steps = breadcrumbsService.get(state, {
                username: vm.subscription.user.username,
                userid: vm.subscription.user_id,
                name: vm.subscription.account_name,
                id: vm.subscription.id
            });
        }
    }
})();
