(function() {
    'use strict';

    angular.module('adminApp.wit')
        .controller('WitSettingsController', WitSettingsController);

    WitSettingsController.$inject = ['$ngConfirm', '$stateParams', '$uibModal', '$uibModalInstance', 'witModalService', 'domain', 'isNew', 'Notification', 'setting', 'settingsService', 'witAccountService'];

    function WitSettingsController($ngConfirm, $stateParams, $uibModal, $uibModalInstance, witModalService, domain, isNew, Notification, setting, settingsService, witAccountService) {
        var vm = this;

        vm.checkCheckboxStatus = checkCheckboxStatus;
        vm.checkCheckboxStatusCmprdpicl = checkCheckboxStatusCmprdpicl;
        vm.checkFitMethod = checkFitMethod;
        vm.checkQualityOverride = checkQualityOverride;
        vm.checkStaticImage = checkStaticImage;
        vm.close = close;
        vm.errors = {
            path: false,
            setting_name: false
        };
        vm.form = {
            path: _.isNull(setting) ? null : setting.path,
            setting_name: _.isNull(setting) ? null : setting.setting_name,
            w: null,
            h: null,
            m: {
                selected: null,
                hex: null,
                opacity: null
            },
            ppi: null,
            naf: [],
            cors: [{
                protocol: null,
                hostname: null,
                port: null
            }],
            cmprdpicl: {},
            s: null,
            origin_returnable: null
        };
        vm.formatOptions = ["jpg","gif","png","bmp","webp","jp2"];
        vm.formatOptionsAuto = ["webp", "mp4", "jp2"];
        vm.openFitMethod = openFitMethod;
        vm.openHonorPpi = openHonorPpi;
        vm.openPathExplanation = openPathExplanation;
        vm.openPathValidation = openPathValidation;
        vm.qualityOverride = null;
        vm.radio = {
            size: 'default',
            quality: 'default',
            format: null,
            origin_returnable_behavior: 'default'
        };
        vm.running = false;
        vm.selected = {
            browser_cache_ttl: {
                default: true,
                custom: false
            },
            cache_ttl: {
                default: true,
                custom: false
            },
            cors: {
                default: true,
                custom: false
            },
            format: {
                default: true,
                custom: false
            },
            image_info: {
                default: true,
                custom: false
            },
            meta: {
                default: true,
                custom: false
            },
            origin_timeout: {
                default: true,
                custom: false
            },
            pass: {
                default: true,
                custom: false
            },
            s: {
                default: true,
                custom: false
            },
            x_wit_sync: {
                default: true,
                custom: false
            },
            origin_returnable: {
                default: true,
                custom: false
            },
            response_headers: {
                default: true,
                custom: false
            }
        };
        vm.sharpenSliderOptions = {
            floor: 0,
            ceil: 100,
            step: 1,
            minLimit: 0,
            maxLimit: 100,
            minValue: 0,
            maxValue: 100
        };
        vm.sliderOptions = {
            floor: 1,
            ceil: 100,
            step: 1,
            minLimit: 1,
            maxLimit: 100
        }
        vm.submit = submit;
        vm.title = isNew ? "Add Setting to " : "Edit Setting for ";
        vm.title += domain.cname;
        vm.updateCheckbox = updateCheckbox;
        vm.updateCorsOrigin = updateCorsOrigin;
        vm.updateFitMethod = updateFitMethod;
        vm.updateImageFormat = updateImageFormat;
        vm.updateImageQuality = updateImageQuality;
        vm.updateImageSize = updateImageSize;
        vm.updateQuality = updateQuality;
        vm.updateQualityOverride = updateQualityOverride;
        vm.updateCustomResponseHeaders = updateCustomResponseHeaders;
        vm.removeCustomResponseHeader = removeCustomResponseHeader;
        vm.addCustomResponseHeader = addCustomResponseHeader;
        vm.updateSelected = updateSelected;

        if (!_.isNull(setting)) {
            angular.forEach(setting.wit_domain_path_setting, function(obj) {
                if (!obj.wit_setting.active) {
                    return;
                }

                var fnc = settingsService.formatSetting(obj);
                var response = settingsService[fnc](obj);
                vm.form[obj.wit_setting.directive] = response.value || response.map_value;
                angular.forEach(vm.selected[response.selected.field], function(val, key) {
                    if (key === response.selected.value) {
                        vm.selected[response.selected.field][key] = true;
                    } else {
                        vm.selected[response.selected.field][key] = false;
                    }
                });

                if (!_.isUndefined(response.radio)) {
                    angular.forEach(response.radio, function(o) {
                        vm.radio[o.field] = o.value;
                    });
                }

                if (!_.isUndefined(response.form)) {
                    angular.forEach(response.form, function(o) {
                        vm.form[o.field] = o.value;
                    });
                }
            });

            if (vm.radio.quality == 'auto' && _.isNull(vm.form.ppi) && _.isUndefined(vm.form.w_auto)) {
                vm.radio.size = 'auto';
            }

            if (vm.radio.size == 'auto' && _.isNull(vm.form.ppi)) {
                vm.form.ppi = 'true';
            }
        }

        function checkCheckboxStatus(option) {
            if (_.indexOf(vm.form.naf, option) !== -1) {
                return true;
            }
            return false;
        }

        function checkCheckboxStatusCmprdpicl(val) {
            if (_.indexOf(_.keys(vm.form.cmprdpicl), val) !== -1) {
                return true;
            }
            return false;
        }

        function checkFitMethod() {
            if (vm.selected.image_info == 'default' || vm.radio.size !== 'force') {
                return true;
            }

            if (vm.form.w == null || vm.form.h == null) {
                return true;
            }

            return false;
        }

        function checkQualityOverride(option) {
            return _.indexOf(_.keys(vm.form.cmprdpicl), option) !== -1;
        }

        function checkStaticImage() {
            if (_.isNull(vm.form.w) || _.isNull(vm.form.h)) {
                vm.form.m = {
                    selected: null,
                    hex: null,
                    opacity: null
                };
            } else if (!_.isNull(vm.form.w) && !_.isNull(vm.form.h) && _.isNull(vm.form.m)) {
                vm.form.m = {
                    selected: "stretch",
                    hex: null,
                    opacity: null
                };
            }
        }

        function close() {
            $uibModalInstance.dismiss();
        }

        function openFitMethod() {
            $ngConfirm({
                title: 'Fit Methods',
                theme: 'dark',
                contentUrl: 'view/subscriptions.wit.components.fit_method',
                buttons: {
                    close: {
                        text: 'OK',
                        btnClass: 'btn-primary'
                    }
                }
            });
        }

        function openHonorPpi() {
            $ngConfirm({
                title: 'Honor High PPI Screens',
                theme: 'dark',
                content: "Indicates whether to use \"display pixels\" or physical pixels when deciding the optimal pixel size of the image. Default is physical pixels. If display pixels (sometimes called CSS pixels) is used, images will be smaller in byte size but may have lower visual quality.",
                buttons: {
                    close: {
                        text: 'OK',
                        btnClass: 'btn-primary'
                    }
                }
            });
        }

        function openPathExplanation() {
            $ngConfirm({
                title: 'Valid Path Pattern',
                theme: 'dark',
                contentUrl: 'view/subscriptions.wit.components.path_explanation',
                buttons: {
                    close: {
                        text: 'OK',
                        btnClass: 'btn-primary'
                    }
                }
            });
        }

        function openPathValidation() {
            if (_.isNull(vm.form.path)) {
                Notification.warning("Please provide a path");
                return;
            }

            var path = angular.copy(vm.form.path);
            if (!vm.form.path.match(/^\//)) {
                path = "/**/"+path;
            }

            var result = witModalService.pathValidation(vm.form.path, path);
            result.result.then(function(response) {
                vm.form.path = response;
            });
        }

        function submit() {
            angular.forEach(vm.selected, function(obj, field) {
                if (obj.default) {
                    switch (field) {
                        case 'image_info':
                            vm.radio.size = 'default';
                            vm.radio.quality = 'default';
                            vm.form.w = null;
                            vm.form.h = null;
                            vm.form.m = null;
                            vm.form.w_auto = null;
                            vm.form.ppi = null;
                            vm.form.cmprdpicl = {};
                            break;
                        case 'format':
                            vm.form.naf = [];
                            vm.form.f = null;
                            vm.radio.format = null;
                            break;
                        case 'cors':
                            vm.form.cors = [{
                                protocol: null,
                                hostname: null,
                                port: null
                            }];
                            break;
                        default:
                            vm.form[field] = null;
                    }
                }
            });

            vm.errors = _.mapObject(vm.errors, function(val, key) {
                return false;
            });

            if (_.isNull(vm.form.setting_name) || vm.form.setting_name == '') {
                vm.errors.setting_name = true;
            }

            if (_.isNull(vm.form.path) || vm.form.path == '') {
                vm.errors.path = true;
            }

            if (vm.errors.path || vm.errors.setting_name) {
                Notification.error("Please provide both a path and a setting name");
                return;
            }

            var form = _.omit(vm.form, function(obj, key) {
                return key == 'setting_name' || key == 'path';
            });

            if (_.every(form.m, function(obj) {
                return _.isNull(obj);
            })) {
                form.m = null
            }

            if (form.naf.length === 0) {
                form.naf = null;
            }

            if (_.every(form.cors, function(value) {
                return _.every(value, function(val) {
                    return _.isNull(val);
                });
            })) {
                form.cors = null;
            }

            var others = _.reject(form, function(obj) {
                return _.isNull(obj);
            });

            if (_.isEmpty(others)) {
                Notification.error("Please provide a minimum of one custom setting");
                return;
            }

            if (vm.radio.size == 'auto' && (_.isNull(vm.form.w_auto) || _.isUndefined(vm.form.w_auto))) {
                vm.errors.w_auto = true;
            }

            if (!_.every(vm.errors, function(obj) {
                return obj === false;
            })) {
                Notification.error("Form errors exist");
                return;
            }

            form.path = vm.form.path;
            form.setting_name = vm.form.setting_name;
            form = _.omit(form, function(val, key) {
                return _.isNull(val);
            });

            if (!_.isUndefined(form.naf)) {
                form.naf = form.naf.join(",");
            }

            if (!_.isUndefined(form.m)) {
                if (!_.isUndefined(form.m.hex) && !_.isNull(form.m.hex)) {
                    form.m.hex = "#" + form.m.hex;
                }
                form.m = JSON.stringify(form.m);
            }

            if (!_.isUndefined(form.cmprdpicl)) {
                if (!_.isEmpty(form.cmprdpicl)) {
                    form.cmprdpicl = JSON.stringify(form.cmprdpicl);
                } else {
                    delete form.cmprdpicl;
                }
            }

            if (!_.isNull(form.cors)) {
                var cors = [];
                var errors = [];
                angular.forEach(form.cors, function(val, key) {
                    try {
                        if (val.protocol === 's3') {
                            var origin = 'http://' + val.hostname;
                        } else {
                            var origin = val.protocol + "://" + val.hostname;
                        }

                        if (!_.isNull(val.port)) {
                            origin += ":" + val.port;
                        }
                        if (_.isNull(val.protocol) || _.isNull(val.hostname)) {
                            errors.push({
                                value: origin,
                                message: "Protocol and hostname are required for all CORS origins",
                                class: 'error'
                            });
                            return;
                        }

                        var url = new URL('', origin);

                        if (
                            url.username !== "" ||
                            url.password !== "" ||
                            url.hash !== "" ||
                            url.search !== "" ||
                            !_.isEmpty(url.searchParams)
                        ) {
                            errors.push({
                                value: origin,
                                message: "The provided origin is incorrectly formatted. Please only include the origin scheme and path, with an optional port between 1 and 65535.<br />Suggested: "+url.origin,
                                class: 'warning'
                            });
                            return;
                        } else if (
                            !_.isNull(val.port) &&
                            (val.port < 1 || val.port > 65535)
                        ) {
                            errors.push({
                                value: origin,
                                message: "Invalid origin provided for CORS Header Support. Please be sure to enclude the origin scheme and path, with an optional port between 1 and 65535.",
                                class: 'error'
                            });
                            return;
                        }
                        if (val.protocol === 's3') {
                            origin = origin.replace("http", "s3");
                        }
                        cors.push(origin);
                    } catch (err) {
                        errors.push({
                            value: origin,
                            message: "Invalid origin provided for CORS Header Support. Please be sure to enclude the origin scheme and path, with an optional port between 1 and 65535.",
                            class: 'error'
                        });
                        return;
                    }
                });

                if (errors.length > 0) {
                    var message = '';
                    var errorClass = null;
                    angular.forEach(errors, function(val) {
                        message += "<strong>" + val.value + "</strong><br />";
                        message += val.message + "<br />";
                        if (_.isNull(errorClass)) {
                            errorClass = val.class;
                        } else if (errorClass !== 'error' && val.class === 'error') {
                            errorClass = val.class;
                        }
                    });
                    if (errorClass == 'error') {
                        Notification.error(message);
                    } else {
                        Notification.warning(message);
                    }

                    return;
                } else {
                    if (cors.length !== 0) {
                        form.cors = cors.join(",");
                    }
                }
            }

            if (vm.selected.origin_returnable.custom) {
                form.origin_returnable = vm.radio.origin_returnable_behavior;
                if (!_.isUndefined(form.origin_returnable_ttl) && !_.isNull(form.origin_returnable_ttl) && !_.isNaN(form.origin_returnable_ttl)) {
                    form.origin_returnable += '; ttl=' + form.origin_returnable_ttl;
                }
            }
            delete form.origin_returnable_ttl;

            if (!_.isUndefined(form.response_headers) && !_.isNull(form.response_headers)) {
                form.response_headers = _.filter(form.response_headers, function(obj) {
                    return obj.key.trim() !== '' || obj.value.trim() !== '';
                });

                if (form.response_headers.length === 0) {
                    Notification.error('At least one header is required.');
                    return;
                }

                var keyError = '',
                    valueError = '';
                angular.forEach(form.response_headers, function(obj) {
                    if (obj.key.trim() === '') {
                        keyError = 'Please provide a name for all custom headers.';
                        return;
                    }

                    if (obj.value.trim() === '') {
                        valueError = 'Please provide a value for all custom headers.';
                        return;
                    }
                });

                if (keyError) {
                    Notification.error(keyError);
                }
                if (valueError) {
                    Notification.error(valueError);
                }
                if (keyError || valueError) {
                    return;
                }

                var uniqueKeys = new Set(_.map(form.response_headers, function(obj) { return obj.key; }));
                if (form.response_headers.length !== uniqueKeys.size) {
                    Notification.error('A duplicate header key was found.');
                    return;
                }
            }

            if (form.path === '/**/*' || form.path === '**/*') {
                var run = witModalService.confirmSettings("<h3>Are you really sure you want to apply these settings to all images?</h3><p>Moving forward, all supplied settings will be applied to all images. If you're certain, press \"proceed\" to continue, or press \"cancel\" to make changes.</p>");
                run.result.then(function() {
                    runSubmit(form);
                });
            } else if (!form.path.match(/^\//)) {
                var message = "<p>The supplied settings will be applied to all paths ending in <code>"+form.path+"</code>. If this is not the desired behavior, please press \"close\" to make changes.</p><p>Adding a proceeding <code>/</code> will limit the settings to paths matching the entire pattern only.</p>";
                var confirm = witModalService.confirmSettings(message);
                confirm.result
                    .then(function() {
                        form.path = "/**/"+form.path;
                        runSubmit(form);
                    })
            } else {
                runSubmit(form)
            }
        }

        function runSubmit(form) {
            vm.running = true;
            if (isNew) {
                witAccountService.postCreateSettings($stateParams.id, domain.id, form)
                    .then(function(response) {
                        $uibModalInstance.close(response.data);
                    }).catch(function(error) {
                        console.log(error);
                        Notification.error(error.data.message);
                    }).finally(function() {
                        vm.running = false;
                    });
            } else {
                witAccountService.patchEditSettings($stateParams.id, setting.id, form)
                    .then(function(response) {
                        Notification.success("Updated settings");
                        $uibModalInstance.close(response.data);
                    }).catch(function(error) {
                        console.log(error);
                        Notification.error(error.data.message);
                    }).finally(function() {
                        vm.running = false;
                    });
            }
        }

        function updateCheckbox(option) {
            if (_.indexOf(vm.form.naf, option) === -1) {
                vm.form.naf.push(option);
            } else {
                var indx = _.indexOf(vm.form.naf, option);
                vm.form.naf.splice(indx, 1);
            }
        }

        function updateCorsOrigin(key) {
            if (_.isUndefined(key)) {
                vm.form.cors.push({
                    protocol: 'http',
                    hostname: null,
                    port: null
                });
            } else {
                vm.form.cors.splice(key, 1);
                if (vm.form.cors.length === 0) {
                    vm.form.cors = [{
                        protocol: 'http',
                        hostname: null,
                        port: null
                    }];
                }
            }
        }

        function updateFitMethod() {
            vm.form.m.hex = null;
            vm.form.m.opacity = null;
        }

        function updateImageFormat(value) {
            if (value != 'force') {
                vm.form.f = null;
            }
            vm.form.naf = [];
        }

        function updateImageQuality(value) {
            if (value == 'specify') {
                vm.form.cmpr = 1;
            } else {
                vm.form.cmpr = null;
            }

            if (!_.isNull(vm.form.ppi)) {
                vm.form.cmprdpicl = {};
            }
            vm.qualityOverride = null;
        }

        function updateImageSize(value) {
            switch (value) {
                case 'none':
                case 'force':
                    vm.form.w = null;
                    vm.form.h = null;
                    vm.form.m = null;
                    vm.form.w_auto = null;
                    vm.form.ppi = null;
                    vm.form.cmprdpicl = null;
                    if (vm.radio.quality == 'auto') {
                        vm.qualityOverride = null;
                        vm.radio.quality = 'default';
                        vm.form.cmpr = 1;
                    }
                    break;
                case 'auto':
                    vm.form.w_auto = 2560;
                    vm.form.w = null;
                    vm.form.h = null;
                    vm.form.m = null;
                    vm.form.ppi = 'true';
                    vm.form.cmprdpicl = {};
                    break;
            }
        }

        function updateQuality() {
            if (vm.form.ppi == 'false') {
                if (vm.radio.quality === 'auto') {
                    vm.radio.quality = 'default';
                }
                vm.form.cmprdpicl = {};
                vm.qualityOverride = null;
            }
        }

        function updateQualityOverride(val) {
            if (_.indexOf(_.keys(vm.form.cmprdpicl), val) === -1) {
                vm.form.cmprdpicl[val] = 1;
            } else {
                vm.form.cmprdpicl = _.omit(vm.form.cmprdpicl, function(value, key, obj) {
                    return key === val;
                });
            }
        }

        function updateCustomResponseHeaders(value) {
            if (value == 'custom') {
                vm.form.response_headers = [{key: '', value: ''}];
            } else {
                vm.form.response_headers = null;
            }
            updateSelected('response_headers', value);
        }

        function removeCustomResponseHeader(i) {
            vm.form.response_headers.splice(i, 1);
        }

        function addCustomResponseHeader() {
            vm.form.response_headers.push({key: '', value: ''});
        }

        function updateSelected(field, value) {
            if (value == 'custom') {
                switch (field) {
                    case 'cors':
                        if (_.every(vm.form.cors, function(value) {
                            return _.every(value, function(val) {
                                return _.isNull(val);
                            });
                        })) {
                            vm.form.cors[0].protocol = 'http';
                        }
                        break;
                    case 's':
                        if (_.isNull(vm.form.s)) {
                            vm.form.s = 0;
                        }
                        break;
                }
            }

            angular.forEach(vm.selected[field], function(val, key) {
                if (key == value) {
                    vm.selected[field][key] = true;
                } else {
                    vm.selected[field][key] = false;
                }
            });
        }
    }
})();
