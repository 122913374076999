(function() {
    'use strict';

    angular.module('adminApp.coreapi')
        .factory('coreApiModalService', coreApiModalService);

    coreApiModalService.$inject = ['$uibModal'];

    function coreApiModalService($uibModal) {
        var service = {
            create: create,
            createScope: createScope,
            deleteClient: deleteClient,
            deleteScope: deleteScope,
            edit: edit,
            editClientScopes: editClientScopes,
            editScope: editScope
        };

        return service;

        function create() {
            $uibModal.open({
                templateUrl: 'view/coreapi.modal_template',
                size: 'md',
                keyboard: false,
                backdrop: 'static',
                controller: "CoreApiNewModalController",
                controllerAs: "vm"
            });
        }

        function createScope() {
            $uibModal.open({
                templateUrl: 'view/coreapi.modal_template',
                size: 'md',
                keyboard: false,
                backdrop: 'static',
                controller: "CoreApiNewScopeModalController",
                controllerAs: "vm"
            });
        }

        function deleteClient(id) {
            $uibModal.open({
                templateUrl: 'view/coreapi.modal_template',
                size: 'md',
                keyboard: false,
                backdrop: 'static',
                controller: "CoreApiDeleteModalController",
                controllerAs: "vm",
                resolve: {
                    clientId: function() {
                        return id;
                    }
                }
            });
        }

        function deleteScope(scope) {
            $uibModal.open({
                templateUrl: 'view/coreapi.modal_template',
                size: 'md',
                keyboard: false,
                backdrop: 'static',
                controller: "ScopesDeleteModalController",
                controllerAs: "vm",
                resolve: {
                    scope: function() {
                        return scope;
                    }
                }
            });
        }

        function edit(client) {
            $uibModal.open({
                templateUrl: 'view/coreapi.modal_template',
                size: 'md',
                keyboard: false,
                backdrop: 'static',
                controller: "CoreApiEditModalController",
                controllerAs: "vm",
                resolve: {
                    client: function() {
                        return client;
                    }
                }
            });
        }

        function editClientScopes(id) {
            $uibModal.open({
                templateUrl: 'view/coreapi.modal_template',
                size: 'lg',
                keyboard: false,
                backdrop: 'static',
                controller: "ClientScopesController",
                controllerAs: "vm",
                resolve: {
                    clientId: function() {
                        return id;
                    }
                }
            });
        }

        function editScope(scope) {
            $uibModal.open({
                templateUrl: 'view/coreapi.modal_template',
                size: 'md',
                keyboard: false,
                backdrop: 'static',
                controller: "ScopesEditModalController",
                controllerAs: "vm",
                resolve: {
                    scope: function() {
                        return scope;
                    }
                }
            });
        }
    }
})();
