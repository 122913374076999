(function() {
    'use strict';

    angular.module('adminApp.users')
        .factory('teamModalService', teamModalService);

    teamModalService.$inject = ['$state', '$uibModal', 'teamService',  '$window', 'externalService', 'loginService', 'Notification'];

    function teamModalService($state, $uibModal, teamService, $window, externalService, loginService, Notification) {
        var service = {
            createNew: createNew,
            delete: deleteTeam,
            edit: edit,
            showTeamUsers: showTeamUsers,
            showTeamSubscriptions: showTeamSubscriptions,
        };

        return service;

        function createNew() {
            $uibModal.open({
                templateUrl: 'view/users.templates.modal_team_form',
                controller: 'CreateTeamController',
                controllerAs: 'vm',
                size: 'md',
                keyboard: false,
                backdrop: 'static'
            }).result.then(function(result) {
                if (result) {
                    return $state.transitionTo("users.teams");
                }
            });
        }

        function edit(id) {
            $uibModal.open({
                templateUrl: 'view/users.templates.modal_team_form',
                controller: 'EditTeamController',
                controllerAs: 'vm',
                size: 'md',
                keyboard: false,
                backdrop: 'static',
                resolve: {
                    id: function() {
                        return id;
                    }
                }
            }).result.then(function(result) {
                if (result) {
                    return $state.transitionTo("users.teams");
                }
            });
        }

        function deleteTeam(id) {
            $uibModal.open({
                templateUrl: 'view/users.templates.action_modal',
                controller: 'TeamsDeleteController',
                controllerAs: 'vm',
                size: 'lg',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    id: function() {
                        return id;
                    }
                }
            });
        }

        function showTeamUsers(teamId) {
            $uibModal.open({
                templateUrl: 'view/teams.modals.users',
                controller: ['$uibModalInstance', function($uibModalInstance) {
                    var vm = this;
                    vm.data = null;
                    teamService.users(teamId).then(function(response) {
                        vm.data = response.data;
                    }).catch(function(teamError) {
                        Notification.error("An unexpected error has occurred");
                        console.log(teamError);
                    });

                    vm.close = function() {
                        $uibModalInstance.dismiss();
                    }

                    vm.impersonateWURFL = function(id) {
                        // NOTE Window will open, data will appear when async is done
                        var tabWindowId = $window.open('about:blank', '_blank');
                        externalService.postSessionToken(loginService.authInfo.user.id, id).then(function(response) {
                            var url = response.data.url;
                            var session = response.data.session;
                            tabWindowId.location.href = url + "/impersonate/app/admin?session="+session+"&access_token="+localStorage.getItem('access_token');
                        }).catch(function(error) {
                            Notification.error(error.data.message);
                        });
                    }
                }],
                controllerAs: 'vm',
                size: 'lg',
                keyboard: false,
                backdrop: 'static',
            });
            
                
        }

        function showTeamSubscriptions(teamId) {
            $uibModal.open({
                templateUrl: 'view/teams.modals.subscriptions',
                controller: ['$uibModalInstance', function($uibModalInstance) {
                    var vm = this;
                    vm.data = null;
                    teamService.subscriptions(teamId).then(function(response) {
                        vm.data = response.data;
                    }).catch(function(teamError) {
                        Notification.error("An unexpected error has occurred");
                        console.log(teamError);
                    });

                    vm.close = function() {
                        $uibModalInstance.dismiss();
                    }

                    vm.impersonateWURFL = function(id) {
                        // NOTE Window will open, data will appear when async is done
                        var tabWindowId = $window.open('about:blank', '_blank');
                        externalService.postSessionToken(loginService.authInfo.user.id, id).then(function(response) {
                            var url = response.data.url;
                            var session = response.data.session;
                            tabWindowId.location.href = url + "/impersonate/app/admin?session="+session+"&access_token="+localStorage.getItem('access_token');
                        }).catch(function(error) {
                            Notification.error(error.data.message);
                        });
                    }
                    
                    vm.impersonateIE = function(id) {
                        // NOTE Window will open, data will appear when async is done
                        var tabWindowId = $window.open('about:blank', '_blank');
                        tabWindowId.location.href = "/impersonate/imageengine/"+id;
                    }
                    
                    vm.viewSubscription = function(id, type) {
                        // NOTE Window will open, data will appear when async is done
                        var tabWindowId = $window.open('about:blank', '_blank');
                        if (type == "imgeng") {
                            type = "wit";
                        }
                        tabWindowId.location.href = "/" + type + "/app/" + id + "/view";
                    }
                }],
                controllerAs: 'vm',
                size: 'lg',
                keyboard: false,
                backdrop: 'static',
            });
        }
    }
})();
