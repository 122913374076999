(function() {
    'use strict';

    angular.module('adminApp.wit')
        .controller('WitOriginController', WitOriginController);

    WitOriginController.$inject = ['$stateParams', '$uibModalInstance', '$window', 'isNew', 'Notification', 'originId', 'witAccountService'];

    function WitOriginController($stateParams, $uibModalInstance, $window, isNew, Notification, originId, witAccountService) {
        var vm = this;

        vm.advanced = false;
        vm.close = close;
        vm.errors = null;
        vm.form = {
            name: null,
            origin: null,
            originProtocol: 'http',
            hostHeader: null,
            username: null,
            password: null,
            port: null,
            path: null,
            bucketname: null
        };
        vm.generalError = false;
        vm.loading = false;
        vm.running = false;
        vm.submit = submit;
        vm.title = isNew ? "Add New Origin" : "Edit Origin";

        clearErrors();

        if (!_.isNull(originId)) {
            vm.loading = true;
            witAccountService.getOrigin($stateParams.id, originId).then(function(response) {
                vm.form.name = response.data.name;
                vm.form.originProtocol = response.data.url_type;
                if (response.data.url_type != 's3') {
                    vm.form.origin = response.data[response.data.url_type].hostname;
                    vm.form.hostHeader = response.data.hostname;
                    vm.form.username = response.data[response.data.url_type].username;
                    vm.form.password = response.data[response.data.url_type].password;
                    vm.form.port = response.data[response.data.url_type].port;
                } else {
                    vm.form.bucketname = response.data[response.data.url_type].bucketname;
                }
                vm.form.path = response.data[response.data.url_type].path;
                vm.loading = false;
            }).catch(function(error) {
                console.log(error);
                Notification.error("Unable to load origin");
            }).finally(function() {
                vm.loading = false;
            });
        }

        function clearErrors() {
            vm.errors = {
                name: false,
                origin: false,
                originProtocol: false,
                hostHeader: false,
                username: false,
                password: false,
                port: false,
                path: false,
                bucketname: false
            };
            vm.generalError = false;
        }

        function close() {
            $uibModalInstance.dismiss();
        }

        function submit() {
            clearErrors();
            var errors = false;
            if (_.isNull(vm.form.name)) {
                vm.errors.name = true;
                errors = true;
            }
            if (vm.form.originProtocol != 's3') {
                if (_.isNull(vm.form.origin)) {
                    vm.errors.origin = true;
                    errors = true;
                }
            } else {
                if (_.isNull(vm.form.bucketname)) {
                    vm.errors.bucketname = true;
                    errors = true;
                }
            }
            if (errors) {
                return;
            }

            vm.running = true;
            if (isNew) {
                witAccountService.postAddNewOrigin($stateParams.id, vm.form).then(function(response) {
                    Notification.success("Origin created!");
                    $uibModalInstance.close(response.data);
                }).catch(function(error) {
                    console.log(error.data);
                    if (error.data.data.type == 'form') {
                        angular.forEach(error.data.data.errors, function(val, key) {
                            vm.errors[key] = val;
                        });
                        console.log(vm.errors);
                    } else {
                        vm.advanced = false;
                        vm.generalError = error.data.message;
                    }
                }).finally(function() {
                    vm.running = false;
                });
            } else {
                witAccountService.patchEditOrigin($stateParams.id, originId, vm.form).then(function(response) {
                    Notification.success("Origin updated!");
                    $uibModalInstance.close(response.data);
                }).catch(function(error) {
                    console.log(error.data);
                    if (error.data.data.type == 'form') {
                        angular.forEach(error.data.data.errors, function(val, key) {
                            vm.errors[key] = val;
                        });
                        console.log(vm.errors);
                    } else {
                        vm.advanced = false;
                        vm.generalError = error.data.message;
                    }
                }).finally(function() {
                    vm.running = false;
                });
            }
        }
    }
})();
