(function() {
    'use strict';

    angular.module('adminApp.quwurfl')
        .controller('QuWurflViewController', QuWurflViewController);

    QuWurflViewController.$inject = ['$filter', '$ngConfirm', '$rootScope', '$scope', '$state', '$stateParams',  '$uibModal', 'accountService', 'breadcrumbsService', 'Notification', 'subscriptionModalService', 'quwurflAccountService', 'saasConstants'];

    function QuWurflViewController($filter, $ngConfirm, $rootScope, $scope, $state, $stateParams,  $uibModal, accountService, breadcrumbsService, Notification, subscriptionModalService, quwurflAccountService, saasConstants) {
        var vm = this;

        // variables
        vm.stateParams = $stateParams;
        vm.steps = {};
        vm.subscription = undefined;
        vm.subscriptions = []; // Holds all the QuWURFL subscriptions for the user
        vm.domains = []; // Holds all the domains for the subscription
        vm.capabilities = []; // Holds all the capabilities for the subscription
        vm.apiKeys = []; // Holds all the api keys for the subscription
        vm.user = undefined;

        vm.defaults = saasConstants.getConstants('quwurfl', 'defaults');
        
        // functions
        vm.createNew = createNew;
        vm.cancel = cancel;
        vm.edit = edit;
        vm.reactivate = reactivate;
        vm.editCapabilities = editCapabilities;
        vm.updateBreadcrumbs = updateBreadcrumbs;
        vm.addDomain = addDomain;
        vm.deleteDomain = deleteDomain;
        vm.addKey = addKey;
        vm.deleteKey = deleteKey;
        vm.capabilitiesList = capabilitiesList;
       
        if ($state.current.name === 'quwurfl.view') {
            if (vm.subscription === undefined) {
                run();
            } else {
                vm.steps = breadcrumbsService.get('quwurfl.view', {
                    username: vm.user.username,
                    userid: vm.subscription.user_id,
                    name: vm.subscription.account_name
                });
                vm.loading = false;
            }
        } else {
            vm.loading = false;
        }

        switch ($state.current.name) {
            case 'quwurfl.view':
                vm.currentTab = 0;
                break;
            case 'quwurfl.view.configuration':
                vm.currentTab = 1;
                break;
            default:

        }
        
        function capabilitiesList() {
            return vm.capabilities.map(cap => cap.name).join(', ')
        }

        function addDomain() {
            quwurflAccountService.addDomain(vm.subscription.id, vm.domain)
                .then(function(response) {
                    Notification.success("Domain added");
                    vm.domains.push(response[0]);
                }, function(response) {
                    console.log(response);
                    Notification.error(response.data.error.message);
                });
        }

        function deleteDomain(domainId) {
            var target = _.findWhere(vm.domains, {id:domainId});
            var message = "The domain will be deleted immediately! Confirm to delete "+target.domain+"?";
           
            $uibModal.open({
                templateUrl: 'view/components.confirm',
                controller: function($scope, $uibModalInstance, message) {
                    $scope.message = message;
                    $scope.dismiss = function() {
                        $uibModalInstance.dismiss('cancel');
                    };
                    $scope.confirm = function() {
                        $uibModalInstance.close();
                    };
                },
                resolve: {
                    message: function() { return message; }
                },
                size: 'lg',
                backdrop: 'static',
                keyboard: false
            })
            .result
            .then(function(result) {
                quwurflAccountService.deleteDomain(vm.subscription.id, domainId)
                    .then(function(response) {
                        Notification.success("Domain deleted");
                        vm.domains = _.without(vm.domains, _.findWhere(vm.domains, {id:domainId}));
                    }, function(response) {
                        Notification.error(response.data.error.message);
                        console.log(response);
                    });

            }, function(result) {
                return false;
            });
        }

        function addKey() {
            console.log(vm.apiKeyName)
            quwurflAccountService.addKey(vm.subscription.id, vm.apiKeyName)
                .then(function(response) {
                    Notification.success("API key added");
                    vm.apiKeys.push(response);
                }, function(response) {
                    console.log(response);
                    Notification.error(response.data.error.message);
                });
        }

        function deleteKey(keyId) {
            var target = _.findWhere(vm.apiKeys, {id:keyId});
            var message = "The API key will be deleted immediately! Confirm to delete?";
           
            $uibModal.open({
                templateUrl: 'view/components.confirm',
                controller: function($scope, $uibModalInstance, message) {
                    $scope.message = message;
                    $scope.dismiss = function() {
                        $uibModalInstance.dismiss('cancel');
                    };
                    $scope.confirm = function() {
                        $uibModalInstance.close();
                    };
                },
                resolve: {
                    message: function() { return message; }
                },
                size: 'lg',
                backdrop: 'static',
                keyboard: false
            })
            .result
            .then(function(result) {
                quwurflAccountService.deleteKey(vm.subscription.id, keyId)
                    .then(function(response) {
                        Notification.success("Domain deleted");
                        vm.apiKeys = _.without(vm.apiKeys, _.findWhere(vm.apiKeys, {id:keyId}));
                    }, function(response) {
                        Notification.error(response.data.error.message);
                        console.log(response);
                    });

            }, function(result) {
                return false;
            });
        }

        function createNew() {
            subscriptionModalService.createNew('quwurfl', vm.subscription.user_id);
        }

        function cancel() {
            subscriptionModalService.cancel('quwurfl');
        }

        function edit() {
            subscriptionModalService.edit('quwurfl');
        }


        function reactivate() {
            alert("Not implemented, see WQ-101");
        }

        function editCapabilities() {
            subscriptionModalService.editQuWURFLCapabilities(vm.subscription.id, vm.capabilities);
        }

        function run() {
            quwurflAccountService.get($stateParams.id)
                .then(function(response) {
                    vm.subscription = response.subscription;
                    vm.domains = response.domains;
                    vm.capabilities = response.capabilities;
                    vm.apiKeys = response.api_keys;
                    vm.user = response.user;
                    vm.steps = breadcrumbsService.get('quwurfl.view', {
                        username: vm.user.username,
                        userid: vm.subscription.user_id,
                        name: vm.subscription.account_name
                    });
                    accountService.setValues('quwurfl', 'subscription', response.subscription);
                    if ($stateParams.edit) {
                        edit();
                    } else if ($stateParams.editDefaults) {
                        editDefaults();
                    }

                    quwurflAccountService.all()
                    .then(function(response) {
                        response.forEach(data => {
                            if (data.subscription.user_id != vm.subscription.user_id) {
                                return;
                            }
                            vm.subscriptions.push(data.subscription);
                        });
                    }).catch(function(error) {
                        console.log(error);
                        Notification.error("The user QuWURFL accounts could not be loaded");
                    })    

                }).catch(function(error) {
                    console.log(error);
                    Notification.error("The account could not be loaded");
                }).finally(function() {
                    vm.loading = false;
                });
        }

        function updateBreadcrumbs() {
            var state = 'quwurfl.view';
            vm.steps = breadcrumbsService.get(state, {
                username: vm.user.username,
                userid: vm.subscription.user_id,
                name: vm.subscription.account_name,
                id: vm.subscription.id
            });
        }

        var updateQuWURFLSubscription = $rootScope.$on('updateQuWURFLSubscription', function(event, args) {
            vm.subscription = args.data;
            updateBreadcrumbs();
            accountService.setValues('quwurfl', 'subscription', vm.subscription);
            
            $scope.$on('$destroy', updateQuWURFLSubscription);
        });

        var completeReload = $rootScope.$on('completeReload', function() {
            vm.steps = {};
            vm.subscription = undefined;
            vm.subscriptions = []; // Holds all the QuWURFL subscriptions for the user
            vm.domains = []; // Holds all the domains for the subscription
            vm.apiKeys = []; // Holds all the api keys for the subscription
            vm.user = undefined;
            run();
        })
    }
})();
