(function() {
    'use strict';

    angular.module('adminApp.apikey')
        .controller('ApikeyEditController', ApikeyEditController);

    ApikeyEditController.$inject = ['$rootScope', '$state', '$stateParams', '$uibModalInstance', 'apikeyService', 'breadcrumbsService', 'Notification', 'userService'];

    function ApikeyEditController($rootScope, $state, $stateParams, $uibModalInstance, apikeyService, breadcrumbsService, Notification, userService) {
        var vm = this;

        vm.apikey = {};
        vm.close = close;
        vm.loading = true;
        vm.moveKey = moveKey;
        vm.newSubscriptionId = undefined;
        vm.placeholder = "loading subscriptions, please wait...";
        vm.saving = false;
        vm.subscriptionIds = [];

        apikeyService.get($stateParams.id).then(function(response) {
            vm.apikey = response.data;
            userService.getWithSubscriptions(vm.apikey.user_id).then(function(response) {
                vm.subscriptionIds = _.reject(response.data[0].subscriptions, function(num) {
                    return num == vm.apikey.subscription_id;
                });
                vm.placeholder = "Search and select subscription";
            }).catch(function(error) {
                console.log(error);
                vm.placeholder = "Unable to find subscriptions";
            }).finally(function() {
                vm.loading = false;
            });
        }).catch(function(error) {
            console.log(error);
        });

        function close() {
            $uibModalInstance.dismiss();
        }

        function moveKey() {
            if (vm.newSubscriptionId === undefined || vm.newSubscriptionId == null) {
                Notification.error('Please enter a subscription ID');
            } else if (vm.newSubscriptionId == vm.apikey.subscription_id) {
                Notification.warning('No change detected');
            } else {
                vm.saving = true;
                apikeyService.update(
                    $stateParams.id, vm.newSubscriptionId, vm.apikey.subscription_id
                ).then(function(response) {
                    $rootScope.$broadcast('reloadApiKey', {subscriptionId: vm.newSubscriptionId});
                    $uibModalInstance.dismiss();
                }).catch(function(error) {
                    console.log(error);
                    Notification.error('Could not update Api Key');
                }).finally(function() {
                    vm.saving = false;
                });
            }
        }
    }
})();
