(function() {
    'use strict';

    angular.module('adminApp')
        .directive('loadButton', loadButton);

    loadButton.$inject = ['$rootScope', '$stateParams', '$window', 'adminlogService', 'apikeyService', 'cutModalService', 'externalService', 'licensesModalService', 'loginService', 'Notification', 'subscriptionModalService', 'userModalService', 'userService', 'witModalService', 'teamModalService'];

    function loadButton($rootScope, $stateParams, $window, adminlogService, apikeyService, cutModalService, externalService, licensesModalService, loginService, Notification, subscriptionModalService, userModalService, userService, witModalService, teamModalService) {
        var directive = {
            restrict: 'E',
            replace: true,
            template: '<ng-include src="getTemplateUrl()" />',
            scope: {
                view: '@',
                data: '=',
                tableType: '@',
                fullState: '@'
            },
            controller: controller
        };

        return directive;

        function controller($ngConfirm, $rootScope, $scope, $stateParams, $state, subscriptionModalService, userService, witModalService) {
            $scope.cancel = cancel;
            $scope.confirms = confirms;
            $scope.delete = deleteAction;
            $scope.enable = enable;
            $scope.edit = edit;
            $scope.getTemplateUrl = getTemplateUrl;
            $scope.impersonate = impersonate;
            $scope.moveSupportTier = moveSupportTier;
            $scope.restart = restart;
            $scope.revoke = revoke;
            $scope.setSupport = setSupport;
            $scope.transfer = transfer;
            $scope.viewLogData = viewLogData;
            $scope.showTeamSubscriptions = showTeamSubscriptions;
            $scope.showTeamUsers = showTeamUsers;

            function cancel(data) {
                if ($scope.tableType === 'wit') {
                    var external = null;
                    if (data.payment_plan !== 'IMAGEENGINE_PRO') {
                        external = false;
                    } else if (data.payment_type === 'EXTERNAL') {
                        external = true;
                    } else if (data.payment_type === 'STANDARD') {
                        external = false;
                    } else {
                        var auto = true;
                        if (!_.isNull(data.extra_type)) {
                            angular.forEach(data.extra_type.split(','), function(val, key) {
                                if (auto) {
                                    if (val === 'auto_trial') {
                                        auto = false;
                                    }
                                }
                            });
                        }
                        external = auto;
                    }
                    if (!_.isNull(data.domains)) {
                        data.wit_domain_conf = data.domains.split(",");
                    } else {
                        data.wit_domain_conf = [];
                    }

                    witModalService.cancel(data, external)
                        .result.then(function(response) {
                            if (response.success) {
                                Notification.success("The account has been canceled");
                                // $state.reload();
                                $rootScope.$broadcast('witReloadList');
                            } else {
                                if (response.error.account) {
                                    Notification.error("The account could not be canceled");
                                } else {
                                    Notification.warning("The account was canceled, however errors occurred");
                                }

                                if (response.error.certificate) {
                                    Notification.error("Certificate(s) could not be marked for deletion");
                                }

                                if (response.error.dns) {
                                    Notification.error("DNS resource record(s) could not be deleted");
                                }
                            }
                        });
                } else {
                    subscriptionModalService.cancel($scope.tableType, data);
                }
            }

            function confirms(action, id) {
                switch ($scope.fullState) {
                    case 'centraltest.admin.assertions':
                        switch (action) {
                            case 'view':
                                cutModalService.view('assertion', id);
                                break;
                            case 'edit':
                                cutModalService.edit('assertion', id);
                                break;
                            case 'remove':
                                cutModalService.remove('assertion', id);
                                break;
                        }
                        break;
                    case 'centraltest.admin.headers':
                        switch (action) {
                            case 'view':
                                cutModalService.view('header', id);
                                break;
                            case 'edit':
                                cutModalService.edit('header', id);
                                break;
                            case 'remove':
                                cutModalService.remove('header', id);
                                break;
                        }
                        break;
                    case 'centraltest.admin.requests':
                        switch (action) {
                            case 'view':
                                cutModalService.view('request', id);
                                break;
                            case 'edit':
                                cutModalService.edit('request', id);
                                break;
                            case 'remove':
                                cutModalService.remove('request', id);
                                break;
                            default:

                        }
                        break;
                    case 'centraltest.admin.tests':
                        cutModalService.remove('test', id);
                        break;
                    case 'apikey.manage':
                        $ngConfirm({
                            title: 'Confirm',
                            theme: 'dark',
                            content: 'Are you sure you want to delete this Api Key?',
                            buttons: {
                                close: {
                                    text: 'Cancel',
                                    btnClass: 'btn-blue'
                                },
                                proceed: {
                                    text: '<strong>OK</strong>',
                                    btnClass: 'btn-red',
                                    action: function(button){
                                        apikeyService.remove(id).then(function(response) {
                                            Notification.success('Api Key removed');
                                            $rootScope.$broadcast('reloadApiKeys', $stateParams)
                                        }).catch(function(error) {
                                            console.log(error);
                                            Notification.error('Could not remove Api Key');
                                            return false;
                                        });
                                    }
                                }
                            }
                        });
                        break;
                }
            }

            function deleteAction(id, action) {
                switch (action) {
                    case 'users':
                        userModalService.deleteUser({id: id});
                        break;
                    case 'licenses':
                        licensesModalService.remove(id);
                        break;
                    case 'teams':
                        teamModalService.delete(id);
                }
            }

            function enable(id, type) {
                switch (type) {
                    case 'users':
                        $ngConfirm({
                            title: 'Are you sure?',
                            theme: 'dark',
                            content: 'This will return the user to enabled, allowing them access to their account and providing the ability to create new accounts.<br /><br />Are you sure you want to continue?',
                            buttons: {
                                close: {
                                    text: 'Cancel',
                                    btnClass: 'btn-default'
                                },
                                proceed: {
                                    text: 'Continue',
                                    btnClass: 'btn-primary',
                                    action: function() {
                                        var form = {
                                            id: id,
                                            enabled: 1
                                        };
                                        userService.update(form).then(function(response) {
                                            $rootScope.$broadcast('reloadUsers', $stateParams)
                                        }).catch(function(error) {
                                            console.log(error);
                                            Notification.error("Could not enable user");
                                        });
                                    }
                                }
                            }
                        });
                        break;
                    case 'licenses':
                        licensesModalService.enable(id);
                        break;
                }
            }

            function edit(id, type) {
                switch (type) {
                    case 'teams':
                        teamModalService.edit(id);
                        break;
                }
            }

            function getTemplateUrl() {
                return 'view/'+$scope.view;
            }

            function impersonate(id) {
                // NOTE Window will open, data will appear when async is done
                // var tabWindowId = $window.open(location.host + "/redirecting", '_blank');
                var tabWindowId = $window.open('about:blank', '_blank');
                externalService.postSessionToken(loginService.authInfo.user.id, id).then(function(response) {
                    var url = response.data.url;
                    var session = response.data.session;
                    tabWindowId.location.href = url + "/impersonate/app/admin?session="+session+"&access_token="+localStorage.getItem('access_token');
                }).catch(function(error) {
                    tabWindowId.close();
                    Notification.error(error.data.message);
                });
            }

            function moveSupportTier(id, username, add) {
                $rootScope.$broadcast('moveSupportTier', {id: id, username: username, add: add});
            }

            function restart(data) {
                if (data.type == 'imgeng') {
                    witModalService.restart(data);
                } else {
                    subscriptionModalService.restart(data.type, data);
                }
            }

            function revoke(id, type) {
                switch (type) {
                    case 'users':
                        userModalService.revoke({id: id});
                        break;
                    case 'licenses':
                        licensesModalService.revoke(id);
                        break;
                }
            }

            function setSupport(id, zendeskTierId) {
                userService.getZendeskTiers().then(function(response) {
                    userModalService.editZendeskTier({
                        id: id,
                        zendeskTierId: zendeskTierId,
                        zendeskTiers: response.data
                    });
                }).catch(function(error) {
                    console.log(error);
                    Notification.error("An error occurred while gathering support tiers");
                });
            }

            function transfer(id) {
                licensesModalService.transfer({id: id});
            }

            function viewLogData(previous, next) {
                adminlogService.viewLogData(previous, next);
            }

            function showTeamUsers(teamId) {
                teamModalService.showTeamUsers(teamId);
            }

            function showTeamSubscriptions(teamId) {
                teamModalService.showTeamSubscriptions(teamId);
            }
        }
    }
})();
