(function() {
    'use strict';

    angular.module('adminApp.users')
        .controller('UsersPasswordController', UsersPasswordController);

    UsersPasswordController.$inject = ['$uibModalInstance', 'Notification', 'user', 'userService'];

    function UsersPasswordController($uibModalInstance, Notification, user, userService) {
        var vm = this;

        vm.close = close;
        vm.error = {
            password: false,
            password2: false
        };
        vm.modalOptions = {
            closeButtonText: 'Cancel',
            okButtonText: 'Save',
            template: 'view/users.modals.password',
            title: 'Change User Password'
        };
        vm.password = null;
        vm.password2 = null;
        vm.spinner = false;
        vm.submit = submit;

        function close() {
            $uibModalInstance.dismiss();
        }
        function submit() {
            vm.error = {
                password: false,
                password2: false
            };
            var error = false;
            if (vm.password === null) {
                vm.error.password = 'Password is required';
                error = true;
            }
            if (vm.password2 === null) {
                vm.error.password2 = 'Password repeat is required';
                error = true;
            }
            if (vm.password !== vm.password2) {
                vm.error.password2 = 'Password values do not match';
                error = true;
            }

            if (vm.password.length < 6) {
                vm.error.password = 'Password should be at least 6 characters';
                error = true;
            }
            if (error) {
                return;
            }

            vm.spinner = true;
            userService.updatePassword(user, vm.password)
                .then(function() {
                    Notification.success("Password updated successfully");
                    $uibModalInstance.dismiss();
                }, function(error) {
                    Notification.error("error.data.message");
                }).finally(function() {
                    vm.spinner = false;
                });
        }
    }
})();
