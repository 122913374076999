(function() {
    'use strict';

    angular.module('adminApp.wit')
        .factory('witModalService', witModalService);

    witModalService.$inject = ['$filter', '$ngConfirm', '$ocLazyLoad', '$rootScope', '$state', '$stateParams', '$uibModal', 'accountService', 'IE', 'Notification', 'paymentService', 'witAccountService'];

    function witModalService($filter, $ngConfirm, $ocLazyLoad, $rootScope, $state, $stateParams, $uibModal, accountService, IE, Notification, paymentService, witAccountService) {
        var service = {
            activate: activate,
            addPaymentInfo: addPaymentInfo,
            cancel: cancel,
            cancelDowngrade: cancelDowngrade,
            confirmDeleteSettings: confirmDeleteSettings,
            confirmSettings: confirmSettings,
            createNew: createNew,
            download: download,
            downloadExpiredTrialStats: downloadExpiredTrialStats,
            edit: edit,
            editDefaults: editDefaults,
            openEditSettings: openEditSettings,
            openDailyTrafficBreakdown: openDailyTrafficBreakdown,
            openDateRangeStats: openDateRangeStats,
            openDownloadTrialStats: openDownloadTrialStats,
            openExpirationDateUpdate: openExpirationDateUpdate,
            openTrialDaysCalendar: openTrialDaysCalendar,
            openUpdateDateRangeStats: openUpdateDateRangeStats,
            pathValidation: pathValidation,
            restart: restart,
            suspend: suspend,
            switchPaths: switchPaths,
            unsuspend: unsuspend,
            upgrade: upgrade,
            viewPurgeInfo: viewPurgeInfo,
            viewTrialTokens: viewTrialTokens
        };

        return service;

        function activate(subscription, externalPath) {
            if (!externalPath) {
                var message = "Once active, this account will start automated billing on the next day.";
            } else {
                var message = "Once active, the account will be available to start invoicing (must be done separately).";
            }
            if (!externalPath && subscription.vault_subscription_id === null) {
                message += "<br /><br />You will be required to enter payment information on the next screen.";
            }
            message += "<br /><br />Do you want to continue?";
            $ngConfirm({
                title: 'Confirm',
                theme: 'dark',
                content: message,
                buttons: {
                    close: {
                        text: 'Cancel',
                        btnClass: 'btn-danger'
                    },
                    proceed: {
                        text: 'Continue',
                        btnClass: 'btn-primary',
                        action: function(button) {
                            $rootScope.$broadcast('disableButtons');
                            subscription.status = 'ACTIVE';
                            if (!externalPath) {
                                subscription.payment_type = 'STANDARD';
                            } else {
                                subscription.payment_type = 'EXTERNAL';
                            }

                            if (subscription.vault_subscription_id === null && !externalPath) {
                                addPaymentInfo('activate', subscription);
                            } else {
                                subscription.nonce = null;
                                subscription.pro_standard = !externalPath;
                                witAccountService.update(subscription, 'activate').then(function(response) {
                                    accountService.setValues('wit', 'subscription', undefined);
                                    accountService.setValues('wit', 'default', undefined);
                                    $rootScope.$broadcast('reloadWit', {data: response.data});
                                    Notification.success("Account activated successfully");
                                }).catch(function(error) {
                                    console.log(error);
                                });
                            }
                        }
                    }
                }
            });
        }

        function addPaymentInfo(action, subscription, isNew) {
            isNew = _.isUndefined(isNew) ? false : isNew;
            action = !_.isUndefined(action) ? action : null;
            subscription.date_expiration = $filter('date')(subscription.date_expiration, "yyyy-MM-dd");
            $uibModal.open({
                templateUrl: 'view/subscriptions.wit.components.payment',
                controller: 'WitPaymentController',
                controllerAs: 'vm',
                resolve: {
                    braintree: () => $ocLazyLoad.load(
                        'https://js.braintreegateway.com/web/dropin/1.9.3/js/dropin.min.js'
                    ),
                    token: () => generateToken(),
                    form: () => subscription,
                    defaults: () => null,
                    tierIds: () => null,
                    action: () => action,
                    isNew: () => isNew
                },
                backdrop: 'static',
                keyboard: false
            });
        }

        function cancel(subscription, externalPath) {
            var message = '';
            if (!externalPath) {
                message += "This will cancel a subscription in the Payment Gateway.";
            } else {
                message += "This will cancel a subscription.";
                if (subscription.payment_type !== 'TRIAL') {
                    message += "<br /><br />External manual invoicing should no longer occur.";
                }
            }
            if (subscription.payment_type === 'STANDARD') {
                message += "<br /><br />Customer payment information will remain in the gateway until the end of the billing cycle so that any overage fees can be calculated and charged.";
            } else if (
                subscription.payment_type === 'TRIAL' &&
                (
                    subscription.payment_plan === IE.basic ||
                    subscription.payment_plan === IE.standard
                )
            ) {
                message += "<br /><br />The account will not be charged at the end of its original trial period.";
            }

            if (subscription.wit_domain_conf.length != 0) {
                message += "<br /><br />DNS resource records will be removed from the AWS console.";
                var certs = _.flatten(_.pluck(subscription.wit_domain_conf, 'wit_certificate'));
                if (certs.length != 0) {
                    message += "<br /><br />SSL certificates will be marked for deletion in the AWS console. This action will only be reversable for 48 hours. After that time, SSL will need to be reenabled if the account is reenabled.";
                }
            }

            message += "<br /><br />Do you want to continue?";

            return $uibModal.open({
                templateUrl: 'view/subscriptions.wit.components.wit_cancel',
                controller: 'WitCancelController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    message: function() {
                        return message;
                    },
                    subscription: function() {
                        return subscription;
                    }
                }
            });
        }

        function cancelDowngrade() {
            $ngConfirm({
                title: 'Confirm',
                theme: 'dark',
                content: 'This will cancel the planned downgrade and keep the account at its current plan.<br /><br />Do you want to continue?',
                buttons: {
                    close: {
                        text: 'Cancel',
                        btnClass: 'btn-danger'
                    },
                    proceed: {
                        text: 'Continue',
                        btnClass: 'btn-primary',
                        action: function(button) {
                            $rootScope.$broadcast('disableButtons');
                            witAccountService.cancelDowngrade($stateParams.id).then(function(response) {
                                if (response.status == 201) {
                                    Notification.success(response.message);
                                    $rootScope.$broadcast('upgradeWit');
                                } else {
                                    Notification.warning('No downgrade has been flagged during the current billing cycle');
                                    $rootScope.$broadcast('enableButtons');
                                }
                            }).catch(function(error) {
                                console.log(error);
                                Notification.error("Could not cancel downgrade");
                            });
                        }
                    }
                }
            });
        }

        function changePlan() {
            $uibModal.open({
                templateUrl: 'view/subscriptions.wit.components.plan_change',
                controller: 'WitPlanChangeController',
                controllerAs: 'vm',
                size: 'lg',
                keyboard: false,
                backdrop: 'static'
            });
        }

        function confirmDeleteSettings(settingId, pattern) {
            return $uibModal.open({
                templateUrl: 'view/subscriptions.wit.components.delete_setting',
                controller: 'WitDeleteSettingController',
                controllerAs: 'vm',
                size: 'md',
                keyboard: false,
                backdrop: 'static',
                resolve: {
                    pattern: function() {
                        return pattern;
                    },
                    settingId: function() {
                        return settingId;
                    }
                }
            });
        }

        function confirmSettings(message) {
            return $uibModal.open({
                templateUrl: 'view/subscriptions.wit.components.confirm_settings',
                controllerAs: 'vm',
                size: 'md',
                keyboard : false,
                backdrop: 'static',
                resolve: {
                    message: function() {
                        return message;
                    }
                },
                controller: function($uibModalInstance, message) {
                    var vm = this;

                    vm.close = close;
                    vm.message = message;
                    vm.proceed = proceed;

                    function close() {
                        $uibModalInstance.dismiss();
                    }

                    function proceed() {
                        $uibModalInstance.close();
                    }
                }
            })
        }

        function createNew(userId) {
            userId = _.isUndefined(userId) ? null : userId;
            $uibModal.open({
                templateUrl: 'view/subscriptions.wit.new',
                controller: 'WitNewAccountController',
                controllerAs: "vm",
                size: 'md',
                keyboard : false,
                backdrop: 'static',
                resolve: {
                    userId: function() {
                        return userId;
                    }
                }
            });
        }

        function download() {
            $uibModal.open({
                templateUrl: 'view/components.account_download_tracker',
                controller: 'AccountDownloadsImageEngineController',
                controllerAs: 'vm',
                size: 'md',
                backdrop: 'static',
                keyboard: false
            });
        }

        function downloadExpiredTrialStats() {
            $uibModal.open({
                templateUrl: 'view/components.account_download_tracker',
                controller: 'WitDownloadExpiredTrialStatsController',
                controllerAs: 'vm',
                size: 'md',
                backdrop: 'static',
                keyboard: false
            });
        }

        function edit() {
            $uibModal.open({
                templateUrl: 'view/subscriptions.wit.edit_account',
                controller: 'WitEditAccountFormController',
                controllerAs: 'vm',
                size: 'md',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    saasType: function() {
                        return 'wit';
                    }
                }
            });
        }

        function editDefaults() {
            $uibModal.open({
                templateUrl: 'view/subscriptions.wit.edit_tier',
                controller: 'WitEditTierController',
                controllerAs: 'vm',
                size: 'md',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    saasType: function() {
                        return 'wit';
                    }
                }
            });
        }

        function generateToken() {
            return paymentService.getBraintreeAuthorization().then(function(response) {
                return response.data.authorization;
            });
        }

        function openEditSettings(domain, isNew, setting) {
            return $uibModal.open({
                templateUrl: 'view/subscriptions.wit.components.settings',
                controller: 'WitSettingsController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    domain: function() {
                        return domain;
                    },
                    isNew: function() {
                        return isNew;
                    },
                    setting: function() {
                        return setting;
                    }
                },
                backdrop: 'static',
                keyboard: false
            });
        }

        function openDailyTrafficBreakdown(trial, account, start, end) {
            $uibModal.open({
                templateUrl: 'view/subscriptions.wit.components.daily_traffic',
                controller: 'WitDailyTrafficController',
                controllerAs: 'vm',
                size: 'lg',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    trial: function() {
                        return trial;
                    },
                    account: function() {
                        return account;
                    },
                    start: function() {
                        return start;
                    },
                    end: function() {
                        return end;
                    }
                }
            });
        }

        function openDateRangeStats(search, type) {
            return $uibModal.open({
                templateUrl: 'view/subscriptions.wit.components.stats_date_range',
                controller: 'WitStatsDateRangeController',
                controllerAs: 'vm',
                size: 'lg',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    search: function() {
                        return search;
                    },
                    type: function() {
                        return type;
                    }
                }
            });
        }

        function openDownloadTrialStats(uri) {
            $uibModal.open({
                templateUrl: 'view/subscriptions.wit.components.download_trial_stats',
                size: 'custom-lg',
                keyboard: false,
                backdrop: 'static',
                controller: 'WitDownloadTrialStatsController',
                controllerAs: 'vm',
                resolve: {
                    uri: function() {
                        return uri;
                    }
                }
            });
        }

        function openExpirationDateUpdate(trialDaysLeft, trialExpireDate) {
            return $uibModal.open({
                templateUrl: 'view/subscriptions.wit.components.expiration_update',
                controller: function($uibModalInstance, trialExpireDate, trialDaysLeft) {
                    var vm = this;

                    vm.cancel = cancel;
                    vm.changeExpirationDate = changeExpirationDate;
                    vm.extendTrial = extendTrial;
                    vm.trialDaysLeft = trialDaysLeft;
                    vm.trialExpireDate = trialExpireDate;

                    function cancel() {
                        $uibModalInstance.dismiss();
                    }

                    function changeExpirationDate() {
                        $uibModalInstance.close('expiration');
                    }

                    function extendTrial() {
                        $uibModalInstance.close('extend');
                    }
                },
                controllerAs: 'vm',
                size: 'md',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    trialDaysLeft: function() {
                        return trialDaysLeft;
                    },
                    trialExpireDate: function() {
                        return trialExpireDate;
                    }
                }
            });
        }

        function openTrialDaysCalendar(start, days) {
            return $uibModal.open({
                templateUrl: 'view/subscriptions.wit.components.trial_days',
                controller: function($uibModalInstance, days, start) {
                    var vm = this;

                    vm.cancel = cancel;
                    vm.close = close;
                    vm.date = moment(start).startOf('day').add(days - 1, 'd') > moment() ?
                        moment(start).startOf('day').add(days - 1, 'd') : moment();
                    vm.options = {
                        minDate: moment().toDate()
                    };

                    function cancel() {
                        $uibModalInstance.dismiss();
                    }

                    function close() {
                        $uibModalInstance.close(vm.date);
                    }
                },
                controllerAs: 'vm',
                size: 'md',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    start: function() {
                        return start;
                    },
                    days: function() {
                        return days;
                    }
                }
            })
        }

        function openUpdateDateRangeStats(start, end) {
            return $uibModal.open({
                templateUrl: 'view/subscriptions.wit.components.stats_range',
                controller: 'WitStatsDisplayRangeController',
                controllerAs: 'vm',
                size: 'lg',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    start: function() {
                        return start;
                    },
                    end: function() {
                        return end;
                    }
                }
            });
        }

        function pathValidation(pattern, correctedPattern) {
            return $uibModal.open({
                templateUrl: 'view/subscriptions.wit.components.path_validation',
                controller: 'WitPathValidationController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    correctedPattern: function() {
                        return correctedPattern;
                    },
                    pattern: function() {
                        return pattern;
                    }
                },
                backdrop: 'static',
                keyboard: false
            });
        }

        function restart(subscription) {
            var message = 'This will return the subscription to an active status.';

            message += "<br /><br />DNS records will be created for any existing domains."

            if (
                subscription.payment_plan == IE.basic ||
                subscription.payment_plan == IE.standard
            ) {
                if (!_.isNull(subscription.payment_method_token)) {
                    message += "<br /><br />A new billing subscription will be started in Braintree. Depending on the specific subscription, billing will either begin at the end of the original trial period, at the start of the next billing cycle, or immediately.";
                } else {
                    message += "<br /><br />No payment information exists. If the subscription is still within its original trial period, the account holder will be prompted to provide payment information. Otherwise this action will not be permitted.";
                }
            } else {
                message += "<br /><br />Any SSL certificates previously linked to the account that still exist will be re-enabled.";
            }
            message += "<br /><br />Do you want to continue?";

            return $uibModal.open({
                templateUrl: 'view/subscriptions.wit.components.wit_restart',
                controller: 'WitRestartController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    message: function() {
                        return message;
                    },
                    subscription: function() {
                        return subscription;
                    }
                }
            });
        }

        function suspend(subscription) {
            var message = "This action will indefinitely suspend the account.";
            if (!_.isNull(subscription.vault_subscription_id)) {
                message += "<br /><br />Automated monthly payments will be stopped while the account is suspended.";
            }
            if (subscription.payment_plan == 'IMAGEENGINE_PRO' || subscription.payment_plan == 'IMAGEENGINE_STANDARD') {
                message += "<br /><br />Any associated SSL certificates will be marked as suspended."
            }
            message += "<br /><br />Do you want to continue?";

            return $uibModal.open({
                templateUrl: 'view/subscriptions.wit.components.wit_suspend',
                controller: 'WitSuspendController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    message: function() {
                        return message;
                    },
                    subscription: function() {
                        return subscription;
                    }
                }
            });
        }

        function switchPaths(path, form) {
            $ngConfirm({
                title: 'Alert!',
                theme: 'dark',
                content: 'ImageEngine Pro accounts with automated billing are not currently supported. If this is required, please contact the development team.',
                buttons: {
                    close: {
                        text: 'OK',
                        btnClass: 'btn-info'
                    }
                }
            });
            return;
            // NOTE Beneath works, but the cron can't handle it right now
            if (path == 'external') {
                $uibModal.open({
                    templateUrl: 'view/subscriptions.wit.components.pro_switch',
                    controller: 'WitProSwitchController',
                    controllerAs: 'vm',
                    size: 'lg',
                    backdrop: 'static',
                    keyboard: false,
                    resolve: {
                        path: function() {
                            return path;
                        }
                    }
                });
            } else {
                var message = "This will switch the account from standard (automated) billing to external (invoiced) billing.";
                message += "<br /><br />All automated billing for this account will be removed from the Payment Gateway."
                if (form.payment_type !== 'TRIAL') {
                    message += "<br /><br />Any overage fees already occurred for this billing cycle will be charged.";
                }
                message += "<br /><br />Do you want to continue?";
                $ngConfirm({
                    title: 'Confirm',
                    theme: 'dark',
                    content: message,
                    buttons: {
                        close: {
                            text: 'Cancel',
                            btnClass: 'btn-danger'
                        },
                        proceed: {
                            text: 'Continue',
                            btnClass: 'btn-primary',
                            action: function(button) {
                                $rootScope.$broadcast('disableButtons');
                                form.pro_standard = false;
                                form.payment_type = 'EXTERNAL';
                                if (form.payment_type == 'STANDARD') {
                                    witAccountService.chargeOverageFees($stateParams.id).then(function(response) {
                                        witAccountService.update(form, 'swap').then(function(response) {
                                            Notification.success('Successfully switched to external billing');
                                            $rootScope.$broadcast('upgradeWit');
                                        }).catch(function(error) {
                                            console.log(error);
                                            Notification.error('Could not update account');
                                        });
                                    });
                                } else {
                                    witAccountService.update(form, 'swap').then(function(response) {
                                        Notification.success('Successfully switched to external billing');
                                        $rootScope.$broadcast('upgradeWit');
                                    }).catch(function(error) {
                                        console.log(error);
                                        Notification.error('Could not update account');
                                    });
                                }
                            }
                        }
                    }
                });
            }
        }

        function unsuspend(subscription) {
            var message = "This action will return the account to active.";
            if (subscription.vault_subscription_id !== null) {
                message += "<br /><br />Automated monthly payments will restart at the beginning of the account's next billing cycle.";
            }
            if (subscription.payment_plan == 'IMAGEENGINE_PRO' || subscription.payment_plan == 'IMAGEENGINE_STANDARD') {
                message += "<br /><br />Suspended tags will be removed from any associated SSL certificates."
            }
            message += "<br /><br />Do you want to continue?";

            return $uibModal.open({
                templateUrl: 'view/subscriptions.wit.components.wit_suspend',
                controller: 'WitUnsuspendController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    message: function() {
                        return message;
                    },
                    subscription: function() {
                        return subscription;
                    }
                }
            });
        }

        function upgrade(action, subscription) {
            var message = "This will switch the account to a new plan. You will be prompted for more information after selecting a plan.";
            if (subscription.payment_plan == 'IMAGEENGINE_PRO' || subscription.payment_plan == 'IMAGEENGINE_STANDARD') {
                message += "<br /><br />Any associated SSL certificates will be marked for deletion.";
            }
            message += "<br /><br />Do you want to continue?";

            $ngConfirm({
                title: 'Confirm',
                theme: 'dark',
                contentUrl: 'view/subscriptions.wit.components.wit_upgrade',
                buttons: {
                    close: {
                        text: 'Cancel',
                        btnClass: 'btn-danger'
                    },
                    proceed: {
                        text: 'Continue',
                        btnClass: 'btn btn-primary',
                        action: function(scope) {
                            $rootScope.$broadcast('disableButtons');
                            witAccountService.planChangeStorage('set', scope.plan, subscription, scope.action);
                            changePlan();
                        }
                    }
                },
                onScopeReady: function(scope) {
                    var self = this;
                    if (subscription.payment_plan === IE.basic) {
                        scope.message = message;
                        scope.plan = null;
                        scope.plans = [
                            {
                                key: IE.standard,
                                value: 'ImageEngine Standard'
                            },
                            {
                                key: IE.pro,
                                value: 'ImageEngine Pro'
                            }
                        ];
                        scope.action = true;
                    } else if (subscription.payment_plan === IE.standard) {
                        if (action) {
                            scope.plan = IE.pro;
                            scope.plans = [
                                {
                                    key: IE.pro,
                                    value: 'ImageEngine Pro'
                                }
                            ];
                        } else {
                            scope.plan = IE.basic;
                            scope.plans = [
                                {
                                    key: IE.basic,
                                    value: 'ImageEngine Basic'
                                }
                            ];
                        }
                        scope.message = message;
                        scope.action = action;
                    } else if (subscription.payment_plan === IE.pro) {
                        scope.plans = [
                            {
                                key: IE.basic,
                                value: 'ImageEngine Basic'
                            },
                            {
                                key: IE.standard,
                                value: 'ImageEngine Standard'
                            }
                        ];
                        scope.message = message;
                        scope.action = action;
                    }
                }
            });
        }

        function viewPurgeInfo(json) {
            $uibModal.open({
                templateUrl: 'view/subscriptions.wit.components.purge_info',
                controller: 'WitPurgeInfoController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    json: function() {
                        return json;
                    }
                }
            });
        }

        function viewTrialTokens(tokens) {
            $uibModal.open({
                templateUrl: 'view/subscriptions.wit.components.trial_tokens',
                controller: function($uibModalInstance, tokens) {
                    var vm = this;

                    vm.close = close;
                    vm.tokens = tokens;

                    function close() {
                        $uibModalInstance.dismiss();
                    }
                },
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    tokens: function() {
                        return tokens;
                    }
                }
            })
        }
    }
})();
