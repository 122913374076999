(function () {
    'use strict';

    angular.module('adminApp.wurfl')
        .controller('CapabilitiesController', CapabilitiesController);

    CapabilitiesController.$inject = ['$location', '$rootScope', '$scope', '$state', '$stateParams', 'breadcrumbsService', 'capabilitiesService', 'Notification', 'paginationService'];

    function CapabilitiesController($location, $rootScope, $scope, $state, $stateParams, breadcrumbsService, capabilitiesService, Notification, paginationService) {
        var vm = this;

        vm.columns = [
            {
                name: 'Name',
                search: {
                    isSearchable: true,
                    type: 'text'
                },
                model: 'capability',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: 'Description',
                search: {
                    isSearchable: true,
                    type: 'text'
                },
                model: 'description',
                value: {
                    fromData: true,
                    markdown: true
                },
                display: true
            }
        ];
        vm.search = {};
        vm.steps = breadcrumbsService.get('capabilities.list');

        var currentPage = $stateParams.page;

        if ($state.current.name === 'capabilities.list') {
            var search_params = _.clone($location.search());
            getCapabilities();
            var capabilitiesSearchParamsUpdated = $rootScope.$on("capabilitiesSearchParamsUpdated", function(event, args) {
                vm.search = args;
                currentPage = 1;
                getCapabilities(args);
            });

            var capabilitiesPageChanged = $rootScope.$on("capabilitiesPageChanged", function(event, args) {
                vm.search = args;
                currentPage = args.page;
                getCapabilities(args);
            });

            var capabilitiesItemsPerPageChanged = $rootScope.$on("capabilitiesItemsPerPageChanged", function(event, args) {
                vm.search = args;
                currentPage = 1;
                getCapabilities(args);
            });
        }

        function getCapabilities(searchParams) {
            searchParams = typeof searchParams !== 'undefined' ? searchParams : null;
            var start = 0;
            var number = 50;

            var query_params = {};
            var next_page = 1;

            if (searchParams !== null) {
                next_page = searchParams.page;
                number = searchParams.paginate;
            } else if (search_params && _.isUndefined(search_params.page) === false) { // if defined
                next_page = search_params.page;
            } else {
                next_page = Math.floor(start/number) + 1;
            }

            query_params = {page: next_page};
            if (searchParams !== null) {
                query_params = _.extend(searchParams);
            }

            capabilitiesService.getData(query_params).then(function(response) {
                var numPages = response.pagination.last_page;
                var paginationData = {};

                paginationData.pages = paginationService.createPages(currentPage, numPages);
                paginationData.numPages = numPages;
                paginationData.currentPage = currentPage;
                paginationData.currentState = $state.current.name;
                paginationData.stateParams = $stateParams;
                paginationData.from = paginationService.from(response.pagination);
                paginationData.to = paginationService.to(response.pagination);
                paginationData.total = response.pagination.total;

                $rootScope.$broadcast('capabilitiesDataLoaded', {
                    data: response.data,
                    paginationData: paginationData,
                    itemsPerPage: number
                });
                $scope.$on('$destroy', capabilitiesSearchParamsUpdated);
                $scope.$on('$destroy', capabilitiesPageChanged);
                $scope.$on('$destroy', capabilitiesItemsPerPageChanged);
            }, function(error) {
                console.log(error);
                Notification.error("Could not fetch capabilities");
                $scope.$on('$destroy', capabilitiesSearchParamsUpdated);
                $scope.$on('$destroy', capabilitiesPageChanged);
            });
        }
    }
})();
