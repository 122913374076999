(function() {
    'use strict';

    angular.module('adminApp.wjs')
        .controller('WjsAddPaymentController', WjsAddPaymentController);

    WjsAddPaymentController.$inject = ['$ngConfirm', '$rootScope', '$scope', '$stateParams', '$timeout', '$uibModalInstance', 'Notification', 'paymentService', 'wjsAccountService'];

    function WjsAddPaymentController($ngConfirm, $rootScope, $scope, $stateParams, $timeout, $uibModalInstance, Notification, paymentService, wjsAccountService) {
        var vm = this;

        var formFields = [
            'first_name',
            'last_name',
            'address',
            'city',
            'state',
            'zip',
            'country_id'
        ];
        var loadedInstance = null;

        vm.cancel = cancel;
        vm.class = {
            first_name: null,
            last_name: null,
            address: null,
            city: null,
            state: null,
            zip: null
        };
        vm.form = {
            first_name: null,
            last_name: null,
            address: null,
            city: null,
            state: null,
            zip: null
        };
        vm.running = false;
        vm.submit = submit;

        generateToken();

        $scope.$on('tokenGenerated', function(event, args) {
            braintree.dropin.create({
                authorization: args.token,
                container: '#wjs-payment-form',
                card: {
                    cardholderName: {
                        required: true
                    }
                }
            }, function(createErr, instance) {
                $timeout(function() {
                    vm.disablePay = false;
                }, 1);
                loadedInstance = instance;
            });
        });

        function cancel() {
            $uibModalInstance.dismiss();
        }

        function generateToken() {
            return paymentService.getBraintreeAuthorization().then(function(response) {
                $scope.$broadcast('tokenGenerated', {token: response.data.authorization});
            });
        }

        function submit() {
            $ngConfirm({
                title: 'Are you sure?',
                theme: 'dark',
                content: 'This will create recurring payments for the subscription.<br /><br />This account will be billed immediately.<br /><br />Are you sure you want to continue?',
                buttons: {
                    close: {
                        text: 'Cancel',
                        btnClass: 'btn-default'
                    },
                    proceed: {
                        text: 'Continue',
                        btnClass: 'btn-primary',
                        action: function() {
                            if (_.isUndefined(vm.form.country_id)) {
                                vm.form.country_id = 230;
                            } else {
                                vm.form.country_id = vm.form.country_id.id;
                            }

                            var errors = false;
                            angular.forEach(formFields, function(val) {
                                if (
                                    vm.form[val] === '' ||
                                    _.isNull(vm.form[val]) ||
                                    _.isUndefined(vm.form[val])
                                ) {
                                    vm.class[val] = 'error';
                                    errors = true;
                                }
                            });

                            if (!errors) {
                                vm.running = true;
                                loadedInstance.requestPaymentMethod(function(requestPaymentMethodErr, payload) {
                                    if (!_.isUndefined(payload)) {
                                        vm.form.nonce = payload.nonce;
                                        wjsAccountService.patchMakeStandard($stateParams.id, vm.form).then(function(response) {
                                            Notification.success("Successfully made standard billing!");
                                            $rootScope.$broadcast('completeReload');
                                            $uibModalInstance.close();
                                        }).catch(function(error) {
                                            console.log(error);
                                            loadedInstance.teardown(function(err) {
                                                generateToken();
                                            });
                                            Notification.error("Unable to switch to standard billing");
                                        }).finally(function() {
                                            vm.running = false;
                                        });
                                    }
                                });
                            }
                        }
                    }
                }
            });
        }
    }
})();
