(function() {
    'use strict';

    angular.module('adminApp.wit')
        .controller('WitEditCrbController', WitEditCrbController);

    WitEditCrbController.$inject = ['$ngConfirm', '$uibModalInstance', 'bundle', 'Notification', 'witAccountService'];

    function WitEditCrbController($ngConfirm, $uibModalInstance, bundle, Notification, witAccountService) {
        var vm = this;

        vm.close = close;
        vm.error = {
            name: false,
            regions: false
        };
        vm.form = {
            name: bundle.bundle_name,
            regions: bundle.regions.split(",")
        };
        vm.include = "view/subscriptions.wit.components.crb_form";
        vm.loading = true;
        vm.regionChecked = regionChecked;
        vm.regions = null;
        vm.running = false;
        vm.submit = submit;
        vm.title = "Edit Regional Bundle";
        vm.updateRegions = updateRegions;
        console.log(bundle.subscriptions);

        witAccountService.getExistingAwsRegions().then(function(response) {
            vm.regions = response.data;
            vm.loading = false;
        }).catch(function(error) {
            console.log(error);
        });

        function close() {
            $uibModalInstance.dismiss();
        }

        function regionChecked(region) {
            return _.indexOf(vm.form.regions, region) !== -1;
        }

        function runSubmit() {
            vm.form.regions = vm.form.regions.join(",");
            witAccountService.patchEditCrb(bundle.id, vm.form)
                .then(function(response) {
                    if (_.isEmpty(response.data)) {
                        Notification.success("Updated regional bundle");
                        $uibModalInstance.close();
                        return;
                    }
                    var promises = [];
                    var records = {
                        removing: [],
                        adding: []
                    };
                    angular.forEach(bundle.subscriptions, function(sub, key) {
                        angular.forEach(sub.domains, function(domain, dkey) {
                            // Remove records
                            angular.forEach(domain.certificates, function(certificate, ckey) {
                                if (!_.isUndefined(_.find(response.data.removed, function(val) {
                                    return val === certificate.region;
                                }))) {
                                    console.log("deleteCertificate: "+key+" "+certificate.id);
                                }
                            });
                            angular.forEach(domain.dns, function(dns, dkey) {
                                if (!_.isUndefined(_.find(response.data.removed, function(val) {
                                    return val === dns.region && dns.type === 'A';
                                }))) {
                                    records.removing.push({
                                        domain: dns.domain,
                                        type: dns.type,
                                        region: dns.region
                                    });
                                }
                            });

                            // Add records
                            if (!_.isEmpty(domain.certificates)) {
                                angular.forEach(response.data.added, function(add, key) {
                                    // TODO figure out validation type
                                    // TODO figure out test certificate
                                    console.log("postRequestCertificate: "+domain.cname+" "+add.RegionName);
                                });
                            }
                            if (!_.isEmpty(domain.dns)) {
                                angular.forEach(response.data.added, function(add, key) {
                                    if (add.Deploy !== 'provisioning') {
                                        records.adding.push({
                                            domain: domain.cname,
                                            type: 'A',
                                            region: add.RegionName
                                        });
                                    }
                                });
                            }
                        });
                    });
                    console.log(records);
                }).catch(function(error) {
                    Notification.error('Unable to edit regional bundle');
                    console.log(error.data);
                });
        }

        function submit() {
            if (
                vm.form.name == bundle.bundle_name &&
                _.isEmpty(_.difference(vm.form.regions, bundle.regions.split(",")))
            ) {
                return;
            }

            if (bundle.in_use) {
                $ngConfirm({
                    title: 'Are you sure?',
                    theme: 'dark',
                    contentUrl: 'view/subscriptions.wit.components.edit_crb_confirm',
                    buttons: {
                        close: {
                            text: 'Cancel',
                            btnClass: 'btn-default'
                        },
                        proceed: {
                            text: 'Submit',
                            btnClass: 'btn-primary',
                            action: function(scope) {
                                runSubmit();
                            }
                        }
                    },
                    onScopeReady: function(scope) {
                        scope.subscriptions = bundle.subscriptions;
                    }
                });
            } else {
                runSubmit();
            }
        }

        function updateRegions(region) {
            vm.error.regions = false;
            if ( _.indexOf(vm.form.regions, region) === -1) {
                vm.form.regions.push(region);
            } else {
                vm.form.regions = _.reject(vm.form.regions, function(val) {
                    return val === region;
                });
            }
        }
    }
})();
