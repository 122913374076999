(function() {
    'use strict';

    angular.module('adminApp')
        .factory('paginationService', paginationService);

    function paginationService() {
        var service = {
            clearTableSearch: clearTableSearch,
            createPages: createPages,
            from: from,
            getTableSearch: getTableSearch,
            persistTableSearch: persistTableSearch,
            to: to
        };

        var tableSearch = {};

        return service;

        function clearTableSearch() {
            tableSearch = {};
        }

        function createPages(currentPage, numPages) {
            var pages = [];
            var startPagination;
            var endPagination;
            if (currentPage <=6) {
                startPagination = 1;
                endPagination = parseInt(numPages) >= 10 ? 10 : numPages;
            } else if (parseInt(currentPage) + 4 > numPages) {
                endPagination = numPages;
                startPagination = parseInt(numPages) - 9 >=1 ?
                    parseInt(numPages) - 9 : 1;
            } else {
                startPagination = currentPage - 5;
                endPagination = parseInt(currentPage)+4 <= numPages ? parseInt(currentPage)+4 : numPages;
            }

            for (var i = startPagination; i <= endPagination; i++) {
                pages.push(i);
            }

            return pages;
        }

        function from(pagination) {
            return pagination.per_page*pagination.current_page-pagination.per_page+1;
        }

        function getTableSearch(key) {
            return tableSearch[key];
        }

        function persistTableSearch(key, value) {
            tableSearch[key] = value;
        }

        function to(pagination) {
            var page_items = pagination.current_page*pagination.per_page;

            if (pagination.total < page_items) {
                return pagination.total;
            }

            return page_items;
        }
    }
})();
