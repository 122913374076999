(function() {
    'use strict';

    angular.module('adminApp.wit')
        .controller('WitConfigurationController', WitConfigurationController);

    WitConfigurationController.$inject = ['$filter', '$ngConfirm', '$q', '$rootScope', '$state', '$stateParams', '$timeout', '$uibModal', 'accountService', 'baseAccountService', 'Notification', 'witAccountService'];

    function WitConfigurationController($filter, $ngConfirm, $q, $rootScope, $state, $stateParams, $timeout, $uibModal, accountService, baseAccountService, Notification, witAccountService) {
        var vm = this;

        vm.allowPrefix = false;
        vm.buttonClass = buttonClass;
        vm.confs = null;
        vm.deleteDomain = deleteDomain;
        vm.deleteOrigin = deleteOrigin;
        vm.enableResourceRecord = enableResourceRecord;
        vm.enableSsl = enableSsl;
        vm.hostnameValue = null;
        vm.loading = true;
        vm.openDomain = openDomain;
        vm.openOrigin = openOrigin;
        vm.openSettings = openSettings;
        vm.origins = null;
        vm.restoreHostname = restoreHostname;
        vm.running = false;
        vm.subscription = accountService.getValues('wit', 'subscription');
        vm.tokens = null;
        vm.viewDns = viewDns;
        vm.viewSsl = viewSsl;

        var certificatePromises = [];
        var certificateStatus = {};
        var certificateStatusPromises = [];
        var currentConf = null;
        var enableError = false;
        var testCertificateStatus = {};
        var validation = null;
        var witCertificate = null;

        if (accountService.getId('wit') === undefined || accountService.getId('wit') !== $stateParams.id) {
            accountService.clear();
            vm.subscription = undefined;
        }
        if (vm.subscription === undefined) {
            baseAccountService.get(witAccountService.resourceUrl, $stateParams.id)
                .then(function(response) {
                    accountService.setId('wit', $stateParams.id);
                    vm.subscription = response.data.account;
                    accountService.setValues('wit', 'subscription', vm.subscription);
                    accountService.setValues('wit', 'otherAccounts', response.data.other_accounts);
                    accountService.setValues('wit', 'plans', response.data.plans);
                    accountService.setValues('wit', 'defaults', witAccountService.remapLimits(vm.subscription, response.data.plans));
                    accountService.setValues('wit', 'billingInfoAvailable',
                        baseAccountService.isFree(vm.subscription) === false && baseAccountService.isStandardBilling(vm.subscription));
                    if (vm.subscription.status == 'CANCELED') {
                        var cancelReason = null;
                        baseAccountService.getCancelLog(witAccountService.resourceUrl, $stateParams.id)
                            .then(function(response) {
                                cancelReason = response.data.reason == '' ? "No reason provided" : response.data.reason;
                                accountService.setValues('wit', 'cancelReason', cancelReason);
                                $rootScope.$broadcast('updateWitSubscription', {cancelReason: cancelReason});
                            }).catch(function(error) {
                                console.log(error);
                            }).finally(function() {
                                vm.loading = false;
                            });
                    } else {
                        $rootScope.$broadcast('updateWitSubscription', {cancelReason: null});
                    }
                }, function(error) {
                    console.log(error);
                    Notification.error("The account could not be loaded");
                });
        }

        witAccountService.getDomainConfs($stateParams.id).then(function(response) {
            var certStatusError = false;
            vm.tokens = response.data.tokens;
            vm.confs = response.data.domains;
            vm.origins = response.data.origins;

            vm.allowPrefix = _.indexOf(_.pluck(vm.confs, 'allow_origin_prefix'), 1) !== -1;
            angular.forEach(vm.confs, function(val, key) {
                val.resource_record_enabled = !_.isUndefined(_.find(val.wit_resource_record, function(obj) {
                    return obj.type == 'A';
                }));

                if (val.origin_conf_id !== 0) {
                    var originMatch = _.find(vm.origins, function(obj) {
                        return obj.origin_conf_id == val.origin_conf_id;
                    });
                    if (!_.isUndefined(originMatch)) {
                        val.url = originMatch.url;
                    }
                }
            });

            var loadCertificatePromises = [];
            angular.forEach(vm.confs, function(domain, key) {
                if (domain.status.ssl && !domain.ie_only_flag && !domain.custom_wildcard_flag) {
                    domain.status.ssl = 'loading';
                    loadCertificatePromises.push(
                        witAccountService.getCertificateStatus($stateParams.id, domain.cname).then(function(response) {
                            domain.status.ssl = response.data.status;
                            domain.certificates = response.data.certificates;
                            domain.validation = response.data.domain_validation;
                            domain.test_certificates = response.data.test_certificates;
                            domain.test_enabled = response.data.test_enabled;
                            domain.status.test_ssl = response.data.test_status;
                            domain.status.attached = response.data.attached;
                        }).catch(function(error) {
                            Notification.error("Cannot determine certificate status for "+domain.cname);
                            console.log(error);
                            domain.status.ssl = 'error';
                            $q.reject();
                        })
                    );
                } else if (domain.status.ssl && domain.ie_only_flag && !domain.custom_wildcard_flag) {
                    domain.status.ssl = "loading";
                    loadCertificatePromises.push(
                        witAccountService.getIeOnlyCertificateStatus($stateParams.id, domain.cname)
                            .then(function(response) {
                                domain.status.ssl = response.data.status;
                                domain.certificates = response.data.regions;
                                domain.status.attached = response.data.attached;
                            }).catch(function(error) {
                                console.log(error);
                                Notification.error("Cannot determine certificate status for "+domain.cname);
                                domain.status.ssl = 'error';
                                $q.reject();
                            })
                    );
                } else if (domain.custom_wildcard_flag) {
                    domain.status.ssl = "loading";
                    loadCertificatePromises.push(
                        witAccountService.getCustomWildcardCertificateStatus($stateParams.id, domain.cname)
                            .then(function(response) {
                                domain.status.ssl = response.data.status;
                                domain.certificates = response.data.certificates;
                                domain.validation = response.data.domain_validation;
                                domain.status.attached = response.data.attached;
                                domain.baseName = response.data.base_name;
                            }).catch(function(error) {
                                console.log(error);
                                Notification.error("Cannot determine certificate status for "+domain.cname);
                                domain.status.ssl = 'error';
                                $q.reject();
                            })
                    );
                }
            });
            $q.all(loadCertificatePromises).then(function() {
                vm.loading = false;
            });
            accountService.setValues('wit', 'wit_domain_conf', vm.confs);
        }).catch(function(error) {
            console.log(error);
            Notification.error("Unable to get domain configuration");
            vm.loading = false;
        });

        function buttonClass(conf) {
            if (conf.status.ssl == 'ISSUED' && conf.status.attached) {
                return 'btn-success';
            }

            if (conf.status.ssl == 'PENDING_VALIDATION' || (conf.status.ssl == 'ISSUED' && !conf.status.attached)) {
                return 'btn-warning';
            }

            if (conf.status.ssl == null || conf.status.ssl == 'loading' || conf.status.ssl === true) {
                return 'btn-default';
            }

            return 'btn-danger';
        }

        function deleteDomain(domain) {
            var origin = _.findWhere(vm.origins, {origin_conf_id: domain.id});
            $uibModal.open({
                templateUrl: 'view/subscriptions.wit.components.delete_domain',
                controller: 'WitDeleteDomainController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    domain: function() {
                        return domain;
                    },
                    origin: function() {
                        return origin;
                    }
                },
                backdrop: 'static',
                keyboard: false
            }).result.then(function(response) {
                vm.confs = _.reject(vm.confs, function(obj) {
                    return obj.id == domain.id;
                });
                if (!_.isUndefined(response.origin)) {
                    _.map(vm.origins, function(obj) {
                        if (obj.id == response.origin.id) {
                            obj.origin_conf_id = response.origin.origin_conf_id;
                            obj.in_use = response.origin.in_use;
                        }

                        return obj;
                    });
                }
                vm.allowPrefix = _.indexOf(_.pluck(vm.confs, 'allow_origin_prefix'), 1) !== -1;
            });
        }

        function deleteOrigin(origin) {
            vm.running = true;
            witAccountService.deleteOrigin($stateParams.id, origin.id).then(function(response) {
                Notification.success("Successfully deleted origin");
                vm.origins = _.reject(vm.origins, function(obj) {
                    return obj.id == origin.id;
                });
            }).catch(function(error) {
                console.log(error);
                Notification.error(error.data.message);
            }).finally(function() {
                vm.running = false;
            })
        }

        function enableResourceRecord(conf) {
            vm.running = true;
            witAccountService.getAwsRegionsByAccount($stateParams.id).then(function(response) {
                var records = [];
                angular.forEach(response.data, function(val, key) {
                    if (val.Deploy !== 'provisioning') {
                        records.push({
                            domain: conf.cname,
                            region: val.RegionName,
                            type: 'A'
                        });
                    }
                });
                witAccountService.postResourceRecords($stateParams.id, records)
                    .then(function(response) {
                        conf.wit_resource_record = response.data;
                        conf.resource_record_enabled = true;
                    }).catch(function(error) {
                        console.log(error);
                        Notification.error("Unable to enable resource records");
                    }).finally(function() {
                        vm.running = false;
                    });
            });
        }

        function enableSsl(conf) {
            currentConf = conf;
            $ngConfirm({
                title: 'Select a Validation Method',
                theme: 'dark',
                contentUrl: "view/subscriptions.wit.components.validation_method",
                buttons: {
                    close: {
                        text: 'Cancel',
                        btnClass: 'btn-default'
                    },
                    proceed: {
                        text: 'Submit',
                        btnClass: 'btn-primary',
                        action: function(scope) {
                            vm.running = true;
                            var wildcardEnabled = false;
                            var records = [];
                            conf.status.ssl = "loading";
                            witAccountService.getAwsRegionsByAccount($stateParams.id).then(function(response) {
                                angular.forEach(response.data, function(val, key) {
                                    var certForm = {
                                        domain: conf.cname,
                                        region: val,
                                        validationType: scope.validationType
                                    };
                                    if (val.Deploy !== 'provisioning') {
                                        var record = {
                                            domain: conf.cname,
                                            region: val.RegionName,
                                            type: 'A'
                                        };
                                    }
                                    certificatePromises.push(
                                        witAccountService.postRequestCertificate(conf.subscription_id, certForm)
                                            .then(function(response) {
                                                validation = {
                                                    validation: response.data.validation,
                                                    type: scope.validationType
                                                };
                                                witCertificate = response.data.certificates;
                                                certificateStatus[val.RegionName] = {
                                                    status: response.data.validation.ValidationStatus,
                                                    in_use: response.data.validation.InUse ? 'yes' : 'no',
                                                    attached: response.data.validation.InUse
                                                };
                                                conf.status.ssl = response.data.validation.ValidationStatus;
                                                conf.status.attached = response.data.validation.InUse;
                                                if (val.Deploy !== 'provisioning' && conf.resource_record_enabled) {
                                                    certificateStatus[val.RegionName].r53_exists = true;
                                                }
                                                wildcardEnabled = response.data.validation.wildcard_test_enabled;
                                                if (!_.isUndefined(response.data.validation.hash) && val.Deploy !== 'provisioning') {
                                                    conf.custom_wildcard_flag = true;
                                                    record.hash = response.data.validation.hash;
                                                    records.push(record);
                                                }
                                            }).catch(function(error) {
                                                console.log(error);
                                                enableError = true;
                                                $q.reject();
                                            })
                                    );
                                });
                                $q.all(certificatePromises).then(function() {
                                    if (records.length !== 0) {
                                        // Update the load balancer attachment
                                        witAccountService.patchResourceRecords($stateParams.id, records)
                                            .then(function(response) {})
                                            .catch(function(error) {
                                                console.log(error);
                                                Notification.error("An issue occurred while updating the load balancer attachment");
                                            })
                                            .finally(function() {
                                                finishEnable(wildcardEnabled);
                                            });
                                    } else {
                                        finishEnable(wildcardEnabled);
                                    }
                                });
                            }).catch(function(error) {
                                Notification.error("Unable to generate the certificate at this time");
                                console.log(error);
                                vm.running = false;
                            });
                        }
                    }
                },
                onScopeReady: function(scope) {
                    scope.validationType = 'DNS';
                }
            });
        }

        function finishEnable(wildcardEnabled) {
            if (!enableError) {
                _.map(vm.confs, function(obj) {
                    if (obj.cname == currentConf.cname) {
                        obj.status.ssl = 'PENDING_VALIDATION';
                        obj.validation = validation;
                        obj.wit_certificate = witCertificate;
                    }
                    return obj;
                });
                currentConf.certificates = certificateStatus;
                viewSsl(currentConf, true, wildcardEnabled);
            } else {
                Notification.error("Unable to generate the certificate at this time");
            }
            certificatePromises = [];
            enableError = false;
            currentConf = null;
            vm.running = false;
        }

        function getCertificateStatus(certificates) {
            var issued = false;
            var pendingValidation = false;

            var status = null;

            angular.forEach(certificates, function(certificate, region) {
                switch (certificate.status) {
                    case 'PENDING_VALIDATION':
                        pendingValidation = true;
                        break;
                    case 'ISSUED':
                        issued = true;
                        break;
                    case null:
                        break;
                    default:
                        status = certificate.status;
                        break;
                }
            });

            if (!_.isNull(status)) {
                return status;
            }

            if (pendingValidation) {
                return 'PENDING_VALIDATION';
            } else if (issued) {
                return 'ISSUED';
            } else {
                return 'UNKNOWN';
            }
        }

        function getTestCertificateStatus(certificates) {
            var issued = false;
            var status = null;

            angular.forEach(certificates, function(certificate, region) {
                switch (certificate.status) {
                    case 'PENDING_VALIDATION':
                    case 'ISSUED':
                        issued = true;
                        break;
                    case null:
                        break;
                    default:
                        status = certificate.status;
                        break;
                }
            });

            if (!_.isNull(status)) {
                return status;
            }

            if (issued) {
                return 'ISSUED';
            }

            return 'UNKNOWN';
        }

        function openDomain(isNew, domainId) {
            domainId = _.isUndefined(domainId) ? null : domainId;
            $uibModal.open({
                templateUrl: 'view/subscriptions.wit.components.domain',
                controller: 'WitDomainController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    isNew: function() {
                        return isNew;
                    },
                    origins: function() {
                        return vm.origins;
                    },
                    domainId: function() {
                        return domainId;
                    },
                    subscription: function() {
                        return vm.subscription;
                    }
                },
                backdrop: 'static',
                keyboard: false
            }).result.then(function(response) {
                if (!_.isNull(response)) {
                    if (isNew) {
                        if (!_.isUndefined(response.origin)) {
                            vm.origins.push(response.origin);
                            response.conf.origin_name = response.origin.name;
                        }
                        response.conf.resource_record_enabled = true;
                        vm.confs.push(response.conf);
                        var serviceValue = accountService.getValues('wit', 'subscription');
                        serviceValue.wit_domain_conf = vm.confs;
                        accountService.setValues('wit', 'subscription', serviceValue);

                        if (!_.isUndefined(response.origin_update)) {
                            // Make it in use
                            _.map(vm.origins, function(obj) {
                                if (obj.id == response.origin_update.id) {
                                    obj.origin_conf_id = response.origin_update.origin_conf_id;
                                    obj.in_use = response.origin_update.in_use;
                                    if (!_.isUndefined(response.origin_update.url)) {
                                        obj.url = response.origin_update.url;
                                    }
                                }
                                return obj;
                            });
                        }
                        vm.allowPrefix = _.indexOf(_.pluck(vm.confs, 'allow_origin_prefix'), 1) !== -1;
                    } else {
                        vm.token = response.token;
                        vm.origins = response.origins;
                        _.map(vm.confs, function(obj) {
                            var match = _.find(response.domains, function(conf) {
                                return conf.id == obj.id;
                            });

                            obj.origin_name = match.origin_name;
                            obj.origin_conf_id = match.origin_conf_id;
                            obj.wit_allowed_prefix = match.wit_allowed_prefix;
                            obj.allow_origin_prefix = match.allow_origin_prefix;

                            if (match.url !== '') {
                                obj.url = match.url;
                            } else {
                                var originMatch = _.find(response.origins, function(origin) {
                                    return origin.origin_conf_id == obj.origin_conf_id;
                                });
                                if (!_.isUndefined(originMatch)) {
                                    obj.url = originMatch.url;
                                }
                            }
                        });
                        vm.allowPrefix = _.indexOf(_.pluck(vm.confs, 'allow_origin_prefix'), 1) !== -1;
                    }
                }
            }).catch(function(response) {
                if (!_.isUndefined(response.conf)) {
                    if (!_.isUndefined(response.type) && _.indexOf(response.error_type, 'resource record') != -1) {
                        response.conf.resource_record_enabled = false;
                    } else {
                        response.conf.resource_record_enabled = true;
                    }
                    vm.confs.push(response.conf);
                }
                if (!_.isUndefined(response.origin)) {
                    vm.origins.push(response.origin);
                }

                if (!_.isUndefined(response.origin_update)) {
                    // Make it in use
                    _.map(vm.origins, function(obj) {
                        if (obj.id == response.origin_update.id) {
                            obj.origin_conf_id = response.origin_update.origin_conf_id;
                            obj.in_use = response.origin_update.in_use;
                            if (!_.isUndefined(response.origin_update.url)) {
                                obj.url = response.origin_update.url;
                            }
                        }
                        return obj;
                    });
                }

                if (!_.isUndefined(response.old_origin)) {
                    _.map(vm.origins, function(obj) {
                        if (obj.id == response.old_origin.id) {
                            obj.origin_conf_id = response.old_origin.origin_conf_id;
                            obj.in_use = response.old_origin.in_use;
                        }
                        return obj;
                    });
                }

                if (!_.isUndefined(response.type)) {
                    var message = "Domain configuration has been saved, but errors occurred:";
                    if (response.type == 'aws') {
                        if (_.indexOf(response.error_type, 'certificate') != -1) {
                            message += "<br />Unable to generate SSL/TLS certificate";
                            _.map(vm.confs, function(obj) {
                                if (obj.id === response.conf.id) {
                                    obj.status.ssl = null;
                                }
                                return obj;
                            });
                        }
                        if (_.indexOf(response.error_type, 'resource record') != -1) {
                            message += "<br />Unable to configure DNS record";
                        }
                    }
                    Notification.warning(message);
                } else {
                    Notification.error("An unknown error occurred. Please contact support@scientiamobile.com for assistance.");
                }
            });
        }

        function openOrigin(isNew, originId) {
            originId = _.isUndefined(originId) ? null : originId;
            $uibModal.open({
                templateUrl: 'view/subscriptions.wit.components.origin',
                controller: 'WitOriginController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    isNew: function() {
                        return isNew;
                    },
                    originId: function() {
                        return originId;
                    }
                },
                backdrop: 'static',
                keyboard: false
            }).result.then(function(response) {
                if (isNew) {
                    vm.origins.push(response);
                } else {
                    _.map(vm.origins, function(obj) {
                        if (obj.id === originId) {
                            obj.name = response.name;
                            obj.url = response.url;
                            obj.url_type = response.url_type;
                            obj.hostname = response.hostname;
                            obj.in_use = response.in_use;
                        }
                        return obj;
                    });
                    _.map(vm.confs, function(obj) {
                        if (obj.id == response.origin_conf_id || obj.origin_conf_id == response.origin_conf_id) {
                            obj.origin_name = response.name;
                            obj.url = response.url;
                        }
                        return obj;
                    });
                }
            });
        }

        function openSettings(conf) {
            var result = $uibModal.open({
                templateUrl: 'view/subscriptions.wit.components.edit_settings',
                controller: 'WitEditSettingsController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    domain: function() {
                        return conf;
                    }
                },
                backdrop: 'static',
                keyboard: false
            });
            result.result.then(function(response) {
                conf.transition_time = response;
            });
        }

        function restoreHostname(id) {
            witAccountService.restoreHostname(vm.subscription.id, id)
                .then(function(response) {
                    Notification.success(response.message);

                    var item = _.findIndex(vm.subscription.wit_hostnames, {id:id});
                    vm.subscription.wit_hostnames[item] = response.data;

                }, function(response) {
                    console.log(error);
                    Notification.error(response.message);
                });
        }

        function viewDns(conf) {
            $uibModal.open({
                templateUrl: 'view/subscriptions.wit.components.dns_view',
                controller: 'WitViewDnsController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    conf: function() {
                        return conf;
                    }
                },
                backdrop: 'static',
                keyboard: false
            });
        }

        function viewSsl(conf, test, wildcardEnabled) {
            test = _.isUndefined(test) ? true : test;
            wildcardEnabled = _.isUndefined(wildcardEnabled) ? false : wildcardEnabled;
            $uibModal.open({
                templateUrl: 'view/subscriptions.wit.components.ssl_view',
                controller: 'WitViewSslController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    conf: function() {
                        return conf;
                    },
                    test: function() {
                        return test;
                    },
                    wildcardEnabled: function() {
                        return wildcardEnabled;
                    }
                },
                backdrop: 'static',
                keyboard: false
            });
        }
    }
})();
