(function() {
    'use strict';

    angular.module('adminApp.wit')
        .controller('WitViewSslController', WitViewSslController);

    WitViewSslController.$inject = ['$ngConfirm', '$q', '$stateParams', '$uibModalInstance', 'conf', 'Notification', 'test', 'wildcardEnabled', 'witAccountService'];

    function WitViewSslController($ngConfirm, $q, $stateParams, $uibModalInstance, conf, Notification, test, wildcardEnabled, witAccountService) {
        var vm = this;

        vm.alert = null;
        vm.attachForm = {
            region: null,
            domain: conf.cname
        };
        vm.attaching = false;
        vm.attachToRegion = attachToRegion;
        vm.attachToShard = attachToShard;
        vm.availableRegions = null;
        vm.close = close;
        vm.conf = conf;
        vm.enabled = wildcardEnabled ? wildcardEnabled : conf.test_enabled;
        vm.loading = true;
        vm.removeDisable = false;
        vm.removeFromRegion = removeFromRegion;
        vm.removing = {};
        vm.requestTestCertificate = requestTestCertificate;
        vm.requesting = false;
        vm.resubmitCertificate = resubmitCertificate;
        vm.sharding = false;
        vm.status = null;
        vm.test = test;

        angular.forEach(conf.certificates, function(val, key) {
            vm.removing[key] = false;
        });

        var attachError = false;
        var deleteError = false;
        var detachError = false;
        var enableError = false;
        var regionPromises = [];
        var test = false;
        var validation = null;
        var witCertificate = null;
        var certificateStatus = {};

        function close() {
            $uibModalInstance.dismiss();
        }

        witAccountService.getAwsRegions().then(function(response) {
            var currentRegions = _.keys(vm.conf.certificates);
            vm.availableRegions = _.filter(response.data, function(val) {
                return _.indexOf(currentRegions, val.RegionName) === -1;
            });
        }).finally(function() {
            vm.loading = false;
        });

        getStatus();
        alertSet();

        function alertSet() {
            vm.alert = {
                class: null,
                show: false,
                message: null
            };
        }

        function attachToRegion() {
            alertSet();
            vm.attaching = true;
            var validationType = !_.isUndefined(conf.validation) && !_.isNull(conf.validation) ?
                conf.validation.type : 'DNS';
            var certForm = {
                domain: vm.conf.cname,
                region: vm.attachForm.region,
                validationType: validationType,
                db_store: false
            };
            var region = angular.copy(vm.attachForm.region.RegionName);
            if (vm.conf.ie_only_flag) {
                witAccountService.postRequestTestCertificate($stateParams.id, certForm)
                    .then(function(response) {
                        vm.conf.certificates[vm.attachForm.region.RegionName] = {
                            status: response.data.status,
                            attached: false,
                            r53_exists: false
                        };
                        vm.availableRegions = _.reject(vm.availableRegions, function(val) {
                            return val.RegionName == vm.attachForm.region.RegionName;
                        });
                        if (!response.data.generated) {
                            vm.conf.certificates[vm.attachForm.region.RegionName].attached = true;
                            // Wildcard certificate is already valid
                            // Add the alias record
                            witAccountService.postResourceRecords($stateParams.id, [
                                {
                                    domain: vm.conf.cname,
                                    region: vm.attachForm.region.RegionName,
                                    type: 'A'
                                }
                            ]).then(function(response) {
                                vm.conf.certificates[region].r53_exists = true;
                            }).catch(function(error) {
                                console.log(error);
                                vm.alert = {
                                    class: 'alert-danger',
                                    message: "Unable to create alias record at this time",
                                    show: true
                                };
                            }).finally(function() {
                                vm.attaching = false;
                                vm.removeAllowed = _.keys(vm.conf.certificates).length > 1 ? true : false;
                                vm.attachForm.region = null;
                            });
                        } else {
                            vm.attaching = false;
                            vm.removeAllowed = _.keys(vm.conf.certificates).length > 1 ? true : false;
                            vm.attachForm.region = null;
                        }
                    }).catch(function(error) {
                        console.log(error.data);
                        vm.alert = {
                            class: 'alert-danger',
                            message: "Unable to generate the certificate at this time",
                            show: true
                        };
                        vm.attaching = false;
                        vm.removeAllowed = _.keys(vm.conf.certificates).length > 1 ? true : false;
                        vm.attachForm.region = null;
                    });
            } else if (vm.conf.custom_wildcard_flag) {
                certForm.db_store = true; // Store the certificate
                witAccountService.postRequestCertificate($stateParams.id, certForm)
                    .then(function(response) {
                        var record = {
                            region: vm.attachForm.region.RegionName,
                            type: 'A',
                            domain: vm.conf.cname,
                            hash: response.data.validation.hash
                        };
                        witAccountService.postResourceRecords($stateParams.id, [record])
                            .then(function() {
                                vm.conf.certificates[vm.attachForm.region.RegionName] = {
                                    status: response.data.validation.ValidationStatus,
                                    attached: response.data.validation.InUse,
                                    r53_exists: true
                                };
                            })
                            .catch(function(error) {
                                console.log(error);
                                Notification.error("Unable to generate resource records")
                                vm.conf.certificates[vm.attachForm.region.RegionName] = {
                                    status: response.data.validation.ValidationStatus,
                                    attached: response.data.validation.InUse,
                                    r53_exists: false
                                };
                            }).finally(function() {
                                vm.availableRegions = _.reject(vm.availableRegions, function(val) {
                                    return val.RegionName == vm.attachForm.region.RegionName;
                                });
                                vm.attaching = false;
                                vm.removeAllowed = _.keys(vm.conf.certificates).length > 1 ? true : false;
                                vm.attachForm.region = null;
                            });
                    }).catch(function(error) {
                        console.log(error.data);
                        vm.alert = {
                            class: 'alert-danger',
                            message: "Unable to generate the certificate at this time",
                            show: true
                        };
                    });
            } else {
                witAccountService.postRequestCertificate($stateParams.id, certForm)
                    .then(function(response) {

                        vm.conf.certificates[vm.attachForm.region.RegionName] = {
                            status: response.data.validation.ValidationStatus,
                            attached: false,
                            r53_exists: false
                        };
                        vm.availableRegions = _.reject(vm.availableRegions, function(val) {
                            return val.RegionName == vm.attachForm.region.RegionName;
                        });
                    }).catch(function(error) {
                        console.log(error.data);
                        vm.alert = {
                            class: 'alert-danger',
                            message: "Unable to generate the certificate at this time",
                            show: true
                        };
                    }).finally(function() {
                        vm.attaching = false;
                        vm.removeAllowed = _.keys(vm.conf.certificates).length > 1 ? true : false;
                        vm.attachForm.region = null;
                    });
            }

        }

        function attachToShard(region) {
            vm.sharding = true;
            if (!vm.conf.custom_wildcard_flag) {
                var records = [
                    {
                        domain: conf.cname,
                        region: region,
                        type: 'A'
                    }
                ];
                // TODO Store CNAME record in DB
                witAccountService.postAttachToShard({records: records, region: region, ie_only: vm.conf.ie_only_flag})
                    .then(function(response) {
                        vm.conf.certificates[region].r53_exists = true;
                    }).catch(function(error) {
                        Notification.error("Unable to attach region to shard");
                        console.log(error);
                    }).finally(function() {
                        vm.sharding = false;
                    });
            } else {
                witAccountService.postAttachCustomWildcardToShard({region: region, domains: [{domain_name: conf.cname, base_domain: conf.baseName}]})
                    .then(function(response) {
                        vm.conf.certificates[region].r53_exists = true;
                    }).catch(function(error) {
                        Notification.error("Unable to attach region to shard");
                        console.log(error);
                    }).finally(function() {
                        vm.sharding = false;
                    });
            }
        }

        function finishTestEnable() {
            if (!enableError) {
                vm.conf.wit_certificate = witCertificate;
                vm.enabled = true;
                vm.alert = {
                    class: 'alert-success',
                    message: "Your test SSL certificate has been enabled. Please wait a few minutes for the change to take effect.",
                    show: true
                };
            } else {
                vm.alert = {
                    class: 'alert-danger',
                    message: "We are unable to enable the certificate at this time. Please contact support@scientiamobile.com for assistance.",
                    show: true
                };
            }
            resetVariables();
            vm.attaching = false;
        }

        function getStatus() {
            switch (conf.status.ssl) {
                case 'ISSUED':
                    vm.status = 'SSL Certificate has been issued';
                    if (!conf.status.attached) {
                        vm.status += " but is waiting to enable.<br />No action is needed at this time."
                    }
                    break;
                case 'PENDING_VALIDATION':
                    var message = "Waiting for verification";
                    if (vm.conf.ie_only_flag || vm.conf.validation.type === 'DNS') {
                        message += " through DNS";
                    } else {
                        message += " via email";
                    }
                    vm.status = message;
                    break;
                case 'VALIDATION_TIMED_OUT':
                    vm.status = 'Request has timed out';
                    break;
                case 'EXPIRED':
                    vm.status = 'SSL Certificate is expired';
                    break;
                case 'INACTIVE':
                    vm.status = 'SSL Certificate is inactive'; // TODO Check on this one
                    break;
                case 'REVOKED':
                    vm.status = 'SSL Certificate has been revoked';
                    // vm.revokedAt = conf. TODO Revocation info
                    break;
                case 'FAILED':
                    vm.status = 'Request failed'; // TODO Failure info
                    break;
            }
        }

        function removeFromRegion(region, deploy) {
            alertSet();
            var selectedConf = _.pick(vm.conf.certificates, function(value, key, object) {
                return key === region
            })[region];

            if (_.keys(vm.conf.certificates).length == 1) {
                var message = "This will disable SSL/TLS for the domain.";
            } else if (selectedConf.deploy === 'provisioning') {
                var message = "This will prevent the region from being attached to the load balancer shard.";
            } else {
                var message = "This will immediately make the selected region inaccessible.";
            }

            message += " Are you sure you want to continue?";

            $ngConfirm({
                title: 'Are you sure?',
                theme: 'dark',
                content: message,
                buttons: {
                    close: {
                        text: 'Cancel',
                        btnClass: 'btn-default'
                    },
                    proceed: {
                        text: 'Remove',
                        btnClass: 'btn-danger',
                        action: function() {
                            vm.removing[region] = true;
                            vm.removeDisable = true;
                            witAccountService.postRemoveCertificateFromRegion($stateParams.id, {domain: vm.conf.cname, region: region})
                                .then(function(response) {
                                    vm.conf.wit_certificate = _.reject(vm.conf.wit_certificate, function(obj) {
                                        return obj.region == region;
                                    });
                                    vm.conf.wit_resource_record = _.reject(vm.conf.wit_resource_record, function(obj) {
                                        return obj.region == region;
                                    });
                                    vm.conf.certificates = _.omit(vm.conf.certificates, region);
                                    vm.removing = _.omit(vm.removing, region);
                                    vm.availableRegions.push({
                                        RegionName: region,
                                        Deploy: deploy
                                    });
                                    vm.removeAllowed = _.keys(vm.conf.certificates).length > 1 ? true : false;
                                }).catch(function(error) {
                                    console.log(error);
                                    if (_.indexOf(_.keys(error.data.data), 'failures') !== -1) {
                                        var message = "An issue occurred while adding the certificate";
                                        angular.forEach(error.data.data.failures, function(val, key) {
                                            message += " "+key+": "+val;
                                        });
                                    } else {
                                        var message = error.data.message;
                                    }
                                    vm.alert = {
                                        class: 'alert-danger',
                                        message: message,
                                        show: true
                                    };
                                }).finally(function() {
                                    vm.removing[region] = false;
                                    vm.removeDisable = false;
                                });
                        }
                    }
                }
            });
        }

        function requestTestCertificate(domainName) {
            test = true;
            alertSet();
            vm.requesting = true;
            vm.attaching = true;

            var certificateIds = [];
            var detailsPromises = [];

            witAccountService.getAwsRegionsByAccount($stateParams.id).then(function(response) {
                angular.forEach(response.data, function(val, key) {
                    var certForm = {
                        domain: vm.conf.cname,
                        region: val
                    };
                    regionPromises.push(witAccountService.postRequestTestCertificate(vm.conf.subscription_id, certForm)
                        .then(function(response) {
                            witCertificate = response.data.certificates;
                            if (response.data.dns) {
                                certificateIds.push({
                                    region: val.RegionName,
                                    id: response.data.certificate_id
                                });
                            }
                        }).catch(function(error) {
                            console.log(error);
                            enableError = true;
                            $q.reject();
                        }));
                });

                $q.all(regionPromises).then(function() {
                    if (certificateIds.length !== 0) {
                            witAccountService.getCertificateValidationDetails($stateParams.id, certificateIds[0].id)
                                .then(function(response) {
                                    var records = [{
                                        domain: domainName + ".",
                                        region: 'global',
                                        type: 'CNAME',
                                        certificate: response.data.ResourceRecord
                                    }];
                                    witAccountService.postResourceRecords($stateParams.id, records)
                                        .then(function() {})
                                        .catch(function(error) {
                                            console.log(error);
                                            enableError = true;
                                        }).finally(function() {
                                            finishTestEnable();
                                        })
                                }).catch(function(error) {
                                    console.log(error);
                                    enableError = true;
                                    finishTestEnable();
                                });
                    } else {
                        finishTestEnable();
                    }
                    resetVariables();
                    vm.requesting = false;
                });
            }).catch(function(error) {
                vm.requesting = false;
                vm.alert = {
                    class: 'alert-danger',
                    message: "We are unable to enable the certificate at this time. Please contact support@scientiamobile.com for assistance.",
                    show: true
                };
            });
        }

        function resetVariables() {
            attachError = false;
            deleteError = false;
            detachError = false;
            enableError = false;
            witCertificate = null;
            validation = null;
            regionPromises = [];
            certificateStatus = {};
        }

        function resubmitCertificate() {
            if (vm.conf.ie_only_flag) {
                Notification.error("Cannot resubmit test-only certificates. Please delete and recreate the domain.");
                return;
            }

            var regularCerts = _.where(vm.conf.wit_certificate, {test_certificate: 0});

            alertSet();
            test = false;
            $ngConfirm({
                title: 'Select a Validation Method',
                theme: 'dark',
                contentUrl: "view/subscriptions.wit.components.validation_method",
                buttons: {
                    close: {
                        text: 'Cancel',
                        btnClass: 'btn-default'
                    },
                    proceed: {
                        text: 'Submit',
                        btnClass: 'btn-primary',
                        action: function(scope) {
                            vm.attaching = true;
                            var regions = [];
                            var allBad = _.isEmpty(_.find(vm.conf.certificates, function(obj) {
                                return obj.status === 'ISSUED' || obj.status === 'PENDING_VALIDATION';
                            }));
                            var allPv = _.every(_.reject(vm.conf.certificates, function(obj) {
                                return obj.status !== 'ISSUED' && obj.status !== 'PENDING_VALIDATION';
                            }), function(obj) {
                                return obj.status === 'PENDING_VALIDATION';
                            });
                            var issued = !_.isEmpty(_.where(vm.conf.certificates, {status: "ISSUED"}));
                            var dbStore = true;
                            // Delete the existing certificates
                            angular.forEach(vm.conf.certificates, function(info, region) {
                                if (info.status != 'ISSUED' && info.status != 'PENDING_VALIDATION') {
                                    regions.push({
                                        RegionName: region,
                                        Deploy: info.deploy
                                    });
                                    var id = _.findWhere(regularCerts, {region: region}).id;
                                    regionPromises.push(
                                        witAccountService.patchDeleteCertificate($stateParams.id, id, {delete: true, cancel: false})
                                            .then(function(response) {})
                                            .catch(function(error) {
                                                console.log(error);
                                                deleteError = true;
                                                $q.reject();
                                            })
                                    );

                                    if (!info.r53_exists && allBad) {
                                        // Treating as new domain, add the r53
                                        regionPromises.push(
                                            witAccountService.postResourceRecords($stateParams.id, [{
                                                domain: vm.conf.cname,
                                                region: region,
                                                type: 'A'
                                            }])
                                                .then(function() {})
                                                .catch(function(error) {
                                                    console.log(error);
                                                    attachError = true;
                                                    $q.reject();
                                                })
                                        );
                                    } else if (info.r53_exists && issued) {
                                        // Remove it so as to not serve that region if an issued cert exists
                                        dbStore = false;
                                        var records = [
                                            {
                                                domain: vm.conf.cname,
                                                region: region,
                                                type: 'A'
                                            }
                                        ];
                                        regionPromises.push(
                                            witAccountService.postDetachFromShard({region: region, records: records})
                                                .then(function(response) {})
                                                .catch(function(error) {
                                                    console.log(error);
                                                    detachError = true;
                                                    $q.reject();
                                                })
                                        );
                                    }
                                }
                            });

                            angular.forEach(regions, function(val) {
                                var certForm = {
                                    domain: vm.conf.cname,
                                    region: val,
                                    validationType: scope.validationType,
                                    test: false,
                                    db_store: dbStore
                                };
                                witAccountService.postRequestCertificate(vm.conf.subscription_id, certForm)
                                    .then(function(response) {
                                        validation = {
                                            validation: response.data.validation,
                                            type: scope.validationType
                                        };
                                        witCertificate = response.data.certificates;
                                        certificateStatus[val.RegionName] = {
                                            status: response.data.validation.ValidationStatus,
                                            in_use: response.data.validation.InUse ? 'yes' : 'no'
                                        };
                                    }).catch(function(error) {
                                        console.log(error);
                                        enableError = true;
                                        $q.reject();
                                    })
                            });

                            $q.all(regionPromises).then(function() {
                                if (!enableError) {
                                    vm.conf.wit_certificate = witCertificate;
                                    var message = "The certificate request has been resubmitted.";
                                    if (scope.validationType === 'DNS') {
                                        message += " Please ensure that the record information is in your DNS for verification.";
                                    } else {
                                        message += " Please check your email for validation.";
                                    }
                                    var alertClass = "alert-success";
                                    witAccountService.getCertificateStatus($stateParams.id, vm.conf.cname)
                                        .then(function(response) {
                                            vm.conf.certificates = response.data.certificates;
                                            vm.conf.status.ssl = response.data.status;
                                            vm.conf.validation = response.data.validation;
                                            getStatus();
                                        }).catch(function(error) {
                                            console.log(error.data);
                                            alertClass = "alert-warning";
                                            message += " We are unable to determine the certificate status at this time";
                                        }).finally(function() {
                                            if (deleteError) {
                                                message += " Please note that the timed out certificate could not be deleted.";
                                                alertClass = 'alert-warning';
                                            }
                                            if (attachError) {
                                                message += " Please note that the region could not be attached to the shard.";
                                                alertClass = 'alert-warning';
                                            }
                                            if (detachError) {
                                                message += " Please note that the region was not detached from the shard so traffic may still be served from this region before the certificate is attached";
                                                alertClass = 'alert-danger';
                                            }
                                            vm.alert = {
                                                class: alertClass,
                                                message: message,
                                                show: true
                                            };
                                            vm.attaching = false;
                                            resetVariables();
                                        });
                                } else {
                                    vm.alert = {
                                        class: 'alert-danger',
                                        message: "We are unable to resubmit the certificate at this time. Please contact support@scientiamobile.com for assistance.",
                                        show: true
                                    };
                                    vm.attaching = false;
                                    resetVariables();
                                }
                            });
                        }
                    }
                },
                onScopeReady: function(scope) {
                    scope.validationType = 'DNS';
                }
            })
        }
    }
})();
