(function() {
    'use strict';

    angular.module('adminApp.reports')
        .controller('AccountDownloadsUsersController', AccountDownloadsUsersController);

    AccountDownloadsUsersController.$inject = ['$rootScope', '$scope', '$uibModalInstance', 'reportsService'];

    function AccountDownloadsUsersController($rootScope, $scope, $uibModalInstance, reportsService) {
        var vm = this;

        vm.progress = 0;
        vm.title = "User Accounts Report";

        var updateUsersProgress = $rootScope.$on('updateUsersProgress', function(event, args) {
            vm.progress = reportsService.calculateProgress(args.complete);
            if (vm.progress == 100) {
                $uibModalInstance.close();
            }
            $scope.$on('$destroy', updateUsersProgress);
        });

        reportsService.downloadUserAccounts();

        var userErrorClose = $rootScope.$on('userErrorClose', function() {
            $uibModalInstance.close();
            $scope.$on('$destroy', userErrorClose);
        });
    }
})();
