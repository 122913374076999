(function() {
    'user strict';

    angular.module('adminApp.users')
        .controller('UsersEditController', UsersEditController);

    UsersEditController.$inject = ['$rootScope', '$scope', '$uibModalInstance', 'form', 'Notification', 'platforms', 'userService', 'teamService'];

    function UsersEditController($rootScope, $scope, $uibModalInstance, form, Notification, platforms, userService, teamService) {
        var vm = this;

        vm.close = close;
        vm.errors = [];
        vm.form = form;
        vm.modalOptions = {
            closeButtonText: 'Cancel',
            okButtonText: 'Save',
            template: 'view/users.modals.edit',
            title: 'Edit User Information'
        };
        vm.platforms = platforms;
        vm.platformSelected = platformSelected;
        vm.spinner = false;
        vm.submit = submit;
        vm.togglePlatforms = togglePlatforms;
        vm.initSelectTeamId = initSelectTeamId;
        vm.initSelectResellerTeamId = initSelectResellerTeamId;

        $scope.resellerTeamFilter = function(team) {
            return team.reseller || team.id === -1;
        }

        vm.teams = [
            {id: -1, name: '(none)'}
        ];
        teamService.all().then(function(resellerResponse) {
            angular.forEach(resellerResponse.data, function (value, key) {
                vm.teams.push(value);
            });
        }).catch(function(resellerError) {
            Notification.error("An unexpected error has occurred");
            console.log(resellerError);
        });

        function close() {
            $uibModalInstance.dismiss();
        }

        function platformSelected(id) {
            if (vm.form.platforms.indexOf(id) !== -1) {
                return true;
            }
            return false;
        }

        function submit() {
            vm.spinner = true;
            vm.errors = [];
            vm.form.country_id = vm.form.country.country_id;
            if (vm.form.reseller_id === -1) {
                vm.form.reseller_id = null;
            }
            if (vm.form.reseller_admin_id === -1) {
                vm.form.reseller_admin_id = null;
            }
            if (vm.form.team_id === -1) {
                vm.form.team_id = null;
            }
            if (vm.form.reseller_team_id === -1) {
                vm.form.reseller_team_id = null;
            }

            userService.update(vm.form).then(function(response) {
                Notification.success("User "+vm.form.username+" was updated");
                $uibModalInstance.dismiss();
                $rootScope.$broadcast('reloadUser');
            }).catch(function(error) {
                angular.forEach(error.data.data, function(value, key) {
                    var message;
                    if (_.isArray(value)) {
                        message = value.join(" ");
                    } else {
                        message = value;
                    }
                    vm.errors[key] = {show: true, message: message};
                });
                console.log(vm.errors);
            }).finally(function() {
                vm.spinner = false;
            });
        }

        function togglePlatforms(id) {
            if (vm.form.platforms.indexOf(id) === -1) {
                vm.form.platforms.push(id);
            } else {
                var index = vm.form.platforms.indexOf(id);
                vm.form.platforms.splice(index, 1);
            }
        }

        function initSelectTeamId() {
            if (vm.form.team_id === null) {
                return -1;
            } else {
                return vm.form.team_id;
            }
        }

        function initSelectResellerTeamId() {
            if (vm.form.reseller_team_id === null) {
                return -1;
            } else {
                return vm.form.reseller_team_id;
            }
        }
    }
})();
