(function() {
    angular.module('adminApp')
        .directive('uploadFiles', uploadFiles);
    uploadFiles.$inject = ['$parse'];
    function uploadFiles($parse) {
        var directive = {
            link: file_links,
            restrict: 'EA'
        };
        return directive;

        function file_links(scope, element, attrs) {
            var onChange = $parse(attrs.uploadFiles);
            element.on('change', function(event) {
                onChange(scope, {$files: event.target.files});
            });
            element.on('click', function(event) {
                if (event.target.files === undefined) {
                    var elm = angular.element('#xml');
                    elm.val('');
                }
            });
        }
    }
})();
