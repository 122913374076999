(function() {
    'use strict';

    angular.module('adminApp.wjs')
        .controller('WjsConfigurationController', WjsConfigurationController);

    WjsConfigurationController.$inject = ['$filter', '$rootScope', '$stateParams', '$uibModal', 'accountService', 'baseAccountService', 'Notification', 'wjsAccountService'];

    function WjsConfigurationController($filter, $rootScope, $stateParams, $uibModal, accountService, baseAccountService, Notification, wjsAccountService) {
        var vm = this;

        vm.addCname = addCname;
        vm.addDomain = addDomain;
        vm.certificateForm = certificateForm;
        vm.cnameValue = null;
        vm.deleteCertificate = deleteCertificate;
        vm.deleteCname = deleteCname;
        vm.deleteDomain = deleteDomain;
        vm.hostnameValue = null;
        vm.loading = true;
        vm.restoreDomain = restoreDomain;
        vm.subscription = accountService.getValues('wjs', 'subscription');

        if (vm.subscription === undefined) {
            baseAccountService.get(wjsAccountService.resourceUrl, $stateParams.id)
                .then(function(response) {
                    vm.subscription = response.data.account;
                    accountService.setValues('wjs', 'subscription', vm.subscription);
                    accountService.setValues('wjs', 'otherAccounts', response.data.other_accounts);
                    accountService.setValues('wjs', 'plans', response.data.plans);
                    accountService.setValues('wjs', 'defaults', baseAccountService.remapLimits(vm.subscription.wurfl_js_accounts_defaults, 'wurfl_js_default'));
                    accountService.setValues('wjs', 'billingInfoAvailable',
                        baseAccountService.isFree(vm.subscription) === false && baseAccountService.isStandardBilling(vm.subscription));
                    $rootScope.$broadcast('updateWjsSubscription');
                    vm.loading = false;
                }, function(error) {
                    console.log(error);
                    Notification.error("The account could not be loaded");
                });
        } else {
            vm.loading = false;
        }

        function addCname() {
            wjsAccountService.addCname(vm.subscription.id, vm.cnameValue)
                .then(function(response) {
                    Notification.success(response.message);
                    vm.subscription.cnames.push(response.data);
                    vm.cnameValue = null;
                }, function(response) {
                    Notification.error(response.data.message);
                });
        }

        function addDomain() {
            wjsAccountService.addHostname(vm.subscription.id, vm.hostnameValue)
                .then(function(response) {
                    Notification.success(response.message);
                    vm.subscription.domains.push(response.data);
                    vm.hostnameValue = null;
                }, function(error) {
                    console.log(error);
                    Notification.error(error.data.message);
                });
        }

        function certificateForm(cname) {
            $uibModal.open({
                controller: function($scope, $uibModalInstance, account, cname) {

                    $scope.cname = cname;
                    $scope.ssl={};

                    $scope.submit = function(form) {

                        wjsAccountService.addCertificate(account.id, cname, $scope.ssl).then(function(response) {
                            cname.certificate = response.data;
                            $uibModalInstance.close('success');
                            Notification.success(response.message);

                        }, function(response) {
                            Notification.error(response.data.message);
                        });

                    };
                },
                templateUrl: 'view/subscriptions.wjs.ssl_form_modal',
                resolve: {
                    cname: function() {return cname;},
                    account: function() {return vm.subscription;}
                },
                size: 'lg',
                backdrop: 'static',
                keyboard: false
            });
        }

        function deleteCertificate(cname) {
            $uibModal.open({
                templateUrl: 'view/components.confirm',
                controller: function($scope, $uibModalInstance, message) {
                    $scope.message = message;
                    $scope.dismiss = function() {
                        $uibModalInstance.dismiss('cancel');
                    };
                    $scope.confirm = function() {
                        $uibModalInstance.close();
                    };
                },
                resolve: {
                    message: function() { return "The certificate will be deleted immediately. Confirm?"; }
                },
                size: 'lg',
                backdrop: 'static',
                keyboard: false
            })
            .result.then(function(result) {
                wjsAccountService.deleteCertificate(vm.subscription.id, cname.certificate)
                    .then(function(response) {
                        Notification.success(response.message);
                        cname.certificate = null;

                    }, function(response) {
                        Notification.error(response.data.message);
                    });
            });
        }

        function deleteCname(cname) {
            $uibModal.open({
                templateUrl: 'view/components.confirm',
                controller: function($scope, $uibModalInstance, message) {
                    $scope.message = message;
                    $scope.dismiss = function() {
                        $uibModalInstance.dismiss('cancel');
                    };
                    $scope.confirm = function() {
                        $uibModalInstance.close();
                    };
                },
                resolve: {
                    message: function() { return "The CName <strong>"+cname.cname+"</strong> will be deleted immediately. Confirm?"; }
                },
                size: 'lg',
                backdrop: 'static',
                keyboard: false
            })
            .result.then(function(result) {
                wjsAccountService.deleteCname(vm.subscription.id, cname)
                    .then(function(response) {
                        Notification.success(response.message);
                        vm.subscription.cnames = _.without(vm.subscription.cnames, cname);
                    }, function(response) {
                        Notification.error(response.data.message);
                    });
            });
        }

        function deleteDomain(id, force_delete) {
            var target = _.findWhere(vm.subscription.domains, {id:id});
            var message = "";
            if (force_delete) {
                message = "The domain will be deleted immediately! Confirm to delete "+target.domain+"?";
            } else {
                message = "The domain <strong>"+target.domain+"</strong> will be marked to be deleted in the next 24 hours. Confirm to delete?";
            }

            $uibModal.open({
                templateUrl: 'view/components.confirm',
                controller: function($scope, $uibModalInstance, message) {
                    $scope.message = message;
                    $scope.dismiss = function() {
                        $uibModalInstance.dismiss('cancel');
                    };
                    $scope.confirm = function() {
                        $uibModalInstance.close();
                    };
                },
                resolve: {
                    message: function() { return message; }
                },
                size: 'lg',
                backdrop: 'static',
                keyboard: false
            })
            .result
            .then(function(result) {

                wjsAccountService.deleteHostname(vm.subscription.id, id, force_delete)
                    .then(function(response) {
                        Notification.success(response.message);

                        if (force_delete) {
                            vm.subscription.domains = _.without(vm.subscription.domains, _.findWhere(vm.subscription.domains, {id:id}));
                        } else {
                            var item = _.findWhere(vm.subscription.domains, {id:id});
                            item.deleted_at = $filter('date')(new Date());
                        }

                    }, function(response) {
                        Notification.error(response.data.message);
                    });

            }, function(result) {
                return false;
            });
        }

        function restoreDomain(id) {
            wjsAccountService.restoreHostname(vm.subscription.id, id)
                .then(function(response) {
                    Notification.success(response.message);

                    var item = _.findIndex(vm.subscription.domains, {id:id});
                    vm.subscription.domains[item] = response.data;

                }, function(response) {
                    Notification.error(response.data.message);
                });
        }
    }
})();
