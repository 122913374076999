(function() {
    'use strict';

    angular.module('adminApp')
        .directive('tablesort', tablesort);

    function tablesort() {
        var directive = {
            restrict: 'EA',
            replace: false,
            templateUrl: 'view/components.tablesort',
            scope: {
                name: '@',
                sort: '@'
            },
            controller: 'TableSortController',
            controllerAs: 'vm',
            bindToController: true
        };

        return directive;
    }
})();
