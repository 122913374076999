(function() {
    'use strict';

    angular.module('adminApp.reports')
        .controller('ReportsDatesController', ReportsDatesController);

    ReportsDatesController.$inject = ['$filter', '$uibModalInstance', 'name', 'reportsService'];

    function ReportsDatesController($filter, $uibModalInstance, name, reportsService) {
        var vm = this;

        var params = reportsService.getParams(name);

        vm.close = close;
        vm.dateOptions = {
            formatYear: 'yyyy',
            maxDate: new Date(),
            startingDay: 1
        };
        vm.download = download;
        vm.end = $filter('createDate')({date: params.end, format: 'YMD'});
        vm.isOpen = {
            end: false,
            start: false
        };
        vm.open = open;
        vm.start = $filter('createDate')({date: params.start, format: 'YMD'});

        function close() {
            $uibModalInstance.dismiss();
        }

        function download() {
            vm.start.setHours(0, 0, 0, 0);
            vm.end.setHours(23, 59, 59, 999);
            $uibModalInstance.dismiss();
            reportsService.downloadReports(name, {start: vm.start, end: vm.end});
        }

        function open(key) {
            vm.isOpen[key] = true;
        }
    }
})();
