(function() {
    'use strict';

    angular.module('adminApp.coreapi')
        .controller('ScopesDeleteModalController', ScopesDeleteModalController);

    ScopesDeleteModalController.$inject = ['$rootScope', '$state', '$stateParams', '$uibModalInstance', 'coreApiScopeService', 'Notification', 'scope'];

    function ScopesDeleteModalController($rootScope, $state, $stateParams, $uibModalInstance, coreApiScopeService, Notification, scope) {
        var vm = this;

        vm.close = close;
        vm.modalOptions = {
            actionClass: 'alert-danger',
            actionMessage: 'Deleting...',
            proceedButton: {
                btnClass: 'btn-danger',
                btnSpanClass: 'glyphicon-trash',
                btnText: 'Delete'
            },
            template: 'view/coreapi.modals.delete_modal',
            title: 'Confirm Choice'
        };
        vm.proceed = proceed;
        vm.showActionSpinner = false;
        vm.title = 'scope: '+scope.scope;

        function close() {
            $uibModalInstance.dismiss();
        }

        function proceed() {
            vm.showActionSpinner = true;
            coreApiScopeService.deleteScope(scope.id)
                .then(function(response) {
                    Notification.success('Scope deleted');
                    if ($state.current.name == 'scopes.home') {
                        $rootScope.$broadcast('reloadScopes');
                    } else {
                        $state.go('scopes.home');
                    }
                    $uibModalInstance.dismiss();
                }).catch(function(error) {
                    switch (error.status) {
                        case 403:
                            Notification.error({message: 'You are not permitted to perform this action', replaceMessage: true});
                            break;
                        default:
                            Notification.error("An unknown error has occurred");
                            console.log(error);
                    }
                }).finally(function() {
                    vm.showActionSpinner = false;
                });
        }
    }
})();
