(function() {
    'use strict';

    angular.module('adminApp.home')
        .controller('ExternalController', ExternalController);

    ExternalController.$inject = ['$rootScope', 'userService'];

    function ExternalController($rootScope, userService) {
        var vm = this;

        vm.disabled = true;
        vm.sessionId = null;

        userService.getFilex().then(function(response) {
            vm.sessionId = response.data.admin_session;
            vm.disabled = false;
        }).catch(function(error) {
            console.log(error);
            Notification.error("Unable to generate FileX ID");
        }) ;
    }
})();
