(function() {
    'use strict';

    angular.module('adminApp.licenses')
        .controller('OnsiteController', OnsiteController);

    OnsiteController.$inject = ['$stateParams', '$timeout', 'infuzeService'];

    function OnsiteController($stateParams, $timeout, infuzeService) {
        var vm = this;

        vm.addProfile = addProfile;
        vm.deletingSpinner = [];
        vm.flash = {};
        vm.onDeleteProfileClick = onDeleteProfileClick;
        vm.onSaveProfileClick = onSaveProfileClick;
        vm.profiles = [];
        vm.savingSpinner = [];
        vm.stateParams = $stateParams;

        infuzeService.getProfiles($stateParams.id).then(function(response) {
            vm.profiles = response.data;
            angular.forEach(vm.profiles, function(val, key) {
                vm.flash[val.id] = {
                    show: false,
                    message: null,
                    type: "success"
                };
                vm.deletingSpinner[val.id] = false;
                vm.savingSpinner[val.id] = false;
            });
        }).catch(function(error) {
            switch (error.status) {
                case 404:
                    var uniqId = 'f_' + _.random(1, 1000000);
                    vm.profiles.push({
                        uniqueId: uniqId,
                        license_id: $stateParams.id
                    });
                    vm.flash[uniqId] = {
                        show: false,
                        message: null,
                        type: "success"
                    };
                    vm.deletingSpinner[uniqId] = false;
                    vm.savingSpinner[uniqId] = false;
                    break;
                case 400:
                    Notification.error(error.data.message);
                    console.log(error);
                    break;
                default:
                    Notification.error('An unexpected error occurred');
                    console.log(error);
                    break;
            }
        });

        function addProfile() {
            var uniqId = 'f_' + _.random(1, 1000000);
            vm.profiles.push({
                uniqueId: uniqId,
                license_id: $stateParams.id
            });
        }

        function onDeleteProfileClick(profile) {
            if (_.isUndefined(profile.id)) {
                vm.profiles = _.reject(vm.profiles, function(obj) {
                    return obj.uniqueId === profile.uniqueId;
                });
            } else {
                vm.deletingSpinner[profile.id] = true;
                infuzeService.deleteProfile(profile.id).then(function(response) {
                    vm.flash[profile.id].show = false;
                    vm.flash[profile.id].type = 'success';
                    vm.flash[profile.id].message = "";
                    vm.deletingSpinner[profile.id] = false;
                    vm.profiles = _.reject(vm.profiles, function(obj) {
                        return obj.id == profile.id;
                    });
                    if (_.isEmpty(vm.profiles)) {
                        addProfile();
                    }
                }).catch(function(error) {
                    console.log(error);
                    vm.flash[profile.id].show = true;
                    vm.flash[profile.id].type = 'danger';
                    vm.flash[profile.id].message = error.message;
                    vm.deletingSpinner[profile.id] = false;
                });
            }
        }

        function onSaveProfileClick(profile) {
            var id = !_.isUndefined(profile.id) ? profile.id : profile.uniqueId;
            vm.flash[id] = {
                show: true,
                message: "Saving...",
                type: "success"
            };
            vm.savingSpinner[id] = true;
            if (_.isUndefined(profile.build_os_id) || _.isUndefined(profile.build_product_id) || _.isUndefined(profile.build_arch_id)) {
                vm.savingSpinner[id] = false;
                vm.flash[id].message = "All fields are required";
                vm.flash[id].type = 'danger';
                return;
            }
            if (_.isUndefined(profile.id)) {
                infuzeService.newProfile(profile)
                    .then(function(response) {
                        profile.id = response.data.id;
                        vm.savingSpinner[id] = false;
                        vm.savingSpinner[profile.id] = false;
                        vm.flash[profile.id] = {
                            message: "Save Successful",
                            show: true,
                            type: "success"
                        };
                        vm.flash[id].message = null;
                        $timeout(function() {
                            vm.savingSpinner[profile.id] = false;
                            vm.flash[profile.id].show = false;
                        }, 3000);
                    }).catch(function(error) {
                        console.log(error);
                    });
            } else {
                infuzeService.updateProfile(profile)
                    .then(function(response) {
                        profile.id = response.data.id;
                        vm.savingSpinner[profile.id] = false;
                        vm.flash[profile.id].message = "Save Successful";
                        $timeout(function() {
                            vm.flash[profile.id].show = false;
                        }, 3000);
                    }).catch(function(error) {
                        console.log(error);
                    });
            }
        }
    }
})();
