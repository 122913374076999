(function() {
    'use strict';

    angular.module('adminApp.coreapi')
        .directive('coreapiclient', coreapiclient);

    coreapiclient.$inject = ['$compile', 'coreApiClientService'];

    function coreapiclient($compile, coreApiClientService) {
        var directive = {
            restrict: 'E',
            transclude: true,
            replace: true,
            template: '<input type="text" autocomplete="off" ng-disabled="disable" uib-typeahead="option as option.id for option in clients | filter:$viewValue | limitTo:10" typeahead-min-length="0">',
            link: link
        };

        return directive;

        function link(scope, element, attrs) {
            scope.disable = true;
            scope.clients = [];

            element.attr("placeholder", attrs.preholder);

            coreApiClientService.all().then(function(response) {
                scope.clients = response.data;
                element.attr('placeholder', attrs.placeholder);

                scope.disable = false;
            }).catch(function(error) {
                switch (error.status) {
                    case 403:
                        element.attr('placeholder', 'Action not permitted');
                        break;
                    default:
                        element.attr('placeholder', 'Error occurred');
                        break;
                }
            });
        }
    }
})();
