(function() {
    'use strict';

    angular.module('adminApp.apikey')
        .factory('apikeyService', apikeyService);

    apikeyService.$inject = ['$resource', 'Notification'];

    function apikeyService($resource, Notification) {
        var resource = new $resource('/api/admin/apikey/:id/:action', {id: '@id'}, {
            all: {
                method: 'GET',
                params: {
                    action: 'all',
                    page: '@page',
                    api_key_id: '@id',
                    subscription_id: '@subscription_id',
                    key_name: '@key_name',
                    api_username: '@api_username',
                    api_password: '@api_password',
                    expiration: '@expiration'
                }
            },
            create: {
                method: 'POST',
                params: {action: 'create'}
            },
            generate: {
                method: 'GET',
                params: {action: 'generate'}
            },
            get: {
                method: 'GET',
                params: {action: null}
            },
            getSubscriptionIds: {
                method: 'GET',
                params: {action: 'subscription_ids'}
            },
            remove: {
                method: 'DELETE',
                params: {action: null}
            },
            update: {
                method: 'PATCH',
                params: {action: null}
            }
        });
        var service = {
            all: all,
            create: create,
            generateCredentials: generateCredentials,
            get: get,
            getSubscriptionIds: getSubscriptionIds,
            remove: remove,
            update: update
        };

        return service;

        function all(params) {
            var obj = {
                id: null
            };
            angular.forEach(params, function(val, key) {
                key = key === 'id' ? 'api_key_id' : key;
                obj[key] = val;
            });
            return resource.all(obj).$promise;
        }

        function create(form) {
            return resource.create({id: null}, form).$promise;
        }

        function generateCredentials() {
            return resource.generate({id: null}).$promise;
        }

        function get(id) {
            return resource.get({id: id}).$promise;
        }

        function getSubscriptionIds(userId) {
            return resource.getSubscriptionIds({id: userId}).$promise;
        }

        function remove(id) {
            return resource.remove({id: id}).$promise;
        }

        function update(id, newId, currentId) {
            return resource.update({id: id}, {newId: newId, currentId: currentId}).$promise;
        }
    }
})();
