(function() {
    'use strict';

    angular.module('adminApp')
        .factory('tabStatesService', tabStatesService);

    function tabStatesService() {
        var service = {
            checkNeeded: checkNeeded
        };

        var check = {
            cloud: ['cloud.view', 'cloud.edit', 'cloud.edit-defaults', 'cloud.view.configuration', 'cloud.view.usage', 'cloud.view.billing'],
            licenses: ['licenses.view', 'licenses.view.capabilities', 'licenses.view.configuration', 'licenses.view.text'],
            wit: ['wit.view', 'wit.edit', 'wit.edit-defaults', 'wit.view.configuration', 'wit.view.usage', 'wit.view.billing'],
            wjs: ['wjs.view', 'wjs.edit', 'wjs.edit-defaults', 'wjs.view.configuration', 'wjs.view.usage', 'wjs.view.billing']
        };

        return service;

        function checkNeeded(toState, fromState, toParams, fromParams) {
            var toParentState = toState.name.split(".")[0];
            var fromParentState = fromState.name.split(".")[0];

            if (toParentState != fromParentState) {
                return true;
            }

            if (toParams != fromParams) {
                return true;
            }

            if (check[toParentState] === undefined) {
                return true;
            }

            if (
                check[toParentState].indexOf(toState.name) === -1 ||
                check[toParentState].indexOf(fromState.name) === -1
            ) {
                return true;
            }

            return false;
        }
    }
})();
