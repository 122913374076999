(function() {
    'use strict';

    angular.module('adminApp.wit')
        .controller('WitRestartController', WitRestartController);

    WitRestartController.$inject = ['$q', '$rootScope', '$scope', '$uibModalInstance', 'message', 'Notification', 'subscription', 'witAccountService'];

    function WitRestartController($q, $rootScope, $scope, $uibModalInstance, message, Notification, subscription, witAccountService) {
        var vm = this;

        vm.close = close;
        vm.message = message;
        vm.restart = {
            storedCertificates: false,
            storedDns: false,
            unstoredCertificates: false
        };
        vm.saving = false;
        vm.submit = submit;

        var errorMessage = null;

        $scope.$watch('vm.restart', function(newVal) {
            if (_.every(newVal, function(obj) {
                return obj;
            })) {
                $uibModalInstance.close(errorMessage);
            }
        }, true);

        function close() {
            $uibModalInstance.dismiss();
        }

        function submit() {
            var storedCertificateError = [];
            var storedCertificatePromises = [];
            var hash = [];

            vm.saving = true;
            witAccountService.restart(subscription.id)
                .then(function(response) {
                    angular.forEach(subscription.wit_domain_conf, function(val, key) {
                        // Restart or regenerate stored certificates
                        // Custom wildcards don't need anything on the certificate
                        if (!_.isEmpty(val.wit_certificate)) {
                            if (!val.custom_wildcard_flag) {
                                angular.forEach(val.wit_certificate, function(cert, key) {
                                    storedCertificatePromises.push(
                                        witAccountService.patchRestartCertificate(subscription.id, cert.id)
                                            .then(function(response) {})
                                            .catch(function(error) {
                                                console.log(error);
                                                $q.reject();
                                                storedCertificateError.push(cert);
                                            })
                                    );
                                });
                            } else {
                                storedCertificatePromises.push(
                                    witAccountService.getCertificateValidationDetails(subscription.id, val.wit_certificate[0].id)
                                        .then(function(response) {
                                            hash[val.id] = response.data.hash;
                                        }).catch(function(error) {
                                            console.log(error);
                                            if (_.isNull(errorMessage)) {
                                                errorMessage = ""
                                            }
                                            errorMessage += "Could not find hash for "+val.cname+". DNS records will point to the incorrect load balancer.";
                                        })
                                );
                            }
                        }

                        if ((subscription.payment_plan === 'IMAGEENGINE_PRO' || subscription.payment_plan === 'IMAGEENGINE_STANDARD') && !val.custom_wildcard_flag) {
                            witAccountService.patchRestartUnstoredCertificate(subscription.id, val.id)
                                .then(function() {})
                                .catch(function(error) {
                                    console.log(error);
                                    if (_.isNull(errorMessage)) {
                                        errorMessage = ""
                                    }
                                    errorMessage += "Could not restart unstored certificates for "+val.cname+"<br />";
                                }).finally(function() {
                                    vm.restart.unstoredCertificates = true;
                                });
                        } else {
                            vm.restart.unstoredCertificates = true;
                        }
                    });

                    $q.all(storedCertificatePromises).then(function() {
                        if (!_.isEmpty(storedCertificateError)) {
                            if (_.isNull(errorMessage)) {
                                errorMessage = ""
                            }
                            errorMessage = "Errors occurred in restarting the following stored certificates:<br />";
                            angular.forEach(storedCertificateError, function(error, key) {
                                errorMessage += "Certificate: "+error.id+", "+error.region+", "+error.certificate_arn+"<br />";
                            });
                        }
                        vm.restart.storedCertificates = true;

                        // Regenerate the alias DNS records
                        var records = [];
                        angular.forEach(subscription.wit_domain_conf, function(val, key) {
                            angular.forEach(val.wit_resource_record, function(resource, key) {
                                if (resource.type == 'A') {
                                    var record = {
                                        type: 'A',
                                        region: resource.region,
                                        domain: val.cname
                                    };

                                    if (!_.isUndefined(hash[val.id])) {
                                        record.hash = hash[val.id];
                                    }
                                    records.push(record);
                                }
                            });
                        });

                        if (!_.isEmpty(records)) {
                            witAccountService.postResourceRecords(subscription.id, records, false)
                                .then(function() {})
                                .catch(function(error) {
                                    console.log(error);
                                    if (_.isNull(errorMessage)) {
                                        errorMessage = ""
                                    }
                                    errorMessage += "Route 53: Could not generate alias records for "+val.cname+"<br />";
                                })
                                .finally(function() {
                                    vm.restart.storedDns = true;
                                });
                        } else {
                            vm.restart.storedDns = true;
                        }
                    });
                }).catch(function(error) {
                    console.log(error);
                    vm.saving = false;
                    Notification.error("Unable to restart account");
                    Notification.error(error.data.message)
                });
        }
    }
})();
