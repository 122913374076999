(function() {
    'use strict';

    angular.module('adminApp.licenses')
        .controller('LicenseViewAccountController', LicenseViewAccountController);

    LicenseViewAccountController.$inject = ['$state', '$stateParams', '$uibModalInstance', 'licenseService', 'Notification'];

    function LicenseViewAccountController($state, $stateParams, $uibModalInstance, licenseService, Notification) {
        var vm = this;

        vm.allowedCaps = null;
        vm.close = close;
        vm.css = {
            account_type: 'col-md-4',
            capabilities: 'col-md-4',
            download: 'col-md-4'
        };
        vm.dataLoaded = false;
        vm.daysToExpire = null;
        vm.downloadKey = null;
        vm.expire = {
            soon: false,
            now: false
        };
        vm.inUse = null;
        vm.isEval = false;
        vm.isMicro = false;
        vm.license = null;
        vm.productName = null;
        vm.requiredCaps = null;
        vm.requiredCapsCount = 0;
        vm.viewCapabilities = viewCapabilities;
        vm.viewLicense = viewLicense;

        licenseService.gatherAccountInfo($stateParams.id).then(function(response) {
            // console.log(response.data);
            vm.license = response.data.license;
            vm.daysToExpire = response.data.days_to_expire;
            vm.isEval = response.data.is_eval;
            vm.isMicro = response.data.is_micro;
            if (vm.isMicro) {
                vm.css.download = 'col-md-4';
            }
            if (
                response.data.days_to_expire <= 31 &&
                response.data.days_to_expire >= 0 &&
                response.data.is_eval
            ) {
                vm.expire.soon = true;
            } else if (response.data.is_expired) {
                vm.expire.now = true;
            }
            vm.productName = response.data.product.product_name;
            if (vm.isEval) {
                vm.productName = vm.productName + " Evaluation";
                vm.css.account_type = "col-md-6";
                vm.css.capabilities = "col-md-6";
            }
            if (response.data.download_key) {
                vm.downloadKey = response.data.download_key.hash;
            }
            vm.requiredCaps = response.data.required_caps;
            var count = 0;
            angular.forEach(vm.requiredCaps, function(value, key) {
                count++;
            });
            vm.requiredCapsCount = count;
            vm.allowedCaps = response.data.allowed_caps;
            vm.inUse = response.data.in_use;
            vm.dataLoaded = true;
        }).catch(function(error) {
            Notification.error("An error occurred");
            console.log(error);
        });

        function close() {
            $uibModalInstance.dismiss();
            $state.go('users.myaccount', {id: vm.license.user_id});
        }

        function viewCapabilities() {
            $uibModalInstance.dismiss();
            $state.go('licenses.view.capabilities', {id: vm.license.id});
        }

        function viewLicense() {
            $uibModalInstance.dismiss();
            $state.go('licenses.view', {id: vm.license.id});
        }
    }
})();
