(function() {
    angular.module('adminApp.users')
        .controller('CreateUserController', CreateUserController);

    CreateUserController.$inject = ['$ngConfirm', '$rootScope', '$state', '$uibModalInstance', '$window', 'Notification', 'userService'];

    function CreateUserController($ngConfirm, $rootScope, $state, $uibModalInstance, $window, Notification, userService) {
        var vm = this;

        vm.close = close;
        vm.formData = {
            partner: false
        };
        vm.formErrors = {};
        vm.loading = true;
        vm.modalOptions = {
            template: 'view/users.modals.new_user_form',
            title: "Create User Record",
            closeButtonText: "Back",
            okButtonText: "Save"
        };
        vm.spinner = false;
        vm.submit = submit;

        $rootScope.$on('loadedCountryDirective', function() {
            vm.loading = false;
        });

        function close() {
            $uibModalInstance.dismiss();
        }

        function runSave() {
            vm.spinner = true;
            userService.save(vm.formData)
                .then(function(response) {
                    userService.updateRoles(response.data, ['user']).then(function(response) {
                        Notification.success("User "+response.data.username+" created!");
                        $uibModalInstance.dismiss();
                        vm.spinner = false;
                        vm.formErrors = {};
                        $state.go('users.view', {id: response.data.id});
                    }).catch(function(error) {
                        Notification.success("User "+response.data.username+" created!");
                        Notification.warning("User role was not set");
                        console.log(error);
                        $uibModalInstance.dismiss();
                        vm.spinner = false;
                        vm.formErrors = {};
                        $state.go('users.view', {id: response.data.id});
                    });
                }, function(error) {
                    console.log(error);
                    switch (error.status) {
                        case 400:
                            angular.forEach(error.data.data, function(value, key) {
                                vm.formErrors[key] = value[0];
                            });
                            Notification.error("Form errors exist");
                            vm.spinner = false;
                            $window.scrollTo(0, 0);
                            break;
                        case 500:
                            Notification.error("An unexpected error has occurred");
                            console.log(error);
                            break;
                        default:
                            Notification.error(error.message);
                            console.log(error);
                            break;
                    }
                });
        }

        function submit() {
            if (vm.formData.country) {
                vm.formData.country = vm.formData.country.id;
            }

            if (!vm.formData.confirmed) {
                $ngConfirm({
                    title: 'Continue?',
                    theme: 'dark',
                    content: 'An email will be sent to ' + vm.formData.email + ' for account confirmation. To prevent this, select "Yes" for "Confirm Email?".',
                    buttons: {
                        close: {
                            text: 'Cancel',
                            btnClass: 'btn-default'
                        },
                        proceed: {
                            text: 'Continue',
                            btnClass: 'btn-primary',
                            action: function() {
                                runSave();
                            }
                        }
                    }
                });
            } else {
                runSave();
            }
        }
    }
})();
