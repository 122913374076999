(function() {
    'use strict';

    angular.module('adminApp.wit')
        .controller('WitProSwitchController', WitProSwitchController);

    WitProSwitchController.$inject = ['$filter', '$ocLazyLoad', '$rootScope', '$state', '$stateParams', '$uibModal', '$uibModalInstance', 'accountService', 'Notification', 'path', 'paymentService', 'witAccountService', 'witModalService'];

    function WitProSwitchController($filter, $ocLazyLoad, $rootScope, $state, $stateParams, $uibModal, $uibModalInstance, accountService, Notification, path, paymentService, witAccountService, witModalService) {
        var vm = this;

        vm.cancel = cancel;
        vm.class = {
            usage_limit: 'form-control',
            flat_rate: 'form-control',
            overage_fee: 'form-control',
            trial_days: 'form-control'
        };
        vm.form = angular.copy(accountService.getValues('wit', 'subscription'));
        vm.other = path === 'external' ? 'standard' : 'external';
        vm.path = path;
        vm.payment = vm.form.payment_type == 'TRIAL' ? false : true;
        vm.save = save;
        vm.trialDisabled = trialDisabled;
        vm.trialWarning = false;
        vm.trialWarningMessage = '';

        vm.form.pro_standard = path === 'external' ? true : false;

        var originalTrialDays = angular.copy(vm.form.plan.trial_days);

        function cancel() {
            returnToEdit(false);
        }

        function generateToken() {
            return paymentService.getBraintreeAuthorization().then(function(response) {
                return response.data.authorization;
            });
        }

        function save() {
            vm.trialWarning = false;
            vm.trialWarningMessage = '';
            var errors = witAccountService.validateTier(vm.form);
            if (errors.status) {
                angular.forEach(vm.class, function(val, key) {
                    if (errors.fields[key]) {
                        vm.class[key] += " error";
                    }
                });
                return;
            }

            if (vm.form.payment_type == 'TRIAL') {
                var startDate = $filter('createDate')({date: angular.copy(vm.form.date_started), format: 'YMD HIS'});
                var expireDate = angular.copy(startDate);
                expireDate = new Date(expireDate.setDate(expireDate.getDate() + originalTrialDays));
                var today = new Date();
                var newExpireDate = angular.copy(startDate);
                newExpireDate = new Date(newExpireDate.setDate(newExpireDate.getDate() + parseInt(vm.form.plan.trial_days)));
                if (today > expireDate) {
                    vm.class.trial_days += " error";
                    vm.trialWarning = true;
                    vm.trialWarningMessage = "Trial period is already complete. To extend the trial, please create a new subscription.";
                    return;
                }
                if (today > newExpireDate) {
                    vm.class.trial_days += " error";
                    vm.trialWarning = true;

                    vm.trialWarningMessage = "The requested trial days would end the trial period. If this is desired, please activate the account first.";
                    return;
                }
            } else if (vm.form.payment_type == 'EXTERNAL') {
                vm.form.payment_type = 'STANDARD';
            }

            if (!vm.payment) {
                vm.form.nonce = null;
                witAccountService.update(vm.form, 'swap').then(function(response) {
                    Notification.success('Successfully switched to standard billing');
                    Notification.success('Payment link has been sent to the user');
                    // returnToEdit(true);
                    $rootScope.$broadcast('upgradeWit');
                }).catch(function(error) {
                    console.log(error);
                    Notification.error('Could not update account');
                });
            } else {
                $uibModalInstance.dismiss();
                witModalService.addPaymentInfo('swap', vm.form, false);
            }
        }

        function trialDisabled() {
            if (vm.form.payment_type == 'STANDARD') {
                return true;
            }

            var startDate = $filter('createDate')({date: angular.copy(vm.form.date_started), format: 'YMD HIS'});
            var today = new Date();
            var expireDate = angular.copy(startDate);
            expireDate = new Date(expireDate.setDate(expireDate.getDate() + originalTrialDays));
            if (today > expireDate) {
                return true;
            }

            return false;
        }

        function returnToEdit(action) {
            if (action) {
                accountService.setValues('wit', 'subscription', undefined);
            }

            $uibModalInstance.dismiss('');
            $uibModal.open({
                templateUrl: 'view/subscriptions.wit.edit_account',
                controller: 'WitEditAccountFormController',
                controllerAs: 'vm',
                size: 'md',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    saasType: function() {
                        return 'wit';
                    }
                }
            });
        }
    }
})();
