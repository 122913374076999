(function() {
    'use strict';

    angular.module('adminApp.cut')
        .controller('CutManageAssertionsController', CutManageAssertionsController);

    CutManageAssertionsController.$inject = ['$location', '$rootScope', '$scope', '$state', '$stateParams', '$timeout', 'breadcrumbsService', 'centraltestAssertionsService', 'centraltestTestsService', 'cutModalService', 'paginationService'];

    function CutManageAssertionsController($location, $rootScope, $scope, $state, $stateParams, $timeout, breadcrumbsService, centraltestAssertionsService, centraltestTestsService, cutModalService, paginationService) {
        var vm = this;

        vm.columns = [
            {
                name: 'ID',
                search: {
                    isSearchable: true,
                    type: 'text'
                },
                model: 'id',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: 'Test ID',
                search: {
                    isSearchable: true,
                    type: 'text'
                },
                model: 'test_id',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: 'Assertion Type',
                search: {
                    isSearchable: true,
                    type: 'select',
                    id: 'assertion_type'
                },
                model: 'assertion_type',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: 'Expected / Required ID',
                search: {
                    isSearchable: true,
                    type: 'text'
                },
                model: 'expected_id',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: 'Expected Value',
                search: {
                    isSearchable: true,
                    type: 'text'
                },
                model: 'expected_value',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: 'Exact Match',
                search: {
                    isSearchable: true,
                    type: 'select',
                    id: 'exact_match'
                },
                model: 'exact_match',
                value: {
                    fromData: true
                },
                display: true
            },
            {
                name: '',
                search: {
                    isSearchable: false
                },
                model: '',
                value: {
                    fromData: false,
                },
                display: true
            }
        ];
        vm.createNew = createNew;
        vm.enableCreate = false;
        vm.search = {};
        vm.steps = breadcrumbsService.get('centraltest.admin.assertions');

        var currentPage = $stateParams.page;
        var search_params = _.clone($location.search());
        var ids = [];
        var numPages = 1;

        $timeout(function() {
            $rootScope.$broadcast("apiassertionsUpdateSelect", {
                id: 'assertion_type',
                data: {
                    'device': 'device',
                    'capability': 'capability',
                    'virtual_capability': 'virtual_capability'
                },
                keyNeeded: true,
                value: 'key',
                show: 'value'
            });
        }, 1000);
        $timeout(function() {
            $rootScope.$broadcast("apiassertionsUpdateSelect", {
                id: 'exact_match',
                data: {
                    '0': '0',
                    '1': '1'
                },
                keyNeeded: true,
                value: 'key',
                show: 'value'
            });
        }, 1000);

        getAssertions();
        centraltestTestsService.gatherIds().then(function(response) {
            ids = response.data.ids;
            vm.enableCreate = true;
        });

        $rootScope.$on('reloadAssertions', function(event, args) {
            if (args.page === undefined) {
                getAssertions($stateParams);
            } else {
                getAssertions(args);
            }
        });

        var apiassertionsSearchParamsUpdated = $rootScope.$on('apiassertionsSearchParamsUpdated', function(event, args) {
            vm.search = args;
            currentPage = 1;
            getAssertions(args);
        });

        var apiassertionsPageChanged = $rootScope.$on("apiassertionsPageChanged", function(event, args) {
            vm.search = args;
            currentPage = args.page;
            getAssertions(args);
        });

        var apiassertionsItemsPerPageChanged = $rootScope.$on("apiassertionsItemsPerPageChanged", function(event, args) {
            vm.search = args;
            currentPage = 1;
            getAssertions(args);
        });

        function createNew() {
            cutModalService.create('assertion', ids, 'test', numPages);
        }

        function getAssertions(searchParams) {
            searchParams = typeof searchParams !== 'undefined' ? searchParams : null;
            var start = 0;
            var number = 10;

            var query_params = {};
            var next_page = 1;

            if (searchParams !== null) {
                next_page = searchParams.page;
                number = searchParams.paginate;
            } else if (search_params && _.isUndefined(search_params.page) === false) { // if defined
                next_page = search_params.page;
            } else {
                next_page = Math.floor(start/number) + 1;
            }

            query_params = {page: next_page};
            if (searchParams !== null) {
                angular.forEach(searchParams, function(value, key) {
                    if (value !== '' && value !== null) {
                        query_params[key] = value;
                    }
                });
            }

            centraltestAssertionsService.all(query_params).then(function(response) {
                var paginationData = {};
                numPages = response.pagination.last_page;

                paginationData.pages = paginationService.createPages(currentPage, numPages);
                paginationData.numPages = numPages;
                paginationData.currentPage = currentPage;
                paginationData.currentState = $state.current.name;
                paginationData.stateParams = $stateParams;
                paginationData.from = paginationService.from(response.pagination);
                paginationData.to = paginationService.to(response.pagination);
                paginationData.total = response.pagination.total;

                $rootScope.$broadcast('apiassertionsDataLoaded', {
                    data: response.data,
                    paginationData: paginationData,
                    itemsPerPage: number
                });
                $scope.$on('$destroy', apiassertionsSearchParamsUpdated);
                $scope.$on('$destroy', apiassertionsPageChanged);
                $scope.$on('$destroy', apiassertionsItemsPerPageChanged);
            }).catch(function(error) {
                Notification.error('Cannot gather assertions');
                console.log(error.data);
            });
        }
    }
})();
