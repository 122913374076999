(function() {
    'use strict';

    angular.module('adminApp.saas')
        .controller('SaasSearchFormController', SaasSearchFormController);

    SaasSearchFormController.$inject = ['$location', '$state'];

    function SaasSearchFormController($location, $state) {
        var vm = this;

        vm.keyword = vm.model || null;
        vm.submit = submit;

        function submit(keyword) {
            if (! keyword) {
                return false;
            }
            if (vm.state !== null) {
                $state.go(vm.state, {keyword: keyword, page: 1});
            } else {
                $location.url(vm.action+'/'+keyword);
            }
        }
    }
})();
