(function() {
    'use strict';

    angular.module('adminApp.wurfl')
        .controller('CapabilitiesMenuController', CapabilitiesMenuController);

    CapabilitiesMenuController.$inject = ['$ngConfirm', '$rootScope', 'capabilitiesService', 'menuService'];

    function CapabilitiesMenuController($ngConfirm, $rootScope, capabilitiesService, menuService) {
        var vm = this;

        vm.buttonsDisabled = !(!menuService.getMenu('salesadmin') || !menuService.getMenu('admin'));
        vm.capability = undefined;
        vm.capabilitySelected = false;
        vm.close = close;
        vm.viewCapability = viewCapability;

        function close() {
            $rootScope.$broadcast('sidebarDismiss');
        }

        function viewCapability() {
            if (!_.isNull(capabilitiesService.selectedCapability)) {
                var args = capabilitiesService.selectedCapability;
                $ngConfirm({
                    title: 'Capability Information',
                    theme: 'dark',
                    contentUrl: "view/components.capabilitymodal",
                    buttons: {
                        close: {
                            text: 'OK',
                            btnClass: 'btn-primary'
                        }
                    },
                    onScopeReady: function(scope) {
                        var aspect = args.aspect_name !== undefined ? args.aspect_name : 'none';
                        scope.capability = {
                            group: args.group,
                            aspect: aspect,
                            description: args.description
                        };
                    }
                });
            }
        }
    }
})();
