(function() {
    'use strict';

    angular.module('adminApp.saas')
        .directive('userSaasSubscriptions', userSaasSubscriptions);

    userSaasSubscriptions.$inject = ['$window', 'baseAccountService'];

    function userSaasSubscriptions($window, baseAccountService) {
        var directive = {
            scope: {
                user: '=',
                service: '=',
                app: '@'        // the JS app to link the accounts
            },
            bindToController: true,
            controllerAs: 'vm',
            controller: 'UserSaasSubscriptionsController',
            templateUrl: 'view/subscriptions.common.list'
        };

        return directive;
    }
})();
