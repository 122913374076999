(function() {
    'use strict';

    angular.module('adminApp.cut')
        .factory('cutRunTests', cutRunTests);

    cutRunTests.$inject = ['$resource','CUT_URL'];

    function cutRunTests($resource, CUT_URL) {
        var service = {
            processTest: processTest,
            runInfo: runInfo,
            runTest: runTest,
            showAll: showAll
        };
        service.externalResults = [];

        var resource = new $resource(CUT_URL + '/api/admin/centraltests/run/:action', {}, {
            info: {
                method: 'POST',
                params: {action: 'info'}
            },
            run: {
                method: 'POST',
                params: {action: null}
            }
        });

        return service;

        function processTest(value, response, hideSuccessful) {
            switch (response.data.status) {
                case 'success':
                    var data = {
                        id: value.id,
                        short_name: value.short_name + ' <strong>OK</strong>',
                        header_value: value.header_value,
                        assertions: value.assertions,
                        class: 'match-success'
                    };

                    if (hideSuccessful == 'true') {
                        data.hidden = true;
                    } else {
                        data.hidden = false;
                    }
                    var external = [
                        {
                            testId: value.short_name + ' <strong>OK</strong>',
                            headerValue: value.header_value,
                            assertions: value.assertions,
                            class: 'match-success',
                        }
                    ];
                    var results = 'successful';
                    break;
                case 'failure':
                    var external = [
                        {
                            testId: value.short_name + ' <strong>FAIL</strong>',
                            headerValue: value.header_value,
                            assertions: value.assertions,
                            class: 'match-failure',
                        },
                        {
                            colspan: true,
                            value: response.message
                        }
                    ];
                    var data = {
                        id: value.id,
                        short_name: value.short_name + ' <strong>FAIL</strong>',
                        header_value: value.header_value,
                        assertions: value.assertions,
                        class: 'match-failure'
                    };
                    var results = 'failed';
                    break;
                case 'exception':
                    var external = [
                        {
                            testId: value.short_name + ' <strong>EXCEPTION</strong>',
                            headerValue: value.header_value,
                            assertions: value.assertions,
                            class: 'match-exception',
                        },
                        {
                            colspan: true,
                            value: response.message
                        }
                    ];
                    var data = {
                        id: value.id,
                        short_name: value.short_name + ' <strong>EXCEPTION</strong>',
                        header_value: value.header_value,
                        assertions: value.assertions,
                        class: 'match-exception'
                    };
                    var results = 'exception';
                    break;
                case 'disabled':
                    var external = [
                        {
                            testId: value.short_name + ' <strong>DISABLED</strong>',
                            headerValue: value.header_value,
                            assertions: value.assertions,
                            class: 'match-disabled',
                        }
                    ];
                    var data = {
                        id: value.id,
                        short_name: value.short_name + ' <strong>DISABLED</strong>',
                        header_value: value.header_value,
                        assertions: value.assertions,
                        class: 'match-disabled'
                    };
                    var results = null;
                    break;
                case 'skipped':
                    var external = [
                        {
                            testId: value.short_name + ' <strong>SKIPPED</strong>',
                            headerValue: value.header_value,
                            assertions: value.assertions,
                            class: 'match-skipped',
                        },
                        {
                            colspan: true,
                            value: response.message
                        }
                    ];
                    var data = {
                        id: value.id,
                        short_name: value.short_name + ' <strong>SKIPPED</strong>',
                        header_value: value.header_value,
                        assertions: value.assertions,
                        class: 'match-skipped'
                    };
                    var results = 'skipped';
                    break;

            }
            return {
                data: data,
                external: external,
                results: results
            };
        }

        function runInfo(data) {
            return resource.info({}, data).$promise;
        }

        function runTest(data) {
            return resource.run({}, data).$promise.then(function(response) {
                return {
                    success: true,
                    response: response
                }
            }).catch(function(error) {
                console.log(error);
                return {
                    success: false,
                    status: error.status
                };
            });
        }

        function showAll(action, allTests) {
            if (!action) {
                var hide = true;
            } else {
                var hide = false;
            }
            var tests = [];
            angular.forEach(allTests, function(val, key) {
                if (val.class == 'match-success') {
                    val.hidden = hide;
                }
                tests.push(val);
            });
            return tests;
        }
    }
})();
