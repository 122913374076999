(function() {
    'use strict';

    angular.module('adminApp')
        .factory('loaderService', loaderService);

    loaderService.$inject = ['$ngConfirm'];

    function loaderService($ngConfirm) {
        var service = {
            close: close,
            confirm: confirm
        };
        service.show = true;

        service.loader = null;
        return service;

        function close() {
            if (!_.isNull(service.loader)) {
                service.loader.close();
            }
        }

        function confirm() {
            service.loader = $ngConfirm({
                title: '',
                theme: 'loader',
                contentUrl: 'view/components.loading_overlay',
                bgOpacity: '0%',
                closeIcon: false,
                animation: 'none',
                closeAnimation: 'none'
            });
        }
    }
})();
