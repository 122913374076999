(function() {
    'use strict';

    angular.module('adminApp.cut')
        .controller('CutTestResultsController', CutTestResultsController);

    CutTestResultsController.$inject = ['$uibModalInstance', 'cutRunTests'];

    function CutTestResultsController($uibModalInstance, cutRunTests) {
        var vm = this;

        vm.close = close;
        vm.data = cutRunTests.externalResults;

        function close() {
            $uibModalInstance.dismiss();
        }

    }
})();
