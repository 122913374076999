(function() {
    'use strict';

    angular.module('adminApp.wit')
        .controller('WitCancelController', WitCancelController);

    WitCancelController.$inject = ['$q', '$uibModalInstance', 'accountService', 'message', 'subscription', 'witAccountService'];

    function WitCancelController($q, $uibModalInstance, accountService, message, subscription, witAccountService) {
        var vm = this;

        vm.canceling = false;
        vm.close = close;
        vm.message = message;
        vm.missingReason = false;
        vm.reason = null;
        vm.submit = submit;

        var certificateFail = false;
        var dnsFail = false;
        var obj = {};

        var certificatePromises = [];
        var domainPromises = [];

        var domains = accountService.getValues('wit', 'wit_domain_conf');

        function close() {
            $uibModalInstance.dismiss();
        }

        function deleteResourceRecords() {
            angular.forEach(domains, function(val, key) {
                domainPromises.push(
                    witAccountService.postDeleteResourceRecords(subscription.id, val.id, false, false)
                        .then(function(response) {})
                        .catch(function(error) {
                            console.log(error);
                            dnsFail = true;
                            $q.reject();
                        })
                );
            });

            $q.all(domainPromises).then(function() {
                finish();
            });
        }

        function finish() {
            obj.error = {
                certificate: certificateFail,
                dns: dnsFail,
                account: false
            };
            witAccountService.cancel(subscription.id, vm.reason).then(function(response) {
                subscription.status = 'CANCELED';
                subscription.date_expiration = new Date();
                subscription.payment_type = 'EXTERNAL';
                obj.success = true;
                obj.subscription = subscription;
            }).catch(function(error) {
                obj.success = false;
                obj.error.account = true;
            }).finally(function() {
                vm.canceling = false;
                if (certificateFail || dnsFail) {
                    obj.success = false;
                }
                $uibModalInstance.close(obj);
            });
        }

        function submit() {
            if (_.isNull(vm.reason) || vm.reason === '') {
                vm.missingReason = true;
                return;
            }

            vm.canceling = true;
            var certificates = _.reject(_.flatten(_.pluck(domains, 'wit_certificate')), function(obj) {
                return _.isUndefined(obj);
            });
            if (certificates.length === 0) {
                deleteResourceRecords();
            } else {
                var domainIds = [];
                angular.forEach(certificates, function(val, key) {
                    // Delete the certificates
                    certificatePromises.push(
                        witAccountService.patchDeleteCertificate(subscription.id, val.id, {delete: false, cancel: true})
                            .then(function(response) {})
                            .catch(function(error) {
                                console.log(error);
                                certificateFail = true;
                                $q.reject();
                            })
                    );
                    var domain = _.findWhere(domains, {id: val.wit_domain_conf_id});
                    if (_.indexOf(domainIds, val.wit_domain_conf_id) == -1 && !domain.custom_wildcard_flag) {
                        domainIds.push(val.wit_domain_conf_id);
                        certificatePromises.push(
                            witAccountService.deleteUnstoredCertificate(subscription.id, val.wit_domain_conf_id)
                                .then(function(response) {})
                                .catch(function(error) {
                                    certificateFail = true;
                                    $q.reject();
                                })
                        );
                    }
                });
                $q.all(certificatePromises).then(function() {
                    deleteResourceRecords();
                });
            }
        }
    }
})();
