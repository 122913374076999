(function() {
    'use strict';

    angular.module('adminApp.wit')
        .controller('WitViewDnsController', WitViewDnsController);

    WitViewDnsController.$inject = ['$uibModalInstance', 'conf'];

    function WitViewDnsController($uibModalInstance, conf) {
        var vm = this;

        vm.close = close;
        vm.conf = conf;
        vm.status = conf.status.dns ? 'DNS configured successfully' : 'Waiting for customer DNS configuration';

        function close() {
            $uibModalInstance.dismiss();
        }
    }
})();
