(function() {
    'use strict';

    angular.module('adminApp.licenses')
        .directive('licenseData', licenseData);

    function licenseData() {
        var directive = {
            restrict: 'E',
            templateUrl: 'view/licenses.templates.data',
            scope: {
                ngLicense: "=license",
                userId: "=",
                type: "@"
            },
            controller: 'LicenseDirectiveController',
            controllerAs: 'vm'
        };

        return directive;
    }
})();
